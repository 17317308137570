import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "./DetailsTags";

//const projectId = "google_1";
const dashboardName = "DCX Connections";

const SubMenu = () => {
  const { projectId } = useParams();

  return (
    <>
      <h3>Hybrid Network</h3>
      <div className="otherTableTitles ">
        <h3 className="font-15 ">
          <Link className="text-color-gray" to={ROUTES.VPN + "/" + projectId}>
            VPN
          </Link>
        </h3>
        <h3 className="font-15 activeTable">Direct Connect</h3>
        <h3 className="font-15">
          <Link
            className="text-color-gray"
            to={ROUTES.VPN_DIRECT_CONNECT_GATEWAY + "/" + projectId}
          >
            DGW
          </Link>
        </h3>
      </div>
    </>
  );
};

const Details = ({ close, selectedRow }) => {
  const [type, setType] = useState("Details");

  return (
    <div className="justify-content-center align-items-end account-detail">
      <div className="w-100 s-flex flex-column account-detail-area">
        <button className="exitAccount" onClick={() => close()}>
          <img src="../images/close.svg" />
        </button>
        <div className="">
          <ul className="account-detail-link m-0 p-0 d-inline-flex">
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Details" && "my_active"
              }`}
              onClick={() => setType("Details")}
            >
              Details
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Tags" && "my_active"
              }`}
              onClick={() => setType("Tags")}
            >
              Tags
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 account-detail-container-area">
          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Details" && "inaktif"
            }`}
          >
            <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Owner Account</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.ownerAccount}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Region</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.region}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  DCX Connection Id
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.connectionId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  DCX Connection Name
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.connectionName}
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Provider</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.providerName}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Bandwidth</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.bandwidth}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">State</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.connectionState}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Jumboframe Capable
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.hasOwnProperty("jumboFrameCapable") &&
                    selectedRow.jumboFrameCapable.toString()}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Logical Redundency
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.hasLogicalRedundancy}
                </p>
              </div>
            </div>
          </div>

          <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>
        </div>
      </div>
    </div>
  );
};

const Navigations = () => {
  const { projectId } = useParams();
  return (
    <>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.TGW + "/" + projectId}
      >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Transit Gateway</p>
      </Link>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.NETWORK_FIREWALL_FIREWALLS + "/" + projectId}
      >
        <p className="m-0 p-0 mr-2 font-weight-bold">Network Firewall</p>
        <img src="../images/ribbon-designRight.svg" />
      </Link>
    </>
  );
};

const VPNDirectConnect = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Owner Account",
        accessor: "ownerAccount",
      },
      {
        Header: "Region",
        accessor: "region",
      },
      {
        Header: "DCX Connection Id",
        accessor: "connectionId",
      },
      {
        Header: "DCX Connection Name",
        accessor: "connectionName",
      },
      {
        Header: "Location",
        accessor: "location",
      },
      {
        Header: "Provider",
        accessor: "providerName",
      },
      {
        Header: "Bandwidth",
        accessor: "bandwidth",
      },
      {
        Header: "State",
        accessor: "connectionState",
      },
      {
        Header: "Jumboframe Capable",
        accessor: (d) =>
          d.hasOwnProperty("jumboFrameCapable") &&
          d.jumboFrameCapable.toString(),
      },
      {
        Header: "Logical Redundency",
        accessor: "hasLogicalRedundancy",
      },
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "network",
    title: "Network",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_CENTERS,
    designRoute: ROUTES.DESIGN_NETWORK,
    SubMenu: SubMenu,
  });
};

export default VPNDirectConnect;
