/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createClient = /* GraphQL */ `
  mutation CreateClient($input: createClientInput!) {
    createClient(input: $input)
  }
`;
export const updateClientAccess = /* GraphQL */ `
  mutation UpdateClientAccess(
    $ClientID: ID!
    $Field: String!
    $Value: String!
  ) {
    updateClientAccess(ClientID: $ClientID, Field: $Field, Value: $Value)
  }
`;
export const initiateLogoUpload = /* GraphQL */ `
  mutation InitiateLogoUpload($name: String!, $type: String!) {
    initiateLogoUpload(name: $name, type: $type)
  }
`;
export const revokeClientAdmin = /* GraphQL */ `
  mutation RevokeClientAdmin($userId: String!) {
    revokeClientAdmin(userId: $userId)
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient($input: updateClientInput!) {
    updateClient(input: $input)
  }
`;
export const removeClient = /* GraphQL */ `
  mutation RemoveClient($ClientID: String!) {
    removeClient(ClientID: $ClientID)
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject($input: createProjectInput!) {
    createProject(input: $input)
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject($input: updateProjectInput!) {
    updateProject(input: $input)
  }
`;
export const removeProject = /* GraphQL */ `
  mutation RemoveProject($ProjectID: String!) {
    removeProject(ProjectID: $ProjectID)
  }
`;
export const updateProjectAccess = /* GraphQL */ `
  mutation UpdateProjectAccess(
    $ProjectID: ID!
    $Field: String!
    $Value: String!
  ) {
    updateProjectAccess(ProjectID: $ProjectID, Field: $Field, Value: $Value)
  }
`;
export const unassignUserFromProject = /* GraphQL */ `
  mutation UnassignUserFromProject($projectId: ID!, $userId: String!) {
    unassignUserFromProject(projectId: $projectId, userId: $userId)
  }
`;
export const copyProject = /* GraphQL */ `
  mutation CopyProject(
    $sourceProjectId: String!
    $targetProjectId: String!
    $copyCode: Boolean
  ) {
    copyProject(
      sourceProjectId: $sourceProjectId
      targetProjectId: $targetProjectId
      copyCode: $copyCode
    )
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: createUserInput!) {
    createUser(input: $input)
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: createUserInput!) {
    updateUser(input: $input)
  }
`;
export const removeUser = /* GraphQL */ `
  mutation RemoveUser($UserID: String!) {
    removeUser(UserID: $UserID)
  }
`;
export const log = /* GraphQL */ `
  mutation Log($action: String!, $addInfo: String!, $clientId: String) {
    log(action: $action, addInfo: $addInfo, clientId: $clientId)
  }
`;
export const backup = /* GraphQL */ `
  mutation Backup(
    $targetStage: String!
    $targetRegion: String!
    $copyCode: Boolean!
  ) {
    backup(
      targetStage: $targetStage
      targetRegion: $targetRegion
      copyCode: $copyCode
    )
  }
`;
