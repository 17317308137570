import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { Link, useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import {
  validateAwsPublicPrivateDomainName,
  validateCidr,
  validateDomainName,
} from "../../../../helpers/validator";
import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

//const projectId = "google_1";
const PREFIX = "/aws/network/aws";
const sharedservicesaccountslist_PREFIX =
  "/aws/accountframework/platformaccounts/sharedservicesaccountslist";
const securityaccountslist_PREFIX =
  "/aws/accountframework/platformaccounts/securityaccountslist";

const AwsEnvironment = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    array: [],
    awsprimaryregion: {
      value: "",
    },
    awsdrregion: {
      value: "",
    },
    awsprivatednsdomainname: {
      value: "",
      isValid: false,
    },
    awspublicdnsdomainname: {
      value: "",
      isValid: false,
    },
    networkservicesaccount: {
      value: "",
    },
    publicdns_enablednssec: {
      value: "yes",
    },
    regions: [],
    platformaccounts: [],
  });

  const [empty, setEmpty] = useState(false);


  const disabled = useProjectDisabledStatus(projectId, [
    "Launched",
    "Deployed",
  ]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const allRegions = await awsAmplify.getAllRegions();

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        let servicesAccounts = [];

        const sharedServicesAccounts = await awsAmplify.loadProjectData(
          projectId,
          sharedservicesaccountslist_PREFIX
        );

        if (
          sharedServicesAccounts.data &&
          sharedServicesAccounts.data[sharedservicesaccountslist_PREFIX]
        ) {
          servicesAccounts =
            sharedServicesAccounts.data[
              sharedservicesaccountslist_PREFIX
            ].split(",");
        }

        const securityAccounts = await awsAmplify.loadProjectData(
          projectId,
          securityaccountslist_PREFIX
        );

        if (
          securityAccounts.data &&
          securityAccounts.data[securityaccountslist_PREFIX]
        ) {
          servicesAccounts = servicesAccounts.concat(
            securityAccounts.data[securityaccountslist_PREFIX].split(",")
          );
        }

        console.log(servicesAccounts);

        setEmpty(result.data.empty);

        setFormData(
          convertBEToFE(result.data, allRegions.data, servicesAccounts)
        );

        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if (empty) {
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data, regions, platformaccounts) => {
    const fe = {
      array: [createEntity(), createEntity()],

      awsprimaryregion: {
        value: "",
      },
      awsdrregion: {
        value: "",
      },
      awsprivatednsdomainname: {
        value: "",
        isValid: false,
      },
      awspublicdnsdomainname: {
        value: "",
        isValid: false,
      },
      networkservicesaccount: {
        value: "",
      },
      publicdns_enablednssec: {
        value: "yes",
      },
      regions,
      platformaccounts,
    };

    if (!data.empty) {
      if (data[`${PREFIX}/awsprimaryregion`]) {
        fe.awsprimaryregion.value = data[`${PREFIX}/awsprimaryregion`];
      }

      if (data[`${PREFIX}/awsdrregion`]) {
        fe.awsdrregion.value = data[`${PREFIX}/awsdrregion`];
      }

      if (data[`${PREFIX}/awsprivatednsdomainname`]) {
        fe.awsprivatednsdomainname.value =
          data[`${PREFIX}/awsprivatednsdomainname`];
        fe.awsprivatednsdomainname.isValid = true;
      }

      if (data[`${PREFIX}/awspublicdnsdomainname`]) {
        fe.awspublicdnsdomainname.value =
          data[`${PREFIX}/awspublicdnsdomainname`];
        fe.awspublicdnsdomainname.isValid = true;
      }

      if (data[`${PREFIX}/networkservicesaccount`]) {
        fe.networkservicesaccount.value =
          data[`${PREFIX}/networkservicesaccount`];
      }

      if (data[`${PREFIX}/publicdns/enablednssec`]) {
        fe.publicdns_enablednssec.value =
          data[`${PREFIX}/publicdns/enablednssec`];
      }

      if (data[`${PREFIX}/awsregionslist`]) {
        fe.array = [];
        const awsRegions = data[`${PREFIX}/awsregionslist`].split(",");

        for (const awsRegion of awsRegions) {
          const entity = createEntity();

          entity.region.value = awsRegion;
          entity.region.isValid = true;

          /* if (data[`${PREFIX}/${awsRegion}/cidrranges`]) {
            entity.cidrRanges = [];

            const cidrRangesArray = data[
              `${PREFIX}/${awsRegion}/cidrranges`
            ].split(",");

            for (const cidrRange of cidrRangesArray) {
              entity.cidrRanges.push({
                value: cidrRange,
                isValid: true,
              });
            }
          } */

          fe.array.push(entity);
        }

        if (fe.array.length === 1) {
          fe.array.push(createEntity());
        }
      }
    }

    return fe;
  };

  const convertFEToBE = () => {
    const be = {};

    if (formData.awsprimaryregion.value !== "") {
      be[`${PREFIX}/awsprimaryregion`] = formData.awsprimaryregion.value;
    }

    if (formData.awsdrregion.value !== "") {
      be[`${PREFIX}/awsdrregion`] = formData.awsdrregion.value;
    }

    if (
      formData.awsprivatednsdomainname.value !== "" &&
      formData.awsprivatednsdomainname.isValid
    ) {
      be[`${PREFIX}/awsprivatednsdomainname`] =
        formData.awsprivatednsdomainname.value;
    }

    if (
      formData.awspublicdnsdomainname.value !== "" &&
      formData.awspublicdnsdomainname.isValid
    ) {
      be[`${PREFIX}/awspublicdnsdomainname`] =
        formData.awspublicdnsdomainname.value;
    }

    if (formData.networkservicesaccount.value !== "") {
      be[`${PREFIX}/networkservicesaccount`] =
        formData.networkservicesaccount.value;
    }

    be[`${PREFIX}/publicdns/enablednssec`] =
      formData.publicdns_enablednssec.value;

    const awsregionslist = [];
    formData.array.forEach((item) => {
      if (item.region.isValid && item.region.value !== "") {
        awsregionslist.push(item.region.value);

        /* const cidrs = [];

        for (const cidrRange of item.cidrRanges) {
          if (cidrRange.isValid) {
            cidrs.push(cidrRange.value);
          }
        }

        if (cidrs.length > 0) {
          be[`${PREFIX}/${item.region.value}/cidrranges`] = cidrs.join(",");
        } */
      }
    });

    if (awsregionslist.length > 0) {
      be[`${PREFIX}/awsregionslist`] = awsregionslist.join(",");
    }

    return be;
  };

  const createEntity = () => {
    return {
      region: {
        value: "",
        isValid: false,
      },
      /* cidrRanges: [
        { value: "", isValid: false },
        { value: "", isValid: false },
      ], */
    };
  };

  const addArrayItem = () => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, { array: { $push: [createEntity()] } })
    );
  };

  const removeArrayItem = (idx) => {
    if (formData.array.length > 2) {
      setIsDirty(true);

      setFormData((state) => update(state, { array: { $splice: [[idx, 1]] } }));
    }
  };

  const addCidr = (dcIdx) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        array: {
          [dcIdx]: { cidrRanges: { $push: [{ value: "", isValid: false }] } },
        },
      })
    );
  };

  const removeCidr = (dcIdx, cidrIdx) => {
    if (formData.array[dcIdx].cidrRanges.length > 1) {
      setIsDirty(true);

      setFormData((state) =>
        update(state, {
          array: { [dcIdx]: { cidrRanges: { $splice: [[cidrIdx, 1]] } } },
        })
      );
    }
  };

  const onArrayItemChange = (idx, value) => {
    setErrorMsg(null);
    setIsDirty(true);
    setFormData((state) =>
      update(state, {
        array: { [idx]: { region: { value: { $set: value } } } },
      })
    );
  };

  const onArrayItemBlur = (idx, validateFunc, value) => {
    const errorMsg = validateFunc(value);

    setFormData((state) =>
      update(state, {
        array: {
          [idx]: { region: { isValid: { $set: errorMsg === undefined } } },
        },
      })
    );

    if (errorMsg) {
      setErrorMsg(errorMsg);
    }
  };

  const onCidrChange = (dcIdx, cidrIdx, value) => {
    setErrorMsg(null);
    setIsDirty(true);
    setFormData((state) =>
      update(state, {
        array: {
          [dcIdx]: { cidrRanges: { [cidrIdx]: { value: { $set: value } } } },
        },
      })
    );
  };

  const onCidrBlur = (dcIdx, cidrIdx, validateFunc, value) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          array: {
            [dcIdx]: {
              cidrRanges: {
                [cidrIdx]: { isValid: { $set: errorMsg === undefined } },
              },
            },
          },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, {
          array: {
            [dcIdx]: {
              cidrRanges: {
                [cidrIdx]: { isValid: { $set: false } },
              },
            },
          },
        })
      );
    }
  };

  const getArrayItemValidationClass = (idx) => {
    return formData.array[idx].region.value !== "" &&
      !formData.array[idx].region.isValid
      ? "invalid"
      : "";
  };

  const getCidrValidationClass = (dcIdx, cidrIdx) => {
    return formData.array[dcIdx].cidrRanges[cidrIdx].value !== "" &&
      !formData.array[dcIdx].cidrRanges[cidrIdx].isValid
      ? "invalid"
      : "";
  };

  const getFieldValidationClass = (key1) => {
    return !formData[key1].isValid && formData[key1].value !== ""
      ? "invalid"
      : "";
  };

  const onFieldChange = (value, key1) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key1]: { value: { $set: value } } })
    );
  };

  const onFieldBlur = (validateFunc, value, key1) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, { [key1]: { isValid: { $set: errorMsg === undefined } } })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, { [key1]: { isValid: { $set: false } } })
      );
    }
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Network</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Network"
                isDirty={isDirty}
                dashboardRoute={ROUTES.VPC}
                designRoute={ROUTES.DESIGN_NETWORK}
              ></DomainMenu>
              <fieldset
                disabled={disabled}
                className="mainArea fitDeviceHeight"
              >
                <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 ">
                  <div className="row d-flex mb-2 align-items-center">
                    <div className="col-12 col-md-4 pl-lg-0 px-2">
                      <h3 className="font-weight-bold">AWS Environment</h3>
                    </div>
                  </div>
                  {empty && <NoInitialData />}
                  <div className="d-flex flex-column   pb-3 pt-0">
                    <div className=" row mainContentArea p-relative px-md-2 px-2 my-1 py-1 align-items-start justify-content-start">
                      <div className="col-12 d-flex">
                        <div className="d-inline-flex flex-wrap align-items-center my-1">
                          {formData.array.map((arrayItem, idxArrayItem) => (
                            <div
                              className="d-inline-flex align-items-center my-1 mr-sm-2"
                              key={idxArrayItem}
                            >
                              <div className="d-inline-flex flex-wrap align-items-center bg-special2 justify-content-xl-between justify-content-md-start border-10 w-100 pr-2 py-2 px-3">
                                <span className="font-12 d-flex align-items-center  mr-3">
                                  AWS Region {idxArrayItem + 1}
                                </span>
                                <div className="d-inline-flex justify-content-end">
                                  <select
                                    className="w-175 form-control mr-2"
                                    //options={regions}
                                    value={arrayItem.region.value}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      let exists = false;

                                      formData.array.forEach((item) => {
                                        if (item.region.value === value) {
                                          exists = true;
                                        }
                                      });

                                      if (!exists) {
                                        onArrayItemChange(idxArrayItem, value);

                                        setFormData((state) =>
                                          update(state, {
                                            array: {
                                              [idxArrayItem]: {
                                                region: {
                                                  isValid: { $set: true },
                                                },
                                              },
                                            },
                                          })
                                        );
                                      } else {
                                        onArrayItemChange(idxArrayItem, -1);

                                        setFormData((state) =>
                                          update(state, {
                                            array: {
                                              [idxArrayItem]: {
                                                region: {
                                                  isValid: { $set: false },
                                                },
                                              },
                                            },
                                          })
                                        );

                                        setErrorMsg(
                                          "Same AWS region is not allowed"
                                        );
                                      }
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {formData.regions.map(
                                      (region, idxRegion) => (
                                        <option key={idxRegion} value={region}>
                                          {region}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  <button
                                    disabled={disabled}
                                    type="button"
                                    onClick={() =>
                                      removeArrayItem(idxArrayItem)
                                    }
                                    className="closeIcon"
                                  >
                                    <img src="../images/close.svg" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                          <button
                            type="button"
                            disabled={disabled}
                            onClick={() => addArrayItem()}
                          >
                            <img src="../images/purplePlusIcon.svg" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="mainContentArea px-2 py-1 my-1 justify-content-between">
                      <div className=" row">
                        <div className="col-lg-11 col-12 ">
                          <div className="row">
                            <div className="col-md-6 col-xl-6 col-xxl-4 col-12 d-inline-flex form-line3 my-1">
                              <span className="font-12 mr-2 text-nowrap d-flex align-items-center flex-3">
                                Primary Region{" "}
                              </span>
                              <select
                                className="form-control gray flex-2"
                                value={formData.awsprimaryregion.value}
                                onChange={(e) => {
                                  onFieldChange(
                                    e.target.value,
                                    "awsprimaryregion"
                                  );
                                }}
                              >
                                <option value="">Select</option>
                                {formData.array
                                  .filter((item) => {
                                    return (
                                      item.region.value !== "" &&
                                      item.region.isValid &&
                                      item.region.value !==
                                        formData.awsdrregion.value
                                    );
                                  })
                                  .map((item, idxItem) => {
                                    return (
                                      <option
                                        key={idxItem}
                                        value={item.region.value}
                                      >
                                        {item.region.value}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>

                            <div className="col-md-6 col-xl-6 col-xxl-4 col-12 d-inline-flex form-line3 my-1">
                              <span className="font-12 mr-2 text-nowrap d-flex align-items-center flex-3">
                                DR Region{" "}
                              </span>
                              <select
                                className="form-control gray flex-2"
                                value={formData.awsdrregion.value}
                                onChange={(e) => {
                                  onFieldChange(e.target.value, "awsdrregion");
                                }}
                              >
                                <option value="">Select</option>
                                {formData.array
                                  .filter((item) => {
                                    return (
                                      item.region.value !== "" &&
                                      item.region.isValid &&
                                      item.region.value !==
                                        formData.awsprimaryregion.value
                                    );
                                  })
                                  .map((item, idxItem) => {
                                    return (
                                      <option
                                        key={idxItem}
                                        value={item.region.value}
                                      >
                                        {item.region.value}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-1 col-12"></div>
                      </div>

                      <div className=" row">
                        <div className="col-lg-11 col-12 ">
                          <div className="row">
                            <div className="col-md-6 col-xl-6 col-xxl-4 col-12 d-inline-flex form-line3 my-1">
                              <span className="font-12 mr-2 text-nowrap d-flex align-items-center flex-3">
                                Private DNS Domain Name for AWS{" "}
                              </span>
                              <input
                                disabled={disabled}
                                type="text"
                                className={`form-control gray flex-2 ${getFieldValidationClass(
                                  "awsprivatednsdomainname"
                                )}`}
                                value={formData.awsprivatednsdomainname.value}
                                placeholder="aws.client.com"
                                onChange={(e) =>
                                  onFieldChange(
                                    e.target.value,
                                    "awsprivatednsdomainname"
                                  )
                                }
                                onBlur={(e) =>
                                  onFieldBlur(
                                    validateAwsPublicPrivateDomainName,
                                    e.target.value,
                                    "awsprivatednsdomainname"
                                  )
                                }
                              />
                            </div>

                            <div className="col-md-6 col-xl-6 col-xxl-4 col-12 d-inline-flex form-line3 my-1">
                              <span className="font-12 mr-2 text-nowrap d-flex align-items-center flex-3">
                                Public DNS Domain Name for AWS{" "}
                              </span>
                              <input
                                disabled={disabled}
                                type="text"
                                className={`form-control gray flex-2 ${getFieldValidationClass(
                                  "awspublicdnsdomainname"
                                )}`}
                                placeholder="aws.client.com"
                                value={formData.awspublicdnsdomainname.value}
                                onChange={(e) =>
                                  onFieldChange(
                                    e.target.value,
                                    "awspublicdnsdomainname"
                                  )
                                }
                                onBlur={(e) =>
                                  onFieldBlur(
                                    validateAwsPublicPrivateDomainName,
                                    e.target.value,
                                    "awspublicdnsdomainname"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-1 col-12"></div>
                      </div>

                      <div className=" row">
                        <div className="col-lg-11 col-12 ">
                          <div className="row">
                            <div className="col-md-6 col-xl-6 col-xxl-4 col-12 d-inline-flex form-line3 my-1">
                              <div className="d-inline-flex form-line wrapped-margin my-1">
                                <label className="switch mb-0 ml-lg-3 ml-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData.publicdns_enablednssec.value ===
                                      "yes"
                                    }
                                    onChange={(e) => {
                                      const value =
                                        e.target.checked === true
                                          ? "yes"
                                          : "no";
                                      setIsDirty(true);
                                      setFormData((state) =>
                                        update(state, {
                                          publicdns_enablednssec: {
                                            value: {
                                              $set: value,
                                            },
                                          },
                                        })
                                      );
                                    }}
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <span className="switchLabel font-weight-light">
                                  Enable DNSSEC
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-1 col-12"></div>
                      </div>
                    </div>
                    <div className="px-2 row">
                      <div className="col-lg-11 col-12 ">
                        <div className="row">
                          <div className="col-md-6 col-xl-6 col-xxl-4 col-12 d-inline-flex form-line3 my-1">
                            <span className="font-12 mr-2 text-nowrap d-flex align-items-center flex-3">
                              Centralized Network Services Account{" "}
                            </span>

                            {(formData.platformaccounts && formData.platformaccounts.length == 0 ) ?
                            (
                              <div className="col-md-12 flex-wrap d-inline-flex">
                              <span className="warning">
                              This section depends on the platform accounts information in
                              Account Framework section that is yet to be provided
                            </span>
                            </div>
                            ) : (
                              <>
                            <select
                              className="form-control gray flex-2"
                              value={formData.networkservicesaccount.value}
                              onChange={(e) => {
                                onFieldChange(
                                  e.target.value,
                                  "networkservicesaccount"
                                );
                              }}
                            >
                              <option value="">Select</option>
                              {formData.platformaccounts.map(
                                (item, idxItem) => (
                                  <option key={idxItem} value={item}>
                                    {item}
                                  </option>
                                )
                              )}
                            </select>
                            </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-1 col-12"></div>
                    </div>
                  </div>
                </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(ROUTES.DATA_CENTERS + "/" + projectId)
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Data Centers
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(ROUTES.VPC_DESIGN + "/" + projectId)
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">VPC Design</p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AwsEnvironment;
