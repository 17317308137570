
import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getBooleanValue,getDateValue} from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";
import { array } from "yup";

const dashboardName = "AWSBudgets";

const SubMenu = () => {
   const { projectId } = useParams();
   return (
     <>
      <h3>AWS Budgets</h3>
     </>
   );
 };

const Details = ({ close, selectedRow }) => {
   const [type, setType] = useState("Details");
 
   return (
     <div className="justify-content-center align-items-end account-detail">
       <div className="w-100 s-flex flex-column account-detail-area">
         <button className="exitAccount" onClick={() => close()}>
           <img src="../images/close.svg" />
         </button>
         <div className="">
           <ul className="account-detail-link m-0 p-0 d-inline-flex">
             <li
               className={`bolgeler-btn my-active px-3 py-2 ${
                 type === "Details" && "my_active"
               }`}
               onClick={() => setType("Details")}
             >
               Details
             </li>
             <li
               className={`bolgeler-btn my-active px-3 py-2 ${
                 type === "Cost Types" && "my_active"
               }`}
               onClick={() => setType("Cost Types")}
             >
              Cost Types
             </li>
             <li
               className={`bolgeler-btn my-active px-3 py-2 ${
                 type === "Time Period" && "my_active"
               }`}
               onClick={() => setType("Time Period")}
             >
               Time Period
             </li>
             <li
               className={`bolgeler-btn my-active px-3 py-2 ${
                 type === "Planned Budget Limits" && "my_active"
               }`}
               onClick={() => setType("Planned Budget Limits")}
             >
              Planned Budget Limits
             </li>
             <li
               className={`bolgeler-btn my-active px-3 py-2 ${
                 type === "Cost Filters" && "my_active"
               }`}
               onClick={() => setType("Cost Filters")}
             >
              Cost Filters
             </li>
             <li
               className={`bolgeler-btn my-active px-3 py-2 ${
                 type === "Notifications" && "my_active"
               }`}
               onClick={() => setType("Notifications")}
             >
              Notifications
             </li>
           </ul>
         </div>
         <div className="d-flex w-100 account-detail-container-area">
           <div
             className={`bolgeler-container w-100 row p-5 ${
               type !== "Details" && "inaktif"
             }`}
           >
             <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">Account Id</p>
                 <p className="m-0 p-0 account-title-subcontent">
                   {selectedRow.AccountId}
                 </p>
               </div>
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">Budget Name</p>
                 <p className="m-0 p-0 account-title-subcontent">
                 {selectedRow.BudgetName}
                 </p>
               </div>
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">Budget Type</p>
                 <p className="m-0 p-0 account-title-subcontent">
                 {selectedRow.BudgetType}
                 </p>
               </div>
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">Time Unit</p>
                 <p className="m-0 p-0 account-title-subcontent">
                 {selectedRow.TimeUnit}
                 </p>
               </div>
             </div>
             <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">
                   Current Spend
                 </p>
                 <p className="m-0 p-0 account-title-subcontent">
                 {selectedRow.CalculatedSpend && selectedRow.CalculatedSpend.ActualSpend &&
                 selectedRow.CalculatedSpend.ActualSpend.Amount}
                 </p>
               </div>
 
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">
                Budgeted
                 </p>
                 <p className="m-0 p-0 account-title-subcontent">
                 {selectedRow.BudgetLimit && selectedRow.BudgetLimit.Amount}
                 </p>
               </div>
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">Forecasted</p>
                 <p className="m-0 p-0 account-title-subcontent">
                 {selectedRow.CalculatedSpend && selectedRow.CalculatedSpend.ForecastedSpend &&
                 selectedRow.CalculatedSpend.ForecastedSpend.Amount}
                 </p>
               </div>
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">Last Updated</p>
                 <p className="m-0 p-0 account-title-subcontent">
                 { getDateValue(selectedRow.LastUpdatedTime)}
                 </p>
               </div>
             </div>
           </div>

           <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Planned Budget Limits" && "inaktif"
              }`}
            >
              <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Month</th>
                      <th>Budget</th>
                      <th>Currency</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                  { selectedRow.PlannedBudgetLimits && (
                       getPlannedBudgetMonths(selectedRow).map( (month) => 
                       <>
                        <tr> 
                        <td>{getFormattedMonth(month) }</td>
                        <td>{selectedRow.PlannedBudgetLimits[month].Amount}</td>
                        <td>{selectedRow.PlannedBudgetLimits[month].Unit}</td>
                        </tr>  
                        </>
                       ) ) }
                  {!selectedRow.PlannedBudgetLimits && (
                     getMonthStartingFromCurrentMonth().map((month) => 
                     <>
                      <tr>
                      <td>{month}</td>
                      <td>{selectedRow.BudgetLimit.Amount}</td>
                      <td>{selectedRow.BudgetLimit.Unit}</td>
                    </tr>
                      </>
                     )
                  )}
                  </>

                  </tbody>
                </table>
              </div>
             </div>

           <div
             className={`bolgeler-container w-100 row p-5 ${
               type !== "Cost Types" && "inaktif"
             }`}
           >
             <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">Include Tax</p>
                 <p className="m-0 p-0 account-title-subcontent">
                   {selectedRow.CostTypes && getBooleanValue(selectedRow.CostTypes, "IncludeTax")}
                 </p>
               </div>
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">Include Subscription</p>
                 <p className="m-0 p-0 account-title-subcontent">
                 {selectedRow.CostTypes && 
                 getBooleanValue(selectedRow.CostTypes, "IncludeSubscription")}
                 </p>
               </div>
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">Use Blended</p>
                 <p className="m-0 p-0 account-title-subcontent">
                 {selectedRow.CostTypes && getBooleanValue(selectedRow.CostTypes, "UseBlended")}
                 </p>
               </div>
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">Include Refund</p>
                 <p className="m-0 p-0 account-title-subcontent">
                 {selectedRow.CostTypes && getBooleanValue(selectedRow.CostTypes, "IncludeRefund")}
                 </p>
               </div>
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">Include Credit</p>
                 <p className="m-0 p-0 account-title-subcontent">
                 {selectedRow.CostTypes && getBooleanValue(selectedRow.CostTypes, "IncludeCredit")}
                 </p>
               </div>
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">Include Upfront</p>
                 <p className="m-0 p-0 account-title-subcontent">
                 {selectedRow.CostTypes && getBooleanValue(selectedRow.CostTypes, "IncludeUpfront")}
                 </p>
               </div>
             </div>
             <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">
                   Include Recurring
                 </p>
                 <p className="m-0 p-0 account-title-subcontent">
                 {selectedRow.CostTypes && getBooleanValue(selectedRow.CostTypes, "IncludeRecurring")}
                 </p>
               </div>
 
               <div className="d-inline-flex w-120 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">
                  Include Other Subscription
                 </p>
                 <p className="m-0 p-0 account-title-subcontent">
                 {selectedRow.CostTypes && getBooleanValue(selectedRow.CostTypes, "IncludeOtherSubscription")}
                 </p>
               </div>
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">Include Support</p>
                 <p className="m-0 p-0 account-title-subcontent">
                 {selectedRow.CostTypes && getBooleanValue(selectedRow.CostTypes, "IncludeSupport")}
                 </p>
               </div>
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">Include Discount</p>
                 <p className="m-0 p-0 account-title-subcontent">
                 {selectedRow.CostTypes && getBooleanValue(selectedRow.CostTypes, "IncludeDiscount")}
                 </p>
               </div>
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">Use Amortized</p>
                 <p className="m-0 p-0 account-title-subcontent">
                 {selectedRow.CostTypes && getBooleanValue(selectedRow.CostTypes, "UseAmortized")}
                 </p>
               </div>
             </div>
           </div>

           <div
             className={`bolgeler-container w-100 row p-5 ${
               type !== "Time Period" && "inaktif"
             }`}
           >
             <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">Start</p>
                 <p className="m-0 p-0 account-title-subcontent">
                   {selectedRow.TimePeriod && selectedRow.TimePeriod.Start}
                 </p>
               </div>
               <div className="d-inline-flex w-100 my-1">
                 <p className="m-0 p-0 account-detail-subtitle">End</p>
                 <p className="m-0 p-0 account-title-subcontent">
                 {selectedRow.TimePeriod && selectedRow.TimePeriod.End}
                 </p>
               </div>
              </div>
           </div>


           <div
             className={`bolgeler-container w-100 row p-5 ${
               type !== "Cost Filters" && "inaktif"
             }`}
           >
               <div className=" d-flex flex-column">
                  <div className="d-flex flex-column">
                  <p className="font-12 font-weight-bold">
                        Service:
                  </p>

                  <p className="account-detail-subtitle-1">
                  {selectedRow.CostFilters && selectedRow.CostFilters.Service &&
                       selectedRow.CostFilters.Service.join(", ")}
                  </p>
                 
                  </div>
                  <div className="d-flex flex-column">
                  <p className="font-12 font-weight-bold">
                        Linked Account:
                  </p>

                  <p className="account-detail-subtitle-1">
                  {selectedRow.CostFilters && selectedRow.CostFilters.LinkedAccount &&
                       selectedRow.CostFilters.LinkedAccount.join(", ")}
                  </p>
                  </div>
                  <div className="d-flex flex-column">
                  <p className="font-12 font-weight-bold">
                        Region:
                  </p>

                  <p className="account-detail-subtitle-1">
                      {selectedRow.CostFilters && selectedRow.CostFilters.Region &&
                       selectedRow.CostFilters.Region.join(", ")}
                  </p>
                  </div>
                  <div className="d-flex flex-column">
                  <p className="font-12 font-weight-bold">
                        Instance Type:
                  </p>

                  <p className="account-detail-subtitle-1">
                  {selectedRow.CostFilters && selectedRow.CostFilters.InstanceType &&
                       selectedRow.CostFilters.InstanceType.join(", ")}
                  </p>
             
                  </div>
                  <div className="d-flex flex-column">
                  <p className="font-12 font-weight-bold">
                        Usage Type Group:
                  </p>

                  <p className="account-detail-subtitle-1">
                  {selectedRow.CostFilters && selectedRow.CostFilters.UsageTypeGroup &&
                       selectedRow.CostFilters.UsageTypeGroup.join(", ")}
                  </p>
                  </div>
                  <div className="d-flex flex-column">
                  <p className="font-12 font-weight-bold">
                        Usage Type: EC2-Instances (Elastic Compute Cloud - Compute)
                  </p>

                  <p className="account-detail-subtitle-1">
                  {selectedRow.CostFilters && selectedRow.CostFilters.UsageType &&
                       selectedRow.CostFilters.UsageType.join(", ")}
                  </p>
                  </div>
                  <div className="d-flex flex-column">
                  <p className="font-12 font-weight-bold">
                        Cost Category:
                  </p>

                  <p className="account-detail-subtitle-1">
                    {/* ToDO:  Verify the mapping for CostCategory*/}
                  {selectedRow.CostFilters && selectedRow.CostFilters.CostCategory &&
                       selectedRow.CostFilters.CostCategory.join(", ")}
                  </p>
                  </div>
                  <div className="d-flex flex-column">
                  <p className="font-12 font-weight-bold">
                        Tag:
                  </p>

                  <p className="account-detail-subtitle-1">
                      {/* ToDO:  Verify the mapping for Tag*/}
                  {selectedRow.CostFilters && selectedRow.CostFilters.Tag &&
                       selectedRow.CostFilters.Tag.join(", ")}
                  </p>
                  </div>
                  <div className="d-flex flex-column">
                  <p className="font-12 font-weight-bold">
                        Api Operation:
                  </p>

                  <p className="account-detail-subtitle-1">
                  {selectedRow.CostFilters && selectedRow.CostFilters.Operation &&
                       selectedRow.CostFilters.Operation.join(", ")}
                  </p>
                  </div>
                  <div className="d-flex flex-column">
                  <p className="font-12 font-weight-bold">
                        Availability Zone:
                  </p>

                  <p className="account-detail-subtitle-1">
                  {selectedRow.CostFilters && selectedRow.CostFilters.AZ &&
                       selectedRow.CostFilters.AZ.join(", ")}
                  </p>
                  </div>
                  <div className="d-flex flex-column">
                  <p className="font-12 font-weight-bold">
                        Purchase Option:
                  </p>

                  <p className="account-detail-subtitle-1">
                  {selectedRow.CostFilters && selectedRow.CostFilters.PurchaseType &&
                       selectedRow.CostFilters.PurchaseType.join(", ")}
                  </p>
                  </div>
                  <div className="d-flex flex-column">
                  <p className="font-12 font-weight-bold">
                        Billing Entity:
                  </p>

                  <p className="account-detail-subtitle-1">
                  {selectedRow.CostFilters && selectedRow.CostFilters.BillingEntity &&
                       selectedRow.CostFilters.BillingEntity.join(", ")}
                  </p>
                  </div>
               </div>

         </div>
            <DetailsGrid
             type={type}
             typeName="Notifications"
             array={selectedRow.Notifications}
             metadata={[
               { header: "Notification Type", key: "NotificationType" },
               { header: "Comparison Operator", key: "ComparisonOperator" },
               { header: "Threshold", key: "Threshold" },
               { header: "Notifications", key: "NotificationState" }, 
             ]}
           ></DetailsGrid>

      </div>
       </div>
     </div>
   );
 };


const getPlannedBudgetMonths = (data) =>
{
  var monthArray = [];
  if(data.PlannedBudgetLimits)
  {
        for(const key in data.PlannedBudgetLimits) {
          monthArray.push(key);
        }
      return monthArray;
    }
};

const getFormattedMonth = (key) => 
{
   var  unixTimestamp  = key;
   var  date = new Date(unixTimestamp*1000);
   var  month = date.getMonth();
   var theMonths = ["JAN", "FEB", "MAR", "APR", "MAY",
   "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
   return theMonths[month];
};

 const getMonthStartingFromCurrentMonth = () => {
    var theMonths = ["JAN", "FEB", "MAR", "APR", "MAY",
      "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    var today = new Date();
    var aMonth = today.getMonth();
    var i;
    var newMonthOrder = [];
      for (i=0; i<12; i++) {
        newMonthOrder.push(theMonths[aMonth]);
        aMonth++;
        if (aMonth > 11) {
        aMonth = 0;
      }
     }
     return newMonthOrder;
 };

 const getCurrentVsBudgeted = (data) => {

  var current =  parseFloat(data && data.CalculatedSpend  && 
  data.CalculatedSpend.ActualSpend && 
  data.CalculatedSpend.ActualSpend.Amount);

 var budgeted = parseFloat(data && data.BudgetLimit &&
                 data.BudgetLimit.Amount);
                 
 var result;
 
 if(current && budgeted)
 {
    result = current / budgeted;
 }
 return result;
}

const getForecastedVsBudgeted = (data) => {
 var forecasted = parseFloat(data && data.CalculatedSpend  && 
 data.CalculatedSpend.ForecastedSpend && 
 data.CalculatedSpend.ForecastedSpend.Amount);

 var budgeted = parseFloat(data && data.BudgetLimit &&
 data.BudgetLimit.Amount);

 var result;
 if(forecasted && budgeted)
 {
    result = forecasted / budgeted;
 }
 return result;
}


const Navigations = () => {
   const { projectId } = useParams();
   return (
     <>
    <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.GOVERNANCE_RECORDERS + "/" + projectId}
      >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Config</p>       
   </Link>
   <Link
     className="btn-post btn-animation d-inline-flex"
     to={ROUTES.GOVERNANCE_PORTFOLIOS + "/" + projectId}
   >
     <p className="m-0 p-0 mr-2 font-weight-bold">Service Catalog</p>
     <img src="../images/ribbon-designRight.svg" />
   </Link>
   </>
   );
 };

const AWSBudgets = () => {
   const columns = useMemo(
     () => [
       {
         Header: "Account Id",
         accessor: "AccountId",
         id:"Account Id"
       },
       {
         Header: "Budget Name",
         accessor: "BudgetName",
         id:"Budget Name"
       },
       {
         Header: "Budget Type",
         accessor: "BudgetType",
         id: "Budget Type"
       },
       {
         Header: "Time Unit",
         accessor: "TimeUnit",
         id: "Time Unit"
       },
       {
         Header: "Current Spend",
         accessor: "CalculatedSpend.ActualSpend.Amount",
         id: "Current Spend"
       },
       {
         Header: "Budgeted",
         accessor: "BudgetLimit.Amount",
         id: "Budgeted"
       },
       {
         Header: "Forecasted",
         accessor: "CalculatedSpend.ForecastedSpend.Amount",
         id: "Forecasted"
       },
       {
         Header: "Current Vs. Budgeted",
         accessor: (d) => (getCurrentVsBudgeted(d) + '%'),
         id: "Current Vs. Budgeted"
       },
       {
         Header: "Forecasted Vs. Budgeted",
         accessor: (d) => (getForecastedVsBudgeted(d) + '%'),
         id: "Forecasted Vs. Budgeted"
       },
       {
         Header: "Last Updated",
         accessor: "LastUpdatedTime",
         id: "Last Updated"
       },
     ],
     []
   );
 
   return Dashboard({
     domain: "governance",
     title: "Governance",
     dashboardName,
     columns,
     Details,
     Navigations,
     configureRoute: ROUTES.GOVERNANCE_SERVICE_CONTROL_POLICIES,
     designRoute: ROUTES.DESIGN_GOVERNANCE,
     SubMenu
   });
 };
 


export default AWSBudgets;