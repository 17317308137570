import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import { getArrayValue, getBooleanValue } from "./DashboardHelper";

//const projectId = "google_1";
const dashboardName = "VPC Subnets";

const SubMenu = () => {
  const { projectId } = useParams();

  return (
    <>
      <h3>VPCs</h3>
      <div className="otherTableTitles ">
        <h3 className="font-15">
          <Link className="font-15" to={ROUTES.VPC + "/" + projectId}>
            VPCs
          </Link>
        </h3>
        <h3 className="font-15 activeTable">Subnets</h3>
        <h3 className="font-15">
          <Link
            className="text-color-gray"
            to={ROUTES.VPC_ROUTE_TABLES + "/" + projectId}
          >
            Route Tables
          </Link>
        </h3>
        <h3 className="font-15">
          <Link
            className="text-color-gray"
            to={ROUTES.VPC_DHCP + "/" + projectId}
          >
            DHCP
          </Link>
        </h3>
        <h3 className="font-15">
          <Link
            className="text-color-gray"
            to={ROUTES.VPC_END_POINTS + "/" + projectId}
          >
            Endpoints
          </Link>
        </h3>
      </div>
    </>
  );
};

const Details = ({ close, selectedRow }) => {
  const [type, setType] = useState("Details");

  return (
    <div className="justify-content-center align-items-end account-detail">
      <div className="w-100 s-flex flex-column account-detail-area">
        <button className="exitAccount" onClick={() => close()}>
          <img src="../images/close.svg" />
        </button>
        <div className="">
          <ul className="account-detail-link m-0 p-0 d-inline-flex">
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Details" && "my_active"
              }`}
              onClick={() => setType("Details")}
            >
              Details
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "IPv6 Block Association Set" && "my_active"
              }`}
              onClick={() => setType("IPv6 Block Association Set")}
            >
              IPv6 Block Association Set
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Route Table" && "my_active"
              }`}
              onClick={() => setType("Route Table")}
            >
              Route Table
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Flow Logs" && "my_active"
              }`}
              onClick={() => setType("Flow Logs")}
            >
              Flow Logs
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Tags" && "my_active"
              }`}
              onClick={() => setType("Tags")}
            >
              Tags
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Network ACLs" && "my_active"
              }`}
              onClick={() => setType("Network ACLs")}
            >
              Network ACLs
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Sharing" && "my_active"
              }`}
              onClick={() => setType("Sharing")}
            >
              Sharing
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 account-detail-container-area">
          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Details" && "inaktif"
            }`}
          >
            <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow["AccountId"]}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Region</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Region}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Subnet Name</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {getArrayValue(selectedRow.Tags, -1, "Key", "Name", "Value")}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Subnet ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.SubnetId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">VPC ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.VpcId}
                </p>
              </div>

              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  IPv4 CIDR Block
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.CidrBlock}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Available IP Address Count
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.AvailableIpAddressCount}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Availability Zone
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.AvailabilityZone}
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Available Zone ID
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.AvailabilityZoneId}
                </p>
              </div>

              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Route Table</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {getArrayValue(selectedRow.RouteTable, 0, "RouteTableId")}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Network ACL</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.NetworkACL.NetworkAclId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Default Subnet for AZ
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {getBooleanValue(selectedRow, "DefaultForAz")}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  MapPublicIPOnLaunch
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {getBooleanValue(selectedRow, "MapPublicIpOnLaunch")}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Assign IPv6 Address on Creation
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {getBooleanValue(selectedRow, "AssignIpv6AddressOnCreation")}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Owner ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.OwnerId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">State</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.State}
                </p>
              </div>
            </div>
          </div>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "IPv6 Block Association Set" && "inaktif"
            }`}
          >
            <div className="d-flex flex-column">
              <table className="details-table">
                <thead>
                  <tr>
                    <th>CIDR Block</th>
                    <th>Association Id</th>
                    <th>CIDR Block State</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedRow.Ipv6CidrBlockAssociationSet &&
                    selectedRow.Ipv6CidrBlockAssociationSet.map((item) => (
                      <tr>
                        <td>{item.Ipv6CidrBlock}</td>
                        <td>{item.AssociationId}</td>
                        <td>
                          {item.Ipv6CidrBlockState &&
                            item.Ipv6CidrBlockState.State}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Route Table" && "inaktif"
            }`}
          >
            {selectedRow.RouteTable &&
              selectedRow.RouteTable.map((item) => (
                <table className="details-table">
                  <tbody>
                    <tr>
                      <th>Route Table</th>
                      <td>{item.RouteTableId}</td>
                    </tr>
                    <tr>
                      <th>Destination</th>
                      <th>Target</th>
                    </tr>
                    {item.Routes &&
                      item.Routes.map((subItem) => (
                        <>
                          <tr>
                            <td>
                              {subItem.DestinationCidrBlock ||
                                subItem.DestinationIpv6CidrBlock || 
                                subItem.DestinationPrefixListId}
                            </td>
                            <td>{subItem.GatewayId || 
                            subItem.TransitGatewayId ||
                            subItem.NetworkInterfaceId ||
                            subItem.NatGatewayId}</td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
              ))}
          </div>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Flow Logs" && "inaktif"
            }`}
          >
            <table className="details-table">
              <thead>
                <tr>
                  <th>Flow Log Id</th>
                  <th>Filter</th>
                  <th>Destination Type</th>
                  <th>Destination Name</th>
                  <th>IAM Role ARN</th>
                  <th>Maximum Aggregation Interval</th>
                  <th>Creation Time</th>
                  <th>Status</th>
                  <th>Log Line Format</th>
                </tr>
              </thead>
              <tbody>
                {selectedRow.FlowLogs &&
                  selectedRow.FlowLogs.map((item) => (
                    <tr>
                      <td>{item.FlowLogId}</td>
                      <td>{item.TrafficType}</td>
                      <td>{item.LogDestinationType}</td>
                      <td>{item.LogGroupName}</td>
                      <td>{item.DeliverLogsPermissionArn}</td>
                      <td>{item.MaxAggregationInterval}</td>
                      <td>{item.CreationTime}</td>
                      <td>{item.FlowLogStatus}</td>
                      <td>{item.LogFormat}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Tags" && "inaktif"
            }`}
          >
            <table className="details-table">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {selectedRow.Tags &&
                  selectedRow.Tags.map((item) => (
                    <tr>
                      <td>{item.Key}</td>
                      <td>{item.Value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Network ACLs" && "inaktif"
            }`}
          >
            <div className="col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Network ACL Id
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.NetworkACL &&
                    selectedRow.NetworkACL.NetworkAclId}
                </p>
              </div>
            </div>
            <div className="col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Inbound Rules</p>
              </div>
            </div>
            <table className="details-table">
              <thead>
                <tr>
                  <th>Rule #</th>
                  <th>Type</th>
                  <th>Protocol</th>
                  <th>Port Range/ICMP Types</th>
                  <th>Source</th>
                  <th>Allow/Deny</th>
                </tr>
              </thead>
              <tbody>
                {selectedRow.NetworkACL &&
                  selectedRow.NetworkACL.Entries &&
                  selectedRow.NetworkACL.Entries.filter(
                    (entry) => entry.Egress === false
                  ).map((entry) => (
                    <tr>
                      <td>{entry.RuleNumber}</td>
                      <td></td>
                      <td>{ `${entry.Protocol}` ===  "-1" ? "All" : `${entry.Protocol}`}</td>
                      <td>
                        {entry.PortRange ?
                          `${entry.PortRange.From} - ${entry.PortRange.To}` : "All"}
                      </td>
                      <td>{entry.CidrBlock}</td>
                      <td>{entry.RuleAction}</td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <div className="col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Outbound Rules</p>
              </div>
            </div>
            <table className="details-table">
              <thead>
                <tr>
                  <th>Rule #</th>
                  <th>Type</th>
                  <th>Protocol</th>
                  <th>Port Range/ICMP Types</th>
                  <th>Source</th>
                  <th>Allow/Deny</th>
                </tr>
              </thead>
              <tbody>
                {selectedRow.NetworkACL &&
                  selectedRow.NetworkACL.Entries &&
                  selectedRow.NetworkACL.Entries.filter(
                    (entry) => entry.Egress === true
                  ).map((entry) => (
                    <tr>
                      <td>{entry.RuleNumber}</td>
                      <td></td>
                      <td>{ `${entry.Protocol}` ===  "-1" ? "All" : `${entry.Protocol}`}</td>
                      <td>
                      {entry.PortRange ?
                          `${entry.PortRange.From} - ${entry.PortRange.To}` : "All"}
                      </td>
                      <td>{entry.CidrBlock}</td>
                      <td>{entry.RuleAction}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Sharing" && "inaktif"
            }`}
          >
           {/*  <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Resource Share ARN</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Sharing && selectedRow.Sharing.resourceShareArn}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Status</p>
                <p className="m-0 p-0 account-title-subcontent">
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Status Message</p>
                <p className="m-0 p-0 account-title-subcontent">
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Last Updated Time</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Sharing && selectedRow.Sharing.lastUpdatedTime}
                </p>
              </div>
            </div>
         */}
            <table className="details-table">
              <thead>
                <tr>
                  <th>Resource Share ARN</th>
                  <th>Status</th>
                  <th>Status Message</th>
                  <th>Last Updated Time</th>
                </tr>
              </thead>
              <tbody>
                { 
                 ( selectedRow  && selectedRow.Sharing ) ?
              <tr>
                  <td>{selectedRow.Sharing && selectedRow.Sharing.resourceShareArn}</td>
                  <td></td>
                  <td></td>
                  <td>{selectedRow.Sharing && selectedRow.Sharing.lastUpdatedTime}</td>
                </tr> :
            
                <tr><div className="d-flex align-items-center justify-content-center p-2 font-12">{"This resource is not part of any resource share."} </div> </tr>}
              </tbody>
            </table>
          
          </div>
        </div>
      </div>
    </div>
  );
};

const Navigations = () => {
  const { projectId } = useParams();
  return (
    <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.TGW + "/" + projectId}
    >
      <p className="m-0 p-0 mr-2 font-weight-bold">Transist Gateway</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
  );
};

const VPCSubnets = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "Subnet Name",
        accessor: (d) => getArrayValue(d.Tags, -1, "Key", "Name", "Value"),
      },
      {
        Header: "Subnet ID",
        accessor: "SubnetId",
      },
      {
        Header: "VPC ID",
        accessor: "VpcId",
      },
      {
        Header: "IPv4 CIDR Block",
        accessor: "CidrBlock",
      },
      {
        Header: "Available IP Address Count",
        accessor: "AvailableIpAddressCount",
      },
      {
        Header: "Availability Zone",
        accessor: "AvailabilityZone",
      },
      {
        Header: "Availability Zone ID",
        accessor: "AvailabilityZoneId",
      },
      {
        Header: "Route Table",
        accessor: (d) => getArrayValue(d.RouteTable, 0, "RouteTableId"),
      },
      {
        Header: "Network ACL",
        accessor: "NetworkACL.NetworkAclId",
      },
      {
        Header: "Default Subnet for AZ",
        accessor: (d) => getBooleanValue(d, "DefaultForAz"),
      },
      {
        Header: "MapPublicIPOnLaunch",
        accessor: (d) => getBooleanValue(d, "MapPublicIpOnLaunch"),
      },
      {
        Header: "Assign IPv6 Address on Creation",
        accessor: (d) => getBooleanValue(d, "AssignIpv6AddressOnCreation"),
      },
      {
        Header: "Owner ID",
        accessor: "OwnerId",
      },
      {
        Header: "State",
        accessor: "State",
      },
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "network",
    title: "Network",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_CENTERS,
    designRoute: ROUTES.DESIGN_NETWORK,
    SubMenu: SubMenu,
  });
};

export default VPCSubnets;
