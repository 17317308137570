import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "./DetailsTags";
import { getArraysArrayValue, getArrayValue } from "./DashboardHelper";

//const projectId = "google_1";
const dashboardName = "DHCP OptionsSets";

const SubMenu = () => {
  const { projectId } = useParams();

  return (
    <>
      <h3>VPCs</h3>
      <div className="otherTableTitles ">
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPC + "/" + projectId}>
            VPCs
          </Link>
        </h3>
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPC_SUBNETS + "/" + projectId}>
            Subnets
          </Link>
        </h3>
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPC_ROUTE_TABLES + "/" + projectId}>
            Route Tables
          </Link>
        </h3>
        <h3 className="font-15 activeTable">DHCP</h3>

        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPC_END_POINTS + "/" + projectId}>
            Endpoints
          </Link>
        </h3>
      </div>
    </>
  );
};

const Details = ({ close, selectedRow }) => {
  const [type, setType] = useState("Details");

  return (
    <div className="justify-content-center align-items-end account-detail">
      <div className="w-100 s-flex flex-column account-detail-area">
        <button className="exitAccount" onClick={() => close()}>
          <img src="../images/close.svg" />
        </button>
        <div className="">
          <ul className="account-detail-link m-0 p-0 d-inline-flex">
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Details" && "my_active"
              }`}
              onClick={() => setType("Details")}
            >
              Details
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Tags" && "my_active"
              }`}
              onClick={() => setType("Tags")}
            >
              Tags
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "VPCs" && "my_active"
              }`}
              onClick={() => setType("VPCs")}
            >
              VPCs
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 account-detail-container-area">
          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Details" && "inaktif"
            }`}
          >
            <div className="d-inline-flex">
              <div className="detailTableType-1">
                <div className="d-flex flex-column">
                  <p className="account-detail-subtitle-1">Account ID</p>
                  <p className="account-detail-subtitle-1">Region</p>
                  <p className="account-detail-subtitle-1">
                    DHCP Options Set Name
                  </p>
                  <p className="account-detail-subtitle-1">
                    DHCP Options Set ID
                  </p>
                  <p className="account-detail-subtitle-1">Owner Account ID</p>
                </div>

                <div className="d-flex flex-column">
                  <p className="account-title-subcontent">
                    {selectedRow.AccountId}
                  </p>
                  <p className="account-title-subcontent">
                    {selectedRow.Region}
                  </p>
                  <p className="account-title-subcontent">
                    {getArrayValue(
                      selectedRow.Tags,
                      -1,
                      "Key",
                      "Name",
                      "Value"
                    )}
                  </p>
                  <p className="account-title-subcontent">
                    {selectedRow.DhcpOptionsId}
                  </p>
                  <p className="account-title-subcontent">
                    {selectedRow.OwnerId}
                  </p>
                </div>
              </div>

              <div className="detailTableType-1">
                <div className="d-flex flex-column">
                  <p className="account-detail-subtitle-1">Domain Name</p>
                  <p className="account-detail-subtitle-1">
                    Domain Name Servers
                  </p>
                  <p className="account-detail-subtitle-1">NTP Servers</p>
                  <p className="account-detail-subtitle-1">
                    NetBIOS Name Servers
                  </p>
                  <p className="account-detail-subtitle-1">NetBIOS Node Type</p>
                </div>

                <div className="d-flex flex-column">
                  <p className="account-title-subcontent">
                    {getArraysArrayValue(
                      selectedRow.DhcpConfigurations,
                      "Key",
                      "domain-name"
                    )}
                  </p>
                  <p className="account-title-subcontent">
                    {getArraysArrayValue(
                      selectedRow.DhcpConfigurations,
                      "Key",
                      "domain-name-servers"
                    )}
                  </p>
                  <p className="account-title-subcontent"></p>
                  <p className="account-title-subcontent"></p>
                  <p className="account-title-subcontent"></p>
                </div>
              </div>
            </div>
          </div>

          <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "VPCs" && "inaktif"
            }`}
          >
            <table className="details-table">
              <thead>
                <tr>
                  <th>VPC Name</th>
                  <th>VPC ID</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const Navigations = () => {
  const { projectId } = useParams();
  return (
    <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.TGW + "/" + projectId}
    >
      <p className="m-0 p-0 mr-2 font-weight-bold">Transist Gateway</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
  );
};

const VPCDhcp = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "DHCP Options Set Name",
        accessor: (d) => getArrayValue(d.Tags, -1, "Key", "Name", "Value"),
      },
      {
        Header: "DHCP Options Set ID",
        accessor: "DhcpOptionsId",
      },
      {
        Header: "Owner Account Id",
        accessor: "OwnerId",
      },
      {
        Header: "Domain Name",
        accessor: (item) =>
          getArraysArrayValue(item.DhcpConfigurations, "Key", "domain-name"),
        id: "DomainName",
      },
      {
        Header: "Domain Name Servers",
        accessor: (item) =>
          getArraysArrayValue(
            item.DhcpConfigurations,
            "Key",
            "domain-name-servers"
          ),
        id: "DomainNameServers",
      },
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "network",
    title: "Network",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_CENTERS,
    designRoute: ROUTES.DESIGN_NETWORK,
    SubMenu: SubMenu,
  });
};

export default VPCDhcp;
