export const LANDING = "/";
export const CONTACT = "/contact";
export const CAREERS = "/careers";


export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/home";

export const FORGET_PASSWORD = "/reset-pwd";
export const VERIFY_CODE = "/verify-code";
export const CONFIRM_SIGNUP = "/confirm-signup";

export const PRELAUNCH = "/prelaunch";
export const CONFIGURE = "/configure";
export const BUILD = "/build";
export const VALIDATE = "/validate";
export const DESIGN = "/design";
export const PLAN = "/plan";

export const ORGANIZATIONAL_STRUCTURE = "/organizational-structure";
export const PLATFORM_ACCOUNTS = "/platform-accounts";
export const ENVIRONMENT_STRATEGY = "/environment-strategy";
export const WORKLOAD_ACCOUNTS = "/workload-accounts";
export const SANDBOX_ACCOUNTS = "/sandbox-accounts";
export const ORGANIZATIONAL_UNITS = "/organizational-units";
export const ACCOUNTS = "/accounts";
export const DESIGN_ACCOUNT_FRAMEWORK = "/account-framework-design";


/* export const OKTA_INTEGRATION = "/okta-integration";
export const ADFS_INTEGRATION = "/adfs-integration";
export const AWS_SSO_INTEGRATION = "/aws-sso-integration"; */
export const BASELINE_ROLES = "/baseline_roles";
export const SINGLE_SIGN_ON = "/single_sign_on";
export const PRIVILEGED_ACCESS_MANAGEMENT = "/privileged_access_mgmt";
export const IDENTITY_PROVIDERS = "/identity-providers";
export const IAM_ROLES = "/iam-roles";
export const DESIGN_IAM = "/iam-design";

export const DATA_CENTERS = "/data-centers";
export const AWS_ENVIRONMENT = "/aws-environment";
export const VPC_DESIGN = "/vpc-design";
export const VPC_FACTORY = "/vpc-factory";
export const INTER_VPC_CONNECTIVITY = "/inter-vpc-connectivity";
export const CROSS_REGION_CONNECTIVITY = "/cross-region-connectivity";
export const VPC_CONNECTIVITY = "/vpn-connectivity";
export const DIRECT_CONNECT_CONNECTIVITY = "/direct-connect-connectivity";
export const DESIGN_NETWORK = "/network-design";
export const VPC_SHAREDENDPOINTS = "/vpc-endpoints";
export const TRAFFIC_INSPECTION = "/traffic-inspection";

export const VPC = "/vpc";
export const VPC_SUBNETS = "/vpc-subnets"
export const VPC_ROUTE_TABLES = "/vpc-route-tables";
export const VPC_DHCP = "/vpc-dhcp";
export const VPC_END_POINTS = "/vpc-end-points";
export const TGW = "/tgw";
export const TGW_ATTACHMENT = "/tgw-attachment";
export const TGW_ROUTING_DOMAINS = "/tgw-routing-domains";
export const VPN = "/vpn";
export const VPN_DIRECT_CONNECT = "/vpn-direct-connect";
export const VPN_DIRECT_CONNECT_GATEWAY = "/vpn-direct-connect-gateway";
export const RESOURCE_SHARES_BY_ME = "/resource-shares-by-me";
export const RESOURCE_SHARES_WITH_ME = "/resource-shares-with-me";
export const TRAFFIC_MIRROR_TARGETS = "/traffic-mirror-targets";

export const NETWORK_FIREWALL_FIREWALLS = "/network-firewall-firewalls";
export const NETWORK_FIREWALL_POLICIES = "/network-firewall-policies";
export const NETWORK_FIREWALL_RULEGROUPS = "/network-firewall-rulegroups";

export const DATA_PROTECTION_ACM ="/data-protection-acm";
export const DATA_PROTECTION_BACKUP_PLAN ="/data-protection-backupplan";
export const DATA_PROTECTION_BACKUP_POLICY ="/data-protection-backuppolicy";
export const DATA_PROTECTION_BACKUP_VAULTS ="/data-protection-backupvaults";
export const DATA_PROTECTION_BACKUP_JOBS ="/data-protection-backupjobs";
export const DATA_PROTECTION_BACKUP_COPY_JOBS ="/data-protection-copyjobs";
export const DATA_PROTECTION_KEYS ="/data-protection-keys";
export const DATA_PROTECTION_KEY_STORES ="/data-protection-keystores";
export const DATA_PROTECTION_KEY_MANAGEMENT = "/data-protection-key-management";
export const DATA_PROTECTION_CERTIFICATE_MANAGEMENT = "/data-protection-certificate-management";
export const DATA_PROTECTION_BACKUP_ARCHIVAL = "/data-protection-backup";
export const DATA_PROTECTION_DATA_PRIVACY = "/data-privacy";
export const DATA_PROTECTION_DLP = "/data-protection-backup";
export const DESIGN_DATA_PROTECTION = "/data-protection-design";

export const SECURITY_COMPLIANCE_MANAGEMENT = "/compliance-management";
export const SECURITY_ENDPOINT_PROTECTION = "/endpoint-protection";
export const SECURITY_INTELLIGENT_THREAT_DETECTION = "/intelligent-threat-detection";
export const SECURITY_PERIMETER_PROTECTION = "/perimeter-protection";
export const SECURITY_SECRETS_MANAGEMENT = "/secrets-management";
export const SECURITY_SIEM = "/siem";
export const SECURITY_VULNERABILITY_MANAGEMENT = "/vulnerability-management";
export const SECURITY_HUB_MEMBERS = "/security-hub-members";
export const SECURITY_HUB_STANDARDS = "/security-hub-standards";
export const SECURITY_HUB_INTEGRATIONS = "/security-hub-integrations";
export const SECURITY_GUARDDUTY_MEMBERS = "/security-guardduty-members";
export const SECURITY_SECRETS_MANAGER = "/security-secrets-manager";
export const SECURITY_FIREWALL_SECURITY_POLICIES= "/security-firewallmgr-securitypolicies";
export const SECURITY_FIREWALL_APP_LISTS = "/security-firewallmgr-applists";
export const SECURITY_FIREWALL_PROTOCOL_LISTS = "/security-firewallmgr-protocollists";
export const DESIGN_SECURITY = "/security-design";

export const INFRASTRUCTURE_ADIntegration = "/adintegration";
export const INFRASTRUCTURE_IMAGEMANAGEMENT = "/image-management";
export const INFRASTRUCTURE_PATCHMANAGEMENT = "/patch-management";
export const INFRASTRUCTURE_PRIVATEDNS = "/private-dns";
export const INFRASTRUCTURE_VDISOLUTION = "/vdi-solution";
/*start todo:: temporarily mapped to adintegration, todo: updated these to corresponding pages*/
export const INFRASTRUCTURE_CONTAINER_PLATFORM = "/container-platform";
export const INFRASTRUCTURE_API_GATEWAY = "/adintegration";
export const INFRASTRUCTURE_SHARED_FILE_SYSTEM = "/adintegration";
export const INFRASTRUCTURE_SECURE_FILE_EXCHANGE = "/adintegration";
export const INFRASTRUCTURE_ENTERPRISE_SERVICE_BUS = "/adintegration";
export const INFRASTRUCTURE_MESSAGE_QUEUE = "/adintegration";
export const INFRASTRUCTURE_DATA_LAKE = "/adintegration";
/*end todo*/
export const INFRASTRUCTURE_DIRECTORIES = "/infra-directories";
export const INFRASTRUCTURE_SHARED_WITH_ME = "/infra-shared-with-me";
export const INFRASTRUCTURE_INBOUND_ENDPOINTS = "/infra-inbound-endpoints";
export const INFRASTRUCTURE_OUTBOUND_ENDPOINTS = "/infra-outbound-endpoints";
export const INFRASTRUCTURE_RESOLVER_RULES = "/infra-resolver-rules";
export const INFRASTRUCTURE_COMPONENTS = "/infra-components";
export const INFRASTRUCTURE_RECIPES = "/infra-recipes";
export const INFRASTRUCTURE_PIPELINES = "/infra-pipelines";
export const INFRASTRUCTURE_IMAGES = "/infra-images";
export const INFRASTRUCTURE_BASELINES = "/infra-baselines";
export const INFRASTRUCTURE_GROUPS = "/infra-groups";
export const INFRASTRUCTURE_MAINTENANCE_WINDOWS = "/infra-maintenance-windows";
export const INFRASTRUCTURE_WORKSPACES = "/infra-workspaces";
export const INFRASTRUCTURE_IP_ACCESS_CONTROLS = "/infra-ip-access-controls";
export const DESIGN_INFRASTRUCTURE = "/infrastructure-design";

export const GOVERNANCE_AWS_LIMITS_MONITOR = "/aws-limits-monitor";
export const GOVERNANCE_BUDGET_ENFORCEMENT = "/budget-enforcement";
export const GOVERNANCE_CONFIG_RULES = "/config-rules";
export const GOVERNANCE_SERVICE_CONTROL_POLICIES = "/service-control-policies";
export const GOVERNANCE_SERVICENOW_INTEGRATION = "/servicenow-integration";
export const GOVERNANCE_TAGGING_STRATEGY = "/tagging-strategy";
export const GOVERNANCE_SERVICE_CONTROL = "/governance-service-control";
export const GOVERNANCE_TAGGING = "/governance-tagging";
export const GOVERNANCE_RECORDERS = "/governance-recorders";
export const GOVERNANCE_RULES = "/governance-rules";
export const GOVERNANCE_AWS_BUDGETS = "/governance-aws-budgets";
export const GOVERNANCE_PORTFOLIOS = "/governance-portfolios";
export const GOVERNANCE_PRODUCTS = "/governance-products";
export const GOVERNANCE_FIN_OPS =  "/tagging-strategy";  //"/governance-finops";
export const DESIGN_GOVERNANCE = "/governance-design";

export const MONITORING_CLOUDWATCH_EVENTS = "/cloudwatch-events";
export const MONITORING_CENTRALIZED_LOGGING = "/centralized-logging";
export const MONITORING_LOG_ANALYTICS = "/log-analytics";
export const MONITORING_MONITORING_TOOLS_INTEGRATION = "/monitoring-tools-integration";
export const MONITORING_SERVICENOW_IM_INTEGRATION = "/servicenow-im-integration";
export const DESIGN_MONITORING = "/monitoring-design";

export const DIGITAL_DEV_PORTAL = "/digital-dev-portal";
export const DIGITAL_DEV_TOOLS = "/digital-dev-tools";
export const DIGITAL_STARTER_KITS = "/digital-starter-kits";


export const ADMIN_CLIENTS = "/admin/clients";
export const ADMIN_PROJECTS = "/admin/projects";
export const ADMIN_USERS = "/admin/users";
export const ADMIN_LOGS = "/logs";								  

export const USERS = "/users";
export const PROJECTS = "/projects";
export const CLIENTS = "/clients";

export const GCP_WORKLOAD_ACCOUNTS = "/gcp-workload_accounts";
export const GCP_PLATFORM_ACCOUNTS = "/gcp-platform-accounts";
export const GCP_ENVIRONMENT_STRATEGY = "/gcp-environment-strategy";
export const GCP_SANDBOX_ACCOUNTS = "/gcp-sandbox-accounts";
export const GCP_ORGANIZATIONAL_UNITS = "/gcp-organizational-units";
export const GCP_ACCOUNTS = "/gcp-accounts";
export const GCP_DESIGN_ACCOUNT_FRAMEWORK = "/gcp-account-framework-design";

export const GCP_PRELAUNCH = "/gcp-prelaunch";
export const GCP_CONFIGURE = "/gcp-configure";
export const GCP_BUILD = "/gcp-build";
export const GCP_VALIDATE= "/gcp-validate";
export const GCP_DESIGN = "/gcp-design";
export const GCP_PLAN= "/gcp-plan";

export const ALICLOUD_WORKLOAD_ACCOUNTS = "/alicloud-workload_accounts";
export const ALICLOUD_PLATFORM_ACCOUNTS = "/alicloud-platform-accounts";
export const ALICLOUD_ENVIRONMENT_STRATEGY = "/alicloud-environment-strategy";
export const ALICLOUD_SANDBOX_ACCOUNTS = "/alicloud-sandbox-accounts";
export const ALICLOUD_ORGANIZATIONAL_UNITS = "/alicloud-organizational-units";
export const ALICLOUD_ACCOUNTS = "/alicloud-accounts";
export const ALICLOUD_DESIGN_ACCOUNT_FRAMEWORK = "/alicloud-account-framework-design";

export const ALICLOUD_PRELAUNCH = "/alicloud-prelaunch";
export const ALICLOUD_CONFIGURE = "/alicloud-configure";
export const ALICLOUD_BUILD= "/alicloud-build";
export const ALICLOUD_VALIDATE= "/alicloud-validate";
export const ALICLOUD_DESIGN= "/alicloud-design";
export const ALICLOUD_PLAN= "/alicloud-plan";

export const AZURE_ORGANIZATIONAL_STRUCTURE = "/azure-organizational-structure";
export const AZURE_WORKLOAD_SUBSCRIPTIONS= "/azure-workload-subscriptions";
export const AZURE_PLATFORM_SUBSCRIPTIONS= "/azure-platform-subscriptions";
export const AZURE_SANDBOX_SUBSCRIPTIONS = "/azure-sandbox-subscriptions";
export const AZURE_ORGANIZATIONAL_UNITS = "/azure-organizational-units";
export const AZURE_SUBSCRIPTIONS= "/azure-subscriptions";
export const AZURE_DESIGN_SUBSCRIPTION_FRAMEWORK = "/azure-account-framework-design";

export const AZURE_PRELAUNCH = "/azure-prelaunch";
export const AZURE_CONFIGURE = "/azure-configure";
export const AZURE_BUILD = "/azure-build";
export const AZURE_VALIDATE = "/azure-validate";
export const AZURE_DESIGN = "/azure-design";
export const AZURE_PLAN = "/azure-plan";
