import React from "react";
import * as ROUTES from "../../../constants/routes";

const PrelaunchComponent = ({ projectId, prelaunchData, setErrorMsg }) => {
  const getClassName = (key) => {
    if (prelaunchData) {
      let className;

      switch (prelaunchData[key].status) {
        case "NA":
          className = "class-na";
          break;
        case "FAIL":
          className = "class-failed";
          break;
        case "PASS":
          className = "class-success";
          break;
        case "NODATA":
          className = "noDataInput";
          break;
        default:
          className = "class-loading";
          break;
      }

      return className;
    } else {
      return "build-loading";
    }
  };

  const onClick = (key) => {
    if (prelaunchData[key].status === "FAIL") {
      setErrorMsg(prelaunchData[key].message);
    }
  };

  const getGrayText = (key) => {
    return prelaunchData && prelaunchData[key].status === "NA"
      ? "text-color-gray"
      : "";
  };

  return (
    <>
      <div className="mainContentArea preLaunchArea row px-2 py-4 mt-2">
       
      <div className="row">
      <div className="col-xl-6 col-lg-6 col-12 p-0">
        <div className="col-md-6 col-12 d-flex flex-column">
          <div className="row">
          <div className="col-12 d-flex flex-column ">
              <div className="build__box-head p-relative">
                <div>
                  <img
                    src="../images/icons/icon-account-framework.svg"
                    alt=""
                  ></img>
                  <h3 className="font-15 px-3">
                    Account Framework
                  </h3>
                </div>
                 </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("organizationalStructure")}
                  onClick={() => onClick("organizationalStructure")}
                ></span>
                <a
                  href={`${ROUTES.ORGANIZATIONAL_STRUCTURE}/${projectId}`}
                  className="font-12"
                >
                  Organizational Structure
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("platformAccounts")}
                  onClick={() => onClick("platformAccounts")}
                ></span>
                <a
                  href={`${ROUTES.PLATFORM_ACCOUNTS}/${projectId}`}
                  className="font-12"
                >
                  Platform Accounts
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("environmentStrategy")}
                  onClick={() => onClick("environmentStrategy")}
                ></span>
                <a
                  href={`${ROUTES.ENVIRONMENT_STRATEGY}/${projectId}`}
                  className="font-12"
                >
                  Envionment Strategy
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("workloadAccounts")}
                  onClick={() => onClick("workloadAccounts")}
                ></span>
                <a
                  href={`${ROUTES.WORKLOAD_ACCOUNTS}/${projectId}`}
                  className="font-12"
                >
                  Workload Accounts
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("sandboxAccounts")}
                  onClick={() => onClick("sandboxAccounts")}
                ></span>
                <a
                  href={`${ROUTES.SANDBOX_ACCOUNTS}/${projectId}`}
                  className="font-12"
                >
                  Sandbox Accounts
                </a>
              </div>
            </div>
           
            <div className="col-12 d-flex flex-column">
              <div className="build__box-head p-relative">
                <div>
                  <img
                    src="../images/icons/icon-infrastructure.svg"
                    alt=""
                  ></img>
                  <h3 className="font-15 px-3">
                    Infrastructure
                  </h3>
                </div>
                  </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("activeDirectory")}
                  onClick={() => onClick("activeDirectory")}
                ></span>
                <a
                  href={`${ROUTES.INFRASTRUCTURE_ADIntegration}/${projectId}`}
                  className="font-12"
                >
                  Active Directory Integration
                </a>
              </div>

              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("imageManagement")}
                  onClick={() => onClick("imageManagement")}
                ></span>
                <a
                  href={`${ROUTES.INFRASTRUCTURE_IMAGEMANAGEMENT}/${projectId}`}
                  className="font-12"
                >
                  Image Management
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("patchManagement")}
                  onClick={() => onClick("patchManagement")}
                ></span>
                <a
                  href={`${ROUTES.INFRASTRUCTURE_PATCHMANAGEMENT}/${projectId}`}
                  className="font-12"
                >
                  Patch Management
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("vdiSolution")}
                  onClick={() => onClick("vdiSolution")}
                ></span>
                <a
                  href={`${ROUTES.INFRASTRUCTURE_VDISOLUTION}/${projectId}`}
                  className="font-12"
                >
                  VDI Solution
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("containerPlatform")}
                  onClick={() => onClick("containerPlatform")}
                ></span>
                <a
                  href={`${ROUTES.INFRASTRUCTURE_CONTAINER_PLATFORM}/${projectId}`}
                  className="font-12"
                >
                  Container Platform
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <a className="class-na"></a>
                <a
                  href={`${ROUTES.INFRASTRUCTURE_API_GATEWAY}/${projectId}`}
                  className="font-12"
                >
                  API Gateway
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <a className="class-na"></a>
                <a
                  href={`${ROUTES.INFRASTRUCTURE_SHARED_FILE_SYSTEM}/${projectId}`}
                  className="font-12"
                >
                  Shared File System
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <a className="class-na"></a>
                <a
                  href={`${ROUTES.INFRASTRUCTURE_SECURE_FILE_EXCHANGE}/${projectId}`}
                  className="font-12"
                >
                  Secure File Exchange
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <a className="class-na"></a>
                <a
                  href={`${ROUTES.INFRASTRUCTURE_ENTERPRISE_SERVICE_BUS}/${projectId}`}
                  className="font-12"
                >
                  Enterprise Service Bus
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <a className="class-na"></a>
                <a
                  href={`${ROUTES.INFRASTRUCTURE_MESSAGE_QUEUE}/${projectId}`}
                  className="font-12"
                >
                  Message Queue
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <a className="class-na"></a>
                <a
                  href={`${ROUTES.INFRASTRUCTURE_DATA_LAKE}/${projectId}`}
                  className="font-12"
                >
                  Data Lake
                </a>
              </div>
            </div>
         
           
            </div>
        </div>
        <div className="col-md-6 col-12 flex-column d-flex">
          <div className="row">
          <div className="col-12 d-flex flex-column">
              <div className="build__box-head p-relative">
                <div>
                  <img
                    src="../images/icons/icon-identity-access.svg"
                    alt=""
                  ></img>
                  <h3 className="font-15 px-3">
                    Identity & Access
                  </h3>
                </div>
              
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("baselineRoles")}
                  onClick={() => onClick("baselineRoles")}
                ></span>
                <a
                  href={`${ROUTES.BASELINE_ROLES}/${projectId}`}
                  className="font-12"
                >
                  Baseline Roles
                </a>
              </div>

              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("singleSignOn")}
                  onClick={() => onClick("singleSignOn")}
                ></span>
                <a
                  href={`${ROUTES.SINGLE_SIGN_ON}/${projectId}`}
                  className="font-12"
                >
                  Single Sign-On
                </a>
              </div>

              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("pam")}
                  onClick={() => onClick("pam")}
                ></span>
                <a
                  href={`${ROUTES.PRIVILEGED_ACCESS_MANAGEMENT}/${projectId}`}
                  className="font-12"
                >
                  Privileged Access
                </a>
              </div>
            </div>
            <div className="col-12 d-flex flex-column">
              <div className="build__box-head p-relative">
                <div>
                  <img
                    src="../images/icons/icon-governance.svg"
                    alt=""
                  ></img>
                  <h3 className="font-15 px-3">Governance</h3>
                </div>
              
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("taggingStrategy")}
                  onClick={() => onClick("taggingStrategy")}
                ></span>
                <a
                  href={`${ROUTES.GOVERNANCE_TAGGING_STRATEGY}/${projectId}`}
                  className="font-12"
                >
                  Tagging Strategy
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("serviceControlPolicy")}
                  onClick={() => onClick("serviceControlPolicy")}
                ></span>
                <a
                  href={`${ROUTES.GOVERNANCE_SERVICE_CONTROL_POLICIES}/${projectId}`}
                  className="font-12"
                >
                  Service Contol Polices
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("configRules")}
                  onClick={() => onClick("configRules")}
                ></span>
                <a
                  href={`${ROUTES.GOVERNANCE_CONFIG_RULES}/${projectId}`}
                  className="font-12"
                >
                  Config Rules
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("budgetEnforcement")}
                  onClick={() => onClick("budgetEnforcement")}
                ></span>
                <a
                  href={`${ROUTES.GOVERNANCE_BUDGET_ENFORCEMENT}/${projectId}`}
                  className="font-12"
                >
                  Budget Enforcement
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("awsLimitsMonitor")}
                  onClick={() => onClick("awsLimitsMonitor")}
                ></span>
                <a
                  href={`${ROUTES.GOVERNANCE_AWS_LIMITS_MONITOR}/${projectId}`}
                  className="font-12"
                >
                  AWS Limits Monitor
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("serviceNowIntegration")}
                  onClick={() => onClick("serviceNowIntegration")}
                ></span>
                <a
                  href={`${ROUTES.GOVERNANCE_SERVICENOW_INTEGRATION}/${projectId}`}
                  className="font-12"
                >
                  ServiceNow Integration
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <a className="class-na"></a>
                <a
                  href={`${ROUTES.GOVERNANCE_FIN_OPS}/${projectId}`}
                  className="font-12"
                >
                  FinOps
                </a>
              </div>
            </div>
            <div className="col-12 d-flex flex-column">
              <div className="build__box-head p-relative">
                <div>
                  <img
                    src="../images/icons/icon-monitoring.svg"
                    alt=""
                  ></img>
                  <h3 className="font-15 px-3">Monitoring</h3>
                </div>
                 </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                      className={getClassName("cloudthwatchEvents")}
                      onClick={() => onClick("cloudthwatchEvents")}
                    ></span>
                <a
                  href={`${ROUTES.MONITORING_CLOUDWATCH_EVENTS}/${projectId}`}
                  className="font-12"
                >
                  CloudWatch Event Rules
                </a>
              </div>

              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                      className={getClassName("centralizedLogging")}
                      onClick={() => onClick("centralizedLogging")}
                    ></span>
                <a
                  href={`${ROUTES.MONITORING_CENTRALIZED_LOGGING}/${projectId}`}
                  className="font-12"
                >
                  Centralized Logging
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                      className={getClassName("logAnalytics")}
                      onClick={() => onClick("logAnalytics")}
                    ></span>
                <a
                  href={`${ROUTES.MONITORING_LOG_ANALYTICS}/${projectId}`}
                  className="font-12"
                >
                  Log Analytics
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                      className={getClassName("monitoringTools")}
                      onClick={() => onClick("monitoringTools")}
                    ></span>
                <a
                  href={`${ROUTES.MONITORING_MONITORING_TOOLS_INTEGRATION}/${projectId}`}
                  className="font-12"
                >
                  Monitoring Tools Integration
                </a>
              </div>
            </div>
       
         </div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-12 p-0">
        <div className="col-md-6 col-12 flex-column d-flex">
          <div className="row">
          <div className="col-12 d-flex flex-column">
              <div className="build__box-head p-relative">
                <div>
                  <img
                    src="../images/icons/icon-network.svg"
                    alt=""
                  ></img>
                  <h3 className="font-15 px-3">Network</h3>
                </div>
              </div>

              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("dataCenters")}
                  onClick={() => onClick("dataCenters")}
                ></span>
                <a
                  href={`${ROUTES.DATA_CENTERS}/${projectId}`}
                  className="font-12"
                >
                  Data Centers
                </a>
              </div>

              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                  className={getClassName("awsEnvironment")}
                  onClick={() => onClick("awsEnvironment")}
                ></span>
                <a
                  href={`${ROUTES.AWS_ENVIRONMENT}/${projectId}`}
                  className="font-12"
                >
                  AWS Environment
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("vpcDesign")}
                  onClick={() => onClick("vpcDesign")}
                ></span>
                <a
                  href={`${ROUTES.VPC_DESIGN}/${projectId}`}
                  className="font-12"
                >
                  VPC Design
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("vpcFactory")}
                  onClick={() => onClick("vpcFactory")}
                ></span>
                <a
                  href={`${ROUTES.VPC_FACTORY}/${projectId}`}
                  className="font-12"
                >
                  VPC Factory
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("ipam")}
                  onClick={() => onClick("ipam")}
                ></span>
                <a
                  href={`${ROUTES.VPC_FACTORY}/${projectId}`}
                  className="font-12"
                >
                  IPAM
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("interVpcConnectivity")}
                  onClick={() => onClick("interVpcConnectivity")}
                ></span>
                <a
                  href={`${ROUTES.INTER_VPC_CONNECTIVITY}/${projectId}`}
                  className="font-12"
                >
                  Inter VPC Connectivity
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("crossRegionConnectivity")}
                  onClick={() => onClick("crossRegionConnectivity")}
                ></span>
                <a
                  href={`${ROUTES.CROSS_REGION_CONNECTIVITY}/${projectId}`}
                  className="font-12"
                >
                  Cross-region Connectivity
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("vpnConnectivity")}
                  onClick={() => onClick("vpnConnectivity")}
                ></span>
                <a
                  href={`${ROUTES.VPC_CONNECTIVITY}/${projectId}`}
                  className="font-12"
                >
                  VPN Connectivity
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("directConnect")}
                  onClick={() => onClick("directConnect")}
                ></span>
                <a
                  href={`${ROUTES.DIRECT_CONNECT_CONNECTIVITY}/${projectId}`}
                  className="font-12"
                >
                  DirectConnect
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("trafficInspection")}
                  onClick={() => onClick("trafficInspection")}
                ></span>
                <a
                  href={`${ROUTES.TRAFFIC_INSPECTION}/${projectId}`}
                  className="font-12"
                >
                  Traffic Inspection
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("vpcEndpoints")}
                  onClick={() => onClick("vpcEndpoints")}
                ></span>
                <a
                  href={`${ROUTES.VPC_SHAREDENDPOINTS}/${projectId}`}
                  className="font-12"
                >
                  Shared VPC Endpoints
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("privatedns")}
                  onClick={() => onClick("privatedns")}
                ></span>
                <a
                  href={`${ROUTES.INFRASTRUCTURE_PRIVATEDNS}/${projectId}`}
                  className="font-12"
                >
                  Private DNS
                </a>
              </div>
         </div>
         <div className="col-12 d-flex flex-column">
            <div className="build__box-head p-relative">
              <div>
                <img
                  src="../images/icons/icon-data-protection.svg"
                  alt=""
                ></img>
                <h3 className="font-15 px-3">
                  Data Protection
                </h3>
              </div>
             
            </div>

            <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("backupArchival")}
                  onClick={() => onClick("backupArchival")}
                ></span>
                <a
                  href={`${ROUTES.DATA_PROTECTION_BACKUP_ARCHIVAL}/${projectId}`}
                  className="font-12"
                >
                  Backup & Archival
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("certManagement")}
                  onClick={() => onClick("certManagement")}
                ></span>
                <a
                  href={`${ROUTES.DATA_PROTECTION_CERTIFICATE_MANAGEMENT}/${projectId}`}
                  className="font-12"
                >
                  Certificate Management
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("keyManagement")}
                  onClick={() => onClick("keyManagement")}
                ></span>
                <a
                  href={`${ROUTES.DATA_PROTECTION_KEY_MANAGEMENT}/${projectId}`}
                  className="font-12"
                >
                  Key Management
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("dataPrivacy")}
                  onClick={() => onClick("dataPrivacy")}
                ></span>
                <a
                  href={`${ROUTES.DATA_PROTECTION_DATA_PRIVACY}/${projectId}`}
                  className="font-12"
                >
                  Data Privacy
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <a className="class-na"></a>
                <a
                  href={`${ROUTES.DATA_PROTECTION_DLP}/${projectId}`}
                  className="font-12"
                >
                  Data Loss Prevention
                </a>
              </div>
          </div>
      
         </div>
        </div>
 
        <div className="col-md-6 col-12 d-flex flex-column">
          <div className="row">
          <div className="col-12 d-flex flex-column">
              <div className="build__box-head p-relative">
                <div>
                  <img
                    src="../images/icons/icon-security.svg"
                    alt=""
                  ></img>
                  <h3 className="font-15 px-3">Security</h3>
                </div>
                 </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                      className={getClassName("complianceManagement")}
                      onClick={() => onClick("complianceManagement")}
                    ></span>
                <a
                  href={`${ROUTES.SECURITY_COMPLIANCE_MANAGEMENT}/${projectId}`}
                  className="font-12"
                >
                  Compliance Management
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                      className={getClassName("intelThreatDetection")}
                      onClick={() => onClick("intelThreatDetection")}
               ></span>
                <a
                  href={`${ROUTES.SECURITY_INTELLIGENT_THREAT_DETECTION}/${projectId}`}
                  className="font-12"
                >
                  Intelligent Threat Detection
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                className={getClassName("vulnerabilityManagement")}
                onClick={() => onClick("vulnerabilityManagement")}
              ></span>
                <a
                  href={`${ROUTES.SECURITY_VULNERABILITY_MANAGEMENT}/${projectId}`}
                  className="font-12"
                >
                  Vulnerability Management
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                    <span
                      className={getClassName("endpointProtection")}
                      onClick={() => onClick("endpointProtection")}
                    ></span>
                <a
                  href={`${ROUTES.SECURITY_ENDPOINT_PROTECTION}/${projectId}`}
                  className="font-12"
                >
                  Endpoint Protection
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span className={getClassName("secretsManagement")}
                      onClick={() => onClick("secretsManagement")}
                    ></span>
                <a
                  href={`${ROUTES.SECURITY_SECRETS_MANAGEMENT}/${projectId}`}
                  className="font-12"
                >
                  Secrets Management
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span className={getClassName("perimeterProtection")}
                      onClick={() => onClick("perimeterProtection")}
                    ></span>
                <a
                  href={`${ROUTES.SECURITY_PERIMETER_PROTECTION}/${projectId}`}
                  className="font-12"
                >
                  Perimeter Protection
                </a>
              </div>

              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <span
                      className={getClassName("siem")}
                      onClick={() => onClick("siem")}
                    ></span>
                <a
                  href={`${ROUTES.SECURITY_SIEM}/${projectId}`}
                  className="font-12"
                >
                  SIEM
                </a>
              </div>
            </div>
            <div className="col-12 d-flex flex-column">
              <div className="build__box-head p-relative">
                <div>
                  <img
                    src="../images/icons/icon-digital.svg"
                    alt=""
                  ></img>
                  <h3 className="font-15 px-3">Digital</h3>
                </div>
              </div>

              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <a className="class-na"></a>
           
                <a
                  href={`${ROUTES.DIGITAL_DEV_PORTAL}/${projectId}`}
                  className="font-12"
                >
                  Developer Portal
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <a className="class-na"></a>
                <a
                  href={`${ROUTES.DIGITAL_DEV_TOOLS}/${projectId}`}
                  className="font-12"
                >
                  Developer Tools
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
              <a className="class-na"></a>
                <a
                  href={`${ROUTES.DIGITAL_STARTER_KITS}/${projectId}`}
                  className="font-12"
                >
                  Starter Kits
                </a>
              </div>
            </div>
          
             </div>
      </div>
    </div>
  </div>
 </div>      
    </>
  );
};

export default PrelaunchComponent;
