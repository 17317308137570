import { useEffect, useState } from "react";
import useAwsAmplify from "./useAwsAmplify";
import $ from "jquery";

const useProjectDisabledStatus = (projectId, disabledStatuses) => {
  const awsAmplify = useAwsAmplify();
  const [disabled, setDisabled] = useState(false);

  const disabledProjectStatuses = ["Launching", "Deploying"];

  useEffect(() => {
    const fetch = async () => {
      const results = await awsAmplify.getMyProjectStatusAndLocked(projectId);

      const project = results?.data?.getProjectByID?.project;

      let disabled = disabledProjectStatuses.includes(project.ProjectStatus);

      if(!disabled){
        disabled = project.Locked === "yes";
      }

      setDisabled(disabled);
    };

    fetch();
  }, [projectId]);

  return disabled;
};

export default useProjectDisabledStatus;
