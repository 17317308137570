import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "./DetailsTags";
import {getArrayValue} from "./DashboardHelper";

//const projectId = "google_1";
const dashboardName = "VPC RouteTables";

const SubMenu = () => {
  const { projectId } = useParams();

  return (
    <>
      <h3>VPCs</h3>
      <div className="otherTableTitles ">
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPC + "/" + projectId}>
            VPCs
          </Link>
        </h3>
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPC_SUBNETS + "/" + projectId}>
            Subnets
          </Link>
        </h3>
        <h3 className="font-15 activeTable">Route Tables</h3>

        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPC_DHCP + "/" + projectId}>
            DHCP
          </Link>
        </h3>
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPC_END_POINTS + "/" + projectId}>
            Endpoints
          </Link>
        </h3>
      </div>
    </>
  );
};

const Details = ({ close, selectedRow }) => {
  const [type, setType] = useState("Details");

  return (
    <div className="justify-content-center align-items-end account-detail">
      <div className="w-100 s-flex flex-column account-detail-area">
        <button className="exitAccount" onClick={() => close()}>
          <img src="../images/close.svg" />
        </button>
        <div className="">
          <ul className="account-detail-link m-0 p-0 d-inline-flex">
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Details" && "my_active"
              }`}
              onClick={() => setType("Details")}
            >
              Details
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Routes" && "my_active"
              }`}
              onClick={() => setType("Routes")}
            >
              Routes
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Subnet Associations" && "my_active"
              }`}
              onClick={() => setType("Subnet Associations")}
            >
              Subnet Associations
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Edge Associations" && "my_active"
              }`}
              onClick={() => setType("Edge Associations")}
            >
              Edge Associations
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Route Propagation" && "my_active"
              }`}
              onClick={() => setType("Route Propagation")}
            >
              Route Propagation
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Tags" && "my_active"
              }`}
              onClick={() => setType("Tags")}
            >
              Tags
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 account-detail-container-area">
          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Details" && "inaktif"
            }`}
          >
            <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.AccountId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Region</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Region}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Route Table Name
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {getArrayValue(selectedRow.Tags, -1, "Key", "Name", "Value")}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Route Table ID
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.RouteTableId}
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Owner Account Id
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.OwnerId}
                </p>
              </div>

              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Explicitly Associated with
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {getArrayValue(selectedRow.Associations, 0, "SubnetId")}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Main</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {getArrayValue(selectedRow.Associations, 0, "Main")}
                </p>
              </div>

              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">VPC ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.VpcId}
                </p>
              </div>
            </div>
          </div>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Routes" && "inaktif"
            }`}
          >
            <div className="d-flex flex-column">
              <table className="details-table">
                <thead>
                  <tr>
                    <th className="font-15">Routes</th>
                  </tr>
                  <tr>
                    <th>Destination</th>
                    <th>Target</th>
                    <th>Status</th>
                    <th>Origin</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedRow.Routes &&
                    selectedRow.Routes.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.DestinationCidrBlock ||
                             item.DestinationIpv6CidrBlock ||
                             item.DestinationPrefixListId}</td>
                        <td>
                          {item.GatewayId ||
                            item.TransitGatewayId ||
                            item.NetworkInterfaceId ||
                            item.NatGatewayId}
                        </td>
                        <td>{item.State}</td>
                        <td>{item.Origin}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Subnet Associations" && "inaktif"
            }`}
          >
            <table className="details-table">
              <thead>
                <tr>
                  <th>Subnet ID</th>
                  <th>Association State</th>
                </tr>
              </thead>
              <tbody>
                {selectedRow.Associations &&
                  selectedRow.Associations.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item.SubnetId}</td>
                      <td>
                        {item.SubnetId && item.AssociationState && item.AssociationState.State}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Edge Associations" && "inaktif"
            }`}
          >
            <table className="details-table">
              <thead>
                <tr>
                  <th>IGW ID</th>
                  <th>Association State</th>
                </tr>
              </thead>
              <tbody>
                {selectedRow.Associations ? 
                  selectedRow.Associations.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item.GatewayId}</td>
                      <td>
                        {item.GatewayId && item.AssociationState && item.AssociationState.State}
                      </td>
                    </tr>
                  )) : 
                  <tr><div className="d-flex align-items-center justify-content-center p-2 font-12">{"You do not have any edge associations."} </div> </tr>}
              </tbody>
            </table>
          </div>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Route Propagation" && "inaktif"
            }`}
          >
            <table className="details-table">
              <thead>
                <tr>
                  <th>VGW</th>
                  <th>Propagate</th>
                </tr>
              </thead>
              <tbody>
                {selectedRow.Associations &&
                  selectedRow.Associations.map((item, idx) => (
                    <tr key={idx}>
                      <td></td>
                      <td></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>
        </div>
      </div>
    </div>
  );
};

const Navigations = () => {
  const { projectId } = useParams(); return (
  <Link
    className="btn-post btn-animation d-inline-flex"
    to={ROUTES.TGW + "/" + projectId}
  >
    <p className="m-0 p-0 mr-2 font-weight-bold">Transist Gateway</p>
    <img src="../images/ribbon-designRight.svg" />
  </Link>
)};

const VPCRouteTables = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "Route Table Name",
        accessor: (d) => getArrayValue(d.Tags, -1, "Key", "Name", "Value"),
      },
      {
        Header: "Route Table ID",
        accessor: "RouteTableId",
      },
      {
        Header: "Owner Account Id",
        accessor: "OwnerId",
      },
      {
        Header: "Explicitly Associated with",
        accessor: (d) => getArrayValue(d.Associations, 0, "SubnetId"),
      },
      {
        Header: "Main",
        accessor: (d) => getArrayValue(d.Associations, 0, "Main"),
      },
      {
        Header: "VPC ID",
        accessor: "VpcId",
      },
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "network",
    title: "Network",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_CENTERS,
    designRoute: ROUTES.DESIGN_NETWORK,
    SubMenu: SubMenu,
  });
};

export default VPCRouteTables;
