import React, { useEffect, useState } from "react";
import update from "immutability-helper";
import axios from "axios";

import useAwsAmplify from "../../hooks/useAwsAmplify";
import {
  validate2to32AlphaNumeric,
  validateDomainName,
} from "../../helpers/validator";
import { getError } from "../../helpers/errorHelper";

const FILE_SIZE_LIMIT_IN_BYTE = 2000000;

const lookupObj = {
  clientName: "Client Name",
  domainName: "Domain Name",
};

const ClientForm = ({ close, client }) => {
  const awsAmplify = useAwsAmplify();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    clientName: {
      value: client ? client.ClientName : "",
      isValid: client ? true : false,
    },
    domainName: {
      value: client ? client.DomainName : "",
      isValid: client ? true : false,
    },
    enableCodeDeploy: client ? client.EnableCodeDeploy : "yes",
    enableDocAccess: client ? client.EnableDocAccess : "yes",
    selectedClientAdminUserID: {
      value: "",
    },
    clientAdminUserIDs: client ? client.ClientAdminUserIDs : [],
    domainUsers: client
      ? client.DomainUsers.filter(
          (du) =>
            client.ClientAdminUserIDs &&
            client.ClientAdminUserIDs.indexOf(du.UserID) === -1
        )
      : [],
  });
  const [logo, setLogo] = useState(null);
  const [previewImageUrl, setPreviewImageUrl] = useState(
    client ? client.Logo : ""
  );
  const [isDirty, setIsDirty] = useState(false);
  const [domainName, setDomainName] = useState(null);
  const [domainUsers, setDomainUsers] = useState(
    client ? client.DomainUsers : []
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    const errorMsgs = [];

    for (const prop in formData) {
      if (formData[prop].hasOwnProperty("isValid") && !formData[prop].isValid) {
        if (formData[prop].errorMsg) {
          errorMsgs.push(formData[prop].errorMsg);
        } else {
          errorMsgs.push(`${lookupObj[prop]} must be provided`);

          setFormData((state) =>
            update(state, {
              [prop]: {
                errorMsg: {
                  $set: errorMsg
                    ? `${lookupObj[prop]} ${errorMsg}`
                    : `${lookupObj[prop]} must be provided`,
                },
              },
            })
          );
        }
      }
    }

    if (errorMsgs.length > 0) {
      setErrorMsg(errorMsgs);
      return;
    }

    try {
      setIsLoading(true);

      let clientId;

      if (client) {
        clientId = client.ClientID;

        const result = await awsAmplify.updateClient(
          clientId,
          formData.clientName.value,
          formData.enableDocAccess,
          formData.enableCodeDeploy,
          formData.clientAdminUserIDs,
          client.ExistingClientAdminUserIDs
        );
      } else {
        const result = await awsAmplify.createClient(
          formData.clientName.value,
          formData.domainName.value,
          formData.enableCodeDeploy,
          formData.enableDocAccess,
          formData.clientAdminUserIDs
        );

        clientId = result.data.createClient;
      }

      if (logo && logo.size < FILE_SIZE_LIMIT_IN_BYTE) {
        const initiateUploadResult = await awsAmplify.initiateLogoUpload(
          `${clientId}.${logo.name.split(".").pop()}`,
          logo.type
        );

        const uploadResult = await axios.put(
          initiateUploadResult.data.initiateLogoUpload,
          logo,
          {
            headers: {
              "Content-Type": logo.type,
            },
          }
        );

        if (uploadResult.status === 200) {
          await awsAmplify.updateClientAccess(
            clientId,
            "Logo",
            `${clientId}.${logo.name.split(".").pop()}`
          );

          await awsAmplify.log("Updated Client Logo", `New Logo File Name ${logo.name}`, clientId);
        }
      }

      setIsLoading(false);
      setIsDirty(false);

      close(true, "create", `Successfully ${client? "updated" : "added"} the client`);
    } catch (err) {
      console.log(err);
      setIsLoading(false);

      const errorMsg = getError(err);
      if (errorMsg === "Client doesn't exist") {
        close(true, "create", null, errorMsg);
      }
      else{
        setErrorMsg(errorMsg);
      }
    }
  };

  const convertBEToFE = (data) => {};

  const convertFEToBE = () => {};

  const onFieldChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [name]: { value: { $set: value } } })
    );
  };

  const onFieldBlur = (validateFunc, event) => {
    const value = event.target.value;
    const name = event.target.name;

    let errorMsg;

    if (value === "") {
      errorMsg = "must be provided";
    } else {
      errorMsg = validateFunc(value);
    }

    setFormData((state) =>
      update(state, {
        [name]: {
          isValid: { $set: errorMsg === undefined },
          errorMsg: {
            $set: errorMsg ? `${lookupObj[name]} ${errorMsg}` : errorMsg,
          },
        },
      })
    );

    if (errorMsg) {
      setErrorMsg(`${lookupObj[name]} ${errorMsg}`);
    }

    return errorMsg === undefined;
  };

  useEffect(() => {
    const fetchDomainUsers = async (domainName) => {
      try {
        setErrorMsg(null);
        setIsLoading(true);
        const results = await awsAmplify.findUsersByDomainName(domainName);
        setIsLoading(false);

        if (results.data && results.data.findUsersByDomainName) {
          setDomainUsers(results.data.findUsersByDomainName);

          setFormData((state) =>
            update(state, {
              domainUsers: { $set: results.data.findUsersByDomainName },
              clientAdminUserIDs: {$set: []}
            })
          );
        }
      } catch (err) {
        setIsLoading(false);
        getError(err, setErrorMsg);
      }
    };

    if (domainName) {
      fetchDomainUsers(domainName);
    }
  }, [domainName]);

  const addClientAdmin = (userId) => {
    const exists = (value) => {
      return formData.clientAdminUserIDs.indexOf(value) !== -1;
    };

    if (userId) {
      if (!exists(userId)) {
        setFormData((state) => {
          let idx = -1;

          state.domainUsers.forEach((du, duIdx) => {
            if (du.UserID === userId) {
              idx = duIdx;
            }
          });

          console.log(`idx: ${idx}`);

          return update(state, {
            clientAdminUserIDs: { $push: [userId] },
            domainUsers: { $splice: [[idx, 1]] },
          });
        });
      }
    }
  };

  const removeClientAdmin = (userId, email) => {
    setFormData((state) =>
      update(state, {
        clientAdminUserIDs: {
          $splice: [[state.clientAdminUserIDs.indexOf(userId), 1]],
        },
        domainUsers: {
          $push: [{ UserID: userId, EmailID: email }],
        },
        selectedClientAdminUserID: {
          value: {
            $set: "",
          },
        },
      })
    );
  };

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong>
              {Array.isArray(errorMsg)
                ? errorMsg.map((em) => (
                    <>
                      <span>{em}</span>
                    </>
                  ))
                : errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="justify-content-center align-items-center popupArea add-new-client">
        <div className="auth-main">
          <form className="auth-form" onSubmit={onSubmit}>
            <button
              className="exit btn-animation"
              onClick={() => close(false, "create")}
            >
              Exit
            </button>
            <div className="title">{client ? "Update" : "Add"} Client</div>
            <div className="auth-form-content d-flex flex-column justify-content-center">
              <input
                type="text"
                className={
                  "form-control cname my-1 " +
                  (formData.clientName.errorMsg && "invalid")
                }
                placeholder="Client Name"
                name="clientName"
                value={formData.clientName.value}
                onChange={onFieldChange}
                onBlur={(e) => onFieldBlur(validate2to32AlphaNumeric, e)}
                maxLength="15"
              />
              <label
                className="fileInput d-flex justify-content-center my-1"
                htmlFor="upload-photo"
              >
                Upload Logo
              </label>
              <input
                type="file"
                className="my-1"
                name="photo"
                id="upload-photo"
                onChange={(e) => {
                  const file = e.target.files[0];

                  if (file.size > FILE_SIZE_LIMIT_IN_BYTE) {
                    setErrorMsg(
                      `Logo should be of .jpg .jpeg .png format and less than 2MB`
                    );
                    return;
                  }

                  if (
                    ["image/jpeg", "image/jpg", "image/png"].indexOf(
                      file.type
                    ) === -1
                  ) {
                    setErrorMsg("Logo should be of .jpg .jpeg .png format and less than 2MB");
                    return;
                  }

                  setLogo(file);

                  const reader = new FileReader();
                  reader.onloadend = () => setPreviewImageUrl(reader.result);
                  reader.readAsDataURL(file);

                  setIsDirty(true);
                }}
              />
              <div
                className="halfbox d-flex flex-column my-1 logoArea justify-content-center"
                style={{ border: "1px", dashed: "#bfbfbf" }}
              >
                {/* <img src="../images/mainLogo.svg" /> */}
                {previewImageUrl && (
                  <img
                    src={previewImageUrl}
                    alt="Client Logo"
                    width="150"
                    height="100"
                  />
                )}
              </div>

              <input
                disabled={client !== null}
                type="text"
                className={
                  "form-control cname " +
                  (formData.domainName.errorMsg && "invalid")
                }
                placeholder="Email Domain"
                name="domainName"
                value={formData.domainName.value}
                onChange={onFieldChange}
                onBlur={(e) => {
                  const isValid = onFieldBlur(validateDomainName, e);

                  if (isValid) {
                    setDomainName(e.target.value);
                  }
                }}
              />

              <div className="halfBox">
                <div className="adminInfo d-flex flex-column justify-content-center w-100">
                  <span className="littleAdmin text-center my-1">
                    Admin Users
                  </span>
                  {formData.clientAdminUserIDs.map((userId, idxUserId) => {
                    console.log(domainUsers);
                    const adminUser = domainUsers.find(
                      (du) => du.UserID === userId
                    );
                    console.log(adminUser);

                    if (adminUser) {
                      return (
                        <div
                          key={idxUserId}
                          className="inlineRow my-1 d-inline-flex justify-content-between"
                        >
                          <button
                            className="negativeIcon"
                            type="button"
                            onClick={() =>
                              removeClientAdmin(
                                adminUser.UserID,
                                adminUser.EmailID
                              )
                            }
                          >
                            <img src="../image/negative.svg" />
                          </button>
                          <p className="adminMail text-center py-1 m-0 spec-padding">
                            {adminUser.EmailID}
                          </p>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                  <div className="inlineRow d-inline-flex justify-content-between my-1">
                    <button
                      type="button"
                      className="plusIcon"
                      onClick={() =>
                        addClientAdmin(formData.selectedClientAdminUserID.value)
                      }
                    >
                      <img src="../image/Layer%2020.svg" />
                    </button>
                    <select
                      name="selectedClientAdminUserID"
                      className="selectInput w-100"
                      value={formData.selectedClientAdminUserID.value}
                      onChange={onFieldChange}
                    >
                      <option value="">Select</option>
                      {formData.domainUsers.map((dm, idxDm) => (
                        <option key={idxDm} value={dm.UserID}>
                          {dm.EmailID}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="halfBox d-flex flex-column">
                <div className="inlineRow d-inline-flex justify-content-between"></div>
              </div>
              <div className="halfBox2 d-flex flex-column">
                <div className="inlineRow d-inline-flex justify-content-between mb-3">
                  <div className="titleOfContent my-1">
                    Access to Documentation
                  </div>
                  <div>
                    <div className="btn-group btn-group-toggle btn-group-radio mw-100 my-1">
                      <label
                        className={`btn btn-link btn-linkYes ${
                          formData.enableDocAccess === "yes" && "active"
                        }`}
                      >
                        <input
                          type="radio"
                          name="options"
                          checked={formData.enableDocAccess === "yes"}
                          onChange={(e) =>
                            setFormData((state) =>
                              update(state, {
                                enableDocAccess: { $set: "yes" },
                              })
                            )
                          }
                        />{" "}
                        YES
                      </label>
                      <label
                        className={`btn btn-link btn-linkNo ${
                          formData.enableDocAccess === "no" && "active"
                        }`}
                      >
                        <input
                          type="radio"
                          name="options"
                          checked={formData.enableDocAccess === "no"}
                          onChange={(e) =>
                            setFormData((state) =>
                              update(state, { enableDocAccess: { $set: "no" } })
                            )
                          }
                        />{" "}
                        NO
                      </label>
                    </div>
                  </div>
                </div>
                <div className="inlineRow d-inline-flex justify-content-between mb-2">
                  <div className="titleOfContent my-1">
                    Access to Code & Deploy
                  </div>
                  <div>
                    <div className="btn-group btn-group-toggle btn-group-radio mw-100 my-1">
                      <label
                        className={`btn btn-link btn-linkYes ${
                          formData.enableCodeDeploy === "yes" && "active"
                        } `}
                      >
                        <input
                          type="radio"
                          name="options"
                          checked={formData.enableCodeDeploy === "yes"}
                          onChange={(e) =>
                            setFormData((state) =>
                              update(state, {
                                enableCodeDeploy: { $set: "yes" },
                              })
                            )
                          }
                        />{" "}
                        YES
                      </label>
                      <label
                        className={`btn btn-link btn-linkNo ${
                          formData.enableCodeDeploy === "no" && "active"
                        }`}
                      >
                        <input
                          type="radio"
                          name="options"
                          checked={formData.enableCodeDeploy === "no"}
                          onChange={(e) =>
                            setFormData((state) =>
                              update(state, {
                                enableCodeDeploy: { $set: "no" },
                              })
                            )
                          }
                        />{" "}
                        NO
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="auth-form-footer">
              <div>
                <button
                  type="submit"
                  className="confirmBtn btn btn-link btn-green btn-animation"
                >
                  {client ? "Update" : "Add"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {console.log(formData)}
    </>
  );
};

export default ClientForm;
