import React, { useState, useEffect } from "react";

import LeftHandMenu from "../LeftHandMenu";
import TopMenu from "../../TopMenu";

import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../hooks/useHideLeftMenu";
import DomainMenu from "../DomainMenu";

import * as ROUTES from "../../../constants/routes";
import { getError } from "../../../helpers/errorHelper";

const domains = [
  "ProjectLauncher",
  "AccountFramework"
];

const GcpBuild = () => {
  const history = useHistory();
  const { projectId } = useParams();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [projectStatus, setProjectStatus] = useState(null);
  const [alertClass, setAlertClass] = useState("");

  const [buildData, setBuildData] = useState(null);

  const intervalObj = {};

  const awsAmplify = useAwsAmplify();

  const getClassName = (domain, key) => {
    if (buildData && buildData[domain] && buildData[domain][key]) {
      return `class-${buildData[domain][key]}`;
    } else {
      return "class-loading";
    }
  };

  const getProjectStatus = async () => {
    try {
      setErrorMsg(null);
      const result = await awsAmplify.getProjectStatus(projectId);

      if (result && result.data && result.data.getProjectStatus) {
        setProjectStatus(result.data.getProjectStatus);
        return result.data.getProjectStatus;
      }
    } catch (err) {
      setIsLoading(false);
      getError(err, setErrorMsg);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);

        //setIsLoading(true);

        const projectStatus = await getProjectStatus();

        //setIsLoading(false);

        const promises = domains.map(async (domain) => {
          const result = await awsAmplify.build(projectId, domain);

          if (
            projectStatus &&
            (projectStatus.status === "Launching" ||
              projectStatus.status === "Deploying")
          ) {
            for (const subdomain in result) {
              if (
                result[subdomain] === "running" ||
                result[subdomain] === "yettostart"
              ) {
                let handler = setInterval(async () => {
                  const result = await awsAmplify.build(projectId, domain);

                  console.log(intervalObj);
                  let allDone = true;

                  for (const subdomain in result) {
                    if (
                      result[subdomain] === "running" ||
                      result[subdomain] === "yettostart"
                    ) {
                      allDone = false;
                      break;
                    }
                  }

                  if (allDone && intervalObj[domain]) {
                    clearInterval(intervalObj[domain]);
                    delete intervalObj[domain];
                  }

                  setBuildData((state) => {
                    return { ...state, [domain]: result };
                  });
                }, 30000);

                if (!intervalObj[domain]) {
                  intervalObj[domain] = handler;
                }
                break;
              }
            }
          }

          setBuildData((state) => {
            return { ...state, [domain]: result };
          });
        });

        await Promise.all(promises);
      } catch (err) {
        //setIsLoading(false);
        const errorMsg = getError(err, setErrorMsg);

        if (errorMsg === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();

    return () => {
      for (const domain in intervalObj) {
        clearInterval(intervalObj[domain]);
      }
    };
  }, [projectId]);

  useEffect(() => {
    if (projectStatus && projectStatus.status) {
      switch (projectStatus.status) {
        case "Created":
        case "Saved":
          setAlertClass("bluealert");
          break;
        case "Launching":
        case "Deploying":
          setAlertClass("orangealert");
          break;
        case "Launched Successfully":
        case "Deployed Successfully":
          setAlertClass("greenalert");
          break;
        case "Launch Failed":
        case "Deployment Failed":
          setAlertClass("redalert");
          break;
        default:
          setAlertClass("");
          break;
      }
    }

    if (
      projectStatus &&
      (projectStatus.status === "Launching" ||
        projectStatus.status === "Deploying")
    ) {
      let handler = setInterval(getProjectStatus, 5000);

      return () => clearInterval(handler);
    }
  }, [projectStatus]);

  useHideLeftMenu();

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Build Status</div>
      <LeftHandMenu></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu></TopMenu>
            <div className="tableArea accountsTableArea aplicationTableArea container-fluid p-0 d-flex flex-column">
              <DomainMenu
                title="Build"
                isBuild={true}
                isDashboard={false}
                isConfigure={false}
                isPrelaunch={false}
              ></DomainMenu>

              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div className="row">
                  {projectStatus && (
                    <div className="status-bar">
                      <div className={alertClass}>
                        <div className="warnImg">
                          {alertClass === "orangealert" ? (
                            <img
                              className="rotating"
                              src="../images/rotating-arrow.svg"
                              alt="rotating arrow"
                            />
                          ) : (
                            <img src="../images/warning.svg" alt="warning" />
                          )}
                        </div>
                        <div className="messageText">
                        {`${projectStatus.status === "Deploying" ? `${projectStatus.status} Project` : `Project ${projectStatus.status}`}  on ${projectStatus.updatedOn}. `}
                          {projectStatus.message}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="mainContentArea preLaunchArea row px-2 py-2 mt-2">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-12 p-0">
                        <div className="col-md-6 col-12 d-flex flex-column">
                          <div className="row">
                            <div className="col-12 d-flex flex-column ">
                              <div className="build__box-head p-relative">
                                <div>
                                  <img
                                    src="../images/icons/icon-project-launcher.svg"
                                    alt=""
                                  ></img>
                                  <h3 className="font-15 px-3">
                                    Project Launcher
                                  </h3>
                                </div>
                                <div className="p-relative p-5 my-1 d-inline-flex align-items-center">
                                  <span
                                    className={getClassName(
                                      "ProjectLauncher",
                                      "ProjectLauncher"
                                    )}
                                  ></span>
                                </div>
                              </div>

                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "ProjectLauncher",
                                    "PreRequisites"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.PLATFORM_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Pre-requisites
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "ProjectLauncher",
                                    "CodeGeneration"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.WORKLOAD_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Code Generation
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "ProjectLauncher",
                                    "CodeTransfer"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.WORKLOAD_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Code Transfer
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "ProjectLauncher",
                                    "Builder"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.WORKLOAD_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Builder
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "ProjectLauncher",
                                    "DeploymentOrchestration"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.WORKLOAD_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Deployment Orchestration
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "ProjectLauncher",
                                    "FoundationalResources"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.WORKLOAD_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Foundational Resources
                                </a>
                              </div>
                            </div></div>
                        </div>
                        <div className="col-md-6 col-12 flex-column d-flex">
                          <div className="row">
                            <div className="col-12 d-flex flex-column ">
                              <div className="build__box-head p-relative">
                                <div>
                                  <img
                                    src="../images/icons/icon-account-framework.svg"
                                    alt=""
                                  ></img>
                                  <h3 className="font-15 px-3">
                                    Account Framework
                                  </h3>
                                </div>
                                <div className="p-relative p-5 my-1 d-inline-flex align-items-center">
                                  <span
                                    className={getClassName(
                                      "AccountFramework",
                                      "AccountFramework"
                                    )}
                                  ></span>
                                </div>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "AccountFramework",
                                    "AccountFramework"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.PLATFORM_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Platform Accounts
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "AccountFramework",
                                    "platformaccounts"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.PLATFORM_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Envionment Strategy
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "AccountFramework",
                                    "WorkloadAccounts"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.WORKLOAD_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Workload Accounts
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "AccountFramework",
                                    "SandboxAccounts"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.SANDBOX_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Sandbox Accounts
                                </a>
                              </div>
                            </div>
                            </div>
                        </div>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GcpBuild;
