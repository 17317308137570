import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import {
  validateCidr,
  validateDomainName,
  validateIPAddress,
} from "../../../../helpers/validator";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/network/privatedns";
const REGIONS_PREFIX = "/aws/network/aws/awsregionslist";
const IPAMINTEGRATION_PREFIX = "/aws/network/vpcfactory/enableipamintegration";
const ONPREMISE_PREFIX = "/aws/network/datacenters/establishonpremintegration";

const getInitialFormData = (data, regions, onPremise) => {
  const initialFormData = {
    deployprivatedns: {
      value: data[`${PREFIX}/deployprivatedns`] || "yes",
    },
    privatednssolution: {
      value: data[`${PREFIX}/privatednssolution`] || "route53resolverbased",
    },
    /* privatednsvpccidrs: regions
      ? regions.map((region) => {
          return {
            region: region,
            value: data[`${PREFIX}/${region}/privatednsvpccidr`] || "",
            isValid: data[`${PREFIX}/${region}/privatednsvpccidr`]
              ? true
              : false,
          };
        })
      : [], */

    deploydynamicdnsforaws: {
      value: data[`${PREFIX}/deploydynamicdnsforaws`] || "yes",
    },
    deployintegrationwithonpremdns: {
      value:
        data[`${PREFIX}/deployintegrationwithonpremdns`] || "yes"
        //(onPremise && onPremise === "no" ? "no" : "yes"),
    },
    forwardingrules: [createForwardingrule()],
  };

  if (data[`${PREFIX}/forwardingruleslist`]) {
    const forwardingrules = [];

    data[`${PREFIX}/forwardingruleslist`].split(",").forEach((ruleName) => {
      forwardingrules.push({
        onpremdnsdomainname: {
          value: data[`${PREFIX}/${ruleName}/onpremdnsdomainname`],
          isValid: true,
        },
        onpremdnsserverips: data[`${PREFIX}/${ruleName}/onpremdnsserverips`]
          ? data[`${PREFIX}/${ruleName}/onpremdnsserverips`]
              .split(",")
              .map((ip) => {
                return {
                  value: ip,
                  isValid: true,
                };
              })
          : [createIp()],
      });
    });

    if (forwardingrules.length > 0) {
      initialFormData.forwardingrules = forwardingrules;
    }
  }

  return initialFormData;
};

const createForwardingrule = () => {
  return {
    onpremdnsdomainname: {
      value: "",
      isValid: false,
    },
    onpremdnsserverips: [createIp()],
  };
};

const createIp = () => {
  return {
    value: "",
    isValid: false,
  };
};

const PrivateDNS = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));

  const [empty, setEmpty] = useState(false);

  const [ipam, setIpam] = useState();
  const [onPremise, setOnPremise] = useState();

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);
  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);

        const regionsData = await awsAmplify.loadProjectData(
          projectId,
          REGIONS_PREFIX
        );

        const ipamData = await awsAmplify.loadProjectData(
          projectId,
          IPAMINTEGRATION_PREFIX
        );

        setIpam(ipamData.data && ipamData.data[IPAMINTEGRATION_PREFIX]);

        /* const onPremiseData = await awsAmplify.loadProjectData(
          projectId,
          ONPREMISE_PREFIX
        ); */

        setOnPremise(
          result.data && result.data[ONPREMISE_PREFIX]
        );

        /* if (
          result.data &&
          result.data[`${PREFIX}/deployintegrationwithonpremdns`] === "yes" &&
          onPremiseData.data &&
          onPremiseData.data[ONPREMISE_PREFIX] === "no"
        ) {
          await awsAmplify.saveProjectData({
            prefix: `${PREFIX}/deployintegrationwithonpremdns`,
            projectId: projectId,
            projectData: {
              [`${PREFIX}/deployintegrationwithonpremdns`]: "no",
            },
          });

          result.data[`${PREFIX}/deployintegrationwithonpremdns`] = "no";
        } */

        setIsLoading(false);

        setEmpty(result.data.empty);

        setFormData(
          convertBEToFE(
            result.data,
            regionsData.data && regionsData.data[REGIONS_PREFIX]
              ? regionsData.data[REGIONS_PREFIX].split(",")
              : [],
            //onPremiseData.data && onPremiseData.data[ONPREMISE_PREFIX]
          )
        );
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data, regions, onPremise) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data, regions, onPremise);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deployprivatedns`]: formData.deployprivatedns.value,
    };

    if (formData.deployprivatedns.value === "yes") {
      be[`${PREFIX}/privatednssolution`] = formData.privatednssolution.value;

      /* formData.privatednsvpccidrs.forEach((cidr) => {
        if (cidr.isValid) {
          be[`${PREFIX}/${cidr.region}/privatednsvpccidr`] = cidr.value;
        }
      }); */

      be[`${PREFIX}/deploydynamicdnsforaws`] =
        formData.deploydynamicdnsforaws.value;
      be[`${PREFIX}/deployintegrationwithonpremdns`] =
        formData.deployintegrationwithonpremdns.value;

      if (formData.deployintegrationwithonpremdns.value === "yes") {
        const forwardingruleslist = [];

        formData.forwardingrules.forEach((rule, idxRule) => {
          forwardingruleslist.push(`forwardingrule${idxRule + 1}`);

          if (rule.onpremdnsdomainname.isValid) {
            be[`${PREFIX}/forwardingrule${idxRule + 1}/onpremdnsdomainname`] =
              rule.onpremdnsdomainname.value;
          }

          if (rule.onpremdnsserverips.filter((ip) => ip.isValid).length > 0) {
            be[
              `${PREFIX}/forwardingrule${idxRule + 1}/onpremdnsserverips`
            ] = rule.onpremdnsserverips
              .filter((ip) => ip.isValid)
              .map((ip) => ip.value)
              .join(",");
          }
        });

        if (forwardingruleslist.length > 0) {
          be[`${PREFIX}/forwardingruleslist`] = forwardingruleslist.join(",");
        }
      }
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const onFieldChange = (value, idx, key) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key]: { [idx]: { value: { $set: value } } } })
    );
  };

  const onFieldBlur = (validateFunc, value, idx, key) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          [key]: { [idx]: { isValid: { $set: errorMsg === undefined } } },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, { [key]: { [idx]: { isValid: { $set: false } } } })
      );
    }
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Network</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Network"
                isDirty={isDirty}
                dashboardRoute={ROUTES.INFRASTRUCTURE_INBOUND_ENDPOINTS}
                designRoute={ROUTES.DESIGN_INFRASTRUCTURE}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight privatednsPage flex-column pl-xl-5 pr-xl-5 py-xl-2 px-lg-4 p-0">
                <div className="row d-flex align-items-center">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3>Private DNS</h3>
                  </div>
                  <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                        Deploy Private DNS Solution
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                        <label
                          className={`createElementBtn btn btn-link btn-linkYes ${
                            formData.deployprivatedns.value === "yes" &&
                            "active"
                          } mw-70`}
                        >
                          <input
                            type="radio"
                            name="deployprivatedns"
                            checked={formData.deployprivatedns.value === "yes"}
                            onChange={() =>
                              updateRadio("deployprivatedns", "yes")
                            }
                          />{" "}
                          YES
                        </label>
                        <label
                          className={`createElementBtn btn btn-link btn-linkNo ${
                            formData.deployprivatedns.value === "no" && "active"
                          } mw-70`}
                        >
                          <input
                            type="radio"
                            name="deployprivatedns"
                            checked={formData.deployprivatedns.value === "no"}
                            //onChange={() => onChange("deployprivatedns", "no")}
                          />{" "}
                          NO
                        </label>
                      </div>
                    </div>
                  </div>
                  {empty && (
                    <NoInitialData />
                  )}
                  {formData.deployprivatedns.value === "yes" && (
                    <div className="show-hide-content px-0">
                      <div className=" col-xxl-7  col-xl-8 col-lg-12 col-12 d-inline-flex selectionArea  px-3  flex-wrap my-1 py-1">
                        <a className="font-12 mr-5  my-2 text-color-black">
                          Private DNS Solution
                        </a>
                        <div className="mr-5 my-2">
                          <label className="container font-12">
                            AWS Route53 Resolver based
                            <input
                              type="radio"
                              name="privatednssolution"
                              checked={
                                formData.privatednssolution.value ===
                                "route53resolverbased"
                              }
                              onChange={(e) =>
                                updateRadio(
                                  "privatednssolution",
                                  "route53resolverbased"
                                )
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="my-2">
                          <label className="container">
                            Windows AD DNS based
                            <input
                              type="radio"
                              name="privatednssolution"
                              checked={
                                formData.privatednssolution.value ===
                                "windowsaddnsbased"
                              }
                              onChange={(e) =>
                                updateRadio(
                                  "privatednssolution",
                                  "windowsaddnsbased"
                                )
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {formData.deployprivatedns.value === "yes" && (
                  <div className="show-hide-content">
                    {/* <div className="my-1">
                      <p className="m-0 p-0 font-12">
                        VPC CIDR for Route 53 Resolver End-points
                      </p>
                    </div>

                    <div className="row py-1 my-1 selectionArea">
                      {ipam && ipam === "yes" ? (
                        <span className="warning">
                          VPC CIDR block provided by IPAM solution.
                        </span>
                      ) : (
                        formData.privatednsvpccidrs.map((item, idxItem) => (
                          <div
                            key={idxItem}
                            className="d-inline-flex col-md-4 my-2 col-12"
                          >
                            <span className="font-12 d-flex align-items-center text-nowrap mr-2">
                              {item.region} VPC CIDR Block
                            </span>
                            <input
                              type="text"
                              className={`form-control gray ${
                                item.value !== "" && item.isValid === false
                                  ? "invalid"
                                  : ""
                              }`}
                              placeholder=""
                              value={item.value}
                              onChange={(e) =>
                                onFieldChange(
                                  e.target.value,
                                  idxItem,
                                  "privatednsvpccidrs"
                                )
                              }
                              onBlur={(e) =>
                                onFieldBlur(
                                  validateCidr,
                                  e.target.value,
                                  idxItem,
                                  "privatednsvpccidrs"
                                )
                              }
                            />
                          </div>
                        ))
                      )}
                    </div> */}

                    <div className="d-inline-flex w-100 py-1 my-1">
                      <div className="pl-lg-0 px-2 d-flex align-items-center mr-xl-5 mr-3 ">
                        <div className="font-12">
                          Deploy Dynamic DNS for AWS
                        </div>
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                        <RadioGroup
                          field="deploydynamicdnsforaws"
                          value={formData.deploydynamicdnsforaws.value}
                          onChange={updateRadio}
                        ></RadioGroup>
                      </div>
                    </div>

                    <div>
                      <div className="d-flex flex-column  pb-3 ">
                        <div className="d-inline-flex w-100 my-1 ">
                          <div className="pl-lg-0 px-2 d-flex align-items-center mr-xl-5 mr-3">
                            <div className="font-12">
                              Deploy Integration with On-premise DNS
                            </div>
                          </div>
                          <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                            <label
                              className={`createElementBtn btn btn-link btn-linkYes ${
                                formData.deployintegrationwithonpremdns
                                  .value === "yes" && "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name="deployintegrationwithonpremdns"
                                checked={
                                  formData.deployintegrationwithonpremdns
                                    .value === "yes"
                                }
                                onChange={() => {
                                  if (onPremise && onPremise === "no") {
                                  } else {
                                    setIsDirty(true);

                                    setFormData((state) =>
                                      update(state, {
                                        deployintegrationwithonpremdns: {
                                          value: { $set: "yes" },
                                        },
                                      })
                                    );
                                  }
                                }}
                              />{" "}
                              YES
                            </label>
                            <label
                              className={`createElementBtn btn btn-link btn-linkNo ${
                                formData.deployintegrationwithonpremdns
                                  .value === "no" && "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name="deployintegrationwithonpremdns"
                                checked={
                                  formData.deployintegrationwithonpremdns
                                    .value === "no"
                                }
                                onChange={() => {
                                  setIsDirty(true);

                                  setFormData((state) =>
                                    update(state, {
                                      deployintegrationwithonpremdns: {
                                        value: { $set: "no" },
                                      },
                                    })
                                  );
                                }}
                              />{" "}
                              NO
                            </label>
                          </div>
                        </div>
                        {onPremise && onPremise === "no" && (
                          <span className="warning">
                            This section is not applicable as On-prem Integration is not required. Check 'Establish On-prem Integration' in DataCenters section
                          </span>
                        )}

                        {formData.deployintegrationwithonpremdns.value ===
                          "yes" && (
                          <>
                            <div className="mainContentArea px-2 my-1 py-2">
                              <span className=" font-12">
                                Outbound DNS Resolution Rules
                              </span>
                              {formData.forwardingrules.map((item, idxItem) => (
                                <div
                                  key={idxItem}
                                  className="formRow row align-items-start justify-content-start"
                                >
                                  <div className="col-md-11 col-12 d-flex specialBoxDirection flex-wrap align-items-start">
                                    <span className="text-nowrap mr-3 d-flex specialTitle align-items-center px-0 my-1">
                                      Rule {idxItem + 1}:
                                    </span>
                                    <div className="row flex-1">
                                      <div className="col-xxl-4 col-xl-5 col-lg-6 col-special-lg-6 col-12 d-flex flex-md-row flex-column align-items-start px-0">
                                        <span className="text-nowrap mr-2 my-1 d-flex specialTitle align-items-center">
                                          On-Prem DNS Domain Name
                                        </span>
                                        <input
                                          type="text"
                                          className={`fw-150 my-1 form-control ${
                                            item.onpremdnsdomainname.value !==
                                              "" &&
                                            item.onpremdnsdomainname.isValid ===
                                              false
                                              ? "invalid"
                                              : ""
                                          }`}
                                          placeholder="maryland-dc"
                                          value={item.onpremdnsdomainname.value}
                                          onChange={(e) => {
                                            const value = e.target.value;

                                            setErrorMsg(null);
                                            setIsDirty(true);

                                            setFormData((state) =>
                                              update(state, {
                                                forwardingrules: {
                                                  [idxItem]: {
                                                    onpremdnsdomainname: {
                                                      value: { $set: value },
                                                    },
                                                  },
                                                },
                                              })
                                            );
                                          }}
                                          onBlur={(e) => {
                                            const value = e.target.value;

                                            if (value !== "") {
                                              const errorMsg = validateDomainName(
                                                value
                                              );

                                              setFormData((state) =>
                                                update(state, {
                                                  forwardingrules: {
                                                    [idxItem]: {
                                                      onpremdnsdomainname: {
                                                        isValid: {
                                                          $set:
                                                            errorMsg ===
                                                            undefined,
                                                        },
                                                      },
                                                    },
                                                  },
                                                })
                                              );

                                              if (errorMsg) {
                                                setErrorMsg(errorMsg);
                                              }
                                            } else {
                                              setFormData((state) =>
                                                update(state, {
                                                  forwardingrules: {
                                                    [idxItem]: {
                                                      onpremdnsdomainname: {
                                                        isValid: {
                                                          $set: false,
                                                        },
                                                      },
                                                    },
                                                  },
                                                })
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="col-xxl-8 col-xl-7 col-lg-6 col-special-lg-6 col-12 d-flex flex-md-row flex-column align-items-start  px-0">
                                        <span className="text-nowrap mr-2 d-flex specialTitle align-items-center my-1">
                                          On-Prem DNS Domain Server IP(s)
                                        </span>
                                        <div className="d-inline-flex flex-wrap">
                                          {item.onpremdnsserverips.map(
                                            (ips, idxIps) => (
                                              <div
                                                key={idxIps}
                                                className="p-relative my-1 mr-1 d-flex align-items-center"
                                              >
                                                <button disabled={disabled}
                                                  type="button"
                                                  className="closableInput"
                                                  onClick={() => {
                                                    if (
                                                      item.onpremdnsserverips
                                                        .length > 1
                                                    ) {
                                                      setIsDirty(true);
                                                      setFormData((state) =>
                                                        update(state, {
                                                          forwardingrules: {
                                                            [idxItem]: {
                                                              onpremdnsserverips: {
                                                                $splice: [
                                                                  [idxIps, 1],
                                                                ],
                                                              },
                                                            },
                                                          },
                                                        })
                                                      );
                                                    }
                                                  }}
                                                ></button>
                                                <input
                                                  type="text"
                                                  className={`w-150 form-control ${
                                                    ips.value !== "" &&
                                                    ips.isValid === false
                                                      ? "invalid"
                                                      : ""
                                                  }`}
                                                  placeholder="10.212.0.0/14"
                                                  value={ips.value}
                                                  onChange={(e) => {
                                                    const value =
                                                      e.target.value;

                                                    for (const [
                                                      idxOtherItem,
                                                      otherItem,
                                                    ] of item.onpremdnsserverips.entries()) {
                                                      if (
                                                        idxIps !== idxOtherItem
                                                      ) {
                                                        if (
                                                          otherItem.isValid &&
                                                          otherItem.value !== ""
                                                        ) {
                                                          if (
                                                            otherItem.value.toLowerCase() ===
                                                            value.toLowerCase()
                                                          ) {
                                                            setErrorMsg(
                                                              "Server IP must be unique"
                                                            );
                                                            return false;
                                                          }
                                                        }
                                                      }
                                                    }

                                                    setErrorMsg(null);
                                                    setIsDirty(true);

                                                    setFormData((state) =>
                                                      update(state, {
                                                        forwardingrules: {
                                                          [idxItem]: {
                                                            onpremdnsserverips: {
                                                              [idxIps]: {
                                                                value: {
                                                                  $set: value,
                                                                },
                                                              },
                                                            },
                                                          },
                                                        },
                                                      })
                                                    );
                                                  }}
                                                  onBlur={(e) => {
                                                    const value =
                                                      e.target.value;

                                                    if (value !== "") {
                                                      const errorMsg = validateIPAddress(
                                                        value
                                                      );

                                                      setFormData((state) =>
                                                        update(state, {
                                                          forwardingrules: {
                                                            [idxItem]: {
                                                              onpremdnsserverips: {
                                                                [idxIps]: {
                                                                  isValid: {
                                                                    $set:
                                                                      errorMsg ===
                                                                      undefined,
                                                                  },
                                                                },
                                                              },
                                                            },
                                                          },
                                                        })
                                                      );

                                                      if (errorMsg) {
                                                        setErrorMsg(errorMsg);
                                                      }
                                                    } else {
                                                      setFormData((state) =>
                                                        update(state, {
                                                          forwardingrules: {
                                                            [idxItem]: {
                                                              onpremdnsserverips: {
                                                                [idxIps]: {
                                                                  isValid: {
                                                                    $set: false,
                                                                  },
                                                                },
                                                              },
                                                            },
                                                          },
                                                        })
                                                      );
                                                    }
                                                  }}
                                                />
                                              </div>
                                            )
                                          )}
                                          <button disabled={disabled}
                                            type="button"
                                            className="btn-animation d-flex align-items-center pl-2"
                                            onClick={() => {
                                              setIsDirty(true);
                                              setFormData((state) =>
                                                update(state, {
                                                  forwardingrules: {
                                                    [idxItem]: {
                                                      onpremdnsserverips: {
                                                        $push: [createIp()],
                                                      },
                                                    },
                                                  },
                                                })
                                              );
                                            }}
                                          >
                                            <img src="../images/purplePlusIcon.svg" />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-1 closeFormRow justify-content-end  d-flex align-items-center col-12 px-0 specialTitle">
                                    <button disabled={disabled}
                                      className="btn-animation closeIcon d-flex justify-content-end"
                                      type="button"
                                      onClick={() => {
                                        if (
                                          formData.forwardingrules.length > 1
                                        ) {
                                          setIsDirty(true);
                                          setFormData((state) =>
                                            update(state, {
                                              forwardingrules: {
                                                $splice: [[idxItem, 1]],
                                              },
                                            })
                                          );
                                        }
                                      }}
                                    >
                                      <img src="../images/close.svg" />
                                    </button>
                                  </div>
                                  <div className="closeFormRowMobil tr-10">
                                    <button disabled={disabled}
                                      className="btn-animation closeIcon"
                                      type="button"
                                      onClick={() => {
                                        if (
                                          formData.forwardingrules.length > 1
                                        ) {
                                          setFormData((state) =>
                                            update(state, {
                                              forwardingrules: {
                                                $splice: [[idxItem, 1]],
                                              },
                                            })
                                          );
                                        }
                                      }}
                                    >
                                      <img src="../images/close.svg" />
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div class="d-flex align-items-center justify-content-end px-2  my-1">
                              <div class="add-button ">
                                <button disabled={disabled}
                                  class="add-new-account-link btn-animation d-inline-flex align-items-center"
                                  type="button"
                                  onClick={() =>
                                    setFormData((state) =>
                                      update(state, {
                                        forwardingrules: {
                                          $push: [createForwardingrule()],
                                        },
                                      })
                                    )
                                  }
                                >
                                  <p class="my-0 mr-2 p-0 green-style2">
                                    Add Rule
                                  </p>
                                  <img src="../images/coloredPlus.svg" />
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              </fieldset>
              {console.log(ipam)}
              {console.log(onPremise)}
              {console.log(formData)}

              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.VPC_SHAREDENDPOINTS + "/" + projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      VPC Endpoints
                    </p>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivateDNS;
