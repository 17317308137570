import React, { useEffect, useState } from "react";
import * as ROUTES from "../constants/routes";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import $ from "jquery";
import ContactUsForm from "./ContactUsForm";

import { Hub } from "@aws-amplify/core";


const HomeFooter = ({setIsLoading, setError, setInfo}) => {
  const [authStateChanged, setAuthStateChanged] = useState(null);
  
  Hub.listen("auth", (data) => {
    switch (data.payload.event) {
      case "signIn":
        setAuthStateChanged("SIGNIN");
        break;
      case "signOut":
        setAuthStateChanged("SIGNOUT");
        break;
      default:
        break;
    }
  });

  useEffect(() => {

    //$(function () {
    /* $(".scrollUp").click(function () {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        600
      );
      return false;
    }); */

    /* $(".up").click(function () {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        600
      );
      return false;
    }); */

    //$(".demoForm").hide();

    $(".demoButton").on("click", function () {
      $(".demoForm").slideDown();
    });
  //});
  });

  return (
    <>
      <footer>
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-5 left">
              <a href="index.html" className="logo">
                <img src="images/logo-footer.svg" alt="" />
              </a>
              <div className="row">
                <div className="col-lg-5">
                  <address>
                    100 Overlook Center, <br />
                    2 nd Floor, <br />
                    Princeton, NJ 08540, <br />
                    USA
                  </address>
                  <a href="tel:+1 (732) 328-9119" className="link">
                    +1 (732) 328-9119
                  </a>
                  <a href="mailto:info@epic9.io" className="link">
                    info@epic9.io
                  </a>
                </div>
                <div className="col-lg-6 offset-lg-1">
                  <p>
                    Mytri Square, 2-41/11, 6/2, Gachibowli - Miyapur Road,
                    Prashanth Nagar Colony, Hyderabad, Telangana 500084, India
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-7 right">
              <h3>FOUNDATION FOR DIGITAL INNOVATION</h3>
              <div className="row align-items-end">
                <div className="col-lg-3">
                  <ul className="foot-menu">
                    <li>
                      <HashLink smooth to={`${ROUTES.LANDING}#`}>
                        HOME
                      </HashLink>
                    </li>
                    <li>
                      <HashLink smooth to="/#solutions">
                        SOLUTIONS
                      </HashLink>
                    </li>
                    <li>
                      <HashLink smooth to="/#approach">
                        APPROACH
                      </HashLink>
                    </li>
                    <li>
                      <Link to={ROUTES.CAREERS}>CAREERS</Link>
                    </li>
                    <li>
                      <Link to={ROUTES.CONTACT}>CONTACT</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-9">
                  <h5>
                    Epic9 is a solution-led Cloud consulting company driven by a
                    passion for delivering the “Faster, Better, Cheaper” promise
                    of Cloud, Automation, and NextGen technologies
                  </h5>

                  <div className="buttons">
                    <a href="#demoForm" className="signUp demoButton">
                      <span>Contact us</span>
                    </a>
                    {authStateChanged !== "SIGNIN" && (
                    <a className="signUp signClick">
                      <span>Sign up</span>
                    </a>
                    )}
                    <HashLink className="scrollUp" to="#" smooth>
                      <img src="images/icons/up.svg" alt="" />
                    </HashLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="demoForm"></div>
        <div className="container demoForm" >
          <ContactUsForm setError={setError} setIsLoading={setIsLoading} setInfo={setInfo}></ContactUsForm>
        </div>
      </footer>
    </>
  );
};

export default HomeFooter;
