import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "./DetailsTags";
import DetailsGrid from "./DetailsGrid";

//const projectId = "google_1";
const dashboardName = "Resource Shares Shared By Me";

const SubMenu = () => {
  const { projectId } = useParams();
  
  return (
    <>
      <h3>Resources Shares</h3>
      <div className="otherTableTitles ">
        <h3 className="font-15 activeTable">Shared by me</h3>
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.RESOURCE_SHARES_WITH_ME + "/" + projectId}>
            Shared with me
          </Link>
        </h3>
      </div>
    </>
  );
};

const Details = ({ close, selectedRow }) => {
  const [type, setType] = useState("Details");

  return (
    <div className="justify-content-center align-items-end account-detail">
      <div className="w-100 s-flex flex-column account-detail-area">
        <button className="exitAccount" onClick={() => close()}>
          <img src="../images/close.svg" />
        </button>
        <div className="">
          <ul className="account-detail-link m-0 p-0 d-inline-flex">
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Details" && "my_active"
              }`}
              onClick={() => setType("Details")}
            >
              Details
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Shared Resources" && "my_active"
              }`}
              onClick={() => setType("Shared Resources")}
            >
              Shared Resources
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Shared Principals" && "my_active"
              }`}
              onClick={() => setType("Shared Principals")}
            >
              Shared Principals
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Tags" && "my_active"
              }`}
              onClick={() => setType("Tags")}
            >
              Tags
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 account-detail-container-area">
          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Details" && "inaktif"
            }`}
          >
            <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Owner ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.owningAccountId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Region</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Region}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Resource Share Name
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.name}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Created on</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.creationTime}
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Status</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.status}
                </p>
              </div>

              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">ARN</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.resourceShareArn}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Allow External Principals
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.hasOwnProperty("allowExternalPrincipals") && selectedRow.allowExternalPrincipals.toString()}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Last Updated On
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.lastUpdatedTime}
                </p>
              </div>
            </div>
          </div>

          <DetailsGrid
            type={type}
            typeName="Shared Resources"
            array={selectedRow["Shared Resources"]}
            metadata={[
              { header: "Resource ID", key: "arn" },
              { header: "Resource Type", key: "type" },
              { header: "Status", key: "status" },
            ]}
          ></DetailsGrid>

          <DetailsGrid
            type={type}
            typeName="Shared Principals"
            array={selectedRow.Principals}
            metadata={[
              { header: "Principal ID", key: "Id" },
              { header: "Principal Type", key: "principalType" },
              { header: "Status", key: "status" },
            ]}
          ></DetailsGrid>

          <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>
        </div>
      </div>
    </div>
  );
};

const Navigations = () => {
  const { projectId } = useParams();
  return (
  <>
    <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.TRAFFIC_MIRROR_TARGETS + "/" + projectId}
    >
      <img src="../images/ribbon-design.svg" />
      <p className="m-0 p-0 ml-2 font-weight-bold">Traffic Mirror Targets</p>
    </Link>
  </>
)};

const ResourceSharesbyme = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Owner ID",
        accessor: "owningAccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "Resource Share Name",
        accessor: "name",
      },
      {
        Header: "Created on",
        accessor: "creationTime",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "ARN",
        accessor: "resourceShareArn",
      },
      {
        Header: "Allow External Principals",
        id: "allowExternalPrincipals",
        accessor: (value) =>
          value.hasOwnProperty("allowExternalPrincipals") && value.allowExternalPrincipals.toString(),
      },
      {
        Header: "Last Updated On",
        accessor: "lastUpdatedTime",
      },
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "network",
    title: "Network",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_CENTERS,
    designRoute: ROUTES.DESIGN_NETWORK,
    SubMenu: SubMenu,
  });
};

export default ResourceSharesbyme;
