import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "./DetailsTags";
import { getArrayValue, getBooleanValue } from "./DashboardHelper";
import DetailsGrid from "./DetailsGrid";

//const projectId = "google_1";
const dashboardName = "VPN Connections";

const SubMenu = () => {
  const { projectId } = useParams();

  return (
    <>
      <h3>Hybrid Network</h3>
      <div className="otherTableTitles ">
        <h3 className="font-15 activeTable">VPN</h3>
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPN_DIRECT_CONNECT + "/" + projectId}>
            Direct Connect
          </Link>
        </h3>
        <h3 className="font-15">
          <Link
            className="text-color-gray"
            to={ROUTES.VPN_DIRECT_CONNECT_GATEWAY + "/" + projectId}
          >
            DGW
          </Link>
        </h3>
      </div>
    </>
  );
};

const Details = ({ close, selectedRow }) => {
  const [type, setType] = useState("Details");

  return (
    <div className="justify-content-center align-items-end account-detail">
      <div className="w-100 s-flex flex-column account-detail-area">
        <button className="exitAccount" onClick={() => close()}>
          <img src="../images/close.svg" />
        </button>
        <div className="">
          <ul className="account-detail-link m-0 p-0 d-inline-flex">
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Details" && "my_active"
              }`}
              onClick={() => setType("Details")}
            >
              Details
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Customer Gateway Configuration" && "my_active"
              }`}
              onClick={() => setType("Customer Gateway Configuration")}
            >
              Customer Gateway Configuration
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Tags" && "my_active"
              }`}
              onClick={() => setType("Tags")}
            >
              Tags
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Tunnel Details" && "my_active"
              }`}
              onClick={() => setType("Tunnel Details")}
            >
              Tunnel Details
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Routes" && "my_active"
              }`}
              onClick={() => setType("Routes")}
            >
              Routes
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 account-detail-container-area">
          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Details" && "inaktif"
            }`}
          >
            <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow["AccountId"]}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Region</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Region}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  VPN Connection Name
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {getArrayValue(selectedRow.Tags, -1, "Key", "Name", "Value")}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  VPN Connection Id
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.VpnConnectionId}
                </p>
              </div>

              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">State</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.State}
                </p>
              </div>

              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Transit Gateway
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.TransitGatewayId}
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Customer Gateway
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.CustomerGatewayId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Type</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Type}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Category</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Category}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Routing</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Options.StaticRoutesOnly ? "Static" : "Dynamic"}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Acceleration Enabled
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {getBooleanValue(selectedRow, "Options.EnableAcceleration")}
                </p>
              </div>
            </div>
          </div>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Customer Gateway Configuration" && "inaktif"
            }`}
          >
            <pre className="font-12">{selectedRow.CustomerGatewayConfiguration}</pre>
          </div>

          <DetailsGrid
            type={type}
            typeName="Tunnel Details"
            array={selectedRow.VgwTelemetry}
            metadata={[
              { header: "Accept Route Count", key: "AcceptedRouteCount" },
              { header: "Last Status Change", key: "LastStatusChange" },
              { header: "Outside IP Address", key: "OutsideIpAddress" },
              { header: "Status", key: "Status" },
              { header: "Status Message", key: "StatusMessage" },
            ]}
          ></DetailsGrid>

          <DetailsGrid
            type={type}
            typeName="Routes"
            array={selectedRow.Routes}
            metadata={[
              { header: "Destination CIDR", key: "DesinationCIDR" },
              { header: "Target", key: "Target" },
            ]}
          ></DetailsGrid>

          <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>
        </div>
      </div>
    </div>
  );
};

const Navigations = () => {
  const { projectId } = useParams();
  return (
    <>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.TGW + "/" + projectId}
      >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Transit Gateway</p>
      </Link>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.NETWORK_FIREWALL_FIREWALLS + "/" + projectId}
      >
        <p className="m-0 p-0 mr-2 font-weight-bold">Network Firewall</p>
        <img src="../images/ribbon-designRight.svg" />
      </Link>
    </>
  );
};

const VPN = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "VPN Connection Name",
        accessor: (d) => getArrayValue(d.Tags, -1, "Key", "Name", "Value"),
      },
      {
        Header: "VPN Connection Id",
        accessor: "VpnConnectionId",
      },
      {
        Header: "State",
        accessor: "State",
      },
      {
        Header: "Transit Gateway",
        accessor: "TransitGatewayId",
      },
      {
        Header: "Customer Gateway",
        accessor: "CustomerGatewayId",
      },
      {
        Header: "Type",
        accessor: "Type",
      },
      {
        Header: "Category",
        accessor: "Category",
      },
      {
        Header: "Routing",
        accessor: (d) => (d.Options.StaticRoutesOnly ? "Static" : "Dynamic"),
        id: "Routing",
      },
      {
        Header: "Acceleration Enabled",
        accessor: (d) => getBooleanValue(d, "Options.EnableAcceleration"),
        id: "Options.EnableAcceleration",
      },
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "network",
    title: "Network",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_CENTERS,
    designRoute: ROUTES.DESIGN_NETWORK,
    SubMenu: SubMenu,
  });
};

export default VPN;
