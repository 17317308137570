import React, { useState, useEffect } from "react";


import LeftHandMenu from "../LeftHandMenu";
import TopMenu from "../../TopMenu";

import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../hooks/useHideLeftMenu";
import DomainMenu from "../DomainMenu";

import { getError } from "../../../helpers/errorHelper";
import $ from "jquery";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import OverlayScrollbars from "overlayscrollbars";
import PlanComponent from "../PlanComponent";

const issueTypes = [
  "Design Decisions",
  "Assumptions",
  "Dependencies",
  "Risks",
  "Issues",
  "Stories",
];

const AlicloudPlan = () => {
  const history = useHistory();
  const { projectId } = useParams();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [planData, setPlanData] = useState({
    Assumptions: [],
    Dependencies: [],
    DesignDecisions: [],
    Issues: [],
    Risks: [],
    Stories: [],
  });

  const awsAmplify = useAwsAmplify();

  function initResize() {
    window.addEventListener("mousemove", resize, false);
    window.addEventListener("touchmove", resize, false);
    window.addEventListener("mouseup", stopResize, false);
    window.addEventListener("touchend", stopResize, false);
  }

  function resize(e) {
    window.dispatchEvent(new Event("resize"));

    let element = $(e.target)
        .closest(".plan__section-scroll")
        .find(`[resize-node-js]`),
      container = $(e.target).closest(".plan__section-scroll")[0];

    for (let i = 0; i < element.length; i++) {
      let resizeVal =
          (e.clientX || e.touches[0].clientX) -
          (element[i].offsetLeft +
            parseInt($(".plan__wrapper").css("padding-left"))),
        resizeValPercent =
          (resizeVal * 100) / container.getBoundingClientRect().width;

      if (resizeValPercent > 20 && resizeValPercent < 70)
        element[i].style.maxWidth = resizeValPercent + "%";

      $(element).trigger("element.resize");
    }
  }

  function stopResize(e) {
    window.removeEventListener("mousemove", resize, false);
    window.removeEventListener("touchmove", resize, false);
    window.removeEventListener("mouseup", stopResize, false);
    window.removeEventListener("touchend", stopResize, false);
  }

  useEffect(() => {
    if ($(".plan__section").length > 0) {
      for (
        let i = 0;
        i < document.querySelectorAll("[resize-handle-js]").length;
        i++
      ) {
        document
          .querySelectorAll("[resize-handle-js]")
          [i].addEventListener("mousedown", initResize, false);
        document
          .querySelectorAll("[resize-handle-js]")
          [i].addEventListener("touchstart", initResize, false);
      }
    }
    /* OverlayScrollbars($(".plan__section-footer"), {
			className: "os-theme-dark",
			autoUpdate: true,
			overflowBehavior: {
				x: "hidden",
				y: "scroll"
			}
		});
		
		OverlayScrollbars($(".plan__section-scroll"), {
			className: "os-theme-dark",
			autoUpdate: true,
			overflowBehavior: {
				x: "scroll",
				y: "hidden"
			}
		}); */
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);

        const errorMsgs = [];

        const promises = issueTypes.map(async (issueType) => {
          const issueTypeKey = issueType.replace(/\s/gi, "");
          let result = await awsAmplify.plan(projectId, issueTypeKey);

          if (result.Status === "Success") {
            setPlanData((state) => {
              return { ...state, [issueTypeKey]: result.IssuesList };
            });
          } else if (result.Status === "Failed") {
            errorMsgs.push(result.Message);
          }
        });

        await Promise.all(promises);

        if (errorMsgs.length > 0) {
          setErrorMsg(errorMsgs);
        }
      } catch (err) {
        //setIsLoading(false);
        const errorMsg = getError(err, setErrorMsg);

        if (errorMsg === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  useHideLeftMenu();

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong>{" "}
              {Array.isArray(errorMsg)
                ? errorMsg.map((error) => <div>{error}</div>)
                : errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Plan</div>
      <LeftHandMenu></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu></TopMenu>
            <div className="tableArea accountsTableArea aplicationTableArea container-fluid p-0 d-flex flex-column">
              <DomainMenu
                title="Plan"
                isDashboard={false}
                isConfigure={false}
                isPrelaunch={false}
                isBuild={false}
                isPlan={true}
              ></DomainMenu>

              <PlanComponent
                features={issueTypes}
                planData={planData}
              ></PlanComponent>
            </div>
          </div>
        </div>
        {console.log(planData)}
      </div>
    </>
  );
};

export default AlicloudPlan;
