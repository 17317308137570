import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getJSONStr } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "KMS KeyStores";

const SubMenu = () => {
  const { projectId } = useParams();

  return (
    <>
      <h3>Key Management</h3>
      <div className="otherTableTitles ">       
      <h3 className="font-15">   
        <Link className="text-color-gray" to={ROUTES.DATA_PROTECTION_KEYS + "/" + projectId}>  
           Keys
        </Link>          
        </h3>
        <h3 className="font-15 activetable">         
           KeyStores         
        </h3>
      </div>
    </>
  );
}

  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Trust Anchor Certificate" && "my_active"
                }`}
                onClick={() => setType("Trust Anchor Certificate")}
              >
                Trust Anchor Certificate
              </li>       
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">AWS Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Custom KeyStore Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.CustomKeyStoreName}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Custom Key Store Id</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.CustomKeyStoreId}
                  </p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Cloud HSM Cluster Id
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.CloudHsmClusterId}
                  </p>
                </div>
  
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Connection State
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.ConnectionState}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Creation Date</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.CreationDate}
                  </p>
                </div>
              </div>
            </div>
          
         <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Trust Anchor Certificate" && "inaktif"
              }`} >
             <div className="col-12">
              <p className="color-spc3">
                <pre className="font-12">
                  {selectedRow && getJSONStr(selectedRow.TrustAnchorCertificate)}
                </pre>
              </p>
            </div>
         </div>

          </div>
        </div>
      </div>
    );
  };

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.DATA_PROTECTION_ACM + "/" + projectId}
      >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">ACM Certificates</p>       
      </Link>
      </>
    );
  };
  
  
const KeyStores = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
        id:"Account ID"
      },
      {
        Header: "AWS Region",
        accessor: "Region",
        id: "AWS Region"
      },
      {
        Header: "Custom KeyStore Name",
        accessor: "CustomKeyStoreName",
        id: "Custom KeyStore Name"
      },
      {
        Header: "Custom KeyStore Id",
        accessor: "CustomKeyStoreId",
        id: "Custom KeyStore Id"
      },
      {
        Header: "Cloud HSM Cluster Id",
        accessor: "CloudHsmClusterId",
        id: "Cloud HSM Cluster Id"
      },
      {
        Header: "Connection State",
        accessor: "ConnectionState",
        id: "Connection State"
      },
      {
        Header: "Creation Date",
        accessor: "CreationDate",
        id: "Creation Date"
      }
    ],
    []
  );

  return Dashboard({
    domain: "dataprotection",
    title: "Data Protection",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_PROTECTION_KEY_MANAGEMENT,
    designRoute: ROUTES.DESIGN_DATA_PROTECTION,
    SubMenu: SubMenu,
  });
};

export default KeyStores;