import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import { validateCidr, validatePortNumber } from "../../../../helpers/validator";
import { getError } from "../../../../helpers/errorHelper";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";
import { array } from "yup";

const REGION_KEY = "/aws/network/aws/awsregionslist";
const IPAMINTEGRATION_KEY = "/aws/network/vpcfactory/enableipamintegration";

const PREFIX = "/aws/network/trafficinspection";

const TrafficInspection = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    deploytrafficinspection: {
      value: "yes",
    },
    regions: [],
    egressvpc: [],
    ipamIntegration: "no",
    solution: {
      value: "",
    },
    denycommunicationusinginsecureprotocols:{
      value: "yes"
    },
    protocols : {
      insecureprotocolslist: [],
      insecureprotocols : []
    },
    denycommunicationusinginsecureports : {
      value: "yes"
    },
    ports : {
      insecureportlist : [],
      insecureports : []
    },
    deployawsmanagedrulegroups: {
      value: "yes"
    }

  });

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched", "Deployed"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        console.log(result);

        const regionResult = await awsAmplify.loadProjectData(
          projectId,
          REGION_KEY
        );
        console.log(regionResult);

        const ipamIntegrationResult = await awsAmplify.loadProjectData(
          projectId,
          IPAMINTEGRATION_KEY
        );
        console.log(ipamIntegrationResult);

        setIsLoading(false);

        setEmpty(result.data.empty);

        const fe = convertBEToFE(
          result.data,
          regionResult.data,
          ipamIntegrationResult.data
        );

        setFormData(fe);
      } catch (err) {
        console.log(err);
        setIsLoading(false);

        const errorMsg = getError(err, setErrorMsg);

        if (errorMsg === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);

      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

 

  const convertBEToFE = (data, regionData, ipamIntegrationData) => {
    if (!data) {
      data = {};
    }

    const fe = {
      deploytrafficinspection: {
        value: data[`${PREFIX}/deploytrafficinspection`] || "yes",
      },
      regions:
        regionData && regionData[REGION_KEY]
          ? regionData[REGION_KEY].split(",")
          : [],
      centralizeegresstraffic: {
        value: data[`${PREFIX}/centralizeegresstraffic`] || "yes",
      },
      egressvpc:
        regionData && regionData[REGION_KEY]
          ? regionData[REGION_KEY].split(",").map((reg) => {
              return { region: reg, value: "", isValid: false };
            })
          : [],
      inspectegresstraffic: {
        value: data[`${PREFIX}/inspectegresstraffic`] || "yes",
      },
      centralizeingresstraffic: {
        value: data[`${PREFIX}/centralizeingresstraffic`] || "yes",
      },
      ingressvpc:
        regionData && regionData[REGION_KEY]
          ? regionData[REGION_KEY].split(",").map((reg) => {
              return { region: reg, value: "", isValid: false };
            })
          : [],
      inspectingresstraffic: {
        value: data[`${PREFIX}/inspectingresstraffic`] || "yes",
      },
      inspectintervpctraffic: {
        value: data[`${PREFIX}/inspectintervpctraffic`] || "yes",
      },
      inspectonpremtraffic: {
        value: data[`${PREFIX}/inspectonpremtraffic`] || "yes",
      },
      solution: {
        value: data[`${PREFIX}/solution`]
          ? data[`${PREFIX}/solution`] === "none"
            ? ""
            : data[`${PREFIX}/solution`]
          : "",
      },
      centralizetrafficmirroring: {
        value: data[`${PREFIX}/centralizetrafficmirroring`] || "yes",
      },
      trafficmirroringvpc:
        regionData && regionData[REGION_KEY]
          ? regionData[REGION_KEY].split(",").map((reg) => {
              return { region: reg, value: "", isValid: false };
            })
          : [],
      ipamIntegration:
        ipamIntegrationData && ipamIntegrationData[IPAMINTEGRATION_KEY]
          ? ipamIntegrationData[IPAMINTEGRATION_KEY]
          : "no",

      denycommunicationusinginsecureprotocols: {
        value: data[`${PREFIX}/denycommunicationusinginsecureprotocols`] || "yes",
      },
      protocols : {
      insecureprotocolslist: ["DCERPC","DHCP","DNS","FTP","HTTP","ICMP","IKEV2","IMAP","IP","KRB5","MSN","NTP","SMB","SMTP","SSH","TCP","TFTP","TLS","UDP"],
   
      insecureprotocols : []
      },

      denycommunicationusinginsecureports : {
        value: data[`${PREFIX}/denycommunicationusinginsecureports`] || "yes",
      },
      ports: {
        insecureports: [{value: "", isValid: false}]
      }

      };

      if(data[`${PREFIX}/insecureprotocols`] !== "none" && data[`${PREFIX}/insecureprotocols`] !== undefined)
      {
        fe.protocols.insecureprotocols = data[`${PREFIX}/insecureprotocols`].split(',');
      }  

      if(data[`${PREFIX}/insecureports`] !== "none" && data[`${PREFIX}/insecureports`] !== undefined)
      {
        var portArray = data[`${PREFIX}/insecureports`].split(",");
        if(portArray !== undefined && portArray.length > 0)
            fe.ports.insecureports = [];
        for(const port in portArray)
        {
          fe.ports.insecureports.push({
            value: portArray[port] || "",
            isValid: portArray[port] == "" ? false : true 
          })
        }
      } 

        fe.deployawsmanagedrulegroups  = {
        value: data[`${PREFIX}/awsnetworkfirewall/deployawsmanagedrulegroups`] || "yes"
        };

    fe.egressvpc.forEach((vpc) => {
      if (data[`${PREFIX}/egressvpc/${vpc.region}/cidr`]) {
        vpc.value = data[`${PREFIX}/egressvpc/${vpc.region}/cidr`];
        vpc.isValid = true;
      }
    });

    fe.ingressvpc.forEach((vpc) => {
      if (data[`${PREFIX}/ingressvpc/${vpc.region}/cidr`]) {
        vpc.value = data[`${PREFIX}/ingressvpc/${vpc.region}/cidr`];
        vpc.isValid = true;
      }
    });

    fe.trafficmirroringvpc.forEach((vpc) => {
      if (data[`${PREFIX}/trafficmirroringvpc/${vpc.region}/cidr`]) {
        vpc.value = data[`${PREFIX}/trafficmirroringvpc/${vpc.region}/cidr`];
        vpc.isValid = true;
      }
    });



    console.log(fe);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploytrafficinspection`]: formData.deploytrafficinspection
        .value,
    };

    if (formData.deploytrafficinspection.value === "yes") {
      be[`${PREFIX}/centralizeegresstraffic`] =
        formData.centralizeegresstraffic.value;

      if (formData.centralizeegresstraffic.value === "yes") {
        formData.egressvpc.forEach((vpc) => {
          if (vpc.isValid) {
            be[`${PREFIX}/egressvpc/${vpc.region}/cidr`] = vpc.value;
          }
        });

        be[`${PREFIX}/inspectegresstraffic`] =
          formData.inspectegresstraffic.value;
      } else {
        be[`${PREFIX}/inspectegresstraffic`] = "no";
      }

      be[`${PREFIX}/centralizeingresstraffic`] =
        formData.centralizeingresstraffic.value;

      if (formData.centralizeingresstraffic.value === "yes") {
        formData.ingressvpc.forEach((vpc) => {
          if (vpc.isValid) {
            be[`${PREFIX}/ingressvpc/${vpc.region}/cidr`] = vpc.value;
          }
        });

        be[`${PREFIX}/inspectingresstraffic`] =
          formData.inspectingresstraffic.value;
      } else {
        be[`${PREFIX}/inspectingresstraffic`] = "no";
      }

      be[`${PREFIX}/inspectintervpctraffic`] =
        formData.inspectintervpctraffic.value;

      be[`${PREFIX}/inspectonpremtraffic`] =
        formData.inspectonpremtraffic.value;

      if (
        formData.inspectegresstraffic.value === "yes" ||
        formData.inspectingresstraffic.value === "yes" ||
        formData.inspectintervpctraffic.value === "yes" ||
        formData.inspectonpremtraffic.value === "yes"
      ) {
        if (formData.solution.value !== "") {
          be[`${PREFIX}/solution`] = formData.solution.value;

          if(formData.solution.value === "awsnetworkfirewall")
          {
            be[`${PREFIX}/awsnetworkfirewall/deployawsmanagedrulegroups`] = formData.deployawsmanagedrulegroups.value;
          }
        }
      }
      else{
        be[`${PREFIX}/solution`] = "none";
      }

      be[`${PREFIX}/centralizetrafficmirroring`] =
        formData.centralizetrafficmirroring.value;

      if (formData.centralizetrafficmirroring.value === "yes") {
        formData.trafficmirroringvpc.forEach((vpc) => {
          if (vpc.isValid) {
            be[`${PREFIX}/trafficmirroringvpc/${vpc.region}/cidr`] = vpc.value;
          }
        });
      }

      be[`${PREFIX}/denycommunicationusinginsecureprotocols`] =  formData.denycommunicationusinginsecureprotocols.value;
       if(formData.denycommunicationusinginsecureprotocols.value === "yes")
         {
           if(formData.protocols.insecureprotocols !== undefined && formData.protocols.insecureprotocols.length > 0)
              be[`${PREFIX}/insecureprotocols`] =  formData.protocols.insecureprotocols.join(',') 
         }
       else
       {
        be[`${PREFIX}/insecureprotocols`] = "none";
       } 

       be[`${PREFIX}/denycommunicationusinginsecureports`] =  formData.denycommunicationusinginsecureports.value;
       if(formData.denycommunicationusinginsecureports.value === "yes")
          {
            var portArray = [];
              for(var port in formData.ports.insecureports)
              {
                  if(formData.ports.insecureports[port].isValid) {
                    portArray.push(formData.ports.insecureports[port].value);
                  }
                  if(portArray.length > 0)
                      be[`${PREFIX}/insecureports`] = portArray.join(',');
              }
          }
       else
       {
        be[`${PREFIX}/insecureports`] = "none";
       } 
    }
    else{
      be[`${PREFIX}/centralizeingresstraffic`] = "no";
      be[`${PREFIX}/centralizeegresstraffic`] = "no";
      be[`${PREFIX}/centralizetrafficmirroring`] = "no";
      be[`${PREFIX}/inspectegresstraffic`] = "no";
      be[`${PREFIX}/inspectingresstraffic`] = "no";
      be[`${PREFIX}/inspectintervpctraffic`] = "no";
      be[`${PREFIX}/inspectonpremtraffic`] = "no";
      be[`${PREFIX}/solution`] = "none";
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const onFieldArrayChange = (idx, value, key1) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key1]: { [idx]: { value: { $set: value } } } })
    );
  };

  const onFieldArrayBlur = (validateFunc, idx, value, key1) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          [key1]: { [idx]: { isValid: { $set: errorMsg === undefined } } },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, {
          [key1]: { [idx]: { isValid: { $set: false } } },
        })
      );
    }
  };

  const getFieldArrayValidationClass = (idx, key1) => {
    return !formData[key1][idx].isValid && formData[key1][idx].value !== ""
      ? "invalid"
      : "";
  };

  
  const getPortValidationClass = ( idx) => {
    return ( formData.ports && formData.ports.insecureports && formData.ports.insecureports[idx].value !== "" &&
      !formData.ports.insecureports[idx].isValid)
      ? "invalid"
      : "";
  };

  const onFieldChange = (value, key1) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key1]: { value: { $set: value } } })
    );
  };

  const addPort = (key,array) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key]: { [array]: { $push: [{ value: "", isValid: false }] } ,
        }, 
      })
    );
  };

  const removePort = (key,array,idxItem) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          [array]: { $splice: [[idxItem, 1]] },
        },
      })
        );
   };



  
  const onPortChange = (portIdx, value) => {
    setErrorMsg(null);
    setIsDirty(true);
    setFormData((state) =>
      update(state, {
          ["ports"]: { insecureports: { [portIdx]: { value: { $set: value } } } },
      })
    );
  };


  const onPortBlur = (portIdx, validateFunc, value) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
            ["ports"]: { insecureports: { [portIdx]: { isValid: { $set: errorMsg === undefined } },
              },
            },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, {
            ["ports"]: { insecureports: { [portIdx]: { isValid: { $set: false } },
              },
            },
        })
      );
    }
  };


  const exists = (key, array, value) => {
    return formData[key][array].indexOf(value) !== -1;
  };

  const add = (key, array, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key]: { [array]: { $push: [value] } } })
    );
  };

  const remove = (key, array, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          [array]: { $splice: [[state[key][array].indexOf(value), 1]] },
        },
      })
    );
  };


/* 
  const modifyArray = (array, value) => {
    if (exists( array, value)) {
      remove(array, value);
    } else {
      add(array, value);
    }
  };
 */

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Network</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Network"
                isDirty={isDirty}
                dashboardRoute={ROUTES.VPC}
                designRoute={ROUTES.DESIGN_NETWORK}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 ">
                <div className="row d-flex align-items-center ">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3 className="font-weight-bold">Traffic Inspection</h3>
                  </div>
                  {formData.regions.length !== 0 && (
                    <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                      <div className="d-inline-flex yes-no-area align-items-center">
                        <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                          Deploy Traffic Inspection
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                          <label
                            className={`createElementBtn btn btn-link btn-linkYes mw-70 ${
                              formData.deploytrafficinspection.value ===
                                "yes" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.deploytrafficinspection.value === "yes"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    deploytrafficinspection: {
                                      value: { $set: "yes" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            YES
                          </label>
                          <label
                            className={`dontCreateElementBtn btn btn-link btn-linkNo mw-70 ${
                              formData.deploytrafficinspection.value === "no" &&
                              "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.deploytrafficinspection.value === "no"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    deploytrafficinspection: {
                                      value: { $set: "no" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            NO
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {empty && (
                  <NoInitialData />
                )}
                {formData.regions.length === 0 ? (
                  <span className="warning">
                    This section depends on data in AWS Environment section in
                    Network domain, which are yet to be provided
                  </span>
                ) : (
                  formData.deploytrafficinspection.value === "yes" && (
                    <>
                      <div className="d-inline-flex w-100 py-1 my-1">
                        <div className="pl-lg-0 px-2 d-flex align-items-center w-md-155 ">
                          <div className="font-12">
                            Centralize Egress Traffic
                          </div>
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                          <label
                            className={`btn btn-link btn-linkYes ${
                              formData.centralizeegresstraffic.value ===
                                "yes" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.centralizeegresstraffic.value === "yes"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    centralizeegresstraffic: {
                                      value: { $set: "yes" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            YES
                          </label>
                          <label
                            className={`btn btn-link btn-linkNo ${
                              formData.centralizeegresstraffic.value === "no" &&
                              "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.centralizeegresstraffic.value === "no"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    centralizeegresstraffic: {
                                      value: { $set: "no" },
                                    },
                                    inspectegresstraffic: {
                                      value: { $set: "no" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            NO
                          </label>
                        </div>
                      </div>

                      {formData.centralizeegresstraffic.value === "yes" && (
                        <div className="row py-1 my-1 mainContentArea">
                          <span className="font-12 my-1">
                            Egress VPC CIDR Blocks
                          </span>
                          {formData.ipamIntegration === "yes" && (
                            <span className="warning">
                              VPC CIDR block provided by IPAM solution
                            </span>
                          )}
                          {formData.ipamIntegration === "no" &&
                            formData.egressvpc.map((item, idxItem) => (
                              <div
                                className="d-inline-flex flex-wrap col-md-4 my-2 col-12"
                                key={idxItem}
                              >
                                <span className="font-12 d-flex align-items-center mr-2 w-180">
                                  {item.region} VPC CIDR Block
                                </span>
                                <input
                                  type="text"
                                  className={`form-control w-150 gray ${getFieldArrayValidationClass(
                                    idxItem,
                                    "egressvpc"
                                  )}`}
                                  placeholder=""
                                  value={item.value}
                                  onChange={(e) => {
                                    const value = e.target.value;

                                    for (const [
                                      idxOtherItem,
                                      otherItem,
                                    ] of formData.egressvpc.entries()) {
                                      if (idxItem !== idxOtherItem) {
                                        if (
                                          otherItem.isValid &&
                                          otherItem.value !== ""
                                        ) {
                                          if (otherItem.value === value) {
                                            setErrorMsg(
                                              "VPC CIDR block must be unique"
                                            );
                                            return false;
                                          }
                                        }
                                      }
                                    }

                                    for (const [
                                      idxOtherItem,
                                      otherItem,
                                    ] of formData.ingressvpc.entries()) {
                                        if (
                                          otherItem.isValid &&
                                          otherItem.value !== ""
                                        ) {
                                          if (otherItem.value === value) {
                                            setErrorMsg(
                                              "VPC CIDR block must be unique"
                                            );
                                            return false;
                                          }
                                        }
                                    }

                                    for (const [
                                      idxOtherItem,
                                      otherItem,
                                    ] of formData.trafficmirroringvpc.entries()) {
                                        if (
                                          otherItem.isValid &&
                                          otherItem.value !== ""
                                        ) {
                                          if (otherItem.value === value) {
                                            setErrorMsg(
                                              "VPC CIDR block must be unique"
                                            );
                                            return false;
                                          }
                                        }
                                    }

                                    onFieldArrayChange(
                                      idxItem,
                                      e.target.value,
                                      "egressvpc"
                                    );
                                  }}
                                  onBlur={(e) =>
                                    onFieldArrayBlur(
                                      validateCidr,
                                      idxItem,
                                      e.target.value,
                                      "egressvpc"
                                    )
                                  }
                                />
                              </div>
                            ))}

                          <div className="d-inline-flex w-100 py-1 my-1">
                            <div className="pl-lg-0 px-2 d-flex align-items-center w-md-144 ">
                              <div className="font-12">
                                Inspect Egress Traffic
                              </div>
                            </div>
                            <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                              <label
                                className={`btn btn-link btn-linkYes ${
                                  formData.inspectegresstraffic.value ===
                                    "yes" && "active"
                                }`}
                              >
                                <input
                                  type="radio"
                                  name="options"
                                  checked={
                                    formData.inspectegresstraffic.value ===
                                    "yes"
                                  }
                                  onChange={() => {
                                    setIsDirty(true);
                                    setFormData((state) =>
                                      update(state, {
                                        inspectegresstraffic: {
                                          value: { $set: "yes" },
                                        },
                                      })
                                    );
                                  }}
                                />{" "}
                                YES
                              </label>
                              <label
                                className={`btn btn-link btn-linkNo ${
                                  formData.inspectegresstraffic.value ===
                                    "no" && "active"
                                }`}
                              >
                                <input
                                  type="radio"
                                  name="options"
                                  checked={
                                    formData.inspectegresstraffic.value === "no"
                                  }
                                  onChange={() => {
                                    setIsDirty(true);
                                    setFormData((state) =>
                                      update(state, {
                                        inspectegresstraffic: {
                                          value: { $set: "no" },
                                        },
                                      })
                                    );
                                  }}
                                />{" "}
                                NO
                              </label>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="d-inline-flex w-100 py-1 my-1 mt-3">
                        <div className="pl-lg-0 px-2 d-flex align-items-center w-md-155 ">
                          <div className="font-12">
                            Centralize Ingress Traffic
                          </div>
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                          <label
                            className={`btn btn-link btn-linkYes ${
                              formData.centralizeingresstraffic.value ===
                                "yes" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.centralizeingresstraffic.value ===
                                "yes"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    centralizeingresstraffic: {
                                      value: { $set: "yes" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            YES
                          </label>
                          <label
                            className={`btn btn-link btn-linkNo ${
                              formData.centralizeingresstraffic.value ===
                                "no" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.centralizeingresstraffic.value === "no"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    centralizeingresstraffic: {
                                      value: { $set: "no" },
                                    },
                                    inspectingresstraffic: {
                                      value: { $set: "no" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            NO
                          </label>
                        </div>
                      </div>

                      {formData.centralizeingresstraffic.value === "yes" && (
                        <>
                          <div className="row py-1 my-1 mainContentArea">
                            <span className="font-12 my-1">
                              Ingress VPC CIDR Blocks
                            </span>
                            {formData.ipamIntegration === "yes" && (
                              <span className="warning">
                                VPC CIDR block provided by IPAM solution
                              </span>
                            )}
                            {formData.ipamIntegration === "no" &&
                              formData.ingressvpc.map((item, idxItem) => (
                                <div
                                  className="d-inline-flex flex-wrap col-md-4 my-2 col-12"
                                  key={idxItem}
                                >
                                  <span className="font-12 d-flex align-items-center mr-2 w-180">
                                    {item.region} VPC CIDR Block
                                  </span>
                                  <input
                                    type="text"
                                    className={`form-control w-150 gray ${getFieldArrayValidationClass(
                                      idxItem,
                                      "ingressvpc"
                                    )}`}
                                    placeholder=""
                                    value={item.value}
                                    onChange={(e) => {
                                      const value = e.target.value;

                                      for (const [
                                        idxOtherItem,
                                        otherItem,
                                      ] of formData.ingressvpc.entries()) {
                                        if (idxItem !== idxOtherItem) {
                                          if (
                                            otherItem.isValid &&
                                            otherItem.value !== ""
                                          ) {
                                            if (otherItem.value === value) {
                                              setErrorMsg(
                                                "VPC CIDR block must be unique"
                                              );
                                              return false;
                                            }
                                          }
                                        }
                                      }

                                      for (const [
                                        idxOtherItem,
                                        otherItem,
                                      ] of formData.egressvpc.entries()) {
                                          if (
                                            otherItem.isValid &&
                                            otherItem.value !== ""
                                          ) {
                                            if (otherItem.value === value) {
                                              setErrorMsg(
                                                "VPC CIDR block must be unique"
                                              );
                                              return false;
                                            }
                                          }
                                      }
  
                                      for (const [
                                        idxOtherItem,
                                        otherItem,
                                      ] of formData.trafficmirroringvpc.entries()) {
                                          if (
                                            otherItem.isValid &&
                                            otherItem.value !== ""
                                          ) {
                                            if (otherItem.value === value) {
                                              setErrorMsg(
                                                "VPC CIDR block must be unique"
                                              );
                                              return false;
                                            }
                                          }
                                      }

                                      onFieldArrayChange(
                                        idxItem,
                                        e.target.value,
                                        "ingressvpc"
                                      );
                                    }}
                                    onBlur={(e) =>
                                      onFieldArrayBlur(
                                        validateCidr,
                                        idxItem,
                                        e.target.value,
                                        "ingressvpc"
                                      )
                                    }
                                  />
                                </div>
                              ))}

                            <div className="d-inline-flex w-100 py-1 my-1">
                              <div className="pl-lg-0 px-2 d-flex align-items-center w-md-144 ">
                                <div className="font-12">
                                  Inspect Ingress Traffic
                                </div>
                              </div>
                              <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                                <label
                                  className={`btn btn-link btn-linkYes ${
                                    formData.inspectingresstraffic.value ===
                                      "yes" && "active"
                                  }`}
                                >
                                  <input
                                    type="radio"
                                    name="options"
                                    checked={
                                      formData.inspectingresstraffic.value ===
                                      "yes"
                                    }
                                    onChange={() => {
                                      setIsDirty(true);
                                      setFormData((state) =>
                                        update(state, {
                                          inspectingresstraffic: {
                                            value: { $set: "yes" },
                                          },
                                        })
                                      );
                                    }}
                                  />{" "}
                                  YES
                                </label>
                                <label
                                  className={`btn btn-link btn-linkNo ${
                                    formData.inspectingresstraffic.value ===
                                      "no" && "active"
                                  }`}
                                >
                                  <input
                                    type="radio"
                                    name="options"
                                    checked={
                                      formData.inspectingresstraffic.value ===
                                      "no"
                                    }
                                    onChange={() => {
                                      setIsDirty(true);
                                      setFormData((state) =>
                                        update(state, {
                                          inspectingresstraffic: {
                                            value: { $set: "no" },
                                          },
                                        })
                                      );
                                    }}
                                  />{" "}
                                  NO
                                </label>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="d-inline-flex w-100 py-1 my-1 mt-3"></div>
                      <div className="row py-1 my-1 mainContentArea">
                        <div className="d-inline-flex w-100 py-1 my-1">
                          <div className="pl-lg-0 px-2 d-flex align-items-center w-md-144 ">
                            <div className="font-12">
                              Inspect Inter-VPC Traffic
                            </div>
                          </div>
                          <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                            <label
                              className={`btn btn-link btn-linkYes ${
                                formData.inspectintervpctraffic.value ===
                                  "yes" && "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name="options"
                                checked={
                                  formData.inspectintervpctraffic.value ===
                                  "yes"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                      inspectintervpctraffic: {
                                        value: { $set: "yes" },
                                      },
                                    })
                                  );
                                }}
                              />{" "}
                              YES
                            </label>
                            <label
                              className={`btn btn-link btn-linkNo ${
                                formData.inspectintervpctraffic.value ===
                                  "no" && "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name="options"
                                checked={
                                  formData.inspectintervpctraffic.value === "no"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                      inspectintervpctraffic: {
                                        value: { $set: "no" },
                                      },
                                    })
                                  );
                                }}
                              />{" "}
                              NO
                            </label>
                          </div>
                        </div>
                        <div className="d-inline-flex w-100 py-1 my-1">
                          <div className="pl-lg-0 px-2 d-flex align-items-center w-md-144 ">
                            <div className="font-12">
                              Inspect On-prem Traffic
                            </div>
                          </div>
                          <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                            <label
                              className={`btn btn-link btn-linkYes ${
                                formData.inspectonpremtraffic.value === "yes" &&
                                "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name="options"
                                checked={
                                  formData.inspectonpremtraffic.value === "yes"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                      inspectonpremtraffic: {
                                        value: { $set: "yes" },
                                      },
                                    })
                                  );
                                }}
                              />{" "}
                              YES
                            </label>
                            <label
                              className={`btn btn-link btn-linkNo ${
                                formData.inspectonpremtraffic.value === "no" &&
                                "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name="options"
                                checked={
                                  formData.inspectonpremtraffic.value === "no"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                      inspectonpremtraffic: {
                                        value: { $set: "no" },
                                      },
                                    })
                                  );
                                }}
                              />{" "}
                              NO
                            </label>
                          </div>
                        </div>
                        {(formData.inspectegresstraffic.value === "yes" ||
                          formData.inspectingresstraffic.value === "yes" ||
                          formData.inspectintervpctraffic.value === "yes" ||
                          formData.inspectonpremtraffic.value === "yes") && (
                          <div className="show-hide-content selectionArea my-3 px-3">
                            <div className="col-9 d-inline-flex justify-content-between align-items-center flex-wrap py-1">
                              <span className="font-12 d-flex align-items-center">
                                Traffic Inspection Solution
                              </span>
                              <div className="my-2">
                                <label className="container text-color-black">
                                  AWS Network Firewall
                                  <input
                                    type="radio"
                                    name="server"
                                    checked={
                                      formData.solution.value ===
                                      "awsnetworkfirewall"
                                    }
                                    onChange={() =>
                                      onFieldChange(
                                        "awsnetworkfirewall",
                                        "solution"
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="my-2">
                                <label className="container text-color-black">
                                  Palo Alto
                                  <input
                                    type="radio"
                                    name="server"
                                    checked={
                                      formData.solution.value === "paloalto"
                                    }
                                    onChange={() =>
                                      onFieldChange("paloalto", "solution")
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                             </div>
                              <div className="my-2">
                                <label className="container text-color-black">
                                  Fortinet
                                  <input
                                    type="radio"
                                    name="server"
                                    checked={
                                      formData.solution.value === "fortinet"
                                    }
                                    onChange={() =>
                                      onFieldChange("fortinet", "solution")
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            {
                              formData.solution.value === "awsnetworkfirewall" && (
                                <>
                                  <div className="show-hide-sub-content my-2">
                                  <div className="pl-lg-0 px-2 d-inline-flex align-items-center w-180 font-12 ">
                                  Deploy AWS Managed Rules
                                   </div>
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-2">
                                  <label
                                    className={`btn btn-link btn-linkYes ${
                                      formData.deployawsmanagedrulegroups.value === "yes" &&
                                      "active"
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="options"
                                      checked={
                                        formData.deployawsmanagedrulegroups.value === "yes"
                                      }
                                      onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            deployawsmanagedrulegroups: {
                                              value: { $set: "yes" },
                                            },
                                          })
                                        );
                                      }}
                                    />{" "}
                                    YES
                                  </label>
                                  <label
                                    className={`btn btn-link btn-linkNo ${
                                      formData.deployawsmanagedrulegroups.value === "no" &&
                                      "active"
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="options"
                                      checked={
                                        formData.deployawsmanagedrulegroups.value === "no"
                                      }
                                      onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            deployawsmanagedrulegroups: {
                                              value: { $set: "no" },
                                            },
                                          })
                                        );
                                      }}
                                    />{" "}
                                    NO
                                  </label>
                                </div>
                              </div>
                                </>
                              )
                            }
                          </div>
                        )}
                        
                      </div>
                     

                      <div className="d-inline-flex w-100 py-1 my-1 mt-3">
                        <div className="pl-lg-0 px-2 d-flex align-items-center w-md-155 ">
                          <div className="font-12">
                            Centralize Traffic Mirroring
                          </div>
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                          <label
                            className={`btn btn-link btn-linkYes ${
                              formData.centralizetrafficmirroring.value ===
                                "yes" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.centralizetrafficmirroring.value ===
                                "yes"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    centralizetrafficmirroring: {
                                      value: { $set: "yes" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            YES
                          </label>
                          <label
                            className={`btn btn-link btn-linkNo ${
                              formData.centralizetrafficmirroring.value ===
                                "no" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.centralizetrafficmirroring.value ===
                                "no"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    centralizetrafficmirroring: {
                                      value: { $set: "no" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            NO
                          </label>
                        </div>
                      </div>

                      {formData.centralizetrafficmirroring.value === "yes" && (
                        <>
                          <div className="row py-1 my-1 mainContentArea">
                            <span className="font-12 my-1">
                              Traffic Mirroring VPC CIDR Blocks
                            </span>
                            {formData.ipamIntegration === "yes" && (
                              <span className="warning">
                                VPC CIDR block provided by IPAM solution
                              </span>
                            )}
                            {formData.ipamIntegration === "no" &&
                              formData.trafficmirroringvpc.map(
                                (item, idxItem) => (
                                  <div
                                    className="d-inline-flex flex-wrap col-md-4 my-2 col-12"
                                    key={idxItem}
                                  >
                                    <span className="font-12 d-flex align-items-center mr-2 w-180">
                                      {item.region} VPC CIDR Block
                                    </span>
                                    <input
                                      type="text"
                                      className={`form-control w-150 gray ${getFieldArrayValidationClass(
                                        idxItem,
                                        "trafficmirroringvpc"
                                      )}`}
                                      placeholder=""
                                      value={item.value}
                                      onChange={(e) => {
                                        const value = e.target.value;

                                        for (const [
                                          idxOtherItem,
                                          otherItem,
                                        ] of formData.trafficmirroringvpc.entries()) {
                                          if (idxItem !== idxOtherItem) {
                                            if (
                                              otherItem.isValid &&
                                              otherItem.value !== ""
                                            ) {
                                              if (otherItem.value === value) {
                                                setErrorMsg(
                                                  "VPC CIDR block must be unique"
                                                );
                                                return false;
                                              }
                                            }
                                          }
                                        }

                                        for (const [
                                          idxOtherItem,
                                          otherItem,
                                        ] of formData.ingressvpc.entries()) {
                                            if (
                                              otherItem.isValid &&
                                              otherItem.value !== ""
                                            ) {
                                              if (otherItem.value === value) {
                                                setErrorMsg(
                                                  "VPC CIDR block must be unique"
                                                );
                                                return false;
                                              }
                                            }
                                        }
    
                                        for (const [
                                          idxOtherItem,
                                          otherItem,
                                        ] of formData.egressvpc.entries()) {
                                            if (
                                              otherItem.isValid &&
                                              otherItem.value !== ""
                                            ) {
                                              if (otherItem.value === value) {
                                                setErrorMsg(
                                                  "VPC CIDR block must be unique"
                                                );
                                                return false;
                                              }
                                            }
                                        }

                                        onFieldArrayChange(
                                          idxItem,
                                          e.target.value,
                                          "trafficmirroringvpc"
                                        );
                                      }}
                                      onBlur={(e) =>
                                        onFieldArrayBlur(
                                          validateCidr,
                                          idxItem,
                                          e.target.value,
                                          "trafficmirroringvpc"
                                        )
                                      }
                                    />
                                  </div>
                                )
                              )}
                          </div>
                        </>
                      )}

                      <div className="d-inline-flex w-100 py-1 my-1 mt-3">
                        <div className="pl-lg-0 px-2 d-flex align-items-center w-md-155 ">
                          <div className="font-12">
                          Deny communication using insecure protocols
                          </div>
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                          <label
                            className={`btn btn-link btn-linkYes ${
                              formData.denycommunicationusinginsecureprotocols.value ===
                                "yes" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.denycommunicationusinginsecureprotocols.value ===
                                "yes"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    denycommunicationusinginsecureprotocols: {
                                      value: { $set: "yes" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            YES
                          </label>
                          <label
                            className={`btn btn-link btn-linkNo ${
                              formData.denycommunicationusinginsecureprotocols.value ===
                                "no" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.denycommunicationusinginsecureprotocols.value ===
                                "no"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    denycommunicationusinginsecureprotocols: {
                                      value: { $set: "no" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            NO
                          </label>
                        </div>
                      </div>
                      { formData.denycommunicationusinginsecureprotocols.value === "yes" && (
                          <>
                          <div className="row py-1 my-1 mainContentArea">
                            <div className="d-inline-flex flex-wrap mr-lg-5 mr-md-2 my-1 mr-0">
                            <span className="font-12 d-flex align-items-center mr-2 w-150 my-1">
                              Insecure Protocols
                            </span>
                            <div className=" bg-gray border-10  p-relative d-inline-flex align-items-center w-md-250 mr-2">
                              <select
                                className="form-control bg-gray pr-lg-0 pr-5"
                                value=""
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setIsDirty(true);
                                  
                                  if (
                                    exists( "protocols","insecureprotocols",value )
                                  ) {
                                    remove("protocols", "insecureprotocols",value
                                    );
                                  }
                                    if (
                                      !exists(
                                        "protocols",
                                        "insecureprotocols",
                                        value)
                                    ) {
                                      add(
                                        "protocols",
                                        "insecureprotocols",
                                        value
                                      );
                                    }
                                  }
                                }
                              >
                                <option value="">Select</option>
                                {formData.protocols.insecureprotocolslist && formData.protocols.insecureprotocolslist.map(
                                  (item, idxItem) => (
                                    <option key={idxItem} value={item}>
                                      {item}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                            {formData.protocols && formData.protocols.insecureprotocols 
                            && formData.protocols.insecureprotocols.map(
                              (item, idxItem) => (
                                <div
                                  key={idxItem}
                                  className=" bg-gray border-10  p-relative  d-inline-flex align-items-center w-md-250 mr-2 m-1"
                                >
                                  <button disabled={disabled}
                                    type="button"
                                    className="closableInput"
                                    onClick={() =>
                                      remove(
                                        "protocols",
                                        "insecureprotocols",
                                        item
                                      )
                                    }
                                  ></button>
                                  <input
                                    type="text"
                                    className="form-control bg-transparent pr-lg-0 pr-5"
                                    placeholder={
                                     formData.protocols.insecureprotocolslist.find( (s) => s === item)
                                    }
                                    readOnly
                                  />
                                </div>
                              )
                            )}
                          </div>
                          </div>
                          </>
                        )
                      }
          

                  <div className="d-inline-flex w-100 py-1 my-1 mt-3">
                        <div className="pl-lg-0 px-2 d-flex align-items-center w-md-155 ">
                          <div className="font-12">
                          Deny communication using insecure ports
                          </div>
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                          <label
                            className={`btn btn-link btn-linkYes ${
                              formData.denycommunicationusinginsecureports.value ===
                                "yes" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.denycommunicationusinginsecureports.value ===
                                "yes"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    denycommunicationusinginsecureports: {
                                      value: { $set: "yes" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            YES
                          </label>
                          <label
                            className={`btn btn-link btn-linkNo ${
                              formData.denycommunicationusinginsecureports.value ===
                                "no" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.denycommunicationusinginsecureports.value ===
                                "no"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    denycommunicationusinginsecureports: {
                                      value: { $set: "no" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            NO
                          </label>
                        </div>
                      </div>
                      { formData.denycommunicationusinginsecureports.value === "yes" && (
                  <div className="row py-1 my-1 mainContentArea">
                  <div className=" border-10  p-relative d-inline-flex align-items-center">
                      <span className="font-12 text-nowrap mr-2">
                        Insecure Ports
                      </span>    
                      <div className="col-md-10 my-1 flex-wrap d-inline-flex">               
                        {formData.ports.insecureports.map((portItem, portIdx) => (                                                                                                 
                            <div key={portIdx}  className="mx-2 my-1 p-relative form-line d-flex align-items-center">
                              <button disabled={disabled} 
                                type="button"
                                className="closableInput"
                                onClick={() =>
                                 (formData.ports.insecureports.length > 1 ) &&
                                     removePort("ports","insecureports",portIdx)
                                }
                              ></button>
                              <input
                                type="text"
                                className={`form-control bg-gray ${getPortValidationClass(
                                portIdx
                                )}`}
                                placeholder="1111"
                                value={portItem.value}
                                onChange={(e) => {
                                  onPortChange(
                                    portIdx,
                                    e.target.value,
                                  )
                                }}
                                onBlur={(e) =>
                                  onPortBlur(
                                    portIdx,
                                    validatePortNumber,
                                    e.target.value,
                                  )
                                }
                              />
                            </div>                         
                     
                        ))}
                        <button disabled={disabled} 
                          type="button"
                          className="btn-animation d-flex align-items-center pl-2"
                          onClick={() => addPort("ports","insecureports")}
                        >
                          <img src="../images/coloredPlus.svg" />
                        </button>
                      </div>
                    </div>
                  </div>                                  
                      )}
                    </>
                  )
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.DIRECT_CONNECT_CONNECTIVITY + "/" + projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      DirectConnect
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.VPC_SHAREDENDPOINTS + "/" + projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      VPC Endpoints
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrafficInspection;
