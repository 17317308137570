import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import { getError } from "../../../../helpers/errorHelper";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/network/sharedvpcendpoints";

const Endpoint = ({ name, isExist, onCheck, specialClass }) => {
  const fieldName = name;

  return (
    <div
      class={
        specialClass ||
        "form-line w-100  my-1  d-inline-flex align-items-center"
      }
    >
      <label class="switch mb-0">
        <input
          type="checkbox"
          name={fieldName}
          checked={isExist(fieldName)}
          onChange={onCheck}
        />
        <span class="slider round"></span>
      </label>
      <span class="switchLabel ">{fieldName}</span>
    </div>
  );
};

const VPCSharedEndpoints = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    deploysharedvpcendpoints: {
      value: "",
    },
    sharedvpcendpointslist: [],
  });

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        setEmpty(result.data.empty);
        setFormData(convertBEToFE(result.data));
      } catch (err) {
        setIsLoading(false);
        const errorMsg = getError(err, setErrorMsg);
        console.log(errorMsg);

        if (errorMsg === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }
    return {
      deploysharedvpcendpoints: {
        value: data[`${PREFIX}/deploysharedvpcendpoints`] || "yes",
      },
      sharedvpcendpointslist: data[`${PREFIX}/sharedvpcendpointslist`]
        ? data[`${PREFIX}/sharedvpcendpointslist`].split(",")
        : [],
    };
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploysharedvpcendpoints`]: formData.deploysharedvpcendpoints
        .value,
    };

    if (
      formData.deploysharedvpcendpoints.value === "yes" &&
      formData.sharedvpcendpointslist.length > 0
    ) {
      be[
        `${PREFIX}/sharedvpcendpointslist`
      ] = formData.sharedvpcendpointslist.join(",");
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const onCheck = (e) => {
    const checked = e.target.checked,
      name = e.target.name;

    const itemIdx = formData.sharedvpcendpointslist.indexOf(name);

    if (checked) {
      if (itemIdx === -1) {
        setErrorMsg(null);
        setIsDirty(true);

        setFormData((state) =>
          update(state, { sharedvpcendpointslist: { $push: [name] } })
        );
      }
    } else {
      if (itemIdx !== -1) {
        setErrorMsg(null);
        setIsDirty(true);

        setFormData((state) =>
          update(state, { sharedvpcendpointslist: { $splice: [[itemIdx, 1]] } })
        );
      }
    }
  };

  const isExist = (value) => {
    return formData.sharedvpcendpointslist.indexOf(value) !== -1;
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Network</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Network"
                isDirty={isDirty}
                dashboardRoute={ROUTES.VPC}
                designRoute={ROUTES.DESIGN_NETWORK}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 ">
                <div class="row d-flex align-items-center">
                  <div class="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3>VPC Endpoints</h3>
                  </div>
                  <div class="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div class="d-inline-flex yes-no-area align-items-center">
                      <div class="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                        Deploy Centralized & Shared Interface Endpoints
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                        <label
                          className={`createElementBtn btn btn-link btn-linkYes mw-70 ${
                            formData.deploysharedvpcendpoints.value === "yes" &&
                            "active"
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={
                              formData.deploysharedvpcendpoints.value === "yes"
                            }
                            onChange={() => {
                              if (formData.onPremIntegration === "no") {
                                return false;
                              }

                              setIsDirty(true);
                              setFormData((state) =>
                                update(state, {
                                  deploysharedvpcendpoints: {
                                    value: { $set: "yes" },
                                  },
                                })
                              );
                            }}
                          />{" "}
                          YES
                        </label>
                        <label
                          className={`dontCreateElementBtn btn btn-link btn-linkNo mw-70 ${
                            formData.deploysharedvpcendpoints.value === "no" &&
                            "active"
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={
                              formData.deploysharedvpcendpoints.value === "no"
                            }
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) =>
                                update(state, {
                                  deploysharedvpcendpoints: {
                                    value: { $set: "no" },
                                  },
                                })
                              );
                            }}
                          />{" "}
                          NO
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {empty && (
                  <NoInitialData />
                )}
                {formData.deploysharedvpcendpoints.value === "yes" && (
                  <div class="row">
                    <div class="col-12">
                      <h4 class="font-weight-bold my-1">COMPUTE</h4>
                    </div>
                    <div class="mainContentArea show-hide-content row px-2 py-1 my-1 mb-4">
                      <div class="col-xxl-4 col-lg-6 col-12 d-flex flex-column">
                        <h4 class="mt-2 mb-1">
                          EC2, EC2 Messages, EBS, Elastic Load Balancing
                        </h4>
                        <Endpoint
                          name="ec2"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="ec2messages"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="ebs"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="elasticloadbalancing"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                      <div class="col-xxl-4 col-lg-6 col-12 d-flex flex-column">
                        <h4 class="mt-2 mb-1">
                          Elastic Beanstalk & Elastic Beanstalk Health
                        </h4>
                        <Endpoint
                          name="elasticbeanstalk"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="elasticbeanstalk-health"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>

                        <h4 class="mt-2 mb-1">EC2 Image Builder</h4>
                        <Endpoint
                          name="imagebuilder"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                    </div>

                    <div class="col-12">
                      <h4 class="font-weight-bold my-1">CONTAINERS</h4>
                    </div>
                    <div class="mainContentArea show-hide-content row px-2 py-1 my-1 mb-4">
                      <div class="col-xxl-4 col-lg-6 col-12  d-flex flex-column">
                        <h4 class="mt-2 mb-1">Elastic Container Registry</h4>
                        <Endpoint
                          name="ecr.api"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="ecr.dkr"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                      <div class="col-xxl-4 col-lg-6 col-12  d-flex flex-column">
                        <h4 class="mt-2 mb-1">Elastic Container Service</h4>
                        <Endpoint
                          name="ecs"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="ecs-agent"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="ecs-telemetry"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                    </div>

                    <div class="col-12">
                      <h4 class="font-weight-bold my-1">STORAGE</h4>
                    </div>
                    <div class="mainContentArea show-hide-content row px-2 py-1 my-1 mb-4">
                      <div class="col-xxl-4 col-lg-6 col-12 d-flex flex-column">
                        <h4 class="mt-2 mb-1">EFS</h4>
                        <Endpoint
                          name="elasticfilesystem"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                      <div class="col-xxl-4 col-lg-6 col-12 d-flex flex-column">
                        <h4 class="mt-2 mb-1">Storage Gateway</h4>
                        <Endpoint
                          name="storagegateway"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                    </div>

                    <div class="col-12">
                      <h4 class="font-weight-bold my-1">DATABASE</h4>
                    </div>
                    <div class="mainContentArea show-hide-content row px-2 py-1 my-1 mb-4">
                      <div class="col-xxl-4 col-lg-6 col-12 order-1 d-flex flex-column">
                        <h4 class="mt-2 mb-1">Amazon QLDB Session</h4>
                        <Endpoint
                          name="qldb.session"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                      <div class="col-xxl-4 col-lg-6 col-12 order-xxl-2 order-lg-3 order-2 d-flex flex-column">
                        <h4 class="mt-2 mb-1">RDS and RDS Data</h4>
                        <Endpoint
                          name="rds"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="rds-data"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>

                      <div class="col-xxl-4 col-lg-6 col-12 order-xxl-3 order-lg-2 order-3  d-flex flex-column">
                        <h4 class="mt-2 mb-1">Amazon Keyspaces</h4>
                        <Endpoint
                          name="cassandra"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                    </div>

                    <div class="col-12">
                      <h4 class="font-weight-bold my-1">
                        NETWORKING & CONTENT DELIVERY
                      </h4>
                    </div>
                    <div class="mainContentArea show-hide-content row px-2 py-1 my-1 mb-4">
                      <div class="col-xxl-4 col-lg-6 col-12  d-flex flex-column">
                        <h4 class="mt-2 mb-1">API Gateway</h4>
                        <Endpoint
                          name="execute-api"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                      <div class="col-xxl-4 col-lg-6 col-12 d-flex flex-column">
                        <h4 class="mt-2 mb-1">AWS App Mesh</h4>
                        <Endpoint
                          name="appmesh-envoy-management"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                    </div>

                    <div class="col-12">
                      <h4 class="font-weight-bold my-1">
                        SECURITY, IDENTITY, & COMPLIANCE
                      </h4>
                    </div>
                    <div class="mainContentArea show-hide-content row px-2 py-1 my-1 mb-4">
                      <div class="col-xxl-4 col-lg-6 col-12  order-1 d-flex flex-column">
                        <h4 class="mt-2 mb-1">IAM Access Analyzer</h4>

                        <Endpoint
                          name="access-analyzer"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <h4 class="mt-2 mb-1">Certificate Manager</h4>
                        <Endpoint
                          name="acm-pca"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <h4 class="mt-2 mb-1">Secure Token Service</h4>
                        <Endpoint
                          name="sts"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                      <div class="col-xxl-4 col-lg-6 col-12 order-xxl-2 order-lg-3 order-2 d-flex flex-column">
                        <h4 class="mt-2 mb-1">Secrets Manager</h4>
                        <Endpoint
                          name="secretsmanager"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <h4 class="mt-2 mb-1 d-xxl-flex d-none">
                          Key Management Service
                        </h4>
                        <Endpoint
                          name="kms"
                          isExist={isExist}
                          onCheck={onCheck}
                          specialClass="form-line w-100  my-1  d-xxl-inline-flex d-none align-items-center"
                        ></Endpoint>
                        <h4 class="mt-2 mb-1 d-xxl-flex d-none">
                          Amazon Macie
                        </h4>
                        <Endpoint
                          name="macie2"
                          isExist={isExist}
                          onCheck={onCheck}
                          specialClass="form-line w-100  my-1  d-xxl-inline-flex d-none align-items-center"
                        ></Endpoint>
                      </div>

                      <div class="col-xxl-4 col-lg-6 col-12 order-xxl-3 order-lg-2 order-3 d-flex flex-column">
                        <h4 class="mt-2 mb-1 d-xxl-none d-inline-flex">
                          Key Management Service
                        </h4>
                        <Endpoint
                          name="kms"
                          isExist={isExist}
                          onCheck={onCheck}
                          specialClass="form-line w-100  my-1  d-xxl-none d-inline-flex align-items-center"
                        ></Endpoint>
                        <h4 class="mt-2 mb-1 d-xxl-none d-inline-flex">
                          Amazon Macie
                        </h4>
                        <Endpoint
                          name="macie2"
                          isExist={isExist}
                          onCheck={onCheck}
                          specialClass="form-line w-100  my-1  d-xxl-none d-inline-flex align-items-center"
                        ></Endpoint>

                        <h4 class="mt-2 mb-1">Directory Service</h4>
                        <Endpoint
                          name="clouddirectory"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                    </div>

                    <div class="col-12">
                      <h4 class="font-weight-bold my-1">
                        MANAGEMENT & GOVERNANCE
                      </h4>
                    </div>
                    <div class="mainContentArea show-hide-content row px-2 py-1 my-1 mb-4">
                      <div class="col-xxl-4 col-lg-6 col-12 order-1 d-flex flex-column">
                        <h4 class="mt-2 mb-1">CloudWatch</h4>

                        <Endpoint
                          name="logs"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="synthetics"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>

                        <Endpoint
                          name="monitoring"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>

                        <h4 class="mt-2 mb-1">CloudTrail</h4>
                        <Endpoint
                          name="cloudtrail"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <h4 class="mt-2 mb-1">Systems Manager</h4>
                        <Endpoint
                          name="ssm"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="ssmmessages"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                      <div class="col-xxl-4 col-lg-6 col-12 order-xxl-2 order-lg-3 order-2  d-flex flex-column">
                        <h4 class="mt-2 mb-1">AWS Auto Scaling</h4>
                        <Endpoint
                          name="autoscaling"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="autoscaling-plans"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="application-autoscaling"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <h4 class="mt-2 mb-1 d-xxl-flex d-none">Config</h4>
                        <Endpoint
                          name="config"
                          isExist={isExist}
                          onCheck={onCheck}
                          specialClass="form-line w-100  my-1  d-xxl-inline-flex d-none align-items-center"
                        ></Endpoint>
                      </div>

                      <div class="col-xxl-4 col-lg-6 col-12  order-xxl-3 order-lg-2 order-3 d-flex flex-column">
                        <h4 class="mt-2 mb-1 d-xxl-none d-inline-flex">
                          Config
                        </h4>
                        <Endpoint
                          name="config"
                          isExist={isExist}
                          onCheck={onCheck}
                          specialClass="form-line w-100  my-1  d-xxl-none d-inline-flex align-items-center"
                        ></Endpoint>
                        <h4 class="mt-2 mb-1">License Manager</h4>
                        <Endpoint
                          name="license-manager"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>

                        <h4 class="mt-2 mb-1">CloudFormation</h4>
                        <Endpoint
                          name="cloudformation"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <h4 class="mt-2 mb-1">Service Catalog</h4>
                        <Endpoint
                          name="servicecatalog"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                    </div>

                    <div class="col-12">
                      <h4 class="font-weight-bold my-1">
                        APPLICATION INTEGRATION
                      </h4>
                    </div>
                    <div class="mainContentArea show-hide-content row px-2 py-1 my-1 mb-4">
                      <div class="col-xxl-4 col-lg-6 col-12  d-flex flex-column">
                        <h4 class="mt-2 mb-1">Amazon EventBridge</h4>
                        <Endpoint
                          name="events"
                          isExist={isExist}
                          onCheck={onCheck}
                          specialClass="form-line w-100    d-inline-flex align-items-center"
                        ></Endpoint>
                        <h4 class="mt-2 mb-1">SQS</h4>
                        <Endpoint
                          name="sqs"
                          isExist={isExist}
                          onCheck={onCheck}
                          specialClass="form-line w-100    d-inline-flex align-items-center"
                        ></Endpoint>
                      </div>
                      <div class="col-xxl-4 col-lg-6 col-12  d-flex flex-column">
                        <h4 class="mt-2 mb-1">Step Functions</h4>
                        <Endpoint
                          name="states"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <h4 class="mt-2 mb-1">SNS</h4>
                        <Endpoint
                          name="sns"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                    </div>

                    <div class="col-12">
                      <h4 class="font-weight-bold my-1">ANALYTICS</h4>
                    </div>
                    <div class="mainContentArea show-hide-content row px-2 py-1 my-1 mb-4">
                      <div class="col-xxl-4 col-lg-6 col-12  d-flex order-1 flex-column">
                        <h4 class="mt-2 mb-1">Athena</h4>
                        <Endpoint
                          name="athena"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <h4 class="mt-2 mb-1">Kinesis</h4>
                        <Endpoint
                          name="kinesis-firehose"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="kinesis-streams"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                      <div class="col-xxl-4 col-lg-6 col-12 order-xxl-2 order-lg-3 order-2 d-flex flex-column">
                        <h4 class="mt-2 mb-1">Amazon Redshift</h4>
                        <Endpoint
                          name="redshift"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <h4 class="mt-2 mb-1 d-xxl-flex d-none">
                          AWS Data Exchange
                        </h4>
                        <Endpoint
                          name="dataexchange"
                          isExist={isExist}
                          onCheck={onCheck}
                          specialClass="form-line w-100  my-1  d-xxl-inline-flex d-none align-items-center"
                        ></Endpoint>
                      </div>

                      <div class="col-xxl-4 col-lg-6 col-12 order-xxl-3 order-lg-2 order-3 d-flex flex-column">
                        <h4 class="mt-2 mb-1 d-xxl-none d-inline-flex">
                          AWS Data Exchange
                        </h4>
                        <Endpoint
                          name="dataexchange"
                          isExist={isExist}
                          onCheck={onCheck}
                          specialClass="form-line w-100  my-1  d-xxl-none d-inline-flex align-items-center"
                        ></Endpoint>
                        <h4 class="mt-2 mb-1">EMR</h4>
                        <Endpoint
                          name="elasticmapreduce"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <h4 class="mt-2 mb-1">AWS Glue</h4>
                        <Endpoint
                          name="glue"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                    </div>

                    <div class="col-12">
                      <h4 class="font-weight-bold my-1">MACHINE LEARNING</h4>
                    </div>
                    <div class="mainContentArea show-hide-content row px-2 py-1 my-1 mb-4">
                      <div class="col-xxl-4 col-lg-6 col-12  order-1 d-flex flex-column">
                        <h4 class="mt-2 mb-1">Amazon Sagemaker</h4>

                        <Endpoint
                          name="notebook"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="sagemaker.api"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="sagemaker.runtime"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="elastic-inference.runtime"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <h4 class="mt-2 mb-1">Amazon Faud Detector</h4>
                        <Endpoint
                          name="frauddetector"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <h4 class="mt-2 mb-1">Amazon Textract</h4>
                        <Endpoint
                          name="textract"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                      <div class="col-xxl-4 col-lg-6 col-12  order-xxl-2 order-lg-3 order-2 d-flex flex-column">
                        <h4 class="mt-2 mb-1">Amazon Codeguru</h4>
                        <Endpoint
                          name="codeguru-profiler"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="codeguru-reviewer"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <h4 class="mt-2 mb-1 d-xxl-flex d-none">
                          Amazon Kendra
                        </h4>
                        <Endpoint
                          name="kendra"
                          isExist={isExist}
                          onCheck={onCheck}
                          specialClass="form-line w-100  my-1  d-xxl-inline-flex d-none align-items-center"
                        ></Endpoint>
                        <h4 class="mt-2 mb-1 d-xxl-flex d-none">
                          Amazon Transcribe Streaming
                        </h4>
                        <Endpoint
                          name="transcribestreaming"
                          isExist={isExist}
                          onCheck={onCheck}
                          specialClass="form-line w-100  my-1  d-xxl-inline-flex d-none align-items-center"
                        ></Endpoint>
                      </div>

                      <div class="col-xxl-4 col-lg-6 col-12  order-xxl-3 order-lg-2 order-3 d-flex flex-column">
                        <h4 class="mt-2 mb-1 d-xxl-none d-inline-flex">
                          Amazon Kendra
                        </h4>
                        <Endpoint
                          name="kendra"
                          isExist={isExist}
                          onCheck={onCheck}
                          specialClass="form-line w-100  my-1  d-xxl-none d-inline-flex align-items-center"
                        ></Endpoint>

                        <h4 class="mt-2 mb-1 d-xxl-none d-inline-flex">
                          Amazon Transcribe Streaming
                        </h4>
                        <Endpoint
                          name="transcribestreaming"
                          isExist={isExist}
                          onCheck={onCheck}
                          specialClass="form-line w-100  my-1  d-xxl-none d-inline-flex align-items-center"
                        ></Endpoint>
                        <h4 class="mt-2 mb-1">Amazon Comprehend</h4>
                        <Endpoint
                          name="comprehend"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>

                        <h4 class="mt-2 mb-1">Amazon Rekognition</h4>
                        <Endpoint
                          name="rekognition"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>

                        <h4 class="mt-2 mb-1">Amazon Translate</h4>
                        <Endpoint
                          name="translate"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                    </div>

                    <div class="col-12">
                      <h4 class="font-weight-bold my-1">INTERNET OF THINGS</h4>
                    </div>
                    <div class="mainContentArea show-hide-content row px-2 py-1 my-1 mb-4">
                      <div class="col-xxl-4 col-lg-6 col-12 d-flex flex-column">
                        <h4 class="mt-2 mb-1">IOT SiteWise</h4>
                        <Endpoint
                          name="iotsitewise.data"
                          isExist={isExist}
                          onCheck={onCheck}
                          specialClass="form-line w-100 d-inline-flex align-items-center"
                        ></Endpoint>
                      </div>
                    </div>

                    <div class="col-12">
                      <h4 class="font-weight-bold my-1">DEVELOPER TOOLS</h4>
                    </div>
                    <div class="mainContentArea show-hide-content row px-2 py-1 my-1 mb-4">
                      <div class="col-lg-6 col-12 d-flex flex-column">
                        <h4 class="mt-2 mb-1">
                          Code Commit, Code Build, Code Artifact, Code Deploy,
                          Code Pipeline
                        </h4>
                        <Endpoint
                          name="api"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="codeartifact.repositories"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="codebuild"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="codecommit"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="codedeploy"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="codedeploy-commands-secure"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="codepipeline"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="git-codecommit"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                    </div>

                    <div class="col-12">
                      <h4 class="font-weight-bold my-1">CUSTOMER ENGAGEMENT</h4>
                    </div>
                    <div class="mainContentArea show-hide-content row px-2 py-1 my-1 mb-4">
                      <div class="col-xxl-4 col-lg-6 col-12  d-flex flex-column">
                        <h4 class="mt-2 mb-1">Simple Email Service</h4>
                        <Endpoint
                          name="email-smtp"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                    </div>

                    <div class="col-12">
                      <h4 class="font-weight-bold my-1">END USER COMPUTING</h4>
                    </div>
                    <div class="mainContentArea show-hide-content row px-2 py-1 my-1 mb-4">
                      <div class="col-xxl-4 col-lg-6 col-12  d-flex flex-column">
                        <h4 class="mt-2 mb-1">Workspaces</h4>
                        <Endpoint
                          name="workspaces"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                      <div class="col-xxl-4 col-lg-6 col-12  d-flex flex-column">
                        <h4 class="mt-2 mb-1">AppStream</h4>
                        <Endpoint
                          name="appstream.api"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="appstream.streaming"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                    </div>

                    <div class="col-12">
                      <h4 class="font-weight-bold my-1">
                        MIGRATION & TRANSFER
                      </h4>
                    </div>
                    <div class="mainContentArea show-hide-content row px-2 py-1 my-1 mb-4">
                      <div class="col-xxl-4 col-lg-6 col-12 d-flex flex-column">
                        <h4 class="mt-2 mb-1">AWS Connector</h4>
                        <Endpoint
                          name="awsconnector"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <h4 class="mt-2 mb-1">AWS Transfer Family</h4>
                        <Endpoint
                          name="transfer"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <Endpoint
                          name="transfer.server"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                      <div class="col-xxl-4 col-lg-6 col-12  d-flex flex-column">
                        <h4 class="mt-2 mb-1">Server Migration Service</h4>
                        <Endpoint
                          name="sms"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                        <h4 class="mt-2 mb-1">Data Sync</h4>
                        <Endpoint
                          name="datasync"
                          isExist={isExist}
                          onCheck={onCheck}
                        ></Endpoint>
                      </div>
                    </div>
                  </div>
                )}
                {console.log(formData)}
              </div>
              </fieldset>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(ROUTES.TRAFFIC_INSPECTION + "/" + projectId)
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Traffic Inspection
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.INFRASTRUCTURE_PRIVATEDNS + "/" + projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Private DNS
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VPCSharedEndpoints;
