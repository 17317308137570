import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import * as ROUTES from "../constants/routes";
import useAwsAmplify from "../hooks/useAwsAmplify";

const schema = yup.object().shape({
  code: yup.string().required(),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{6,99}$/,
      "password needs minimum 8 (upper, lower, special char and number)"
    )
    .required(),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords don't match"),
});

const VerifyCodeForm = ({ setState, setError, setIsLoading, currentEmail }) => {
  const awsAmplify = useAwsAmplify();
  const history = useHistory();
  const location = useLocation();

  const username = currentEmail;

  if (!currentEmail) {
    setState("RESETPWD");
  }

  const [showPwd, setShowPwd] = useState(false);
  const [showRepeatPwd, setShowRepeatPwd] = useState(false);
  //const [error, setError] = useState("");
  //const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState, errors } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = async (formValues) => {
    setIsLoading(true);
    setError("");

    try {
      await awsAmplify.forgotPasswordSubmit(
        username,
        formValues.code,
        formValues.password
      );
      setIsLoading(false);

      setState("SIGNIN");
    } catch (err) {
      setIsLoading(false);

      if (err.message) {
        setError(err.message);
      }
    }
  };

  const resendCode = async () => {
    setIsLoading(true);
    setError("");

    try {
      await awsAmplify.forgotPassword(username);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);

      setError(err);
    }
  };

  useEffect(() => {
    const errorMsgs = [];

    if(errors){
      for(const field in errors){
        if(errors[field].message){
          errorMsgs.push(errors[field].message);
        }
      }
    }

    if (errorMsgs.length > 0) {
      setError(errorMsgs);
    }
  }, [errors.code, errors.password, errors.repeatPassword]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="#" className={errors.code && "highlight"}>
          <img src="images/icons/check1.svg" alt="" />
          <input
            type="text"
            placeholder="Verification Code"
            name="code"
            ref={register}
          />
        </label>
        <label htmlFor="#" className={errors.password && "highlight"}>
          <img src="images/icons/lock.svg" alt="" />
          <input
            type={showPwd ? "text" : "password"}
            placeholder="New Password"
            name="password"
            ref={register}
          />
          <img className="toggle-password" src="images/icons/eye.svg" alt="" onClick={() => setShowPwd(!showPwd)} />
        </label>
        <label htmlFor="#" className={errors.repeatPassword && "highlight"}>
          <img src="images/icons/lock.svg" alt="" />
          <input
            type={showRepeatPwd ? "text" : "password"}
            placeholder="Again Password"
            name="repeatPassword"
            ref={register}
          />
          <img className="toggle-password" src="images/icons/eye.svg" alt="" onClick={() => setShowRepeatPwd(!showRepeatPwd)} />
        </label>

        <button type="button" className="resend-button" onClick={resendCode}>
          Resend Code
        </button>

        <button type="submit" className="button">
          Verify
        </button>

        <div className="back-to">
          <a className="myclickable" onClick={() => setState("RESETPWD")}>Back to Reset Password</a>
        </div>
      </form>
    </>
  );
};

export default VerifyCodeForm;
