import React from "react";

const NoInitialData = () => (
  <div className="row d-flex align-items-center my-4">
    <span className="font-12" style={{color: "#2196F3"}}>
      Configuration information for this feature is yet to be provided
    </span>
  </div>
);

export default NoInitialData;
