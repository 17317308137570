import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import useServiceAccounts from "../../../../hooks/useServiceAccounts";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";
import { validateTime } from "../../../../helpers/validator";

const PREFIX = "/aws/dataprotection/dataprivacy";

const getInitialFormData = (data) => {
  const initialFormData = {
    deploydataprivacysolution: {
      value: data[`${PREFIX}/deploydataprivacysolution`] || "yes",
    },
    dataprivacysolution: {
      value: data[`${PREFIX}/dataprivacysolution`] || "macie",
    },
    macie_delegatedadminaccount: {
      value: data[`${PREFIX}/macie/delegatedadminaccount`] || "",
    },
    macie_monitoralls3bucketsinorg: {
      value: data[`${PREFIX}/macie/monitoralls3bucketsinorg`] || "yes",
    },
    macie_executionschedule: {
      value: data[`${PREFIX}/macie/executionschedule`] || "",
    },
    macie_publishsensitivedatafindings:{
      value: data[`${PREFIX}/macie/publish/sensitivedatafindings`] || "yes",
    },
    macie_publishpolicyfindings:{
      value: data[`${PREFIX}/macie/publish/policyfindings`] || "yes",
    },
    protegrity_deploymentmodel: {
      value:
        data[`${PREFIX}/protegrity/deploymentmodel`] || "integratewithexisting",
    },
    protegrity_targetdatastores: data[`${PREFIX}/protegrity/targetdatastores`]
      ? data[`${PREFIX}/protegrity/targetdatastores`].split(",")
      : [],
    protegrity_protectionmode: {
      value: data[`${PREFIX}/protegrity/protectionmode`] || "detectiononly",
    },
    protegrity_executionschedule: {
      value: data[`${PREFIX}/protegrity/executionschedule`] || "",
    },
    dgsecure_deploymentmodel: {
      value:
        data[`${PREFIX}/dgsecure/deploymentmodel`] || "integratewithexisting",
    },
    dgsecure_targetdatastores: data[`${PREFIX}/dgsecure/targetdatastores`]
      ? data[`${PREFIX}/dgsecure/targetdatastores`].split(",")
      : [],
    dgsecure_protectionmode: {
      value: data[`${PREFIX}/dgsecure/protectionmode`] || "detectiononly",
    },
    dgsecure_executionschedule: {
      value: data[`${PREFIX}/dgsecure/executionschedule`] || "",
    },
  };


  return initialFormData;
};

const DataPrivacy = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));
  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();
  const serviceAccounts = useServiceAccounts(projectId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        setEmpty(result.data.empty);
        setFormData(convertBEToFE(result.data));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if (empty) {
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploydataprivacysolution`]:
        formData.deploydataprivacysolution.value,
    };

    if (formData.deploydataprivacysolution.value === "yes") {
      be[`${PREFIX}/dataprivacysolution`] = formData.dataprivacysolution.value;

      if (formData.dataprivacysolution.value === "macie") {
        if (formData.macie_delegatedadminaccount.value !== "") {
          be[`${PREFIX}/macie/delegatedadminaccount`] =
            formData.macie_delegatedadminaccount.value;
        }

        be[`${PREFIX}/macie/monitoralls3bucketsinorg`] =
          formData.macie_monitoralls3bucketsinorg.value;

        if (
          formData.macie_executionschedule.value !== ""
        ) {
          be[`${PREFIX}/macie/executionschedule`] =
          formData.macie_executionschedule.value;
        }

        be[`${PREFIX}/macie/publish/sensitivedatafindings`] =
        formData.macie_publishsensitivedatafindings.value;

        be[`${PREFIX}/macie/publish/policyfindings`] =
        formData.macie_publishpolicyfindings.value;
      }

      if (formData.dataprivacysolution.value === "dgsecure") {
        be[`${PREFIX}/dgsecure/deploymentmodel`] =
          formData.dgsecure_deploymentmodel.value;

        be[`${PREFIX}/dgsecure/protectionmode`] =
          formData.dgsecure_protectionmode.value;

        if (formData.dgsecure_targetdatastores.length > 0) {
          be[`${PREFIX}/dgsecure/targetdatastores`] =
            formData.dgsecure_targetdatastores.join(",");
        }

        if (
          formData.dgsecure_executionschedule.value !== ""
        ) {
          be[`${PREFIX}/dgsecure/executionschedule`] =
          formData.dgsecure_executionschedule.value;
        }
      }

      if (formData.dataprivacysolution.value === "protegrity") {
        be[`${PREFIX}/protegrity/deploymentmodel`] =
          formData.protegrity_deploymentmodel.value;

        be[`${PREFIX}/protegrity/protectionmode`] =
          formData.protegrity_protectionmode.value;

        if (formData.protegrity_targetdatastores.length > 0) {
          be[`${PREFIX}/protegrity/targetdatastores`] =
            formData.protegrity_targetdatastores.join(",");
        }

        if (
          formData.protegrity_executionschedule.value !== ""
        ) {
          be[`${PREFIX}/protegrity/executionschedule`] =
          formData.protegrity_executionschedule.value;
        }
      }
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const onFieldChange = (value, key) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key]: { value: { $set: value } } })
    );
  };

  const onFieldBlur = (validateFunc, value, key) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          [key]: { isValid: { $set: errorMsg === undefined } },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, { [key]: { isValid: { $set: false } } })
      );
    }
  };

  const updateArray = (isAdd, value, key) => {
    console.log(isAdd);
    console.log(value);
    console.log(key);
    setIsDirty(true);

    if (isAdd) {
      setFormData((state) => update(state, { [key]: { $push: [value] } }));
    } else {
      const idx = formData[key].indexOf(value);
      setFormData((state) => update(state, { [key]: { $splice: [[idx, 1]] } }));
    }
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Data Protection</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Data Protection"
                isDirty={isDirty}
                //dashboardRoute={ROUTES.GOVERNANCE_SERVICE_CONTROL}
                designRoute={ROUTES.DESIGN_DATA_PROTECTION}
              ></DomainMenu>
              <fieldset
                disabled={disabled}
                className="mainArea fitDeviceHeight"
              >
                <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <div className="row d-flex align-items-center">
                    <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                      <h3>Data Privacy</h3>
                    </div>
                    <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                      <div className="d-inline-flex yes-no-area align-items-center">
                        <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                          Deploy Data Privacy Solution
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                          <RadioGroup
                            field="deploydataprivacysolution"
                            value={formData.deploydataprivacysolution.value}
                            onChange={updateRadio}
                            isLarge={true}
                          ></RadioGroup>
                        </div>
                      </div>
                    </div>
                  </div>

                  {empty && <NoInitialData />}

                  {formData.deploydataprivacysolution.value === "yes" && (
                    <>
                      <div className="show-hide-content px-0">
                        <div className=" col-12 d-inline-flex selectionArea my-1 px-3 justify-content-between flex-wrap py-1">
                          <label className="font-weight-bold my-2 font-12">
                            Data Privacy Solution
                          </label>
                          <div className="my-2">
                            <label className="container">
                              Amazon Macie
                              <input
                                type="radio"
                                name="dataPrivacySolution"
                                checked={
                                  formData.dataprivacysolution.value ===
                                  "macie"
                                }
                                onChange={(e) =>
                                  updateRadio(
                                    "dataprivacysolution",
                                    "macie"
                                  )
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div className="my-2">
                            <label className="container">
                              PKWare Dataguise DgSecure
                              <input
                                type="radio"
                                name="dataPrivacySolution"
                                checked={
                                  formData.dataprivacysolution.value ===
                                  "dgsecure"
                                }
                                onChange={(e) =>
                                  updateRadio("dataprivacysolution", "dgsecure")
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div className="my-2">
                            <label className="container">
                              Protegrity
                              <input
                                type="radio"
                                name="dataPrivacySolution"
                                checked={
                                  formData.dataprivacysolution.value ===
                                  "protegrity"
                                }
                                onChange={(e) =>
                                  updateRadio(
                                    "dataprivacysolution",
                                    "protegrity"
                                  )
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      {formData.dataprivacysolution.value === "macie" && (
                        <>
                          <div className="mainContentArea row px-2 py-1 my-1">
                            <div className="d-inline-flex align-items-center row px-2">
                              <div className="col-xxl-3 col-md-4 col-12 p-0 my-1">
                                <span className="mb-0 font-12">
                                  Delegated Macie Admin Account
                                </span>
                              </div>
                              <div className="col-xxl-9 col-md-8 col-12 flex-wrap d-inline-flex">
                                <div className="mr-3 my-1">
                                  <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  {(serviceAccounts && (serviceAccounts.security
                                    .concat(serviceAccounts.shared)).length === 0)  ?
                                (
                                  <div className="col-md-12 flex-wrap d-inline-flex">
                                  <span className="warning">
                                  This section depends on the platform accounts information in
                                  Account Framework section that is yet to be provided
                                </span>
                                </div>
                                ) : (
                                 <> 
                                    <select
                                      className="form-control gray"
                                      value={
                                        formData
                                          .macie_delegatedadminaccount
                                          .value
                                      }
                                      onChange={(e) => {
                                        onFieldChange(
                                          e.target.value,
                                          "macie_delegatedadminaccount"
                                        );
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {serviceAccounts.security
                                        .concat(serviceAccounts.shared)
                                        .map((sa, idxSa) => (
                                          <option key={idxSa} value={sa}>
                                            {sa}
                                          </option>
                                        ))}
                                    </select>
                                 </>
                                  )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="d-inline-flex align-items-center row px-2 py-2">
                              <div className="col-xxl-3 col-md-4 col-12 p-0 my-1">
                                <span className="mb-0 font-12">
                                  Monitor all S3 buckets in the org
                                </span>
                              </div>
                              <div className="col-xxl-9 col-md-8 col-12 flex-wrap d-inline-flex">
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                                  <label
                                    className={`btn btn-link btn-linkYes h-20 ${
                                      formData
                                        .macie_monitoralls3bucketsinorg
                                        .value === "yes"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="macie_monitoralls3bucketsinorg"
                                      checked={
                                        formData
                                          .macie_monitoralls3bucketsinorg
                                          .value === "yes"
                                      }
                                      onChange={(e) =>
                                        onFieldChange(
                                          "yes",
                                          "macie_monitoralls3bucketsinorg"
                                        )
                                      }
                                    />{" "}
                                    YES
                                  </label>
                                  <label
                                    className={`btn btn-link btn-linkNo h-20  ${
                                      formData
                                        .macie_monitoralls3bucketsinorg
                                        .value === "no"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="macie_monitoralls3bucketsinorg"
                                      checked={
                                        formData
                                          .macie_monitoralls3bucketsinorg
                                          .value === "no"
                                      }
                                      onChange={(e) =>
                                        onFieldChange(
                                          "no",
                                          "macie_monitoralls3bucketsinorg"
                                        )
                                      }
                                    />{" "}
                                    NO
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="d-inline-flex align-items-center row px-2">
                              <div className="col-md-3 my-1 p-0">
                                <span className="mb-0 font-12">
                                  Execution Schedule
                                </span>
                              </div>
                              <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                <div className="mr-3 my-1 ">
                                  <div className="w-250  border-10 my-1  p-relative   d-inline-flex align-items-center">
                                    <label className="mb-0 d-flex align-items-center font-12 mr-2">
                                      Every
                                    </label>
                                    <select
                                      className="form-control gray"
                                      value={
                                        formData
                                          .macie_executionschedule
                                          .value
                                      }
                                      onChange={(e) => {
                                        onFieldChange(
                                          e.target.value,
                                          "macie_executionschedule"
                                        );
                                      }}
                                    >
                                      <option value="">Select</option>
                                      <option value="Daily">Daily</option>
                                      <option value="Weekly">Weekly</option>
                                      <option value="Monthly">Monthly</option>
                                    </select>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                        
                            <div className="d-inline-flex align-items-center row px-2 py-2">
                              <div className="col-xxl-3 col-md-4 col-12 p-0 my-1">
                                <span className="mb-0 font-12">
                                   Publish Sensitive Data Findings
                                </span>
                              </div>
                              <div className="col-xxl-9 col-md-8 col-12 flex-wrap d-inline-flex">
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                                  <label
                                    className={`btn btn-link btn-linkYes h-20 ${
                                      formData
                                        .macie_publishsensitivedatafindings
                                        .value === "yes"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="macie_publishsensitivedatafindings"
                                      checked={
                                        formData
                                          .macie_publishsensitivedatafindings
                                          .value === "yes"
                                      }
                                      onChange={(e) =>
                                        onFieldChange(
                                          "yes",
                                          "macie_publishsensitivedatafindings"
                                        )
                                      }
                                    />{" "}
                                    YES
                                  </label>
                                  <label
                                    className={`btn btn-link btn-linkNo h-20  ${
                                      formData
                                        .macie_publishsensitivedatafindings
                                        .value === "no"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="macie_publishsensitivedatafindings"
                                      checked={
                                        formData
                                          .macie_publishsensitivedatafindings
                                          .value === "no"
                                      }
                                      onChange={(e) =>
                                        onFieldChange(
                                          "no",
                                          "macie_publishsensitivedatafindings"
                                        )
                                      }
                                    />{" "}
                                    NO
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="d-inline-flex align-items-center row px-2 py-2">
                              <div className="col-xxl-3 col-md-4 col-12 p-0 my-1">
                                <span className="mb-0 font-12">
                                   Publish Policy Findings
                                </span>
                              </div>
                              <div className="col-xxl-9 col-md-8 col-12 flex-wrap d-inline-flex">
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                                  <label
                                    className={`btn btn-link btn-linkYes h-20 ${
                                      formData
                                        .macie_publishpolicyfindings
                                        .value === "yes"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="macie_publishpolicyfindings"
                                      checked={
                                        formData
                                          .macie_publishpolicyfindings
                                          .value === "yes"
                                      }
                                      onChange={(e) =>
                                        onFieldChange(
                                          "yes",
                                          "macie_publishpolicyfindings"
                                        )
                                      }
                                    />{" "}
                                    YES
                                  </label>
                                  <label
                                    className={`btn btn-link btn-linkNo h-20  ${
                                      formData
                                        .macie_publishpolicyfindings
                                        .value === "no"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="macie_publishpolicyfindings"
                                      checked={
                                        formData
                                          .macie_publishpolicyfindings
                                          .value === "no"
                                      }
                                      onChange={(e) =>
                                        onFieldChange(
                                          "no",
                                          "macie_publishpolicyfindings"
                                        )
                                      }
                                    />{" "}
                                    NO
                                  </label>
                                </div>
                              </div>
                            </div>

                          </div>
                        </>
                      )}

                      {formData.dataprivacysolution.value === "dgsecure" && (
                        <>
                          <div className="show-hide-content px-0">
                            <div className=" col-12 d-inline-flex selectionArea my-1 px-3 justify-content-start  flex-wrap py-1">
                              <label className="font-weight-bold my-2 font-12">
                                Deployment Model
                              </label>
                              <div className="my-2 mx-5">
                                <label className="container">
                                  Integrate with existing
                                  <input
                                    type="radio"
                                    name="dgsecure_deploymentModel"
                                    checked={
                                      formData.dgsecure_deploymentmodel
                                        .value === "integratewithexisting"
                                    }
                                    onChange={(e) =>
                                      updateRadio(
                                        "dgsecure_deploymentmodel",
                                        "integratewithexisting"
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="my-2">
                                <label className="container">
                                  Deploy in AWS
                                  <input
                                    type="radio"
                                    name="dgsecure_deploymentModel"
                                    checked={
                                      formData.dgsecure_deploymentmodel
                                        .value === "deployinaws"
                                    }
                                    onChange={(e) =>
                                      updateRadio(
                                        "dgsecure_deploymentmodel",
                                        "deployinaws"
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>

                            <div className="d-inline-flex align-items-left my-1 px-1">
                              <div className="row px-2 my-1">
                                <span className="mb-0 font-12">
                                  Target Data Stores
                                </span>
                              </div>

                              <div className="row px-1 my-1">
                                <div className="formRow w-100">
                                  <div className="one form-line d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={formData.dgsecure_targetdatastores.includes(
                                          "S3"
                                        )}
                                        onChange={(e) =>
                                          updateArray(
                                            e.target.checked,
                                            "S3",
                                            "dgsecure_targetdatastores"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">S3</span>
                                  </div>
                                </div>
                                <div className="formRow w-100">
                                  <div className="one form-line d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={formData.dgsecure_targetdatastores.includes(
                                          "RDS"
                                        )}
                                        onChange={(e) =>
                                          updateArray(
                                            e.target.checked,
                                            "RDS",
                                            "dgsecure_targetdatastores"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">RDS</span>
                                  </div>
                                </div>
                                <div className="formRow w-100">
                                  <div className="one form-line d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={formData.dgsecure_targetdatastores.includes(
                                          "Redshift"
                                        )}
                                        onChange={(e) =>
                                          updateArray(
                                            e.target.checked,
                                            "Redshift",
                                            "dgsecure_targetdatastores"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">
                                      Redshift
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-12 d-inline-flex selectionArea my-1 px-3 justify-content-start  flex-wrap py-1">
                              <label className="font-weight-bold my-2 font-12">
                                Protection
                              </label>
                              <div className="my-2 mx-5">
                                <label className="container">
                                  Detection only
                                  <input
                                    type="radio"
                                    name="dgsecure_protectionmode"
                                    checked={
                                      formData.dgsecure_protectionmode.value ===
                                      "detectiononly"
                                    }
                                    onChange={(e) =>
                                      updateRadio(
                                        "dgsecure_protectionmode",
                                        "detectiononly"
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="my-2">
                                <label className="container">
                                  Detect and Protect
                                  <input
                                    type="radio"
                                    name="dgsecure_protectionmode"
                                    checked={
                                      formData.dgsecure_protectionmode.value ===
                                      "detectandprotect"
                                    }
                                    onChange={(e) =>
                                      updateRadio(
                                        "dgsecure_protectionmode",
                                        "detectandprotect"
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="col-12 d-inline-flex justify-content-startrow my-1 px-3 py-3">
                              <div className="col-md-1 d-inline-flex my-1 p-0">
                                <span className="mb-0 font-12 d-inline-flex align-items-center">
                                  Execution Schedule
                                </span>
                              </div>
                              <div className="col-md-6 d-inline-flex px-1">
                                <div className="mr-3 my-1 ">
                                  <div className="w-250  border-10 my-1  p-relative   d-inline-flex align-items-center">
                                    <label className="mb-0 d-flex align-items-center font-12 mr-2">
                                      Every
                                    </label>
                                    <select
                                      className="form-control gray"
                                      value={
                                        formData
                                          .dgsecure_executionschedule
                                          .value
                                      }
                                      onChange={(e) => {
                                        onFieldChange(
                                          e.target.value,
                                          "dgsecure_executionschedule"
                                        );
                                      }}
                                    >
                                      <option value="">Select</option>
                                      <option value="Daily">Daily</option>
                                      <option value="Weekly">Weekly</option>
                                      <option value="Monthly">Monthly</option>
                                    </select>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {formData.dataprivacysolution.value === "protegrity" && (
                        <>
                          <div className="show-hide-content px-0">
                            <div className=" col-12 d-inline-flex selectionArea my-1 px-3 justify-content-start  flex-wrap py-1">
                              <label className="font-weight-bold my-2 font-12">
                                Deployment Model
                              </label>
                              <div className="my-2 mx-5">
                                <label className="container">
                                  Integrate with existing
                                  <input
                                    type="radio"
                                    name="protegrity_deploymentmodel"
                                    checked={
                                      formData.protegrity_deploymentmodel
                                        .value === "integratewithexisting"
                                    }
                                    onChange={(e) =>
                                      updateRadio(
                                        "protegrity_deploymentmodel",
                                        "integratewithexisting"
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="my-2">
                                <label className="container">
                                  Deploy in AWS
                                  <input
                                    type="radio"
                                    name="protegrity_deploymentmodel"
                                    checked={
                                      formData.protegrity_deploymentmodel
                                        .value === "deployinaws"
                                    }
                                    onChange={(e) =>
                                      updateRadio(
                                        "protegrity_deploymentmodel",
                                        "deployinaws"
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>

                            <div className="d-inline-flex align-items-left my-1 px-1">
                              <div className="row px-2 my-1">
                                <span className="mb-0 font-12">
                                  Target Data Stores
                                </span>
                              </div>

                              <div className="row px-1 my-1">
                                <div className="formRow w-100">
                                  <div className="one form-line d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={formData.protegrity_targetdatastores.includes(
                                          "S3"
                                        )}
                                        onChange={(e) =>
                                          updateArray(
                                            e.target.checked,
                                            "S3",
                                            "protegrity_targetdatastores"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">S3</span>
                                  </div>
                                </div>
                                <div className="formRow w-100">
                                  <div className="one form-line d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={formData.protegrity_targetdatastores.includes(
                                          "RDS"
                                        )}
                                        onChange={(e) =>
                                          updateArray(
                                            e.target.checked,
                                            "RDS",
                                            "protegrity_targetdatastores"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">RDS</span>
                                  </div>
                                </div>
                                <div className="formRow w-100">
                                  <div className="one form-line d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={formData.protegrity_targetdatastores.includes(
                                          "Redshift"
                                        )}
                                        onChange={(e) =>
                                          updateArray(
                                            e.target.checked,
                                            "Redshift",
                                            "protegrity_targetdatastores"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">
                                      Redshift
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-12 d-inline-flex selectionArea my-1 px-3 justify-content-start  flex-wrap py-1">
                              <label className="font-weight-bold my-2 font-12">
                                Protection
                              </label>
                              <div className="my-2 mx-5">
                                <label className="container">
                                  Detection only
                                  <input
                                    type="radio"
                                    name="protegrity_protectionmode"
                                    checked={
                                      formData.protegrity_protectionmode
                                        .value === "detectiononly"
                                    }
                                    onChange={(e) =>
                                      updateRadio(
                                        "protegrity_protectionmode",
                                        "detectiononly"
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="my-2">
                                <label className="container">
                                  Detect and Protect
                                  <input
                                    type="radio"
                                    name="protegrity_protectionmode"
                                    checked={
                                      formData.protegrity_protectionmode
                                        .value === "detectandprotect"
                                    }
                                    onChange={(e) =>
                                      updateRadio(
                                        "protegrity_protectionmode",
                                        "detectandprotect"
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="col-12 d-inline-flex justify-content-startrow my-1 px-3 py-3">
                              <div className="col-md-1 d-inline-flex my-1 p-0">
                                <span className="mb-0 font-12 d-inline-flex align-items-center">
                                  Execution Schedule
                                </span>
                              </div>
                              <div className="col-md-6 d-inline-flex px-1">
                                <div className="mr-3 my-1 ">
                                  <div className="w-250  border-10 my-1  p-relative   d-inline-flex align-items-center">
                                    <label className="mb-0 d-flex align-items-center font-12 mr-2">
                                      Every
                                    </label>
                                    <select
                                      className="form-control gray"
                                      value={
                                        formData
                                          .protegrity_executionschedule
                                          .value
                                      }
                                      onChange={(e) => {
                                        onFieldChange(
                                          e.target.value,
                                          "protegrity_executionschedule"
                                        );
                                      }}
                                    >
                                      <option value="">Select</option>
                                      <option value="Daily">Daily</option>
                                      <option value="Weekly">Weekly</option>
                                      <option value="Monthly">Monthly</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.DATA_PROTECTION_KEY_MANAGEMENT + "/" + projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Key Management
                    </p>
                  </button>
                  {/* <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.DATA_PROTECTION_BACKUP_COPY_JOBS + "/" + projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Data Lost Protection
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataPrivacy;
