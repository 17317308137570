import React, { useState } from "react";
import ForgetPassword from "./ForgotPassword";
import SwitchUserForm from "./SwitchUserForm";
import NewPasswordRequired from "./NewPasswordRequired";
import VerifyCode from "./VerifyCode";

const SwitchUserContainer = ({ close, setInfo }) => {
  const [isSwitchUser, setIsSwitchUser] = useState(true);
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isVerifyCode, setIsVerifyCode] = useState(false);

  const [email, setEmail] = useState("");
  const [tempAuthUser, setTempAuthUser] = useState(null);

  return (
    <>
      <div className="justify-content-center align-items-center popupArea switch-user">
        {isSwitchUser && (
          <SwitchUserForm
            close={close}
            setIsForgotPassword={setIsForgotPassword}
            setIsSwitchUser={setIsSwitchUser}
            setTempAuthUser={setTempAuthUser}
            setIsNewPasswordRequired={setIsNewPasswordRequired}
            setInfo={setInfo}
          ></SwitchUserForm>
        )}
        {isNewPasswordRequired && (
          <NewPasswordRequired
            close={close}
            setIsSwitchUser={setIsSwitchUser}
            setIsNewPasswordRequired={setIsNewPasswordRequired}
            tempAuthUser={tempAuthUser}
          ></NewPasswordRequired>
        )}
        {isForgotPassword && (
          <ForgetPassword
            close={close}
            setIsForgotPassword={setIsForgotPassword}
            setIsSwitchUser={setIsSwitchUser}
            setIsVerifyCode={setIsVerifyCode}
            setEmail={setEmail}
          ></ForgetPassword>
        )}
        {isVerifyCode && (
          <VerifyCode
            email={email}
            setIsVerifyCode={setIsVerifyCode}
            setIsForgotPassword={setIsForgotPassword}
            setIsSwitchUser={setIsSwitchUser}
          ></VerifyCode>
        )}
      </div>
    </>
  );
};

export default SwitchUserContainer;
