import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import * as ROUTES from "../constants/routes";
import useAwsAmplify from "./useAwsAmplify";

const getGroup = (cognitoUser) => {
  let group = null;
  if (
    cognitoUser &&
    cognitoUser.signInUserSession &&
    cognitoUser.signInUserSession.idToken &&
    cognitoUser.signInUserSession.idToken.payload
  ) {
    const groups =
      cognitoUser.signInUserSession.idToken.payload["cognito:groups"];

    if (groups.indexOf("SuperAdmin") !== -1) {
      group = "SuperAdmin";
    } else if (groups.indexOf("ClientAdmin") !== -1) {
      group = "ClientAdmin";
    } else if (groups.indexOf("User") !== -1) {
      group = "User";
    }
  }
  return group;
};

const useSession = () => {
  const history = useHistory();
  const awsAmplify = useAwsAmplify();

  const [authUser, setAuthUser] = useState(null);
  const [group, setGroup] = useState("");
  const [isUser, setIsUser] = useState(false);
  const [isClientAdmin, setIsClientAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const fetchAuthenticatedUser = async () => {
      try {
        const authenticatedUser = await awsAmplify.auth.currentAuthenticatedUser();

        if (authenticatedUser) {
          setAuthUser(authenticatedUser);
          const group = getGroup(authenticatedUser);
          setGroup(group);
          setIsUser(group === "User");
          setIsClientAdmin(group === "ClientAdmin");
          setIsSuperAdmin(group === "SuperAdmin");
        } else {
          window.location.href = "/";
          //history.push(ROUTES.LANDING);
        }
      } catch (err) {
        window.location.href = "/";
        //history.push(ROUTES.LANDING);
      }
    };

    fetchAuthenticatedUser();
  }, []);

  return { authUser, isUser, isClientAdmin, isSuperAdmin, group};
};

export default useSession;
