import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "./DetailsTags";
import DetailsGrid from "./DetailsGrid";

//const projectId = "google_1";
const dashboardName = "DGWs";

const SubMenu = () => {
  const { projectId } = useParams();

  return (
    <>
      <h3>Hybrid Network</h3>
      <div className="otherTableTitles ">
        <h3 className="font-15 ">
          <Link className="text-color-gray" to={ROUTES.VPN + "/" + projectId}>
            VPN
          </Link>
        </h3>
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPN_DIRECT_CONNECT + "/" + projectId}>
            Direct Connect
          </Link>
        </h3>
        <h3 className="font-15 activeTable">DGW</h3>
      </div>
    </>
  );
};

const Details = ({ close, selectedRow }) => {
  const [type, setType] = useState("Details");

  return (
    <div className="justify-content-center align-items-end account-detail">
      <div className="w-100 s-flex flex-column account-detail-area">
        <button className="exitAccount" onClick={() => close()}>
          <img src="../images/close.svg" />
        </button>
        <div className="">
          <ul className="account-detail-link m-0 p-0 d-inline-flex">
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Details" && "my_active"
              }`}
              onClick={() => setType("Details")}
            >
              Details
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Virtual Interface Attachments" && "my_active"
              }`}
              onClick={() => setType("Virtual Interface Attachments")}
            >
              Virtual Interface Attachments
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Gateway Associations" && "my_active"
              }`}
              onClick={() => setType("Gateway Associations")}
            >
              Gateway Associations
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 account-detail-container-area">
          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Details" && "inaktif"
            }`}
          >
            <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">AWS Account</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.AccountId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Region</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Region}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">DGW Name</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.directConnectGatewayName}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Owner Account Id
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.ownerAccount}
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">DGW ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.directConnectGatewayId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Amazon side ASN
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.amazonSideAsn}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">State</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.directConnectGatewayState}
                </p>
              </div>
            </div>
          </div>

          <DetailsGrid
            type={type}
            typeName="Virtual Interface Attachments"
            array={selectedRow.Gatewayassociations}
            metadata={[
              { header: "Id", key: "Id" },
              { header: "Region", key: "Region" },
              { header: "AWS Account", key: "AWS Account" },
              { header: "State", key: "State" },
              { header: "DCX Connection Id", key: "DCX Connection Id" },
              { header: "VLAN Id", key: "VLAN Id" },
              { header: "Type", key: "Type" },
            ]}
          ></DetailsGrid>

          <DetailsGrid
            type={type}
            typeName="Gateway Associations"
            array={selectedRow.Gatewayassociations}
            metadata={[
              { header: "Gateway Id", key: "associatedGateway.id" },
              { header: "Gateway Type", key: "associatedGateway.type" },
              { header: "Region", key: "associatedGateway.region" },
              { header: "AWS Account", key: "associatedGateway.ownerAccount" },
              {
                header: "Allowed Prefixes",
                key: "allowedPrefixesToDirectConnectGateway.cidr",
                isArray: true,
              },
              { header: "State", key: "associationState" },
            ]}
          ></DetailsGrid>
        </div>
      </div>
    </div>
  );
};

const Navigations = () => {
  const { projectId } = useParams();
  return (
    <>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.TGW + "/" + projectId}
      >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Transit Gateway</p>
      </Link>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.NETWORK_FIREWALL_FIREWALLS + "/" + projectId}
      >
        <p className="m-0 p-0 mr-2 font-weight-bold">Network Firewall</p>
        <img src="../images/ribbon-designRight.svg" />
      </Link>
    </>
  );
};

const VPNDirectConnectGateway = () => {
  const columns = useMemo(
    () => [
      {
        Header: "AWS Account",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "DGW Name",
        accessor: "directConnectGatewayName",
      },
      {
        Header: "Owner Account Id",
        accessor: "ownerAccount",
      },
      {
        Header: "DGW ID",
        accessor: "directConnectGatewayId",
      },
      {
        Header: "Amazon side ASN",
        accessor: "amazonSideAsn",
      },
      {
        Header: "State",
        accessor: "directConnectGatewayState",
      },
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "network",
    title: "Network",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_CENTERS,
    designRoute: ROUTES.DESIGN_NETWORK,
    SubMenu: SubMenu,
  });
};

export default VPNDirectConnectGateway;
