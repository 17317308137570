import React, { useState } from "react";
import {
  validateAccountName, validateSubscriptionName
} from "../../../../helpers/validator";

const LongAccount3 = ({
  paramPrefix,
  subscriptionName,
  checkboxLabel,
  newAccount = false,
  mandatory,
  defaultChecked = true, // true is for disabled blueSwitch style
  formValues,
  updateValue,
  validate,
  makeChange,
  setErrorMsg,
  remove,
  updateSwitch,
  setIsDirty
}) => {
  const subscriptionname1 = `${paramPrefix}/${subscriptionName}/subscriptionname`;

  const [checked, setChecked] = useState(
    mandatory ? mandatory : defaultChecked
  );

  const onChange = (name, value) => {
    setErrorMsg(null);
    setIsDirty(true);

    updateValue(name, value);

    if (value !== "" && value.trim() !== "") {
      //makeChange(name);
    }
  };

  const onBlur = (name, value, validator) => {
    if (value !== "") {
      const errorMsg = validator(value);

      validate(name, errorMsg === undefined);

      if (errorMsg) {
        setErrorMsg(
          `${checkboxLabel} Subscription Name ${errorMsg}`
        );
      }
    } else {
      validate(name, false);
      // need to reset isValid? user entered valid value first then remove, isValid = true
    }
  };

  return (
    <>
      <div className="d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
        <div
          className={`one form-line my-lg-0 my-1 d-inline-flex align-items-center ${
            mandatory ? "blueSwitch" : ""
          } `}
        >
          <label className="switch mb-0">
            <input
              type="checkbox"
              disabled={mandatory}
              checked={checked}
              onChange={(e) => {
                setIsDirty(true);
                setChecked(e.target.checked); 

                if (newAccount) {
                  if (e.target.checked === false) {
                    delete formValues[subscriptionname1];

                    remove();
                  }
                } else {
                  updateSwitch(subscriptionName, e.target.checked);
                }

                if(e.target.checked === false){
                  updateValue(subscriptionname1, "");
                }
              }}
            />
            <span className="slider round"></span>
          </label>
          <span className="switchLabel">{checkboxLabel}</span>
        </div>

        {checked && (
          <>
          <div className="d-inline-flex my-lg-0 my-1 form-line two">
            <div className="d-flex align-items-center mr-3 green-style text-nowrap">
                Subscription Name
              </div>
              <input
                type="text"
                className={`form-control ${(formValues[subscriptionname1] && formValues[subscriptionname1].value !== "" && !formValues[subscriptionname1].isValid) && "invalid"} `}
                placeholder=""
                name={subscriptionname1}
                value={
                  (formValues[subscriptionname1] &&
                    formValues[subscriptionname1].value) ||
                  ""
                }
                onChange={(e) => {
                  onChange(subscriptionname1, e.target.value);
                }}
                onBlur={(e) => {
                  onBlur(subscriptionname1, e.target.value, validateSubscriptionName);
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LongAccount3;
