import React, { useMemo } from "react";
import { useTable, useSortBy, useGlobalFilter } from "react-table";

const PlanComponent = ({ planData, features }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Summary",
        accessor: "summary",
        Cell: ({ value, row }) => {
          if (value) {
            return (
              <>
                <div className="plan__table-td">
                  <span>{value}</span>
                  <span>
                    <i>{row.original.description}</i>
                  </span>
                </div>
              </>
            );
          } else {
            return null;
          }
        },
      },
      {
        id: "description",
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          if (value) {
            return (
              <>
                <u>
                  <img
                    src={`../images/icons/icon-${value
                      .replace(/\s/gi, "-")
                      .toLowerCase()}.png`}
                    alt=""
                  ></img>
                  <span>{value}</span>
                </u>
              </>
            );
          } else {
            return null;
          }
        },
      },
      {
        Header: "Assignee",
        accessor: "assignee",
      },
      {
        Header: "Due Date",
        accessor: "duedate",
      },
      {
        Header: "Sprint",
        accessor: "sprint",
      },
    ],
    []
  );

  const Feature = ({ feature }) => {
    const featureKey = feature.replace(/\s/gi, "");

    const {
      rows,
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      state: { globalFilter },
      setGlobalFilter,
      setSortBy,
    } = useTable(
      {
        columns,
        data: planData[featureKey],
        initialState: {
          globalFilter: "",
          hiddenColumns: ["description"],
          //sortBy: [{ id: "duedate", desc: false }],
        },
      },
      useGlobalFilter,
      useSortBy
    );

    return (
      <>
        <div className="plan__section">
          <div className="plan__section-head">
            <p className="plan__section-title">{feature}</p>
            <div className="plan__section-search">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="6.86393"
                  cy="6.86393"
                  r="5.24333"
                  stroke="#33325C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5108 10.7831L12.5665 12.8335"
                  stroke="#33325C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <label>
                <input
                  type="text"
                  name=""
                  placeholder="Search by keyword"
                  value={globalFilter || ""}
                  onChange={(e) => {
                    setGlobalFilter(e.target.value);
                  }}
                ></input>
              </label>
            </div>
          </div>

          {planData[featureKey] && planData[featureKey].length === 0 ? (
            <div className="row">
              <div className="col-sm-12 h-100">
                <span>No issues to display</span>
              </div>
            </div>
          ) : (
            <div className="plan__section-scroll">
              <div className="plan__section-body">
                {/* <div className="plan__table-scroll"> */}
                <div className="plan__table-row">
                  <table
                    id="datatable"
                    className="plan__table"
                    {...getTableProps()}
                  >
                    <thead className="plan__table-head">
                      {headerGroups.map((headerGroup) => (
                        <>
                          <tr
                            className="plan__table-tr"
                            {...headerGroup.getHeaderGroupProps()}
                          >
                            {headerGroup.headers.map((column) => (
                              <>
                                {column.Header === "Summary" && (
                                  <>
                                    <div class="plan__table-col" resize-node-js>
                                      <th
                                        className="plan__table-th"
                                        {...(column.isSorted
                                          ? column.isSortedDesc
                                            ? "descending"
                                            : "ascending"
                                          : "")}
                                        {...column.getHeaderProps(
                                          column.getSortByToggleProps()
                                        )}
                                      >
                                        <p>
                                          <span
                                            {...column.getSortByToggleProps()}
                                          >
                                            {column.render("Header")}
                                          </span>
                                        </p>
                                      </th>
                                    </div>

                                    <div
                                      class="plan__table-col"
                                      resize-handle-js
                                    >
                                      <a href="#" class=""></a>
                                    </div>
                                  </>
                                )}
                                {column.Header !== "Summary" && (
                                  <th
                                    className="plan__table-th"
                                    {...(column.isSorted
                                      ? column.isSortedDesc
                                        ? "descending"
                                        : "ascending"
                                      : "")}
                                    {...column.getHeaderProps(
                                      column.getSortByToggleProps()
                                    )}
                                  >
                                    <p>
                                      <span {...column.getSortByToggleProps()}>
                                        {column.render("Header")}
                                      </span>
                                    </p>
                                  </th>
                                )}
                              </>
                            ))}
                          </tr>
                        </>
                      ))}
                    </thead>

                    <tbody
                      className="plan__table-body"
                      {...getTableBodyProps()}
                      style={{ overflowY: "scroll" }}
                    >
                      {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} className="plan__table-tr">
                            {row.cells.map((cell) => {
                              return (
                                <>
                                  {cell.column.Header === "Summary" && (
                                    <>
                                      <div class="plan__table-col">
                                        <td {...cell.getCellProps()}>
                                          {cell.render("Cell")}
                                        </td>
                                      </div>
                                      <div class="plan__table-col"></div>
                                    </>
                                  )}
                                  {cell.column.Header !== "Summary" && (
                                    <>
                                      <td
                                        {...cell.getCellProps()}
                                        className="plan__table-td"
                                      >
                                        {cell.render("Cell")}
                                      </td>
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {/* </div> */}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
        <section id="plan" className="plan">
          <div className="plan__wrapper">
            {features.map((feature) => (
              <div className="plan__content py-2 mt-2 ">
                <Feature feature={feature}></Feature>
              </div>
            ))}
          </div>
        </section>
        <section className="overlay" id="overlay"></section>
      </div>
    </>
  );
};

export default PlanComponent;
