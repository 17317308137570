import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import {
  validateAccountEmailId,
  validateAccountName,
  validateTagKey,
  validateTagValue,
} from "../../../../helpers/validator";
import useSession from "../../../../hooks/useSession";
import { getError } from "../../../../helpers/errorHelper";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/governance/taggingstrategy";

const RESOURCES = [
  //"AllSupportedResources",
  "appmesh:mesh",
  "appmesh:virtualService",
  "appmesh:virtualNode",
  "appmesh:virtualRouter",
  "cognito-idp:userpool",
  "comprehend:document-classifier",
  "comprehend:entity-recognizer",
  "config:aggregation-authorization",
  "config:config-aggregator",
  "config:config-rule",
  "directconnect:dxcon",
  "directconnect:dxlag",
  "directconnect:dxvif",
  "dms:endpoint",
  "dms:es",
  "dms:rep",
  "dms:subgrp",
  "dms:task",
  "dynamodb:table",
  "ec2:capacity-reservation",
  "ec2:client-vpn-endpoint",
  "ec2:customer-gateway",
  "ec2:dhcp-options",
  "ec2:elastic-ip",
  "ec2:fleet",
  "ec2:image",
  "ec2:instance",
  "ec2:internet-gateway",
  "ec2:launch-template",
  "ec2:natgateway",
  "ec2:network-acl",
  "ec2:network-interface",
  "ec2:reserved-instances",
  "ec2:route-table",
  "ec2:security-group",
  "ec2:snapshot",
  "ec2:spot-instance-request",
  "ec2:subnet",
  "ec2:traffic-mirror-filter",
  "ec2:traffic-mirror-target",
  "ec2:traffic-mirror-session",
  "ec2:volume",
  "ec2:vpc",
  "ec2:vpc-endpoint",
  "ec2:vpc-endpoint-service",
  "ec2:vpc-peering- connection",
  "ec2:vpn-connection",
  "ec2:vpn-gateway",
  "elasticbeanstalk:applicationversion",
  "elasticbeanstalk:configurationontemplate",
  "rds:cluster-pg",
  "rds:es",
  "rds:og",
  "rds:pg",
  "rds:ri",
  "rds:secgrp",
  "rds:subgrp",
  "redshift:cluster",
  "redshift:dbgroup",
  "redshift:dbname",
  "redshift:dbuser",
  "redshift:eventsubscription",
  "s3:bucket",
  "secretsmanager:secret",
  "sqs:queue",
  "states:activity",
  "states:stateMachine",
  "storagegateway:gateway",
  "storagegateway:share",
  "storagegateway:tape",
  "storagegateway:volume",
  "ssm:automation-execution",
  "ssm:document",
  "ssm:opsitem",
  "ssm:session",
  "ssm:patchbaseline",
  "ssm:maintenancewindowtask",
  "ssm:managed-instance",
  "workspaces:directory",
  "workspaces:workspace",
  "workspaces:workspacebundle",
  "workspaces:workspaceimage",
  "workspaces:workspaceipgroup",
  "appmesh:route",
  "apigateway:apikeys",
  "apigateway:domainnames",
  "apigateway:restapi",
  "elasticbeanstalk:platform",
  "ecs:task-set",
  "ecs:cluster",
  "ecs:service",
  "elasticache:cluster",
  "elasticloadbalancing:loadbalancer",
  "apigateway:stages",
  "athena:workgroup",
  "acm:certificate",
  "cloudfront:distribution",
  "cloudfront:streaming-distribution",
  "cloudtrail:trail",
  "cloudwatch:alarm",
  "events:event-bus",
  "events:rule",
  "codebuild:project",
  "codecommit:repository",
  "codepipeline:actiontype",
  "codepipeline:pipeline",
  "codepipeline:webhook",
  "cognito-identity:identitypool",
  "elasticloadbalancing:targe",
  "redshift:hsmclientcertificate",
  "redshift:hsmconfiguration",
  "redshift:parametergroup",
  "redshift:snapshot",
  "redshift:snapshotcopygrant",
  "ec2:fpga-image",
  "kms:key",
  "elasticfilesystem:file-system",
  "lambda:function",
  "ec2:host-reservation",
  "elasticbeanstalk:applicationversion",
  "firehose:deliverystream",
  "fsx:backup",
  "fsx:file-system",
  "iotanalytics:channel",
  "iotanalytics:dataset",
  "iotanalytics:datastore",
  "iotanalytics:pipeline",
  "iotevents:detectorModel",
  "iotevents:input",
  "redshift:subnetgroup",
  "redshift:snapshotschedule",
  "ram:resource-share",
  "resource-groups:group",
  "route53:hostedzone",
  "route53resolver:resolver-endpoint",
  "route53resolver:resolver-rule",
].sort();

const AccountTag = ({
  formData,
  setErrorMsg,
  onFieldChange,
  onFieldBlur,
  removeTag,
  addTagvalue,
  removeTagvalue,
  tagkind,
  tag,
  idxTag,
  setFormData,
  disabled
}) => (
  <tr>
    <td>
      <input
        className={`form-control bg-white w-170 ${
          formData[tagkind][idxTag].tagkey.value !== "" &&
          formData[tagkind][idxTag].tagkey.isValid === false
            ? "invalid"
            : ""
        }`}
        type="text"
        placeholder={tag.placeholder}
        value={tag.tagkey.value}
        onChange={(e) => {
          const value = e.target.value;

          const allTagkinds = [];
          allTagkinds.push(tagkind);

          for (const tagkind of allTagkinds) {
            for (const [idxOtherItem, otherItem] of formData[
              tagkind
            ].entries()) {
              if (idxTag !== idxOtherItem) {
                if (otherItem.tagkey.isValid && otherItem.tagkey.value !== "") {
                  if (
                    otherItem.tagkey.value.toLowerCase() === value.toLowerCase()
                  ) {
                    setErrorMsg("Tagkey must be unique");
                    return false;
                  }
                }
              }
            }
          }

          onFieldChange(value, "tagkey", tagkind, idxTag);
        }}
        onBlur={(e) =>
          onFieldBlur(validateTagKey, e.target.value, "tagkey", tagkind, idxTag)
        }
      />
    </td>

    <td className="showRightContenBtn">
      <div className=" color-special-switch justify-content-start d-inline-flex align-items-center w-100 pr-2 py-1">
        <label className="switch mb-0 ml-0">
          <input
            type="checkbox"
            checked={tag.specifiedtagvaluesonly.value === "yes"}
            onChange={(e) => {
              onFieldChange(
                e.target.checked ? "yes" : "no",
                "specifiedtagvaluesonly",
                tagkind,
                idxTag
              );

              if (e.target.checked && tag.tagvalues.length === 0) {
                setFormData((state) =>
                  update(state, {
                    [tagkind]: {
                      [idxTag]: {
                        tagvalues: {
                          $push: [createTag()],
                        },
                      },
                    },
                  })
                );
              }
            }}
          />
          <span className="slider round"></span>
        </label>
      </div>
    </td>

    <td className="rightContentArea">
      <div
        className={`rightContent ${
          tag.specifiedtagvaluesonly.value === "yes" ? "" : "closed"
        }`}
      >
        <div className="d-inline-flex">
          {tag.tagvalues.map((tagvalue, idxTagvalue) => (
            <div className="mr-3" key={idxTagvalue}>
              <div className="w-150 bg-white border-10  p-relative   d-inline-flex align-items-center">
                <button disabled={disabled}
                  type="button"
                  className="closableInput"
                  onClick={() => removeTagvalue(tagkind, idxTag, idxTagvalue)}
                ></button>
                <input
                  type="text"
                  className={`form-control bg-transparent ${
                    tagvalue.value !== "" && tagvalue.isValid === false
                      ? "invalid"
                      : ""
                  }`}
                  placeholder={tagvalue.placeholder}
                  value={tagvalue.value}
                  onChange={(e) => {
                    const value = e.target.value;

                    for (const [
                      idxOtherItem,
                      otherItem,
                    ] of tag.tagvalues.entries()) {
                      if (idxTagvalue !== idxOtherItem) {
                        if (otherItem.isValid && otherItem.value !== "") {
                          if (
                            otherItem.value.toLowerCase() ===
                            value.toLowerCase()
                          ) {
                            setErrorMsg("Tag Value must be unique");
                            return false;
                          }
                        }
                      }
                    }
                    onFieldChange(
                      value,
                      "tagkey",
                      tagkind,
                      idxTag,
                      idxTagvalue
                    );
                  }}
                  onBlur={(e) =>
                    onFieldBlur(
                      validateTagValue,
                      e.target.value,
                      "tagkey",
                      tagkind,
                      idxTag,
                      idxTagvalue
                    )
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </td>
    <td className="rightContentArea2">
      <div
        className={`rightContent ${
          tag.specifiedtagvaluesonly.value === "yes" ? "" : "closed"
        }`}
      >
        <button disabled={disabled}
          type="button"
          onClick={() => {
            addTagvalue(tagkind, idxTag);
          }}
        >
          <img src="../images/purplePlusIcon.svg" />{" "}
        </button>
      </div>
    </td>
    <td className="text-center">
      <button disabled={disabled}
        type="button"
        onClick={() => {
          removeTag(tagkind, idxTag);
        }}
      >
        <img src="../images/close.svg" />{" "}
      </button>
    </td>
  </tr>
);

const ResourceTag = ({
  formData,
  setErrorMsg,
  onFieldChange,
  onFieldBlur,
  removeTag,
  addTagvalue,
  removeTagvalue,
  exists,
  add,
  remove,
  tagkind,
  tag,
  idxTag,
  setFormData,
  disabled
}) => (
  <>
    <tr className="bbw-0">
      <td className="bblr-0">
        <input
          className={`form-control bg-white w-170 ${
            tag.tagkey.value !== "" && tag.tagkey.isValid === false
              ? "invalid"
              : ""
          }`}
          type="text"
          placeholder={tag.placeholder}
          value={tag.tagkey.value}
          onChange={(e) => {
            const value = e.target.value;

            const allTagkinds = [];
            allTagkinds.push(tagkind);

            for (const tagkind of allTagkinds) {
              for (const [idxOtherItem, otherItem] of formData[
                tagkind
              ].entries()) {
                if (idxTag !== idxOtherItem) {
                  if (
                    otherItem.tagkey.isValid &&
                    otherItem.tagkey.value !== ""
                  ) {
                    if (
                      otherItem.tagkey.value.toLowerCase() ===
                      value.toLowerCase()
                    ) {
                      setErrorMsg("Tagkey must be unique");
                      return false;
                    }
                  }
                }
              }
            }

            onFieldChange(value, "tagkey", tagkind, idxTag);
          }}
          onBlur={(e) =>
            onFieldBlur(
              validateTagKey,
              e.target.value,
              "tagkey",
              tagkind,
              idxTag
            )
          }
        />
      </td>
      <td>
        <div className=" color-special-switch justify-content-start d-inline-flex align-items-center w-100 pr-2 py-1">
          <label className="switch mb-0 ml-0">
            <input
              type="checkbox"
              checked={tag.tagkeycapitalizationcompliance.value === "yes"}
              onChange={(e) => {
                onFieldChange(
                  e.target.checked ? "yes" : "no",
                  "tagkeycapitalizationcompliance",
                  tagkind,
                  idxTag
                );
              }}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </td>

      <td className="showRightContenBtn">
        <div className=" color-special-switch justify-content-start d-inline-flex align-items-center w-100 pr-2 py-1">
          <label className="switch mb-0 ml-0">
            <input
              type="checkbox"
              checked={tag.specifiedtagvaluesonly.value === "yes"}
              onChange={(e) => {
                onFieldChange(
                  e.target.checked ? "yes" : "no",
                  "specifiedtagvaluesonly",
                  tagkind,
                  idxTag
                );

                if (e.target.checked && tag.tagvalues.length === 0) {
                  setFormData((state) =>
                    update(state, {
                      [tagkind]: {
                        [idxTag]: {
                          tagvalues: {
                            $push: [createTag()],
                          },
                        },
                      },
                    })
                  );
                }
              }}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </td>

      <td className="rightContentArea">
        <div
          className={`rightContent ${
            tag.specifiedtagvaluesonly.value === "yes" ? "" : "closed"
          }`}
        >
          <div className="d-inline-flex">
            {tag.tagvalues.map((tagvalue, idxTagvalue) => (
              <div className="mr-3" key={idxTagvalue}>
                <div className="w-150 bg-white border-10  p-relative   d-inline-flex align-items-center">
                  <button disabled={disabled}
                    type="button"
                    className="closableInput"
                    onClick={() => removeTagvalue(tagkind, idxTag, idxTagvalue)}
                  ></button>
                  <input
                    type="text"
                    className={`form-control bg-transparent ${
                      tagvalue.value !== "" && tagvalue.isValid === false
                        ? "invalid"
                        : ""
                    }`}
                    placeholder={tagvalue.placeholder}
                    value={tagvalue.value}
                    onChange={(e) => {
                      const value = e.target.value;

                      for (const [
                        idxOtherItem,
                        otherItem,
                      ] of tag.tagvalues.entries()) {
                        if (idxTagvalue !== idxOtherItem) {
                          if (otherItem.isValid && otherItem.value !== "") {
                            if (
                              otherItem.value.toLowerCase() ===
                              value.toLowerCase()
                            ) {
                              setErrorMsg("Tag Value must be unique");
                              return false;
                            }
                          }
                        }
                      }
                      onFieldChange(
                        value,
                        "tagkey",
                        tagkind,
                        idxTag,
                        idxTagvalue
                      );
                    }}
                    onBlur={(e) =>
                      onFieldBlur(
                        validateTagValue,
                        e.target.value,
                        "tagkey",
                        tagkind,
                        idxTag,
                        idxTagvalue
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </td>
      <td className="rightContentArea2">
        <div
          className={`rightContent ${
            tag.specifiedtagvaluesonly.value === "yes" ? "" : "closed"
          }`}
        >
          <button disabled={disabled}
            type="button"
            onClick={() => {
              addTagvalue(tagkind, idxTag);
            }}
          >
            <img src="../images/purplePlusIcon.svg" />{" "}
          </button>
        </div>
      </td>
      <td className="text-center">
        <button disabled={disabled}
          type="button"
          onClick={() => {
            removeTag(tagkind, idxTag);
          }}
        >
          <img src="../images/close.svg" />{" "}
        </button>
      </td>
    </tr>

    <tr className="btw-0">
      <td className="btlr-0"></td>
      <td colSpan="2">
        <div className="selectionInTableRow">
          <div className="one form-line d-inline-flex align-items-center p-relative">
            <div className="rightElement special-selectbox h-100 align-items-center d-inline-flex">
              {tag.preventnoncomplianttagoperations.value === "yes" && (
                <>
                  <select
                    className="w-150 show-hide-this form-control my-1 mr-3"
                    value=""
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!exists(tagkind, idxTag, value)) {
                        add(tagkind, idxTag, value);
                      }
                    }}
                  >
                    <option value="">Select</option>
                    {RESOURCES.map((item, idxItem) => (
                      <option key={idxItem} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {tag.resourcetypestoenforcecompliance.map((r, idxR) => (
                    <div
                      className="w-250 bg-white border-10  p-relative  d-inline-flex align-items-center m-1"
                      key={idxR}
                    >
                      <button disabled={disabled}
                        type="button"
                        className="closableInput"
                        onClick={() => remove(tagkind, idxTag, r)}
                      ></button>
                      <input
                        type="text"
                        className="form-control bg-transparent"
                        placeholder={r}
                        readOnly
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
            <label className="showRightElements  switch mb-0 mr-1">
              <input
                type="checkbox"
                checked={tag.preventnoncomplianttagoperations.value === "yes"}
                onChange={(e) => {
                  onFieldChange(
                    e.target.checked ? "yes" : "no",
                    "preventnoncomplianttagoperations",
                    tagkind,
                    idxTag
                  );
                }}
              />
              <span className="slider round"></span>
            </label>
            <span className="switchLabel lh-md-1">
              Prevent noncompliant operations for this tag
            </span>
          </div>
        </div>
      </td>

      <td></td>
      <td></td>
      <td></td>
    </tr>

    <tr className="btw-0">
      <td className="btlr-0"></td>
      <td colSpan="2">
        <div className="selectionInTableRow">
          <div className="one form-line d-inline-flex align-items-center p-relative">
            <label className="showRightElements  switch mb-0 mr-1">
              <input
                type="checkbox"
                checked={tag.costallocationtag.value === "yes"}
                onChange={(e) => {
                  onFieldChange(
                    e.target.checked ? "yes" : "no",
                    "costallocationtag",
                    tagkind,
                    idxTag
                  );
                }}
              />
              <span className="slider round"></span>
            </label>
            <span className="switchLabel lh-md-1">
              Cost allocation tag
            </span>
          </div>
        </div>
      </td>

      <td></td>
      <td></td>
      <td></td>
    </tr>
  </>
);


const getInitialFormData = (data) => {
  const initialFormData = {
    automatetaggingstrategy: {
      value: data[`${PREFIX}/automatetaggingstrategy`] || "no",
    },
    prefixtagkeywithprojectname: {
      value: data[`${PREFIX}/prefixtagkeywithprojectname`] || "yes",
    },
    deployaccounttaggingscproduct: {
      value: data[`${PREFIX}/deployaccounttaggingscproduct`] || "yes",
    },
    accounttags_mandatory: [],
    accounttags_optional: [],
    resourcetags_mandatory: [],
    resourcetags_optional: [],
    costallocationtagsjson: []
  };

  if (data[`${PREFIX}/accounttagslist`]) {
    data[`${PREFIX}/accounttagslist`].split(",").forEach((tagName) => {
      if (data[`${PREFIX}/${tagName}/tagpresence`] === "mandatory") {
        const tagkeyArray = data[`${PREFIX}/${tagName}/tagkey`].split(":");

        initialFormData.accounttags_mandatory.push({
          tagkey: {
            value:
              tagkeyArray.length > 1
                ? tagkeyArray[1]
                : data[`${PREFIX}/${tagName}/tagkey`],
            isValid: true,
          },
          specifiedtagvaluesonly: {
            value: data[`${PREFIX}/${tagName}/specifiedtagvaluesonly`],
          },
          tagvalues: data[`${PREFIX}/${tagName}/allowedtagvalues`]
            ? data[`${PREFIX}/${tagName}/allowedtagvalues`] !== "none"
              ? data[`${PREFIX}/${tagName}/allowedtagvalues`]
                  .split(",")
                  .map((v) => {
                    return { value: v, isValid: true };
                  })
              : data[`${PREFIX}/${tagName}/specifiedtagvaluesonly`] === "yes"
              ? [createTagvalue()]
              : []
            : [],
        });
      }

      if (data[`${PREFIX}/${tagName}/tagpresence`] === "optional") {
        const tagkeyArray = data[`${PREFIX}/${tagName}/tagkey`].split(":");

        initialFormData.accounttags_optional.push({
          tagkey: {
            value:
              tagkeyArray.length > 1
                ? tagkeyArray[1]
                : data[`${PREFIX}/${tagName}/tagkey`],
            isValid: true,
          },
          specifiedtagvaluesonly: {
            value: data[`${PREFIX}/${tagName}/specifiedtagvaluesonly`],
          },
          tagvalues: data[`${PREFIX}/${tagName}/allowedtagvalues`]
            ? data[`${PREFIX}/${tagName}/allowedtagvalues`] !== "none"
              ? data[`${PREFIX}/${tagName}/allowedtagvalues`]
                  .split(",")
                  .map((v) => {
                    return { value: v, isValid: true };
                  })
              : data[`${PREFIX}/${tagName}/specifiedtagvaluesonly`] === "yes"
              ? [createTagvalue()]
              : []
            : [],
        });
      }
    });
  }

  if (data[`${PREFIX}/resourcetagslist`] && data[`${PREFIX}/resourcetagslist`] !== "none") {
    data[`${PREFIX}/resourcetagslist`].split(",").forEach((tagName) => {
      if (data[`${PREFIX}/${tagName}/tagpresence`] === "mandatory") {
        const tagkeyArray = data[`${PREFIX}/${tagName}/tagkey`].split(":");

        initialFormData.resourcetags_mandatory.push({
          tagkey: {
            value:
              tagkeyArray.length > 1
                ? tagkeyArray[1]
                : data[`${PREFIX}/${tagName}/tagkey`],
            isValid: true,
          },
          specifiedtagvaluesonly: {
            value: data[`${PREFIX}/${tagName}/specifiedtagvaluesonly`],
          },
          tagvalues: data[`${PREFIX}/${tagName}/allowedtagvalues`]
            ? data[`${PREFIX}/${tagName}/allowedtagvalues`] !== "none"
              ? data[`${PREFIX}/${tagName}/allowedtagvalues`]
                  .split(",")
                  .map((v) => {
                    return { value: v, isValid: true };
                  })
              : data[`${PREFIX}/${tagName}/specifiedtagvaluesonly`] === "yes"
              ? [createTagvalue()]
              : []
            : [],
          tagkeycapitalizationcompliance: {
            value: data[`${PREFIX}/${tagName}/tagkeycapitalizationcompliance`],
          },
          preventnoncomplianttagoperations: {
            value:
              data[`${PREFIX}/${tagName}/preventnoncomplianttagoperations`],
          },
          costallocationtag: {
            value: data[`${PREFIX}/${tagName}/costallocationtag`],
          },
          resourcetypestoenforcecompliance: data[
            `${PREFIX}/${tagName}/resourcetypestoenforcecompliance`
          ]
            ? data[`${PREFIX}/${tagName}/resourcetypestoenforcecompliance`] !==
              "none"
              ? data[
                  `${PREFIX}/${tagName}/resourcetypestoenforcecompliance`
                ].split(",")
              : []
            : [],
        });
      }

      if (data[`${PREFIX}/${tagName}/tagpresence`] === "optional") {
        const tagkeyArray = data[`${PREFIX}/${tagName}/tagkey`].split(":");

        initialFormData.resourcetags_optional.push({
          tagkey: {
            value:
              tagkeyArray.length > 1
                ? tagkeyArray[1]
                : data[`${PREFIX}/${tagName}/tagkey`],
            isValid: true,
          },
          specifiedtagvaluesonly: {
            value: data[`${PREFIX}/${tagName}/specifiedtagvaluesonly`],
          },
          tagvalues: data[`${PREFIX}/${tagName}/allowedtagvalues`]
            ? data[`${PREFIX}/${tagName}/allowedtagvalues`] !== "none"
              ? data[`${PREFIX}/${tagName}/allowedtagvalues`]
                  .split(",")
                  .map((v) => {
                    return { value: v, isValid: true };
                  })
              : data[`${PREFIX}/${tagName}/specifiedtagvaluesonly`] === "yes"
              ? [createTagvalue()]
              : []
            : [],
          tagkeycapitalizationcompliance: {
            value: data[`${PREFIX}/${tagName}/tagkeycapitalizationcompliance`],
          },
          preventnoncomplianttagoperations: {
            value:
              data[`${PREFIX}/${tagName}/preventnoncomplianttagoperations`],
          },
          costallocationtag: {
            value: data[`${PREFIX}/${tagName}/costallocationtag`],
          },
          resourcetypestoenforcecompliance: data[
            `${PREFIX}/${tagName}/resourcetypestoenforcecompliance`
          ]
            ? data[`${PREFIX}/${tagName}/resourcetypestoenforcecompliance`] !==
              "none"
              ? data[
                  `${PREFIX}/${tagName}/resourcetypestoenforcecompliance`
                ].split(",")
              : []
            : [],
        });
      }
    });
  }

  if (initialFormData.accounttags_mandatory.length === 0) {
    initialFormData.accounttags_mandatory = [
      createTag("business-unit", [
        "Enterprise IT",
        "Asset Mgmt",
        "Retail Banking",
        "Business Service",
      ]),
      createTag("cost-center", [""]),
      createTag("sdlc-stage", ["Dev", "Test", "QA", "Prod"]),
      createTag("primaryowner-emailid", [""]),
    ];
  }

  if (initialFormData.accounttags_optional.length === 0) {
    initialFormData.accounttags_optional = [
      createTag("secondaryowner-emailid"),
      createTag("data-confidentiality", [
        "Highly Restrictive",
        "Confidential",
        "Internal",
        "Public",
      ]),
    ];
  }

  if (initialFormData.resourcetags_mandatory.length === 0) {
    initialFormData.resourcetags_mandatory = [
      createTag("Name", [""]),
      createTag("application-name", [""]),
      createTag(
        "environment",
        ["Dev", "SIT", "E2E", "UAT", "Prod"]
      ),
      createTag("environment", [""]),
    ];
  }

  if (initialFormData.resourcetags_optional.length === 0) {
    initialFormData.resourcetags_optional = [
      createTag(
        "data-confidentiality",
        ["Highly Restrictive", "Confidential", "Internal", "Public"]
      ),
      createTag(
        "business-unit",
        ["Enterprise IT", "Asset Mgmt.", "Retail Banking", "Business Service"]
      ),
      createTag("cost-center", [""]),
      createTag("automation-opt-in", [""]),
    ];
  }

  return initialFormData;
};

const createTag = (placeholder, placeholders, resources) => {
  return {
    placeholder: placeholder,
    tagkey: {
      value: "",
      isValid: false,
    },
    specifiedtagvaluesonly: {
      value: "yes",
    },
    tagvalues: placeholders
      ? placeholders.map((ph) => createTagvalue(ph))
      : [createTagvalue()],
    tagkeycapitalizationcompliance: {
      value: "yes",
    },
    preventnoncomplianttagoperations: {
      value: "yes",
    },
    costallocationtag: {
      value: "yes",
    },
    resourcetypestoenforcecompliance: resources || [],
  };
};

const createTagvalue = (placeholder) => {
  return {
    placeholder: placeholder,
    value: "",
    isValid: false,
  };
};



const TaggingStrategy = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  const { authUser } = useSession();
  const [myProjectsData, setMyProjectsData] = useState({
    projects: [],
    clientName: "",
  });
  const [projectName, setProjectName] = useState();

  useEffect(() => {
    const getMyProjects = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const results = await awsAmplify.getMyProjects();

        setIsLoading(false);

        if (results.data && results.data.getMyProjects) {
          setMyProjectsData(results.data.getMyProjects);
        }
      } catch (err) {
        setIsLoading(false);

        getError(err, setErrorMsg);
      }
    };

    if (authUser) {
      getMyProjects();
    }
  }, [authUser]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        setEmpty(result.data.empty);
        setFormData(convertBEToFE(result.data));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();

    if (projectId) {
      const project = myProjectsData.projects.find(
        (p) => p.ProjectID === projectId
      );

      if (project) {
        setProjectName(project.ProjectName);
      }
    }
  }, [projectId, myProjectsData]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/automatetaggingstrategy`]: formData.automatetaggingstrategy
        .value,
    };

    if (formData.automatetaggingstrategy.value === "yes") {
      be[`${PREFIX}/prefixtagkeywithprojectname`] =
        formData.prefixtagkeywithprojectname.value;
      be[`${PREFIX}/deployaccounttaggingscproduct`] =
        formData.deployaccounttaggingscproduct.value;

      let counter = 0;
      const accounttagslist = [],
        resourcetagslist = [],
        tagslist = [];

      const costallocationtagslist = [];
      formData.costallocationtagsjson = [];

      if (formData.deployaccounttaggingscproduct.value === "yes") {
        formData.accounttags_mandatory.forEach((tag) => {
          if (tag.tagkey.isValid) {
            counter++;
            accounttagslist.push(`tag${counter}`);
            tagslist.push(`tag${counter}`);

            be[`${PREFIX}/tag${counter}/tagkey`] =
              formData.prefixtagkeywithprojectname.value === "yes"
                ? `${projectName}:${tag.tagkey.value}`
                : tag.tagkey.value;
            be[`${PREFIX}/tag${counter}/tagtype`] = "accounttag";
            be[`${PREFIX}/tag${counter}/tagpresence`] = "mandatory";
            be[`${PREFIX}/tag${counter}/specifiedtagvaluesonly`] =
              tag.specifiedtagvaluesonly.value;

            if (
              tag.specifiedtagvaluesonly.value === "yes" &&
              tag.tagvalues.filter((tv) => tv.isValid).length > 0
            ) {
              be[`${PREFIX}/tag${counter}/allowedtagvalues`] = tag.tagvalues
                .filter((tv) => tv.isValid)
                .map((tv) => tv.value)
                .join(",");
            }

            if (tag.specifiedtagvaluesonly.value === "no") {
              be[`${PREFIX}/tag${counter}/allowedtagvalues`] = "none";
            }
          }
        });

        formData.accounttags_optional.forEach((tag) => {
          if (tag.tagkey.isValid) {
            counter++;
            accounttagslist.push(`tag${counter}`);
            tagslist.push(`tag${counter}`);

            be[`${PREFIX}/tag${counter}/tagkey`] =
              formData.prefixtagkeywithprojectname.value === "yes"
                ? `${projectName}:${tag.tagkey.value}`
                : tag.tagkey.value;
            be[`${PREFIX}/tag${counter}/tagtype`] = "accounttag";
            be[`${PREFIX}/tag${counter}/tagpresence`] = "optional";
            be[`${PREFIX}/tag${counter}/specifiedtagvaluesonly`] =
              tag.specifiedtagvaluesonly.value;

            if (
              tag.specifiedtagvaluesonly.value === "yes" &&
              tag.tagvalues.filter((tv) => tv.isValid).length > 0
            ) {
              be[`${PREFIX}/tag${counter}/allowedtagvalues`] = tag.tagvalues
                .filter((tv) => tv.isValid)
                .map((tv) => tv.value)
                .join(",");
            }

            if (tag.specifiedtagvaluesonly.value === "no") {
              be[`${PREFIX}/tag${counter}/allowedtagvalues`] = "none";
            }
          }
        });
      }

      formData.resourcetags_mandatory.forEach((tag) => {
        if (tag.tagkey.isValid) {
          counter++;
          resourcetagslist.push(`tag${counter}`);
          tagslist.push(`tag${counter}`);

          be[`${PREFIX}/tag${counter}/tagkey`] =
            formData.prefixtagkeywithprojectname.value === "yes"
              ? `${projectName}:${tag.tagkey.value}`
              : tag.tagkey.value;
          be[`${PREFIX}/tag${counter}/tagtype`] = "workloadtag";
          be[`${PREFIX}/tag${counter}/tagpresence`] = "mandatory";
          be[`${PREFIX}/tag${counter}/specifiedtagvaluesonly`] =
            tag.specifiedtagvaluesonly.value;

          if (
            tag.specifiedtagvaluesonly.value === "yes" &&
            tag.tagvalues.filter((tv) => tv.isValid).length > 0
          ) {
            be[`${PREFIX}/tag${counter}/allowedtagvalues`] = tag.tagvalues
              .filter((tv) => tv.isValid)
              .map((tv) => tv.value)
              .join(",");
          }

          if (tag.specifiedtagvaluesonly.value === "no") {
            be[`${PREFIX}/tag${counter}/allowedtagvalues`] = "none";
          }

          be[`${PREFIX}/tag${counter}/tagkeycapitalizationcompliance`] =
            tag.tagkeycapitalizationcompliance.value;
          be[`${PREFIX}/tag${counter}/preventnoncomplianttagoperations`] =
            tag.preventnoncomplianttagoperations.value;

          if (
            tag.preventnoncomplianttagoperations.value === "yes" &&
            tag.resourcetypestoenforcecompliance.length > 0
          ) {
            be[
              `${PREFIX}/tag${counter}/resourcetypestoenforcecompliance`
            ] = tag.resourcetypestoenforcecompliance.join(",");
          }

          if (tag.preventnoncomplianttagoperations.value === "no") {
            be[`${PREFIX}/tag${counter}/resourcetypestoenforcecompliance`] =
              "none";
          }

          be[`${PREFIX}/tag${counter}/costallocationtag`] =
            tag.costallocationtag.value;

          if(tag.costallocationtag.value === "yes"){
            costallocationtagslist.push(`tag${counter}`);
            createCostAllocationJson(tag);
          }
        }
      });

      formData.resourcetags_optional.forEach((tag) => {
        if (tag.tagkey.isValid) {
          counter++;
          resourcetagslist.push(`tag${counter}`);
          tagslist.push(`tag${counter}`);

          be[`${PREFIX}/tag${counter}/tagkey`] =
            formData.prefixtagkeywithprojectname.value === "yes"
              ? `${projectName}:${tag.tagkey.value}`
              : tag.tagkey.value;
          be[`${PREFIX}/tag${counter}/tagtype`] = "workloadtag";
          be[`${PREFIX}/tag${counter}/tagpresence`] = "optional";
          be[`${PREFIX}/tag${counter}/specifiedtagvaluesonly`] =
            tag.specifiedtagvaluesonly.value;

          if (
            tag.specifiedtagvaluesonly.value === "yes" &&
            tag.tagvalues.filter((tv) => tv.isValid).length > 0
          ) {
            be[`${PREFIX}/tag${counter}/allowedtagvalues`] = tag.tagvalues
              .filter((tv) => tv.isValid)
              .map((tv) => tv.value)
              .join(",");
          }

          if (tag.specifiedtagvaluesonly.value === "no") {
            be[`${PREFIX}/tag${counter}/allowedtagvalues`] = "none";
          }

          be[`${PREFIX}/tag${counter}/tagkeycapitalizationcompliance`] =
            tag.tagkeycapitalizationcompliance.value;
          be[`${PREFIX}/tag${counter}/preventnoncomplianttagoperations`] =
            tag.preventnoncomplianttagoperations.value;

          if (
            tag.preventnoncomplianttagoperations.value === "yes" &&
            tag.resourcetypestoenforcecompliance.length > 0
          ) {
            be[
              `${PREFIX}/tag${counter}/resourcetypestoenforcecompliance`
            ] = tag.resourcetypestoenforcecompliance.join(",");
          }

          if (tag.preventnoncomplianttagoperations.value === "no") {
            be[`${PREFIX}/tag${counter}/resourcetypestoenforcecompliance`] =
              "none";
          }

          be[`${PREFIX}/tag${counter}/costallocationtag`] =
            tag.costallocationtag.value;

          if(tag.costallocationtag.value === "yes"){
            costallocationtagslist.push(`tag${counter}`);
            createCostAllocationJson(tag);
          }
        }
      });

      if(formData.costallocationtagsjson && formData.costallocationtagsjson.length > 0)
      {
        be[`${PREFIX}/costallocationtagsjson`] = `[${formData.costallocationtagsjson.join(',')}]`;
      }
      if (accounttagslist.length > 0) {
        be[`${PREFIX}/accounttagslist`] = accounttagslist.join(",");
      }

      if (resourcetagslist.length > 0) {
        be[`${PREFIX}/resourcetagslist`] = resourcetagslist.join(",");
      }

      if (tagslist.length > 0) {
        be[`${PREFIX}/tagslist`] = tagslist.join(",");
      }

      if (costallocationtagslist.length > 0) {
        be[`${PREFIX}/costallocationtagslist`] = costallocationtagslist.join(",");
      }
    }
    else{
      be[`${PREFIX}/resourcetagslist`] = "none";
    }

    return be;
  };

  const createCostAllocationJson = (tag) => {
    if(tag) {  
    var objKey = "";
    if(tag.tagkey && tag.tagkey.isValid)
    {
      objKey = formData.prefixtagkeywithprojectname.value === "yes"
                  ? `${projectName}:${tag.tagkey.value}`
                  : tag.tagkey.value;
    }
    let objValue =  tag.tagvalues.filter((tv) => tv.isValid).length > 0 ?
                       tag.tagvalues[0].isValid &&  tag.tagvalues[0].value: "";
      if(objKey != "") 
      {
        let value =  objValue ? objValue : "";
        formData.costallocationtagsjson && 
          formData.costallocationtagsjson.push(`{"${objKey}" : "${value}"}`);
      }
    }
  }

  const saveDataIfDirty = async (routeToGoTo) => {
    console.log(`isDirty: ${isDirty}`);

    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const onFieldChange = (value, key, tagkind, idxTag, idxTagvalue) => {
    setErrorMsg(null);
    setIsDirty(true);

    if (idxTagvalue !== undefined) {
      setFormData((state) =>
        update(state, {
          [tagkind]: {
            [idxTag]: {
              tagvalues: {
                [idxTagvalue]: {
                  //[key]: {
                  value: {
                    $set: value,
                  },
                  //},
                },
              },
            },
          },
        })
      );
    } else {
      if (idxTag !== undefined) {
        setFormData((state) =>
          update(state, {
            [tagkind]: {
              [idxTag]: {
                [key]: {
                  value: {
                    $set: value,
                  },
                },
              },
            },
          })
        );
      } else {
        setFormData((state) =>
          update(state, { [key]: { value: { $set: value } } })
        );
      }
    }
  };

  const onFieldBlur = (
    validateFunc,
    value,
    key,
    tagkind,
    idxTag,
    idxTagvalue
  ) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      if (idxTagvalue !== undefined) {
        setFormData((state) =>
          update(state, {
            [tagkind]: {
              [idxTag]: {
                tagvalues: {
                  [idxTagvalue]: {
                    // [key]: {
                    isValid: { $set: errorMsg === undefined },
                    //},
                  },
                },
              },
            },
          })
        );
      } else {
        if (idxTag !== undefined) {
          setFormData((state) =>
            update(state, {
              [tagkind]: {
                [idxTag]: {
                  [key]: { isValid: { $set: errorMsg === undefined } },
                },
              },
            })
          );
        } else {
          setFormData((state) =>
            update(state, {
              [key]: { isValid: { $set: errorMsg === undefined } },
            })
          );
        }
      }

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      if (idxTagvalue !== undefined) {
        setFormData((state) =>
          update(state, {
            [tagkind]: {
              [idxTag]: {
                tagvalues: {
                  [idxTagvalue]: {
                    //[key]: {
                    isValid: { $set: false },
                    //},
                  },
                },
              },
            },
          })
        );
      } else {
        if (idxTag !== undefined) {
          setFormData((state) =>
            update(state, {
              [tagkind]: {
                [idxTag]: {
                  [key]: { isValid: { $set: false } },
                },
              },
            })
          );
        } else {
          setFormData((state) =>
            update(state, { [key]: { isValid: { $set: false } } })
          );
        }
      }
    }
  };

  const addAccountTag = (tagkind) => {
    var isValid = false;
    if(tagkind === "resourcetags_mandatory")
    {
      if(formData.resourcetags_mandatory.length < 6)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
        setErrorMsg("Maximum of 6 Mandatory Resource Tags are allowed");
      }
    }
    else
    {
      isValid = true;
    }

    if(isValid)
    {
      setIsDirty(true);

      setFormData((state) =>
        update(state, {
          [tagkind]: { $push: [createTag()] },
        })
      );
    }
  };

  const removeTag = (tagkind, idxTag) => {
    if (formData[tagkind].length > 1) {
      setIsDirty(true);

      setFormData((state) =>
        update(state, {
          [tagkind]: {
            $splice: [[idxTag, 1]],
          },
        })
      );
    }
  };

  const addTagvalue = (tagkind, idxTag) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [tagkind]: { [idxTag]: { tagvalues: { $push: [createTagvalue()] } } },
      })
    );
  };

  const removeTagvalue = (tagkind, idxTag, idxTagvalue) => {
    if (formData[tagkind][idxTag].tagvalues.length > 1) {
      setIsDirty(true);

      setFormData((state) =>
        update(state, {
          [tagkind]: {
            [idxTag]: { tagvalues: { $splice: [[idxTagvalue, 1]] } },
          },
        })
      );
    }
  };

  const exists = (tagkind, idxTag, resource) => {
    return (
      formData[tagkind][idxTag].resourcetypestoenforcecompliance.indexOf(
        resource
      ) !== -1
    );
  };

  const add = (tagkind, idxTag, resource) => {
    if (formData[tagkind][idxTag].resourcetypestoenforcecompliance.length < 7) {
    setIsDirty(true);
    setFormData((state) =>
      update(state, {
        [tagkind]: {
          [idxTag]: {
            resourcetypestoenforcecompliance: { $push: [resource] },
          },
        },
      })
    );
    }
    else{
      setErrorMsg("Maximum of 7 Resource Types to Enforce per Tag are allowed");
    }
  };

  const remove = (tagkind, idxTag, resource) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [tagkind]: {
          [idxTag]: {
            resourcetypestoenforcecompliance: {
              $splice: [
                [
                  state[tagkind][
                    idxTag
                  ].resourcetypestoenforcecompliance.indexOf(resource),
                  1,
                ],
              ],
            },
          },
        },
      })
    );
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Governance</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Governance"
                isDirty={isDirty}
                dashboardRoute={ROUTES.GOVERNANCE_SERVICE_CONTROL}
                designRoute={ROUTES.DESIGN_GOVERNANCE}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 p-0">
                <div>
                  <div className="row d-flex align-items-center my-1">
                    <div className="col-xl-6 col-lg-5  col-md-4 pl-lg-0 px-2">
                      <h3>Tagging Strategy</h3>
                    </div>
                    <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                      <div className="d-inline-flex yes-no-area align-items-center">
                        <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                          Automate Tagging Strategy
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                          <RadioGroup
                            field="automatetaggingstrategy"
                            value={formData.automatetaggingstrategy.value}
                            onChange={updateRadio}
                            isLarge={true}
                          ></RadioGroup>
                        </div>
                      </div>
                    </div>
                    <div className="show-hide-content px-0">
                      {empty && (
                        <NoInitialData />
                      )}
                    </div>
                    {formData.automatetaggingstrategy.value === "yes" && (
                      <div className="show-hide-content px-0">
                        <div className="col-12 d-flex pr-0 mt-2 align-items-center px-0">
                          <div className="d-inline-flex yes-no-area align-items-center">
                            <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                              Prefix Tag Key with “ProjectName{" "}
                              <span className="text-color-gray">
                                ”E.g epic9:business-unit”
                              </span>
                            </div>
                            <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                              <RadioGroup
                                field="prefixtagkeywithprojectname"
                                value={
                                  formData.prefixtagkeywithprojectname.value
                                }
                                onChange={updateRadio}
                              ></RadioGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {formData.automatetaggingstrategy.value === "yes" && (
                  <div className="show-hide-content px-0">
                    <div className="row">
                      <div className="col-12 pl-lg-0 px-2 mb-1">
                        <h3 className="m-0 font-15">Account Tags</h3>
                      </div>
                      <div className="col-12 d-flex pr-0 my-1  align-items-center px-0">
                        <div className="d-inline-flex yes-no-area align-items-center">
                          <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                            Deploy Account Tagging Service Catalog Product
                          </div>
                          <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                            <RadioGroup
                              field="deployaccounttaggingscproduct"
                              value={
                                formData.deployaccounttaggingscproduct.value
                              }
                              onChange={updateRadio}
                            ></RadioGroup>
                          </div>
                        </div>
                      </div>
                      {formData.deployaccounttaggingscproduct.value ===
                        "yes" && (
                        <div className="show-hide-sub-content px-0">
                          <div className="mainContentArea row px-2 py-1 my-1">
                            <div className="col-12 pl-lg-0 px-2 my-1">
                              <h3 className="font-15 mb-0">Mandatory Tags</h3>
                            </div>
                            <div className="col-12 customTableArea">
                              <table className="customTable">
                                <thead>
                                  <tr>
                                    <th>Tag Key</th>

                                    <th>
                                      Specified Tag <br /> Values Only
                                    </th>
                                    <th>Allowed Tag Value(s)</th>
                                    <th></th>

                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {formData.accounttags_mandatory.map(
                                    (tag, idxTag) => (
                                      <AccountTag
                                        key={idxTag}
                                        formData={formData}
                                        setErrorMsg={setErrorMsg}
                                        onFieldChange={onFieldChange}
                                        onFieldBlur={onFieldBlur}
                                        removeTag={removeTag}
                                        addTagvalue={addTagvalue}
                                        removeTagvalue={removeTagvalue}
                                        tagkind="accounttags_mandatory"
                                        tag={tag}
                                        idxTag={idxTag}
                                        setFormData={setFormData}
                                        disabled={disabled}
                                      />
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div className="col-12 my-1">
                              <div className="d-flex align-items-center justify-content-end px-3">
                                <div className="add-button ">
                                  <button disabled={disabled}
                                    className="add-new-account-link btn-animation d-inline-flex align-items-center"
                                    type="button"
                                    onClick={() =>
                                      addAccountTag("accounttags_mandatory")
                                    }
                                  >
                                    <p className="my-0 mr-2 p-0 green-style2">
                                      Add Tag
                                    </p>
                                    <img src="../images/coloredPlus.svg" />
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="col-12 pl-lg-0 px-2  my-1">
                              <h3 className="font-15 mb-0">Optional Tags</h3>
                            </div>

                            <div className="col-12 customTableArea">
                              <table className="customTable">
                                <thead>
                                  <tr>
                                    <th>Tag Key</th>

                                    <th>
                                      Specified Tag <br /> Values Only
                                    </th>
                                    <th>Allowed Tag Value(s)</th>
                                    <th></th>

                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {formData.accounttags_optional.map(
                                    (tag, idxTag) => (
                                      <AccountTag
                                        key={idxTag}
                                        formData={formData}
                                        setErrorMsg={setErrorMsg}
                                        onFieldChange={onFieldChange}
                                        onFieldBlur={onFieldBlur}
                                        removeTag={removeTag}
                                        addTagvalue={addTagvalue}
                                        removeTagvalue={removeTagvalue}
                                        tagkind="accounttags_optional"
                                        tag={tag}
                                        idxTag={idxTag}
                                        setFormData={setFormData}
                                        disabled={disabled}
                                      />
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div className="col-12 my-1">
                              <div className="d-flex align-items-center justify-content-end px-3">
                                <div className="add-button ">
                                  <button disabled={disabled}
                                    type="button"
                                    className="add-new-account-link btn-animation d-inline-flex align-items-center"
                                    onClick={() =>
                                      addAccountTag("accounttags_optional")
                                    }
                                  >
                                    <p className="my-0 mr-2 p-0 green-style2">
                                      Add Tag
                                    </p>
                                    <img src="../images/coloredPlus.svg" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-12 pl-lg-0 px-2 my-1">
                        <h3 className="mb-0 font-15">Workload/Resource Tags</h3>
                      </div>

                      <div className="mainContentArea row px-2 my-1 py-1">
                        <div className="col-12 pl-lg-0 px-2 my-1">
                          <h3 className="font-15 mb-0">Mandatory Tags</h3>
                        </div>
                        <div className="col-12 customTableArea">
                          <table className="customTable verycustom">
                            <thead>
                              <tr>
                                <th>Tag Key</th>
                                <th>
                                  Tag Key Capitalization <br /> Compliance
                                </th>

                                <th>
                                  Specified Tag <br /> Values Only
                                </th>
                                <th>
                                  Allowed Tag Value(s)/Resource Types to Enforce
                                </th>
                                <th></th>

                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {formData.resourcetags_mandatory.map(
                                (tag, idxTag) => (
                                  <ResourceTag
                                    key={idxTag}
                                    formData={formData}
                                    setErrorMsg={setErrorMsg}
                                    onFieldChange={onFieldChange}
                                    onFieldBlur={onFieldBlur}
                                    removeTag={removeTag}
                                    addTagvalue={addTagvalue}
                                    removeTagvalue={removeTagvalue}
                                    tagkind="resourcetags_mandatory"
                                    tag={tag}
                                    idxTag={idxTag}
                                    exists={exists}
                                    add={add}
                                    remove={remove}
                                    setFormData={setFormData}
                                    disabled={disabled}
                                  />
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="col-12 my-1">
                          <div className="d-flex align-items-center justify-content-end px-3">
                            <div className="add-button ">
                              <button disabled={disabled}
                                type="button"
                                onClick={() =>
                                  addAccountTag("resourcetags_mandatory")
                                }
                                className="add-new-account-link btn-animation d-inline-flex align-items-center"
                              >
                                <p className="my-0 mr-2 p-0 green-style2">
                                  Add Tag
                                </p>
                                <img src="../images/coloredPlus.svg" />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 pl-lg-0 px-2 my-1">
                          <h3 className="font-15 mb-0">Optional Tags</h3>
                        </div>

                        <div className="col-12 customTableArea">
                          <table className="customTable">
                            <thead>
                              <tr>
                                <th>Tag Key</th>
                                <th>
                                  Tag Key Capitalization <br /> Compliance
                                </th>

                                <th>
                                  Specified Tag <br /> Values Only
                                </th>
                                <th>
                                  Allowed Tag Value(s)/Resource Types to Enforce
                                </th>
                                <th></th>

                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {formData.resourcetags_optional.map(
                                (tag, idxTag) => (
                                  <ResourceTag
                                    key={idxTag}
                                    formData={formData}
                                    setErrorMsg={setErrorMsg}
                                    onFieldChange={onFieldChange}
                                    onFieldBlur={onFieldBlur}
                                    removeTag={removeTag}
                                    addTagvalue={addTagvalue}
                                    removeTagvalue={removeTagvalue}
                                    tagkind="resourcetags_optional"
                                    tag={tag}
                                    idxTag={idxTag}
                                    exists={exists}
                                    add={add}
                                    remove={remove}
                                    setFormData={setFormData}
                                    disabled={disabled}
                                  />
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="col-12 my-1">
                          <div className="d-flex align-items-center justify-content-end px-3">
                            <div className="add-button ">
                              <button disabled={disabled}
                                type="button"
                                onClick={() =>
                                  addAccountTag("resourcetags_optional")
                                }
                                className="add-new-account-link btn-animation d-inline-flex align-items-center"
                              >
                                <p className="my-0 mr-2 p-0 green-style2">
                                  Add Tag
                                </p>
                                <img src="../images/coloredPlus.svg" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {console.log(formData)}
              </fieldset>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.GOVERNANCE_SERVICE_CONTROL_POLICIES +
                          "/" +
                          projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Service Control Policies
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaggingStrategy;
