

import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getBooleanValue } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "Patch Management Patch Groups";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
        <>
          <h3>Patch Management</h3>
          <div className="otherTableTitles ">
            <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_BASELINES + "/" + projectId}>  
               Baselines
            </Link>          
            </h3>
            <h3 className="font-15 activetable">  
              Groups  
            </h3>
            <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_MAINTENANCE_WINDOWS + "/" + projectId}>  
              Maintenance Windows
            </Link>          
            </h3>
          </div>
        </>
      );
  };

  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Patch Group Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.PatchGroup}
                  </p>
                </div>
           
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Baseline Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.BaselineIdentity && selectedRow.BaselineIdentity.BaselineName}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Operating System
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.BaselineIdentity && selectedRow.BaselineIdentity.OperatingSystem}
                  </p>
                </div>
             </div>

              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                    <p className="m-0 p-0 account-detail-subtitle">
                       Baseline Description
                    </p>
                    <p className="m-0 p-0 account-title-subcontent">
                        {selectedRow && selectedRow.BaselineIdentity && selectedRow.BaselineIdentity.BaselineDescription}
                    </p>
                    </div>
                    <div className="d-inline-flex w-100 my-1">
                    <p className="m-0 p-0 account-detail-subtitle">
                        Default Baseline
                    </p>
                    <p className="m-0 p-0 account-title-subcontent">
                        {getBooleanValue((selectedRow && selectedRow.BaselineIdentity), "DefaultBaseline")}
                    </p>
                    </div>
                    <div className="d-inline-flex w-100 my-1">
                    <p className="m-0 p-0 account-detail-subtitle">Baseline ID</p>
                    <p className="m-0 p-0 account-title-subcontent">
                        {selectedRow && selectedRow.BaselineIdentity && selectedRow.BaselineIdentity.BaselineId}
                    </p>
                 </div>
                </div>
            </div>
            </div>
          </div>
        </div>
    );
  };

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
     <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.INFRASTRUCTURE_COMPONENTS + "/" + projectId} >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Image Builder</p>       
      </Link> 

      <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.INFRASTRUCTURE_WORKSPACES + "/" + projectId}
      >
      <p className="m-0 p-0 ml-2 font-weight-bold">Workspaces</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
      </>
    );
  };

  
const Groups = () => {
    const columns = useMemo(
        () => [
          {
            Header: "Account ID",
            accessor: "AccountId",
          },
          {
            Header: "Region",
            accessor: "Region",
          },
          {
            Header: "Patch Group Name",
            accessor: "PatchGroup",
            id: "Patch Group Name"
          },
          {
            Header: "Baseline Name",
            accessor: "BaselineIdentity.BaselineName",
            id: "Baseline Name"
          },
          {
            Header: "Operating System",
            accessor: "BaselineIdentity.OperatingSystem",
            id: "Operating System"
          },
          {
            Header: "Baseline Description",
            accessor: "BaselineIdentity.BaselineDescription",
            id: "Baseline Description"
          },
          {
            Header: "Default Baseline",
            accessor: (d) => getBooleanValue(d, "BaselineIdentity.DefaultBaseline"),
            id: "Default Baseline"
          },
          {
            Header: "Baseline ID",
            accessor: "BaselineIdentity.BaselineId",
            id:  "Baseline ID"
          },
        ],
        []
      );

    return Dashboard({
        domain: "infrastructure",
        title: "Infrastructure",
        dashboardName,
        columns,
        Details,
        Navigations,
        configureRoute: ROUTES.INFRASTRUCTURE_PATCHMANAGEMENT,
        designRoute: ROUTES.DESIGN_INFRASTRUCTURE,
        SubMenu: SubMenu,
      });
};

export default Groups;