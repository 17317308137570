
import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue,getBooleanValue } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "Patch Management Baselines";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
        <>
          <h3>Patch Management</h3>
          <div className="otherTableTitles ">
          <h3 className="font-15 activetable">  
               Baselines  
            </h3>
            <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_GROUPS + "/" + projectId}>  
               Groups
            </Link>          
            </h3>
            <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_MAINTENANCE_WINDOWS + "/" + projectId}>  
              Maintenance Windows
            </Link>          
            </h3>
          </div>
        </>
      );
  };

  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Global Filters" && "my_active"
                }`}
                onClick={() => setType("Global Filters")}
              >
               Global Filters
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Approval Rules" && "my_active"
                }`}
                onClick={() => setType("Approval Rules")}
              >
                Approval Rules
              </li> 
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Patch Exceptions" && "my_active"
                }`}
                onClick={() => setType("Patch Exceptions")}
              >
                Patch Exceptions
              </li> 
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Patch Sources" && "my_active"
                }`}
                onClick={() => setType("Patch Sources")}
              >
                Patch Sources
              </li> 
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Tags" && "my_active"
                }`}
                onClick={() => setType("Tags")}
              >
               Tags
              </li> 
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
               <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">

                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Baseline ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.BaselineId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Baseline Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.Name}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Description
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.Description}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Operating System
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.OperatingSystem}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Default Baseline
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {getBooleanValue(selectedRow, "DefaultBaseline")}
                  </p>
                </div>
              </div>

              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Patch Groups</p>
                  <p className="m-0 p-0 account-title-subcontent">
                   {selectedRow && selectedRow.PatchGroups} 
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                   Creation Time
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.CreatedDate}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                   Modified Date
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.ModifiedDate}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                   Approved Patches Compliance Level
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.ApprovedPatchesComplianceLevel}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Approved Patches Enable NonSecurity
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && getBooleanValue(selectedRow, "ApprovedPatchesEnableNonSecurity")}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Rejected Patches Action
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.RejectedPatchesAction}
                  </p>
                </div>
             </div>
            
            </div>

          <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Global Filters" && "inaktif"
              }`}
            >
                  <p class="font-12 pl-1 ml-0 font-weight-bold">Patch Filters:</p>
                  <div className="d-flex flex-column">
                    <table className="details-table">
                    <thead>
                        <tr>
                        <th>Key</th>
                        <th>Values</th>
                      
                        </tr>
                    </thead>
                    <tbody>
                     {selectedRow  && selectedRow.GlobalFilters && selectedRow.GlobalFilters.PatchFilters &&
                      selectedRow.GlobalFilters.PatchFilters.map((item, idx) => (
                     <tr key={idx}>
                        <td>{ item.Key }</td>
                        <td>{ item.Values }</td>
                        </tr> 
                     ))}
                    </tbody>
                    </table>
                  </div>
            </div>


      <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Approval Rules" && "inaktif"
              }`}
            >
                  <p class="font-12 pl-1 ml-0 font-weight-bold"></p>
                  <div className="d-flex flex-column">
                    <table className="details-table">
                    <thead>
                        <tr>
                        <th>Product</th>
                        <th>Classification</th>
                        <th>Severity</th>
                        <th>Auto Approval Delay (days)</th>
                        <th>Approval Until Date</th>
                        <th>Compliance Reporting</th>
                        <th>Include Non Security Updates</th>
                      
                        </tr>
                    </thead>
                    <tbody>
                     {selectedRow  && selectedRow.ApprovalRules && selectedRow.ApprovalRules.PatchRules.map((item, idx) => (
                     <tr key={idx}>
                       {  approvalRules(item) }                
                        <td>{ item.ApproveAfterDays }</td>
                        <td> {'-'} </td>
                        <td>{ item.ComplianceLevel}</td>
                        <td>{getBooleanValue(item, "EnableNonSecurity")}</td>
                        </tr> 
                     ))}
                    </tbody>
                    </table>
                  </div>
            </div>

            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Patch Exceptions" && "inaktif"
              }`}
            >
            <div className="col-xl-4 col-lg-5 col-md-25 col-12 d-flex flex-column">
              <p class="font-15 font-weight-bold">Approved Patches</p>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Approved Patches  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.ApprovedPatches}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Approved Patch Compliance Level  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.ApprovedPatchesComplianceLevel}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m- p-0 account-detail-subtitle"> Approved Patches Include Non-security Updates  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {getBooleanValue(selectedRow, "ApprovedPatchesEnableNonSecurity")}
                  </p>
                </div>
              </div>

              <div className="col-xl-8 col-lg-7 col-md-18 col-12 d-flex flex-column">
              <p class="font-15 font-weight-bold">Rejected Patches</p>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Rejected Patches</p>
                  <p className="m-0 p-0 account-title-subcontent">
                   {selectedRow && selectedRow.RejectedPatches} 
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Rejected Patches Action
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.RejectedPatchesAction}
                  </p>
                </div>
              </div>
            </div>

    
    {/*      <DetailsGrid
            type={type}
            typeName="Patch Sources"
            array={selectedRow["Sources"]}
            metadata={[
              { header: "Name", key: "" },
              { header: "Product", key: "" },
              { header: "Configuration", key: "" },
            ]}
          ></DetailsGrid>
 */}

             <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags> 
            </div>
          </div>
        </div>
    );
  };

  function approvalRules(item) {

     var productValue = '-' ; 
     var classValue = '-';
     var severity = '-';
  (item.PatchFilterGroup &&  item.PatchFilterGroup.PatchFilters &&  item.PatchFilterGroup.PatchFilters.map((pf,idx) => {
    if(pf.Key === "PRODUCT")
      productValue = pf.Values.join(",");
    if(pf.Key === "CLASSIFICATION")
      classValue = pf.Values.join(",");
    if(pf.Key === "SEVERITY")
      severity = pf.Values.join(",");
  }
  ));
  return (
    <>
      <td> { productValue }</td>
      <td> { classValue } </td>
      <td> { severity } </td>
      </>
  );
  };

  function Navigations() {
  const { projectId } = useParams();
  return (
    <>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.INFRASTRUCTURE_COMPONENTS + "/" + projectId}>
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Image Builder</p>
      </Link>

      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.INFRASTRUCTURE_WORKSPACES + "/" + projectId}
      >
        <p className="m-0 p-0 ml-2 font-weight-bold">Workspaces</p>
        <img src="../images/ribbon-designRight.svg" />
      </Link>
    </>
  );
}

  
const Baselines = () => {
    const columns = useMemo(
        () => [
          {
            Header: "Account ID",
            accessor: "AccountId",
          },
          {
            Header: "AWS Region",
            accessor: "Region",
          },
          {
            Header: "Baseline ID",
            accessor: "BaselineId",
          },
          {
            Header: "Baseline Name",
            accessor: "Name",
            id: "Baseline Name"
          },
          {
            Header: "Description",
            accessor: "Description",
            id: "Description"
          },
          {
            Header: "Operating System",
            accessor: "OperatingSystem",
            id: "Operating System"
          },
          {
            Header: "Default Baseline",
            accessor: (d) => getBooleanValue(d, "DefaultBaseline"),
            id: "Default Baseline"
          },
          {
            Header: "Creation Time",
            accessor: "CreatedDate",
            id: "Creation Time"
          }
        ],
        []
      );

    return Dashboard({
        domain: "infrastructure",
        title: "Infrastructure",
        dashboardName,
        columns,
        Details,
        Navigations,
        configureRoute: ROUTES.INFRASTRUCTURE_PATCHMANAGEMENT,
        designRoute: ROUTES.DESIGN_INFRASTRUCTURE,
        SubMenu: SubMenu,
      });
};

export default Baselines;