import React, { useEffect, useState } from "react";
import SignInForm from "./SignInForm";
import ResetPwdForm from "./ResetPwdForm";

import * as ROUTES from "../constants/routes";
import { Link, useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import useAwsAmplify from "../hooks/useAwsAmplify";

import { Hub } from "@aws-amplify/core";

import $ from "jquery";
import VerifyCodeForm from "./VerifyCodeForm";
import SignUpForm from "./SignUpForm";
import ConfirmSignUpForm from "./CofirmSignUpForm";
import {
  getGroup,
  isClientAdmin,
  isSuperAdmin,
  isUser,
} from "../helpers/userHelper";
import SwitchUserContainer from "./SwitchUserContainer";

import {getDefaultRoute} from "../helpers/projectHelper"

const HomeHeader = ({setIsLoading, setError, setInfo}) => {
  const [openProjectList, setOpenProjectList] = useState(false);
  const [state, setState] = useState(
    window.location.pathname.indexOf("signin") === -1 ? "" : "SIGNIN"
  );
  const [currentEmail, setCurrentEmail] = useState(null);

  const [authUser, setAuthUser] = useState(null);
  const [authStateChanged, setAuthStateChanged] = useState(null);

  const awsAmplify = useAwsAmplify();
  const history = useHistory();
  

  const [myProjectsData, setMyProjectsData] = useState({
    projects: [],
    clientName: "",
  });

  const [isSwitchUser, setIsSwitchUser] = useState(false);

  const signOut = () => {
    awsAmplify.signOut();
    //history.push(ROUTES.LANDING);
    setAuthStateChanged("SIGNOUTSUCCESSFUL");
    window.location.href = "/";
  };

  const switchUser = () => {
    setIsSwitchUser(true);
    /* awsAmplify.signOut();
    setAuthStateChanged("SIGNOUTSUCCESSFUL");
    window.location.href = "/signin"; */
  };

  Hub.listen("auth", (data) => {
    switch (data.payload.event) {
      case "signIn":
        setAuthStateChanged("SIGNIN");
        break;
      case "signOut":
        setAuthStateChanged("SIGNOUT");
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    //$(function () {
    /* $(".menu-trigger").on("click", function () {
      $(this).toggleClass("active");
      $(".menu").toggleClass("active");
      $("body").toggleClass("o-hidden");
      $(window).scrollTop(0);
      return false;
    }); */

    $(".menu li a").on("click", function () {
      $(".menu-trigger").removeClass("active");
      $(".menu").removeClass("active");
      $("body").removeClass("o-hidden");
    });

    $(".log li").on("click", function () {
      $(".log li").removeClass("active");
      $(this).toggleClass("active");
    });

    $(".signClick").on("click", function () {
      // $(window).scrollTop(0);
      $(".log li").removeClass("active");
      $(".signUpContainer").addClass("active");
      setState("SIGNUP");
    });

    $("*").click(function (event) {
      if (
        !$(event.target).is(
          ".signClick,.signClick *, .log *, .sign *, .closebtn"
        )
      ) {
        $(".log li").removeClass("active");
        // return false;
      }
    });
  //});
  });

  const triggerMenu = () => {
    $(".menu-trigger").toggleClass("active");
    $(".menu").toggleClass("active");
    $("body").toggleClass("o-hidden");
    $(window).scrollTop(0);
  };

  useEffect(() => {
    const fetchAuthenticatedUser = async () => {
      try {
        const authenticatedUser = await awsAmplify.auth.currentAuthenticatedUser();

        if (authenticatedUser) {
          setAuthUser(authenticatedUser);
        } else {
          setAuthUser(null);
        }
      } catch (err) {
        setAuthUser(null);
      }
    };

    fetchAuthenticatedUser();
  }, [authStateChanged]);

  useEffect(() => {
    const getMyProjects = async () => {
      try {
        setError(null);
        setIsLoading(true);

        const results = await awsAmplify.getMyProjects();
        setIsLoading(false);

        console.log(results);

        if (results.data && results.data.getMyProjects) {
          console.log(results.data.getMyProjects);
          setMyProjectsData(results.data.getMyProjects);
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);

        if (err && err.message) {
          setError(err.message);
        } else if (err && err.response && err.response.data) {
          setError(err.response.data.message);
        }
      }
    };

    if (authUser) {
      getMyProjects();
    }
  }, [authUser]);

  return (
    <>
      
      {isSwitchUser && (
        <SwitchUserContainer
          close={() => setIsSwitchUser(false)}
          setInfo={setInfo}
        ></SwitchUserContainer>
      )}
      <header>
        <Link to={ROUTES.LANDING} className="logo ">
          <img className="logo-default" src="images/logo.svg" alt="" />
          <img className="logo-sticky" src="images/logo-footer.svg" alt="" />
        </Link>

        {authUser ? (
          <ul className="log spc">
            <li className="userInfo linkToDrop">
              <a
                className=" p-0 userName align-items-center d-inline-flex"
                href="#"
              >
                <p className="ml-0 my-0 mr-2 p-0 mainUserName text-color-white">{`${authUser.attributes.given_name} ${authUser.attributes.family_name}`}</p>
                <img className="userImg" src="images/icons/Avatar.svg" />
              </a>
              <ul className="drop m-0 px-2">
                <li className="d-flex flex-column  userInfoArea1">
                  <div className="d-inline-flex justify-content-between align-items-center pb-2">
                    <a className="userName mr-2" href="#">
                      {`${authUser.attributes.given_name} ${authUser.attributes.family_name}`}
                    </a>
                    <img className="userImg" src="images/icons/Avatar.svg" />
                  </div>
                  <a className="justify-content-start userMail" href="#">
                    {`${authUser.attributes.email}`}
                  </a>
                  {myProjectsData.clientName && (
                    <a
                      className="justify-content-start userCompany pt-2 padding-bottom-15px"
                      href="#"
                    >
                      {myProjectsData.clientName}
                    </a>
                  )}
                </li>
                <li className="no-border">
                  <button
                    type="button"
                    className="pb-2 d-inline-flex switch-user-link "
                    onClick={switchUser}
                  >
                    <p className="m-0 p-0">Switch User</p>
                  </button>
                </li>
                <li className=" signout-link  border-spc-bottom">
                  <button
                    type="button"
                    className="padding-bottom-15px d-inline-flex"
                    onClick={signOut}
                  >
                    <p className="m-0 p-0">Sign Out</p>
                  </button>
                </li>

                {myProjectsData.projects.length > 0 ? (
                  <div
                    className="projectListLink mobil flex-column"
                    onClick={() => setOpenProjectList(!openProjectList)}
                  >
                    <a className="pr-2 userProjectList" href="#">
                      My Projects{" "}
                      <div className="iconDrop">
                        <img src="images/icons/small-down.svg" />
                      </div>
                    </a>
                    <div
                      className={`projectList ${
                        openProjectList === true ? "opened" : ""
                      }`}
                    >
                      {myProjectsData.projects.map((project, idxProject) => (
                        <p key={idxProject}>
                          <Link
                            to={`${getDefaultRoute(project.ProjectType)}/${project.ProjectID}`}
                          >
                            {project.ClientName
                              ? `${project.ClientName} | ${project.ProjectName}`
                              : project.ProjectName}
                          </Link>
                        </p>
                      ))}
                    </div>
                  </div>
                ) : (
                  <li className="no-border">
                    <p className="m-0 p-0">No projects assigned</p>
                  </li>
                )}

                {!isUser(authUser) && (
                  <li className=" padding-top-15px no-border">
                    <Link
                      to={
                        isSuperAdmin(authUser)
                          ? ROUTES.ADMIN_CLIENTS
                          : isClientAdmin(authUser)
                          ? ROUTES.ADMIN_PROJECTS
                          : ""
                      }
                      className="userRole"
                    >
                      Admin
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          </ul>
        ) : (
          <ul className="log">
            <li
              className={`${
                ["SIGNIN", "RESETPWD", "VERIFYCODE"].indexOf(state) !== -1
                  ? "active"
                  : ""
              }`}
            >
              <a
                className={`link-a myclickable ${
                  ["SIGNIN", "RESETPWD", "VERIFYCODE"].indexOf(state) !== -1
                    ? "active"
                    : ""
                }`}
                onClick={() => setState("SIGNIN")}
              >
                <span>Sign in</span>
              </a>

              <div className="sign">
                {state === "SIGNIN" && (
                  <SignInForm
                    setState={setState}
                    setAuthStateChanged={setAuthStateChanged}
                    setError={setError}
                    setIsLoading={setIsLoading}
                    setInfo={setInfo}
                  ></SignInForm>
                )}
                {state === "RESETPWD" && (
                  <ResetPwdForm
                    setState={setState}
                    setError={setError}
                    setIsLoading={setIsLoading}
                    setCurrentEmail={setCurrentEmail}
                  ></ResetPwdForm>
                )}
                {state === "VERIFYCODE" && (
                  <VerifyCodeForm
                    currentEmail={currentEmail}
                    setState={setState}
                    setError={setError}
                    setIsLoading={setIsLoading}
                  ></VerifyCodeForm>
                )}
              </div>
            </li>
            <li
              className={`signUpContainer ${
                ["SIGNUP", "CONFIRMSIGNUP"].indexOf(state) !== -1
                  ? "active"
                  : ""
              }`}
            >
              <a
                className={`link-a myclickable ${
                  ["SIGNUP", "CONFIRMSIGNUP"].indexOf(state) !== -1
                    ? "active"
                    : ""
                }`}
                onClick={() => setState("SIGNUP")}
              >
                <span>Sign up</span>
              </a>
              <div className="sign">
                {state === "SIGNUP" && (
                  <SignUpForm
                    setState={setState}
                    setError={setError}
                    setIsLoading={setIsLoading}
                  ></SignUpForm>
                )}
                {state === "CONFIRMSIGNUP" && (
                  <ConfirmSignUpForm
                    setState={setState}
                    setError={setError}
                    setIsLoading={setIsLoading}
                  ></ConfirmSignUpForm>
                )}
              </div>
            </li>
          </ul>
        )}
      </header>
    </>
  );
};

export default HomeHeader;
