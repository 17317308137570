import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import * as ROUTES from "../constants/routes";
import useAwsAmplify from "../hooks/useAwsAmplify";

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const ResetPwdForm = ({
  setState,
  setError,
  setIsLoading,
  setCurrentEmail,
}) => {
  const awsAmplify = useAwsAmplify();
  const history = useHistory();

  const { register, handleSubmit, formState, errors } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = async (formValues) => {
    setIsLoading(true);
    setError("");

    try {
      setCurrentEmail(formValues.email);
      await awsAmplify.forgotPassword(formValues.email);
      setIsLoading(false);

      setState("VERIFYCODE");
    } catch (err) {
      console.log(err);

      setIsLoading(false);
      if (
        err.code &&
        err.code === "InvalidParameterException" &&
        err.message &&
        err.message ===
          "Cannot reset password for the user as there is no registered/verified email or phone_number"
      ) {
        setError("Confirm Registration");
        setState("CONFIRMSIGNUP");
      } else if (err.code === "UserNotFoundException") {
        setError("User not registered. Please register");
        setState("SIGNUP");
      } else {
        if (err.message) {
          setError(err.message);
        }
      }
    }
  };

  useEffect(() => {
    const errorMsgs = [];

    if(errors){
      for(const field in errors){
        if(errors[field].message){
          errorMsgs.push(errors[field].message);
        }
      }
    }

    if (errorMsgs.length > 0) {
      setError(errorMsgs);
    }
  }, [errors.email]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="text-center">
          You will receive a verification code to reset your password
        </p>
        <label htmlFor="#" className={errors.email && "highlight"}>
          <img src="images/icons/mail.svg" alt="" />
          <input
            type="email"
            placeholder="Email Address"
            name="email"
            ref={register}
          />
        </label>

        <div className="back-to">
          <a className="myclickable" onClick={() => setState("SIGNIN")}>
            Back to Sign In
          </a>
        </div>

        <button type="submit" className="button">
          Submit
        </button>
      </form>
    </>
  );
};

export default ResetPwdForm;
