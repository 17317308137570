import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import useServiceAccounts from "../../../../hooks/useServiceAccounts";
import useRegions from "../../../../hooks/useRegions";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/security/siem";
const WORKLOADACCOUNTS_PREFIX = "/aws/accountframework/workloadaccounts";
const SANDBOXACCOUNTS_PREFIX =
  "/aws/accountframework/sandboxaccounts/sandboxaccountslist";

const getInitialFormData = (data, workloadAccounts, sandboxAccounts) => {
  const initialFormData = {
    deploysiemcapabilities: {
      value: data[`${PREFIX}/deploysiemcapabilities`] || "no",
    },
    amazondetective_enable: {
      value: data[`${PREFIX}/amazondetective/enable`] || "no",
    },
    amazondetective_delegatedmasteraccount: {
      value: data[`${PREFIX}/amazondetective/delegatedmasteraccount`] || "",
    },
    amazondetective_regionslist: data[`${PREFIX}/amazondetective/regionslist`]
      ? data[`${PREFIX}/amazondetective/regionslist`].split(",")
      : [],
    amazondetective_accountslist: data[`${PREFIX}/amazondetective/accountslist`]
      ? data[`${PREFIX}/amazondetective/accountslist`].split(",")
      : [],
    amazondetective_autoenablefornewaccounts: {
      value: data[`${PREFIX}/amazondetective/autoenablefornewaccounts`] || "no",
    },
    accessanalyzer_enable: {
      value: data[`${PREFIX}/accessanalyzer/enable`] || "no",
    },
    accessanalyzer_delegatedmasteraccount: {
      value: data[`${PREFIX}/accessanalyzer/delegatedmasteraccount`] || "",
    },
    accounts:
      workloadAccounts && sandboxAccounts
        ? workloadAccounts.concat(sandboxAccounts)
        : [],
  };

  return initialFormData;
};

const SIEM = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  const accounts = useServiceAccounts(projectId);
  const regions = useRegions(projectId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);

        setEmpty(result.data.empty);

        const workloadAccountsResult = await awsAmplify.loadProjectData(
          projectId,
          WORKLOADACCOUNTS_PREFIX
        );

        const workloadAccounts = [];

        if (workloadAccountsResult.data) {
          for (const key in workloadAccountsResult.data) {
            const keyArray = key.split("/");

            if (keyArray[keyArray.length - 1] === "accountname") {
              workloadAccounts.push(workloadAccountsResult.data[key]);
            }
          }
        }

        console.log(workloadAccounts);

        const sandboxAccountsResult = await awsAmplify.loadProjectData(
          projectId,
          SANDBOXACCOUNTS_PREFIX
        );

        const sandboxAccounts =
          sandboxAccountsResult.data &&
          sandboxAccountsResult.data[SANDBOXACCOUNTS_PREFIX]
            ? sandboxAccountsResult.data[SANDBOXACCOUNTS_PREFIX].split(",")
            : [];

        console.log(sandboxAccounts);

        setIsLoading(false);

        setFormData(
          convertBEToFE(result.data, workloadAccounts, sandboxAccounts)
        );
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data, workloadAccounts, sandboxAccounts) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data, workloadAccounts, sandboxAccounts);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploysiemcapabilities`]: formData.deploysiemcapabilities
        .value,
    };

    if (formData.deploysiemcapabilities.value === "yes") {
      be[`${PREFIX}/amazondetective/enable`] =
        formData.amazondetective_enable.value;

      if (formData.amazondetective_enable.value === "yes") {
        if (formData.amazondetective_delegatedmasteraccount.value !== "") {
          be[`${PREFIX}/amazondetective/delegatedmasteraccount`] =
            formData.amazondetective_delegatedmasteraccount.value;
        }

        if (formData.amazondetective_regionslist.length > 0) {
          be[
            `${PREFIX}/amazondetective/regionslist`
          ] = formData.amazondetective_regionslist.join(",");
        }

        if (formData.amazondetective_accountslist.length > 0) {
          be[
            `${PREFIX}/amazondetective/accountslist`
          ] = formData.amazondetective_accountslist.join(",");
        }

        be[`${PREFIX}/amazondetective/autoenablefornewaccounts`] =
        formData.amazondetective_autoenablefornewaccounts.value;
      }

    

      be[`${PREFIX}/accessanalyzer/enable`] =
        formData.accessanalyzer_enable.value;

      if (formData.accessanalyzer_enable.value === "yes") {
        if (formData.accessanalyzer_delegatedmasteraccount.value !== "") {
          be[`${PREFIX}/accessanalyzer/delegatedmasteraccount`] =
            formData.accessanalyzer_delegatedmasteraccount.value;
        }
      }
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const exists = (key, value) => {
    return formData[key].indexOf(value) !== -1;
  };

  const add = (key, value) => {
    setIsDirty(true);

    setFormData((state) => update(state, { [key]: { $push: [value] } }));
  };

  const remove = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: { $splice: [[state[key].indexOf(value), 1]] },
      })
    );
  };

  const modifyArray = (key, value) => {
    if (exists(key, value)) {
      remove(key, value);
    } else {
      add(key, value);
    }
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Security</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Security"
                isDirty={isDirty}
                dashboardRoute={ROUTES.SECURITY_HUB_MEMBERS}
                designRoute={ROUTES.DESIGN_SECURITY}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div class="mainArea fitDeviceHeight siem-page flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div class="row d-flex align-items-center">
                  <div class="col-xl-4 col-lg-4 col-md-4 pl-lg-0 px-2">
                    <h3>SIEM </h3>
                  </div>
                  <div class="col-xl-8 col-lg-8 col-md-8 d-flex align-items-center justify-content-end rightNavArea px-0">
                    <div class="d-inline-flex yes-no-area align-items-center">
                      <div class="content-label mr-xl-5 mr-lg-3 mr-1 font-12 text-nowrap">
                        Deploy SIEM Capabilities
                      </div>
                      <div class=" btn-group btn-group-toggle btn-group-radio mw-100 ">
                        <RadioGroup
                          field="deploysiemcapabilities"
                          value={formData.deploysiemcapabilities.value}
                          onChange={updateRadio}
                          isLarge={true}
                        ></RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>
                {empty && (
                  <NoInitialData />
                )}
                {formData.deploysiemcapabilities.value === "yes" && (
                  <div class="row">
                    <div class="col-12 px-1 mt-3 mb-1">
                      <div class="w-100">
                        <div class="d-inline-flex yes-no-area align-items-center">
                          <div class="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                            Enable Amazon Detective for Security Investigation &
                            Threat Hunting
                          </div>
                          <div class="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                            <RadioGroup
                              field="amazondetective_enable"
                              value={formData.amazondetective_enable.value}
                              onChange={updateRadio}
                            ></RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    {formData.amazondetective_enable.value === "yes" && (
                      <div class="mainContentArea row px-2 py-1 my-1">
                        <div class="d-inline-flex align-items-center row px-0">
                          <div class="col-md-12 flex-wrap d-inline-flex">
                            <div class="mr-3 my-1">
                              <span class="mb-0 mr-xl-5 mr-lg-3 mr-1 font-12">
                                Delegated Amazon Detective Master Account
                              </span>
                              <div class="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                <select
                                  class="form-control gray"
                                  value={
                                    formData
                                      .amazondetective_delegatedmasteraccount
                                      .value
                                  }
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setIsDirty(true);
                                    setFormData((state) =>
                                      update(state, {
                                        amazondetective_delegatedmasteraccount: {
                                          value: { $set: value },
                                        },
                                      })
                                    );
                                  }}
                                >
                                  <option value="">Select</option>
                                  {accounts.security.map((acc, idxAcc) => (
                                    <option value={acc} key={idxAcc}>
                                      {acc}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="align-items-center my-1 row">
                          <div class="col-md-12 my-1  p-0">
                            <span class="mb-0 font-12 font-weight-bold">
                              Regions
                            </span>
                          </div>

                          <div class=" d-inline-flex justify-content-start row formRow px-md-2 px-3 my-1 align-items-start flex-wrap">
                            {regions.map((reg, idxReg) => (
                              <div class="form-line col-md-3 col-12 my-1  d-inline-flex align-items-center">
                                <label class="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked={exists(
                                      "amazondetective_regionslist",
                                      reg
                                    )}
                                    onChange={() =>
                                      modifyArray(
                                        "amazondetective_regionslist",
                                        reg
                                      )
                                    }
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <span class="switchLabel ">{reg}</span>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div class=" align-items-center my-1 row">
                          <div class="col-md-12 my-1  p-0">
                            <span class="mb-0 font-12 font-weight-bold">
                              Enable Amazon Detective on Following Accounts
                            </span>
                          </div>

                          <div class=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center justify-content-start flex-wrap">
                            {accounts.shared
                              .concat(accounts.security)
                              .concat(formData.accounts)
                              .map((acc, idxAcc) => (
                                <div
                                  key={idxAcc}
                                  class="form-line col-xxl-4 col-lg-6 col-12  my-1 d-inline-flex align-items-center"
                                >
                                  <label class="switch mb-0">
                                    <input
                                      type="checkbox"
                                      checked={exists(
                                        "amazondetective_accountslist",
                                        acc
                                      )}
                                      onChange={() =>
                                        modifyArray(
                                          "amazondetective_accountslist",
                                          acc
                                        )
                                      }
                                    />
                                    <span class="slider round"></span>
                                  </label>
                                  <span class="switchLabel ">{acc}</span>
                                </div>
                              ))}
                          </div>
                        </div>

                          <div class="col-12 px-1 mt-3 mb-1">
                            <div class="w-100">
                              <div class="d-inline-flex yes-no-area align-items-center px-2">
                                <div class="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                                  Auto-Enable Detective for New Accounts
                                </div>
                                <div class="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                                  <RadioGroup
                                    field="amazondetective_autoenablefornewaccounts"
                                    value={formData.amazondetective_autoenablefornewaccounts.value}
                                    onChange={updateRadio}
                                  ></RadioGroup>
                                </div>
                              </div>
                            </div>
                          </div>

                      </div>
                    )}
         
                    <div class="col-12 px-1 mt-3 mb-1">
                      <div class="w-100">
                        <div class="d-inline-flex yes-no-area align-items-center px-2">
                          <div class="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                            Enable Access Analyzer
                          </div>
                          <div class="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                            <RadioGroup
                              field="accessanalyzer_enable"
                              value={formData.accessanalyzer_enable.value}
                              onChange={updateRadio}
                            ></RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                    {formData.accessanalyzer_enable.value === "yes" && (
                      <div class="mainContentArea">
                        <div class="d-inline-flex align-items-center row px-0">
                          <div class="col-md-12 flex-wrap d-inline-flex">
                            <div class="mr-3 my-1">
                              <span class="mb-0 mr-xl-5 mr-lg-3 mr-1 font-12">
                                Delegated Access Analyzer Account
                              </span>
                              <div class="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                <select
                                  class="form-control gray"
                                  value={
                                    formData
                                      .accessanalyzer_delegatedmasteraccount
                                      .value
                                  }
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setIsDirty(true);
                                    setFormData((state) =>
                                      update(state, {
                                        accessanalyzer_delegatedmasteraccount: {
                                          value: { $set: value },
                                        },
                                      })
                                    );
                                  }}
                                >
                                  <option value="">Select</option>
                                  {accounts.security.map((acc, idxAcc) => (
                                    <option value={acc} key={idxAcc}>
                                      {acc}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                
                  </div>
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.SECURITY_PERIMETER_PROTECTION + "/" + projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Perimeter Protection
                    </p>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SIEM;
