import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import axios from 'axios';

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getJSONStr } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "Secrets Management";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
      <>
        <h3>Secrets Management</h3>
      </>
    );
  };
   
  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li  className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Resource Policy" && "my_active" }`}
                onClick={() => setType("Resource Policy")}>
                 Resource Policy</li>
              <li className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Tags" && "my_active" }`}
                onClick={() => setType("Tags")}>
                 Tags</li>
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && ( selectedRow.AccountId ? selectedRow.AccountId : "-") }
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle"> AWS Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && ( selectedRow.Region ? selectedRow.Region : "-" )} 
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Secret Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && ( selectedRow.Name ? selectedRow.Name : "-" )}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Description</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && 
                  (selectedRow.Description ? selectedRow.Description : "-" )}
                  </p>
                </div>
              </div>

              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
               <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Rotation Status
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && 
                  (selectedRow.RotationStatus ? selectedRow.RotationStatus : "-" ) }
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                   Rotation Interval
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && selectedRow.RotationRules && 
                  (selectedRow.RotationRules.AutomaticallyAfterDays ? selectedRow.RotationRules.AutomaticallyAfterDays : "-") }
                  </p>
                </div>

                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                   Rotation Lambda Function
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && ( selectedRow.RotationLambdaARN ? selectedRow.RotationLambdaARN : "-" )}
                  </p>
                </div>
  
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Last Retrieved
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && (selectedRow.LastAccessedDate ? selectedRow.LastAccessedDate : "-")}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Deleted On</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && (selectedRow.DeletedDate ? selectedRow.DeletedDate : "-" )}
                  </p>
                </div>
              </div>
          
              </div>
          
            <div
                className={`bolgeler-container w-100 row p-5 ${type !== "Resource Policy" && "inaktif"
                    }`} >
                <div className="col-12" >
                    <pre className="font-12">
                     {
                       selectedRow.ResourcePolicy ? 
                        getJSONStr(selectedRow.ResourcePolicy) :
                        "No Resource Policy Found."}
                    </pre>
                </div>
            </div>

            <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags> 
          
           </div>
          </div>

       
        </div>
    );
  };

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
      <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.SECURITY_GUARDDUTY_MEMBERS + "/" + projectId} >
      <img src="../images/ribbon-design.svg" />
      <p className="m-0 p-0 ml-2 font-weight-bold">Guard Duty</p>       
      </Link>
      <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.SECURITY_FIREWALL_SECURITY_POLICIES + "/" + projectId} >
      <p className="m-0 p-0 mr-2 font-weight-bold">Firewall Manager</p>
      <img src="../images/ribbon-designRight.svg" />
      </Link>
      </>
    );
  };
  
  
const SecretsManager = () => {
  const columns = useMemo(
    () => [
       {
        Header: "Member Account Id",
        accessor: "AccountId"
       },
      {
        Header: "AWS Region",
        id:"AWS Region",
        accessor: "Region",
      },
      {
        Header: "Secret Name",
        accessor: "Name"
      },
      {
        Header: "Description",
        accessor: "Description",
      },
      {
        Header: "Last Retrieved",
        accessor: "LastAccessedDate",
      },
    ],
    []
  );

  return Dashboard({
    domain: "Security",
    title: "Security",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.SECURITY_SECRETS_MANAGEMENT,
    designRoute: ROUTES.DESIGN_DATA_PROTECTION,
    SubMenu: SubMenu,
  });
};

export default SecretsManager;