
import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";


const dashboardName = "Private DNS Inbound Endpoints";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
        <>
          <h3>Private DNS</h3>
          <div className="otherTableTitles ">
            <h3 className="font-15 activetable">  
              Inbound Endpoints      
            </h3>
            <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_OUTBOUND_ENDPOINTS + "/" + projectId}>  
               Outbound Endpoints
            </Link>          
            </h3>
            <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_RESOLVER_RULES + "/" + projectId}>  
               Resolver Rules
            </Link>          
            </h3>
          </div>
        </>
      );
  };

  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "IP Addresses" && "my_active"
                }`}
                onClick={() => setType("IP Addresses")}
              >
               IP Addresses
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Tags" && "my_active"
                }`}
                onClick={() => setType("Tags")}
              >
                Tags
              </li> 
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Security Group" && "my_active"
                }`}
                onClick={() => setType("Security Group")}
              >
                Security Group
              </li> 
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.Id}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.Name}
                  </p>
                </div>
              </div>

              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Status
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.Status}
                  </p>
                </div>
  
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Host VPC</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.HostVPCId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">ARN</p>
                  <p className="m-0 p-0 account-title-subcontent">
                   {selectedRow && selectedRow.Arn} 
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Direction
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.Direction}
                  </p>
                </div>
             </div>
            </div>

            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "IP Addresses" && "inaktif"
              }`}
            >
              <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>IP Addresses</th>
                      <th>IP Address ID</th>
                      <th>Status</th>
                      <th>Subnet ID</th>
                      <th>Status Message</th>
                      <th>Creation Time</th>
                      <th>Modification Time</th>
                    </tr>
                  </thead>
                  <tbody>
                  {selectedRow  && 
                  selectedRow.IpAddresses.IpAddresses.map((item, idx) => (
                    <tr key={idx}>
                       <td>{item.Ip}</td>
                       <td>{item.IpId}</td>
                       <td>{item.Status}</td>
                       <td>{item.SubnetId}</td>
                       <td>{item.StatusMessage}</td>
                       <td>{item.CreationTime}</td>
                       <td>{item.ModificationTime}</td>
                    </tr>
                  ))}  
                  </tbody>
                </table>
              </div>
            </div>
            
            <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>
      
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Security Group" && "inaktif"
              }`}
            >
                <div  className="d-inline-flex px-2">
                   <div className="detailTableType-1">
                    <div  className="d-flex flex-column">
                      <p className="account-detail-subtitle-1">
                         Security Group ID
                      </p>
                    </div>
                        
                    <div  className="d-flex flex-column">
                        <p className="account-title-subcontent">
                           {selectedRow && 
                            selectedRow.SecurityGroupIds}
                        </p>
                      </div>
                    </div>
                </div>
               </div>

            </div>
          </div>
        </div>
    );
  };


  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
     <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.INFRASTRUCTURE_DIRECTORIES + "/" + projectId} >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Directories</p>       
      </Link> 

      <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.INFRASTRUCTURE_COMPONENTS + "/" + projectId}
    >
      <p className="m-0 p-0 ml-2 font-weight-bold">Image Builder</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
      </>
    );
  };


const InboundEndpoints = () => {
    const columns = useMemo(
        () => [
          {
            Header: "Account ID",
            accessor: "AccountId",
          },
          {
            Header: "Region",
            accessor: "Region",
          },
          {
            Header: "ID",
            accessor: "Id",
            id: "ID"
          },
          {
            Header: "Name",
            accessor: "Name",
            id: "Name"
          },
          {
            Header: "Status",
            accessor: "Status",
            id: "Status"
          },
          {
            Header: "Host VPC ID",
            accessor: "HostVPCId",
            id: "HostVPCId"
          },
          {
            Header: "IP Address Count",
            accessor: "IpAddressCount",
            id: "IP Address Count"
          },
          {
            Header: "ARN",
            accessor: "Arn",
            id: "ARN"
          },
          {
            Header: "Direction",
            accessor: "Direction",
            id: "Direction"
          },
          {
            Header: "Status Message",
            accessor: "StatusMessage",
            id: "Status Message"
          },
          {
            Header: "Creation Time",
            accessor: "CreationTime",
            id: "Creation Time"
          },
          {
            Header: "Modification Time",
            accessor: "ModificationTime",
            id: "Modification Time"
          },
        ],
        []
      );

    return Dashboard({
        domain: "infrastructure",
        title: "Infrastructure",
        dashboardName,
        columns,
        Details,
        Navigations,
        configureRoute: ROUTES.INFRASTRUCTURE_PRIVATEDNS,
        designRoute: ROUTES.DESIGN_INFRASTRUCTURE,
        SubMenu: SubMenu,
      });
    
};

export default InboundEndpoints;