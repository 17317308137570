
import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "Private DNS Resolver Rules";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
        <>
          <h3>Private DNS</h3>
          <div className="otherTableTitles ">
            <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_INBOUND_ENDPOINTS + "/" + projectId}>  
               Inbound Endpoints
            </Link>          
            </h3>
            <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_OUTBOUND_ENDPOINTS + "/" + projectId}>  
              Outbound Endpoints  
            </Link>          
            </h3>
            <h3 className="font-15 activetable">  
              Resolver Rules    
            </h3>
          </div>
        </>
      );
  };

  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "VPCs" && "my_active"
                }`}
                onClick={() => setType("VPCs")}
              >
                VPCs
              </li> 
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Target IP Addresses" && "my_active"
                }`}
                onClick={() => setType("Target IP Addresses")}
              >
               Target IP Addresses
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Tags" && "my_active"
                }`}
                onClick={() => setType("Tags")}
              >
                Tags
              </li> 
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.Id}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.Name}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Status
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.Status}
                  </p>
                </div>
              </div>

              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
             
  
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Outbound Endpoint</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.OutboundEndpoint}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Type</p>
                  <p className="m-0 p-0 account-title-subcontent">
                   {selectedRow && selectedRow.RuleType} 
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                   Sharing Status
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.ShareStatus}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                   Owner
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.OwnerId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                   Domain Name
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.DomainName}
                  </p>
                </div>
             </div>
            </div>

            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "VPCs" && "inaktif"
              }`}
            >
              <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>VPC ID</th>
                      <th>Name</th>
                      <th>Association ID</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>           
     
                      {selectedRow.Associations && selectedRow.Associations.ResolverRuleAssociations &&
                      selectedRow.Associations.ResolverRuleAssociations.map( (item) => 
                      (
                          <>
                          <tr>
                            <td>{item.VPCId} </td>
                            <td>{item.Name} </td>
                            <td>{item.Id} </td>
                            <td>{item.Status} </td>
                          </tr>
                          </>
                      ))}
                      
                  </tbody>
                </table>
              </div>
            </div>
            

            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Target IP Addresses" && "inaktif"
              }`}
            >
              <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>IP Address</th>
                      <th>Port</th>
                    </tr>
                  </thead>
                  <tbody>
                 
                    {selectedRow.TargetIps &&  selectedRow.TargetIps.map( (item) => 
                    <>
                       <tr>
                        <td>{item.Ip}</td>
                        <td>{item.Port}</td>
                      </tr>
                    </>
                    )} 
                  </tbody>
                </table>
              </div>
            </div>
            
            <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>
      
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Security Group" && "inaktif"
              }`}
            >
                <div  className="d-inline-flex px-2">
                   <div className="detailTableType-1">
                    <div  className="d-flex flex-column">
                      <p className="account-detail-subtitle-1">
                         Security Group ID
                      </p>
                    </div>
                        
                    <div  className="d-flex flex-column">
                        <p className="account-title-subcontent">
                           {selectedRow && 
                            selectedRow.SecurityGroupIds}
                        </p>
                      </div>
                    </div>
                </div>
               </div>

            </div>
          </div>
        </div>
    );
  };

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
     <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.INFRASTRUCTURE_DIRECTORIES + "/" + projectId} >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Directories</p>       
      </Link> 

      <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.INFRASTRUCTURE_COMPONENTS + "/" + projectId}
    >
      <p className="m-0 p-0 ml-2 font-weight-bold">Image Builder</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
      </>
    );
  };

  
const ResolverRules = () => {
    const columns = useMemo(
        () => [
          {
            Header: "Account ID",
            accessor: "AccountId",
          },
          {
            Header: "AWS Region",
            accessor: "Region",
          },
          {
            Header: "Name",
            accessor: "Name",
            id: "Name"
          },
          {
            Header: "ID",
            accessor: "Id",
            id: "ID"
          },
          {
            Header: "Status",
            accessor: "Status",
            id: "Status"
          },
          {
            Header: "Outbound Endpoint",
            accessor: "",
            id: "Outbound Endpoint"
          },
          {
            Header: "Type",
            accessor: "RuleType",
            id: "Type"
          },
          {
            Header: "Sharing Status",
            accessor: "ShareStatus",
            id: "Sharing Status"
          },
          {
            Header: "Owner",
            accessor: "OwnerId",
            id: "Owner"
          },
          {
            Header: "Domain Name",
            accessor: "DomainName",
            id: "Domain Name"
          },
          {
            Header: "Target IP Addresses",
            accessor: (d) => (d.TargetIps && d.TargetIps.length),
            id: "Target IP Addresses"
          },
          {
            Header: "Associations",
            accessor: (d) => (d.Associations && d.Associations.ResolverRuleAssociations && d.Associations.ResolverRuleAssociations.length),
            id: "Associations"
          },
        ],
        []
      );

    return Dashboard({
        domain: "infrastructure",
        title: "Infrastructure",
        dashboardName,
        columns,
        Details,
        Navigations,
        configureRoute: ROUTES.INFRASTRUCTURE_PRIVATEDNS,
        designRoute: ROUTES.DESIGN_INFRASTRUCTURE,
        SubMenu: SubMenu,
      });
};


export default ResolverRules;