const _getGroup = (cognitoUser) => {
  let group = null;
if (
  cognitoUser &&   
  cognitoUser.signInUserSession &&
  cognitoUser.signInUserSession.idToken &&
  cognitoUser.signInUserSession.idToken.payload
) {
  const groups =
    cognitoUser.signInUserSession.idToken.payload["cognito:groups"];

  if (groups.indexOf("SuperAdmin") !== -1) {
    group = "SuperAdmin";
  } else if (groups.indexOf("ClientAdmin") !== -1) {
    group = "ClientAdmin";
  } else if (groups.indexOf("User") !== -1) {
    group = "User";
  }
}
return group;
}

export const getGroup = (cognitoUser) => {
  return _getGroup(cognitoUser);
}

export const isUser = (cognitoUser) => {
  return _getGroup(cognitoUser) === "User";
}

export const isClientAdmin = (cognitoUser) => {
  return _getGroup(cognitoUser) === "ClientAdmin";
}

export const isSuperAdmin = (cognitoUser) => {
  return _getGroup(cognitoUser) === "SuperAdmin";
}