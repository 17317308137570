import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { Link, useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import {
  validate1to15AlphaNumeric,
  validateAccountName,
  validateAccountEmailId,
} from "../../../../helpers/validator";
import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const projectId = "google_1";
const PREFIX = "/azure/subscriptionframework/sandboxsubscriptions";
const MGMT_STRUCTURE_PREFIX = "/azure/subscriptionframework/managementgroups";

const createEntity = () => {
  return {
    subscriptionname: {
      value: "",
      isValid: false,
    },
    managementgroupname: {
      value: "",
    },
  };
};

const AzureSandboxAccounts = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    createsandboxsubscriptions: {
      value: "yes",
    },
    array: [createEntity()],
    managementgroupnames: [],
    managementgroupname : {
      value: ""
    }
  });
  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);
        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        const managementgroupstructure = await awsAmplify.loadProjectData(projectId, MGMT_STRUCTURE_PREFIX);
        setIsLoading(false);

        console.log(result);

        setEmpty(result.data.empty);

        const fe = convertBEToFE(result, managementgroupstructure);
        setFormData(fe);

      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if(err.response.data.message === "You have no access"){
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);
      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response && err.response.data.message);
    }
  };

  const convertBEToFE = (result,managementgroupstructure) => {
    const fe = {
      createsandboxsubscriptions: {
        value: "yes",
      },
      array: [],
      managementgroupnames: []
    };

    if(managementgroupstructure !== undefined && managementgroupstructure.data)
    {
      const mgmt_data = managementgroupstructure.data;
      var mgmtGroupList = mgmt_data[`${MGMT_STRUCTURE_PREFIX}/managementgrouplist`]; 
      fe.managementgroupnames = mgmtGroupList && mgmtGroupList.split(',').filter(Boolean) || [];
    }

    if (result && result.data && !result.data.empty) {
      let data = result.data;
      fe.createsandboxsubscriptions.value =
        data[`${PREFIX}/createsandboxsubscriptions`];

      if (data[`${PREFIX}/createsandboxsubscriptions`] === "yes") {
        if (data[`${PREFIX}/sandboxsubscriptionslist`]) {
          const subscriptionNames = data[`${PREFIX}/sandboxsubscriptionslist`].split(
            ","
          );

          for (const subscriptionName of subscriptionNames) {
            const entity = createEntity();

            entity.subscriptionname.value = subscriptionName;
            entity.subscriptionname.isValid = true;

            if (data[`${PREFIX}/${subscriptionName}/managementgroupname`]) {
              const name =  data[`${PREFIX}/${subscriptionName}/managementgroupname`];
              entity.managementgroupname.value =  getManagementGroupHierarchy(name, fe.managementgroupnames) || "";
          }

            fe.array.push(entity);
          }
        }
      }
    } else {
      fe.array.push(createEntity());
    }

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/createsandboxsubscriptions`]: formData.createsandboxsubscriptions
        .value,
    };

    if (formData.createsandboxsubscriptions.value === "yes") {
      const sandboxsubscriptionslist = [];

      for (const item of formData.array) {
        console.log(item);
        if (item.subscriptionname.isValid) {
          sandboxsubscriptionslist.push(item.subscriptionname.value);

          if(item.subscriptionname.isValid) {
            be[`${PREFIX}/${item.subscriptionname.value}/subscriptionname`] =
              item.subscriptionname.value;
          }
          if(item.managementgroupname.value != "") {
            const managementGroupHierarchy =  item.managementgroupname.value;
           const groupArray = managementGroupHierarchy && managementGroupHierarchy.split('/');
           if(groupArray && groupArray[groupArray.length-1] != "")
           be[`${PREFIX}/${item.subscriptionname.value}/managementgroupname`] = groupArray[groupArray.length-1];
         }
        }
      }

      if (sandboxsubscriptionslist.length > 0) {
        be[`${PREFIX}/sandboxsubscriptionslist`] = sandboxsubscriptionslist.join(",");
      }
    }

    console.log(be);

    return be;
  };

  const addApp = () => {
    setIsDirty(true);

    setFormData((state) => {
      return {
        ...state,
        array: update(state.array, { $push: [createEntity()] }),
      };
    });
  };

  const removeApp = (idx) => {
    setIsDirty(true);

    setFormData((state) => {
      return {
        ...state,
        array: update(state.array, { $splice: [[idx, 1]] }),
      };
    });
  };

  const updateArrayValue = (idx, key, value) => {
    setFormData((state) => {
      return {
        ...state,
        array: update(state.array, {
          [idx]: {
            [key]: {
              value: { $set: value },
            },
          },
        }),
      };
    });
  };

  const updateArrayIsValid = (idx, key, isValid) => {
    setFormData((state) => {
      return {
        ...state,
        array: update(state.array, {
          [idx]: {
            [key]: {
              isValid: { $set: isValid },
            },
          },
        }),
      };
    });
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const getManagementGroupHierarchy = (name, groupnames) => {
    for(const groupname of groupnames)
    {
      let groupArray = groupname.split('/');
      if(groupArray){
        var lastString = groupArray[groupArray.length-1];
        if(name == lastString)
        {
          return groupname;
        }
     }
    }
  }


  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Subscription Framework</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
          <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                title = "Subscription Framework"
                saveDataIfDirty={saveDataIfDirty}
                isDirty={isDirty}
                dashboardRoute={ROUTES.AZURE_ORGANIZATIONAL_UNITS}
                designRoute={ROUTES.AZURE_DESIGN_SUBSCRIPTION_FRAMEWORK}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 ">
                <div className="row d-flex align-items-center">
                  <div className="col-xl-5 col-lg-4 col-md-4 pl-lg-0 px-2">
                    <h3>Sandbox Subscriptions</h3>
                  </div>
                  <div className="px-0 col-xl-7 col-lg-8 col-md-8 d-flex align-items-center justify-content-end rightNavArea">
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 text-nowrap">
                        Create Sandbox Subscriptions
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100">
                        <label
                          className={`createElementBtn btn btn-link mw-70 btn-linkYes ${
                            formData.createsandboxsubscriptions.value === "yes"
                              ? "active"
                              : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="createsandboxsubscriptions"
                            value="yes"
                            checked={
                              formData.createsandboxsubscriptions.value === "yes"
                            }
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) => {
                                return {
                                  ...state,
                                  createsandboxsubscriptions: {
                                    value: "yes",
                                  },
                                };
                              });
                            }}
                          />{" "}
                          YES
                        </label>
                        <label
                          className={`dontCreateElementBtn btn btn-link mw-70 btn-linkNo ${
                            formData.createsandboxsubscriptions.value === "no"
                              ? "active"
                              : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="createsandboxsubscriptions"
                            value="no"
                            checked={
                              formData.createsandboxsubscriptions.value === "no"
                            }
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) => {
                                return {
                                  ...state,
                                  createsandboxsubscriptions: {
                                    value: "no",
                                  },
                                };
                              });
                            }}
                          />{" "}
                          NO
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {empty && (
                    <NoInitialData />
                )}
                {formData.createsandboxsubscriptions.value === "yes" && (
                  <div className="show-hide-content">
                    <div className="d-flex flex-column mainContentArea  px-3 py-1 my-1">
                      {formData.array.map((item, idx) => {
                        return (
                          <div
                            className=" d-inline-flex my-1 formRow px-md-2 px-3 align-items-center justify-content-between"
                            key={idx}
                          >
                            <div className="d-flex align-items-center justify-content-center number mr-xl-5 mr-lg-4 mr-md-3 mr-1 my-lg-0   my-1 one">
                              {idx + 1}
                            </div>
                            <div className="d-inline-flex form-line two my-lg-0  my-1">
                              <div className="d-flex align-items-center mx-xl-5">
                                <b className="text-nowrap">Subscription Name</b>
                              </div>
                              <input
                                type="text"
                                className={
                                  !item.subscriptionname.isValid && item.subscriptionname.value !== ""
                                    ? "form-control invalid"
                                    : "form-control"
                                }
                                placeholder={`Developer ${idx + 1}`}
                                value={(item.subscriptionname && item.subscriptionname.value) || ""}
                                onChange={(e) => {
                                  setIsDirty(true);
                                  setErrorMsg("");

                                  const value = e.target.value;

                                  for (const [
                                    idxOtherItem,
                                    otherItem,
                                  ] of formData.array.entries()) {
                                    if (idx !== idxOtherItem) {
                                      if (
                                        otherItem.subscriptionname.isValid &&
                                        otherItem.subscriptionname.value !== ""
                                      ) {
                                        if (
                                          otherItem.subscriptionname.value.toLowerCase() ===
                                          value.toLowerCase()
                                        ) {
                                          setErrorMsg(
                                            "Subscription name must be unique"
                                          );
                                          return false;
                                        }
                                      }
                                    }
                                  }

                                  updateArrayValue(idx, "subscriptionname", e.target.value);
                                }}
                                onBlur={(e) => {
                                  const value = e.target.value;

                                  if (value !== "") {
                                    const errorMsg = validateAccountName(value);

                                    updateArrayIsValid(
                                      idx,
                                      "subscriptionname",
                                      errorMsg === undefined
                                    );

                                    if (errorMsg) {
                                      setErrorMsg(errorMsg);
                                    }
                                  } else {
                                    updateArrayIsValid(idx, "subscriptionname", false);
                                  }
                                }}
                              />
                            </div>

                            <div className="d-inline-flex form-line two my-lg-0  my-1">
                             <div className="d-flex align-items-center mx-xl-5 font-weight-light">   
                             <b className="text-nowrap">Management Group Name </b>
                              </div>
                              { (formData.managementgroupnames && formData.managementgroupnames.length === 0) ? (
                              <div className="col-md-9 flex-wrap d-inline-flex">
                                  <span className="warning justify-content-center">
                                  This section depends on the organizational structure information in
                                  Subscription Framework section that is yet to be provided
                                </span>
                                </div>
                              ) : (
                                <>
                              <div className="col-md-9 flex-wrap d-inline-flex">
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                <select className="form-control pr-lg-0 pr-5" 
                                    disabled={disabled}
                                      value={(item.managementgroupname && item.managementgroupname.value)}
                                      onChange={(e) => {
                                      updateArrayValue(idx, "managementgroupname", e.target.value);
                                      setIsDirty(true);
                                    }}>
                                <option value="">Select</option>
                                {formData.managementgroupnames && formData.managementgroupnames.map((managementgroupname, idxV) => (
                                (managementgroupname !== "") &&
                                  <option key={idxV} value={managementgroupname}>
                                    {managementgroupname}
                                  </option>
                                ))}</select>             
                                </div>
                                </div>
                                </>
                              )}
                             </div>
                   
                            <button disabled={disabled}
                              type="button"
                              className="closeFormRow closeFormRowBtn my-lg-0   my-1 four"
                              onClick={() => removeApp(idx)}
                            >
                              <img src="../images/bigfontclose.svg" />
                            </button>
                            <button disabled={disabled}
                              type="button"
                              className="closeFormRowMobil closeFormRowBtn four"
                              onClick={() => removeApp(idx)}
                            >
                              <img src="../images/bigfontclose.svg" />
                            </button>
                          </div>
                        );
                      })}

                      <div className="w-100  mb-2  ">
                        <div className="row d-flex align-items-center">
                          <div className="add-button d-flex px-1  justify-content-end">
                            <button disabled={disabled}
                              type="button"
                              className="add-new-account-link btn-animation d-inline-flex align-items-center"
                              onClick={() => addApp()}
                            >
                              <p className="my-0 mr-2 p-0 green-style2">
                                Add Sandbox Subscription
                              </p>
                              <img src="../images/coloredPlus.svg" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              </fieldset>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={async () => {
                      if (isDirty) {
                        await submit();
                      }
                      history.push(
                        ROUTES.AZURE_WORKLOAD_SUBSCRIPTIONS + "/" + projectId
                      );
                    }}
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                    Workload Subscriptions
                    </p>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {console.log(formData)}
    </>
  );
};

export default AzureSandboxAccounts;
