import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import { getArrayValue, getBooleanValue, getJSONStr} from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "./DetailsGrid";
import DetailsTags from "./DetailsTags";

//const projectId = "google_1";
const dashboardName = "VPC Endpoints";

const SubMenu = () => {
  const { projectId } = useParams();

  return (
    <>
      <h3>VPCs</h3>
      <div className="otherTableTitles ">
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPC + "/" + projectId}>
            VPCs
          </Link>
        </h3>
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPC_SUBNETS + "/" + projectId}>
            Subnets
          </Link>
        </h3>
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPC_ROUTE_TABLES + "/" + projectId}>
            Route Tables
          </Link>
        </h3>
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPC_DHCP + "/" + projectId}>
            DHCP
          </Link>
        </h3>
        <h3 className="font-15 activeTable">Endpoints</h3>
      </div>
    </>
  );
};

const Details = ({ close, selectedRow }) => {
  const [type, setType] = useState("Details");

  return (
    <div className="justify-content-center align-items-end account-detail">
      <div className="w-100 s-flex flex-column account-detail-area">
        <button className="exitAccount" onClick={() => close()}>
          <img src="../images/close.svg" />
        </button>
        <div className="">
          <ul className="account-detail-link m-0 p-0 d-inline-flex">
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Details" && "my_active"
              }`}
              onClick={() => setType("Details")}
            >
              Details
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Route Tables" && "my_active"
              }`}
              onClick={() => setType("Route Tables")}
            >
              Route Tables
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Subnet IDs" && "my_active"
              }`}
              onClick={() => setType("Subnet IDs")}
            >
              Subnet IDs
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Policy" && "my_active"
              }`}
              onClick={() => setType("Policy")}
            >
              Policy
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Tags" && "my_active"
              }`}
              onClick={() => setType("Tags")}
            >
              Tags
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 account-detail-container-area">
          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Details" && "inaktif"
            }`}
          >
            <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.AccountId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Region</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Region}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Endpoint Name</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {getArrayValue(selectedRow.Tags, -1, "Key", "Name", "Value")}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Endpoint ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.VpcEndpointId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Endpoint Owner ID
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.OwnerId}
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">VPC ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.VpcId}
                </p>
              </div>

              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Service Name</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.ServiceName}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Endpoint Type</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.VpcEndpointType}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">State</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.State}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Creation Time</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.CreationTimestamp}
                </p>
              </div>
            </div>
          </div>
          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Route Tables" && "inaktif"
            }`}
          >
            <table className="details-table">
              <thead>
                <tr>
                  <th>Route Table ID</th>
                  <th>Main</th>
                  <th>Associated With</th>
                </tr>
              </thead>
              <tbody>
              {(selectedRow.VpcEndpointType === "Gateway" ? ( selectedRow.RouteTableIds &&
                  selectedRow.RouteTableIds.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item}</td>
                    </tr>
                  ))) : "" )}
              </tbody>
            </table>
          </div>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Subnet IDs" && "inaktif"
            }`}
          >
            <table className="details-table">
              <thead>
                <tr>
                  <th>Subnet IDs</th>
                  <th>Main</th>
                  <th>Associated With</th>
                </tr>
              </thead>
              <tbody>
                {(selectedRow.VpcEndpointType === "Interface" ? ( selectedRow.SubnetIds &&
                  selectedRow.SubnetIds.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item}</td>
                    </tr>
                  ))) : "" )}
              </tbody>
            </table>
          </div>


          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Policy" && "inaktif"
            }`}
          >
            <pre className="font-12">{selectedRow && getJSONStr(selectedRow.PolicyDocument)}</pre>
          </div>

          <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "networkAcls" && "inaktif"
            }`}
          >
            <div className="col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  {selectedRow.NetworkACL &&
                    selectedRow.NetworkACL.NetworkAclId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-title-subcontent"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Navigations = () => {
  const { projectId } = useParams(); return (
  <Link className="btn-post btn-animation d-inline-flex" to={ROUTES.TGW + "/" + projectId}>
    <p className="m-0 p-0 mr-2 font-weight-bold">Transist Gateway</p>
    <img src="../images/ribbon-designRight.svg" />
  </Link>
)};

const VPCEndpoints = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "Endpoint Name",
        accessor: (d) => getArrayValue(d.Tags, -1, "Key", "Name", "Value"),
      },
      {
        Header: "Endpoint ID",
        accessor: "VpcEndpointId",
      },
      {
        Header: "Endpoint Owner ID",
        accessor: "OwnerId",
      },
      {
        Header: "VPC ID",
        accessor: "VpcId",
      },
      {
        Header: "Service Name",
        accessor: "ServiceName",
      },
      {
        Header: "Endpoint Type",
        accessor: "VpcEndpointType",
      },
      {
        Header: "Status",
        accessor: "State",
      },
      {
        Header: "Creation Time",
        accessor: "CreationTimestamp",
      },
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "network",
    title: "Network",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_CENTERS,
    designRoute: ROUTES.DESIGN_NETWORK,
    SubMenu: SubMenu,
  });
};

export default VPCEndpoints;
