import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useBlockLayout,
  useFlexLayout,
} from "react-table";

import LeftHandMenu from "./LeftHandMenu";
import TopMenu from "../TopMenu";
import DomainMenu from "./DomainMenu";
import useAwsAmplify from "../../hooks/useAwsAmplify";
import useHideLeftMenu from "../../hooks/useHideLeftMenu";
import { useHistory, useParams } from "react-router-dom";

const Dashboard = ({
  //projectId,
  domain,
  dashboardName,
  columns,
  Details,
  Navigations,
  configureRoute,
  title,
  SubMenu,
  designRoute,
}) => {
  const { projectId } = useParams();

  const history = useHistory();
  const awsAmplify = useAwsAmplify();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [selectedRowId, setSelectedRowId] = useState(-1);

  const {
    allColumns,
    rows,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        globalFilter: "",
        pageIndex: 0,
        pageSize: 10,
        //sortBy: [{ id: "Name", desc: false }],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
    //useFlexLayout,
    //useResizeColumns
  );

  const checkHasDashboardAccess = async () => {
    try{
    setErrorMsg(null);
    setIsLoading(true);

    const result = await awsAmplify.getProjectAccess(
      projectId
    );
    setIsLoading(false);

    console.log(result);

    if (result &&
      result.data &&
      result.data
        .getProjectAccess &&
      result.data.getProjectAccess
        .hasDashboardAccess
    ) {
      fetchData();
    } else {   
      history.goBack();
    }
  }
  catch(err)
  {
    console.log(err);
    setIsLoading(false);
    setErrorMsg(err.response && err.response.data.message);

    if(err.response && err.response.data.message === "You have no access"){
      history.goBack();
     }
    }
 };

  const fetchData = async () => {
    try
    {
    setErrorMsg(null);  
    setIsLoading(true);
    const results = await awsAmplify.validateProjectData(
      projectId,
      dashboardName.split(" ").join("")
    );

    setIsLoading(false);

    console.log(results);
    var data = (results && results.data) ? results.data : [];
    setData(data);
    }
    catch(err)
    {
      console.log(err);
      setIsLoading(false);
      setErrorMsg(err.response && err.response.data.message);

      if(err.response && err.response.data.message === "You have no access"){
        history.goBack();
      }
    }
  };

  useEffect(() => {
    checkHasDashboardAccess();
   // fetchData();

  }, [projectId]);

  useHideLeftMenu();

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      {showSettings && (
        <div className="justify-content-center align-items-center popupArea list-of-table-columns">
          <div className="auth-main">
            <div className="auth-form">
              <button
                type="button"
                className="exit btn-animation"
                onClick={() => setShowSettings(false)}
              >
                Exit
              </button>
              <div className="title">Columns</div>
              <div className="auth-form-content">
                <ul className="default-list-type">
                  {allColumns && allColumns.length > 0 &&  allColumns.map((column, idx) => (
                    <li key={idx}>
                      <div>
                        <label className="container">
                          <input
                            type="checkbox"
                            {...column.getToggleHiddenProps()}
                          />
                          <span className="checkmark"></span>
                          {column.id}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="auth-form-footer">
                <div>
                  <button
                    type="button"
                    className="btn confirmBtn btn-link btn-green btn-animation"
                    onClick={() => setShowSettings(false)}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="userTitleTop"></div>
      <LeftHandMenu domain={domain} isDashboard={true}></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu></TopMenu>
            <div className="tableArea accountsTableArea accountsTitledTable container-fluid p-0 d-flex flex-column">
              {showDetails && (
                <Details
                  close={() => {
                    setSelectedRowId(-1);
                    setShowDetails(false);
                    setSelectedRow(null);
                  }}
                  selectedRow={selectedRow}
                ></Details>
              )}
              <DomainMenu
                title={title}
                isDashboard={true}
                isConfigure={false}
                configureRoute={configureRoute}
                designRoute={designRoute}
              ></DomainMenu>
              <div className="mainArea fitDeviceHeight network-aws-environment flex-column px-md-1 px-0">
                <div>
                  <div
                    id="datatable_wrapper"
                    className="dataTables_wrapper no-footer h-100"
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-7">
                        <div
                          className="dataTables_length bs-select"
                          id="datatable_length"
                        >
                          {!SubMenu && <h3>{dashboardName}</h3>}
                          {SubMenu && <SubMenu></SubMenu>}

                          <label>
                            Show
                            <select
                              name="datatable_length"
                              aria-controls="datatable"
                              className="custom-select custom-select-sm form-control form-control-sm"
                              value={pageSize}
                              onChange={(e) => {
                                setPageSize(Number(e.target.value));
                              }}
                            >
                              {[10, 50, 100, 500].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                  {pageSize}
                                </option>
                              ))}
                            </select>{" "}
                            entries
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-5">
                        <div
                          id="datatable_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            <div className="d-flex align-items-center justify-content-center">
                              <img
                                className="searchBar"
                                src="../images/search.svg"
                                alt=":)"
                              />
                            </div>
                            <input
                              type="search"
                              className="form-control form-control-sm"
                              placeholder="Search by keyword"
                              aria-controls="datatable"
                              value={globalFilter || ""}
                              onChange={(e) => {
                                setGlobalFilter(e.target.value);
                              }}
                            />
                          </label>
                          <button
                            type="button"
                            className="d-flex align-items-center justify-content-center p-2 refreshButton"
                            onClick={() => {
                              setShowDetails(false);
                              fetchData();
                            }}
                          >
                            <img src="../images/refresh.svg" alt=":)" />
                          </button>
                          <button
                            type="button"
                            onClick={() => setShowSettings(true)}
                            className="d-flex align-items-center justify-content-center p-2 ml-2 settingsButton"
                          >
                            <img src="../images/settings.svg" alt=":)" />
                          </button>
                          <ul className="pagination">
                            <li
                              className="paginate_button page-item previous"
                              id="datatable_previous"
                            >
                              <button
                                className="page-link"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                              >
                                <img src="../images/ribbon-design.svg" />{" "}
                              </button>
                            </li>
                            <li className="pageInfo">
                              <span className="currentPage ">
                                {pageIndex + 1}
                              </span>
                              <span className="totalPage">
                                /{pageOptions.length}
                              </span>
                            </li>
                            <li
                              className="paginate_button page-item next"
                              id="datatable_next"
                            >
                              <button
                                className="page-link"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                              >
                                <img src="../images/ribbon-designRight.svg" />
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 h-100">
                        <table
                          id="datatable"
                          className="w-100 h-100"
                          {...getTableProps()}
                        >
                          <thead>
                            {headerGroups.map((headerGroup) => (
                              <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                  <th
                                    className={
                                      column.isSorted
                                        ? column.isSortedDesc
                                          ? "descending"
                                          : "ascending"
                                        : ""
                                    }
                                    {...column.getHeaderProps(
                                      column.getSortByToggleProps()
                                    )}
                                  >
                                    <span {...column.getSortByToggleProps()}>
                                      {column.render("Header")}
                                    </span>
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>
                          <tbody
                            {...getTableBodyProps()}
                            style={{ overflowY: "scroll" }}
                          >
                            {page && page.map((row, i) => {
                              prepareRow(row);
                              return (
                                <tr
                                  {...row.getRowProps()}
                                  className={`${
                                    selectedRowId === row.id && "details-row"
                                  }`}
                                  onClick={() => {
                                    if (selectedRow) {
                                      if (row.id === selectedRowId) {
                                        setSelectedRowId(-1);
                                        setShowDetails(false);
                                        setSelectedRow(null);
                                      } else {
                                        setSelectedRowId(row.id);
                                        setSelectedRow(row.original);
                                      }
                                    } else {
                                      setSelectedRowId(row.id);
                                      setShowDetails(true);
                                      setSelectedRow(row.original);
                                    }
                                  }}
                                >
                                   {row && row.cells.map((cell) => {
                                    return (
                                      <td {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                     
                      {!isLoading && rows.length === 0 && (
                              <label className="font-12">                 
                             No {dashboardName} found
                             </label>
                       ) }
                       </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea px-md-3 px-0">
                  <div
                    className="dataTables_info"
                    id="datatable_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing {pageIndex * pageSize + 1} to{" "}
                    {pageIndex * pageSize + pageSize > rows.length
                      ? rows.length
                      : pageIndex * pageSize + pageSize}{" "}
                    of {rows.length} entries
                  </div>
                  <Navigations></Navigations>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
