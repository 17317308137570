






import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getBooleanValue } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "WorkspacesIPAccessControls";  //To do:  mapping pending

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
        <>
          <h3>Workspaces</h3>
          <div className="otherTableTitles ">
            <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_WORKSPACES + "/" + projectId}>  
                 Workspaces 
            </Link>  
            </h3>
            <h3 className="font-15 activetable">   
                 IP Access Controls       
            </h3>         
          </div>
        </>
      );
  };

  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "User Rules" && "my_active"
                }`}
                onClick={() => setType("User Rules")}
              >
                User Rules
              </li>
             </ul>
          </div>

          {/*<DetailsGrid
            type={type}
            typeName="User Rules"
            array={}
            metadata={[
              { header: "IP Rule", key: "" },
              { header: "Rule Description", key: "" },
            ]}
        ></DetailsGrid>*/}

          </div>
        </div>
    );
  };

  function Navigations() {
  const { projectId } = useParams();
  return (
    <>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.INFRASTRUCTURE_COMPONENTS + "/" + projectId}>
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Image Builder</p>
      </Link>

    
    </>
  );
}

const IPAccessControls = () => {
    const columns = useMemo(
        () => [
          {
            Header: "Account ID",
            accessor: "AccountId",
          },
          {
            Header: "Region",
            accessor: "Region",
          },
          {
            Header: "Group Name",
            accessor: "",
            id: "Group Name"
          },
          {
            Header: "Group Description",
            accessor: "",
            id: "Group Description"
          },
          {
            Header: "Group Id",
            accessor: "",
            id: "Group Id"
          },
        ],
        []
      );

    return Dashboard({
        domain: "infrastructure",
        title: "Infrastructure",
        dashboardName,
        columns,
        Details,
        Navigations,
        configureRoute: ROUTES.INFRASTRUCTURE_VDISOLUTION,
        designRoute: ROUTES.DESIGN_INFRASTRUCTURE,
        SubMenu: SubMenu,
      });
};

export default IPAccessControls;