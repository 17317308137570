export const getError = (error, setErrorMessage) => {
  let errorMsg;

  if (error.graphQLErrors) {
    errorMsg = error.graphQLErrors
      .map(({ message }) => {
        if (message.indexOf("PreSignUp failed with error") !== -1) {
          const errorMessageArray = message.split(":");
          if (errorMessageArray.length > 1) {
            return errorMessageArray[1];
          }
        } else if (
          message === "An account with the given email already exists."
        ) {
          return "User account already exists";
        } else {
          return message;
        }
      })
      .join(" ");
  } else if (error.response && error.response.data) {
    errorMsg = error.response.data.message;
    //setErrorMessage(error.response.data.message);
  } else if (error.message) {
    errorMsg = error.message;
    //setErrorMessage(error.message);
  } else {
    console.error(error);
  }

  if (errorMsg && setErrorMessage) {
    setErrorMessage(errorMsg);
  }

  return errorMsg;
};
