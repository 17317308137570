import React, { useState, useEffect, useRef } from "react";
import update from "immutability-helper";

import axios from "axios";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams,  } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import { validateAccountEmailId, validate1to20AlpheNumHyphen, validateDomainName,
    validateIPAddress, validatePasswordMax127, validate1to10AlphaNumericHyphen,} from "../../../../helpers/validator";
import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

// import prelaunchTest from "../../../Testing/test";

const PREFIX = "/aws/iam/sso";
const ACCOUNT_LIST_KEYS = [
  "/aws/accountframework/platformaccounts/sharedservicesaccountslist",
  "/aws/accountframework/platformaccounts/securityaccountslist",
];

const FILE_SIZE_LIMIT_IN_BYTE = 1000000;
const UPLOAD_FOLDER = "templates";

const SingleSignOn = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
   
    deploysinglesignon: {
      value: "yes",
    },
    
    singlesignonsolution: {
        value: ""
    },
    oktaportalaccount: {
      value: "",
    },
    adfsportalaccount: {
      value: "",
    },
    deploymentmodel : {
        value : "integratewithexistingad"
    },
    integratewithexistingad: {
        directorydnsdomainname: {
          value: "",
          isValid: false,
        },
        directorydnsserveripaddresses: [
          {
            value: "",
            isValid: false,
          },
          {
            value: "",
            isValid: false,
          },
        ],
        adconnectorserviceaccountusername: {
          value: "",
          isValid: false,
        },
        adconnectorserviceaccountpassword: {
          value: "",
          isValid: false,
        },
      },
    enablemfaforawsssousers: {
        value: "yes"
    }
    
  });

  const [empty, setEmpty] = useState(false);

  const [oktaXmlFile, setOktaXmlFile] = useState(null);
  const [existingOktaXmlFile, setExistingOktaXmlFile] = useState(null);

  const [adfsXmlFile, setAdfsXmlFile] = useState(null);
  const [existingAdfsXmlFile, setExistingAdfsXmlFile] = useState(null);

  const [accountList, setAccountList] = useState([]);

  const fileInputRef = useRef(null);

  const adfsFileInputRef = useRef(null);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        console.log(result);

        setIsLoading(false);

        setEmpty(result.data.empty);

        if (result.data) {
          setFormData(convertBEToFE(result.data));
        } else {
          setFormData({
            deploysinglesignon: {
                value: "yes",
              },
              
              singlesignonsolution: {
                  value: "okta"
              },
              oktaportalaccount: {
                value: "",
              },
              deploymentmodel : {
                  value : "integratewithexistingad"
              },
              integratewithexistingad: {
                  directorydnsdomainname: {
                    value: "",
                    isValid: false,
                  },
                  directorydnsserveripaddresses: [
                    {
                      value: "",
                      isValid: false,
                    },
                    {
                      value: "",
                      isValid: false,
                    },
                  ],
                  adconnectorserviceaccountusername: {
                    value: "",
                    isValid: false,
                  },
                  adconnectorserviceaccountpassword: {
                    value: "",
                    isValid: false,
                  },
                },
            enablemfaforawsssousers: {
                value: "yes"
            }
              
          });
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        if(err.response)
            setErrorMsg(err.response.data.message);
        else
            setErrorMsg(err);

        if(err.response.data.message === "You have no access"){
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);

      let oktaXmlFileName = null;
      let adfsXmlFileName = null;

      if (oktaXmlFile && oktaXmlFile.size < FILE_SIZE_LIMIT_IN_BYTE &&
        formData.deploysinglesignon.value === "yes" && formData.singlesignonsolution.value === "okta") {
        const initiateUploadResult = await awsAmplify.initiateUpload(
          projectId,
          UPLOAD_FOLDER,
          oktaXmlFile.name
        );

        const oktaUploadResult = await axios.put(
          initiateUploadResult.data.signedUrl,
          oktaXmlFile,
          {
            headers: {
              "Content-Type": oktaXmlFile.type,
            },
          });

        console.log(oktaUploadResult);

        if (oktaUploadResult.status === 200) {
          oktaXmlFileName = oktaXmlFile.name;
        }
      }

      if (adfsXmlFile && adfsXmlFile.size < FILE_SIZE_LIMIT_IN_BYTE &&
        formData.deploysinglesignon.value === "yes" && formData.singlesignonsolution.value === "adfs") {
        const initiateADFSUploadResult = await awsAmplify.initiateUpload(
          projectId,
          UPLOAD_FOLDER,
          adfsXmlFile.name
        );

        const adfsUploadResult = await axios.put(
            initiateADFSUploadResult.data.signedUrl,
            adfsXmlFile,
          {
            headers: {
              "Content-Type": adfsXmlFile.type,
            },
          });

        console.log(adfsUploadResult);

        if (adfsUploadResult.status === 200) {
          adfsXmlFileName = adfsXmlFile.name;
        }
      }

      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(oktaXmlFileName,adfsXmlFileName),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      if(err.response)
        setErrorMsg(err.response.data.message);
      else
        setErrorMsg("Error " + err);
    }
  };

  const convertFEToBE = (oktaFileName,adfsFileName) => {
    const beData = {
         [`${PREFIX}/deploysinglesignon`]: formData.deploysinglesignon.value,
    };

    if (formData.deploysinglesignon.value === "yes") {

        beData[`${PREFIX}/singlesignonsolution`] = formData.singlesignonsolution.value;
      if (formData.singlesignonsolution.value === "okta")
      {
          if(oktaFileName) {
             beData[`${PREFIX}/okta/oktametadataxmls3key`] = `${oktaFileName}`;
          }
        else {
            if (existingOktaXmlFile) {
            beData[
                `${PREFIX}/okta/oktametadataxmls3key`
            ] = `${existingOktaXmlFile.name}`;
            }
        }

        if(formData.oktaportalaccount.value){
            beData[`${PREFIX}/okta/oktaportalaccount`] = formData.oktaportalaccount.value;
        }
    }

      if (formData.singlesignonsolution.value === "adfs")
      {
        if(adfsFileName) {
            beData[`${PREFIX}/adfs/adfsmetadataxmls3key`] = `${adfsFileName}`;
        }
        else {
            if (existingAdfsXmlFile) {
            beData[
                `${PREFIX}/adfs/adfsmetadataxmls3key`
            ] = `${existingAdfsXmlFile.name}`;
            }
        }
        if(formData.adfsportalaccount.value){
          beData[`${PREFIX}/adfs/adfsportalaccount`] = formData.adfsportalaccount.value;
      }
      }

      if(formData.singlesignonsolution.value === "awssso"){
        beData[`${PREFIX}/awssso/deploymentmodel`] = formData.deploymentmodel.value;

        if(formData.deploymentmodel.value === "integratewithexistingad"){
        const additionalData = formData[formData.deploymentmodel.value];
        
        for (const prop in additionalData) {
          if (prop === "directorydnsserveripaddresses") {
            const ipaddresses = [];
  
            for (const data of additionalData[prop]) {
              if (data.isValid) {
                ipaddresses.push(data.value);
              }
            }
  
            if (ipaddresses.length > 0) {
              beData[`${PREFIX}/awssso/integratewithexistingad/directorydnsserveripaddresses`] = ipaddresses.join(
                ","
              );
            }
          } else {
            if (prop !== "repeatedpassword") {
              if (additionalData[prop].hasOwnProperty("isValid")) {
                if (additionalData[prop].isValid) {
                  beData[`${PREFIX}/awssso/integratewithexistingad/${prop}`] = additionalData[prop].value;
                }
              } else {
                beData[`${PREFIX}/awssso/integratewithexistingad/${prop}`] = additionalData[prop].value;
              }
            }
          }
        }
      }

      if(formData.enablemfaforawsssousers)
      beData[`${PREFIX}/awssso/enablemfaforawsssousers`] = formData.enablemfaforawsssousers.value;     
     }  
    }
    else
    {
      beData[`${PREFIX}/singlesignonsolution`] = "none";
    }
    return beData;
  };

  const convertBEToFE = (beData) => {
    const feData = {
        deploysinglesignon: {
        value: beData[`${PREFIX}/deploysinglesignon`] || "yes",
      },
      singlesignonsolution: {
          value: (beData[`${PREFIX}/singlesignonsolution`] &&  beData[`${PREFIX}/singlesignonsolution`] != "none" ) ?
                beData[`${PREFIX}/singlesignonsolution`] : "okta"
      },
      oktaportalaccount: {
        value: "",
      },
      deploymentmodel: {
        value: beData[`${PREFIX}/awssso/deploymentmodel`] || "integratewithexistingad",
      },
      integratewithexistingad: {
        directorydnsdomainname: {
          value: beData[`${PREFIX}/awssso/integratewithexistingad/directorydnsdomainname`] || "",
          isValid: beData.hasOwnProperty(`${PREFIX}/awssso/integratewithexistingad/directorydnsdomainname`),
        },
        directorydnsserveripaddresses: [
          {
            value: "",
            isValid: false,
          },
          {
            value: "",
            isValid: false,
          },
        ],
        adconnectorserviceaccountusername: {
          value: beData[`${PREFIX}/awssso/integratewithexistingad/adconnectorserviceaccountusername`] || "",
          isValid: beData.hasOwnProperty(
            `${PREFIX}/awssso/integratewithexistingad/adconnectorserviceaccountusername`
          ),
        },
        adconnectorserviceaccountpassword: {
          value: beData[`${PREFIX}/awssso/integratewithexistingad/adconnectorserviceaccountpassword`] || "",
          isValid: beData.hasOwnProperty(
            `${PREFIX}/awssso/integratewithexistingad/adconnectorserviceaccountpassword`
          ),
        },
      },
     
      enablemfaforawsssousers: {
        value: beData[`${PREFIX}/awssso/enablemfaforawsssousers`]  || "yes" ,
     },
   
    };

    if (feData.deploysinglesignon.value === "yes") {
        if (beData[`${PREFIX}/okta/oktametadataxmls3key`]) {
            setOktaXmlFile({
            name: beData[`${PREFIX}/okta/oktametadataxmls3key`],
            });
            setExistingOktaXmlFile({
            name: beData[`${PREFIX}/okta/oktametadataxmls3key`],
            });
        }
        

        feData.oktaportalaccount = {
        value: beData[`${PREFIX}/okta/oktaportalaccount`] || "",
        };

      if (beData[`${PREFIX}/adfs/adfsmetadataxmls3key`]) {
        setAdfsXmlFile({
          name: beData[`${PREFIX}/adfs/adfsmetadataxmls3key`],
        });
        setExistingAdfsXmlFile({
          name: beData[`${PREFIX}/adfs/adfsmetadataxmls3key`],
        });
      }

      feData.adfsportalaccount = {
        value: beData[`${PREFIX}/adfs/adfsportalaccount`] || "",
        };
    }

    if (beData[`${PREFIX}/awssso/integratewithexistingad/directorydnsserveripaddresses`]) {
        feData.integratewithexistingad.directorydnsserveripaddresses = beData[
          `${PREFIX}/awssso/integratewithexistingad/directorydnsserveripaddresses`
        ]
          .split(",")
          .map((ip) => {
            return { value: ip, isValid: true };
          });
  
        for (
          let loop =
            feData.integratewithexistingad.directorydnsserveripaddresses.length;
          loop < 2;
          loop++
        ) {
          feData.integratewithexistingad.directorydnsserveripaddresses.push({
            value: "",
            isValid: false,
          });
        }
      }
  
    let accounts = [];
    for (const accountKey of ACCOUNT_LIST_KEYS) {
      if (beData[accountKey]) {
        accounts = accounts.concat(beData[accountKey].split(","));
      }
    }
    console.log(accounts);
    setAccountList(accounts);

    return feData;
  };

  const exists = (value) => {
    return formData.array.indexOf(value) !== -1;
  };

  const modifyArray = (value) => {
    setIsDirty(true);

    if (exists(value)) {
      setFormData((state) => {
        return {
          ...state,
          array: update(state.array, {
            $splice: [[state.array.indexOf(value), 1]],
          }),
        };
      });
    } else {
      setFormData((state) => {
        return {
          ...state,
          array: update(state.array, { $push: [value] }),
        };
      });
    }
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const addFieldArrayItem = (key1, key2) => {
    setIsDirty(true);

    if (key2) {
      setFormData((state) =>
        update(state, {
          [key1]: { [key2]: { $push: [{ value: "", isValid: false }] } },
        })
      );
    } else {
      setFormData((state) =>
        update(state, { [key1]: { $push: [{ value: "", isValid: false }] } })
      );
    }
  };

  const removeFieldArrayItem = (idx, key1, key2) => {
    let canRemove = false;

    if (key2) {
      canRemove = formData[key1][key2].length > 2;
    } else {
      canRemove = formData[key1].length > 2;
    }

    if (!canRemove) {
      setErrorMsg(`Cannot less than 2 items`);
      return;
    }

    setIsDirty(true);

    if (key2) {
      setFormData((state) =>
        update(state, {
          [key1]: { [key2]: { $splice: [[idx, 1]] } },
        })
      );
    } else {
      setFormData((state) =>
        update(state, { [key1]: { $splice: [[idx, 1]] } })
      );
    }
  };

  const getFieldValidationClass = (key1, key2) => {
    if (key2) {
      return !formData[key1][key2].isValid && formData[key1][key2].value !== ""
        ? "invalid"
        : "";
    } else {
      return !formData[key1].isValid && formData[key1].value !== ""
        ? "invalid"
        : "";
    }
  };

  const getFieldArrayValidationClass = (idx, key1, key2) => {
    if (key2) {
      return !formData[key1][key2][idx].isValid &&
        formData[key1][key2][idx].value !== ""
        ? "invalid"
        : "";
    } else {
      return !formData[key1][idx].isValid && formData[key1][idx].value !== ""
        ? "invalid"
        : "";
    }
  };

  const onFieldChange = (value, key1, key2) => {
    setErrorMsg(null);
    setIsDirty(true);

    if (key2) {
      setFormData((state) =>
        update(state, { [key1]: { [key2]: { value: { $set: value } } } })
      );
    } else {
      setFormData((state) =>
        update(state, { [key1]: { value: { $set: value } } })
      );
    }
  };

  const onFieldBlur = (validateFunc, value, key1, key2) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      if (key2) {
        setFormData((state) =>
          update(state, {
            [key1]: { [key2]: { isValid: { $set: errorMsg === undefined } } },
          })
        );
      } else {
        setFormData((state) =>
          update(state, {
            [key1]: { isValid: { $set: errorMsg === undefined } },
          })
        );
      }

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      if (key2) {
        setFormData((state) =>
          update(state, {
            [key1]: { [key2]: { isValid: { $set: false } } },
          })
        );
      } else {
        setFormData((state) =>
          update(state, {
            [key1]: { isValid: { $set: false } },
          })
        );
      }
    }
  };

  const onFieldArrayChange = (idx, value, key1, key2) => {
    setErrorMsg(null);
    setIsDirty(true);

    if (key2) {
      setFormData((state) =>
        update(state, {
          [key1]: { [key2]: { [idx]: { value: { $set: value } } } },
        })
      );
    } else {
      setFormData((state) =>
        update(state, { [key1]: { [idx]: { value: { $set: value } } } })
      );
    }
  };

  const onFieldArrayBlur = (validateFunc, idx, value, key1, key2) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      if (key2) {
        setFormData((state) =>
          update(state, {
            [key1]: {
              [key2]: { [idx]: { isValid: { $set: errorMsg === undefined } } },
            },
          })
        );
      } else {
        setFormData((state) =>
          update(state, {
            [key1]: { [idx]: { isValid: { $set: errorMsg === undefined } } },
          })
        );
      }

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      if (key2) {
        setFormData((state) =>
          update(state, {
            [key1]: {
              [key2]: { [idx]: { isValid: { $set: false } } },
            },
          })
        );
      } else {
        setFormData((state) =>
          update(state, {
            [key1]: { [idx]: { isValid: { $set: false } } },
          })
        );
      }
    }
  };

  useHideLeftMenu();
  useUnload();

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Identity&Access</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
          <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Identity & Access"
                isDirty={isDirty}
                dashboardRoute={ROUTES.IDENTITY_PROVIDERS}
                designRoute={ROUTES.DESIGN_IAM}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 ">
                  <div className="row mb-2 d-flex align-items-center">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3>Single Sign-On</h3>
                  </div>
                  <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                        Deploy Single Sign-on
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                        <label
                          className={`createElementBtn btn btn-link btn-linkYes ${
                            formData.deploysinglesignon.value === "yes"
                              ? "active"
                              : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={
                              formData.deploysinglesignon.value === "yes"
                            }
                            value="yes"
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) => {
                                return {
                                  ...state,
                                  deploysinglesignon: {
                                    value: "yes",
                                  },
                                };
                              });
                            }}
                          />{" "}
                          YES
                        </label>
                        <label
                          className={`dontCreateElementBtn btn btn-link btn-linkNo ${
                            formData.deploysinglesignon.value === "no"
                              ? "active"
                              : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={
                              formData.deploysinglesignon.value === "no"
                            }
                            value="no"
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) => {
                                return {
                                  ...state,
                                  deploysinglesignon: {
                                    value: "no",
                                  },
                                };
                              });
                            }}
                          />{" "}
                          NO
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {empty && (
                    <NoInitialData />
                )}
                {formData.deploysinglesignon.value === "yes" && (
                  <div className="show-hide-content">
                    <div className=" col-12 d-inline-flex selectionArea my-1 px-3 justify-content-between flex-wrap py-1">
                        <label className="font-weight-bold my-2 font-12">
                          Single Sign-On Solution
                        </label>
                        <div className="my-2">
                          <label className="container">
                            Okta
                            <input
                              type="radio"
                              name="single"
                              value="okta"
                              checked={
                                formData.singlesignonsolution.value ===
                                "okta"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) => {
                                  return {
                                    ...state,
                                    singlesignonsolution: {
                                      value: "okta",
                                    },
                                  };
                                });
                                setExistingAdfsXmlFile(null);
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="my-2">
                          <label className="container">
                            ADFS
                            <input
                              type="radio"
                              name="single"
                              value="adfs"
                              checked={
                                formData.singlesignonsolution.value ===
                                "adfs"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) => {
                                  return {
                                    ...state,
                                    singlesignonsolution: {
                                      value: "adfs",
                                    },
                                  };
                                });
                                setExistingOktaXmlFile(null);
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="my-2">
                          <label className="container">
                            AWS SSO
                            <input
                              type="radio"
                              name="single"
                              value="awssso"
                              checked={
                                formData.singlesignonsolution.value ===
                                "awssso"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) => {
                                  return {
                                    ...state,
                                    singlesignonsolution: {
                                      value: "awssso",
                                    },
                                  };
                                });
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>

                     {formData.singlesignonsolution.value === "okta" && (
                     <div className="show-hide-content">
                          <div className="d-flex flex-column mainContentArea  pl-3 pr-3 py-3 my-5 ">
                      <div className=" d-inline-flex row formRow w-100 topOfFormRow  px-md-2 my-1 align-items-center justify-content-between">
                      <div className="uploadImage row d-inline-flex align-items-center justify-content-between">
                        <div className="col-lg-6 col-12 pl-lg-3 pl-0 ">
                          <h2 className="mb-0 font-weight-normal font-12">
                            Upload Okta Metadata XML
                          </h2>
                        </div>

                        <div className="col-lg-6 col-12 one form-line d-inline-flex align-items-center pr-0">
                          <div
                            className="d-flex align-items-center uploadIcon mr-3"
                            onClick={() => fileInputRef.current.click()}
                          >
                            <img src="../images/upload.svg" />
                          </div>
                          <button
                            disabled={disabled || !oktaXmlFile}
                            className="closableInput"
                            onClick={() => {
                              setIsDirty(true);
                              setOktaXmlFile(null);
                              setExistingOktaXmlFile(null);
                            }}
                          ></button>
                          <input
                            type="file"
                            id="upload-photo"
                            name="photo"
                            className="form-control"
                            ref={fileInputRef}
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if(file)
                              {
                                if (file && file.size > FILE_SIZE_LIMIT_IN_BYTE) {
                                  setErrorMsg(
                                    `${file.name} has exceeded ${FILE_SIZE_LIMIT_IN_BYTE} Bytes`
                                  );
                                  return;
                                }

                                if (file && file.type !== "text/xml") {
                                  setErrorMsg("Only XML file is allowed");
                                  return;
                                }
                                
                                  const oktaFileName = "iam-sso-okta-create-oktametadata.xml";
                                  var okta_blob = file.slice(0, file.size, file.type); 
                                  var newoktaFile = new File([okta_blob], oktaFileName, {type: ""});
                                  setOktaXmlFile(newoktaFile);
                                  setExistingAdfsXmlFile(null);
                                  setAdfsXmlFile(null);

                                  setIsDirty(true);
                              }
                              else
                              {
                                setErrorMsg("Invalid file.  Please upload xml file");
                                return;
                              }
                            }}
                          />
                          <label
                            className="flex-1 py-2 pl-3 mw-200 bg-white color-spc border-10 fileInpt d-flex align-items-center m-0"
                            htmlFor="upload-photo"
                          >
                            {oktaXmlFile
                              ? oktaXmlFile.name
                              : "Upload Okta Metadata XML"}
                          </label>
                        </div>
                      </div>
                    </div>
                       <div className="d-inline-flex flex-wrap w-100 px-3 row">
                      <div className="d-inline-flex col-md-2 pl-lg-0 px-1 my-1">
                        <label className="font-12 d-flex align-items-center mb-0 ">
                          AWS Portal Account for Okta
                        </label>
                      </div>
                      <div className="col-md-10">
                      {(accountList && accountList.length === 0)? (
                         <div className = "my-3">
                        <span className="warning">
                          This value depends on Platform Accounts section which is yet to be provided.
                        </span> </div>
                          ) : (
                        <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                          <select
                            className="form-control gray"
                            value={formData.oktaportalaccount.value}
                            onChange={(e) => {
                              setErrorMsg(null);
                              setIsDirty(true);
                              const value = e.target.value;
                              setFormData((state) => {
                                return {
                                  ...state,
                                  oktaportalaccount: {
                                    value: value,
                                  },
                                };
                              });
                            }}
                          >
                            <option value="">Select</option>
                            {accountList.map((account, idx) => (
                              <option key={idx} value={account}>
                                {account}
                              </option>
                            ))}
                          </select>
                        </div>
                          )}
                     </div>
                        </div>
                      </div>
                    </div>
                    )}

                    {formData.singlesignonsolution.value === "adfs" && (
                         <div className="show-hide-content">
                            <div className="d-flex flex-column mainContentArea  pl-3 pr-3 py-3 my-5 ">
                                <div className=" d-inline-flex row formRow w-100 topOfFormRow  px-md-2 my-1 align-items-center justify-content-between">
                                    <div className="uploadImage row d-inline-flex align-items-center justify-content-between">
                                        <div className="col-lg-6 col-12 pl-lg-3 pl-0 ">
                                            <h2 className="mb-0 font-weight-normal font-12">
                                                Upload Azure ADFS Metadata XML
                                             </h2>
                                        </div>

                                        <div className="col-lg-6 col-12 one form-line d-inline-flex align-items-center pr-0">
                                            <div
                                                className="d-flex align-items-center uploadIcon mr-3"
                                                onClick={() => adfsFileInputRef.current.click()}
                                            >
                                                <img src="../images/upload.svg" />
                                            </div>
                                            <button
                                                disabled={disabled || !adfsXmlFile}
                                                className="closableInput"
                                                onClick={() => {
                                                    setIsDirty(true);
                                                    setAdfsXmlFile(null);
                                                    setExistingAdfsXmlFile(null);
                                                }}
                                            ></button>
                                            <input
                                                type="file"
                                                id="upload-photo"
                                                name="photo"
                                                className="form-control"
                                                ref={adfsFileInputRef}
                                                onChange={(e) => {
                                                    const adfs_file = e.target.files[0];
                                                    if(adfs_file)
                                                    {
                                                    if (adfs_file.size > FILE_SIZE_LIMIT_IN_BYTE) {
                                                        setErrorMsg(
                                                            `${adfs_file.name} has exceeded ${FILE_SIZE_LIMIT_IN_BYTE} Bytes`
                                                        );
                                                        return;
                                                    }

                                                    if (adfs_file.type !== "text/xml") {
                                                        setErrorMsg("Only XML file is allowed");
                                                        return;
                                                    }
                                                    const newADFSFileName = "iam-sso-adfs-create-adfsmetadata.xml";
                                                    var adsf_blob = adfs_file.slice(0, adfs_file.size, adfs_file.type);
                                                    var newADSFFile = new File([adsf_blob], newADFSFileName, { type: '' });
                                                    setAdfsXmlFile(newADSFFile);
                                                    setExistingOktaXmlFile(null);
                                                    setOktaXmlFile(null);

                                                    setIsDirty(true);
                                                  }
                                                  else
                                                  {
                                                    setErrorMsg("Invalid File.  Please upload xml file");
                                                        return;
                                                  }
                                                }}
                                            />
                                            <label
                                                className="flex-1 py-2 pl-3 mw-200 bg-white color-spc border-10 fileInpt d-flex align-items-center m-0"
                                                htmlFor="upload-photo"
                                            >
                                                {adfsXmlFile
                                                    ? adfsXmlFile.name
                                                    : "Upload Azure ADFS Metadata XML"}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-inline-flex flex-wrap w-100 px-3 row">
                              <div className="d-inline-flex col-md-2 pl-lg-0 px-1 my-1">
                                <label className="font-12 d-flex align-items-center mb-0 ">
                                  AWS Portal Account for ADFS
                                </label>
                              </div>
                              <div className="col-md-10">
                              {(accountList && accountList.length === 0)? (
                                <div className = "my-3">
                                          <span className="warning">
                                            This value depends on Platform Accounts section which is yet to be provided.
                                          </span> </div>
                                        ) : (
                                <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                                  <select
                                    className="form-control gray"
                                    value={formData.adfsportalaccount.value}
                                    onChange={(e) => {
                                      setErrorMsg(null);
                                      setIsDirty(true);
                                      const value = e.target.value;
                                      setFormData((state) => {
                                        return {
                                          ...state,
                                          adfsportalaccount: {
                                            value: value,
                                          },
                                        };
                                      });
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {accountList.map((account, idx) => (
                                      <option key={idx} value={account}>
                                        {account}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                        )}
                              </div>
                    </div>
                        
                            </div>
                         </div>
                    )}

                    {formData.singlesignonsolution.value === "awssso" && (
                        <div className="show-hide-content">

                        <div className=" col-12 d-inline-flex selectionArea my-3 px-3 justify-content-between flex-wrap py-1">
                            <label className="font-weight-bold my-2 font-12">
                            Directory for SSO Users
                            </label>
                            <div className="my-2">
                            <label className="container">
                                Integrate with existing Microsoft AD
                                <input
                                type="radio"
                                name="single2"
                                value="integratewithexistingad"
                                checked={
                                    formData.deploymentmodel.value ===
                                    "integratewithexistingad"
                                }
                                onChange={() => {
                                    setIsDirty(true);
                                    setFormData((state) => {
                                    return {
                                        ...state,
                                        deploymentmodel: {
                                        value: "integratewithexistingad",
                                        },
                                    };
                                    });
                                    setExistingOktaXmlFile(null);
                                    setExistingAdfsXmlFile(null);
                                    setOktaXmlFile(null);
                                    setAdfsXmlFile(null);
                                }}
                                />
                                <span className="checkmark"></span>
                            </label>
                            </div>
                            <div className="my-2">
                            <label className="container">
                                Deploy AWS Managed Microsoft AD
                                <input
                                type="radio"
                                name="single2"
                                value="deployawsmanagedmsad"
                                checked={
                                    formData.deploymentmodel.value ===
                                    "deployawsmanagedmsad"
                                }
                                onChange={() => {
                                    setIsDirty(true);
                                    setFormData((state) => {
                                    return {
                                        ...state,
                                        deploymentmodel: {
                                        value: "deployawsmanagedmsad",
                                        },
                                    };
                                    });
                                    setExistingOktaXmlFile(null);
                                    setExistingAdfsXmlFile(null);
                                    setOktaXmlFile(null);
                                    setAdfsXmlFile(null);
                                }}
                                />
                                <span className="checkmark"></span>
                            </label>
                            </div>
                            <div className="my-2">
                            <label className="container">
                               AWS SSO Directory
                                <input
                                type="radio"
                                name="single2"
                                value="deploysimplead"
                                checked={
                                    formData.deploymentmodel.value ===
                                    "deploysimplead"
                                }
                                onChange={() => {
                                    setIsDirty(true);
                                    setFormData((state) => {
                                    return {
                                        ...state,
                                        deploymentmodel: {
                                        value: "deploysimplead",
                                        },
                                    };
                                    });
                                    setExistingOktaXmlFile(null);
                                    setExistingAdfsXmlFile(null);
                                    setOktaXmlFile(null);
                                    setAdfsXmlFile(null);
                                }}
                                />
                                <span className="checkmark"></span>
                            </label>
                            </div>
                        </div>
                     
                        <div className="d-inline-flex w-100 my-3">
                          <div className="pl-lg-0 px-3 d-flex align-items-center mr-xl-5 mr-1 font-12">
                            <div>Enable MFA for AWS SSO Users</div>
                          </div>
                          <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                            <label
                              className={`createSubElementBtn btn btn-link btn-linkYes ${
                               formData.enablemfaforawsssousers && formData.enablemfaforawsssousers.value ===
                                "yes"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <input
                                type="radio"
                                name="options"
                                value="yes"
                                checked={
                                  formData.enablemfaforawsssousers && formData.enablemfaforawsssousers
                                    .value === "yes" 
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) => {
                                    return {
                                      ...state,
                                      enablemfaforawsssousers: {
                                        value: "yes",
                                      },
                                    };
                                  });
                                }}
                              />{" "}
                              YES
                            </label>
                            <label
                              className={`dontCreateSubElementBtn btn btn-link btn-linkNo ${
                                formData.enablemfaforawsssousers && formData.enablemfaforawsssousers.value ===
                                "no"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <input
                                type="radio"
                                name="options"
                                checked={
                                    formData.enablemfaforawsssousers && formData.enablemfaforawsssousers
                                    .value === "no"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) => {
                                    return { 
                                      ...state,
                                      enablemfaforawsssousers: {
                                        value: "no",
                                      },
                                    };
                                  });
                                }}
                              />{" "}
                              NO
                            </label>
                          </div>
                        </div>    
                    
                        
                        {formData.deploymentmodel.value ===
                        "integratewithexistingad" && (
                        <div className="d-flex flex-column mainContentArea  pl-3 pr-3 py-1 my-1 ">
                            <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                                <span className="mb-0 font-12">
                                Directory DNS Domain Name
                                </span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                                <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                    <input
                                    type="text"
                                    className={`form-control bg-transparent ${getFieldValidationClass(
                                        "integratewithexistingad",
                                        "directorydnsdomainname"
                                    )}`}
                                    placeholder="client.com"
                                    value={
                                        formData.integratewithexistingad
                                        .directorydnsdomainname.value
                                    }
                                    onChange={(e) => {
                                        onFieldChange(
                                        e.target.value,
                                        "integratewithexistingad",
                                        "directorydnsdomainname"
                                        );                                       
                                    }}
                                    onBlur={(e) => {
                                        onFieldBlur(
                                        validateDomainName,
                                        e.target.value,
                                        "integratewithexistingad",
                                        "directorydnsdomainname"
                                        );
                                    }}
                                    />
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                                <span className="mb-0 font-12">
                                Directory DNS Server IP Addresses
                                </span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                                {formData.integratewithexistingad.directorydnsserveripaddresses.map(
                                (item, idx) => (
                                    <div className="mr-3 my-1" key={idx}>
                                    <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                        <button disabled={disabled}
                                        type="button"
                                        className="closableInput"
                                        onClick={() =>
                                            removeFieldArrayItem(
                                            idx,
                                            "integratewithexistingad",
                                            "directorydnsserveripaddresses"
                                            )
                                        }
                                        ></button>
                                        <input
                                        type="text"
                                        className={`form-control bg-transparent ${getFieldArrayValidationClass(
                                            idx,
                                            "integratewithexistingad",
                                            "directorydnsserveripaddresses"
                                        )}`}
                                        placeholder="192.168.1.56"
                                        value={item.value}
                                        onChange={(e) => {
                                            const value = e.target.value;

                                    for (const [
                                    idxOtherItem,
                                    otherItem,
                                    ] of formData.integratewithexistingad.directorydnsserveripaddresses.entries()) {
                                    if (idx !== idxOtherItem) {
                                        if (
                                        otherItem.isValid &&
                                        otherItem.value !== ""
                                        ) {
                                        if (
                                            otherItem.value.toLowerCase() ===
                                            value.toLowerCase()
                                        ) {
                                            setErrorMsg(
                                            "Directory DNS Server IP Addresses must be unique"
                                            );
                                            return false;
                                        }
                                        }
                                    }
                                    }

                                            onFieldArrayChange(
                                            idx,
                                            e.target.value,
                                            "integratewithexistingad",
                                            "directorydnsserveripaddresses"
                                            );
                                        }}
                                        onBlur={(e) => {
                                            onFieldArrayBlur(
                                            validateIPAddress,
                                            idx,
                                            e.target.value,
                                            "integratewithexistingad",
                                            "directorydnsserveripaddresses"
                                            );
                                        }}
                                        />
                                    </div>
                                    </div>
                                )
                                )}

                                <button disabled={disabled}
                                type="button"
                                onClick={() =>
                                    addFieldArrayItem(
                                    "integratewithexistingad",
                                    "directorydnsserveripaddresses"
                                    )
                                }
                                >
                                <img src="../images/coloredPlus.svg" />
                                </button>
                            </div>
                            </div>
                            <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                                <span className="mb-0 font-12">
                                AD Connector Service Account Username
                                </span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                                <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                    <input
                                    type="text"
                                    className={`form-control bg-transparent ${getFieldValidationClass(
                                        "integratewithexistingad",
                                        "adconnectorserviceaccountusername"
                                    )}`}
                                    placeholder=""
                                    value={
                                        formData.integratewithexistingad
                                        .adconnectorserviceaccountusername.value
                                    }
                                    onChange={(e) => {
                                        onFieldChange(
                                        e.target.value,
                                        "integratewithexistingad",
                                        "adconnectorserviceaccountusername"
                                        );
                                    }}
                                    onBlur={(e) => {
                                        onFieldBlur(
                                        validate1to20AlpheNumHyphen,
                                        e.target.value,
                                        "integratewithexistingad",
                                        "adconnectorserviceaccountusername"
                                        );
                                    }}
                                    />
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                                <span className="mb-0 font-12">Password</span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                                <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                    <input
                                    type="password"
                                    className={`form-control show-psw bg-transparent ${getFieldValidationClass(
                                        "integratewithexistingad",
                                        "adconnectorserviceaccountpassword"
                                    )}`}
                                    placeholder="*******"
                                    value={
                                        formData.integratewithexistingad
                                        .adconnectorserviceaccountpassword.value
                                    }
                                    onChange={(e) => {
                                        onFieldChange(
                                        e.target.value,
                                        "integratewithexistingad",
                                        "adconnectorserviceaccountpassword"
                                        );
                                    }}
                                    onBlur={(e) => {
                                        onFieldBlur(
                                        validatePasswordMax127,
                                        e.target.value,
                                        "integratewithexistingad",
                                        "adconnectorserviceaccountpassword"
                                        );
                                    }}
                                    />
                                </div>
                                </div>
                            </div>
                            </div>
                          
                        </div>
                        )}  
                    
                    </div>       
              
             )} 
             
              {/* <div> <button type="button" name="testButton" onClick = {() => prelaunchTest()}> Test Data </button> </div>
              */}
             </div>
           
                
                )}</div>
            
    
             </fieldset>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() => saveDataIfDirty(ROUTES.BASELINE_ROLES+"/"+projectId)}
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Baseline Roles 
                    </p>
                  </button>
                  
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() => saveDataIfDirty(ROUTES.PRIVILEGED_ACCESS_MANAGEMENT+"/"+projectId)}
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                     Privileged Access
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>

             
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleSignOn;
