import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "./DetailsTags";

//const projectId = "google_1";
const dashboardName = "TGWs";

const SubMenu = () => {
  const { projectId } = useParams();

  return (
    <>
      <h3>Transit Gateway</h3>
      <div className="otherTableTitles ">
        <h3 className="font-15 activeTable">TGWs</h3>
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.TGW_ROUTING_DOMAINS + "/" + projectId}>
            Routing Domains
          </Link>
        </h3>
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.TGW_ATTACHMENT + "/" + projectId}>
            Attachments
          </Link>
        </h3>
      </div>
    </>
  );
};

const Details = ({ close, selectedRow }) => {
  const [type, setType] = useState("Details");

  return (
    <div className="justify-content-center align-items-end account-detail">
      <div className="w-100 s-flex flex-column account-detail-area">
        <button className="exitAccount" onClick={() => close()}>
          <img src="../images/close.svg" />
        </button>
        <div className="">
          <ul className="account-detail-link m-0 p-0 d-inline-flex">
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Details" && "my_active"
              }`}
              onClick={() => setType("Details")}
            >
              Details
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Tags" && "my_active"
              }`}
              onClick={() => setType("Tags")}
            >
              Tags
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 account-detail-container-area">
          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Details" && "inaktif"
            }`}
          >
            <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.AccountId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Region</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Region}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">TGW ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.TransitGatewayId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Description</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Description}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">TGW State</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.State}
                </p>
              </div>

              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">TGW Owner ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.OwnerId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Amazon Side ASN
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Options.AmazonSideAsn}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Creation Time</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.CreationTime}
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Auto Accept Shared Attachments
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Options.AutoAcceptSharedAttachments}
                </p>
              </div>

              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Default Route Table Association
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Options.DefaultRouteTableAssociation}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Default Route Table Propagation
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Options.DefaultRouteTablePropagation}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Propagation Default Route Table Id
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Options.PropagationDefaultRouteTableId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  VPN ECMP Support
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Options.VpnEcmpSupport}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">DNS Support</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Options.DnsSupport}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Multicast Support
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Options.MulticastSupport}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">TGW ARN</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.TransitGatewayArn}
                </p>
              </div>
            </div>
          </div>

          <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>
        </div>
      </div>
    </div>
  );
};

const Navigations = () => {
  const { projectId } = useParams();
  return (
    <>
    <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.VPC + "/" + projectId}
    >
      <img src="../images/ribbon-design.svg" />
      <p className="m-0 p-0 ml-2 font-weight-bold">VPCs</p>
    </Link>
    <Link className="btn-post btn-animation d-inline-flex" to={ROUTES.VPN + "/" + projectId}>
      <p className="m-0 p-0 mr-2 font-weight-bold">Hybrid Network</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
  </>
  );
};

const TGW = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "TGW Id",
        accessor: "TransitGatewayId",
      },
      {
        Header: "Description",
        accessor: "Description",
      },
      {
        Header: "TGW State",
        accessor: "State",
      },
      {
        Header: "TGW Owner ID",
        accessor: "OwnerId",
      },
      {
        Header: "Amazon Side ASN",
        accessor: "Options.AmazonSideAsn",
      },
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "network",
    title: "Network",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_CENTERS,
    designRoute: ROUTES.DESIGN_NETWORK,
    SubMenu: SubMenu,
  });
};

export default TGW;
