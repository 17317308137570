import React, { useEffect, useState } from "react";
import update from "immutability-helper";

import useAwsAmplify from "../../hooks/useAwsAmplify";
import useSession from "../../hooks/useSession";
import { getError } from "../../helpers/errorHelper";

const BackupForm = ({ close, projects }) => {
  const { group, authUser, isSuperAdmin } = useSession();
  const awsAmplify = useAwsAmplify();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    targetRegion: {
      value: "",
      isValid: false,
    },
    targetStage: {
      value: "",
      isValid: false,
    },
    copyCode: true,
    regions: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const allRegions = await awsAmplify.getAllRegions();

        setIsLoading(false);

        setFormData((state) =>
          update(state, {
            regions: {
              $set: allRegions.data,
            },
          })
        );
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);
      }
    };

    fetchData();
  }, []);

  const [isDirty, setIsDirty] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    console.log(formData);

    const errorMsgs = [];

    for (const prop in formData) {
      if (formData[prop].hasOwnProperty("isValid") && !formData[prop].isValid) {
        if (formData[prop].errorMsg) {
          errorMsgs.push(formData[prop].errorMsg);
        } else {
          errorMsgs.push(`${prop} must be provided`);

          setFormData((state) =>
            update(state, {
              [prop]: {
                errorMsg: {
                  $set: `${prop} must be provided`,
                },
              },
            })
          );
        }
      }
    }

    if (errorMsgs.length > 0) {
      setErrorMsg(errorMsgs);
      return;
    }

    try {
      setIsLoading(true);

      console.log(formData);

      const result = await awsAmplify.backup(
        formData.targetRegion.value,
        formData.targetStage.value,
        formData.copyCode
      );
      console.log(result);

      setIsLoading(false);
      setIsDirty(false);

      close(
        false,
        "backup",
        "System backup has started. Please check Log for status."
        /* result.data.copyProject > 0
          ? `Successfully cleared the Target Project data and copied Source Project data to the Target Project`
          : `Successfuly copied Source Project data to the Target Project` */
      );
    } catch (err) {
      setIsLoading(false);

      const errorMsg = getError(err);

      setErrorMsg(errorMsg);
    }
  };

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong>
              {Array.isArray(errorMsg)
                ? errorMsg.map((em, idx) => (
                    <>
                      <span key={idx}>{em}</span>
                    </>
                  ))
                : errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="justify-content-center align-items-center popupArea copy-project">
        <div className="auth-main">
          <form className="auth-form" onSubmit={onSubmit}>
            <button
              type="button"
              className="exit btn-animation"
              onClick={() => close(false, "backup")}
            >
              Exit
            </button>
            <div className="title">System Backup</div>
            <div className="auth-form-content d-flex flex-column justify-content-center">
              <div className="halfBox d-flex flex-column mt-4">
                <div className="columnRow w-100 d-flex flex-column">
                  <div className="titleOfContent my-1">Target AWS Region</div>
                  <select
                    className="selectInput my-1"
                    value={formData.targetRegion.value}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (
                        value !== "" &&
                        value !== formData.targetRegion.value
                      ) {
                        setFormData((state) =>
                          update(state, {
                            targetRegion: { value: { $set: value } },
                          })
                        );
                      }
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;
                      if (value !== "") {
                        setFormData((state) =>
                          update(state, {
                            targetRegion: { isValid: { $set: true } },
                          })
                        );
                      }
                    }}
                  >
                    <option value="">Select</option>
                    {formData.regions.map((p, idx) => (
                      <option key={idx} value={p}>
                        {p}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="columnRow w-100 d-flex flex-column justify-content-center">
                  <div className="titleOfContent my-1">Target Stage</div>
                  <input
                    type="text"
                    className={
                      "form-control my-1 " +
                      (formData.targetStage.errorMsg && "invalid")
                    }
                    style={{width: "100%"}}
                    placeholder="Target Stage"
                    name="targetStage"
                    value={formData.targetStage.value}
                    onChange={(e) => {
                      const value = e.target.value;
                      setErrorMsg(null);
                      setIsDirty(true);

                      setFormData((state) =>
                        update(state, {
                          targetStage: { value: { $set: value } },
                        })
                      );
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;

                      let errorMsg;

                      if (value === "") {
                        errorMsg = "must be provided";
                      }

                      setFormData((state) =>
                        update(state, {
                          targetStage: {
                            isValid: { $set: errorMsg === undefined },
                            errorMsg: {
                              $set: errorMsg,
                            },
                          },
                        })
                      );

                      if (errorMsg) {
                        setErrorMsg(errorMsg);
                      }

                      return errorMsg === undefined;
                    }}
                    maxLength="15"
                  />
                </div>

                <div className="form-line my-lg-0 my-1 d-inline-flex align-items-center">
                  <label className="switch mb-0">
                    <input
                      type="checkbox"
                      checked={formData.copyCode}
                      onChange={(e) => {
                        const value = e.target.checked;
                        setFormData((state) =>
                          update(state, { copyCode: { $set: value } })
                        );
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span className="switchLabel ">Copy Code</span>
                </div>
              </div>
            </div>
            <div className="auth-form-footer">
              <div>
                <button className="confirmBtn btn btn-link btn-green btn-animation">
                  Backup
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {console.log(formData)}
    </>
  );
};

export default BackupForm;
