import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import useServiceAccounts from "../../../../hooks/useServiceAccounts";
import useRegions from "../../../../hooks/useRegions";
import { validateAccountName } from "../../../../helpers/validator";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/security/perimeterprotection";
const PRIMARYREGION_PREFIX = "/aws/network/aws/awsprimaryregion";

const Regions = ({ regions, section, exists, modifyArray }) => (
  <div className="align-items-center my-1 row">
    <div className="col-md-12 my-1  p-0">
      <span className="mb-0 font-12 font-weight-bold">Regions</span>
    </div>

    <div className=" d-inline-flex justify-content-start row formRow px-md-2 px-3 my-1 align-items-start flex-wrap">
      {regions.map((item, idxItem) => (
        <div
          key={idxItem}
          className="form-line col-md-3 col-12 my-1  d-inline-flex align-items-center"
        >
          <label className="switch mb-0">
            <input
              type="checkbox"
              checked={exists(`${section}_regions`, item)}
              onChange={() => modifyArray(`${section}_regions`, item)}
            />
            <span className="slider round"></span>
          </label>
          <span className="switchLabel ">{item}</span>
        </div>
      ))}
    </div>
  </div>
);

const PolicyAction = ({ formData, section, updateRadio }) => (
  <div className="d-inline-flex px-2 align-items-center selectionArea no-border my-1 row">
    <div className="col-md-12 my-1  p-0">
      <span className="mb-0 font-12 font-weight-bold">Policy Action</span>
    </div>
    <div className=" col-md-12 d-flex flex-column">
      <div>
        <div className="d-inline-flex mr-3 my-1 flex-wrap">
          <label className="container  font-12 font-weight-normal">
            Identify resources that don't comply with the policy rules, but
            don't auto remediate
            <input
              type="radio"
              name={`${section}_policyaction`}
              checked={formData[`${section}_policyaction`].value === "identity"}
              onChange={() =>
                updateRadio(`${section}_policyaction`, "identity")
              }
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>
      <div>
        <div className="d-inline-flex mr-3 my-1 flex-wrap">
          <label className="container font-12 font-weight-normal">
            Auto remediate any noncompliant resources
            <input
              type="radio"
              name={`${section}_policyaction`}
              checked={
                formData[`${section}_policyaction`].value === "autoremediate"
              }
              onChange={() =>
                updateRadio(`${section}_policyaction`, "autoremediate")
              }
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
);

const IncludeResources = ({
  formData,
  updateRadio,
  onFieldChange,
  onFieldBlur,
  section,
}) => (
  <div className="selectionArea no-border px-2 col-md-12 d-flex flex-column">
    <div>
      <div className="d-inline-flex mr-3 my-1 flex-wrap">
        <label className="container font-12 font-weight-normal mt-1">
          Include all resources in all member accounts that match the selected
          resource type
          <input
            type="radio"
            name={`${section}_policyscope_resources`}
            checked={
              formData[`${section}_policyscope_resources`].value ===
              "allresources"
            }
            onChange={() =>
              updateRadio(`${section}_policyscope_resources`, "allresources")
            }
          />
          <span className="checkmark"></span>
        </label>
      </div>
    </div>
    <div>
      <div className="d-inline-flex w-100 mr-3 my-1 flex-wrap">
        <label className="container font-12 font-weight-normal mt-1 mb-2">
          Include only resources in all member accounts that have specified
          resource tags
          <input
            type="radio"
            name={`${section}_policyscope_resources`}
            checked={
              formData[`${section}_policyscope_resources`].value ===
              "onlyresourceswithspecifiedtag"
            }
            onChange={() =>
              updateRadio(
                `${section}_policyscope_resources`,
                "onlyresourceswithspecifiedtag"
              )
            }
          />
          <span className="checkmark"></span>
        </label>

        {formData[`${section}_policyscope_resources`].value ===
          "onlyresourceswithspecifiedtag" && (
          <>
            <div className="ml-5 border-10  p-relative d-inline-flex align-items-center">
              <span className="font-12 d-flex align-items-center mr-2 no-wrap">
                Tag Key
              </span>
              <input
                placeholder="Environment"
                type="text"
                className="form-control bg-gray"
                value={
                  formData[`${section}_policyscope_resources_tagkey`].value
                }
                onChange={(e) =>
                  onFieldChange(
                    e.target.value,
                    `${section}_policyscope_resources_tagkey`
                  )
                }
                onBlur={(e) =>
                  onFieldBlur(
                    validateAccountName,
                    e.target.value,
                    `${section}_policyscope_resources_tagkey`
                  )
                }
              />
            </div>

            <div className="ml-5 border-10  p-relative d-inline-flex align-items-center ">
              <span className="font-12 d-flex align-items-center mr-2 no-wrap">
                Tag Value
              </span>
              <input
                placeholder="Prod"
                type="text"
                className="form-control bg-gray"
                value={
                  formData[`${section}_policyscope_resources_tagvalue`].value
                }
                onChange={(e) =>
                  onFieldChange(
                    e.target.value,
                    `${section}_policyscope_resources_tagvalue`
                  )
                }
                onBlur={(e) =>
                  onFieldBlur(
                    validateAccountName,
                    e.target.value,
                    `${section}_policyscope_resources_tagvalue`
                  )
                }
              />
            </div>
          </>
        )}
      </div>
    </div>
  </div>
);

const getInitialFormData = (data) => {
  const initialFormData = {
    deploycentralizedperimeterprotection: {
      value: data[`${PREFIX}/deploycentralizedperimeterprotection`] || "no",
    },
    firewallmanageradministratoraccount: {
      value: data[`${PREFIX}/firewallmanageradministratoraccount`] || "",
    },
    sendfindingstosecurityhub: {
      value: data[`${PREFIX}/sendfindingstosecurityhub`] || "yes",
    },
    sendnotificationstosecuritydomainsnstopic: {
      value:
        data[`${PREFIX}/sendnotificationstosecuritydomainsnstopic`] || "yes",
    },
    createsecuritygrouppolicies: {
      value: data[`${PREFIX}/createsecuritygrouppolicies`] || "no",
    },
    createbaselinesecuritygrouppolicy: {
      value: data[`${PREFIX}/createbaselinesecuritygrouppolicy`] || "yes",
    },
    baselinesecuritygrouppolicy_policyscope_includeresourcesfromsharedvpcs: {
      value:
        data[
          `${PREFIX}/baselinesecuritygrouppolicy/policyscope/includeresourcesfromsharedvpcs`
        ] || "yes",
    },

    createsecuritygroupauditingpolicy: {
      value: data[`${PREFIX}/createsecuritygroupauditingpolicy`] || "no",
    },

    createunusedsecuritygroupspolicy: {
      value: data[`${PREFIX}/createunusedsecuritygroupspolicy`] || "no",
    },

    createwafpolicies: {
      value: data[`${PREFIX}/createwafpolicies`] || "no",
    },

    createbaselinewebaclforcloudfrontpolicy: {
      value: data[`${PREFIX}/createbaselinewebaclforcloudfrontpolicy`] || "no",
    },

    createbaselinewebaclforalbapigatewaypolicy: {
      value:
        data[`${PREFIX}/createbaselinewebaclforalbapigatewaypolicy`] || "no",
    },

    createshieldpolicies: {
      value: data[`${PREFIX}/createshieldpolicies`] || "no",
    },

    createshieldforcloudfrontpolicy: {
      value: data[`${PREFIX}/createshieldforcloudfrontpolicy`] || "no",
    },

    createshieldforalboreippolicy: {
      value: data[`${PREFIX}/createshieldforalboreippolicy`] || "no",
    },

    monitorhighriskevents: {
      value: data[`${PREFIX}/monitorhighriskevents`] || "yes"
    }
  };

  [
    "baselinesecuritygrouppolicy",
    "securitygroupauditingpolicy",
    "unusedsecuritygroupspolicy",
    "baselinewebaclforcloudfrontpolicy",
    "baselinewebaclforalbapigatewaypolicy",
    "shieldforcloudfrontpolicy",
    "shieldforalboreippolicy",
  ].forEach((section) => {
    initialFormData[`${section}_regions`] =
      data[`${PREFIX}/${section}/regions`] &&
      data[`${PREFIX}/${section}/regions`].length > 0
        ? data[`${PREFIX}/${section}/regions`].split(",")
        : [];

    initialFormData[`${section}_policyrules`] =
      data[`${PREFIX}/${section}/policyrules`] &&
      data[`${PREFIX}/${section}/policyrules`].length > 0
        ? data[`${PREFIX}/${section}/policyrules`].split(",")
        : [];

    initialFormData[`${section}_policyaction`] = {
      value: data[`${PREFIX}/${section}/policyaction`] || "",
    };
    initialFormData[`${section}_policyscope_resourcetypes`] =
      data[`${PREFIX}/${section}/policyscope/resourcetypes`] &&
      data[`${PREFIX}/${section}/policyscope/resourcetypes`].length > 0
        ? data[`${PREFIX}/${section}/policyscope/resourcetypes`].split(",")
        : [];
    initialFormData[`${section}_policyscope_resources`] = {
      value: data[`${PREFIX}/${section}/policyscope/resources`] || "",
    };
    initialFormData[`${section}_policyscope_resources_tagkey`] = {
      value: data[`${PREFIX}/${section}/policyscope/resources/tagkey`] || "",
      isValid: data[`${PREFIX}/${section}/policyscope/resources/tagkey`]
        ? true
        : false,
    };
    initialFormData[`${section}_policyscope_resources_tagvalue`] = {
      value: data[`${PREFIX}/${section}/policyscope/resources/tagvalue`] || "",
      isValid: data[`${PREFIX}/${section}/policyscope/resources/tagvalue`]
        ? true
        : false,
    };
  });

  return initialFormData;
};

const PerimeterProtection = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  const serviceAccounts = useServiceAccounts(projectId);
  const regions = useRegions(projectId);

  const [primaryRegion, setPrimaryRegion] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);

        const primaryRegionData = await awsAmplify.loadProjectData(
          projectId,
          PRIMARYREGION_PREFIX
        );

        setPrimaryRegion(
          primaryRegionData.data && primaryRegionData.data[PRIMARYREGION_PREFIX]
        );

        setIsLoading(false);

        setEmpty(result.data.empty);

        setFormData(convertBEToFE(result.data));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploycentralizedperimeterprotection`]: formData
        .deploycentralizedperimeterprotection.value,
    };

    if (formData.deploycentralizedperimeterprotection.value === "yes") {
      const securitygrouppolicies = [],
        wafpolicies = [],
        shieldpolicies = [];

      if (formData.firewallmanageradministratoraccount.value !== "") {
        be[`${PREFIX}/firewallmanageradministratoraccount`] =
          formData.firewallmanageradministratoraccount.value;
      }

      be[`${PREFIX}/sendfindingstosecurityhub`] =
        formData.sendfindingstosecurityhub.value;
      be[`${PREFIX}/sendnotificationstosecuritydomainsnstopic`] =
        formData.sendnotificationstosecuritydomainsnstopic.value;

      be[`${PREFIX}/createsecuritygrouppolicies`] =
        formData.createsecuritygrouppolicies.value;

      be[`${PREFIX}/createbaselinesecuritygrouppolicy`] =
        formData.createbaselinesecuritygrouppolicy.value;

      be[`${PREFIX}/createsecuritygroupauditingpolicy`] =
        formData.createsecuritygroupauditingpolicy.value;

      be[`${PREFIX}/createunusedsecuritygroupspolicy`] =
        formData.createunusedsecuritygroupspolicy.value;

      if (formData.createsecuritygrouppolicies.value === "yes") {
        if (formData.createbaselinesecuritygrouppolicy.value === "yes") {
          securitygrouppolicies.push("baselinesecuritygrouppolicy");

          convertFEToBEPerSection("baselinesecuritygrouppolicy", be);
        }

        if (formData.createsecuritygroupauditingpolicy.value === "yes") {
          securitygrouppolicies.push("securitygroupauditingpolicy");

          convertFEToBEPerSection("securitygroupauditingpolicy", be);
        }

        if (formData.createunusedsecuritygroupspolicy.value === "yes") {
          securitygrouppolicies.push("unusedsecuritygroupspolicy");

          convertFEToBEPerSection("unusedsecuritygroupspolicy", be);
        }
      }

      be[`${PREFIX}/createwafpolicies`] = formData.createwafpolicies.value;

      be[`${PREFIX}/createbaselinewebaclforcloudfrontpolicy`] =
        formData.createbaselinewebaclforcloudfrontpolicy.value;

      be[`${PREFIX}/createbaselinewebaclforalbapigatewaypolicy`] =
        formData.createbaselinewebaclforalbapigatewaypolicy.value;

      if (formData.createwafpolicies.value === "yes") {
        if (formData.createbaselinewebaclforcloudfrontpolicy.value === "yes") {
          wafpolicies.push("baselinewebaclforcloudfrontpolicy");
          convertFEToBEPerSection("baselinewebaclforcloudfrontpolicy", be);

          if (primaryRegion) {
            be[
              `${PREFIX}/baselinewebaclforcloudfrontpolicy/regions`
            ] = primaryRegion;
          }
        }

        if (
          formData.createbaselinewebaclforalbapigatewaypolicy.value === "yes"
        ) {
          wafpolicies.push("baselinewebaclforalbapigatewaypolicy");

          convertFEToBEPerSection("baselinewebaclforalbapigatewaypolicy", be);
        }
      }

      be[`${PREFIX}/createshieldpolicies`] =
        formData.createshieldpolicies.value;

      be[`${PREFIX}/createshieldforcloudfrontpolicy`] =
        formData.createshieldforcloudfrontpolicy.value;

      be[`${PREFIX}/createshieldforalboreippolicy`] =
        formData.createshieldforalboreippolicy.value;

      if (formData.createshieldpolicies.value === "yes") {
        if (formData.createshieldforcloudfrontpolicy.value === "yes") {
          shieldpolicies.push("shieldforcloudfrontpolicy");
          convertFEToBEPerSection("shieldforcloudfrontpolicy", be);

          if (primaryRegion) {
            be[`${PREFIX}/shieldforcloudfrontpolicy/regions`] = primaryRegion;
          }
        }

        if (formData.createshieldforalboreippolicy.value === "yes") {
          shieldpolicies.push("shieldforalboreippolicy");

          convertFEToBEPerSection("shieldforalboreippolicy", be);
        }
      }

      if (securitygrouppolicies.length > 0) {
        be[`${PREFIX}/securitygrouppolicies`] = securitygrouppolicies.join(",");
      }

      if (wafpolicies.length > 0) {
        be[`${PREFIX}/wafpolicies`] = wafpolicies.join(",");
      }

      if (shieldpolicies.length > 0) {
        be[`${PREFIX}/shieldpolicies`] = shieldpolicies.join(",");
      }

      be[`${PREFIX}/monitorhighriskevents`] = formData.monitorhighriskevents.value;
    }
    else{
      be[`${PREFIX}/createbaselinesecuritygrouppolicy`] = "no";
      be[`${PREFIX}/createsecuritygroupauditingpolicy`] = "no";
      be[`${PREFIX}/createunusedsecuritygroupspolicy`] = "no";
      be[`${PREFIX}/createbaselinewebaclforalbapigatewaypolicy`] = "no";
      be[`${PREFIX}/createbaselinewebaclforcloudfrontpolicy`] = "no";
      be[`${PREFIX}/createshieldforalboreippolicy`] = "no";
      be[`${PREFIX}/createshieldforcloudfrontpolicy`] = "no";
    }

    return be;
  };

  const convertFEToBEPerSection = (section, be) => {
    if (formData[`${section}_regions`].length > 0) {
      be[`${PREFIX}/${section}/regions`] = formData[`${section}_regions`].join(
        ","
      );
    }

    if (formData[`${section}_policyrules`].length > 0) {
      be[`${PREFIX}/${section}/policyrules`] = formData[
        `${section}_policyrules`
      ].join(",");
    }

    if (formData[`${section}_policyaction`].value !== "") {
      be[`${PREFIX}/${section}/policyaction`] =
        formData[`${section}_policyaction`].value;
    }

    if (formData[`${section}_policyscope_resourcetypes`].length > 0) {
      be[`${PREFIX}/${section}/policyscope/resourcetypes`] = formData[
        `${section}_policyscope_resourcetypes`
      ].join(",");
    }

    if (formData[`${section}_policyscope_resources`].value !== "") {
      be[`${PREFIX}/${section}/policyscope/resources`] =
        formData[`${section}_policyscope_resources`].value;

      if (
        formData[`${section}_policyscope_resources`].value ===
        "onlyresourceswithspecifiedtag"
      ) {
        if (formData[`${section}_policyscope_resources_tagkey`].isValid) {
          be[`${PREFIX}/${section}/policyscope/resources/tagkey`] =
            formData[`${section}_policyscope_resources_tagkey`].value;
        }

        if (formData[`${section}_policyscope_resources_tagvalue`].isValid) {
          be[`${PREFIX}/${section}/policyscope/resources/tagvalue`] =
            formData[`${section}_policyscope_resources_tagvalue`].value;
        }
      }
    }

    if (section === "baselinesecuritygrouppolicy") {
      if (
        formData
          .baselinesecuritygrouppolicy_policyscope_includeresourcesfromsharedvpcs
          .value !== ""
      ) {
        be[
          `${PREFIX}/baselinesecuritygrouppolicy/policyscope/includeresourcesfromsharedvpcs`
        ] =
          formData.baselinesecuritygrouppolicy_policyscope_includeresourcesfromsharedvpcs.value;
      }
    }
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    if (key === "createsecuritygrouppolicies" && value === "no") {
      setFormData((state) =>
        update(state, {
          [key]: {
            value: { $set: value },
          },
          createbaselinesecuritygrouppolicy: {
            value: { $set: "no" },
          },
          createsecuritygroupauditingpolicy: {
            value: { $set: "no" },
          },
          createunusedsecuritygroupspolicy: {
            value: { $set: "no" },
          },
        })
      );
    } else if (key === "createwafpolicies" && value === "no") {
      setFormData((state) =>
        update(state, {
          [key]: {
            value: { $set: value },
          },
          createbaselinewebaclforcloudfrontpolicy: {
            value: { $set: "no" },
          },
          createbaselinewebaclforalbapigatewaypolicy: {
            value: { $set: "no" },
          },
        })
      );
    } else if (key === "createshieldpolicies" && value === "no") {
      setFormData((state) =>
        update(state, {
          [key]: {
            value: { $set: value },
          },
          createshieldforcloudfrontpolicy: {
            value: { $set: "no" },
          },
          createshieldforalboreippolicy: {
            value: { $set: "no" },
          },
        })
      );
    } else {
      setFormData((state) =>
        update(state, {
          [key]: {
            value: { $set: value },
          },
        })
      );
    }
  };

  const onFieldChange = (value, key) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key]: { value: { $set: value } } })
    );
  };

  const exists = (key, value) => {
    return formData[key].indexOf(value) !== -1;
  };

  const add = (key, value) => {
    setIsDirty(true);

    setFormData((state) => update(state, { [key]: { $push: [value] } }));
  };

  const remove = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: { $splice: [[state[key].indexOf(value), 1]] },
      })
    );
  };

  const modifyArray = (key, value) => {
    if (exists(key, value)) {
      remove(key, value);
    } else {
      add(key, value);
    }
  };

  const onFieldBlur = (validateFunc, value, key1) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, { [key1]: { isValid: { $set: errorMsg === undefined } } })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, { [key1]: { isValid: { $set: false } } })
      );
    }
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Network</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Security"
                isDirty={isDirty}
                dashboardRoute={ROUTES.SECURITY_HUB_MEMBERS}
                designRoute={ROUTES.DESIGN_SECURITY}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight perimeter-protection-page flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div className="row d-flex align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-4 pl-lg-0 px-2">
                    <h3>Perimeter Protection</h3>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 d-flex align-items-center justify-content-end rightNavArea px-0">
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 text-nowrap">
                        Configure Centralized Management of WAF, Shield, and VPC
                        Security Groups
                      </div>
                      <div className=" btn-group btn-group-toggle btn-group-radio mw-100 ">
                        <RadioGroup
                          field="deploycentralizedperimeterprotection"
                          value={
                            formData.deploycentralizedperimeterprotection.value
                          }
                          onChange={updateRadio}
                          isLarge={true}
                        ></RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>
                {empty && (
                  <NoInitialData />
                )}
                {formData.deploycentralizedperimeterprotection.value ===
                  "yes" && (
                  <div className="row">
                    <div className="d-inline-flex align-items-center row px-2">
                      <div className="col-md-3 p-0 my-1">
                        <span className="mb-0 font-12">
                          AWS Firewall Manager Administrator Account
                        </span>
                      </div>
                      <div className="col-md-9 flex-wrap d-inline-flex">
                        <div className="mr-3 my-1">
                          <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                            <select
                              className="form-control gray"
                              value={
                                formData.firewallmanageradministratoraccount
                                  .value
                              }
                              onChange={(e) => {
                                onFieldChange(
                                  e.target.value,
                                  "firewallmanageradministratoraccount"
                                );
                              }}
                            >
                              <option value="">Select</option>
                              {serviceAccounts.security.map((sa, idxSa) => (
                                <option key={idxSa} value={sa}>
                                  {sa}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 px-1 my-1">
                      <div className="w-100">
                        <div className="d-inline-flex yes-no-area align-items-center">
                          <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                            Send Firewall Manager Findings to SecurityHub
                          </div>
                          <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                            <RadioGroup
                              field="sendfindingstosecurityhub"
                              value={formData.sendfindingstosecurityhub.value}
                              onChange={updateRadio}
                            ></RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 px-1 my-1">
                      <div className="w-100">
                        <div className="d-inline-flex yes-no-area align-items-center">
                          <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                            Send Notifications to Security Domain SNS Topic
                          </div>
                          <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                            <RadioGroup
                              field="sendnotificationstosecuritydomainsnstopic"
                              value={
                                formData
                                  .sendnotificationstosecuritydomainsnstopic
                                  .value
                              }
                              onChange={updateRadio}
                            ></RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 px-1 mt-3 mb-1">
                      <div className="w-100">
                        <div className="d-inline-flex yes-no-area align-items-center">
                          <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                           <b>Create Security Groups Policies</b>
                          </div>
                          <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                            <RadioGroup
                              field="createsecuritygrouppolicies"
                              value={formData.createsecuritygrouppolicies.value}
                              onChange={updateRadio}
                            ></RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    {formData.createsecuritygrouppolicies.value === "yes" && (
                      <>
                        <div className="mainContentArea row px-2 py-1 my-1">
                          <div className="col-12 px-1 my-1">
                            <div className="w-100">
                              <div className="d-inline-flex yes-no-area align-items-center">
                                <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                                  Create and Associate a Baseline Security Group
                                  with (SSH, RDP, Nessus Scanner, etc.) access
                                  from on-prem network in all member accounts in
                                  AWS Organization
                                </div>
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                                  <RadioGroup
                                    field="createbaselinesecuritygrouppolicy"
                                    value={
                                      formData.createbaselinesecuritygrouppolicy
                                        .value
                                    }
                                    onChange={updateRadio}
                                  ></RadioGroup>
                                </div>
                              </div>
                            </div>
                          </div>

                          {formData.createbaselinesecuritygrouppolicy.value ===
                            "yes" && (
                            <>
                              <Regions
                                regions={regions}
                                section="baselinesecuritygrouppolicy"
                                exists={exists}
                                modifyArray={modifyArray}
                              ></Regions>

                              <div className="align-items-center my-1 row">
                                <div className="col-md-12 my-1  p-0">
                                  <span className="mb-0 font-12 font-weight-bold">
                                    Policy Rules
                                  </span>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "baselinesecuritygrouppolicy_policyrules",
                                          "revertlocalchanges"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "baselinesecuritygrouppolicy_policyrules",
                                            "revertlocalchanges"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Identify and revert any local changes made
                                      to the security group rules created by
                                      this policy
                                    </span>
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "baselinesecuritygrouppolicy_policyrules",
                                          "disassociateotherssecuritygroups"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "baselinesecuritygrouppolicy_policyrules",
                                            "disassociateotherssecuritygroups"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Disassociate any other security groups
                                      from AWS resources within the policy scope
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <PolicyAction
                                formData={formData}
                                section="baselinesecuritygrouppolicy"
                                updateRadio={updateRadio}
                              ></PolicyAction>

                              <div className="align-items-center my-1 row">
                                <div className="col-md-12 my-1  p-0">
                                  <span className="mb-0 font-12 font-weight-bold">
                                    Policy Scope
                                  </span>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "baselinesecuritygrouppolicy_policyscope_resourcetypes",
                                          "AWS::EC2::Instance"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "baselinesecuritygrouppolicy_policyscope_resourcetypes",
                                            "AWS::EC2::Instance"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      EC2 instance
                                    </span>
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "baselinesecuritygrouppolicy_policyscope_resourcetypes",
                                          "AWS::EC2::NetworkInterface"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "baselinesecuritygrouppolicy_policyscope_resourcetypes",
                                            "AWS::EC2::NetworkInterface"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Elastic Network Interface
                                    </span>
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "baselinesecuritygrouppolicy_policyscope_resourcetypes",
                                          "AWS::ElasticLoadBalancingV2::LoadBalancer"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "baselinesecuritygrouppolicy_policyscope_resourcetypes",
                                            "AWS::ElasticLoadBalancingV2::LoadBalancer"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Application Load Balancer
                                    </span>
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "baselinesecuritygrouppolicy_policyscope_resourcetypes",
                                          "AWS::ElasticLoadBalancing::LoadBalancer"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "baselinesecuritygrouppolicy_policyscope_resourcetypes",
                                            "AWS::ElasticLoadBalancing::LoadBalancer"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Classic Load Balancer
                                    </span>
                                  </div>
                                </div>

                                <IncludeResources
                                  formData={formData}
                                  updateRadio={updateRadio}
                                  onFieldChange={onFieldChange}
                                  onFieldBlur={onFieldBlur}
                                  section="baselinesecuritygrouppolicy"
                                />

                                <div className="d-inline-flex row px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="d-inline-flex yes-no-area align-items-center">
                                    <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                                      Include resources from shared VPCs
                                    </div>
                                    <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                                      <RadioGroup
                                        field="baselinesecuritygrouppolicy_policyscope_includeresourcesfromsharedvpcs"
                                        value={
                                          formData
                                            .baselinesecuritygrouppolicy_policyscope_includeresourcesfromsharedvpcs
                                            .value
                                        }
                                        onChange={updateRadio}
                                      ></RadioGroup>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="mainContentArea row px-2 py-1 my-1">
                          <div className="col-12 px-1 my-1">
                            <div className="w-100">
                              <div className="d-inline-flex yes-no-area align-items-center">
                                <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                                  Audit & Enforce Security Group Rules in all
                                  member accounts in AWS organization
                                </div>
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                                  <RadioGroup
                                    field="createsecuritygroupauditingpolicy"
                                    value={
                                      formData.createsecuritygroupauditingpolicy
                                        .value
                                    }
                                    onChange={updateRadio}
                                  ></RadioGroup>
                                </div>
                              </div>
                            </div>
                          </div>

                          {formData.createsecuritygroupauditingpolicy.value ===
                            "yes" && (
                            <>
                              <Regions
                                regions={regions}
                                section="securitygroupauditingpolicy"
                                exists={exists}
                                modifyArray={modifyArray}
                              />

                              <div className="align-items-center my-1 row">
                                <div className="col-md-12 my-1  p-0">
                                  <span className="mb-0 font-12 font-weight-bold">
                                    Policy Rules
                                  </span>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "securitygroupauditingpolicy_policyrules",
                                          "auditoverlypermissivecidrranges"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "securitygroupauditingpolicy_policyrules",
                                            "auditoverlypermissivecidrranges"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Audit SGs with Inbound Rules that have
                                      overly permissive CIDR ranges
                                    </span>
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "securitygroupauditingpolicy_policyrules",
                                          "audithighriskapplications"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "securitygroupauditingpolicy_policyrules",
                                            "audithighriskapplications"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Audit SGs with Inbound Rules that have
                                      high-risk applications such as RDP and SSH
                                      accessed from the internet
                                    </span>
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "securitygroupauditingpolicy_policyrules",
                                          "auditsshaccess"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "securitygroupauditingpolicy_policyrules",
                                            "auditsshaccess"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Audit SGs to only allow SSH access from
                                      corporate IP addresses
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <PolicyAction
                                formData={formData}
                                section="securitygroupauditingpolicy"
                                updateRadio={updateRadio}
                              ></PolicyAction>

                              <div className="align-items-center my-1 row">
                                <div className="col-md-12 my-1  p-0">
                                  <span className="mb-0 font-12 font-weight-bold">
                                    Policy Scope
                                  </span>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "securitygroupauditingpolicy_policyscope_resourcetypes",
                                          "AWS::EC2::Instance"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "securitygroupauditingpolicy_policyscope_resourcetypes",
                                            "AWS::EC2::Instance"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      EC2 instance
                                    </span>
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "securitygroupauditingpolicy_policyscope_resourcetypes",
                                          "AWS::EC2::NetworkInterface"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "securitygroupauditingpolicy_policyscope_resourcetypes",
                                            "AWS::EC2::NetworkInterface"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Elastic Network Interface
                                    </span>
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "securitygroupauditingpolicy_policyscope_resourcetypes",
                                          "AWS::EC2::SecurityGroup"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "securitygroupauditingpolicy_policyscope_resourcetypes",
                                            "AWS::EC2::SecurityGroup"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Security Group
                                    </span>
                                  </div>
                                </div>

                                <IncludeResources
                                  formData={formData}
                                  updateRadio={updateRadio}
                                  onFieldChange={onFieldChange}
                                  onFieldBlur={onFieldBlur}
                                  section="securitygroupauditingpolicy"
                                />
                              </div>
                            </>
                          )}
                        </div>

                        <div className="mainContentArea row px-2 py-1 my-1">
                          <div className="col-12 px-1 my-1">
                            <div className="w-100">
                              <div className="d-inline-flex yes-no-area align-items-center">
                                <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                                  Identify and cleanup any unused security
                                  groups
                                </div>
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                                  <RadioGroup
                                    field="createunusedsecuritygroupspolicy"
                                    value={
                                      formData.createunusedsecuritygroupspolicy
                                        .value
                                    }
                                    onChange={updateRadio}
                                  ></RadioGroup>
                                </div>
                              </div>
                            </div>
                          </div>
                          {formData.createunusedsecuritygroupspolicy.value ===
                            "yes" && (
                            <>
                              <Regions
                                regions={regions}
                                section="unusedsecuritygroupspolicy"
                                exists={exists}
                                modifyArray={modifyArray}
                              ></Regions>

                              <div className="align-items-center my-1 row">
                                <div className="col-md-12 my-1  p-0">
                                  <span className="mb-0 font-12 font-weight-bold">
                                    Policy Rules
                                  </span>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "unusedsecuritygroupspolicy_policyrules",
                                          "mustbeusedbyatleastoneresource"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "unusedsecuritygroupspolicy_policyrules",
                                            "mustbeusedbyatleastoneresource"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Security group within this policy scope
                                      must be used by at least one resource
                                    </span>
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "unusedsecuritygroupspolicy_policyrules",
                                          "mustbeunique"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "unusedsecuritygroupspolicy_policyrules",
                                            "mustbeunique"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Security groups within this policy scope
                                      must be unique
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <PolicyAction
                                formData={formData}
                                section="unusedsecuritygroupspolicy"
                                updateRadio={updateRadio}
                              ></PolicyAction>

                              <div className="align-items-center my-1 row">
                                <div className="col-md-12 my-1  p-0">
                                  <span className="mb-0 font-12 font-weight-bold">
                                    Policy Scope
                                  </span>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "unusedsecuritygroupspolicy_policyscope_resourcetypes",
                                          "AWS::EC2::SecurityGroup"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "unusedsecuritygroupspolicy_policyscope_resourcetypes",
                                            "AWS::EC2::SecurityGroup"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Security Group
                                    </span>
                                  </div>
                                </div>

                                <IncludeResources
                                  formData={formData}
                                  updateRadio={updateRadio}
                                  onFieldChange={onFieldChange}
                                  onFieldBlur={onFieldBlur}
                                  section="unusedsecuritygroupspolicy"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                    <div className="col-12 px-1 mt-3 mb-1">
                      <div className="w-100">
                        <div className="d-inline-flex yes-no-area align-items-center">
                          <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                            <b>Create Web Application Firewall (WAF) Policies</b>
                          </div>
                          <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                            <RadioGroup
                              field="createwafpolicies"
                              value={formData.createwafpolicies.value}
                              onChange={updateRadio}
                            ></RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                    {formData.createwafpolicies.value === "yes" && (
                      <>
                        <div className="mainContentArea row px-2 py-1 my-1">
                          <div className="col-12 px-1 my-1">
                            <div className="w-100">
                              <div className="d-inline-flex yes-no-area align-items-center">
                                <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                                  Create and Associate Baseline WebACLs whenever
                                  a cloud front distribution is created in
                                  member accounts
                                </div>
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                                  <RadioGroup
                                    field="createbaselinewebaclforcloudfrontpolicy"
                                    value={
                                      formData
                                        .createbaselinewebaclforcloudfrontpolicy
                                        .value
                                    }
                                    onChange={updateRadio}
                                  ></RadioGroup>
                                </div>
                              </div>
                            </div>
                          </div>

                          {formData.createbaselinewebaclforcloudfrontpolicy
                            .value === "yes" && (
                            <>
                              <div className="align-items-center my-1 row">
                                <div className="col-md-12 my-1  p-0">
                                  <span className="mb-0 font-12 font-weight-bold">
                                    Policy Rules
                                  </span>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "baselinewebaclforcloudfrontpolicy_policyrules",
                                          "coreruleset"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "baselinewebaclforcloudfrontpolicy_policyrules",
                                            "coreruleset"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Core rule set
                                    </span>
                                  </div>
                                  <div className="subinfoForSelection">
                                    Contains rules that are generally applicable
                                    to web applications. This provides
                                    protection against exploitation of a wide
                                    range of vulnerabilities, including those
                                    described in OWASP publications.
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "baselinewebaclforcloudfrontpolicy_policyrules",
                                          "adminprotectionruleset"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "baselinewebaclforcloudfrontpolicy_policyrules",
                                            "adminprotectionruleset"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Admin protection rule set
                                    </span>
                                  </div>
                                  <div className="subinfoForSelection">
                                    Contains rules that allow you to block
                                    external access to exposed admin pages. This
                                    may be useful if you are running third-party
                                    software or would like to reduce the risk of
                                    a malicious actor gaining administrative
                                    access to your application.
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "baselinewebaclforcloudfrontpolicy_policyrules",
                                          "knownbadinputsruleset"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "baselinewebaclforcloudfrontpolicy_policyrules",
                                            "knownbadinputsruleset"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Known bad inputs rule set
                                    </span>
                                  </div>
                                  <div className="subinfoForSelection">
                                    Contains rules that allow you to block
                                    request patterns that are known to be
                                    invalid and are associated with exploitation
                                    or discovery of vulnerabilities. This can
                                    help reduce the risk of a malicious actor
                                    discovering a vulnerable application.
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "baselinewebaclforcloudfrontpolicy_policyrules",
                                          "amazonipreputationlistruleset"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "baselinewebaclforcloudfrontpolicy_policyrules",
                                            "amazonipreputationlistruleset"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Amazon IP reputation list rule set
                                    </span>
                                  </div>
                                  <div className="subinfoForSelection">
                                    This group contains rules that are based on
                                    Amazon threat intelligence. This is useful
                                    if you would like to block sources
                                    associated with bots or other threats.
                                  </div>
                                </div>
                              </div>

                              <PolicyAction
                                formData={formData}
                                section="baselinewebaclforcloudfrontpolicy"
                                updateRadio={updateRadio}
                              ></PolicyAction>

                              <div className="align-items-center my-1 row">
                                <div className="col-md-12 my-1  p-0">
                                  <span className="mb-0 font-12 font-weight-bold">
                                    Policy Scope
                                  </span>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "baselinewebaclforcloudfrontpolicy_policyscope_resourcetypes",
                                          "AWS::CloudFront::Distribution"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "baselinewebaclforcloudfrontpolicy_policyscope_resourcetypes",
                                            "AWS::CloudFront::Distribution"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      CloudFront Distribution
                                    </span>
                                  </div>
                                </div>

                                <IncludeResources
                                  formData={formData}
                                  updateRadio={updateRadio}
                                  onFieldChange={onFieldChange}
                                  onFieldBlur={onFieldBlur}
                                  section="baselinewebaclforcloudfrontpolicy"
                                />
                              </div>
                            </>
                          )}
                        </div>

                        <div className="mainContentArea row px-2 py-1 my-1">
                          <div className="col-12 px-1 my-1">
                            <div className="w-100">
                              <div className="d-inline-flex yes-no-area align-items-center">
                                <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                                  Create and Associate Baseline WebACLs whenever
                                  an API Gateway Stage or Application Load
                                  Balancer is created in member accounts
                                </div>
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                                  <RadioGroup
                                    field="createbaselinewebaclforalbapigatewaypolicy"
                                    value={
                                      formData
                                        .createbaselinewebaclforalbapigatewaypolicy
                                        .value
                                    }
                                    onChange={updateRadio}
                                  ></RadioGroup>
                                </div>
                              </div>
                            </div>
                          </div>
                          {formData.createbaselinewebaclforalbapigatewaypolicy
                            .value === "yes" && (
                            <>
                              <Regions
                                regions={regions}
                                section="baselinewebaclforalbapigatewaypolicy"
                                exists={exists}
                                modifyArray={modifyArray}
                              ></Regions>

                              <div className="align-items-center my-1 row">
                                <div className="col-md-12 my-1  p-0">
                                  <span className="mb-0 font-12 font-weight-bold">
                                    Policy Rules
                                  </span>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "baselinewebaclforalbapigatewaypolicy_policyrules",
                                          "coreruleset"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "baselinewebaclforalbapigatewaypolicy_policyrules",
                                            "coreruleset"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Core rule set
                                    </span>
                                  </div>
                                  <div className="subinfoForSelection">
                                    Contains rules that are generally applicable
                                    to web applications. This provides
                                    protection against exploitation of a wide
                                    range of vulnerabilities, including those
                                    described in OWASP publications.
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "baselinewebaclforalbapigatewaypolicy_policyrules",
                                          "adminprotectionruleset"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "baselinewebaclforalbapigatewaypolicy_policyrules",
                                            "adminprotectionruleset"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Admin protection rule set
                                    </span>
                                  </div>
                                  <div className="subinfoForSelection">
                                    Contains rules that allow you to block
                                    external access to exposed admin pages. This
                                    may be useful if you are running third-party
                                    software or would like to reduce the risk of
                                    a malicious actor gaining administrative
                                    access to your application.
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "baselinewebaclforalbapigatewaypolicy_policyrules",
                                          "knownbadinputsruleset"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "baselinewebaclforalbapigatewaypolicy_policyrules",
                                            "knownbadinputsruleset"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Known bad inputs rule set
                                    </span>
                                  </div>
                                  <div className="subinfoForSelection">
                                    Contains rules that allow you to block
                                    request patterns that are known to be
                                    invalid and are associated with exploitation
                                    or discovery of vulnerabilities. This can
                                    help reduce the risk of a malicious actor
                                    discovering a vulnerable application.
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "baselinewebaclforalbapigatewaypolicy_policyrules",
                                          "amazonipreputationlistruleset"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "baselinewebaclforalbapigatewaypolicy_policyrules",
                                            "amazonipreputationlistruleset"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Amazon IP reputation list rule set
                                    </span>
                                  </div>
                                  <div className="subinfoForSelection">
                                    This group contains rules that are based on
                                    Amazon threat intelligence. This is useful
                                    if you would like to block sources
                                    associated with bots or other threats.
                                  </div>
                                </div>
                              </div>

                              <PolicyAction
                                formData={formData}
                                section="baselinewebaclforalbapigatewaypolicy"
                                updateRadio={updateRadio}
                              ></PolicyAction>

                              <div className="align-items-center my-1 row">
                                <div className="col-md-12 my-1  p-0">
                                  <span className="mb-0 font-12 font-weight-bold">
                                    Policy Scope
                                  </span>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "baselinewebaclforalbapigatewaypolicy_policyscope_resourcetypes",
                                          "AWS::ApiGateway::Stage"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "baselinewebaclforalbapigatewaypolicy_policyscope_resourcetypes",
                                            "AWS::ApiGateway::Stage"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      API Gateway Stage
                                    </span>
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "baselinewebaclforalbapigatewaypolicy_policyscope_resourcetypes",
                                          "AWS::ElasticLoadBalancingV2::LoadBalancer"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "baselinewebaclforalbapigatewaypolicy_policyscope_resourcetypes",
                                            "AWS::ElasticLoadBalancingV2::LoadBalancer"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Application Load Balancer
                                    </span>
                                  </div>
                                </div>

                                <IncludeResources
                                  formData={formData}
                                  updateRadio={updateRadio}
                                  onFieldChange={onFieldChange}
                                  onFieldBlur={onFieldBlur}
                                  section="baselinewebaclforalbapigatewaypolicy"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                    <div className="col-12 px-1 mt-3 mb-1">
                      <div className="w-100">
                        <div className="d-inline-flex yes-no-area align-items-center">
                          <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                           <b> Create Policy for AWS Shield Advanced </b>
                          </div>
                          <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                            <RadioGroup
                              field="createshieldpolicies"
                              value={formData.createshieldpolicies.value}
                              onChange={updateRadio}
                            ></RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    {formData.createshieldpolicies.value === "yes" && (
                      <>
                        <div className="mainContentArea row px-2 py-1 my-1">
                          <div className="col-12 px-1 my-1">
                            <div className="w-100">
                              <div className="d-inline-flex yes-no-area align-items-center">
                                <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                                  Associate AWS Shield Advanced whenever an
                                  Application Load Balancer or EIP is created in
                                  member accounts
                                </div>
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                                  <RadioGroup
                                    field="createshieldforalboreippolicy"
                                    value={
                                      formData.createshieldforalboreippolicy
                                        .value
                                    }
                                    onChange={updateRadio}
                                  ></RadioGroup>
                                </div>
                              </div>
                            </div>
                          </div>

                          {formData.createshieldforalboreippolicy.value ===
                            "yes" && (
                            <>
                              <Regions
                                regions={regions}
                                section="shieldforalboreippolicy"
                                exists={exists}
                                modifyArray={modifyArray}
                              ></Regions>

                              <PolicyAction
                                formData={formData}
                                section="shieldforalboreippolicy"
                                updateRadio={updateRadio}
                              ></PolicyAction>

                              <div className="align-items-center my-1 row">
                                <div className="col-md-12 my-1  p-0">
                                  <span className="mb-0 font-12 font-weight-bold">
                                    Policy Scope
                                  </span>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "shieldforalboreippolicy_policyscope_resourcetypes",
                                          "AWS::ElasticLoadBalancingV2::LoadBalancer"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "shieldforalboreippolicy_policyscope_resourcetypes",
                                            "AWS::ElasticLoadBalancingV2::LoadBalancer"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Application Load Balancer
                                    </span>
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "shieldforalboreippolicy_policyscope_resourcetypes",
                                          "AWS::ElasticLoadBalancing::LoadBalancer"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "shieldforalboreippolicy_policyscope_resourcetypes",
                                            "AWS::ElasticLoadBalancing::LoadBalancer"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Classic Load Balancer
                                    </span>
                                  </div>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "shieldforalboreippolicy_policyscope_resourcetypes",
                                          "AWS::EC2::EIP"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "shieldforalboreippolicy_policyscope_resourcetypes",
                                            "AWS::EC2::EIP"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      Elastic IP
                                    </span>
                                  </div>
                                </div>

                                <IncludeResources
                                  formData={formData}
                                  updateRadio={updateRadio}
                                  onFieldChange={onFieldChange}
                                  onFieldBlur={onFieldBlur}
                                  section="shieldforalboreippolicy"
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="mainContentArea row px-2 py-1 my-1">
                          <div className="col-12 px-1 my-1">
                            <div className="w-100">
                              <div className="d-inline-flex yes-no-area align-items-center">
                                <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                                  Associate AWS Shield Advanced whenever an
                                  CloudFront Distribution is created in member
                                  accounts
                                </div>
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                                  <RadioGroup
                                    field="createshieldforcloudfrontpolicy"
                                    value={
                                      formData.createshieldforcloudfrontpolicy
                                        .value
                                    }
                                    onChange={updateRadio}
                                  ></RadioGroup>
                                </div>
                              </div>
                            </div>
                          </div>

                          {formData.createshieldforcloudfrontpolicy.value ===
                            "yes" && (
                            <>
                              <PolicyAction
                                formData={formData}
                                section="shieldforcloudfrontpolicy"
                                updateRadio={updateRadio}
                              ></PolicyAction>

                              <div className="align-items-center my-1 row">
                                <div className="col-md-12 my-1  p-0">
                                  <span className="mb-0 font-12 font-weight-bold">
                                    Policy Scope
                                  </span>
                                </div>

                                <div className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap">
                                  <div className="form-line  my-1 col-12 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "shieldforcloudfrontpolicy_policyscope_resourcetypes",
                                          "AWS::CloudFront::Distribution"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "shieldforcloudfrontpolicy_policyscope_resourcetypes",
                                            "AWS::CloudFront::Distribution"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel ">
                                      CloudFront Distribution
                                    </span>
                                  </div>
                                </div>

                                <IncludeResources
                                  formData={formData}
                                  updateRadio={updateRadio}
                                  onFieldChange={onFieldChange}
                                  onFieldBlur={onFieldBlur}
                                  section="shieldforcloudfrontpolicy"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}

                    <div className="mainContentArea row  py-1 my-1">
                      <div className="col-12 px-1 my-1">
                        <div className="w-100">
                          <div className="d-inline-flex yes-no-area align-items-center">
                            <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                              Monitor High-risk Events
                            </div>
                            <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                              <RadioGroup
                                field="monitorhighriskevents"
                                value={formData.monitorhighriskevents.value}
                                onChange={updateRadio}
                              ></RadioGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.SECURITY_SECRETS_MANAGEMENT + "/" + projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Secrets Management
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(ROUTES.SECURITY_SIEM + "/" + projectId)
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">SIEM</p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerimeterProtection;
