import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import * as ROUTES from "../constants/routes";
import useAwsAmplify from "../hooks/useAwsAmplify";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const SwitchUserForm = ({
  close,
  setIsForgotPassword,
  setIsSwitchUser,
  setTempAuthUser,
  setIsNewPasswordRequired,
  setInfo
}) => {
  const awsAmplify = useAwsAmplify();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [showPwd, setShowPwd] = useState(false);
  const { register, handleSubmit, formState, errors } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = async (formValues) => {
    setIsLoading(true);
    setError("");

    try {
      awsAmplify.signOut();

      const signInResults = await awsAmplify.signIn(
        formValues.email,
        formValues.password
      );

      console.log(signInResults);
      setIsLoading(false);

      if (signInResults.challengeName === "NEW_PASSWORD_REQUIRED") {
        setInfo("Change Password");
        setTempAuthUser(signInResults);
        setIsSwitchUser(false);
        setIsNewPasswordRequired(true);

        setTimeout(() => setInfo(""), 2000);
      } else {
        window.location.href = "/";
      }
    } catch (err) {
      setIsLoading(false);

      if (err.code === "UserNotConfirmedException") {
        //setState("CONFIRMSIGNUP");
      } else if (err.code === "UserNotFoundException") {
        setError("User not registered.");
      } else if (
        err.code === "NotAuthorizedException" &&
        err.message === "Incorrect username or password."
      ) {
        setError("Incorrect Password");
      } else {
        if (err.message) {
          setError(err.message);
        }
      }

      window.location.href = "/";
    }
  };

  useEffect(() => {
    const errorMsgs = [];

    if(errors){
      for(const field in errors){
        if(errors[field].message){
          errorMsgs.push(errors[field].message);
        }
      }
    }

    if (errorMsgs.length > 0) {
      setError(errorMsgs);
    }
  }, [errors.email, errors.password]);

  return (
    <>
      {error && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setError(null)}>
              &times;
            </span>
            <div className="messageText">
            <strong>Error!</strong>
              {Array.isArray(error)
                ? error.map((em, idx) => (
                      <span key={idx}>{em}</span>
                  ))
                : error}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="auth-main">
        <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
          <a onClick={close} className="exit btn-animation">
            Exit
          </a>
          <div className="title">Switch User</div>
          <div className="auth-form-content">
            <input
              type="text"
              className="form-control mail"
              placeholder="Email Address"
              name="email"
              ref={register}
            />
            <div className="password">
              <input
                type={showPwd ? "text" : "password"}
                className="form-control psw"
                placeholder="Password"
                name="password"
                ref={register}
              />
              <button
                type="button"
                className="btn btn-link btn-animation"
                onClick={() => setShowPwd(!showPwd)}
              >
                Show / Hide
              </button>
            </div>
          </div>
          <div className="auth-form-footer">
            <div>
              <button
                type="submit"
                className="btn btn-link btn-green btn-animation"
              >
                Sign In
              </button>
            </div>
            <div className="forgot-password">
              Forgot your password?{" "}
              <a
                className="myclickable"
                onClick={() => {
                  setIsForgotPassword(true);
                  setIsSwitchUser(false);
                }}
              >
                Reset password
              </a>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SwitchUserForm;
