import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import * as ROUTES from "../constants/routes";
import useAwsAmplify from "../hooks/useAwsAmplify";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  code: yup.string().required(),
});

const emailOnlySchema = yup.string().email().required();

const ConfirmSignUpForm = ({ setState, setError, setIsLoading }) => {
  const awsAmplify = useAwsAmplify();
  const history = useHistory();
  const location = useLocation();

  let msg;

  if (location.search) {
    const params = new URLSearchParams(location.search);

    if (params.get("msg")) {
      switch (params.get("msg")) {
        case "notRegistered":
          msg = "User not registered. Please register";
          break;
        default:
          msg = null;
          break;
      }
    }
  }

  const { register, handleSubmit, formState, errors, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const watchEmail = watch("email");

  const onSubmit = async (formValues) => {
    setIsLoading(true);
    setError("");

    try {
      await awsAmplify.confirmSignUp(formValues.email, formValues.code);
      setIsLoading(false);

      setState("SIGNIN");
    } catch (err) {
      setIsLoading(false);

      if (err.code === "ExpiredCodeException") {
        setError("Confirmation code is expired.");
      } else if (
        err.code === "NotAuthorizedException" &&
        err.message === "User cannot be confirmed. Current status is CONFIRMED"
      ) {
        setError("Registration already confirmed");
        setState("SIGNIN");
      } else if (err.code === "UserNotFoundException") {
        setError("User not registered. Please register");
        setState("SIGNUP");
      } else if (err.code === "CodeMismatchException") {
        setError("Incorrect Registration Code");
      } else {
        if (err.message) {
          setError(err.message);
        }
      }
    }
  };

  const resendCode = async () => {
    if(!watchEmail){
      return;
    }

    setIsLoading(true);
    setError("");


    try {
      await awsAmplify.resendSignUp(watchEmail);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);

      //setError(err);

      if (
        err.code === "NotAuthorizedException" &&
        err.message === "User cannot be confirmed. Current status is CONFIRMED"
      ) {
        setError("Registration already confirmed");
        setState("SIGNIN");
      } else if (err.code === "UserNotFoundException") {
        setError("User not registered. Please register");
        setState("SIGNUP");
      } else if (err.code === "CodeMismatchException") {
        setError("Incorrect Registration Code");
      } else {
        if (err.message) {
          setError(err.message);
        }
      }
    }
  };

  useEffect(() => {
    const errorMsgs = [];

    if(errors){
      for(const field in errors){
        if(errors[field].message){
          errorMsgs.push(errors[field].message);
        }
      }
    }

    if (errorMsgs.length > 0) {
      setError(errorMsgs);
    }
  }, [errors.email, errors.code]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="#" className={errors.email && "highlight"}>
          <img src="images/icons/mail.svg" alt="" />
          <input
            type="email"
            placeholder="Email Address"
            name="email"
            ref={register}
          />
        </label>
        <label htmlFor="#" className={errors.code && "highlight"}>
          <img src="images/icons/check1.svg" alt="" />
          <input
            type="text"
            placeholder="Confirmation Code"
            name="code"
            ref={register}
          />
        </label>

        <div className="forgot-password">
          Lost Your Code?{" "}
          <button
            style={{color: "#3D9CD1"}}
            type="button"
            disabled={!emailOnlySchema.isValidSync(watchEmail)}
            onMouseDown={resendCode}
          >
            Resend Code
          </button>
        </div>

        <button type="submit" className="button">
          Confirm
        </button>
        <div className="back-to">
          <a className="myclickable" onClick={() => setState("SIGNUP")}>Back to Register</a>
        </div>
      </form>
    </>
  );
};

export default ConfirmSignUpForm;
