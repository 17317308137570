


import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import useServiceAccounts from "../../../../hooks/useServiceAccounts";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import useRegions from "../../../../hooks/useRegions";
import NoInitialData from "../../configure-common/NoInitialData";
import { validateAccountName, validateNewRelicLicenseKey } from "../../../../helpers/validator";

const PREFIX = "/aws/infrastructure/containerplatform";

const  NETWORK_SEGMENT_KEY = "/aws/network/intervpcconnectivity/networksegmentslist";

const getInitialFormData = (data) => {

    
    const initialFormData = {
        deploycontainerplatform  :  {
            value: data[`${PREFIX}/deploycontainerplatform`] || "yes",
          },
          deployscproduct : {
            value: data[`${PREFIX}/deployscproduct`] || "yes",
          },
          containerorchestrationsolution : {
            value: data[`${PREFIX}/containerorchestrationsolution`] || ""
          },  
          eks : {
            deploycentralizedcontainercluster : {
                value: data[`${PREFIX}/eks/deploycentralizedcontainercluster`]  || "yes"
              },
              accountid : {
                value:  data[`${PREFIX}/eks/centralizedcontainercluster/accountid`] || ""
              },
              region : {
                 value: data[`${PREFIX}/eks/centralizedcontainercluster/region`] || ""
              },
              numberofavailabilityzones : {
                value: data[`${PREFIX}/eks/centralizedcontainercluster/numberofavailabilityzones`] || ""
              },
              minclusternodes : {
                value: data[`${PREFIX}/eks/centralizedcontainercluster/minclusternodes`] || ""
              },
              maxclusternodes : {
                value: data[`${PREFIX}/eks/centralizedcontainercluster/maxclusternodes`] || ""
              },
              nodesinstancetype : {
                value: data[`${PREFIX}/eks/centralizedcontainercluster/nodesinstancetype`] || ""
              },
              nodevolumesize :{
                value: data[`${PREFIX}/eks/centralizedcontainercluster/nodevolumesize`] || ""
              },
              networksegment : {
                value: data[`${PREFIX}/eks/centralizedcontainercluster/networksegment`] || ""
              },
              newrelicintegration :{
                value: data[`${PREFIX}/eks/centralizedcontainercluster/newrelicintegration`] || "Yes"
              }
          },
          ecs : {
            deploycentralizedcontainercluster : {
                value: data[`${PREFIX}/ecs/deploycentralizedcontainercluster`]  || "yes"
              },
              accountid : {
                value:  data[`${PREFIX}/ecs/centralizedcontainercluster/accountid`] || ""
              },
              region : {
                value: data[`${PREFIX}/ecs/centralizedcontainercluster/region`] || ""
              },
              numberofavailabilityzones : {
                value: data[`${PREFIX}/ecs/centralizedcontainercluster/numberofavailabilityzones`] || ""
              },
              mincontainerinstances: {
                value: data[`${PREFIX}/ecs/centralizedcontainercluster/mincontainerinstances`] || ""
              },
              maxcontainerinstances: {
                value: data[`${PREFIX}/ecs/centralizedcontainercluster/maxcontainerinstances`] || ""
              },
              containerinstancetype: {
                value: data[`${PREFIX}/ecs/centralizedcontainercluster/containerinstancetype`] || ""
              },
              containervolumesize: {
                value: data[`${PREFIX}/ecs/centralizedcontainercluster/containervolumesize`] || ""
              },
              networksegment: {
                value: data[`${PREFIX}/ecs/centralizedcontainercluster/networksegment`] || ""
              },
              newrelicintegration: {
                value:  data[`${PREFIX}/ecs/centralizedcontainercluster/newrelicintegration`] || "Yes"
              } 
          },
          ecr : {
            deploycentralizedcontainerregistry : {
                value: data[`${PREFIX}/ecr/deploycentralizedcontainerregistry`]  || "yes"
            },
            accountid : {
                value:  data[`${PREFIX}/ecr/centralizedcontainerregistry/accountid`] || ""
              },
              region : {
                value: data[`${PREFIX}/ecr/centralizedcontainerregistry/region`] || ""
              },
              shareregistrywithorg :{
                  value: data[`${PREFIX}/ecr/centralizedcontainerregistry/shareregistrywithorg`]  || "yes"
              }
          },
          newrelic: {
            deployintegration : {
              value: data[`${PREFIX}/newrelic/deployintegration`]  || "yes"
            },
            newreliclicensekey : {
              value: data[`${PREFIX}/newrelic/newreliclicensekey`] || "",
              isValid: data[`${PREFIX}/newrelic/newreliclicensekey`] ? true : false,
            }
          },
          nodeArray : [],
          volumeSize : [],
          networkDataList : [],
          containerOrchestrationList : [],
          containers: ["eks", "ecs"]
    };
  return initialFormData;
};

const ContainerPlatform = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(() => getInitialFormData({}));
  const [showPwd, setShowPwd] = useState(false);
  
  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  const accounts = useServiceAccounts(projectId);
  const regions = useRegions(projectId);
  const [eksInstanceTypes, setEKSInstanceTypes] = useState([]);
  const [ecsInstanceTypes, setECSInstanceTypes] = useState([]);
  const [selectedEKSRegion, setSelectedEKSRegion] = useState('');
  const [selectedECSRegion, setSelectedECSRegion] = useState('');
  useEffect(() => {       
    const fetchECSInstanceTypes = async() => {
      const region = selectedECSRegion ? selectedECSRegion : ( formData.ecs.region && formData.ecs.region.value)
     if(region)
     {
      try{
        const allInstanceTypes = await awsAmplify.getAllInstanceTypes(region);

          if (allInstanceTypes.data) {
            setECSInstanceTypes(allInstanceTypes.data.split(","));
          }
        }
        catch(err)
        {
            console.log(err);
        }
     }
    }

    fetchECSInstanceTypes();
  },[selectedECSRegion])


  useEffect(() => {       
    const fetchEKSInstanceTypes = async() => {
      const region = selectedEKSRegion ? selectedEKSRegion : ( formData.eks.region && formData.eks.region.value)
     if(region)
     {
      try{
        const allInstanceTypes = await awsAmplify.getAllInstanceTypes(region);

          if (allInstanceTypes.data) {
            setEKSInstanceTypes(allInstanceTypes.data.split(","));
          }
        }
        catch(err)
        {
            console.log(err);
        }
     }
    }

    fetchEKSInstanceTypes();
  },[selectedEKSRegion])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);

        setEmpty(result.data.empty);

        const networkResult = await awsAmplify.loadProjectData(projectId, NETWORK_SEGMENT_KEY);
       
        var networkDataList = [];
        if(networkResult)
        {
         networkDataList = networkResult.data && networkResult.data[NETWORK_SEGMENT_KEY] && networkResult.data[NETWORK_SEGMENT_KEY].split(",") || [];
        } 

        setIsLoading(false);

        const fe =    convertBEToFE(
          result && result.data, networkDataList
        )
        setFormData(fe);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response && err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response && err.response.data.message);
    }
  };


  const convertBEToFE = (data, networkDataList) => {
    if (!data) {
      data = {  
      };
    }
    const fe = getInitialFormData(data);

    fe.containerorchestrationsolution && fe.containerorchestrationsolution.value 
    && fe.containerorchestrationsolution.value !== "none"
    && fe.containerorchestrationsolution.value.split(",").map((container) => 
      fe.containerOrchestrationList.push(container));

    for (var i=0; i<=100; i++){
      fe.nodeArray.push(i);
    }

    for (var i=30; i<=150; i++){
      fe.volumeSize.push(i);
    }

    { networkDataList && networkDataList.length > 0 && networkDataList.map((item) =>
      fe.networkDataList.push(item)
    )};

    setSelectedEKSRegion(fe.eks.region && fe.eks.region.value);

    setSelectedECSRegion(fe.ecs.region && fe.ecs.region.value);
    
    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploycontainerplatform`]: formData.deploycontainerplatform.value,    
    };

    if(formData.deploycontainerplatform.value === "yes")
    {
        be[`${PREFIX}/deployscproduct`] = formData.deployscproduct.value || "yes";
        if((formData.containerOrchestrationList) && (formData.containerOrchestrationList.length !== 0) && !exists("containerOrchestrationList","none"))
          formData.containerorchestrationsolution.value = formData.containerOrchestrationList.join(",");
        else
        {
          if(formData.containerorchestrationsolution)
            formData.containerorchestrationsolution.value = "none";
        }
        be[`${PREFIX}/containerorchestrationsolution`] = formData.containerorchestrationsolution ? formData.containerorchestrationsolution.value : "none";

        be[`${PREFIX}/newrelic/deployintegration`] = formData.newrelic.deployintegration.value;
        if(formData.newrelic.deployintegration && formData.newrelic.deployintegration.value === "yes")
        {
           if(formData.newrelic.newreliclicensekey.isValid &&  formData.newrelic.newreliclicensekey.value !== "")
              be[`${PREFIX}/newrelic/newreliclicensekey`] = formData.newrelic.newreliclicensekey.value;
        }
        if(formData.containerorchestrationsolution.value !== "") {
        if(exists("containerOrchestrationList", "eks"))
        {
            if(formData.eks.deploycentralizedcontainercluster.value !== "")
            be[`${PREFIX}/eks/deploycentralizedcontainercluster`] = formData.eks.deploycentralizedcontainercluster.value;

            if(formData.eks.deploycentralizedcontainercluster.value === "yes") {
                if(formData.eks.accountid && formData.eks.accountid.value !== "" )
                be[`${PREFIX}/eks/centralizedcontainercluster/accountid`] =  formData.eks.accountid.value;
                if(formData.eks.region && formData.eks.region.value !== "" )
                be[`${PREFIX}/eks/centralizedcontainercluster/region`] = formData.eks.region.value;
                if(formData.eks.numberofavailabilityzones && formData.eks.numberofavailabilityzones.value !== "")
                be[`${PREFIX}/eks/centralizedcontainercluster/numberofavailabilityzones`] = formData.eks.numberofavailabilityzones.value;
                if(formData.eks.minclusternodes && formData.eks.minclusternodes.value !== "")
                be[`${PREFIX}/eks/centralizedcontainercluster/minclusternodes`] = formData.eks.minclusternodes.value;
                if(formData.eks.maxclusternodes && formData.eks.maxclusternodes.value !== "")
                be[`${PREFIX}/eks/centralizedcontainercluster/maxclusternodes`] = formData.eks.maxclusternodes.value;
                if(formData.eks.nodesinstancetype && formData.eks.nodesinstancetype.value !== "")
                be[`${PREFIX}/eks/centralizedcontainercluster/nodesinstancetype`] = formData.eks.nodesinstancetype.value;
                if(formData.eks.nodevolumesize && formData.eks.nodevolumesize.value !== "")
                be[`${PREFIX}/eks/centralizedcontainercluster/nodevolumesize`] = formData.eks.nodevolumesize.value;
                if(formData.eks.networksegment && formData.eks.networksegment.value !== "")
                be[`${PREFIX}/eks/centralizedcontainercluster/networksegment`] = formData.eks.networksegment.value;
                if(formData.newrelic.deployintegration && formData.newrelic.deployintegration.value === "yes" &&
                formData.eks.newrelicintegration && formData.eks.newrelicintegration.value !== "")
                be[`${PREFIX}/eks/centralizedcontainercluster/newrelicintegration`] = formData.eks.newrelicintegration.value;
                
            }
        }

        if(exists("containerOrchestrationList", "ecs"))
        {
            if(formData.ecs.deploycentralizedcontainercluster.value !== "")
            be[`${PREFIX}/ecs/deploycentralizedcontainercluster`] = formData.ecs.deploycentralizedcontainercluster.value;

            if(formData.ecs.deploycentralizedcontainercluster.value === "yes") {
                if(formData.ecs.accountid && formData.ecs.accountid.value !== "" )
                be[`${PREFIX}/ecs/centralizedcontainercluster/accountid`] =  formData.ecs.accountid.value;
                if(formData.ecs.region && formData.ecs.region.value !== "" )
                be[`${PREFIX}/ecs/centralizedcontainercluster/region`] = formData.ecs.region.value;
                if(formData.ecs.numberofavailabilityzones && formData.ecs.numberofavailabilityzones.value !== "")
                be[`${PREFIX}/ecs/centralizedcontainercluster/numberofavailabilityzones`] = formData.ecs.numberofavailabilityzones.value;
                if(formData.ecs.mincontainerinstances && formData.ecs.mincontainerinstances.value !== "")
                be[`${PREFIX}/ecs/centralizedcontainercluster/mincontainerinstances`]  = formData.ecs.mincontainerinstances.value;
                if(formData.ecs.maxcontainerinstances && formData.ecs.maxcontainerinstances.value !== "")
                be[`${PREFIX}/ecs/centralizedcontainercluster/maxcontainerinstances`] = formData.ecs.maxcontainerinstances.value;
                if(formData.ecs.containerinstancetype && formData.ecs.containerinstancetype.value !== "")
                be[`${PREFIX}/ecs/centralizedcontainercluster/containerinstancetype`] = formData.ecs.containerinstancetype.value;
                if(formData.ecs.containervolumesize && formData.ecs.containervolumesize.value !== "")
                be[`${PREFIX}/ecs/centralizedcontainercluster/containervolumesize`] = formData.ecs.containervolumesize.value;
                if(formData.ecs.networksegment && formData.ecs.networksegment.value !== "")
                be[`${PREFIX}/ecs/centralizedcontainercluster/networksegment`] = formData.ecs.networksegment.value;
                if( formData.newrelic.deployintegration && formData.newrelic.deployintegration.value === "yes" &&
                  formData.ecs.newrelicintegration && formData.ecs.newrelicintegration.value !== "")
                be[`${PREFIX}/ecs/centralizedcontainercluster/newrelicintegration`] = formData.ecs.newrelicintegration.value; 
            }
        }
      }

        be[`${PREFIX}/ecr/deploycentralizedcontainerregistry`] = formData.ecr.deploycentralizedcontainerregistry.value;
        
        if(formData.ecr.deploycentralizedcontainerregistry.value === "yes")
        {
            if(formData.ecr.accountid && formData.ecr.accountid.value !== "" )
            be[`${PREFIX}/ecr/centralizedcontainerregistry/accountid`] =  formData.ecr.accountid.value;
            if(formData.ecr.region && formData.ecr.region.value !== "" )
            be[`${PREFIX}/ecr/centralizedcontainerregistry/region`] = formData.ecr.region.value; 
            if(formData.ecr.shareregistrywithorg && formData.ecr.shareregistrywithorg.value !== "" )   
            be[`${PREFIX}/ecr/centralizedcontainerregistry/shareregistrywithorg`] = formData.ecr.shareregistrywithorg.value;   
        } 
    }
    else if(formData.deploycontainerplatform.value === "no")
    {
      be[`${PREFIX}/containerorchestrationsolution`] = "none";
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const onFieldChange = (value, key1, key2) => {
    setErrorMsg(null);
    setIsDirty(true);

    if (key2) {
      setFormData((state) =>
        update(state, { [key1]: { [key2]: { value: { $set: value } } } })
      );
    } else {
      setFormData((state) =>
        update(state, { [key1]: { value: { $set: value } } })
      );
    }
  };

  const onFieldBlur = (validateFunc, value, key1, key2) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      if (key2) {
        setFormData((state) =>
          update(state, {
            [key1]: { [key2]: { isValid: { $set: errorMsg === undefined } } },
          })
        );
      } else {
        setFormData((state) =>
          update(state, {
            [key1]: { isValid: { $set: errorMsg === undefined } },
          })
        );
      }

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      if (key2) {
        setFormData((state) =>
          update(state, {
            [key1]: { [key2]: { isValid: { $set: false } } },
          })
        );
      } else {
        setFormData((state) =>
          update(state, {
            [key1]: { isValid: { $set: false } },
          })
        );
      }
    }
  };


  const exists = (array, value) => {
    return formData[array].indexOf(value) !== -1;
  };

  const add = (array, value) => {
    setIsDirty(true);
    setFormData((state) =>
      update(state, { [array]: { $push: [value] } })
    );
  };

  const remove = (array, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
          [array]: { $splice: [[state[array].indexOf(value), 1]] },
      })
    );
  };

  const modifyArray = (array, value) => {
    if (exists(array, value)) {
      remove(array, value);
    } else {
      add(array, value);
    }
  };

  
  useHideLeftMenu();
  useUnload(isDirty);


  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Infrastructure</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column" >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Infrastructure"
                isDirty={isDirty}
                dashboardRoute={ROUTES.INFRASTRUCTURE_DIRECTORIES}
                designRoute={ROUTES.DESIGN_INFRASTRUCTURE}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 p-0">

              <div className="row mb-2 d-flex align-items-center">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3>Container Platform</h3>
                  </div>
                  <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div className="d-inline-flex yes-no-area align-items-center">
                     <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                     Deploy Container Platform
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                        <RadioGroup
                          field="deploycontainerplatform"
                          value={formData.deploycontainerplatform.value}
                          onChange={(key, value) => {
                            setIsDirty(true);
                             
                            setFormData((state) =>
                              update(state, {
                                [key]: {
                                  value: { $set: value },
                                },
                              })
                            );
                          }}
                          isLarge={true}
                          lockToYes={false}
                        ></RadioGroup>
                      </div>                  
                     </div>
                  </div>
              
               </div>
               {empty && (
                  <NoInitialData />
                )}
                {formData.deploycontainerplatform.value === "yes" && (
                  <div className="show-hide-content">  
                     <div className="d-inline-flex yes-no-area align-items-center">
                     <div className="content-label mr-xl-5 mr-lg-3 mr-1 my-3 font-12">
                     Deploy Container Platform Provisioning SC Products
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                        <RadioGroup
                          field="deployscproduct"
                          value={formData.deployscproduct.value}
                          onChange={(key, value) => {
                            setIsDirty(true);
                        
                            setFormData((state) =>
                              update(state, {
                                [key]: {
                                  value: { $set: value },
                                },
                              })
                            );
                          }}
                          isLarge={true}
                          lockToYes={true}
                        ></RadioGroup>
                      </div>                  
                     </div>

                    <div className="mainContentArea col-12 flex-wrap py-2 row">
                       
                    <div className="d-inline-flex flex-wrap">
                        <label className="font-weight-bold font-12 d-flex p-0 mr-2 my-2 col-md-3">
                        Container Orchestration Solution
                        </label>

                        {formData.containers && formData.containers.length !== 0 && 
                        formData.containers.map((container,idxContainer) => (
                        <div key={idxContainer}
                        className="one mr-2  form-line w-md-250 my-1 py-2 d-inline-flex px-2">
                            <label className="switch mb-0"> 
                                 <input type="checkbox" 
                                 name= {`${PREFIX}/${idxContainer}/${container}`} 
                                 value = {container}
                                 checked = {exists("containerOrchestrationList",`${container}`)}
                                 onChange={() => {
                                  modifyArray("containerOrchestrationList",`${container}`)}
                                 }
                                ></input>
                                <span className="slider round"></span> 
                            </label>
                            <span className="switchLabel">{container.toUpperCase()}</span>
                        </div>
                         ) )}
                        </div>
                    </div>    

                      <div className="show-hide-sub-content">
                          <div className="d-flex flex-column mainContentArea  pl-3 pr-3 py-1 my-1">
                          <div className="d-inline-flex align-items-center row">
                              <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">Deploy New Relic Integration</span>
                              </div>
                              <div className="col-md-9 flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                              <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                                  <label
                                  className={`createSubElementBtn btn btn-link btn-linkYes ${
                                  formData.newrelic.deployintegration && formData.newrelic.deployintegration.value ===
                                      "yes"
                                      ? "active"
                                      : ""
                                  }`}
                                  >
                                  <input
                                      type="radio"
                                      name="deployintegration"
                                      value="yes"
                                      checked={
                                      formData.newrelic.deployintegration && formData.newrelic.deployintegration
                                          .value === "yes" 
                                      }
                                      onChange={(e) => {
                                      onFieldChange(
                                          "yes",
                                          "newrelic",
                                          "deployintegration"
                                          );     
                                      }}
                                  />{" "}
                                  YES
                                  </label>
                                  <label
                                  className={`dontCreateSubElementBtn btn btn-link btn-linkNo ${
                                      formData.newrelic.deployintegration && formData.newrelic.deployintegration.value ===
                                      "no"
                                      ? "active"
                                      : ""
                                  }`}
                                  >
                                  <input
                                      type="radio"
                                      name="deployintegration"
                                      checked={
                                          formData.newrelic.deployintegration && formData.newrelic.deployintegration
                                          .value === "no"
                                      }
                                      onChange={(e) => {
                                      onFieldChange(
                                          "no",
                                          "newrelic",
                                          "deployintegration"
                                          );      
                                      }}
                                  />{" "}
                                  NO
                                  </label>
                              </div>
                           </div></div>
                         </div>    
                         
                         {formData.newrelic.deployintegration && formData.newrelic.deployintegration.value === "yes" && (
                         <div className="show-hide-sub-content">  
                          <div className="d-flex flex-column py-1 my-1"> 
                                <div className="d-inline-flex align-items-center row">
                                    <div className="col-md-3 my-1 p-0">
                                        <span className="mb-0 font-12">
                                         New Relic License Key
                                        </span>
                                    </div>
                                    <div className="col-md-6 flex-wrap d-inline-flex">
                                        <div className="">
                                            <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                            <button
                                          disabled={disabled}
                                          className="showableInput"
                                          type="button"
                                          onClick={() => setShowPwd(!showPwd)}
                                        ></button>
                                        <input
                                          type={showPwd ? "text" : "password"}
                                          className={` w-100 form-control  bg-transparent ${
                                            formData
                                              .newrelic
                                              .newreliclicensekey
                                              .value !== "" &&
                                            formData
                                            .newrelic
                                            .newreliclicensekey
                                              .isValid === false
                                              ? "invalid"
                                              : ""
                                          }`}
                                          placeholder=""
                                          value={
                                            formData
                                              .newrelic
                                              .newreliclicensekey
                                              .value
                                          }
                                          onChange={(e) =>
                                            onFieldChange(
                                              e.target.value,
                                              "newrelic",
                                              "newreliclicensekey"
                                            )
                                          }
                                          onBlur={(e) =>
                                            onFieldBlur(
                                              validateNewRelicLicenseKey,
                                              e.target.value,
                                             "newrelic",
                                             "newreliclicensekey"
                                            )
                                          }
                                        />
                                           </div>
                                            </div>
                                        </div>   
                                </div>
                            
                              
                             </div>
                         </div>                                          
                        )}
                        
                      </div>
                      </div>
                         

                  <>
                    { formData.containerOrchestrationList &&  exists("containerOrchestrationList", "eks") && (
                    <div className="show-hide-sub-content">
                        <div className="d-flex flex-column mainContentArea  pl-3 pr-3 py-1 my-1">
                        <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                            <span className="mb-0 font-12">Create Centralized EKS Container Cluster</span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                            <div className="mr-3 my-1">
                            <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                            <label
                                className={`createSubElementBtn btn btn-link btn-linkYes ${
                                formData.eks.deploycentralizedcontainercluster && formData.eks.deploycentralizedcontainercluster.value ===
                                    "yes"
                                    ? "active"
                                    : ""
                                }`}
                                >
                                <input
                                    type="radio"
                                    name="deploycentralizedcontainercluster"
                                    value="yes"
                                    checked={
                                    formData.eks.deploycentralizedcontainercluster && formData.eks.deploycentralizedcontainercluster
                                        .value === "yes" 
                                    }
                                    onChange={(e) => {
                                    onFieldChange(
                                        "yes",
                                        "eks",
                                        "deploycentralizedcontainercluster"
                                        );     
                                    }}
                                />{" "}
                                YES
                                </label>
                            <label
                                className={`dontCreateSubElementBtn btn btn-link btn-linkNo ${
                                    formData.eks.deploycentralizedcontainercluster && formData.eks.deploycentralizedcontainercluster.value ===
                                    "no"
                                    ? "active"
                                    : ""
                                }`}
                                >
                                <input
                                    type="radio"
                                    name="deploycentralizedcontainercluster"
                                    checked={
                                        formData.eks.deploycentralizedcontainercluster && formData.eks.deploycentralizedcontainercluster
                                        .value === "no"
                                    }
                                    onChange={(e) => {
                                    onFieldChange(
                                        "no",
                                        "eks",
                                        "deploycentralizedcontainercluster"
                                        );      
                                    }}
                                />{" "}
                                NO
                                </label>
                            </div>
                         </div></div>
                       </div>    
                        {formData.eks.deploycentralizedcontainercluster && formData.eks.deploycentralizedcontainercluster.value === "yes" && (
                        <div className="show-hide-sub-content">  
                            <div className="d-flex flex-column pl-3 pr-5 py-1 my-1"> 
                            <div className="d-inline-flex align-items-center row">
                                <div className="col-md-3 my-1 p-0">
                                    <span className="mb-0 font-12">
                                    AWS Account
                                    </span>
                                </div>
                                { (accounts && (accounts.security
                                                .concat(accounts.shared)).length === 0) ? (
                                  <div className="col-md-6 flex-wrap d-inline-flex">
                                     <span className="warning">
                                     This section depends on the Platform Accounts information in
                                     Account Framework section that is yet to be provided
                                   </span>
                                   </div>
                                 ) : (
                                  <>
                                    <div className="col-md-6 flex-wrap d-inline-flex">
                                    <div className="my-1">
                                        <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                        <select
                                        className="form-control gray"
                                        value={formData.eks.accountid.value }
                                            onChange={(e) => {
                                            onFieldChange(
                                            e.target.value,
                                            "eks",
                                            "accountid"
                                            );                                       
                                        }} >   
                                        <option value="">Select</option>
                                            { accounts.security
                                                .concat(accounts.shared)
                                                .map((account, idxAccount) => (
                                                <option key={idxAccount} value={account}>
                                                {account}
                                                </option>
                                            )
                                            )}
                                    </select>
                                            </div>
                                        </div>
                                    </div>   
                                 </>
                                )}
                           </div>
                           
                            <div className="d-inline-flex align-items-center row">
                                <div className="col-md-3 my-1 p-0">
                                    <span className="mb-0 font-12">
                                    AWS Region
                                    </span>
                                </div>
                                {  (regions && regions.length === 0) ? (
                                   <div className="col-md-6 flex-wrap d-inline-flex">
                                   <span className="warning">
                                   This section depends on the AWS Regions information in
                                   AWS Environment section that is yet to be provided
                                 </span>
                                 </div>
                               ) : (
                                  <>
                                 <div className="col-md-6 flex-wrap d-inline-flex">
                                    <div className="my-1">
                                        <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                        <select
                                        className="form-control gray"
                                        value={formData.eks.region.value }
                                            onChange={(e) => {
                                            onFieldChange(
                                            e.target.value,
                                            "eks",
                                            "region"
                                            ); 
                                            
                                            setSelectedEKSRegion(e.target.value);                                  
                                        }} >   
                                        <option value="">Select</option>
                                            { 
                                                regions.map((region, idxRegion) => (
                                                <option key={idxRegion} value={region}>
                                                {region}
                                                </option>
                                            )
                                            )}
                                             </select>
                                            </div>
                                        </div>
                                    </div>   
                                 </>
                                )}

                                  </div>
                           
                            <div className="d-inline-flex align-items-center row">
                                <div className="col-md-3 my-1 p-0">
                                    <span className="mb-0 font-12">
                                    Number of AZs for Cluster 
                                    </span>
                                </div>
                                <div className="col-md-6 flex-wrap d-inline-flex">
                                    <div className="my-1">
                                        <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                        <select
                                        className="form-control gray"
                                        value={formData.eks.numberofavailabilityzones.value }
                                            onChange={(e) => {
                                            onFieldChange(
                                            e.target.value,
                                            "eks",
                                            "numberofavailabilityzones"
                                            );                                       
                                        }} >   
                                        <option value="">Select</option>
                                        <option key="2" value="2">
                                            Two
                                        </option>
                                        <option key="3" value="3">
                                            Three
                                        </option>
                                             </select>
                                            </div>
                                        </div>
                                    </div>   
                            </div>
                           
                            <div className="d-inline-flex align-items-center row">
                                <div className="col-md-3 my-1 p-0">
                                    <span className="mb-0 font-12">
                                      Minimum Number of Cluster Nodes
                                    </span>
                                </div>
                                <div className="col-md-6 flex-wrap d-inline-flex">
                                    <div className="my-1">
                                        <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                        <select
                                        className="form-control gray"
                                        value={formData.eks.minclusternodes.value }
                                            onChange={(e) => {
                                            onFieldChange(
                                            e.target.value,
                                            "eks",
                                            "minclusternodes"
                                            );                                       
                                        }} >   
                                        <option value="">Select</option>
                                         {
                                           formData.nodeArray && formData.nodeArray.length > 0 && formData.nodeArray.map((item) =>
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                            )} 
                                             </select>
                                            </div>
                                        </div>
                                    </div>   
                            </div>
                           
                            <div className="d-inline-flex align-items-center row">
                                <div className="col-md-3 my-1 p-0">
                                    <span className="mb-0 font-12">
                                      Maximum Number of Cluster Nodes
                                    </span>
                                </div>
                                <div className="col-md-6 flex-wrap d-inline-flex">
                                    <div className="my-1">
                                        <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                        <select
                                        className="form-control gray"
                                        value={formData.eks.maxclusternodes.value }
                                            onChange={(e) => {
                                            onFieldChange(
                                            e.target.value,
                                            "eks",
                                            "maxclusternodes"
                                            );                                       
                                        }} >   
                                        <option value="">Select</option>
                                         {
                                           formData.nodeArray && formData.nodeArray.length > 0 && formData.nodeArray.map((item) =>
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                            )} 
                                             </select>
                                            </div>
                                        </div>
                                    </div>   
                            </div>
                           
                            <div className="d-inline-flex align-items-center row">
                                <div className="col-md-3 my-1 p-0">
                                    <span className="mb-0 font-12">
                                      Cluster Nodes Instance Type
                                    </span>
                                </div>
                                <div className="col-md-6 flex-wrap d-inline-flex">
                                    <div className="my-1">
                                        <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                        <select
                                        className="form-control gray"
                                        value={formData.eks.nodesinstancetype.value }
                                            onChange={(e) => {
                                            onFieldChange(
                                            e.target.value,
                                            "eks",
                                            "nodesinstancetype"
                                            );                                       
                                        }} >   
                                        <option value="">Select</option>
                                         {
                                           eksInstanceTypes && eksInstanceTypes.length > 0 && eksInstanceTypes.sort().map((instanceType) =>
                                            <option key={instanceType} value={instanceType}>
                                                {instanceType}
                                            </option>
                                            )} 
                                             </select>
                                            </div>
                                        </div>
                                    </div>   
                            </div>
                           
                            <div className="d-inline-flex align-items-center row">
                                <div className="col-md-3 my-1 p-0">
                                    <span className="mb-0 font-12">
                                    Cluster Node Volume Size
                                    </span>
                                </div>
                                <div className="col-md-6 flex-wrap d-inline-flex">
                                    <div className="my-1">
                                        <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                        <select
                                        className="form-control gray"
                                        value={formData.eks.nodevolumesize.value }
                                            onChange={(e) => {
                                            onFieldChange(
                                            e.target.value,
                                            "eks",
                                            "nodevolumesize"
                                            );                                       
                                        }} >   
                                        <option value="">Select</option>
                                         {
                                           formData.volumeSize && formData.volumeSize.length > 0 && formData.volumeSize.map((size) =>
                                            <option key={size} value={size}>
                                                {size}
                                            </option>
                                            )} 
                                             </select>
                                            </div>
                                        </div>
                                    </div>   
                            </div>
                           
                            <div className="d-inline-flex align-items-center row">
                                <div className="col-md-3 my-1 p-0">
                                    <span className="mb-0 font-12">
                                    Network Segment
                                    </span>
                                </div>
                                { (formData.networkDataList && formData.networkDataList.length === 0) ? (
                                  <div className="col-md-6 flex-wrap d-inline-flex">
                                     <span className="warning">
                                     This section depends on the Network Segment information in
                                     InterVPC Connectivity section that is yet to be provided
                                   </span>
                                   </div>
                                 ) : (
                                  <>
                                <div className="col-md-6 flex-wrap d-inline-flex">
                                    <div className="my-1">
                                        <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                        <select
                                        className="form-control gray"
                                        value={formData.eks.networksegment.value }
                                            onChange={(e) => {
                                            onFieldChange(
                                            e.target.value,
                                            "eks",
                                            "networksegment"
                                            );                                       
                                        }} >   
                                        <option value="">Select</option>
                                         {
                                           formData.networkDataList && formData.networkDataList.length > 0 && formData.networkDataList.map((network) =>
                                            <option key={network} value={network}>
                                                {network}
                                            </option>
                                            )} 
                                             </select>
                                            </div>
                                        </div>
                                    </div>   
                                  </> 
                                  ) }
                            </div>
                           
                            <>
                           {formData.newrelic.deployintegration && formData.newrelic.deployintegration.value === "yes" && (                        
                            <div className="d-inline-flex align-items-center row">
                                    <div className="col-md-3 my-1 p-0">
                                        <span className="mb-0 font-12">
                                           New Relic Integration
                                        </span>
                                    </div>
                                    <div className="col-md-6 my-1 flex-wrap d-inline-flex">
                                         <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                                  <label
                                  className={`createSubElementBtn btn btn-link btn-linkYes ${
                                  formData.eks.newrelicintegration && formData.eks.newrelicintegration.value ===
                                      "Yes"
                                      ? "active"
                                      : ""
                                  }`}
                                  >
                                  <input
                                      type="radio"
                                      name="newrelicintegration"
                                      value="Yes"
                                      checked={
                                      formData.eks.newrelicintegration && formData.eks.newrelicintegration
                                          .value === "Yes" 
                                      }
                                      onChange={(e) => {
                                      onFieldChange(
                                          "Yes",
                                          "eks",
                                          "newrelicintegration"
                                          );     
                                      }}
                                  />{" "}
                                  YES
                                  </label>
                                  <label
                                  className={`dontCreateSubElementBtn btn btn-link btn-linkNo ${
                                      formData.eks.newrelicintegration && formData.eks.newrelicintegration.value ===
                                      "No"
                                      ? "active"
                                      : ""
                                  }`}
                                  >
                                  <input
                                      type="radio"
                                      name="newrelicintegration"
                                      checked={
                                          formData.eks.newrelicintegration && formData.eks.newrelicintegration
                                          .value === "No"
                                      }
                                      onChange={(e) => {
                                      onFieldChange(
                                          "No",
                                          "eks",
                                          "newrelicintegration"
                                          );      
                                      }}
                                  />{" "}
                                  NO
                                  </label>
                              </div>
                           
                                    </div>   
                                </div>                           
                           )}
                           </>
                            </div>
                        </div>
                      
                        )}
             
                    </div>
                    </div>
                    )}
                    </>

                    <>
                    { formData.containerOrchestrationList && exists("containerOrchestrationList", "ecs") && (
                          <div className="show-hide-sub-content">
                          <div className="d-flex flex-column mainContentArea  pl-3 pr-3 py-1 my-1">
                          <div className="d-inline-flex align-items-center row">
                              <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">Create Centralized ECS Container Cluster</span>
                              </div>
                              <div className="col-md-9 flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                              <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                                  <label
                                  className={`createSubElementBtn btn btn-link btn-linkYes ${
                                  formData.ecs.deploycentralizedcontainercluster && formData.ecs.deploycentralizedcontainercluster.value ===
                                      "yes"
                                      ? "active"
                                      : ""
                                  }`}
                                  >
                                  <input
                                      type="radio"
                                      name="deploycentralizedcontainercluster"
                                      value="yes"
                                      checked={
                                      formData.ecs.deploycentralizedcontainercluster && formData.ecs.deploycentralizedcontainercluster
                                          .value === "yes" 
                                      }
                                      onChange={(e) => {
                                      onFieldChange(
                                          "yes",
                                          "ecs",
                                          "deploycentralizedcontainercluster"
                                          );     
                                      }}
                                  />{" "}
                                  YES
                                  </label>
                                  <label
                                  className={`dontCreateSubElementBtn btn btn-link btn-linkNo ${
                                      formData.ecs.deploycentralizedcontainercluster && formData.ecs.deploycentralizedcontainercluster.value ===
                                      "no"
                                      ? "active"
                                      : ""
                                  }`}
                                  >
                                  <input
                                      type="radio"
                                      name="deploycentralizedcontainercluster"
                                      checked={
                                          formData.ecs.deploycentralizedcontainercluster && formData.ecs.deploycentralizedcontainercluster
                                          .value === "no"
                                      }
                                      onChange={(e) => {
                                      onFieldChange(
                                          "no",
                                          "ecs",
                                          "deploycentralizedcontainercluster"
                                          );      
                                      }}
                                  />{" "}
                                  NO
                                  </label>
                              </div>
                           </div></div>
                         </div>    
                         
                         {formData.ecs.deploycentralizedcontainercluster && formData.ecs.deploycentralizedcontainercluster.value === "yes" && (
                         <div className="show-hide-sub-content">  
                          <div className="d-flex flex-column pl-3 pr-5 py-1 my-1"> 
                                <div className="d-inline-flex align-items-center row">
                                    <div className="col-md-3 my-1 p-0">
                                        <span className="mb-0 font-12">
                                        AWS Account
                                        </span>
                                    </div>
                                    { (accounts && (accounts.security
                                                .concat(accounts.shared)).length === 0) ? (
                                  <div className="col-md-6 flex-wrap d-inline-flex">
                                     <span className="warning">
                                     This section depends on the Platform Accounts information in
                                     Account Framework section that is yet to be provided
                                   </span>
                                   </div>
                                 ) : (
                                  <>
                                    <div className="col-md-6 flex-wrap d-inline-flex">
                                        <div className="my-1">
                                            <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                            <select
                                            className="form-control gray"
                                            value={formData.ecs.accountid.value }
                                                onChange={(e) => {
                                                onFieldChange(
                                                e.target.value,
                                                "ecs",
                                                "accountid"
                                                );                                       
                                            }} >   
                                            <option value="">Select</option>
                                                { accounts.security
                                                    .concat(accounts.shared)
                                                    .map((account, idxAccount) => (
                                                    <option key={idxAccount} value={account}>
                                                    {account}
                                                    </option>
                                                )
                                                )}
                                        </select>
                                                </div>
                                            </div>
                                        </div>   
                                 </> )}
                                </div>
                            
                                <div className="d-inline-flex align-items-center row">
                                    <div className="col-md-3 my-1 p-0">
                                        <span className="mb-0 font-12">
                                        AWS Region
                                        </span>
                                    </div>
                                    {  (regions && regions.length === 0) ? (
                                   <div className="col-md-6 flex-wrap d-inline-flex">
                                   <span className="warning">
                                   This section depends on the AWS Regions information in
                                   AWS Environment section that is yet to be provided
                                 </span>
                                 </div>
                               ) : (
                                  <>
                                    <div className="col-md-6 flex-wrap d-inline-flex">
                                        <div className="my-1">
                                            <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                            <select
                                            className="form-control gray"
                                            value={formData.ecs.region.value }
                                                onChange={(e) => {
                                                onFieldChange(
                                                e.target.value,
                                                "ecs",
                                                "region"
                                                );   
                                                setSelectedECSRegion(e.target.value);                                      
                                            }} >   
                                            <option value="">Select</option>
                                                { 
                                                    regions.map((region, idxRegion) => (
                                                    <option key={idxRegion} value={region}>
                                                    {region}
                                                    </option>
                                                )
                                                )}
                                                </select>
                                                </div>
                                            </div>
                                        </div>   
                                </> )}
                                </div>
                            
                                <div className="d-inline-flex align-items-center row">
                                    <div className="col-md-3 my-1 p-0">
                                        <span className="mb-0 font-12">
                                        Number of AZs for Cluster 
                                        </span>
                                    </div>
                                    <div className="col-md-6 flex-wrap d-inline-flex">
                                        <div className="my-1">
                                            <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                            <select
                                            className="form-control gray"
                                            value={formData.ecs.numberofavailabilityzones.value }
                                                onChange={(e) => {
                                                onFieldChange(
                                                e.target.value,
                                                "ecs",
                                                "numberofavailabilityzones"
                                                );                                       
                                            }} >   
                                            <option value="">Select</option>
                                            <option key="2" value="2">
                                                Two
                                            </option>
                                            <option key="3" value="3">
                                                Three
                                            </option>
                                                </select>
                                                </div>
                                            </div>
                                        </div>   
                                </div>
                            
                                <div className="d-inline-flex align-items-center row">
                                <div className="col-md-3 my-1 p-0">
                                    <span className="mb-0 font-12">
                                       Minimum Number of Container Instances
                                    </span>
                                </div>
                                <div className="col-md-6 flex-wrap d-inline-flex">
                                    <div className="my-1">
                                        <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                        <select
                                        className="form-control gray"
                                        value={formData.ecs.mincontainerinstances.value }
                                            onChange={(e) => {
                                            onFieldChange(
                                            e.target.value,
                                            "ecs",
                                            "mincontainerinstances"
                                            );                                       
                                        }} >   
                                        <option value="">Select</option>
                                         {
                                           formData.nodeArray && formData.nodeArray.length > 0 && formData.nodeArray.map((item) =>
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                            )} 
                                             </select>
                                            </div>
                                        </div>
                                    </div>   
                            </div>
                           
                            <div className="d-inline-flex align-items-center row">
                                <div className="col-md-3 my-1 p-0">
                                    <span className="mb-0 font-12">
                                    Maximum Number of Container Instances
                                    </span>
                                </div>
                                <div className="col-md-6 flex-wrap d-inline-flex">
                                    <div className="my-1">
                                        <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                        <select
                                        className="form-control gray"
                                        value={formData.ecs.maxcontainerinstances.value }
                                            onChange={(e) => {
                                            onFieldChange(
                                            e.target.value,
                                            "ecs",
                                            "maxcontainerinstances"
                                            );                                       
                                        }} >   
                                        <option value="">Select</option>
                                         {
                                           formData.nodeArray && formData.nodeArray.length > 0 && formData.nodeArray.map((item) =>
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                            )} 
                                             </select>
                                            </div>
                                        </div>
                                    </div>   
                            </div>
                           
                            <div className="d-inline-flex align-items-center row">
                                <div className="col-md-3 my-1 p-0">
                                    <span className="mb-0 font-12">
                                    Container Instances Instance Type 
                                    </span>
                                </div>
                                <div className="col-md-6 flex-wrap d-inline-flex">
                                    <div className="my-1">
                                        <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                        <select
                                        className="form-control gray"
                                        value={formData.ecs.containerinstancetype.value }
                                            onChange={(e) => {
                                            onFieldChange(
                                            e.target.value,
                                            "ecs",
                                            "containerinstancetype"
                                            );                          
                                        }} >   
                                        <option value="">Select</option>
                                         {
                                           ecsInstanceTypes && ecsInstanceTypes.length > 0 && ecsInstanceTypes.sort().map((instanceType) =>
                                            <option key={instanceType} value={instanceType}>
                                                {instanceType}
                                            </option>
                                            )} 
                                             </select>
                                            </div>
                                        </div>
                                    </div>   
                            </div>
                           
                            <div className="d-inline-flex align-items-center row">
                                <div className="col-md-3 my-1 p-0">
                                    <span className="mb-0 font-12">
                                    Container Instance Volume Size
                                    </span>
                                </div>
                                <div className="col-md-6 flex-wrap d-inline-flex">
                                    <div className="my-1">
                                        <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                        <select
                                        className="form-control gray"
                                        value={formData.ecs.containervolumesize.value }
                                            onChange={(e) => {
                                            onFieldChange(
                                            e.target.value,
                                            "ecs",
                                            "containervolumesize"
                                            );                                       
                                        }} >   
                                        <option value="">Select</option>
                                         {
                                           formData.volumeSize && formData.volumeSize.length > 0 && formData.volumeSize.map((size) =>
                                            <option key={size} value={size}>
                                                {size}
                                            </option>
                                            )} 
                                             </select>
                                            </div>
                                        </div>
                                    </div>   
                            </div>
                           
                            <div className="d-inline-flex align-items-center row">
                                <div className="col-md-3 my-1 p-0">
                                    <span className="mb-0 font-12">
                                    Network Segment
                                    </span>
                                </div>
                                { (formData.networkDataList && formData.networkDataList.length === 0) ? (
                                  <div className="col-md-6 flex-wrap d-inline-flex">
                                     <span className="warning">
                                     This section depends on the Network Segment information in
                                     InterVPC Connectivity section that is yet to be provided
                                   </span>
                                   </div>
                                 ) : (
                                  <>
                                <div className="col-md-6 flex-wrap d-inline-flex">
                                    <div className="my-1">
                                        <div className="w-250 bg-gray border-10 p-relative d-inline-flex align-items-center"> 
                                        <select
                                        className="form-control gray"
                                        value={formData.ecs.networksegment.value }
                                            onChange={(e) => {
                                            onFieldChange(
                                            e.target.value,
                                            "ecs",
                                            "networksegment"
                                            );                                       
                                        }} >   
                                        <option value="">Select</option>
                                         {
                                           formData.networkDataList && formData.networkDataList.length > 0 && formData.networkDataList.map((network) =>
                                            <option key={network} value={network}>
                                                {network}
                                            </option>
                                            )} 
                                             </select>
                                            </div>
                                        </div>
                                    </div>   
                               </> )}
                            </div>
                           
                           <>
                           {formData.newrelic.deployintegration && formData.newrelic.deployintegration.value === "yes" && (
                            <div className="d-inline-flex align-items-center row">
                                    <div className="col-md-3 my-1 p-0">
                                        <span className="mb-0 font-12">
                                           New Relic Integration
                                        </span>
                                    </div>
                                    <div className="col-md-6 my-1 flex-wrap d-inline-flex">
                                         <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                                  <label
                                  className={`createSubElementBtn btn btn-link btn-linkYes ${
                                  formData.ecs.newrelicintegration && formData.ecs.newrelicintegration.value ===
                                      "Yes"
                                      ? "active"
                                      : ""
                                  }`}
                                  >
                                  <input
                                      type="radio"
                                      name="ecs_newrelicintegration"
                                      value="Yes"
                                      checked={
                                      formData.ecs.newrelicintegration && formData.ecs.newrelicintegration
                                          .value === "Yes" 
                                      }
                                      onChange={(e) => {
                                      onFieldChange(
                                          "Yes",
                                          "ecs",
                                          "newrelicintegration"
                                          );     
                                      }}
                                  />{" "}
                                  YES
                                  </label>
                                  <label
                                  className={`dontCreateSubElementBtn btn btn-link btn-linkNo ${
                                      formData.ecs.newrelicintegration && formData.ecs.newrelicintegration.value ===
                                      "No"
                                      ? "active"
                                      : ""
                                  }`}
                                  >
                                  <input
                                      type="radio"
                                      name="newrelicintegration"
                                      checked={
                                          formData.ecs.newrelicintegration && formData.ecs.newrelicintegration
                                          .value === "No"
                                      }
                                      onChange={(e) => {
                                      onFieldChange(
                                          "No",
                                          "ecs",
                                          "newrelicintegration"
                                          );      
                                      }}
                                  />{" "}
                                  NO
                                  </label>
                              </div>
                           
                                    </div>   
                                </div>
                           )}
                            </>
                             </div>
                         </div>                                          
                        )}
                        
                      </div>
                      </div>
                      )}
                    </>
               
                    <div className="show-hide-sub-content">
                          <div className="d-flex flex-column mainContentArea  pl-3 pr-3 py-1 my-1">
                          <div className="d-inline-flex align-items-center row">
                              <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">Create Centralized Container Registry</span>
                              </div>
                              <div className="col-md-9 flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                              <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                                  <label
                                  className={`createSubElementBtn btn btn-link btn-linkYes ${
                                  formData.ecr.deploycentralizedcontainerregistry && formData.ecr.deploycentralizedcontainerregistry.value ===
                                      "yes"
                                      ? "active"
                                      : ""
                                  }`}
                                  >
                                  <input
                                      type="radio"
                                      name="deploycentralizedcontainerregistry"
                                      value="yes"
                                      checked={
                                      formData.ecr.deploycentralizedcontainerregistry && formData.ecr.deploycentralizedcontainerregistry
                                          .value === "yes" 
                                      }
                                      onChange={(e) => {
                                      onFieldChange(
                                          "yes",
                                          "ecr",
                                          "deploycentralizedcontainerregistry"
                                          );     
                                      }}
                                  />{" "}
                                  YES
                                  </label>
                                  <label
                                  className={`dontCreateSubElementBtn btn btn-link btn-linkNo ${
                                      formData.ecr.deploycentralizedcontainerregistry && formData.ecr.deploycentralizedcontainerregistry.value ===
                                      "no"
                                      ? "active"
                                      : ""
                                  }`}
                                  >
                                  <input
                                      type="radio"
                                      name="deploycentralizedcontainerregistry"
                                      checked={
                                          formData.ecr.deploycentralizedcontainerregistry && formData.ecr.deploycentralizedcontainerregistry
                                          .value === "no"
                                      }
                                      onChange={(e) => {
                                      onFieldChange(
                                          "no",
                                          "ecr",
                                          "deploycentralizedcontainerregistry"
                                          );      
                                      }}
                                  />{" "}
                                  NO
                                  </label>
                              </div>
                           </div></div>
                         </div>    
                         
                         {formData.ecr.deploycentralizedcontainerregistry && formData.ecr.deploycentralizedcontainerregistry.value === "yes" && (
                         <div className="show-hide-sub-content">  
                          <div className="d-flex flex-column pl-3 pr-5 py-1 my-1"> 
                                <div className="d-inline-flex align-items-center row">
                                    <div className="col-md-3 my-1 p-0">
                                        <span className="mb-0 font-12">
                                        AWS Account
                                        </span>
                                    </div>
                                    { (accounts && (accounts.security
                                                .concat(accounts.shared)).length === 0) ? (
                                  <div className="col-md-6 flex-wrap d-inline-flex">
                                     <span className="warning">
                                     This section depends on the Platform Accounts information in
                                     Account Framework section that is yet to be provided
                                   </span>
                                   </div>
                                 ) : (
                                  <>
                                    <div className="col-md-6 flex-wrap d-inline-flex">
                                        <div className="my-1">
                                            <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                            <select
                                            className="form-control gray"
                                            value={formData.ecr.accountid.value }
                                                onChange={(e) => {
                                                onFieldChange(
                                                e.target.value,
                                                "ecr",
                                                "accountid"
                                                );                                       
                                            }} >   
                                            <option value="">Select</option>
                                                { accounts.security
                                                    .concat(accounts.shared)
                                                    .map((account, idxAccount) => (
                                                    <option key={idxAccount} value={account}>
                                                    {account}
                                                    </option>
                                                )
                                                )}
                                        </select>
                                                </div>
                                            </div>
                                        </div>   
                                  </> )}
                                </div>
                            
                                <div className="d-inline-flex align-items-center row">
                                    <div className="col-md-3 my-1 p-0">
                                        <span className="mb-0 font-12">
                                        AWS Region
                                        </span>
                                    </div>
                                    {  (regions && regions.length === 0) ? (
                                   <div className="col-md-6 flex-wrap d-inline-flex">
                                   <span className="warning">
                                   This section depends on the AWS Regions information in
                                   AWS Environment section that is yet to be provided
                                 </span>
                                 </div>
                               ) : (
                                  <>
                                    <div className="col-md-6 flex-wrap d-inline-flex">
                                        <div className="my-1">
                                            <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                            <select
                                            className="form-control gray"
                                            value={formData.ecr.region.value }
                                                onChange={(e) => {
                                                onFieldChange(
                                                e.target.value,
                                                "ecr",
                                                "region"
                                                );                                       
                                            }} >   
                                            <option value="">Select</option>
                                                { 
                                                    regions.map((region, idxRegion) => (
                                                    <option key={idxRegion} value={region}>
                                                    {region}
                                                    </option>
                                                )
                                                )}
                                                </select>
                                                </div>
                                            </div>
                                        </div>   
                               </>
                               )}
                                </div>
                            
                                <div className="d-inline-flex align-items-center row">
                                    <div className="col-md-3 my-1 p-0">
                                        <span className="mb-0 font-12">
                                       Share Registry With Organization
                                        </span>
                                    </div>
                                    <div className="col-md-6 my-1 flex-wrap d-inline-flex">
                                         <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                                  <label
                                  className={`createSubElementBtn btn btn-link btn-linkYes ${
                                  formData.ecr.shareregistrywithorg && formData.ecr.shareregistrywithorg.value ===
                                      "yes"
                                      ? "active"
                                      : ""
                                  }`}
                                  >
                                  <input
                                      type="radio"
                                      name="shareregistrywithorg"
                                      value="yes"
                                      checked={
                                      formData.ecr.shareregistrywithorg && formData.ecr.shareregistrywithorg
                                          .value === "yes" 
                                      }
                                      onChange={(e) => {
                                      onFieldChange(
                                          "yes",
                                          "ecr",
                                          "shareregistrywithorg"
                                          );     
                                      }}
                                  />{" "}
                                  YES
                                  </label>
                                  <label
                                  className={`dontCreateSubElementBtn btn btn-link btn-linkNo ${
                                      formData.ecr.shareregistrywithorg && formData.ecr.shareregistrywithorg.value ===
                                      "no"
                                      ? "active"
                                      : ""
                                  }`}
                                  >
                                  <input
                                      type="radio"
                                      name="shareregistrywithorg"
                                      checked={
                                          formData.ecr.shareregistrywithorg && formData.ecr.shareregistrywithorg
                                          .value === "no"
                                      }
                                      onChange={(e) => {
                                      onFieldChange(
                                          "no",
                                          "ecr",
                                          "shareregistrywithorg"
                                          );      
                                      }}
                                  />{" "}
                                  NO
                                  </label>
                              </div>
                           
                                    </div>   
                                </div>
                            
                             </div>
                         </div>                                          
                        )}
                        
                      </div>
                      </div>
                    
                 
              </div>
              )} 
              </div>
             </fieldset>
              {console.log(formData)}
              <div>
              <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(ROUTES.INFRASTRUCTURE_VDISOLUTION + "/" + projectId)
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                    VDI Solution
                    </p>
                  </button>
                </div>
             
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};


export default ContainerPlatform;