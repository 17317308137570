export const getArrayValue = (
  array,
  idx,
  lookupKey,
  lookupKeyValue,
  lookupValueKey
) => {
  if (array && array.length > 0) {
    if (idx !== -1) {
      if(array[idx][lookupKey] !== undefined)
      {
        if (typeof array[idx][lookupKey] === "boolean") {
          return array[idx][lookupKey].toString();
        } else {
          return array[idx][lookupKey];
        }
      }
    } else {
      for (const item of array) {
        if ((lookupKey !== undefined) && (item[lookupKey] === lookupKeyValue )) {
          return item[lookupValueKey];
        }
      }
    }
  }
};

export const getArraysArrayValue = (array, lookupKey, lookupKeyValue) => {
  if (array) {
    for (const item of array) {
      if (item[lookupKey] === lookupKeyValue) {
        return item.Values[0].Value;
      }
    }
  }
};

export const getBooleanValue = (item, key) => {
  let keyArray = key.split(".");

  if (keyArray.length > 1) {
    return (
      item.hasOwnProperty(keyArray[0]) &&
      item[keyArray[0]].hasOwnProperty(keyArray[1]) &&
      item[keyArray[0]][keyArray[1]].toString()
    );
  } else {
    return item.hasOwnProperty(key) && item[key].toString();
  }
};

export const getDateValue = (item, format) => {
  if(item !== undefined)
  {
    var dateObject = new Date(item);
    return dateObject.toDateString();
  }
  };

  export const isJSON = (str) => {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
  };

  export const getJSONStr = (value) => {
    var returnVal = value;
    if(isJSON(value))
    {
      returnVal  = JSON.stringify(JSON.parse(value, null,2), null,2);
    }
    else
      returnVal = JSON.stringify(value,null,2);

    return returnVal;
  };

