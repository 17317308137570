import React, { useEffect, useState } from "react";
import { getError } from "../../helpers/errorHelper";

import useAwsAmplify from "../../hooks/useAwsAmplify";

const ClientRemoveForm = ({ close, client }) => {
  const awsAmplify = useAwsAmplify();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const removeClient = async () => {
    try {
      setIsLoading(true);

      const result = await awsAmplify.removeClient(client.ClientID);
      console.log(result);

      setIsLoading(false);

      close(true, "remove", "Successfully deleted the client, associated projects and project data");
    } catch (err) {
      setIsLoading(false);

      const errorMsg = getError(err);
      if (errorMsg === "Client doesn't exist") {
        close(true, "remove", null, errorMsg);
      }
      else{
        setErrorMsg(errorMsg);
      }
    }
  };

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="justify-content-center align-items-center popupArea delete-client">
        <div className="auth-main">
          <div className="auth-form">
            <button
              className="exit btn-animation"
              onClick={() => close(false, "remove")}
            >
              Exit
            </button>
            <div className="title">Delete Client</div>
            <div className="auth-form-content d-flex flex-column justify-content-center">
              <div className="clientInfo d-flex flex-column justify-content-center w-100">
                <h1 className="text-center my-1">{client.ClientName}</h1>
                <p className="text-center companyName my-1">
                  {client.DomainName}
                </p>
              </div>
              {client.Logo && (
                <div
                  className="d-flex flex-column my-1 logoArea justify-content-center"
                  style={{ border: "1px dashed #bfbfbf", display: "block", marginLeft: "auto", marginRight: "auto" }}
                >
                  <img src={client.Logo} width="150" height="100" />
                </div>
              )}

              <div className="adminInfo d-flex flex-column justify-content-center w-100">
                <span className="littleAdmin text-center my-1">Admin</span>
                {client.ClientAdmins.length > 0 ? (
                  client.ClientAdmins.map((clientAdmin) => (
                    <p className="adminMail text-center  my-1">
                      {clientAdmin.EmailID}
                    </p>
                  ))
                ) : (
                  <span className="text-center">No assigned admin user</span>
                )}
              </div>
              <div className="halfbox d-flex flex-column justify-content-center">
                <div className="warningMessageArea d-flex flex-column justify-content-center w-100">
                  <p className="redText text-center my-1 ">
                    You are about to delete this client.{" "}
                  </p>
                  <p className="boldText text-center my-1">
                    Doing so will delete all projects and their data associated
                    with this Client
                  </p>
                </div>
              </div>
            </div>
            <div className="auth-form-footer d-inline-flex justify-content-center w-100">
              <div>
                <a
                  onClick={() => close(false, "remove")}
                  className="confirmBtn cancelBtn btn btn-link btn-green btn-animation mr-1"
                >
                  Cancel
                </a>
              </div>
              <div>
                <a
                  onClick={removeClient}
                  className="confirmBtn deleteBtn btn btn-link btn-green btn-animation ml-1"
                >
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientRemoveForm;
