import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";
import TreeNode from "../../../../helpers/TreeView/tree-node";

import {
  validateAccountEmailId,
  validate1to30AlpheNumHyphen, validateManagementGroupName
} from "../../../../helpers/validator";
import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import { getError } from "../../../../helpers/errorHelper";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";
import { ref } from "yup";
import { getAllByTitle } from "@testing-library/react";


const PREFIX = "/azure/subscriptionframework/managementgroups";
const ROOT_TITLE = "Tenant Root Group";

const ManagementGroupStructure = () => {

    const {projectId} = useParams();

    const history = useHistory();
    const [isDirty, setIsDirty] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  
    const [formData, setFormData] = useState({
      nodes: [],
      defaultChildren: [{title:"Platform", id: "1.1"},
      {title:"Workloads", id: "1.2"}, {title:"Sandboxes", id:"1.3"},{title:"Decomissioned", id:"1.4"}],
      managementgrouplist:[]
    });
    const refNodes = React.useRef(formData.nodes);
    const mgmtList = React.useRef(formData.managementgrouplist);
    const setRefNodes = (data) => {
      refNodes.current = data;
    };
    const setMgmtList = (list) => {
      mgmtList.current = list;
    }
    const refIsDirty= React.useRef(isDirty);
    const setDirtyFlag = (flag) => {
      refIsDirty.current = flag;
      setIsDirty(flag);
    }

    const refIsLoading= React.useRef(isLoading);
    const setLoadingFlag = (flag) => {
      refIsLoading.current = flag;
      setIsLoading(flag);
    }

    const [empty, setEmpty] = useState(false);
  
    const disabled = useProjectDisabledStatus(projectId, ["Launched"]);
  
    const awsAmplify = useAwsAmplify();

    useEffect(() => {
        const fetchData = async () => {
          try {
            setErrorMsg(null);
            setLoadingFlag(true);
            const result = await awsAmplify.loadProjectData(projectId, PREFIX);
            setLoadingFlag(false);
    
            console.log(result);
    
            if (result && result.data) {
              setEmpty(result.data.empty);
               
              var fe = convertBEToFE(result.data);
    
              if (fe) {
                setFormData(fe);
                setRefNodes(fe.nodes)
              }
            } else {
              setEmpty(true);
            }
          } catch (err) {
            console.log(err);
            setLoadingFlag(false);
    
            const errorMsg = getError(err, setErrorMsg);
    
            if(errorMsg === "You have no access"){
              history.goBack();
            }
          }
        };
    
        fetchData();
      }, [projectId]);
    
      const onSubmit = async (e) => {
        e.preventDefault();
        await submit();
      };
    
      const submit = async () => {
        try {
          setLoadingFlag(true);
          const result = await awsAmplify.saveProjectData({
            prefix: PREFIX,
            projectId: projectId,
            projectData: convertFEToBE(),
          });
          console.log(result);
          setLoadingFlag(false);

    
          setDirtyFlag(false);
          if(empty){
            setEmpty(false);
          }
        } catch (err) {
          setLoadingFlag(false);
          setErrorMsg(err.response.data.message);
        }
      };

    
  const convertBEToFE = (data) => {
    var fe = {
    nodes: [],
    defaultChildren: [{title:"Platforms", id: "1.1"},
    {title:"Workloads", id: "1.2"}, {title:"Sandboxes", id:"1.3"},{title:"Decomissioned", id:"1.4"}],
    managementgrouplist:[]
  };
  var OUExist = false;
   if(data && !data.empty)
    {
       var hierarchyData = data[`${PREFIX}/managementgroupstructure`];
       if(hierarchyData)
       {
        OUExist = true;
        var nodes = initializedСopy(JSON.parse(hierarchyData));
        fe.nodes = nodes;
        refNodes.current = fe.nodes;
        fe.managementgrouplist = getManagementGroupList();
        setMgmtList(fe.managementgrouplist);
       }
    } 
    if(!OUExist)
    { 
      refNodes.current = [];
      addRootElement();
      addDefaultChildren();
      fe.nodes = refNodes.current;
    }      
     return fe;
  };

  const convertFEToBE = () => {
    const be = {
      
    };
    if(refNodes.current !=='' && refNodes.current !== undefined)
    {
      const nodeCopy = simplify(refNodes.current);
      if(nodeCopy !== [])
      {
        let nodeStr = '';
        var nodeArray = [];
        nodesToString(nodeCopy[0], nodeArray,nodeStr);

        if(nodeArray !== [] && nodeArray.length > 1 ){
            be[`${PREFIX}/managementgrouplist`] = nodeArray.join(",");
             //this variable is used to build the tree upon fetch
            be[`${PREFIX}/managementgroupstructure`] = getJSONStr(nodeCopy);          
        }
      }
    }
    setErrorMsg(null);
    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (refIsDirty.current) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  useHideLeftMenu();
  useUnload(refIsDirty.current);

 const addRootElement = () => {
    const id = (refNodes && refNodes.current && refNodes.current.length) ? `${refNodes.current.length+ 1}` : "1";
    const newNode = { 
        children: [],
        changeTitle: "readonly",
        removeNode: undefined,
        addChild: addChild(id),
        id,
        title: ROOT_TITLE,
    };
    
    refNodes.current.push(newNode);
    var nodes = refNodes.current;
    var managementgrouplist = getManagementGroupList();
    setMgmtList(managementgrouplist);
    setFormData((state) =>
    update(state, {
      nodes: {$set: [nodes] },
      managementgrouplist: {$set: [managementgrouplist] }
    }));
    setRefNodes(nodes);
  }

  const addDefaultChildren = () => {
  if(formData && formData.defaultChildren)
   {
      let rootNode = refNodes.current;
      var index = [];
      index.push(rootNode && rootNode.length.toString());
      let changingNode = rootNode[index-1];
      for (let i=0; i< formData.defaultChildren.length; i++) 
      {
       // var id = `${index.join(".")}.${changingNode.children ? (changingNode.children.length + 1) : 1}`;
       var id = formData.defaultChildren[i].id;
        changingNode.children = [
          ...changingNode.children,{ 
            children: [],
            changeTitle: changeTitle(id),
            removeNode: removeNode(id,rootNode),
            addChild: addChild(id,rootNode),
            id,
            title: "",
            placeholder : formData.defaultChildren[i].title
        }];
      }
      setRefNodes(rootNode);
      var managementgrouplist = getManagementGroupList();
      setMgmtList(managementgrouplist);
      setFormData((state) =>
      update(state, {
        nodes: {$set: [rootNode] },
        managementgrouplist: {$set: [managementgrouplist] }
      }));
    }
  }

const initializedСopy =  (nodes, location) => {
  const nodesCopy = [];
  for (let i = 0; i < nodes.length; i++) {
      const { children, title } = nodes[i];
      const hasChildren = children !== undefined;
      const id = location ? `${location}.${i + 1}` : `${i + 1}`;
      if(nodes[i].title === ROOT_TITLE && id == '1')
      {
        nodesCopy[i] = { 
          children: hasChildren ? initializedСopy(children, id) : undefined,
          changeTitle: "readonly",
          removeNode: undefined,
          addChild: addChild(id,formData.nodes),
          id,
          title,
          placeholder: ""
         }
      }
      else
      {
        nodesCopy[i] = { 
                children: hasChildren ? initializedСopy(children, id) : undefined,
                changeTitle: changeTitle(id),
                removeNode: removeNode(id,formData.nodes),
                addChild: addChild(id,formData.nodes),
                id,
                title,
                placeholder: "New OU"
        };
      }
     }
  return nodesCopy;
};

const isDefaultNode = (nodes,index, id) => {
     let isdefault = false;
     let value = nodes[index].title;

     if(id === '1' && nodes[index].title === ROOT_TITLE)
     {
       isdefault = true;
     }
     else
     {
       for(var x=0; x<formData.defaultChildren.length; x++)
        {
          if(formData.defaultChildren[x].title === value && formData.defaultChildren[x].id === id)
          {
            isdefault = true;
            break;
          }
        }
     }
      return isdefault;
}

const ValidateTitle = (newTitle) => {
  var errorMsg = validateManagementGroupName(newTitle);
  if(errorMsg == undefined) {
    errorMsg = validateMgmtGroupNameExist(newTitle);
  }
  return errorMsg;
}
const validateMgmtGroupNameExist = (newTitle) =>{
    const exists = (value) => {
      return  (mgmtList && mgmtList.current.indexOf(value) !== -1);
    };
    if(exists(newTitle)){
      return "Management Group Name already exists.";
    }
}

const changeTitle = (id)  => {
  return (newTitle) => {
     var err = ValidateTitle(newTitle);
      if(err)
        setErrorMsg(err);
      else{
        setErrorMsg(null);
          var indx = id && id.split(".").map((str) => parseInt(str));
          const nodes =  refNodes.current;
          let changingNode = nodes[indx[0] - 1];

          if (indx.length > 1) {
              for (let i = 1; i < indx.length; i++) {
                  changingNode = changingNode.children[indx[i] - 1];
              }
          }

        changingNode.title = newTitle;
      
        setRefNodes(nodes);
        var managementgrouplist = getManagementGroupList();
        setMgmtList(managementgrouplist);
        setFormData((state) =>
              update(state, {
                nodes: {$set: [nodes] },
                managementgrouplist: {$set: [managementgrouplist] }
              }));
        setDirtyFlag(true);
        }
  };

}

const getManagementGroupList = () =>{
  var titleList = [];
    if(refNodes.current !=='' && refNodes.current !== undefined)
    {
      var nodeCopy = simplify(refNodes.current);
      if(nodeCopy !== [])
      {
        let nodeStr = '';
        var nodeArray = [];
        nodesToString(nodeCopy[0], nodeArray,nodeStr);
 
        if(nodeArray !== [] && nodeArray.length > 1 ){
          for(var i=0;i<nodeArray.length; i++){
            var node = nodeArray[i].split('/');
            titleList.push(node[node.length-1]);
           } 
           if(!titleList.includes(ROOT_TITLE))
             titleList.push(ROOT_TITLE);
        }
      }
    }
  return titleList;
}

const addChild = (index)  => {
  return (e) => {
     var id = index.split(".").map((str) => parseInt(str));
      const nodes = refNodes.current;
      let changingNode = nodes[id[0] - 1];

      if (id.length > 1) {
          for (let i = 1; i < id.length; i++) {
              changingNode = changingNode.children[id[i] - 1];
          }
      }

      if (changingNode.children === undefined) {
          changingNode.children = [];
      }
      
      id = `${id.join(".")}.${changingNode.children.length + 1}`;

      changingNode.children = [
          ...changingNode.children,
          { 
              children: undefined,
              changeTitle: changeTitle(id),
              removeNode: removeNode(id, nodes),
              addChild: addChild(id, nodes),
              id,
              title: "",
              placeholder: "New Group Name"
          }];
          
          setRefNodes(nodes);
          const managementgrouplist = getManagementGroupList();
          setMgmtList(managementgrouplist);
          setFormData((state) =>
          update(state, {
            nodes: {$set: [nodes] },
            managementgrouplist : {$set: [managementgrouplist] }
          }));
          e.preventDefault();
          setDirtyFlag(true);
  }
}


const removeNode = (index)  => {
  return (e) => {
      var id = index.split(".").map((str) => parseInt(str));
      const nodes = refNodes.current;

      if (id.length === 1) {
          const newNodes = [
              ...nodes.slice(0, [id[0] - 1]),
              ...nodes.slice(id[0])
          ];

          var newNodes1 = initializedСopy(newNodes);
          setRefNodes(newNodes1);
          const managementgrouplist = getManagementGroupList();
          setMgmtList(managementgrouplist);
          setFormData((state) =>
          update(state, {
            nodes: {$set: [newNodes1] },
            managementgrouplist : {$set: [managementgrouplist] }
          }));

      } else {
          let changingNode = nodes[id[0] - 1];
          
          for (let i = 2; i < id.length; i++) {
            changingNode =  changingNode && changingNode.children[id[i - 1] - 1];
          }

          const idx = id[id.length - 1] - 1;

          const newChildren = [
              ...changingNode.children.slice(0, idx),
              ...changingNode.children.slice(idx + 1),
          ];
          changingNode.children = newChildren;

        var newNodes = initializedСopy(nodes);
         setRefNodes(newNodes);
         const managementgrouplist = getManagementGroupList();
         setMgmtList(managementgrouplist);
         setFormData((state) =>
         update(state, {
           nodes: {$set: [newNodes] },
           managementgrouplist : {$set: [managementgrouplist] }
         }));
      }
      e.preventDefault();
      setDirtyFlag(true);
  }
}


const onTextChange = (e)=> {  
  setRefNodes(JSON.parse(e.target.value));
  setFormData((state) =>
  update(state, {
    nodes: {$set: [JSON.parse(e.target.value)] }
  }));
  setDirtyFlag(true);
}

const nodesToString = (node, array, nodeStr)  => {
  const {title, children} = node;
  if(title && title != "")
  {
      const hasChildren = children !== undefined && children.length > 0;
      if(title === ROOT_TITLE && nodeStr === "")
      {
        nodeStr = nodeStr.concat(`/${title}`);
        array.push(nodeStr);
      }
      if(hasChildren)
      {
        for (var gc in children)
        {
          let childNodeStr = nodeStr;
          if(children[gc].title != "")
          {
            childNodeStr = childNodeStr.concat(`/${children[gc].title}`);
            array.push(childNodeStr);
            if(children[gc].children !== undefined && children[gc].children.length > 0)
            {
              nodesToString(children[gc],array,childNodeStr);
            }
          }
        }
      }
   }
}

 const getJSONStr = (value) => {
  var returnVal = value;
  if(isJSON(value))
  {
    returnVal  = JSON.stringify(JSON.parse(value, null,2), null,2);
  }
  else
    returnVal = JSON.stringify(value,null,2);

  return returnVal;
};

const isJSON = (str) => {
  try {
      return (JSON.parse(str) && !!str);
  } catch (e) {
      return false;
  }
};

const simplify =  (nodes) => {
  const nodesCopy = [];
  for (let i = 0; i <  nodes.length; i++) {
      const { children, title} = nodes[i];
    const hasChildren =  nodes[i] !== undefined && children !== undefined && children.length > 0;
    if(title != "" )
    {
      var newNode = { 
        title,
        children: hasChildren ? simplify(children) : undefined
      };
      nodesCopy.push(newNode);
    }
  }
  return nodesCopy;
}

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {refIsLoading.current && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Subscription Framework</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
          <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                title= "Subscription Framework"
                saveDataIfDirty={saveDataIfDirty}
                isDirty={refIsDirty.current}
                dashboardRoute={ROUTES.AZURE_ORGANIZATIONAL_UNITS}
                designRoute={ROUTES.AZURE_DESIGN_SUBSCRIPTION_FRAMEWORK}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 ">
                <div className="row d-flex align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-4 pl-lg-0 px-2">
                    <h3>Organizational Structure</h3>
                  </div>
                  {empty && (
                    <NoInitialData />
                  )}

                   <div className="Tree">
                    <div className="Tree-LeftSide">
                      <ul className="treeUL">
                        { 
                      
                       refNodes.current && refNodes.current.length > 0 && refNodes.current.map((nodeProps) => {
                        console.log('refNode '+ refNodes.current);
                          const { id, ...others } = nodeProps;
                          return (
                            <TreeNode
                              key={id}
                              {...others}
                            />
                          );}) 
                        
                          }
                      </ul>
                  </div>           
                  
                  </div>
                </div></div>
              </fieldset>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={async () => {
                      if (refIsDirty.current) {
                        await submit();
                      }
                      history.push(ROUTES.AZURE_PLATFORM_SUBSCRIPTIONS+"/"+projectId);
                    }}
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Platform Subscriptions
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {console.log(formData)}
    </>
  );

    
};


export default ManagementGroupStructure;
