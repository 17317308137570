import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import axios from 'axios';

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getBooleanValue } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "FMS Policies"; 

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
      <>
        <h3>Firewall Manager</h3>
        <div className="otherTableTitles ">
          <h3 className="font-15 activeTable">Security Policies</h3>
          <h3 className="font-15">
            <Link className="text-color-gray" to={ROUTES.SECURITY_FIREWALL_APP_LISTS + "/" + projectId}>
             Application Lists
            </Link>
          </h3>
          <h3 className="font-15">
            <Link className="text-color-gray" to={ROUTES.SECURITY_FIREWALL_PROTOCOL_LISTS + "/" + projectId}>
             Protocol Lists
            </Link>
          </h3>
        </div>
      </>
    );
  };

  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && (selectedRow.PolicyName ?
                                   selectedRow.PolicyName : "-" )}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Policy Type</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && (selectedRow.SecurityServiceType ?
                                selectedRow.SecurityServiceType  : "-" )}
                  </p>
                </div>
              </div>

              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Automatic Remediation 
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && ( (selectedRow.RemediationEnabled !== "") ?
                   getBooleanValue(selectedRow, "RemediationEnabled").toString() : "-")}
                  </p>
                </div>
  
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Protected Accounts
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && (selectedRow.ProtectedAccounts ? 
                        ( selectedRow.ProtectedAccounts.toString() ) : "-" )}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Noncompliant Accounts</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && (selectedRow.NonCompliantAccounts ?
                               ( selectedRow.NonCompliantAccounts.toString()) : "-")}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Policy ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && (selectedRow.PolicyId ?
                                   selectedRow.PolicyId : "-")}
                  </p>
                </div>
              </div>
          
              </div>
            </div>
          </div>

       
        </div>
    );
  };

  const Navigations = () => {
    const { projectId } = useParams();
    return (
        <>
        <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.SECURITY_SECRETS_MANAGER + "/" + projectId} >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Secrets Management</p>       
      </Link>
      </>
    );
  };
  
  
const FMSSecurityPolicies = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        id: "Account ID",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        id:"Region",
        accessor: "Region",
      },
      {
        Header: "Name",
        accessor: "PolicyName",
        id: "Name"
      },
      {
        Header: "Policy Type",
        accessor: "SecurityServiceType",
        id: "Policy Type"
      },
      {
        Header: "Automatic Remediation",
        accessor: (d) => getBooleanValue(d,"RemediationEnabled"),
        id: "Automatic Remediation"
      },
      {
        Header: "Protected Accounts",
        accessor:  (d) => getArrayValue(d, 0, "ProtectedAccounts", "", ""),
        id: "Protected Accounts"
      },
      {
        Header: "Noncompliant Accounts",
        accessor: (d) => getArrayValue(d, 0, "NoncompliantAccounts", "", ""),
        id: "Noncompliant Accounts"
      },
      {
        Header: "Policy ID",
        accessor: "PolicyId",
      }
    ],
    []
  );

  return Dashboard({
    domain: "Security",
    title: "Security",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.SECURITY_PERIMETER_PROTECTION,
    designRoute: ROUTES.DESIGN_DATA_PROTECTION,
    SubMenu: SubMenu,
  });
};

export default FMSSecurityPolicies;