import { useEffect } from "react";
import $ from "jquery";

const useHideLeftMenu = () => {
  useEffect(() => {
    $(".fitToScreenRight").click(function () {
      $(".leftMenuShowIcon").removeClass("closeIcon");
      $(".sol-menu-alan").removeClass("opened");
      $(".a-link-button").removeClass("opened");
    });

    $(".tableArea").click(function(){
      $(".headerUserNavMobil").removeClass("opened");
    });
  }, []);
};

export default useHideLeftMenu;