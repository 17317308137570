import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "./DetailsTags";
import { getArrayValue } from "./DashboardHelper";
import DetailsGrid from "./DetailsGrid";

const dashboardName = "Firewall RuleGroups";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
      <>
        <h3>Network Firewall</h3>
        <div className="otherTableTitles ">
          <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.NETWORK_FIREWALL_FIREWALLS + "/" + projectId}>
              Firewalls  
          </Link></h3>
          <h3 className="font-15">
            <Link className="text-color-gray" to={ROUTES.NETWORK_FIREWALL_POLICIES + "/" + projectId}>
             Policies
            </Link>
          </h3>
          <h3 className="font-15 activeTable">
            Rule Groups
          </h3>
        </div>
      </>
    );
  };

  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Rules" && "my_active"
                }`}
                onClick={() => setType("Rules")}
              >
                Rules
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Tags" && "my_active"
                }`}
                onClick={() => setType("Tags")}
              >
                Tags
              </li> 
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {getArrayValue(selectedRow.Tags, -1, "Key", "Name", "Value")}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Description</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Description}
                  </p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Type
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Type}
                  </p>
                </div>
  
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Capacity
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Capacity}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Status</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Status}
                  </p>
                </div>
              </div>
            </div>
  
          
           <DetailsGrid
              type={type}
              typeName="Rules"
              array={selectedRow.StatefulRules}
              metadata={[
                { header: "Protocol", key: "Header.Protocol" },
                { header: "Source", key: "Header.Source" },
                { header: "Destination", key: "Header.Destination" },
                { header: "Source Port", key: "Header.SourcePort" },   
                { header: "Destination Port", key: "Header.DestinationPort" },   
                { header: "Direction", key: "Header.Direction" },    
                { header: "Action", key: "Action" },             
              ]}
            ></DetailsGrid>

            <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>

          </div>
        </div>
      </div>
    );
  };

  const Navigations = () => {
    const { projectId } = useParams();
    return(
    <>
    <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.VPN + "/" + projectId}
    >
      <img src="../images/ribbon-design.svg" />
      <p className="m-0 p-0 ml-2 font-weight-bold">Hybrid Network</p>
    </Link>
    <Link
    className="btn-post btn-animation d-inline-flex"
    to={ROUTES.TRAFFIC_MIRROR_TARGETS + "/" + projectId}
  >
    <p className="m-0 p-0 mr-2 font-weight-bold">Traffic Mirror Targets</p>
    <img src="../images/ribbon-designRight.svg" />
  </Link>
     </>
    );
  };
  
  
const FirewallRuleGroups = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "Name",
        accessor: (d) => getArrayValue(d.Tags, -1, "Key", "Name", "Value"),
      },
      {
        Header: "Description",
        accessor: "Description",
      },
      {
        Header: "Type",
        accessor: "Type",
      },
      {
        Header: "Capacity",
        accessor: "Capacity",
      },
      {
        Header: "Status",
        accessor: "Status",
      }
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "network",
    title: "Network",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_CENTERS,
    designRoute: ROUTES.DESIGN_NETWORK,
    SubMenu: SubMenu,
  });
};

export default FirewallRuleGroups;