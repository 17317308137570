import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import { validate2to128AlphaNumeric,
          validateAccountName,  
          validateExternalId,
          validateSourceAccountId} from "../../../../helpers/validator";
import useServiceAccounts from "../../../../hooks/useServiceAccounts";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";
import ShowHidePassword from "../../../../helpers/showHidePassword";

const PREFIX = "/aws/security/vulnerabilitymanagement";

const getInitialFormData = (data) => {
  const initialFormData = {
    deploycentralizedvulnerabilitymgmt: {
      value: data[`${PREFIX}/deploycentralizedvulnerabilitymgmt`] || "no",
    },
    awsinspector_enableawsinspector: {
      value: data[`${PREFIX}/awsinspector/enableawsinspector`] || "yes",
    },
    awsinspector_ec2scanning: {
      value: data[`${PREFIX}/awsinspector/ec2scanning`] || "yes",
    },
    awsinspector_ecrscanning: {
      value: data[`${PREFIX}/awsinspector/ecrscanning`] || "yes",
    },
    awsinspector_delegatedmasteraccount: {
      value: data[`${PREFIX}/awsinspector/delegatedmasteraccount`] || "",
    },
    tenable_deploytenableintegration: {
      value: data[`${PREFIX}/tenable/deploytenableintegration`] || "yes",
    },
    tenable_accesskey: {
      value: data[`${PREFIX}/tenable/accesskey`] || "",
      isValid: data[`${PREFIX}/tenable/accesskey`] ? true : false,
    },
    tenable_secretkey: {
      value: data[`${PREFIX}/tenable/secretkey`] || "",
      isValid: data[`${PREFIX}/tenable/secretkey`] ? true : false,
    },
    tenable_externalid: {
      value: data[`${PREFIX}/tenable/externalid`] || "",
      isValid: data[`${PREFIX}/tenable/externalid`] ? true : false,
    },
    tenable_sourceaccountid: {
      value: data[`${PREFIX}/tenable/sourceaccountid`] || "",
      isValid: data[`${PREFIX}/tenable/sourceaccountid`] ? true : false,
    },
    tenable_frequencyofscans: {
      value: data[`${PREFIX}/tenable/frequencyofscans`] || "",
    },
    tenable_scannerinstancesize: {
      value: data[`${PREFIX}/tenable/scannerinstancesize`] || "",
    },
    vulnerabilityscanneraccount: {
      value: data[`${PREFIX}/vulnerabilityscanneraccount`] || "",
    },
    frequencyofscans: [
      {frequency: 'Daily', expr: '0 0 * * ? *'},
      {frequency: 'Weekly', expr: '0 0 ? * SUN *'},
      {frequency: 'Monthly', expr: '0 0 1 * ? *'},
      {frequency: 'Yearly', expr: '0 0 1 1 ? *'}
    ],
  };

  return initialFormData;
};

const VulnerabilityManagement = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));

  const [empty, setEmpty] = useState(false);

  const [showPwd1, setShowPwd1] = useState(false);
  const [showPwd2, setShowPwd2] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();
  const accounts = useServiceAccounts(projectId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        setEmpty(result.data.empty);

        setFormData(convertBEToFE(result.data));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data);



    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploycentralizedvulnerabilitymgmt`]: formData
        .deploycentralizedvulnerabilitymgmt.value,
    };

    if (formData.deploycentralizedvulnerabilitymgmt.value === "yes") {
      be[`${PREFIX}/awsinspector/enableawsinspector`] =
        formData.awsinspector_enableawsinspector.value;


      if(formData.awsinspector_enableawsinspector.value === "yes") {
        if(formData.awsinspector_delegatedmasteraccount.value !== ""){
        be[`${PREFIX}/awsinspector/delegatedmasteraccount`] =
        formData.awsinspector_delegatedmasteraccount.value;
        }

        if(formData.awsinspector_ec2scanning.value !== ""){
        be[`${PREFIX}/awsinspector/ec2scanning`] = formData.awsinspector_ec2scanning.value;
        }

        if(formData.awsinspector_ecrscanning.value !== ""){
          be[`${PREFIX}/awsinspector/ecrscanning`] = formData.awsinspector_ecrscanning.value;
          }
      }

      be[`${PREFIX}/tenable/deploytenableintegration`] =
        formData.tenable_deploytenableintegration.value;

      if (formData.tenable_deploytenableintegration.value === "yes") {
        if (formData.tenable_accesskey.isValid) {
          be[`${PREFIX}/tenable/accesskey`] = formData.tenable_accesskey.value;
        }

        if (formData.tenable_secretkey.isValid) {
          be[`${PREFIX}/tenable/secretkey`] = formData.tenable_secretkey.value;
        }

        if (formData.tenable_externalid.isValid) {
          be[`${PREFIX}/tenable/externalid`] =
            formData.tenable_externalid.value;
        }

        if (formData.tenable_sourceaccountid.isValid) {
          be[`${PREFIX}/tenable/sourceaccountid`] =
            formData.tenable_sourceaccountid.value;
        }

        if (formData.tenable_frequencyofscans.value !== "") {
          be[`${PREFIX}/tenable/frequencyofscans`] =
            formData.tenable_frequencyofscans.value;
        }

        if (formData.tenable_scannerinstancesize.value !== "") {
          be[`${PREFIX}/tenable/scannerinstancesize`] =
            formData.tenable_scannerinstancesize.value;
        }
      }

      if (formData.vulnerabilityscanneraccount.value !== "") {
        be[`${PREFIX}/vulnerabilityscanneraccount`] =
          formData.vulnerabilityscanneraccount.value;
      }
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const onFieldChange = (value, key) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key]: { value: { $set: value } } })
    );
  };

  const onFieldBlur = (validateFunc, value, key) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          [key]: { isValid: { $set: errorMsg === undefined } },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, { [key]: { isValid: { $set: false } } })
      );
    }
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Network</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Security"
                isDirty={isDirty}
                dashboardRoute={ROUTES.SECURITY_HUB_MEMBERS}
                designRoute={ROUTES.DESIGN_SECURITY}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div className="row d-flex align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-4 pl-lg-0 px-2">
                    <h3>Vulnerability Management</h3>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 d-flex align-items-center justify-content-end rightNavArea">
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 text-nowrap">
                        Deploy Centralized Vulnerability Management
                      </div>
                      <div className=" btn-group btn-group-toggle btn-group-radio mw-100 ">
                        <RadioGroup
                          field="deploycentralizedvulnerabilitymgmt"
                          value={
                            formData.deploycentralizedvulnerabilitymgmt.value
                          }
                          onChange={updateRadio}
                          isLarge={true}
                        ></RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>

                {empty && (
                  <NoInitialData />
                )}

                {formData.deploycentralizedvulnerabilitymgmt.value ===
                  "yes" && (
                  <div className="row show-hide-content">
                    <div className="mainContentArea row px-2 mt-2 py-1 my-1">
                      <div className="b-section formRow align-items-start px-0 my-1 w-100">
                        <div className="showRightContenBtn flex-1 px-1">
                          <div className="w-100 h-md-34 d-flex align-items-center">
                            <div className="one form-line d-inline-flex align-items-center">
                              <label className="switch mb-0">
                                <input
                                  type="checkbox"
                                  checked={
                                    formData.awsinspector_enableawsinspector
                                      .value === "yes"
                                  }
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    setIsDirty(true);
                                    setFormData((state) =>
                                      update(state, {
                                        awsinspector_enableawsinspector: {
                                          value: {
                                            $set: checked ? "yes" : "no",
                                          },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span className="slider round"></span>
                              </label>
                              <span className="switchLabel">
                                Enable AWS Inspector
                              </span>
                            </div>
                          </div>
                        </div>
                        {formData.awsinspector_enableawsinspector.value ===
                          "yes" && (
                          <>
                        <div className="rightContent flex-1 px-1 my-1">
                          <div className="d-flex flex-column mr-2">
                                <div className="d-inline-flex">
                                  <div className="d-flex flex-column mr-2">
                                  <div className="specialTitle mw-md-150 mt-1  py-10px d-flex align-items-center">
                                      <p className="m-0 p-0 font-12">
                                      Delegated AWS Inspector Account
                                      </p>
                                  </div>
                                </div>
                                <div className="flex-1 my-1 border-10 p-relative d-inline-flex align-items-center">
                                    { (accounts && (accounts.security
                                                  .concat(accounts.shared)).length === 0) ? (
                                    <div className="flex-wrap d-inline-flex">
                                      <span className="warning">
                                      This section depends on the Platform Accounts information in
                                      Account Framework section that is yet to be provided
                                    </span>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="flex-wrap d-inline-flex">
                                        <div className="w-250 border-10  p-relative   d-inline-flex align-items-center"> 
                                              <select
                                              className="form-control"
                                              value={formData.awsinspector_delegatedmasteraccount.value}
                                                  onChange={(e) => {
                                                  onFieldChange(
                                                  e.target.value,
                                                  "awsinspector_delegatedmasteraccount"
                                                  );                                       
                                              }} >   
                                              <option value="">Select</option>
                                                  { accounts.security
                                                      .concat(accounts.shared)
                                                      .map((account, idxAccount) => (
                                                      <option key={idxAccount} value={account}>
                                                      {account}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                                  </div>
                                        </div> 
                                  </> )}
                                
                                    </div>
                                </div>
                        
                                <div className="showRightContenBtn flex-1">
                                  <div className="w-100 h-md-34 d-flex align-items-center">
                                    <div className="one form-line d-inline-flex align-items-center">
                                      <label className="switch mb-0">
                                        <input
                                          type="checkbox"
                                          checked={
                                            formData.awsinspector_ec2scanning
                                              .value === "yes"
                                          }
                                          onChange={(e) => {
                                            const checked = e.target.checked;
                                            setIsDirty(true);
                                            setFormData((state) =>
                                              update(state, {
                                                awsinspector_ec2scanning: {
                                                  value: {
                                                    $set: checked ? "yes" : "no",
                                                  },
                                                },
                                              })
                                            );
                                          }}
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                      <span className="switchLabel">
                                      Enable  EC2 scanning
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="showRightContenBtn flex-1">
                                  <div className="w-100 h-md-34 d-flex align-items-center">
                              <div className="one form-line d-inline-flex align-items-center">
                                <label className="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData.awsinspector_ecrscanning
                                        .value === "yes"
                                    }
                                    onChange={(e) => {
                                      const checked = e.target.checked;
                                      setIsDirty(true);
                                      setFormData((state) =>
                                        update(state, {
                                          awsinspector_ecrscanning: {
                                            value: {
                                              $set: checked ? "yes" : "no",
                                            },
                                          },
                                        })
                                      );
                                    }}
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <span className="switchLabel">
                                  Enable ECR container scanning
                                </span>
                              </div>
                            </div>
                              </div>                
                            
                          </div>
                         </div>
                             
                        <div className="rightContent flex-1 px-1 my-1">
                        </div>  
                      
                        {/*} <div className="formRow align-items-start px-0 my-1 w-100">
                          <div className="showRightContenBtn flex-1">
                            <div className="w-100 h-md-34 d-flex align-items-center">
                              <div className="one form-line d-inline-flex align-items-center">
                                <label className="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData.awsinspector_ec2scanning
                                        .value === "yes"
                                    }
                                    onChange={(e) => {
                                      const checked = e.target.checked;
                                      setIsDirty(true);
                                      setFormData((state) =>
                                        update(state, {
                                          awsinspector_ec2scanning: {
                                            value: {
                                              $set: checked ? "yes" : "no",
                                            },
                                          },
                                        })
                                      );
                                    }}
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <span className="switchLabel">
                                Enable  EC2 scanning
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="showRightContenBtn flex-1">
                            <div className="w-100 h-md-34 d-flex align-items-center">
                              <div className="one form-line d-inline-flex align-items-center">
                                <label className="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData.awsinspector_ecrscanning
                                        .value === "yes"
                                    }
                                    onChange={(e) => {
                                      const checked = e.target.checked;
                                      setIsDirty(true);
                                      setFormData((state) =>
                                        update(state, {
                                          awsinspector_ecrscanning: {
                                            value: {
                                              $set: checked ? "yes" : "no",
                                            },
                                          },
                                        })
                                      );
                                    }}
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <span className="switchLabel">
                                  Enable ECR container scanning
                                </span>
                              </div>
                            </div>
                          </div>                
                          </div>
                         
                          </div> */}
                          </>
                          )}
                     </div>
                    </div>


                    <div className="mainContentArea row px-2 mt-2 py-1 my-1">
                      <div className="b-section formRow align-items-start px-0 my-1">
                        <div className="showRightContenBtn flex-1 px-1">
                          <div className="w-100 h-md-34 d-flex align-items-center">
                            <div className="one form-line d-inline-flex align-items-center">
                              <label className="switch mb-0">
                                <input
                                  type="checkbox"
                                  checked={
                                    formData.tenable_deploytenableintegration
                                      .value === "yes"
                                  }
                                  onChange={(e) => {
                                    const value = e.target.checked;
                                    setIsDirty(true);
                                    setFormData((state) =>
                                      update(state, {
                                        tenable_deploytenableintegration: {
                                          value: {
                                            $set: value ? "yes" : "no",
                                          },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span className="slider round"></span>
                              </label>
                              <span className="switchLabel">
                                Deploy Tenable Integration
                              </span>
                            </div>
                          </div>
                        </div>

                        {formData.tenable_deploytenableintegration.value ===
                          "yes" && (
                          <>
                            <div className="rightContent flex-1 px-1">
                              <div className="d-inline-flex w-100">
                                <div className="d-flex flex-column mr-2">
                                  <div className="specialTitle mw-md-150 mb-1  py-10px d-flex align-items-center">
                                    <p className="m-0 p-0 font-12">
                                      Tenable Access Key
                                    </p>
                                  </div>
                                  <div className="specialTitle mw-md-150 mt-1  py-10px d-flex align-items-center">
                                    <p className="m-0 p-0 font-12">
                                      External ID
                                    </p>
                                  </div>
                                  <div className="specialTitle mw-md-150 mt-1  py-10px d-flex align-items-center">
                                    <p className="m-0 p-0 font-12">
                                      Frequency of Assessment Runs
                                    </p>
                                  </div>
                                </div>

                                <div className="d-flex flex-column flex-1">
                                  <div className="flex-1 mb-1 border-10 w-175 p-relative d-inline-flex align-items-center">
                                    <button disabled={disabled}
                                      className="showableInput"
                                      type="button"
                                      onClick={() => setShowPwd1(!showPwd1)}
                                    ></button>
                                    <input
                                      type={showPwd1 ? "text" : "password"}
                                      className={`form-control ${
                                        formData.tenable_accesskey.value !==
                                          "" &&
                                        formData.tenable_accesskey.isValid ===
                                          false
                                          ? "invalid"
                                          : ""
                                      }`}
                                      placeholder=""
                                      value={formData.tenable_accesskey.value}
                                      onChange={(e) =>
                                        onFieldChange(
                                          e.target.value,
                                          "tenable_accesskey"
                                        )
                                      }
                                      onBlur={(e) =>
                                        onFieldBlur(
                                          validate2to128AlphaNumeric,
                                          e.target.value,
                                          "tenable_accesskey"
                                        )
                                      }
                                    />
                                  </div>
                                <div className="flex-1 my-1 border-10 w-175  p-relative d-inline-flex align-items-center">
                                    <ShowHidePassword
                                    field="tenable_externalid"
                                    passwordInput={ formData.tenable_externalid.value}
                                    onFieldChange={onFieldChange}
                                    onFieldBlur={onFieldBlur}
                                    validatorType= {validateExternalId}
                                    disabled={disabled}
                                    inputClassName={`form-control ${
                                      formData.tenable_externalid.value !== "" &&
                                      formData.tenable_externalid.isValid === false
                                        ? "invalid"
                                        : ""
                                      }`}
                                    /> 
                                  </div>
                                  <div className="flex-1 mt-1 w-175  border-10  p-relative d-inline-flex align-items-center">
                                    <select
                                      className="form-control "
                                      value={
                                        formData.tenable_frequencyofscans.value
                                      }
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            tenable_frequencyofscans: {
                                              value: {
                                                $set: value,
                                              },
                                            },
                                          })
                                        );
                                      }}
                                    >
                                   	
									                    <option value="">Select</option>
                                      <option value="Daily">Daily</option>
                                      <option value="Weekly">Weekly</option>
                                      <option value="Monthly">Monthly</option>
                                      <option value="Yearly">Yearly</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="rightContent flex-1 px-1">
                              <div className="d-inline-flex w-100">
                                <div className="d-flex flex-column mr-2">
                                  <div className="specialTitle mb-1  mw-md-150 py-10px d-flex align-items-center">
                                    <p className="m-0 p-0 font-12">
                                      Tenable Secret Key
                                    </p>
                                  </div>
                                  <div className="specialTitle mt-1  mw-md-150 py-10px d-flex align-items-center">
                                    <p className="m-0 p-0 font-12">
                                      Source Account ID
                                    </p>
                                  </div>

                                  <div className="specialTitle mt-1  mw-md-150 py-10px d-flex align-items-center">
                                    <p className="m-0 p-0 font-12">
                                      Scanner Instance Size based on # of Hosts
                                    </p>
                                  </div>
                                </div>

                                <div className="d-flex flex-column flex-1">
                                  <div className="flex-1 mb-1  border-10 w-175  p-relative d-inline-flex align-items-center">
                                    <button disabled={disabled}
                                      className="showableInput"
                                      type="button"
                                      onClick={() => setShowPwd2(!showPwd2)}
                                    ></button>
                                    <input
                                      type={showPwd2 ? "text" : "password"}
                                      className={`form-control bg-w-175 ${
                                        formData.tenable_secretkey.value !==
                                          "" &&
                                        formData.tenable_secretkey.isValid ===
                                          false
                                          ? "invalid"
                                          : ""
                                      }`}
                                      placeholder=""
                                      value={formData.tenable_secretkey.value}
                                      onChange={(e) =>
                                        onFieldChange(
                                          e.target.value,
                                          "tenable_secretkey"
                                        )
                                      }
                                      onBlur={(e) =>
                                        onFieldBlur(
                                          validate2to128AlphaNumeric,
                                          e.target.value,
                                          "tenable_secretkey"
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="flex-1 mt-1 w-175 border-10  p-relative d-inline-flex align-items-center">
                                    <ShowHidePassword
                                    field="tenable_sourceaccountid"
                                    passwordInput={ formData.tenable_sourceaccountid.value}
                                    onFieldChange={onFieldChange}
                                    onFieldBlur={onFieldBlur}
                                    validatorType= {validateSourceAccountId}
                                    disabled={disabled}
                                    inputClassName={`form-control ${
                                      formData.tenable_sourceaccountid.value !== "" &&
                                      formData.tenable_sourceaccountid.isValid === false
                                        ? "invalid"
                                        : ""
                                      }`}
                                    /> 
                                  </div>
                                  <div className="flex-1 mt-1 w-175 border-10  p-relative d-inline-flex align-items-center">
                                    <select
                                      className="form-control "
                                      value={
                                        formData.tenable_scannerinstancesize
                                          .value
                                      }
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            tenable_scannerinstancesize: {
                                              value: {
                                                $set: value,
                                              },
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      <option value="">Select</option>
                                      <option value="ExtraSmall">
                                        Extra Small
                                      </option>
                                      <option value="Small">Small</option>
                                      <option value="Medium">Medium</option>
                                      <option value="Large">Large</option>
                                      <option value="ExtraLarge">
                                        Extra Large
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                   </div>
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.SECURITY_INTELLIGENT_THREAT_DETECTION +
                          "/" +
                          projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Intelligent Threat Detection
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.SECURITY_ENDPOINT_PROTECTION + "/" + projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Endpoint Protection
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VulnerabilityManagement;
