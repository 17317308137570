import React, { useState, useEffect } from "react";

import LeftHandMenu from "../LeftHandMenu";
import TopMenu from "../../TopMenu";

import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../hooks/useHideLeftMenu";
import DomainMenu from "../DomainMenu";

import * as ROUTES from "../../../constants/routes";
import { getError } from "../../../helpers/errorHelper";

const features = [
  "Accounts",
  "OrganizationalUnits",
  "IdentityProviders",
  "IAMRoles",
  "VPCs",
  "VPCSubnets",
  "VPCRouteTables",
  "DHCPOptionsSets",
  "VPCEndpoints",
  "TGWs",
  "TGWRouteTables",
  "TGWAttachments",
  "VPNConnections",
  "DCXConnections",
  "DGWs",
  "TrafficMirrorTargets",
  "ResourceSharesSharedByMe",
  "SecurityHubMemberAccounts",
  "SecurityHubMemberStandards",
  "SecurityHubMemberIntegrations",
  "GuardDutyMemberAccounts",
  "SecretsManagement",
  "FMSPolicies",
  "FMSAppsList",
  "FMSProtocolsList",
  "Directories",
  "PrivateDNSInboundEndpoints",
  "PrivateDNSOutboundEndpoints",
  "PrivateDNSResolverRules",
  "ImageBuilderComponents",
  "ImageBuilderRecipes",
  "ImageBuilderPipelines",
  "ImageBuilderImages",
  "PatchManagementBaselines",
  "PatchManagementPatchGroups",
  "PatchManagementMaintenanceWindows",
  "Workspaces",
  "ServiceControlPolicies",
  "TaggingPolicies",
  "BackupPolicies",
  "ConfigRecorders",
  "ConfigRules",
  "AWSBudgets",
  "ServiceCatalogPortfolios",
  "ServiceCatalogProducts",
  "CloudWatchEventRules",
  "S3Buckets",
  "KinesisFirehoses",
  "ApplicationLoadBalancers",
  "ALBTargetGroups",
  "BackupVaults",
  "BackupPlans",
  "BackupJobs",
  "BackupCopyJobs",
  "ACMCertificates",
  "KMSKeyStores",
  "KMSCMKs"
];

const Validate = () => {
  const history = useHistory();
  const { projectId } = useParams();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [infoMsg, setInfoMsg] = useState(null);

  const [validateData, setValidateData] = useState(null);

  const awsAmplify = useAwsAmplify();

  const FeatureComponent = ({ feature, name, route }) => (
    <li style={{ width: "30px" }}>
      {validateData && validateData[feature] ? (
        validateData[feature].ErrorMessage ? (
          <span style={{ cursor: "pointer" }} className="decline" onClick={() => setErrorMsg(validateData[feature].ErrorMessage)}></span>
        ) : (
          <a
            style={{ cursor: "pointer" }}
            className={getClassName(feature)}
            onClick={() => 
              setInfoMsg(
                `Actual Count: ${validateData[feature].ActualCount}, Expected Count: ${validateData[feature].ExpectedCount}`
              )
            }
          >
            {validateData[feature].ActualCount}
          </a>
        )
      ) : (
        <span className="refresh"></span>
      )}
      <a href={`${route}/${projectId}`}>{name}</a>
    </li>
  );

  const getClassName = (feature) => {
    if (validateData && validateData[feature]) {
      if (
        validateData[feature].ActualCount ===
        validateData[feature].ExpectedCount
      ) {
        return "green";
      } else if (
        parseInt(validateData[feature].ActualCount) < parseInt(validateData[feature].ExpectedCount)
      ) {
        return "red";
      } else {
        return "orange";
      }
    }

    return "refresh";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);

        const projectAccessResp = await awsAmplify.getProjectAccess(projectId);

        if(!projectAccessResp.data.getProjectAccess.hasDashboardAccess){
          history.goBack();
          return;
        }

        /* const promises = features.map(async (feature) => {
          let result = await awsAmplify.validate(projectId, feature);

          setValidateData((state) => {
            return { ...state, [feature]: result[feature] };
          });
        });

        await Promise.all(promises); */

        for (const feature of features) {
          let result = await awsAmplify.validate(projectId, feature);

          setValidateData((state) => {
            return { ...state, [feature]: result[feature] };
          });

          await new Promise(resolve => setTimeout(resolve, 200));
        }
      } catch (err) {
        const errorMsg = getError(err, setErrorMsg);

        if (errorMsg === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  useHideLeftMenu();

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}

      {infoMsg && (
        <div className="alert-area">
          <div className="greenalert">
            <span className="closebtn" onClick={() => setInfoMsg(null)}>
              &times;
            </span>
            <div className="messageText">{infoMsg}</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Validation</div>
      <LeftHandMenu isDashboard={true}></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu></TopMenu>
            <div className="tableArea accountsTableArea aplicationTableArea container-fluid p-0 d-flex flex-column">
              <DomainMenu
                title="Validation"
                isDashboard={true}
                isConfigure={false}
                isPrelaunch={false}
                designRoute={ROUTES.DESIGN_ACCOUNT_FRAMEWORK}
                configureRoute={ROUTES.CONFIGURE}
              ></DomainMenu>

              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div className="dashboard__wrapper">
                  <div className="validate-dashboard__block-wrapper">
                    <div>
                      <div className="validate-dashboard__block-row">
                        <div>
                          <div className="validate-dashboard__block">
                            <div className="validate-dashboard__block-header">
                              <div>
                                <p>Account Framework</p>
                              </div>
                              {/* <div>
                                <span className="success"></span>
                              </div> */}
                            </div>
                            <div className="validate-dashboard__block-body">
                              <div className="validate-dashboard__box">
                                <div className="validate-dashboard__box-body is-2">
                                  <ul>
                                    <FeatureComponent
                                      feature="Accounts"
                                      name="Accounts"
                                      route={ROUTES.ACCOUNTS}
                                    ></FeatureComponent>
                                    <FeatureComponent
                                      feature="OrganizationalUnits"
                                      name="OUs"
                                      route={ROUTES.ORGANIZATIONAL_UNITS}
                                    ></FeatureComponent>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="validate-dashboard__block">
                            <div className="validate-dashboard__block-header">
                              <div>
                                <p>Identity & Access</p>
                              </div>
                              {/* <div>
                                <span className="success"></span>
                              </div> */}
                            </div>
                            <div className="validate-dashboard__block-body">
                              <div className="validate-dashboard__box">
                                <div className="validate-dashboard__box-body is-2">
                                  <ul>
                                    <FeatureComponent
                                      feature="IdentityProviders"
                                      name="IdPs"
                                      route={ROUTES.IDENTITY_PROVIDERS}
                                    ></FeatureComponent>
                                    <FeatureComponent
                                      feature="IAMRoles"
                                      name="Roles"
                                      route={ROUTES.IAM_ROLES}
                                    ></FeatureComponent>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="validate-dashboard__block">
                        <div className="validate-dashboard__block-header">
                          <div>
                            <p>Network</p>
                          </div>
                          {/* <div>
                            <span className="refresh"> </span>
                          </div> */}
                        </div>
                        <div className="validate-dashboard__block-body">
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Virtual Private Network</p>
                            </div>
                            <div className="validate-dashboard__box-body is-5">
                              <ul>
                                <FeatureComponent
                                  feature="VPCs"
                                  name="VPCs"
                                  route={ROUTES.VPC}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="VPCSubnets"
                                  name="Subnets"
                                  route={ROUTES.VPC_SUBNETS}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="VPCRouteTables"
                                  name="Route Tables"
                                  route={ROUTES.VPC_ROUTE_TABLES}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="DHCPOptionsSets"
                                  name="DHCP OptionsSets"
                                  route={ROUTES.VPC_DHCP}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="VPCEndpoints"
                                  name="VPC Endpoints"
                                  route={ROUTES.VPC_END_POINTS}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Transit Network</p>
                            </div>
                            <div className="validate-dashboard__box-body is-3">
                              <ul>
                                <FeatureComponent
                                  feature="TGWs"
                                  name="Transit Gateway"
                                  route={ROUTES.TGW}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="TGWRouteTables"
                                  name="TGW RouteTables"
                                  route={ROUTES.TGW_ROUTING_DOMAINS}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="TGWAttachments"
                                  name="TGW Attachments"
                                  route={ROUTES.TGW_ATTACHMENT}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Hybrid Network</p>
                            </div>
                            <div className="validate-dashboard__box-body is-3">
                              <ul>
                                <FeatureComponent
                                  feature="VPNConnections"
                                  name="VPN Connections"
                                  route={ROUTES.VPN}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="DCXConnections"
                                  name="DCX Connections"
                                  route={ROUTES.VPN_DIRECT_CONNECT}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="DGWs"
                                  name="DGWs"
                                  route={ROUTES.VPN_DIRECT_CONNECT_GATEWAY}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Traffic Mirroring</p>
                            </div>
                            <div className="validate-dashboard__box-body is-2">
                              <ul>
                                <FeatureComponent
                                  feature="TrafficMirrorTargets"
                                  name="Mirror Targets"
                                  route={ROUTES.TRAFFIC_MIRROR_TARGETS}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="ResourceSharesSharedByMe"
                                  name="Resource Shares"
                                  route={ROUTES.RESOURCE_SHARES_BY_ME}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="validate-dashboard__block">
                        <div className="validate-dashboard__block-header">
                          <div>
                            <p>Security</p>
                          </div>
                          {/* <div>
                            <span className="refresh"></span>
                          </div> */}
                        </div>
                        <div className="validate-dashboard__block-body">
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Compliance Mgmt.</p>
                            </div>
                            <div className="validate-dashboard__box-body is-3">
                              <ul>
                                <FeatureComponent
                                  feature="SecurityHubMemberAccounts"
                                  name="SecHub Members"
                                  route={ROUTES.SECURITY_HUB_MEMBERS}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="SecurityHubMemberStandards"
                                  name="SecHub Standards"
                                  route={ROUTES.SECURITY_HUB_STANDARDS}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="SecurityHubMemberIntegrations"
                                  name="SecHub Integrations"
                                  route={ROUTES.SECURITY_HUB_INTEGRATIONS}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                          <div className="validate-dashboard__box validate-dashboard__box--half">
                            <div className="validate-dashboard__box-header">
                              <p>Threat Detection</p>
                            </div>
                            <div className="validate-dashboard__box-body">
                              <ul>
                                <FeatureComponent
                                  feature="GuardDutyMemberAccounts"
                                  name="GuardDuty Members"
                                  route={ROUTES.SECURITY_GUARDDUTY_MEMBERS}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                          <div className="validate-dashboard__box validate-dashboard__box--half">
                            <div className="validate-dashboard__box-header">
                              <p>Secrets Mgmt.</p>
                            </div>
                            <div className="validate-dashboard__box-body">
                              <ul>
                                <FeatureComponent
                                  feature="SecretsManagement"
                                  name="Secrets"
                                  route={ROUTES.SECURITY_SECRETS_MANAGER}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Perimeter Protection</p>
                            </div>
                            <div className="validate-dashboard__box-body is-3">
                              <ul>
                                <FeatureComponent
                                  feature="FMSPolicies"
                                  name="FMS Policies"
                                  route={
                                    ROUTES.SECURITY_FIREWALL_SECURITY_POLICIES
                                  }
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="FMSAppsList"
                                  name="FMS AppList"
                                  route={ROUTES.SECURITY_FIREWALL_APP_LISTS}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="FMSProtocolsList"
                                  name="FMS Protocol List"
                                  route={
                                    ROUTES.SECURITY_FIREWALL_PROTOCOL_LISTS
                                  }
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="validate-dashboard__block">
                        <div className="validate-dashboard__block-header">
                          <div>
                            <p>Infrastructure</p>
                          </div>
                          {/* <div>
                            <span className="decline"></span>
                          </div> */}
                        </div>
                        <div className="validate-dashboard__block-body">
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Active Directory</p>
                            </div>
                            <div className="validate-dashboard__box-body">
                              <ul>
                                <FeatureComponent
                                  feature="Directories"
                                  name="Directories"
                                  route={ROUTES.INFRASTRUCTURE_DIRECTORIES}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Private DNS</p>
                            </div>
                            <div className="validate-dashboard__box-body is-3">
                              <ul>
                                <FeatureComponent
                                  feature="PrivateDNSInboundEndpoints"
                                  name="Inbound Endpoints"
                                  route={
                                    ROUTES.INFRASTRUCTURE_INBOUND_ENDPOINTS
                                  }
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="PrivateDNSOutboundEndpoints"
                                  name="Outbound Endpoints"
                                  route={
                                    ROUTES.INFRASTRUCTURE_OUTBOUND_ENDPOINTS
                                  }
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="PrivateDNSResolverRules"
                                  name="Resolver Rules"
                                  route={ROUTES.INFRASTRUCTURE_RESOLVER_RULES}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Image Management</p>
                            </div>
                            <div className="validate-dashboard__box-body is-4">
                              <ul>
                                <FeatureComponent
                                  feature="ImageBuilderComponents"
                                  name="Components"
                                  route={ROUTES.INFRASTRUCTURE_COMPONENTS}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="ImageBuilderRecipes"
                                  name="Recipes"
                                  route={ROUTES.INFRASTRUCTURE_RECIPES}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="ImageBuilderPipelines"
                                  name="Pipelines"
                                  route={ROUTES.INFRASTRUCTURE_PIPELINES}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="ImageBuilderImages"
                                  name="Images"
                                  route={ROUTES.INFRASTRUCTURE_IMAGES}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Patch Management</p>
                            </div>
                            <div className="validate-dashboard__box-body is-3">
                              <ul>
                                <FeatureComponent
                                  feature="PatchManagementBaselines"
                                  name="Baselines"
                                  route={ROUTES.INFRASTRUCTURE_BASELINES}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="PatchManagementPatchGroups"
                                  name="Patch Groups"
                                  route={ROUTES.INFRASTRUCTURE_GROUPS}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="PatchManagementMaintenanceWindows"
                                  name="Maintenance Windows"
                                  route={
                                    ROUTES.INFRASTRUCTURE_MAINTENANCE_WINDOWS
                                  }
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Workspaces</p>
                            </div>
                            <div className="validate-dashboard__box-body">
                              <ul>
                                <FeatureComponent
                                  feature="Workspaces"
                                  name="Workspaces"
                                  route={ROUTES.INFRASTRUCTURE_WORKSPACES}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="validate-dashboard__block">
                        <div className="validate-dashboard__block-header">
                          <div>
                            <p>Governance</p>
                          </div>
                          {/* <div>
                            <span className="refresh"></span>
                          </div> */}
                        </div>
                        <div className="validate-dashboard__block-body">
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Organization Policies</p>
                            </div>
                            <div className="validate-dashboard__box-body is-3">
                              <ul>
                                <FeatureComponent
                                  feature="ServiceControlPolicies"
                                  name="SCPs"
                                  route={ROUTES.GOVERNANCE_SERVICE_CONTROL}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="TaggingPolicies"
                                  name="Tag Policies"
                                  route={ROUTES.GOVERNANCE_TAGGING}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="BackupPolicies"
                                  name="Backup Policies"
                                  route={ROUTES.DATA_PROTECTION_BACKUP_JOBS}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Config</p>
                            </div>
                            <div className="validate-dashboard__box-body is-2">
                              <ul>
                                <FeatureComponent
                                  feature="ConfigRecorders"
                                  name="Recorders"
                                  route={ROUTES.GOVERNANCE_RECORDERS}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="ConfigRules"
                                  name="Rules"
                                  route={ROUTES.GOVERNANCE_RULES}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Budgets</p>
                            </div>
                            <div className="validate-dashboard__box-body">
                              <ul>
                                <FeatureComponent
                                  feature="AWSBudgets"
                                  name="Budgets"
                                  route={ROUTES.GOVERNANCE_AWS_BUDGETS}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Service Catalog</p>
                            </div>
                            <div className="validate-dashboard__box-body is-2">
                              <ul>
                                <FeatureComponent
                                  feature="ServiceCatalogPortfolios"
                                  name="Portfolios"
                                  route={ROUTES.GOVERNANCE_PORTFOLIOS}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="ServiceCatalogProducts"
                                  name="Products"
                                  route={ROUTES.GOVERNANCE_PRODUCTS}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="validate-dashboard__block">
                        <div className="validate-dashboard__block-header">
                          <div>
                            <p>Monitoring</p>
                          </div>
                          {/* <div>
                            <span className="refresh"></span>
                          </div> */}
                        </div>
                        <div className="validate-dashboard__block-body">
                          <div className="validate-dashboard__box validate-dashboard__box--half">
                            <div className="validate-dashboard__box-header">
                              <p>CloudWatch</p>
                            </div>
                            <div className="validate-dashboard__box-body">
                              <ul>
                                <FeatureComponent
                                  feature="CloudWatchEventRules"
                                  name="Events"
                                  route={ROUTES.MONITORING_CLOUDWATCH_EVENTS}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                          <div className="validate-dashboard__box validate-dashboard__box--half">
                            <div className="validate-dashboard__box-header">
                              <p>Centralized Logging</p>
                            </div>
                            <div className="validate-dashboard__box-body">
                              <ul>
                                <FeatureComponent
                                  feature="S3Buckets"
                                  name="S3 Buckets"
                                  route={ROUTES.MONITORING_CENTRALIZED_LOGGING}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Log Analytics</p>
                            </div>
                            <div className="validate-dashboard__box-body is-3">
                              <ul>
                                <FeatureComponent
                                  feature="KinesisFirehoses"
                                  name="Firehose Delivery Streams"
                                  route={ROUTES.MONITORING_CENTRALIZED_LOGGING}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="ApplicationLoadBalancers"
                                  name="ALBs"
                                  route={ROUTES.MONITORING_CENTRALIZED_LOGGING}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="ALBTargetGroups"
                                  name="ALBs Target Groups"
                                  route={ROUTES.MONITORING_CENTRALIZED_LOGGING}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="validate-dashboard__block">
                        <div className="validate-dashboard__block-header">
                          <div>
                            <p>Data Protection</p>
                          </div>
                          {/* <div>
                            <span className="decline">
                              <i className="icon-font icon-close"></i>
                            </span>
                          </div> */}
                        </div>
                        <div className="validate-dashboard__block-body">
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Backup</p>
                            </div>
                            <div className="validate-dashboard__box-body is-5">
                              <ul>
                                <FeatureComponent
                                  feature="BackupPolicies"
                                  name="Policies"
                                  route={ROUTES.DATA_PROTECTION_BACKUP_POLICY}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="BackupVaults"
                                  name="Vaults"
                                  route={ROUTES.DATA_PROTECTION_BACKUP_VAULTS}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="BackupPlans"
                                  name="Plans"
                                  route={ROUTES.DATA_PROTECTION_BACKUP_PLAN}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="BackupJobs"
                                  name="Backup Jobs"
                                  route={ROUTES.DATA_PROTECTION_BACKUP_JOBS}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="BackupCopyJobs"
                                  name="Copy Jobs"
                                  route={
                                    ROUTES.DATA_PROTECTION_BACKUP_COPY_JOBS
                                  }
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Certificate Mgmt.</p>
                            </div>
                            <div className="validate-dashboard__box-body">
                              <ul>
                                <FeatureComponent
                                  feature="ACMCertificates"
                                  name="ACM Certificates"
                                  route={ROUTES.DATA_PROTECTION_ACM}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                          <div className="validate-dashboard__box">
                            <div className="validate-dashboard__box-header">
                              <p>Key Management</p>
                            </div>
                            <div className="validate-dashboard__box-body is-2">
                              <ul>
                                <FeatureComponent
                                  feature="KMSKeyStores"
                                  name="Key Stores"
                                  route={ROUTES.DATA_PROTECTION_KEY_STORES}
                                ></FeatureComponent>
                                <FeatureComponent
                                  feature="KMSCMKs"
                                  name="Keys"
                                  route={ROUTES.DATA_PROTECTION_KEYS}
                                ></FeatureComponent>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {console.log(validateData)}
      </div>
    </>
  );
};

export default Validate;
