
import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getBooleanValue} from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";


const dashboardName = "Config Recorders";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
      <>
        <h3>Config</h3>
        <div className="otherTableTitles ">
          <h3 className="font-15 activetable">   
             Recorders
          </h3>
          <h3 className="font-15">  
          <Link className="text-color-gray" to={ROUTES.GOVERNANCE_RULES + "/" + projectId}>  
             Rules
         </Link>
          </h3>
        </div>
      </>
    );
  };


  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Resource Types To Record" && "my_active"
                }`}
                onClick={() => setType("Resource Types To Record")}
              >
               Resource Types To Record
              </li>
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Recorder Status</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.ConfigurationRecordersStatus &&
                    getBooleanValue(selectedRow.ConfigurationRecordersStatus[0], "recording") 
                    ? "Recording is on": "Recording is off"} 
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    AWS Config Role
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.ConfigurationRecordersStatus &&
                    selectedRow.ConfigurationRecordersStatus[0].name}
                  </p>
                </div>
  
              </div>
              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                   Data Retention Period
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow ? getRetentionPeriod(selectedRow) : "" }
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Logs S3 Bucket Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    SNS Topic Name
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {}
                  </p>
                </div>
              </div>
            </div>
                  
            <>
              { selectedRow && selectedRow.recordingGroup &&
                (selectedRow.recordingGroup.allSupported) ? 
                (
                  <div
                  className={`bolgeler-container w-100 row p-5 ${
                    type !== "Resource Types To Record" && "inaktif"
                  }`} >
                <pre className="font-12"> 
                  { "All Resources Supported" } </pre>
                  </div>)
                 :
                  selectedRow.recordingGroup.resourceTypes && (
                    <DetailsGrid
                    type={type}
                    typeName="Resource Types to Record"
                    array={selectedRow && selectedRow.recordingGroup &&
                      selectedRow.recordingGroup.resourceTypes}
                    metadata={[
                      { header: "Resource Types to Record", key: "resourceTypes" }   
                    ]}
                  ></DetailsGrid>
                ) 
                } 
                </>
             
           

          </div>
        </div>
      </div>
    );
  };

  const getRetentionPeriod = (data) => {
   var retentionPeriod = "7 Years";
    
   if(data && data.RetentionPeriodInDays)
   {
      retentionPeriod =  data.RetentionPeriodInDays * 0.002738 + " Year(s)";
   }
   return retentionPeriod;
 }

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.GOVERNANCE_SERVICE_CONTROL + "/" + projectId} >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Organization Policies</p>       
      </Link> 

    <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.GOVERNANCE_AWS_BUDGETS + "/" + projectId}
    >
      <p className="m-0 p-0 mr-2 font-weight-bold">AWS Budgets</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
    </>
    );
  };
  
  
const Recorders = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account Id",
        accessor: "AccountId",
        id:"Account Id"
      },
      {
        Header: "AWs Region",
        accessor: "Region",
        id:"AWS Region"
      },
      {
        Header: "Recorder Status",
        accessor: ((d) => getBooleanValue(d.ConfigurationRecordersStatus[0], "recording") 
        ? "Recording is on": "Recording is off"),
        id: "Recorder Status"
      },
      {
        Header: "AWS Config Role",
        accessor: "ConfigurationRecordersStatus[0].name",
        id: "AWS Config Role"
      },
      {
        Header: "Data Retention Period",
        accessor: ((d) =>  getRetentionPeriod(d)),
        id: "Data Retention Period"
      },
      {
        Header: "Logs S3 Bucket Name",
        accessor: "",
        id: "Logs S3 Bucket Name"
      },
      {
        Header: "SNS Topic Name",
        accessor: "",
        id: "SNS Topic Name"
      }
    ],
    []
  );

  return Dashboard({
    domain: "governance",
    title: "Governance",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.GOVERNANCE_SERVICE_CONTROL_POLICIES,
    designRoute: ROUTES.DESIGN_GOVERNANCE,
    SubMenu: SubMenu,
  });
};

export default Recorders;