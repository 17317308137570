import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import useServiceAccounts from "../../../../hooks/useServiceAccounts";
import useRegions from "../../../../hooks/useRegions";
import { validateAccountEmailId } from "../../../../helpers/validator";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";
import { array, object } from "yup";

const PREFIX = "/aws/security/compliancemanagement";

const ComplianceManagement = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    deploycentralizedcompliancemgmt: {
      value: "no",
    },
    securityhub: {
      deploycentralizedsecurityhub: { value: "yes" },
      securityhubmasteraccount: { value: "" },
      /* enableawsfoundationalsecuritybestpractices: { value: "no" },
      enablecisawsfoundationsbenchmark: { value: "no" },
      enablepcidss: { value: "no" }, */
      standardslist: [],
    },
    auditmanager: {
      enableauditmanager: { value: "yes" },
      delegatedauditmanageraccount: { value: "" },
      frameworkslist: [],
      regionslist: [],
      accountslist: [],
    },
    systemData: {
      ServicesInScope: [],
      StandardAssessmentFrameworks: [],
      Standards: [],
    },
  });

  const [empty, setEmpty] = useState(false);
  const [frameworkslistArray, setframeworklistArray] = useState([]);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  const serviceAccounts = useServiceAccounts(projectId);
  const regions = useRegions(projectId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);

        const systemData = await awsAmplify.getComplianceManagement();

        console.log(systemData);

        setIsLoading(false);

        setEmpty(result.data.empty);

        setFormData(
          convertBEToFE(result.data, systemData.data.getComplianceManagement)
        );
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data, systemData) => {
    if (!data) {
      data = {};
    }

    const fe = {
      deploycentralizedcompliancemgmt: {
        value: data[`${PREFIX}/deploycentralizedcompliancemgmt`] || "no",
      },
      securityhub: {
        deploycentralizedsecurityhub: {
          value:
            data[`${PREFIX}/securityhub/deploycentralizedsecurityhub`] || "yes",
        },
        securityhubmasteraccount: {
          value: data[`${PREFIX}/centralizedsecurityservicesaccount`] || "",
        },
        /* enableawsfoundationalsecuritybestpractices: {
          value:
            data[
              `${PREFIX}/securityhub/enableawsfoundationalsecuritybestpractices`
            ] || "no",
        },
        enablecisawsfoundationsbenchmark: {
          value:
            data[`${PREFIX}/securityhub/enablecisawsfoundationsbenchmark`] ||
            "no",
        },
        enablepcidss: {
          value: data[`${PREFIX}/securityhub/enablepcidss`] || "no",
        }, */
        standardslist:
          data[`${PREFIX}/securityhub/standardslist`] &&
          data[`${PREFIX}/securityhub/standardslist`].length > 0
            ? data[`${PREFIX}/securityhub/standardslist`].split(",")
            : [],
      },
      auditmanager: {
        enableauditmanager: {
          value: data[`${PREFIX}/auditmanager/enableauditmanager`] || "yes",
        },
        delegatedauditmanageraccount: {
          value:
            data[`${PREFIX}/auditmanager/delegatedauditmanageraccount`] || "",
        },
        frameworkslist:
          data[`${PREFIX}/auditmanager/frameworkslist`] &&
          data[`${PREFIX}/auditmanager/frameworkslist`].length > 0
            ? data[`${PREFIX}/auditmanager/frameworkslist`].split(",")
            : [],
        regionslist:
          data[`${PREFIX}/auditmanager/regionslist`] &&
          data[`${PREFIX}/auditmanager/regionslist`].length > 0
            ? data[`${PREFIX}/auditmanager/regionslist`].split(",")
            : [],
        accountslist:
          data[`${PREFIX}/auditmanager/accountslist`] &&
          data[`${PREFIX}/auditmanager/accountslist`].length > 0
            ? data[`${PREFIX}/auditmanager/accountslist`].split(",")
            : [],
      },
      systemData: systemData,
    };

    fe.systemData.ServicesInScope.unshift({
      Name: "All Supported Services",
      Value: "AllSupportedServices",
    });

     if( data[`${PREFIX}/auditmanager/frameworks`])
     {
        var frameworksArray =  JSON.parse( data[`${PREFIX}/auditmanager/frameworks`]);
        setframeworklistArray(frameworksArray);
     }
  
    console.log(fe);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploycentralizedcompliancemgmt`]: formData
        .deploycentralizedcompliancemgmt.value,
    };

    if (formData.deploycentralizedcompliancemgmt.value === "yes") {
      be[`${PREFIX}/securityhub/deploycentralizedsecurityhub`] =
        formData.securityhub.deploycentralizedsecurityhub.value;

      if (formData.securityhub.securityhubmasteraccount.value !== "") {
        be[`${PREFIX}/centralizedsecurityservicesaccount`] =
          formData.securityhub.securityhubmasteraccount.value;
      }

      if (formData.securityhub.deploycentralizedsecurityhub.value === "yes") {
        /* be[`${PREFIX}/securityhub/enableawsfoundationalsecuritybestpractices`] =
          formData.securityhub.enableawsfoundationalsecuritybestpractices.value;
        be[`${PREFIX}/securityhub/enablecisawsfoundationsbenchmark`] =
          formData.securityhub.enablecisawsfoundationsbenchmark.value;
        be[`${PREFIX}/securityhub/enablepcidss`] =
          formData.securityhub.enablepcidss.value; */

        if (formData.securityhub.standardslist.length > 0) {
          be[
            `${PREFIX}/securityhub/standardslist`
          ] = formData.securityhub.standardslist.join(","); 
        }
      }

      be[`${PREFIX}/auditmanager/enableauditmanager`] =
        formData.auditmanager.enableauditmanager.value;

      if (formData.auditmanager.enableauditmanager.value === "yes") {
        if (formData.auditmanager.delegatedauditmanageraccount.value !== "") {
          be[`${PREFIX}/auditmanager/delegatedauditmanageraccount`] =
            formData.auditmanager.delegatedauditmanageraccount.value;
        }

        if(frameworkslistArray && frameworkslistArray.length > 0) {
          be[`${PREFIX}/auditmanager/frameworks`] = JSON.stringify(frameworkslistArray);
        var arraylist = getFrameworkslist();
        if(arraylist && arraylist.length > 0)
            be[`${PREFIX}/auditmanager/frameworkslist`] = `{${arraylist.join(',')}}`;
      }

        if (formData.auditmanager.regionslist.length > 0) {
          be[`${PREFIX}/auditmanager/regionslist`] = formData.auditmanager.regionslist.join(",");
        }

        if (formData.auditmanager.accountslist.length > 0) {
          be[
            `${PREFIX}/auditmanager/accountslist`
          ] = formData.auditmanager.accountslist.join(",");
        }
      }
    } else {
      be[`${PREFIX}/auditmanager/enableauditmanager`] = "no";
    }

    return be;
  };

  const getFrameworkslist = () => {
    var strlist = [];
    if(frameworkslistArray && frameworkslistArray.length > 0){
      for(var item in frameworkslistArray)
      {
        var obj = frameworkslistArray[item];
        if(obj) {
        const key =  Object.keys(obj)[0];
        if(key) {
        const value = obj[key];
        if(value)
          strlist.push(`'${key}':'${value}'`);
            }
          } 
        }
      }
      return strlist;
  }

 

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const onFieldChange = (value, key1, key2) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key1]: { [key2]: { value: { $set: value } } } })
    );
  };

  const exists = (key, array, value) => {
    return formData[key][array].indexOf(value) !== -1;
  };

  const checkKey = (keyName, keyValue) => {
    const existsKey = frameworkslistArray && frameworkslistArray.find(x=> x[keyName] == keyValue);
    if(existsKey)
      return true;
    else
      return false;
  }

  const addObj = (objKeyName, objKeyValue) => {
    setIsDirty(true);
    var obj = {};
    obj[objKeyName] = objKeyValue;
    setframeworklistArray( frameworkslistArray => [...frameworkslistArray, obj]);
  }

  
  const removeObj = (objKeyName, objKeyValue) => {
    setIsDirty(true);
    setframeworklistArray((frameworkslistArray.filter(objItem => objItem[objKeyName] != objKeyValue)));
  };

  const modifyObj = (objKeyName, objKeyValue) => {
    if (checkKey( objKeyName, objKeyValue)) {
      removeObj(objKeyName, objKeyValue);
    } else {
      addObj(objKeyName, objKeyValue);
    }
  };

  const add = (key, array,value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key]: { [array]: { $push: [value] } } })
    );
  };

  const remove = (key, array, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          [array]: { $splice: [[state[key][array].indexOf(value), 1]] },
        },
      })
    );
  };

  const modifyArray = (key, array, value) => {
    if (exists(key, array, value)) {
      remove(key, array, value);
    } else {
      add(key, array, value);
    }
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Security</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Security"
                isDirty={isDirty}
                dashboardRoute={ROUTES.SECURITY_HUB_MEMBERS}
                designRoute={ROUTES.DESIGN_SECURITY}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 ">
                <div className="row d-flex align-items-center mb-5">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3 className="font-weight-bold">Compliance Management</h3>
                  </div>
                  <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                        Deploy Centralized Compliance Management
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                        <label
                          className={`createElementBtn btn btn-link btn-linkYes mw-70 ${
                            formData.deploycentralizedcompliancemgmt.value ===
                              "yes" && "active"
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={
                              formData.deploycentralizedcompliancemgmt.value ===
                              "yes"
                            }
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) =>
                                update(state, {
                                  deploycentralizedcompliancemgmt: {
                                    value: { $set: "yes" },
                                  },
                                })
                              );
                            }}
                          />{" "}
                          YES
                        </label>
                        <label
                          className={`createElementBtn btn btn-link btn-linkNo mw-70 ${
                            formData.deploycentralizedcompliancemgmt.value ===
                              "no" && "active"
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={
                              formData.deploycentralizedcompliancemgmt.value ===
                              "no"
                            }
                            /* onChange={() => {
                              setIsDirty(true);
                              setFormData((state) =>
                                update(state, {
                                  deploycentralizedcompliancemgmt: {
                                    value: { $set: "no" },
                                  },
                                })
                              );
                            }} */
                          />{" "}
                          NO
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {empty && (
                  <NoInitialData />
                )}
                {formData.deploycentralizedcompliancemgmt.value === "yes" && (
                  <div className="row show-hide-content">
                    <div className="d-inline-flex flex-wrap w-100 my-1 row">
                      <div className="d-inline-flex col-md-4 col-lg-3 pl-lg-0 px-2 my-1">
                        <label className="font-12 d-flex align-items-center mb-0 ">
                          Centralized Security Services Account
                        </label>
                      </div>
                      <div className="col-md-8 col-lg-9">
                        <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                          <select
                            className="form-control gray"
                            value={
                              formData.securityhub.securityhubmasteraccount
                                .value
                            }
                            onChange={(e) => {
                              onFieldChange(
                                e.target.value,
                                "securityhub",
                                "securityhubmasteraccount"
                              );
                            }}
                          >
                            <option value="">Select</option>
                            {serviceAccounts.security.map((sa, idxSa) => (
                              <option key={idxSa} value={sa}>
                                {sa}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 d-flex pr-0 align-items-center  rightNavArea">
                      <div className="d-inline-flex yes-no-area align-items-center">
                        <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                          Centralize SecurityHub Findings
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                          <label
                            className={`createSubElementBtn btn btn-link btn-linkYes mw-70 ${
                             (formData && formData.securityhub &&
                              formData.securityhub.deploycentralizedsecurityhub
                                .value) === "yes" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                               (formData && formData.securityhub && formData.securityhub
                                  .deploycentralizedsecurityhub.value) === "yes"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    securityhub: {
                                      deploycentralizedsecurityhub: {
                                        value: { $set: "yes" },
                                      },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            YES
                          </label>
                          <label
                            className={`createSubElementBtn btn btn-link btn-linkNo mw-70 ${
                              (formData && formData.securityhub &&
                              formData.securityhub.deploycentralizedsecurityhub
                                .value) === "no" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                (formData && formData.securityhub &&
                                formData.securityhub
                                  .deploycentralizedsecurityhub.value) === "no"
                              }
                              // onChange={() => {
                              //   setIsDirty(true);
                              //   setFormData((state) =>
                              //     update(state, {
                              //       securityhub: {
                              //         deploycentralizedsecurityhub: {
                              //           value: { $set: "no" },
                              //         },
                              //       },
                              //     })
                              //   );
                              // }}
                            />{" "}
                            NO
                          </label>
                        </div>
                      </div>
                    </div>
                    { (formData && formData.securityhub && formData.securityhub.deploycentralizedsecurityhub.value) ===
                      "yes" && (
                      <>
                        <div className="mainContentArea  row px-2 py-1 mt-2">
                          {formData.systemData.Standards.map(
                            (data, idxData) => (
                              <div
                                className="col-md-6 col-12 px-1 my-1"
                                key={idxData}
                              >
                                <div className="formRow w-100">
                                  <div className="one form-line d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "securityhub",
                                          "standardslist",
                                          data.Value
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "securityhub",
                                            "standardslist",
                                            data.Value
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">
                                      {data.Name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </>
                    )}

                    <div className="d-inline-flex flex-wrap w-100 my-3 mr-2 row">
                      <div className="col-12 d-flex pr-0 align-items-center  rightNavArea">
                        <div className="d-inline-flex yes-no-area align-items-center">
                          <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                            Enable Audit Manager
                          </div>
                          <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                            <label
                              className={`createSubElementBtn btn btn-link btn-linkYes mw-70 ${
                                formData.auditmanager.enableauditmanager
                                  .value === "yes" && "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name="options"
                                checked={
                                  formData.auditmanager.enableauditmanager
                                    .value === "yes"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                      auditmanager: {
                                        enableauditmanager: {
                                          value: { $set: "yes" },
                                        },
                                      },
                                    })
                                  );
                                }}
                              />{" "}
                              YES
                            </label>
                            <label
                              className={`dontCreateSubElementBtn btn btn-link btn-linkNo mw-70 ${
                                formData.auditmanager.enableauditmanager
                                  .value === "no" && "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name="options"
                                checked={
                                  formData.auditmanager.enableauditmanager
                                    .value === "no"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                      auditmanager: {
                                        enableauditmanager: {
                                          value: { $set: "no" },
                                        },
                                      },
                                    })
                                  );
                                }}
                              />{" "}
                              NO
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {formData.auditmanager.enableauditmanager.value ===
                      "yes" && (
                      <div className="show-hide-sub-content">
                        <div className="mainContentArea  row px-2 py-1 mt-1">
                          <div className="d-inline-flex align-items-center row px-1">
                            <div className="col-md-3 px-2 my-1">
                              <span className="mb-0 font-12">
                                Delegated Audit Manager Account
                              </span>
                            </div>
                            {
                              serviceAccounts.security && serviceAccounts.security.length === 0 && (
                              <span className="warning">
                                This depends on the Shared Services Accounts and Security Accounts informations in
                                'Platform Accounts" section of 'Account Framework' domain
                                that is yet to be provided.
                              </span>
                            )}
                         {serviceAccounts.security && serviceAccounts.security.length > 0 && (
                            <div className="col-md-9 flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  <select
                                    className="form-control gray"
                                    value={
                                      formData.auditmanager
                                        .delegatedauditmanageraccount.value
                                    }
                                    onChange={(e) => {
                                      onFieldChange(
                                        e.target.value,
                                        "auditmanager",
                                        "delegatedauditmanageraccount"
                                      );
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {serviceAccounts.security.map(
                                      (sa, idxSa) => (
                                        <option key={idxSa} value={sa}>
                                          {sa}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                              )}
                          </div>

                          <div className="d-inline-flex align-items-start row my-1 px-1">
                            <div className="col-md-3 px-2 my-1">
                              <span className="mb-0 font-12">Frameworks</span>
                            </div>
                          </div>

                          {formData.systemData.StandardAssessmentFrameworks.map(
                            (data, idxData) => (
                              <div
                                className="col-md-6 col-12 px-1 my-1"
                                key={idxData}
                              >
                                <div className="formRow w-100">
                                  <div className="one form-line d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={checkKey(
                                          data.Value,
                                          data.Name
                                        )}
                                        onChange={() =>
                                          modifyObj(
                                            data.Value,
                                            data.Name
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">
                                      {data.Name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )
                          )}

                          <div className="d-inline-flex flex-wrap my-1">
                            <span className="font-12 d-flex align-items-center mr-2 w-150  my-1">
                              AWS Regions
                            </span>
                            {regions.length === 0 && (
                              <span className="warning">
                                This depends on the AWS Regions informations in
                                'AWS Environment" section of 'Network' domain
                                that is yet to be provided.
                              </span>
                            )}
                            {regions.map((region, idxRegion) => (
                              <div
                                key={idxRegion}
                                className="one mr-2 bg-special-lightblue form-line w-md-250 py-2  my-1  d-inline-flex align-items-center"
                              >
                                <label className="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked={exists(
                                      "auditmanager",
                                      "regionslist",
                                      region
                                    )}
                                    onChange={() =>
                                      modifyArray(
                                        "auditmanager",
                                        "regionslist",
                                        region
                                      )
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <span className="switchLabel">{region}</span>
                              </div>
                            ))}
                          </div>

                          <div className="d-inline-flex flex-wrap my-1">
                            <span className="font-12 d-flex align-items-center mr-2 w-150  my-1">
                              AWS Accounts
                            </span>
                            {serviceAccounts.shared.concat(
                              serviceAccounts.security
                            ).length === 0 && (
                              <span className="warning">
                                This depends on the Shared Services Accounts and Security Accounts informations in
                                'Platform Accounts" section of 'Account Framework' domain
                                that is yet to be provided.
                              </span>
                            )}
                            {serviceAccounts.shared
                              .concat(serviceAccounts.security)
                              .map((account, idxAccount) => (
                                <div
                                  key={idxAccount}
                                  className="one mr-2 bg-special-lightblue form-line w-md-250 py-2  my-1  d-inline-flex align-items-center"
                                >
                                  <label className="switch mb-0">
                                    <input
                                      type="checkbox"
                                      checked={exists(
                                        "auditmanager",
                                        "accountslist",
                                        account
                                      )}
                                      onChange={() =>
                                        modifyArray(
                                          "auditmanager",
                                          "accountslist",
                                          account
                                        )
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <span className="switchLabel">{account}</span>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.SECURITY_INTELLIGENT_THREAT_DETECTION +
                          "/" +
                          projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Intelligent Threat Detection
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComplianceManagement;
