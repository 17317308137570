import { useEffect, useState } from "react";
import useAwsAmplify from "./useAwsAmplify";

const useServiceAccounts = (projectId) => {
  const awsAmplify = useAwsAmplify();
  const [serviceAccounts, setServiceAccounts] = useState({
    shared: [],
    security: [],
  });

  useEffect(() => {
    const fetchServiceAccounts = async () => {
      console.log("loading service accounts");
      let shared = [];

      const getProjectTypeResp = await awsAmplify.getProjectType(projectId);

      const projectType = getProjectTypeResp.data.getProjectType;

      const sharedservicesaccountslist_PREFIX = `/${projectType.toLowerCase()}/accountframework/platformaccounts/sharedservicesaccountslist`;
      const securityaccountslist_PREFIX = `/${projectType.toLowerCase()}/accountframework/platformaccounts/securityaccountslist`;

      console.log(sharedservicesaccountslist_PREFIX);
      console.log(securityaccountslist_PREFIX);

      const sharedServicesAccounts = await awsAmplify.loadProjectData(
        projectId,
        sharedservicesaccountslist_PREFIX
      );

      if (
        sharedServicesAccounts.data &&
        sharedServicesAccounts.data[sharedservicesaccountslist_PREFIX]
      ) {
        shared =
          sharedServicesAccounts.data[sharedservicesaccountslist_PREFIX].split(
            ","
          );
      }

      let security = [];

      const securityAccounts = await awsAmplify.loadProjectData(
        projectId,
        securityaccountslist_PREFIX
      );

      if (
        securityAccounts.data &&
        securityAccounts.data[securityaccountslist_PREFIX]
      ) {
        security =
          securityAccounts.data[securityaccountslist_PREFIX].split(",");
      }

      setServiceAccounts({
        shared: shared,
        security: security,
      });
    };

    fetchServiceAccounts();
  }, [projectId]);

  return serviceAccounts;
};

export default useServiceAccounts;
