import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import useAwsAmplify from "../hooks/useAwsAmplify";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string(),
  jobTitle: yup.string(),
  organization: yup.string(),
  message: yup.string(),
});

const ContactUsForm = ({ setIsLoading, setError, setInfo }) => {
  const awsAmplify = useAwsAmplify();

  const {
    register,
    handleSubmit,
    formState,
    errors,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    reValidateMode: "onBlur"
  });

  const onSubmit = async (formValues) => {
    setIsLoading(true);
    setError("");

    try {
      const results = await awsAmplify.contactUs(formValues);
      setIsLoading(false);

      setInfo("Your message has been sent.");
      reset();

      setTimeout(() => setInfo(""), 2000);
    } catch (err) {
      setIsLoading(false);

      if (err.message) {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    const errorMsgs = [];

    if (errors) {
      for (const field in errors) {
        if (errors[field].message) {
          errorMsgs.push(errors[field].message);
        }
      }
    }

    if (errorMsgs.length > 0) {
      setError(errorMsgs);
    }
  }, [errors.firstName, errors.lastName, errors.email]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-lg-12">
          <h2>Want us to get back to you?</h2>
        </div>
        <div
          className={"input-group col-lg-4 " + (errors.firstName && "invalid")}
        >
          <label htmlFor="">First Name*</label>
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            ref={register}
          />
        </div>
        <div
          className={"input-group col-lg-4 " + (errors.lastName && "invalid")}
        >
          <label htmlFor="">Last Name*</label>
          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            ref={register}
          />
        </div>
        <div className={"input-group col-lg-4 " + (errors.email && "invalid")}>
          <label htmlFor="">
            Business Email <sup>*</sup>{" "}
          </label>
          <input
            type="text"
            placeholder="Business Email Id"
            name="email"
            ref={register}
          />
        </div>
        <div className="input-group col-lg-4">
          <label htmlFor="">Mobile Number</label>
          <input
            type="tel"
            placeholder="Mobile Number"
            name="phone"
            ref={register}
          />
        </div>
        <div className="input-group col-lg-4">
          <label htmlFor="">Job Title</label>
          <input
            type="text"
            placeholder="Job Title"
            name="jobTitle"
            ref={register}
          />
        </div>
        <div className="input-group col-lg-4">
          <label htmlFor="">Organization</label>
          <input
            type="text"
            placeholder="Organization"
            name="organization"
            ref={register}
          />
        </div>
        <div className="input-group col-lg-12">
          <label htmlFor="">Your Message For Us</label>
          <textarea
            placeholder="Your Message For Us"
            name="message"
            ref={register}
          ></textarea>
        </div>
        <div className="col-lg-12 pl0">
          <button type="submit" className="signUp">
            <span>Send</span>
          </button>
        </div>
      </form>
    </>
  );
};

export default ContactUsForm;
