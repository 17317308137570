import React, { useEffect, useState } from "react";
import { getError } from "../../helpers/errorHelper";

import useAwsAmplify from "../../hooks/useAwsAmplify";

const UserRemoveForm = ({ close, user }) => {
  const awsAmplify = useAwsAmplify();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const removeProject = async () => {
    try {
      setIsLoading(true);

      const result = await awsAmplify.removeUser(user.UserID);
      console.log(result);

      setIsLoading(false);

      close(true, "remove", "Successfully deleted the user");
    } catch (err) {
      setIsLoading(false);

      const errorMsg = getError(err);
      if (errorMsg === "User doesn't exist") {
        close(true, "remove", null, errorMsg);
      }
      else{
        setErrorMsg(errorMsg);
      }
    }
  };

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="justify-content-center align-items-center popupArea delete-user">
        <div className="auth-main">
          <div className="auth-form">
            <button
              className="exit btn-animation"
              onClick={() => close(false, "remove")}
            >
              Exit
            </button>
            <div className="title">Delete User</div>
            <div className="auth-form-content d-flex flex-column justify-content-center">
              <div className="clientInfo d-flex flex-column justify-content-center w-100">
                <h1 className="text-center my-1">{`${user.FirstName} ${user.LastName}`}</h1>
                <p className="text-center companyName my-1">{user.CompanyName}</p>
              </div>

              <div className="adminInfo d-flex flex-column justify-content-center w-100">
                <span className="littleAdmin text-center my-1">Email ID</span>
                <p className="adminMail text-center my-1">{user.EmailID}</p>
              </div>

              <div className="halfBox d-flex flex-column">
                <label className="my-1 titleLabel">Assigned Projects</label>
                {user.AssignedProjects.length === 0 && <span>No projects assigned</span>}
                {user.AssignedProjects.map((assignedProject, idx) => (
                  <div
                    key={idx}
                    className="accessableProject inlineRow d-inline-flex justify-content-between my-1 align-items-center"
                    style={{ position: "relative" }}
                  >
                    <input
                      type="text"
                      className="form-control w-100 "
                      placeholder={assignedProject.ProjectName}
                      disabled
                    />
                  </div>
                ))}
              </div>
              <div className="halfBox d-flex flex-column ">
                <div className="warningMessageArea d-flex flex-column justify-content-center w-100 my-1">
                  <p className="redText text-center my-1">
                    You are about to delete this user.{" "}
                  </p>
                  <p className="boldText text-center ">
                    Doing so will remove access of this user from all projects
                    but will not
                    <br />
                    delete the Project Data
                  </p>
                </div>
              </div>
            </div>
            <div className="auth-form-footer d-inline-flex justify-content-center w-100">
              <div>
                <a
                  onClick={() => close(false, "remove")}
                  className="confirmBtn cancelBtn btn btn-link btn-green btn-animation mr-1"
                >
                  Cancel
                </a>
              </div>
              <div>
                <a
                  onClick={removeProject}
                  className="confirmBtn deleteBtn btn btn-link btn-green btn-animation ml-1"
                >
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserRemoveForm;
