
import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "Directories Shared With Me";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
        <>
          <h3>Directories</h3>
          <div className="otherTableTitles ">
          <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_DIRECTORIES + "/" + projectId}>  
              Directories 
            </Link>          
            </h3>
            <h3 className="font-15 activetable">   
              Shared with me    
            </h3>
          </div>
        </>
      );
  };

  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Owner Directory Details" && "my_active"
                }`}
                onClick={() => setType("Owner Directory Details")}
              >
               Owner Directory Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Trust Relationships" && "my_active"
                }`}
                onClick={() => setType("Trust Relationships")}
              >
                Trust Relationships
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "AWS Apps & Services" && "my_active"
                }`}
                onClick={() => setType("AWS Apps & Services")}
              >
               AWS Apps & Services
              </li> 
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Owner Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.OwnerAccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Owner Directory Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.OwnerDirectoryId}
                  </p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Shared Directory ID
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.SharedDirectoryId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Share Status</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.ShareStatus}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                   Date Shared
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.CreatedDateTime}
                  </p>
                </div>
             </div>
            </div>

            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Owner Directory Details" && "inaktif"
              }`}
            >

              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Directory ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.OwnerDirectoryId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Directory Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {/*TO DO */}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">VPC ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && (selectedRow.VpcId ? selectedRow.VpcId : "-" )}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Status</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && ( selectedRow.ShareStatus ? selectedRow.ShareStatus : "-") }
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Status Reason</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow &&  (selectedRow.StatusReason ? selectedRow.StatusReason: "-") }
                  </p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Subnets
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && (selectedRow.Subnets ? selectedRow.Subnets : "-" )}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Availability Zones</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && (selectedRow.AvailabilityZones ? selectedRow.AvailabilityZones.join(",") : "-")}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  DNS Addresses
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && (selectedRow.DNSAddresses ? selectedRow.DNSAddresses.join(",") : "-")}
                  </p>
                </div>
             </div>
             
            </div>

           <DetailsGrid
              type={type}
              typeName="Trust Relationships"
              array={selectedRow.TrustRelationships}
              metadata={[
                { header: "Domain Name", key: "RemoteDomainName" },
                { header: "Type", key: "TrustType" },
                { header: "Status", key: "TrustState" },
                { header: "Direction", key: "TrustDirection" },
                { header: "Selective Auth", key: "SelectiveAuth" }, 
                { header: "Conditional Fowarder IPs", key: "IPs" }, 
                { header: "Last Verified", key: "LastUpdatedDateTime" }            
              ]}
            ></DetailsGrid> 

          {/*    <DetailsGrid
              type={type}
              typeName="AWS Apps & Services"
              array={selectedRow["AWS Apps & Services"]}
              metadata={[
                { header: "Application", key: "Application" },
                { header: "Status", key: "Status" },
                { header: "URL to applications", key: "URL" },      
              ]}
            ></DetailsGrid>  */}
            </div>

          </div>
        </div>
    );
  };

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
      <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.INFRASTRUCTURE_INBOUND_ENDPOINTS + "/" + projectId}
    >
      <p className="m-0 p-0 ml-2 font-weight-bold">Private DNS</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
      </>
    );
  };
  
  
const Directories = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "Owner Account ID",
        accessor: "OwnerAccountId",
        id: "Owner Account ID"
      },
      {
        Header: "Owner Directory Name",
        accessor: "OwnerDirectoryName",
        id: "Owner Directory Name"
      },
      {
        Header: "Shared Directory ID",
        accessor: "SharedDirectoryId",
        id: "Shared Directory ID"
      },
      {
        Header: "Share Status",
        accessor: "ShareStatus",
        id: "Share Status"
      },
      {
        Header: "Date Shared",
        accessor: "CreatedDateTime",
        id: "Date Shared"
      }
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "infrastructure",
    title: "Infrastructure",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.INFRASTRUCTURE_ADIntegration,
    designRoute: ROUTES.DESIGN_INFRASTRUCTURE,
    SubMenu: SubMenu,
  });
};

export default Directories;