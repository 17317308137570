import React from "react";
import useSession from "../hooks/useSession";

const ProtectedComponent = ({ Component }) => {
  const { authUser } = useSession();

  return <>{authUser ? <Component></Component> : null}</>;
};

export default ProtectedComponent;
