import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

//const projectId = "google_1";
const PREFIX = "/aws/network/crossregionconnectivity";

const REGION_KEY = "/aws/network/aws/awsregionslist";
const INTERVPC_KEY = "/aws/network/intervpcconectivity/intervpcnetwork";
const SEGMENTEDENVIRONMENTS_KEY =
  "/aws/network/intervpcconectivity/segmentedenvironments";

const CrossRegionConnectivity = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    deploycrossregionconnectivity: {
      value: "yes",
    },
    array: [],
    regions: [],
    segmentedEnvironments: [],
  });

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        console.log(result);

        const regionResult = await awsAmplify.loadProjectData(
          projectId,
          REGION_KEY
        );
        console.log(regionResult);

        const interVPCResult = await awsAmplify.loadProjectData(
          projectId,
          INTERVPC_KEY
        );
        console.log(interVPCResult);

        const segmentedEnvironmentsResult = await awsAmplify.loadProjectData(
          projectId,
          SEGMENTEDENVIRONMENTS_KEY
        );
        console.log(segmentedEnvironmentsResult);

        setIsLoading(false);

        setEmpty(result.data.empty);

        const fe = convertBEToFE(
          result.data,
          regionResult.data,
          interVPCResult.data,
          segmentedEnvironmentsResult.data
        );

        setFormData(fe);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);

      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (
    data,
    regionData,
    interVpcData,
    segmentedEnvironmentsData
  ) => {
    if (!data) {
      data = {};
    }

    const fe = {
      deploycrossregionconnectivity: {
        value: data[`${PREFIX}/deploycrossregionconnectivity`] || "yes",
      },
      array: [createEntity()],
      regions:
        regionData && regionData[REGION_KEY]
          ? regionData[REGION_KEY].split(",")
          : [],
      interVpc:
        interVpcData && interVpcData[INTERVPC_KEY]
          ? interVpcData[INTERVPC_KEY]
          : null,
      segmentedEnvironments:
        segmentedEnvironmentsData &&
        segmentedEnvironmentsData[SEGMENTEDENVIRONMENTS_KEY]
          ? segmentedEnvironmentsData[SEGMENTEDENVIRONMENTS_KEY].split(",")
          : [],
    };

    if (data[`${PREFIX}/crconnectionslist`]) {
      fe.array = [];

      const crconnectionslist = data[`${PREFIX}/crconnectionslist`].split(",");

      crconnectionslist.forEach((cr) => {
        fe.array.push({
          requesterregion: data[`${PREFIX}/${cr}/requesterregion`] || "",
          accepterregion: data[`${PREFIX}/${cr}/accepterregion`] || "",
          //environment: data[`${PREFIX}/${cr}/environment`] || "",
        });
      });
    }

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploycrossregionconnectivity`]: formData
        .deploycrossregionconnectivity.value,
    };

    if (formData.deploycrossregionconnectivity.value === "yes") {
      const validItems = [];

      formData.array.forEach((item, idxItem) => {
        let hasValidItem = false;

        if (item.requesterregion !== "") {
          be[`${PREFIX}/crconnection${idxItem + 1}/requesterregion`] =
            item.requesterregion;
          hasValidItem = true;
        }

        if (item.accepterregion !== "") {
          be[`${PREFIX}/crconnection${idxItem + 1}/accepterregion`] =
            item.accepterregion;
          hasValidItem = true;
        }

        /* if (item.environment !== "") {
          be[`${PREFIX}/crconnection${idxItem + 1}/environment`] =
            item.environment;
          hasValidItem = true;
        } */

        if (hasValidItem) {
          validItems.push(`crconnection${idxItem + 1}`);
        }
      });

      if (validItems.length > 0) {
        be[`${PREFIX}/crconnectionslist`] = validItems.join(",");
      }
    }

    return be;
  };

  const addArrayItem = () => {
    if (
      formData.array.length <
      (formData.regions.length * (formData.regions.length - 1)) / 2
    ) {
      setIsDirty(true);

      setFormData((state) =>
        update(state, { array: { $push: [createEntity()] } })
      );
    }
  };

  const removeArrayItem = (idx) => {
    if (formData.array.length > 1) {
      setIsDirty(true);

      setFormData((state) => update(state, { array: { $splice: [[idx, 1]] } }));
    }
  };

  const isAllowed = (item, idxItem) => {
    if (item.requesterregion !== "" && item.accepterregion !== "") {
      if (item.requesterregion === item.accepterregion) {
        setErrorMsg("Same Requester and Accepter Region is not allowed");
        return false;
      }

      for (const [idxOtherItem, otherItem] of formData.array.entries()) {
        if (idxItem !== idxOtherItem) {
          if (
            otherItem.requesterregion !== "" &&
            otherItem.accepterregion !== ""
          ) {
            if (
              (item.requesterregion === otherItem.requesterregion &&
                item.accepterregion === otherItem.accepterregion) ||
              (item.requesterregion === otherItem.accepterregion &&
                item.accepterregion === otherItem.requesterregion)
            ) {
              setErrorMsg(
                "Same Requester/Accepter Region or Accepter/Requester Region combination is not allowed in any 2 rows"
              );
              return false;
            }
          }
        }
      }
    }
    return true;
  };

  const createEntity = () => {
    return {
      requesterregion: "",
      accepterregion: "",
      //environment: "",
    };
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Network</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Network"
                isDirty={isDirty}
                dashboardRoute={ROUTES.VPC}
                designRoute={ROUTES.DESIGN_NETWORK}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight network-aws-environment flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div className="row d-flex align-items-center mb-2">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3 className="font-weight-bold">
                      Cross-region Connectivity
                    </h3>
                  </div>
                  {/* {formData.regions.length !== 0 &&
                    formData.segmentedEnvironments.length !== 0 && ( */}
                  {formData.regions.length !== 0 && (
                    <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                      <div className="d-inline-flex yes-no-area align-items-center">
                        <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                          Deploy Cross-region Connectivity
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                          <label
                            className={`createElementBtn btn btn-link btn-linkYes mw-70 ${
                              formData.deploycrossregionconnectivity.value ===
                                "yes" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.deploycrossregionconnectivity.value ===
                                "yes"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    deploycrossregionconnectivity: {
                                      value: { $set: "yes" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            YES
                          </label>
                          <label
                            className={`dontCreateElementBtn btn btn-link btn-linkNo mw-70 ${
                              formData.deploycrossregionconnectivity.value ===
                                "no" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.deploycrossregionconnectivity.value ===
                                "no"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    deploycrossregionconnectivity: {
                                      value: { $set: "no" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            NO
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {empty && (
                  <NoInitialData />
                )}
                {/* {formData.regions.length === 0 ||
                formData.segmentedEnvironments.length === 0 ? ( */}
                {formData.regions.length === 0 ? (
                  <span className="warning">
                    This section depends on data in AWS Environment and InterVPC
                    Connectivity sections in Network domain, which are yet to be
                    provided
                  </span>
                ) : (
                  formData.deploycrossregionconnectivity.value === "yes" && (
                    <div className="show-hide-content">
                      <div className=" d-flex flex-column   pb-3 pt-0">
                        <div className="row mainContentArea d-flex flex-column px-md-2 px-2 my-1 py-1">
                          {formData.array.map((item, idxItem) => (
                            <div key={idxItem} className="row p-relative my-1 ">
                              <div className="col-12  formRow d-inline-flex flex-wrap">
                                <div className="d-flex flex-sm-row flex-column form-line3 flex-wrap my-lg-0 my-1 col-md-5 col-12">
                                  <span className="font-12 text-nowrap d-flex align-items-center mr-2">
                                    requester Region
                                  </span>
                                  <select
                                    className="form-control w-200"
                                    value={item.requesterregion}
                                    onChange={(e) => {
                                      setErrorMsg(null);
                                      const value = e.target.value;

                                      if (value) {
                                        if (
                                          isAllowed(
                                            { ...item, requesterregion: value },
                                            idxItem
                                          )
                                        ) {
                                          setIsDirty(true);
                                          setFormData((state) =>
                                            update(state, {
                                              array: {
                                                [idxItem]: {
                                                  requesterregion: {
                                                    $set: value,
                                                  },
                                                },
                                              },
                                            })
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {formData.regions.map(
                                      (region, idxRegion) => (
                                        <option key={idxRegion} value={region}>
                                          {region}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <div className="d-flex flex-sm-row flex-column form-line3 flex-wrap my-lg-0 my-1 col-md-5 col-12">
                                  <span className="font-12 text-nowrap d-flex align-items-center mr-2">
                                    accepter Region
                                  </span>
                                  <select
                                    className="form-control w-200"
                                    value={item.accepterregion}
                                    onChange={(e) => {
                                      setErrorMsg(null);
                                      const value = e.target.value;

                                      if (value) {
                                        if (
                                          isAllowed(
                                            { ...item, accepterregion: value },
                                            idxItem
                                          )
                                        ) {
                                          setIsDirty(true);
                                          setFormData((state) =>
                                            update(state, {
                                              array: {
                                                [idxItem]: {
                                                  accepterregion: {
                                                    $set: value,
                                                  },
                                                },
                                              },
                                            })
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {formData.regions.map(
                                      (region, idxRegion) => (
                                        <option key={idxRegion} value={region}>
                                          {region}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                {/* <div className="d-inline-flex form-line3 my-lg-0 my-1">
                                  <span className="font-12 text-nowrap d-flex align-items-center mr-2">
                                    Environment
                                  </span>
                                  <select
                                    className="form-control mw-200"
                                    value={item.environment}
                                    onChange={(e) => {
                                      setErrorMsg(null);
                                      const value = e.target.value;

                                      if (value) {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            array: {
                                              [idxItem]: {
                                                environment: { $set: value },
                                              },
                                            },
                                          })
                                        );
                                      }
                                    }}
                                  >
                                    <option value="">Select</option>
                                    <option value="All">All</option>

                                    {formData.interVpc === "segmentednetwork" &&
                                      formData.segmentedEnvironments.map(
                                        (env, idxEnv) => (
                                          <option key={idxEnv} value={env}>
                                            {env}
                                          </option>
                                        )
                                      )}
                                  </select>
                                </div> */}
                                <div className="col-md-2 my-1 col-md-1 col-12 d-flex justify-content-end  closeFormRow align-items-center px-0">
                                  <button disabled={disabled}
                                    type="button"
                                    className="d-flex justify-content-end"
                                    onClick={() => removeArrayItem(idxItem)}
                                  >
                                    <img src="../images/close.svg" />
                                  </button>
                                </div>
                                <div className="col-md-2 my-1 col-12 closeFormRowMobil">
                                  <button disabled={disabled}
                                    type="button"
                                    className="d"
                                    onClick={() => removeArrayItem(idxItem)}
                                  >
                                    <img src="../images/close.svg" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="w-100 px-md-2 p-2 mb-2  px-2">
                          <div className="row d-flex align-items-center">
                            <div className="add-button d-flex  justify-content-end">
                              <button disabled={disabled}
                                type="button"
                                className="add-new-account-link btn-animation d-inline-flex align-items-center"
                                onClick={() => addArrayItem()}
                              >
                                <p className="my-0 mr-2 p-0 green-style2">
                                  Add Cross-region Connection
                                </p>
                                <img src="../images/coloredPlus.svg" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.INTER_VPC_CONNECTIVITY + "/" + projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Inter-VPC Connectivity
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(ROUTES.VPC_CONNECTIVITY + "/" + projectId)
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      VPN Connectivity
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CrossRegionConnectivity;
