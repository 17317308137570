import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useHistory, useParams } from "react-router-dom";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";
import ShortSubscription from "../subscription-framework/ShortSubscription";
import useAwsAmplify from "../../../../hooks/useAwsAmplify"
import LongSubscription from "../subscription-framework/LongSubscription";

import * as ROUTES from "../../../../constants/routes";
import { validateAccountEmailId, validateEmailId } from "../../../../helpers/validator";
import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

//const projectId = "google_1";

const PREFIX = "/azure/subscriptionframework/platformsubscriptions";
const MGMT_STRUCTURE_PREFIX = "/azure/subscriptionframework/managementgroups";

const SHAREDSERVICES_SUBSCRIPTIONS = [
  "createoruseexisting",
  "sharedservicessubscriptionoption",
  "management",
  "sharedservices",
  "connectivity",
  "security",
  "loganalytics",
  "workspaces",
  "releasemanagement",
];
const DEFAULT_SUBSCRIPTIONS = SHAREDSERVICES_SUBSCRIPTIONS;
const SUBSCRIPTION_LISTS = ["platformsubscriptionlist"];
const RESERVED_KEYS = SUBSCRIPTION_LISTS.concat([
  "createoruseexisting",
  "sharedservicessubscriptionoption",
]);
const ALL_RESERVED_KEYS = RESERVED_KEYS.concat(DEFAULT_SUBSCRIPTIONS);

const allowedFormValues = {
  useexisting_dedicatedsubscriptions: {
    types: ["subscriptionid"],
    platformsubscriptionlist: [
      "management",
      "sharedservices",
      "connectivity",
      "security",
      "loganalytics",
      "workspaces",
      "releasemanagement",
    ],
  },
  useexisting_singlesubscription: {
    types: ["subscriptionid"],
    platformsubscriptionlist: ["management","sharedservices"],
  },
  create_dedicatedsubscriptions: {
    types: ["subscriptionname"],
    platformsubscriptionlist: [
      "management",
      "sharedservices",
      "connectivity",
      "security",
      "loganalytics",
      "workspaces",
      "releasemanagement",
    ],
  },
  create_singlesubscription: {
    types: ["subscriptionname"],
    platformsubscriptionlist: ["management","sharedservices"],
  },
};

const AzurePlatformSubscriptions = () => {
  const { projectId } = useParams();

  const awsAmplify = useAwsAmplify();
  const history = useHistory();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [empty, setEmpty] = useState(false);

  const [formValues, setFormValues] = useState({
    [`${PREFIX}/createoruseexisting`]: {
      value: "useexisting",
      isValid: true,
      isChanged: false,
    },
    [`${PREFIX}/sharedservicessubscriptionoption`]: {
      value: "singlesubscription",
      isValid: true,
      isChanged: false,
    },
    [`${PREFIX}/platformsubscriptionlist`]: {
      value: [
        "management",
        "sharedservices",
        "connectivity",
        "security",
        "loganalytics",
        "workspaces",
        "releasemanagement",
      ],
      isValid: true,
      isChanged: false,
    },
    platformMgmtGroupNames: [],
    managementGroupName: "",
    [`${PREFIX}/managementGroupHierarchy`] : {
      value: "",
      isChanged: false
    }
  });

  const [isDirty, setIsDirty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const getAccountsListName = (name) => {
    return SHAREDSERVICES_SUBSCRIPTIONS.indexOf(name) !== -1
      ? `${PREFIX}/platformsubscriptionlist` : "";
  };

  const isSwitchOn = (name) => {
    return (
      formValues[getAccountsListName(name)] &&
      formValues[getAccountsListName(name)].value.indexOf(name) !== -1
    );
  };

  const updateSwitch = (name, status) => {
    const accountListName = getAccountsListName(name);

    if (status === true) {
      if (formValues[accountListName].value.indexOf(name) === -1) {
        formValues[accountListName].value.push(name);
      }
    } else {
      formValues[accountListName].value = formValues[
        accountListName
      ].value.filter((v) => v !== name);
    }

    makeChange(accountListName);
  };

  const updateValue = (name, value) => {
    const convertedFormValues = convertFormValues(formValues);
    const nameArray = name.split("/");
    const field = nameArray[nameArray.length - 1];

    const uniqueSet = new Set();

    for (const prop in convertedFormValues) {
      const splitArray = prop.split("/");
      if (
        splitArray.length === 5 &&
        splitArray[splitArray.length - 1] === field
      ) {
        uniqueSet.add(convertedFormValues[prop].toLowerCase());
      }
    }

    if (uniqueSet.has(value.toLowerCase())) {
      setErrorMsg(`${field} has to be unique`);
      return false;
    }

    setFormValues((state) => {
      let newFormValue = {
        ...state[name],
        value: value,
      };

      if (!state[name]) {
        newFormValue.isChanged = false;
      }

      return {
        ...state,
        [name]: newFormValue,
      };
    });
  };

  const validate = (name, value) =>
    setFormValues((state) => {
      return {
        ...state,
        [name]: {
          ...state[name],
          isValid: value,
        },
      };
    });

  const makeChange = (name) =>
    setFormValues((state) => {
      return {
        ...state,
        [name]: {
          ...state[name],
          isChanged: true,
        },
      };
    });

    const getManagementGroupHierarchy = (name, groupnames) => {
      for(const groupname of groupnames)
      {
        let groupArray = groupname.split('/');
        if(groupArray){
          var lastString = groupArray[groupArray.length-1];
          if(name == lastString)
          {
            return groupname;
          }
       }
      }
    }


  useEffect(() => {
    console.log("fetching");
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);
        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        const managementgroupstructure = await awsAmplify.loadProjectData(projectId, MGMT_STRUCTURE_PREFIX);
        setIsLoading(false);

        console.log(result);

        if (result.data) {
          setEmpty(result.data.empty);

          const loadedFormValues = {
            [`${PREFIX}/createoruseexisting`]: {
              value: "useexisting",
              isValid: true,
              isChanged: false,
            },
            [`${PREFIX}/sharedservicessubscriptionoption`]: {
              value: "singlesubscription",
              isValid: true,
              isChanged: false,
            },
            [`${PREFIX}/platformsubscriptionlist`]: {
              value: [
                "management",
                "sharedservices",
                "connectivity",
                "security",
                "loganalytics",
                "workspaces",
                "releasemanagement",
              ],
              isValid: true,
              isChanged: false,
            },
            [`${PREFIX}/managementgroupname`] :{
              value: ""
            },
            platformMgmtGroupNames:[],
            managementGroupName: "",
            [`${PREFIX}/managementGroupHierarchy`] : {
              value: "",
              isChange: false
            },
          };

          if (!result.data.empty) {
            result.data[`${PREFIX}/platformsubscriptionlist`] = result.data[
              `${PREFIX}/platformsubscriptionlist`
            ]
              ? result.data[`${PREFIX}/platformsubscriptionlist`].split(",")
              : [];
          
            for (const ssA of result.data[
              `${PREFIX}/platformsubscriptionlist`
            ]) {
              if (ALL_RESERVED_KEYS.indexOf(ssA) === -1) {
                ssAccounts.push({
                  id: uuidv4(),
                  name: ssA,
                });
              }
            }
          }

          for (const key in result.data) {
            loadedFormValues[key] = {
              isValid: true,
              value: result.data[key],
              isChanged: false,
            };
          }

          if(managementgroupstructure !== undefined && managementgroupstructure.data)
          {
            const mgmt_data = managementgroupstructure.data;
            var groupnames = mgmt_data[`${MGMT_STRUCTURE_PREFIX}/managementgrouplist`]; 
            loadedFormValues.platformMgmtGroupNames = groupnames && groupnames.split(',').filter(Boolean) || [];
          }

          if (result.data[`${PREFIX}/managementgroupname`]) {
             loadedFormValues.managementGroupName =  result.data[`${PREFIX}/managementgroupname`] || "";
             loadedFormValues[`${PREFIX}/managementgroupname`] = loadedFormValues.managementGroupName;
             loadedFormValues[`${PREFIX}/managementGroupHierarchy`].value = 
             getManagementGroupHierarchy(loadedFormValues.managementGroupName, loadedFormValues.platformMgmtGroupNames);
          }

          setFormValues(loadedFormValues);
        } else {
          console.log("No value");

          setFormValues({
            [`${PREFIX}/createoruseexisting`]: {
              value: "useexisting",
              isValid: true,
              isChanged: false,
            },
            [`${PREFIX}/sharedservicessubscriptionoption`]: {
              value: "singlesubscription",
              isValid: true,
              isChanged: false,
            },
            [`${PREFIX}/platformsubscriptionlist`]: {
              value: [
                "management",
                "sharedservices",
                "connectivity",
                "security",
                "loganalytics",
                "workspaces",
                "releasemanagement",
              ],
              isValid: true,
              isChanged: false,
            },
            managementGroupName: "",
            [`${PREFIX}/managementGroupHierarchy`]: {
              value: "",
              isChanged: false
            },
            platformMgmtGroupNames:[],
            [`${PREFIX}/managementgroupname`]: {
              value: ""
            }
          });
        }
      } catch (err) {
        setIsLoading(false);
        if (err.response) setErrorMsg(err.response.data.message);
        else setErrorMsg(err.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const [ssAccounts, setSsAccounts] = useState([]);

  const addAccount = (item, isSs = true) => {
    if (isSs) {
      setSsAccounts(ssAccounts.concat(item));
    } 
    setIsDirty(true);
  };

  const removeAccount = (id, isSs = true) => {
    if (isSs) {
      setSsAccounts(ssAccounts.filter((item) => item.id !== id));
    }
    setIsDirty(true);
  };

  const [newSsAccountFunction, setNewSsAccountFunction] = useState("");

  const [
    isNewSsAccountFunctionValid,
    setIsNewSsAccountFunctionValid,
  ] = useState(true);

  const isNewAccountFunctionValid = (isSs = true, value) => {
    const newAccountFunction =
      value || (isSs ? newSsAccountFunction : "");

    if (newAccountFunction === "") {
      return "New Subscription Function must not empty";
    }

    if (!/^\S*$/.test(newAccountFunction)) {
      return "New Subscription Function must not contains space";
    }

    if (ALL_RESERVED_KEYS.indexOf(newAccountFunction.toLowerCase()) !== -1) {
      return "New Subscription Function must be unique";
    } else {

      return;
    }
  };

  const convertFormValues = () => {
    const newFormValues = {
      [`${PREFIX}/createoruseexisting`]: formValues[
        `${PREFIX}/createoruseexisting`
      ].value,
      [`${PREFIX}/sharedservicessubscriptionoption`]: formValues[
        `${PREFIX}/sharedservicessubscriptionoption`
      ].value,
      [`${PREFIX}/managementGroupHierarchy`] : ""
    };

    const allowedValues =
      allowedFormValues[
        `${formValues[`${PREFIX}/createoruseexisting`].value}_${
          formValues[`${PREFIX}/sharedservicessubscriptionoption`].value
        }`
      ];

    console.log("allowed values");
    console.log(allowedValues);

    console.log("current values");
    console.log(formValues[`${PREFIX}/platformsubscriptionlist`]);

    const platformsubscriptionlist = [];

    for (const entry of formValues[`${PREFIX}/platformsubscriptionlist`]
      .value) {
      if (allowedValues.platformsubscriptionlist.indexOf(entry) !== -1) {
        platformsubscriptionlist.push(entry);

        for (const type of allowedValues.types) {
          if (
            formValues[`${PREFIX}/${entry}/${type}`] &&
            formValues[`${PREFIX}/${entry}/${type}`].value !== "" &&
            formValues[`${PREFIX}/${entry}/${type}`].isValid === true
          ) {
            newFormValues[`${PREFIX}/${entry}/${type}`] =
              formValues[`${PREFIX}/${entry}/${type}`].value;
          }
        }
      }
    }

    if (
      newFormValues[`${PREFIX}/sharedservicessubscriptionoption`] ===
      "dedicatedsubscriptions"
    ) {
      for (const ssAccount of ssAccounts) {
        platformsubscriptionlist.push(ssAccount.name);

        for (const type of allowedValues.types) {
          if (
            formValues[`${PREFIX}/${ssAccount.name}/${type}`] &&
            formValues[`${PREFIX}/${ssAccount.name}/${type}`].value !== "" &&
            formValues[`${PREFIX}/${ssAccount.name}/${type}`].isValid === true
          ) {
            newFormValues[`${PREFIX}/${ssAccount.name}/${type}`] =
              formValues[`${PREFIX}/${ssAccount.name}/${type}`].value;
          }
        }
      }
    }

    newFormValues[
      `${PREFIX}/platformsubscriptionlist`
    ] = platformsubscriptionlist.join(",");

    if(formValues[`${PREFIX}/managementGroupHierarchy`].value !== undefined && formValues[`${PREFIX}/managementGroupHierarchy`].value != "") {
      newFormValues[`${PREFIX}/managementGroupHierarchy`] = formValues[`${PREFIX}/managementGroupHierarchy`].value;
      const managementGroupHierarchy = formValues[`${PREFIX}/managementGroupHierarchy`].value;
      const groupArray = managementGroupHierarchy && managementGroupHierarchy.split('/');
      if(groupArray && groupArray.length > 0) {
      formValues.managementGroupName = groupArray[groupArray.length-1];
        newFormValues[`${PREFIX}/managementgroupname`] = groupArray[groupArray.length-1];
      }
    }

   
    console.log("Posting the following:");
    console.log(newFormValues);

    return newFormValues;
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFormValues(formValues),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }

      if (
        formValues[`${PREFIX}/sharedservicessubscriptionoption`].value !==
        "dedicatedsubscriptions"
      ) {
        setSsAccounts([]);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}

      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="userTitleTop">Subscription Framework</div>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              className="tableArea accountsTableArea haveFilter coreTableArea container-fluid p-0 d-flex flex-column"
              onSubmit={async (e) => {
                e.preventDefault();
                await submit();
              }}
            >
              <DomainMenu
                title="Subscription Framework"
                saveDataIfDirty={saveDataIfDirty}
                isDirty={isDirty}
                dashboardRoute={ROUTES.AZURE_ORGANIZATIONAL_UNITS}
                designRoute={ROUTES.AZURE_DESIGN_SUBSCRIPTION_FRAMEWORK}
              ></DomainMenu>
              <fieldset
                disabled={disabled}
                className="mainArea fitDeviceHeight"
              >
                <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 ">
                  <div className="row d-flex align-items-center my-4">
                    <div className="col-xl-4 col-lg-4 col-md-3 col-12 pl-0">
                      <h3>Platform Subscriptions</h3>
                    </div>
                    <div className="col-xl-3 col-lg-2 col-1 col-md-1 col-12"></div>
                    <div className="col-xl-5 col-lg-6 col-5 col-md-8 col-12 pr-0 pl-0 d-flex align-items-center justify-content-end rightNavArea">
                      <div className="d-inline-flex yes-no-area align-items-center w-100">
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100 w-100">
                          <label
                            className={`btn btn-link ${
                              formValues[`${PREFIX}/createoruseexisting`]
                                .value === "useexisting"
                                ? "active"
                                : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="createoruseexisting"
                              value="useexisting"
                              checked={
                                formValues[`${PREFIX}/createoruseexisting`]
                                  .value === "useexisting"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                updateValue(
                                  `${PREFIX}/createoruseexisting`,
                                  "useexisting"
                                );
                                makeChange(`${PREFIX}/createoruseexisting`);
                              }}
                            />{" "}
                            Use Existing Platform Subscriptions
                          </label>
                          <label
                            className={`btn btn-link ${
                              formValues[`${PREFIX}/createoruseexisting`]
                                .value === "create"
                                ? "active"
                                : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="createoruseexisting"
                              value="create"
                              checked={
                                formValues[`${PREFIX}/createoruseexisting`]
                                  .value === "create"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                updateValue(
                                  `${PREFIX}/createoruseexisting`,
                                  "create"
                                );
                                makeChange(`${PREFIX}/createoruseexisting`);
                              }}
                            />{" "}
                            Create Platform Subscriptions
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {empty && (
                    <NoInitialData />
                  )}

                  <div className="row">
                  <div className=" selectionArea d-inline-flex flex-wrap align-items-center my-1  p-2">
                  {/* <div className="d-flex flex-column w-100 px-3 my-1 py-1 mainContentArea"> */}
                  <div className="flex-wrap my-1 p-1 mx-3">
                 {/*  <div className=" d-inline-flex row topOfFormRow  px-md-2 px-3 my-1 align-items-center justify-content-between"> */}
                      <b className="mb-0 font-12">
                      Management Group Name
                      </b>
                   </div>
                    { (formValues.platformMgmtGroupNames && formValues.platformMgmtGroupNames.length === 0) ? (
                      <div className="col-md-6 flex-wrap d-inline-flex">
                          <span className="warning">
                          This section depends on the organizational structure information in
                          Subscription Framework section that is yet to be provided
                        </span>
                        </div>
                      ) : (
                      <>
                        <div className="col-md-6 flex-wrap d-inline-flex ">
                            <div className="w-300 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                            <select className="form-control bg-gray pr-lg-0 pr-5" 
                                disabled={!(formValues.platformMgmtGroupNames && formValues.platformMgmtGroupNames.length > 0)}
                                  value={( formValues[`${PREFIX}/managementGroupHierarchy`] && formValues[`${PREFIX}/managementGroupHierarchy`].value)}
                                  onChange={(e) => {
                                    updateValue(
                                     `${PREFIX}/managementGroupHierarchy`,
                                      e.target.value
                                    );
                                    setIsDirty(true);
                                }}>
                                <option value="">Select</option>
                                {formValues.platformMgmtGroupNames && formValues.platformMgmtGroupNames.map((ouName, idxV) => (
                                (ouName !== "") &&
                                  <option key={idxV} value={ouName}>
                                    {ouName}
                                  </option>
                                ))}</select>  
                            </div>
                      </div> 
                      </>
                    )}
                  </div>
                  </div>

                  <div className="row">
                    <div className="col-12 px-0">
                      <div className=" specialSelectionArea d-inline-flex selectionArea px-2 justify-content-between py-1 my-1">
                        <div className="my-2">
                          <label className="container">
                            Single Shared Services Subscription
                            <input
                              type="radio"
                              name="sharedservicessubscriptionoption"
                              value="singlesubscription"
                              checked={
                                formValues[
                                  `${PREFIX}/sharedservicessubscriptionoption`
                                ].value === "singlesubscription"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                updateValue(
                                  `${PREFIX}/sharedservicessubscriptionoption`,
                                  "singlesubscription"
                                );
                                makeChange(
                                  `${PREFIX}/sharedservicessubscriptionoption`
                                );
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="my-2">
                          <label className="container">
                            Dedicated Subscriptions for Shared Services{" "}
                            <input
                              type="radio"
                              name="sharedservicessubscriptionoption"
                              value="dedicatedsubscriptions"
                              checked={
                                formValues[
                                  `${PREFIX}/sharedservicessubscriptionoption`
                                ].value === "dedicatedsubscriptions"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                updateValue(
                                  `${PREFIX}/sharedservicessubscriptionoption`,
                                  "dedicatedsubscriptions"
                                );
                                makeChange(
                                  `${PREFIX}/sharedservicessubscriptionoption`
                                );
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {formValues[`${PREFIX}/createoruseexisting`].value ===
                      "useexisting" && (
                      <>
                        <div className="d-flex col-lg-6 col-12  pl-md-0 px-1 specialHalfColumn">
                          <div className="d-flex flex-column w-100 px-3 my-1 py-1 mainContentArea">
                            <div className=" d-inline-flex row topOfFormRow  px-md-2 px-3 my-1 align-items-center justify-content-between">
                              <div className="col-12 pl-0">
                                <h2 className="font-weight-bold mb-0">
                                  Shared Services Subscriptions
                                </h2>
                              </div>
                            </div>

                            <ShortSubscription
                              paramPrefix={PREFIX}
                              checkboxLabel="Management"
                              mandatory={true}
                              name="management"
                              formValues={formValues}
                              updateValue={updateValue}
                              validate={validate}
                              makeChange={makeChange}
                              setErrorMsg={setErrorMsg}
                              updateSwitch={updateSwitch}
                              setIsDirty={setIsDirty}
                            ></ShortSubscription>

                            <ShortSubscription
                              paramPrefix={PREFIX}
                              checkboxLabel="Shared Services"
                              mandatory={true}
                              name="sharedservices"
                              formValues={formValues}
                              updateValue={updateValue}
                              validate={validate}
                              makeChange={makeChange}
                              setErrorMsg={setErrorMsg}
                              updateSwitch={updateSwitch}
                              setIsDirty={setIsDirty}
                            ></ShortSubscription>

                            {formValues[`${PREFIX}/sharedservicessubscriptionoption`]
                              .value === "dedicatedsubscriptions" && (
                              <>

                              <ShortSubscription
                                  paramPrefix={PREFIX}
                                  name="connectivity"
                                  checkboxLabel="Connectivity"
                                  mandatory={false}
                                  defaultChecked={isSwitchOn("connectivity")}
                                  formValues={formValues}
                                  updateValue={updateValue}
                                  validate={validate}
                                  makeChange={makeChange}
                                  setErrorMsg={setErrorMsg}
                                  updateSwitch={updateSwitch}
                                  setIsDirty={setIsDirty}
                                ></ShortSubscription>

                              <ShortSubscription
                                  paramPrefix={PREFIX}
                                  name="security"
                                  checkboxLabel="Security"
                                  mandatory={false}
                                  defaultChecked={isSwitchOn("security")}
                                  formValues={formValues}
                                  updateValue={updateValue}
                                  validate={validate}
                                  makeChange={makeChange}
                                  setErrorMsg={setErrorMsg}
                                  updateSwitch={updateSwitch}
                                  setIsDirty={setIsDirty}
                                ></ShortSubscription>

                                <ShortSubscription
                                  paramPrefix={PREFIX}
                                  name="loganalytics"
                                  checkboxLabel="Log Analytics"
                                  mandatory={false}
                                  defaultChecked={isSwitchOn("loganalytics")}
                                  formValues={formValues}
                                  updateValue={updateValue}
                                  validate={validate}
                                  makeChange={makeChange}
                                  setErrorMsg={setErrorMsg}
                                  updateSwitch={updateSwitch}
                                  setIsDirty={setIsDirty}
                                ></ShortSubscription>

                                <ShortSubscription
                                  paramPrefix={PREFIX}
                                  name="workspaces"
                                  checkboxLabel="Workspaces (VDI)"
                                  mandatory={false}
                                  defaultChecked={isSwitchOn("workspaces")}
                                  formValues={formValues}
                                  updateValue={updateValue}
                                  validate={validate}
                                  makeChange={makeChange}
                                  setErrorMsg={setErrorMsg}
                                  updateSwitch={updateSwitch}
                                  setIsDirty={setIsDirty}
                                ></ShortSubscription>

                                <ShortSubscription
                                  paramPrefix={PREFIX}
                                  name="releasemanagement"
                                  checkboxLabel="Release Management"
                                  mandatory={false}
                                  defaultChecked={isSwitchOn(
                                    "releasemanagement"
                                  )}
                                  formValues={formValues}
                                  updateValue={updateValue}
                                  validate={validate}
                                  makeChange={makeChange}
                                  setErrorMsg={setErrorMsg}
                                  updateSwitch={updateSwitch}
                                  setIsDirty={setIsDirty}
                                ></ShortSubscription>

                              

                                {ssAccounts.map((item, idx) => (
                                  <ShortSubscription
                                    key={item.id}
                                    paramPrefix={PREFIX}
                                    name={item.name}
                                    checkboxLabel={item.name}
                                    remove={() => removeAccount(item.id)}
                                    newAccount={true}
                                    mandatory={false}
                                    defaultChecked={true}
                                    formValues={formValues}
                                    updateValue={updateValue}
                                    validate={validate}
                                    makeChange={makeChange}
                                    setErrorMsg={setErrorMsg}
                                    updateSwitch={updateSwitch}
                                    setIsDirty={setIsDirty}

                                  ></ShortSubscription>
                                ))}

                                <div className=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-start">
                                  <div className="one form-line my-lg-0 my-1 d-inline-flex align-items-center">
                                    <span className="switchLabel">
                                      Subscription Function
                                    </span>
                                  </div>
                                  <div className="d-inline-flex my-lg-0 my-1 ml-3 form-line two">
                                    <input
                                      type="text"
                                      className={`form-control ${
                                        !isNewSsAccountFunctionValid &&
                                        "invalid"
                                      }`}
                                      placeholder=""
                                      value={newSsAccountFunction}
                                      onChange={(e) => {
                                        const errorMsg = isNewAccountFunctionValid(
                                          true,
                                          e.target.value
                                        );
                                        //setIsDirty(true);

                                        if (errorMsg) {
                                          setIsNewSsAccountFunctionValid(false);
                                          setErrorMsg(errorMsg);
                                          return false;
                                        } else {
                                          setIsNewSsAccountFunctionValid(true);
                                          setErrorMsg(null);
                                          setNewSsAccountFunction(
                                            e.target.value
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="add-button ml-3">
                                    <button
                                      disabled={disabled}
                                      type="button"
                                      className="add-new-account-link d-inline-flex align-items-center"
                                      onClick={() => {
                                        const errorMsg = isNewAccountFunctionValid(
                                          true
                                        );

                                        if (errorMsg) {
                                          setIsNewSsAccountFunctionValid(false);
                                          setErrorMsg(errorMsg);
                                        } else {
                                          setIsNewSsAccountFunctionValid(true);
                                          addAccount({
                                            id: uuidv4(),
                                            name: newSsAccountFunction,
                                          });
                                          setNewSsAccountFunction("");
                                        }
                                      }}
                                    >
                                      <img src="../images/coloredPlus.svg" />
                                      <p className="my-0 ml-2 p-0">
                                        Add Subscription
                                      </p>
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {formValues[`${PREFIX}/createoruseexisting`].value ===
                      "create" && (
                      <>
                       {/*  <div className="col-12 px-3 d-flex flex-column topOfMainContentArea py-1 my-1 specialHalfColumn">
                          <div className="d-inline-flex justify-content-between my-1 pl-3"> */}
                      <div className="d-flex col-lg-6 col-12  pl-md-0 px-1 specialHalfColumn">
                          <div className="d-flex flex-column w-100 px-3 my-1 py-1 mainContentArea">
                            <div className=" d-inline-flex row topOfFormRow  px-md-2 px-3 my-1 align-items-center justify-content-between">
                            <h3 className="font-15 font-weight-bold mb-0 d-flex align-items-center mb-0">
                              {" "}
                              Shared Services Subscriptions
                            </h3>
                          </div>
                          <LongSubscription
                            paramPrefix={PREFIX}
                            subscriptionName="management"
                            checkboxLabel="Management"
                            mandatory={true}
                            defaultChecked={isSwitchOn("management")}
                            formValues={formValues}
                            updateValue={updateValue}
                            validate={validate}
                            makeChange={makeChange}
                            setErrorMsg={setErrorMsg}
                            updateSwitch={updateSwitch}
                            setIsDirty={setIsDirty}
                          ></LongSubscription>

                          <LongSubscription
                            paramPrefix={PREFIX}
                            subscriptionName="sharedservices"
                            checkboxLabel="Shared Services"
                            mandatory={true}
                            defaultChecked={isSwitchOn("sharedservices")}
                            formValues={formValues}
                            updateValue={updateValue}
                            validate={validate}
                            makeChange={makeChange}
                            setErrorMsg={setErrorMsg}
                            updateSwitch={updateSwitch}
                            setIsDirty={setIsDirty}
                          ></LongSubscription>

                          {formValues[`${PREFIX}/sharedservicessubscriptionoption`]
                            .value === "dedicatedsubscriptions" && (
                            <>

                              <LongSubscription 
                                paramPrefix={PREFIX}
                                subscriptionName="connectivity"
                                checkboxLabel="Connectivity"
                                inputLabel="Subscription Id"
                                mandatory={false}
                                defaultChecked={isSwitchOn("connectivity")}
                                formValues={formValues}
                                updateValue={updateValue}
                                validate={validate}
                                makeChange={makeChange}
                                setErrorMsg={setErrorMsg}
                                updateSwitch={updateSwitch}
                                setIsDirty={setIsDirty}
                              ></LongSubscription>
                                <LongSubscription
                                paramPrefix={PREFIX}
                                subscriptionName="security"
                                checkboxLabel="Security"
                                inputLabel="Subscription Id"
                                mandatory={false}
                                defaultChecked={isSwitchOn("security")}
                                formValues={formValues}
                                updateValue={updateValue}
                                validate={validate}
                                makeChange={makeChange}
                                setErrorMsg={setErrorMsg}
                                updateSwitch={updateSwitch}
                                setIsDirty={setIsDirty}
                              ></LongSubscription>
                                <LongSubscription
                                paramPrefix={PREFIX}
                                subscriptionName="loganalytics"
                                checkboxLabel="Log Analytics"
                                inputLabel="Subscription Id"
                                mandatory={false}
                                defaultChecked={isSwitchOn("loganalytics")}
                                formValues={formValues}
                                updateValue={updateValue}
                                validate={validate}
                                makeChange={makeChange}
                                setErrorMsg={setErrorMsg}
                                updateSwitch={updateSwitch}
                                setIsDirty={setIsDirty}
                              ></LongSubscription>
                              <LongSubscription
                                paramPrefix={PREFIX}
                                subscriptionName="workspaces"
                                checkboxLabel="Workspaces (VDI)"
                                inputLabel="Subscription Id"
                                mandatory={false}
                                defaultChecked={isSwitchOn("workspaces")}
                                formValues={formValues}
                                updateValue={updateValue}
                                validate={validate}
                                makeChange={makeChange}
                                setErrorMsg={setErrorMsg}
                                updateSwitch={updateSwitch}
                                setIsDirty={setIsDirty}
                              ></LongSubscription>
                              <LongSubscription
                                paramPrefix={PREFIX}
                                subscriptionName="releasemanagement"
                                checkboxLabel="Release Management"
                                inputLabel="Subscription Id"
                                mandatory={false}
                                defaultChecked={isSwitchOn("releasemanagement")}
                                formValues={formValues}
                                updateValue={updateValue}
                                validate={validate}
                                makeChange={makeChange}
                                setErrorMsg={setErrorMsg}
                                updateSwitch={updateSwitch}
                                setIsDirty={setIsDirty}
                              ></LongSubscription>
                             

                              {ssAccounts.map((item, idx) => (
                                <LongSubscription
                                  key={item.id}
                                  paramPrefix={PREFIX}
                                  subscriptionName={item.name}
                                  checkboxLabel={item.name}
                                  remove={() => removeAccount(item.id)}
                                  newAccount={true}
                                  mandatory={false}
                                  defaultChecked={true}
                                  formValues={formValues}
                                  updateValue={updateValue}
                                  validate={validate}
                                  makeChange={makeChange}
                                  setErrorMsg={setErrorMsg}
                                  setIsDirty={setIsDirty}
                                ></LongSubscription>
                              ))}

                              <div className=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-start">
                                <div className="one form-line my-lg-0 my-1 d-inline-flex align-items-center">
                                  <span className="switchLabel">
                                    Subscription Function
                                  </span>
                                </div>
                                <div className="d-inline-flex my-lg-0 my-1 ml-3 form-line two">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      !isNewSsAccountFunctionValid && "invalid"
                                    }`}
                                    placeholder=""
                                    value={newSsAccountFunction}
                                    onChange={(e) => {
                                      const errorMsg = isNewAccountFunctionValid(
                                        true,
                                        e.target.value
                                      );

                                      if (errorMsg) {
                                        setIsNewSsAccountFunctionValid(false);
                                        setErrorMsg(errorMsg);
                                        return false;
                                      } else {
                                        setIsNewSsAccountFunctionValid(true);
                                        setErrorMsg(null);
                                        setNewSsAccountFunction(e.target.value);
                                      }
                                    }}
                                  />
                                </div>
                                <div className="add-button ml-3">
                                  <button
                                    disabled={disabled}
                                    type="button"
                                    className="add-new-account-link d-inline-flex align-items-center"
                                    onClick={() => {
                                      const errorMsg = isNewAccountFunctionValid(
                                        true
                                      );

                                      if (errorMsg) {
                                        setIsNewSsAccountFunctionValid(false);
                                        setErrorMsg(errorMsg);
                                      } else {
                                        setIsNewSsAccountFunctionValid(true);
                                        addAccount({
                                          id: uuidv4(),
                                          name: newSsAccountFunction,
                                        });
                                        setNewSsAccountFunction("");
                                      }
                                    }}
                                  >
                                    <img src="../images/coloredPlus.svg" />
                                    <p className="my-0 ml-2 p-0">Add Subscription</p>
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </fieldset>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
               
                <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={async () => {
                      if (isDirty) {
                        await submit();
                      }
                      history.push(ROUTES.AZURE_ORGANIZATIONAL_STRUCTURE+"/"+projectId);
                    }}
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                       Organizational Structure
                    </p>
                  </button>
                  
                  <button
                    className="btn-post btn-animation d-inline-flex"
                    type="button"
                    onClick={async () => {
                      if (isDirty) {
                        await submit();
                      }
                      history.push(
                        ROUTES.AZURE_WORKLOAD_SUBSCRIPTIONS + "/" + projectId
                      );
                    }}
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Workload Subscriptions
                    </p>
                    <img alt="" src="/images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* {console.log(formValues)} */}
    </>
  );
};

export default AzurePlatformSubscriptions;
