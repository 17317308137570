import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getBooleanValue, getJSONStr} from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "Backup Vaults";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
      <>
        <h3>Backup</h3>
        <div className="otherTableTitles ">
          <h3 className="font-15">  
          <Link className="text-color-gray" to={ROUTES.DATA_PROTECTION_BACKUP_POLICY + "/" + projectId}>  
          Policies
         </Link>       
          </h3>
          <h3 className="font-15 activetable">          
             Vaults
          </h3>
          <h3 className="font-15">    
          <Link className="text-color-gray" to={ROUTES.DATA_PROTECTION_BACKUP_PLAN + "/" + projectId}>  
             Plans
         </Link>                  
          </h3>
          <h3 className="font-15">
            <Link className="text-color-gray" to={ROUTES.DATA_PROTECTION_BACKUP_JOBS + "/" + projectId}>
             Backup Jobs
            </Link>
          </h3>
          <h3 className="font-15">
            <Link className="text-color-gray" to={ROUTES.DATA_PROTECTION_BACKUP_COPY_JOBS + "/" + projectId}>
             Copy Jobs
            </Link>
          </h3>
        </div>
      </>
    );
  };


  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Backups" && "my_active"
                }`}
                onClick={() => setType("Backups")}
              >
               Backups
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Access Policy" && "my_active"
                }`}
                onClick={() => setType("Access Policy")}
              >
                Access Policy
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Backup Vault Tags" && "my_active"
                }`}
                onClick={() => setType("Backup Vault Tags")}
              >
                Backup Vault Tags
              </li>
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AccountId }
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Backup Vault Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.BackupVaultName}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Number of Recovery Points</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.NumberOfRecoveryPoints}
                  </p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  KMS Encryption Master Key ARN
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.EncryptionKeyArn}
                  </p>
                </div>
  
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  ARN
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.BackupVaultArn}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Creation Date</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.CreationDate}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Creator Request ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.CreatorRequestId}
                  </p>
                </div>
              </div>
            </div>

           {/*  <DetailsGrid
              type={type}
              typeName="Backups"
              array={selectedRow}
              metadata={[
                { header: "Recovery Point ARN", key: "" },
                { header: "Status", key: "" },
                { header: "Creation Time", key: "" },  
                { header: "Completion Date", key: "" },
                { header: "Resource ARN", key: "" },        
                { header: "Resource Type", key: "" },        
                { header: "Move to Cold Date", key: "" },
                { header: "Expiration Date", key: "" },        
                { header: "Backup Size", key: "" },        
                { header: "Encrypted", key: "" },        
              ]}
            ></DetailsGrid>
 */}
        
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Access Policy" && "inaktif"
              }`}
            >
             <div className="col-12">
              <p className="color-spc3">
                <pre className="font-12">
                {selectedRow && getJSONStr(selectedRow.AccessPolicy)} 
                </pre>
              </p>
            </div>
         </div>

         <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Backup Vault Tags" && "inaktif"
              }`}
            >
             <div className="col-12">
              <p className="color-spc3">
                <pre className="font-12">
                  {selectedRow && getJSONStr(selectedRow.Tags)}
                </pre>
              </p>
            </div>
         </div>


       
          </div>
        </div>
      </div>
    );
  };

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
    <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.DATA_PROTECTION_ACM + "/" + projectId}
    >
      <p className="m-0 p-0 mr-2 font-weight-bold">ACM Certificates</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
    </>
    );
  };
  
  
const BackupVaults = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
        id:"AccountId"
      },
      {
        Header: "Region",
        accessor: "Region",
        id:"Region"
      },
      {
        Header: "Backup Vault Name",
        accessor: "BackupVaultName",
        id: "Backup Vault Name"
      },
      {
        Header: "ARN",
        accessor: "BackupVaultArn",
        id: "ARN"
      },
      {
        Header: "Creation Date",
        accessor: "CreationDate",
        id: "Creation Date"
      },
      {
        Header: "KMS Encryption Master Key ARN",
        accessor: "EncryptionKeyArn",
        id: "KMS Encryption Master Key ARN"
      },
      {
        Header: "Number of Recovery Points",
        accessor: "NumberOfRecoveryPoints",
        id: "Number of Recovery Points"
      }
    ],
    []
  );

  return Dashboard({
    domain: "dataprotection",
    title: "Data Protection",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_PROTECTION_BACKUP_ARCHIVAL,
    designRoute: ROUTES.DESIGN_DATA_PROTECTION,
    SubMenu: SubMenu,
  });
};

export default BackupVaults;