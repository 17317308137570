import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { Link, useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";
import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import {
  validate1to10AlphaNumeric,
  validate1to10AlphaNumericHyphen,
} from "../../../../helpers/validator";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

//const projectId = "google_1";
const PREFIX = "/gcp/accountframework/environmentstrategy";

const GcpEnvironmentStrategy = () => {
  const { projectId } = useParams();

  const history = useHistory();

  const initialStages = [
    {
      id: uuidv4(),
      placeHolder: "dev",
      isValid: false,
    },
    {
      id: uuidv4(),
      placeHolder: "test",
      isValid: false,
    },
    {
      id: uuidv4(),
      placeHolder: "qa",
      isValid: false,
    },
    {
      id: uuidv4(),
      placeHolder: "prod",
      isValid: false,
    },
  ];

  const initialFormValues = {};

  for (const stage of initialStages) {
    initialFormValues[stage.id] = {
      value: "",
      isValid: false,
    };
  }

  const [mergedValue, setMergedValue] = useState({
    formValues: initialFormValues,
    stages: initialStages,
  });

  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);
        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        console.log(result);

        if (result.data) {
          setEmpty(result.data.empty);

          if(!result.data.empty){
          const stages = result.data[`${PREFIX}/sdlcstageslist`]
            .split(",")
            .map((stage) => {
              return {
                id: uuidv4(),
                name: stage,
              };
            });

          console.log(stages);

          const formValues = {};

          for (const stage of stages) {
            formValues[stage.id] = {
              value: stage.name,
              isValid: true,
            };
          }

          console.log(formValues);

          setMergedValue({
            formValues: formValues,
            stages: stages,
          });
        }
        } else {
          const initialStages = [
            {
              id: uuidv4(),
              placeHolder: "dev",
              isValid: false,
            },
            {
              id: uuidv4(),
              placeHolder: "test",
              isValid: false,
            },
            {
              id: uuidv4(),
              placeHolder: "qa",
              isValid: false,
            },
            {
              id: uuidv4(),
              placeHolder: "prod",
              isValid: false,
            },
          ];

          const initialFormValues = {};

          for (const stage of initialStages) {
            initialFormValues[stage.id] = {
              value: "",
              isValid: false,
            };
          }

          setMergedValue({
            formValues: initialFormValues,
            stages: initialStages,
          });
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    const sdlcStagesList = [];

    for (const key in mergedValue.formValues) {
      console.log(mergedValue.formValues[key].value);
      if (
        mergedValue.formValues[key].isValid &&
        mergedValue.formValues[key].value
      ) {
        sdlcStagesList.push(mergedValue.formValues[key].value);
      }
    }

    console.log(sdlcStagesList);
    if (sdlcStagesList.length > 0) {
      try {
        setIsLoading(true);
        const result = await awsAmplify.saveProjectData({
          prefix: PREFIX,
          projectId: projectId,
          projectData: {
            [`${PREFIX}/sdlcstageslist`]: sdlcStagesList.join(","),
          },
        });
        console.log(result);
        setIsLoading(false);

        setIsDirty(false);
        if(empty){
          setEmpty(false);
        }
      } catch (err) {
        setIsLoading(false);
        setErrorMsg(err.response.data.message);
      }
    }
  };

  const addStage = () => {
    const id = uuidv4();

    setMergedValue((state) => {
      return {
        formValues: {
          ...state.formValues,
          [id]: {
            value: "",
            isValid: false,
          },
        },
        stages: state.stages.concat({ id: id, isValid: false }),
      };
    });

    setIsDirty(true);
  };

  const removeStage = (id) => {
    if (mergedValue.stages.length === 2) {
      setErrorMsg("SDLC Stage can't be less than 2");
      return;
    }

    // Handle case that user removing existing stage that's not unique.
    const uniqueSet = new Set(Object.keys(mergedValue.formValues));

    if (uniqueSet.size === Object.keys(mergedValue.formValues).length) {
      for (const key in mergedValue.formValues) {
        mergedValue.formValues[key].isValid = true;
      }
    }

    const newFormValues = mergedValue.formValues;

    delete newFormValues[id];

    console.log(newFormValues);

    setMergedValue((state) => {
      return {
        formValues: newFormValues,
        stages: mergedValue.stages.filter((item) => item.id !== id),
      };
    });
    setIsDirty(true);
  };

  const updateValue = (id, value) => {
    setMergedValue((state) => {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [id]: {
            ...state.formValues[id],
            value: value,
          },
        },
      };
    });
  };

  const updateIsValid = (id, isValid) => {
    setMergedValue((state) => {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [id]: {
            ...state.formValues[id],
            isValid: isValid,
          },
        },
      };
    });
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Account Framework</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea haveFilter coreTableArea container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                isDirty={isDirty}
                dashboardRoute={ROUTES.GCP_ORGANIZATIONAL_UNITS}
                designRoute={ROUTES.GCP_DESIGN_ACCOUNT_FRAMEWORK}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 ">
                <div className="row d-flex align-items-center">
                  <div className="col-12 pl-lg-0 px-2">
                    <h3>Environment Strategy</h3>
                  </div>
                </div>
                {empty && (
                    <NoInitialData />
                  )}
                <div className="show-hide-content ">
                  <div className="d-flex flex-column mainContentArea  px-3 py-1 my-1">
                    <div className="topOfFormRow my-1">
                      <h2 className="font-15 font-weight-bold mb-0">
                        SDLC Stages
                      </h2>
                    </div>

                    {mergedValue.stages.map((stage) => (
                      <div
                        className=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between"
                        key={stage.id}
                      >
                        <div className="d-inline-flex form-line my-lg-0 my-1 two">
                          <div className="d-flex align-items-center  mx-xl-5 text-nowrap">
                            SDLC Stage Name
                          </div>
                          <input
                            type="text"
                            className={`form-control ${
                              mergedValue.formValues[stage.id].value !== "" &&
                              !mergedValue.formValues[stage.id].isValid &&
                              "invalid"
                            } `}
                            placeholder={stage.placeHolder}
                            onChange={(e) => {
                              setErrorMsg(null);
                              const value = e.target.value;

                              updateValue(stage.id, value);

                              setIsDirty(true);
                            }}
                            value={mergedValue.formValues[stage.id].value}
                            onBlur={(e) => {
                              const value = e.target.value;

                              if (value !== "") {
                                for (const key in mergedValue.formValues) {
                                  if (
                                    key !== stage.id &&
                                    mergedValue.formValues[key].value === value
                                  ) {
                                    updateIsValid(stage.id, false);
                                    setErrorMsg(
                                      `${e.target.value} must be unique`
                                    );
                                    return;
                                  }
                                }

                                const errorMsg = validate1to10AlphaNumericHyphen(
                                  e.target.value
                                );

                                updateIsValid(stage.id, errorMsg === undefined);

                                if (errorMsg) {
                                  setErrorMsg(
                                    `${value} stage name ${errorMsg}`
                                  );
                                }
                              } else {
                                updateIsValid(stage.id, false);
                              }
                            }}
                          />
                        </div>

                        <div className="d-inline-flex form-line three pl-xl-4 ml-xl-4 pl-lg-2 ml-lg-2 my-lg-0 my-1  ml-0">
                          <div className="d-flex align-items-center mx-xl-5 ">
                           Could potentially host multiple environments. E.g. POC, SIT, E2E, Perf, UAT, Prod, DR
                          </div>
                        </div>
                        <button disabled={disabled}
                          type="button"
                          className="closeFormRow closeFormRowBtn four"
                          onClick={() => removeStage(stage.id)}
                        >
                          <img alt="" src="../images/bigfontclose.svg" />
                        </button>
                        <button disabled={disabled}
                          type="button"
                          className="closeFormRowMobil closeFormRowBtn four"
                          onClick={() => removeStage(stage.id)}
                        >
                          <img alt="" src="../images/bigfontclose.svg" />
                        </button>
                      </div>
                    ))}
                  </div>

                  <div className="w-100 px-md-2 p-2 mb-2  px-2">
                    <div className="row d-flex align-items-center">
                      <div className="add-button d-flex  justify-content-end">
                        <button disabled={disabled}
                          type="button"
                          className="add-new-account-link btn-animation d-inline-flex align-items-center"
                          onClick={addStage}
                        >
                          <p className="my-0 mr-2 p-0 green-style2">
                            Add SDLC Stage
                          </p>
                          <img alt="" src="../images/coloredPlus.svg" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </fieldset>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.GCP_PLATFORM_ACCOUNTS + "/" + projectId
                      )
                    }
                  >
                    <img alt="" src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Platform Accounts
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex font-weight-bold"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.GCP_WORKLOAD_ACCOUNTS + "/" + projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                    Workload Accounts
                    </p>
                    <img alt="" src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default GcpEnvironmentStrategy;
