import { useEffect, useState } from "react";
import useAwsAmplify from "./useAwsAmplify";

const useRegions = (projectId) => {
  const awsAmplify = useAwsAmplify();
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const getProjectTypeResp = await awsAmplify.getProjectType(
        projectId);

      const projectType = getProjectTypeResp.data.getProjectType;

      const REGION_KEY = `/${projectType.toLowerCase()}/network/aws/awsregionslist`;

      const regionsData = await awsAmplify.loadProjectData(
        projectId,
        REGION_KEY
      );

      if (
        regionsData.data &&
        regionsData.data[REGION_KEY]
      ) {
        setRegions(regionsData.data[
            REGION_KEY
        ].split(","));
      }
    };

    fetch();
  }, [projectId]);

  return regions;
};

export default useRegions;
