import React from "react";
import * as ROUTES from "../../../constants/routes";

const AzurePrelaunchComponent = ({ projectId, prelaunchData, setErrorMsg }) => {
  const getClassName = (key) => {
    if (prelaunchData) {
      let className = "signsInput";

      switch (prelaunchData && prelaunchData[key].status) {
        case "NA":
          className = "naInput";
          break;
        case "FAIL":
          className = "cancelInput";
          break;
        case "PASS":
          className = "checkmarkInput";
          break;
        case "NODATA":
          className = "noDataInput";
          break;
        default:
          className = "signsInput";
          break;
      }

      return className;
    } else {
      return "signsInput";
    }
  };

  const onClick = (key) => {
    if (prelaunchData[key].status === "FAIL") {
      setErrorMsg(prelaunchData[key].message);
    }
  };

  const getGrayText = (key) => {
    return prelaunchData && prelaunchData[key].status === "NA"
      ? "text-color-gray"
      : "";
  };

  return (
    <>
      <div className="mainContentArea preLaunchArea row px-2 py-2 mt-2">
        <div className="col-xl-6 col-lg-6 col-12 p-0">
          <div className="row">
            <div className="col-12 d-flex flex-column">
            <div className="build__box-head p-relative">
                <div>
                  <img
                    src="../images/icons/icon-account-framework.svg"
                    alt=""
                  ></img>
                  <h3 className="font-15 px-3">
                  Subscription Framework
                  </h3>
                </div>
                 </div>
             
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("mangementgroupStructure")}
                  onClick={() => onClick("mangementgroupStructure")}
                ></span>
                <a
                  href={`${ROUTES.AZURE_ORGANIZATIONAL_STRUCTURE}/${projectId}`}
                  className="font-12"
                >
                  Orgnaizational Structure
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("platformSubscriptions")}
                  onClick={() => onClick("platformSubscriptions")}
                ></span>
                <a
                  href={`${ROUTES.AZURE_PLATFORM_SUBSCRIPTIONS}/${projectId}`}
                  className="font-12"
                >
                  Platform Subscriptions
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("workloadSubscriptions")}
                  onClick={() => onClick("workloadSubscriptions")}
                ></span>
                <a
                  href={`${ROUTES.AZURE_WORKLOAD_SUBSCRIPTIONS}/${projectId}`}
                  className="font-12"
                >
                  Workload Subscriptions
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("sandboxSubscriptions")}
                  onClick={() => onClick("sandboxSubscriptions")}
                ></span>
                <a
                  href={`${ROUTES.AZURE_SANDBOX_SUBSCRIPTIONS}/${projectId}`}
                  className="font-12"
                >
                  Sandbox Subscriptions
                </a>
              </div>
            </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default AzurePrelaunchComponent;
