import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import useServiceAccounts from "../../../../hooks/useServiceAccounts";
import RadioGroup from "../../RadioGroup";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/security/intelligentthreatdetection";

const getInitialFormData = (data) => {
  const initialFormData = {
    deploycentralizedthreatdetection: {
      value: data[`${PREFIX}/deploycentralizedthreatdetection`] || "no",
    },
    guardduty_centralizeguarddutyfindings: {
      value: data[`${PREFIX}/guardduty/centralizeguarddutyfindings`] || "yes",
    },
    guardduty_guarddutymasteraccount: {
      value: data[`${PREFIX}/guardduty/guarddutymasteraccount`] || "",
    },
    darktrace_deploydarktraceintegration: {
      value: data[`${PREFIX}/darktrace/deploydarktraceintegration`] || "yes",
    },
  };

  return initialFormData;
};

const IntelligentThreatDetection = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();
  const accounts = useServiceAccounts(projectId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        setEmpty(result.data.empty);
        setFormData(convertBEToFE(result.data));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploycentralizedthreatdetection`]: formData
        .deploycentralizedthreatdetection.value,
    };

    if (formData.deploycentralizedthreatdetection.value === "yes") {
      be[`${PREFIX}/darktrace/deploydarktraceintegration`] =
        formData.darktrace_deploydarktraceintegration.value;

      be[`${PREFIX}/guardduty/centralizeguarddutyfindings`] =
        formData.guardduty_centralizeguarddutyfindings.value;

      if (
        formData.guardduty_centralizeguarddutyfindings.value === "yes" &&
        formData.guardduty_guarddutymasteraccount.value !== ""
      ) {
        be[`${PREFIX}/guardduty/guarddutymasteraccount`] =
          formData.guardduty_guarddutymasteraccount.value;
      }
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Security</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Security"
                isDirty={isDirty}
                dashboardRoute={ROUTES.SECURITY_HUB_MEMBERS}
                designRoute={ROUTES.DESIGN_SECURITY}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div className="row d-flex align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-4 pl-lg-0 px-2">
                    <h3>Intelligent Threat Detection</h3>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 d-flex align-items-center justify-content-end rightNavArea">
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 text-nowrap">
                        Deploy Centralized Intelligent Threat Detection
                      </div>
                      <div className=" btn-group btn-group-toggle btn-group-radio mw-100 ">
                        <RadioGroup
                          field="deploycentralizedthreatdetection"
                          value={
                            formData.deploycentralizedthreatdetection.value
                          }
                          onChange={updateRadio}
                          isLarge={true}
                        ></RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>
                {empty && (
                  <NoInitialData />
                )}
                {formData.deploycentralizedthreatdetection.value === "yes" && (
                  <div className="row show-hide-content">
                    <div className="mainContentArea row px-2 py-1 mt-2">
                      <div className="col-md-6 col-12 px-1  my-1">
                        <div className="formRow w-100">
                          <div className="one form-line d-inline-flex align-items-center">
                            <label className="switch mb-0">
                              <input
                                type="checkbox"
                                checked={
                                  formData.guardduty_centralizeguarddutyfindings
                                    .value === "yes"
                                }
                                onChange={(e) =>
                                  updateRadio(
                                    "guardduty_centralizeguarddutyfindings",
                                    e.target.checked ? "yes" : "no"
                                  )
                                }
                              />
                              <span className="slider round"></span>
                            </label>
                            <span className="switchLabel">
                              Centralize GuardDuty findings
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-12 px-1 my-1">
                        <div className="formRow w-100">
                          <div className="one form-line d-inline-flex align-items-center">
                            <label className="switch mb-0">
                              <input
                                type="checkbox"
                                checked={
                                  formData.darktrace_deploydarktraceintegration
                                    .value === "yes"
                                }
                                onChange={(e) =>
                                  updateRadio(
                                    "darktrace_deploydarktraceintegration",
                                    e.target.checked ? "yes" : "no"
                                  )
                                }
                              />
                              <span className="slider round"></span>
                            </label>
                            <span className="switchLabel">
                              Deploy Integration with DarkTrace Cyber AI
                              Platform
                            </span>
                          </div>
                        </div>
                      </div>
                      {formData.guardduty_centralizeguarddutyfindings.value ===
                        "yes" && (
                        <div className="d-inline-flex flex-wrap w-100 my-1 row">
                          <div className="d-inline-flex col-md-3 col-xxl-2 pl-lg-0 px-2 my-1">
                            <label className="font-12 d-flex align-items-center mb-0 ">
                              GuardDuty Master Account
                            </label>
                          </div>
                          <div className="col-md-9 col-xxl-10">
                            <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                              <select
                                className="form-control gray"
                                value={
                                  formData.guardduty_guarddutymasteraccount
                                    .value
                                }
                                onChange={(e) =>
                                  updateRadio(
                                    "guardduty_guarddutymasteraccount",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">Select</option>
                                {accounts.security.map((item, idxItem) => (
                                  <option value={item} key={idxItem}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.SECURITY_COMPLIANCE_MANAGEMENT + "/" + projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Compliance Management
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.SECURITY_VULNERABILITY_MANAGEMENT +
                          "/" +
                          projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Vulnerability Management
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default IntelligentThreatDetection;
