import React, { useState, useEffect } from "react";

import LeftHandMenu from "../LeftHandMenu";
import TopMenu from "../../TopMenu";

import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../hooks/useHideLeftMenu";
import DomainMenu from "../DomainMenu";

import * as ROUTES from "../../../constants/routes";
import { getError } from "../../../helpers/errorHelper";

import PrelaunchComponent from "../alicloud/PrelaunchComponent";

const AlicloudConfigure = () => {
  const history = useHistory();
  const { projectId } = useParams();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [prelaunchData, setPrelaunchData] = useState(null);

  const [project, setProject] = useState();

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);
        const result = await awsAmplify.configure(projectId);

        console.log(result);

        if (
          result.data &&
          result.data.domainStatus &&
          result.data.projectStatus
        ) {
          const domainStatus = result.data.domainStatus;

          setPrelaunchData(domainStatus);
        }

        const results = await awsAmplify.getMyProjectStatusAndLocked(projectId);

        const project = results?.data?.getProjectByID?.project;
        
        setProject(project);

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        const errorMsg = getError(err, setErrorMsg);

        if (errorMsg === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  useHideLeftMenu();

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Pre-launch Checks</div>
      <LeftHandMenu></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu></TopMenu>
            <div className="tableArea accountsTableArea aplicationTableArea container-fluid p-0 d-flex flex-column">
              <DomainMenu
                title="Configure"
                isDashboard={false}
                isConfigure={true}
                isPrelaunch={false}
                dashboardRoute={ROUTES.ALICLOUD_ORGANIZATIONAL_UNITS}
                designRoute={ROUTES.ALICLOUD_DESIGN_ACCOUNT_FRAMEWORK}
                configureRoute={ROUTES.ALICLOUD_PLATFORM_ACCOUNTS}
              ></DomainMenu>

              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <span className="font-12">Project State: {project && project.Locked === "yes" ? "Locked" : "Unlocked"}</span>
                <div className="row">
                  <PrelaunchComponent
                    projectId={projectId}
                    prelaunchData={prelaunchData}
                    setErrorMsg={setErrorMsg}
                  ></PrelaunchComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlicloudConfigure;
