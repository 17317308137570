import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../Dashboard";
import * as ROUTES from "../../../../constants/routes";

//const projectId = "google_1";
const dashboardName = "Identity Providers";

const Details = ({ close, selectedRow }) => {
  return (
    <div className="justify-content-center align-items-end account-detail">
      <div className="w-100 s-flex flex-column account-detail-area">
        <button className="exitAccount" onClick={() => close()}>
          <img src="../images/close.svg" />
        </button>
        <div className="">
          <ul className="account-detail-link m-0 p-0 d-inline-flex">
            <li className="bolgeler-btn my-active px-3 py-2 my_active">
              Details
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 account-detail-container-area">
          <div className="bolgeler-container w-100 row p-5">
            <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Account ID</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow["Account ID"]}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Type</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Type}
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">ARN</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Arn}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Creation Date</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.CreateDate}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Valid Until</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.ValidUntil}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Navigations = () => {
  const { projectId } = useParams();

  return (
    <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.IAM_ROLES + "/" + projectId}
    >
      <p className="m-0 p-0 mr-2 font-weight-bold">IAM Roles</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
  );
};

const IdentityProviders = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "Account ID",
        width: 35,
      },
      {
        Header: "Type",
        accessor: "Type",
        width: 20,
      },
      {
        Header: "Creation Date",
        accessor: "CreateDate",
        width: 60,
      },
      {
        Header: "Valid Until",
        accessor: "ValidUntil",
        width: 60,
      },
      {
        Header: "ARN",
        accessor: "Arn",
      },
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "iam",
    title: "Identity & Access",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.SINGLE_SIGN_ON,
    designRoute: ROUTES.DESIGN_IAM,
  });
};

export default IdentityProviders;
