import React, { useState } from "react";

function ShowHidePassword({field, passwordInput, onFieldChange, onFieldBlur, validatorType,disabled, inputClassName}){

    const [passwordType, setPasswordType] = useState("password");
    const togglePassword = ()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }
    return(
        <>
                <button disabled={disabled}
                    type="button"
                    onClick={togglePassword}
                    className="showableInput"
                ></button>
            <input type={passwordType==="password" ? "password" : "text"} 
                value={passwordInput} 
                name={field}
                className={inputClassName}
                onChange={(e) => onFieldChange(e.target.value,field)}
                onBlur={(e) =>
                    onFieldBlur(
                        validatorType,
                        e.target.value,
                        field)
                    }			
            /> 
            </>               
    )

}
export default ShowHidePassword;