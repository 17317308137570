import React, { useEffect, useState } from "react";
import update from "immutability-helper";

import useAwsAmplify from "../../hooks/useAwsAmplify";
import {
  validate2to32AlphaNumeric,
  validateAccountEmailId,
  validateEmail,
  validateRequired,
} from "../../helpers/validator";
import useSession from "../../hooks/useSession";
import { getError } from "../../helpers/errorHelper";

const lookupObj = {
  firstName: "First Name",
  lastName: "Last Name",
  emailId: "Email",
  privilege: "User Privilege",
};

const UserForm = ({ close, user }) => {
  const { authUser, isUser, isClientAdmin, isSuperAdmin, group } = useSession();
  const awsAmplify = useAwsAmplify();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstName: {
      value: user ? user.FirstName : "",
      isValid: user ? true : false,
    },
    lastName: {
      value: user ? user.LastName : "",
      isValid: user ? true : false,
    },
    emailId: {
      value: user ? user.EmailID : "",
      isValid: user ? true : false,
    },
    assignedProjects: user ? user.AssignedProjects : [],
    selectedAssignedProjectID: {
      value: "",
    },
    privilege: {
      value: user ? user.Privilege : "",
      isValid: user ? true : false,
    },
    projects: user
      ? user.Projects.filter(
          (p) =>
            user.AssignedProjects &&
            user.AssignedProjects.indexOf(p.ProjectID) === -1
        )
      : [],
  });

  const [isDirty, setIsDirty] = useState(false);

  const [projects, setProjects] = useState(user ? user.Projects : []);
  const [domainName, setDomainName] = useState("");
  const [privileges, setPrivileges] = useState([]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    console.log(formData);

    const errorMsgs = [];

    for (const prop in formData) {
      if (formData[prop].hasOwnProperty("isValid") && !formData[prop].isValid) {
        if (formData[prop].errorMsg) {
          errorMsgs.push(formData[prop].errorMsg);
        } else {
          errorMsgs.push(`${lookupObj[prop]} must be provided`);

          setFormData((state) =>
            update(state, {
              [prop]: {
                errorMsg: {
                  $set: errorMsg
                    ? `${lookupObj[prop]} ${errorMsg}`
                    : `${lookupObj[prop]} must be provided`,
                },
              },
            })
          );
        }
      }
    }

    if (errorMsgs.length > 0) {
      setErrorMsg(errorMsgs);
      return;
    }

    try {
      setIsLoading(true);

      if (user) {
        const result = await awsAmplify.updateUser(
          user.UserID,
          formData.firstName.value,
          formData.lastName.value,
          formData.emailId.value,
          formData.privilege.value,
          formData.privilege.value === "User" ? formData.assignedProjects : []
        );
        console.log(result);

        setIsLoading(false);
        setIsDirty(false);

        close(true, "create", "Successfully updated the user");
      } else {
        const result = await awsAmplify.createUser(
          formData.firstName.value,
          formData.lastName.value,
          formData.emailId.value,
          formData.privilege.value,
          formData.privilege.value === "User" ? formData.assignedProjects : []
        );
        console.log(result);

        setIsLoading(false);
        setIsDirty(false);

        close(true, "create", "Successfully created the user");
      }
    } catch (err) {
      setIsLoading(false);

      const errorMsg = getError(err);
      if (errorMsg === "User doesn't exist") {
        close(true, "create", null, errorMsg);
      }
      else{
        setErrorMsg(errorMsg);
      }
    }
  };

  const onFieldChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [name]: { value: { $set: value } } })
    );
  };

  const onFieldBlur = (validateFunc, event) => {
    const value = event.target.value;
    const name = event.target.name;

    let errorMsg;

    if (value === "") {
      errorMsg = "must be provided";
    } else {
      errorMsg = validateFunc(value);
    }

    setFormData((state) =>
      update(state, {
        [name]: {
          isValid: { $set: errorMsg === undefined },
          errorMsg: {
            $set: errorMsg ? `${lookupObj[name]} ${errorMsg}` : errorMsg,
          },
        },
      })
    );

    if (errorMsg) {
      setErrorMsg(`${lookupObj[name]} ${errorMsg}`);
    }

    return errorMsg === undefined;
  };

  const addAssignedProject = (projectId) => {
    const exists = (value) => {
      return formData.assignedProjects.indexOf(value) !== -1;
    };

    if (projectId) {
      if (!exists(projectId)) {
        setFormData((state) => {
          let idx = -1;

          state.projects.forEach((p, idxP) => {
            if (p.ProjectID === projectId) {
              idx = idxP;
            }
          });

          console.log(`idx: ${idx}`);

          return update(state, {
            assignedProjects: { $push: [projectId] },
            projects: { $splice: [[idx, 1]] },
          });
        });
      }
    }
  };

  const removeAssignedProject = (projectId, projectName) => {
    setFormData((state) =>
      update(state, {
        assignedProjects: {
          $splice: [[state.assignedProjects.indexOf(projectId), 1]],
        },
        projects: {
          $push: [{ ProjectID: projectId, ProjectName: projectName }],
        },
        selectedAssignedProjectID: {
          value: {
            $set: "",
          },
        },
      })
    );
  };

  const fetchProjects = async (domainName) => {
    try {
      setErrorMsg(null);
      setIsLoading(true);
      const results = await awsAmplify.findProjectsByDomainName(domainName);
      setIsLoading(false);

      console.log(results);

      if (results.data && results.data.findProjectsByDomainName) {
        setProjects(results.data.findProjectsByDomainName);

        setFormData((state) =>
          update(state, {
            projects: { $set: results.data.findProjectsByDomainName },
          })
        );
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err && err.message) {
        setErrorMsg(err.message);
      } else if (err && err.response && err.response.data) {
        setErrorMsg(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (domainName !== "") {
      fetchProjects(domainName);
    }
  }, [domainName]);

  useEffect(() => {
    if (isSuperAdmin) {
      setPrivileges(["SuperAdmin", "ClientAdmin", "User"]);
    } else if (isClientAdmin) {
      setPrivileges(["ClientAdmin", "User"]);
    }
  }, [isSuperAdmin, isClientAdmin]);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong>
              {Array.isArray(errorMsg)
                ? errorMsg.map((em) => (
                    <>
                      <span>{em}</span>
                    </>
                  ))
                : errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="justify-content-center align-items-center popupArea create-user">
        <div className="auth-main">
          <form className="auth-form" onSubmit={onSubmit}>
            <button
              type="button"
              className="exit btn-animation"
              onMouseDown={() => close(false, "create")}
            >
              Exit
            </button>
            <div className="title">{user ? "Update" : "Create"} User</div>

            <div className="auth-form-content d-flex flex-column justify-content-center">
              <input
                disabled={user !== null}
                type="text"
                className={
                  "form-control name " +
                  (formData.firstName.errorMsg && "invalid")
                }
                placeholder="First Name"
                name="firstName"
                value={formData.firstName.value}
                onChange={onFieldChange}
                onBlur={(e) => onFieldBlur(validate2to32AlphaNumeric, e)}
              />

              <input
                disabled={user !== null}
                type="text"
                className={
                  "form-control surname " +
                  (formData.lastName.errorMsg && "invalid")
                }
                placeholder="Last Name"
                name="lastName"
                value={formData.lastName.value}
                onChange={onFieldChange}
                onBlur={(e) => onFieldBlur(validate2to32AlphaNumeric, e)}
              />

              <div className="halfBox d-flex flex-column">
                <div className="inlineRow d-inline-flex justify-content-between my-1">
                  <input
                    disabled={user !== null}
                    type="mail"
                    className={
                      "form-control mail m-0 flex-1 " +
                      (formData.emailId.errorMsg && "invalid")
                    }
                    placeholder="Email"
                    name="emailId"
                    value={formData.emailId.value}
                    onChange={onFieldChange}
                    onBlur={(e) => {
                      const validate = (email) => {
                        const errorMsg = validateEmail(email);

                        if (errorMsg === undefined) {
                          const domainName = email.split("@")[1];

                          if (isClientAdmin) {
                            if (
                              authUser.attributes &&
                              authUser.attributes.email &&
                              authUser.attributes.email.split("@")[1] !==
                                domainName.toLowerCase()
                            ) {
                              return "You are not allowed to create user in other domain";
                            }
                          }

                          setDomainName(domainName);
                        }

                        return errorMsg;
                      };

                      onFieldBlur(validate, e);
                    }}
                  />
                </div>
              </div>

              <div className="halfBox d-flex flex-column">
                <div className="inlineRow d-inline-flex justify-content-between my-1">
                  <div className="titleOfContent boldTitle">User Privilege</div>
                  <div className={formData.privilege.errorMsg ? "invalid" : ""}>
                    <select
                      className="selectInput"
                      name="privilege"
                      value={formData.privilege.value}
                      onChange={onFieldChange}
                      onBlur={(e) => onFieldBlur(validateRequired, e)}
                    >
                      <option value="">Select</option>
                      {privileges.map((p, pIdx) => (
                        <option key={pIdx} value={p}>
                          {p}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {formData.privilege.value === "User" && (
                  <>
                    <label className="my-1 titleLabel ">
                      Assigned Projects
                    </label>

                    {formData.assignedProjects &&
                      formData.assignedProjects.map(
                        (projectId, idxProjectId) => {
                          const assignedProject = projects.find(
                            (p) => p.ProjectID === projectId
                          );
                          console.log(assignedProject);

                          if (assignedProject) {
                            return (
                              <div
                                key={idxProjectId}
                                className="assignedUser inlineRow d-inline-flex justify-content-between my-1 align-items-center"
                                style={{ position: "relative" }}
                              >
                                <button
                                  className="negativeIcon"
                                  type="button"
                                  onClick={() =>
                                    removeAssignedProject(
                                      assignedProject.ProjectID,
                                      assignedProject.ProjectName
                                    )
                                  }
                                >
                                  <img src="../image/negative.svg" />
                                </button>
                                <p className="adminMail text-center py-1 m-0 spec-padding">
                                  {`${assignedProject.ProjectName}`}
                                </p>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        }
                      )}

                    <div className="inlineRow d-inline-flex justify-content-between my-1">
                      <button
                        type="button"
                        className="plusIcon"
                        onClick={() =>
                          addAssignedProject(
                            formData.selectedAssignedProjectID.value
                          )
                        }
                      >
                        <img src="../image/Layer%2020.svg" />
                      </button>

                      <select
                        className="selectInput w-100"
                        name="selectedAssignedProjectID"
                        value={formData.selectedAssignedProjectID.value}
                        onChange={onFieldChange}
                      >
                        <option value="">Select</option>
                        {formData.projects.map((dm, idxDm) => (
                          <option key={idxDm} value={dm.ProjectID}>
                            {`${dm.ProjectName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="auth-form-footer">
              <div>
                <button
                  type="submit"
                  className="confirmBtn btn btn-link btn-green btn-animation"
                >
                  {user ? "Update" : "Create"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {console.log(formData)}
    </>
  );
};

export default UserForm;
