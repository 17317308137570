import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "./DetailsTags";
import { getArrayValue } from "./DashboardHelper";
import DetailsGrid from "./DetailsGrid";

const dashboardName = "Firewall Policies";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
      <>
        <h3>Network Firewall</h3>
        <div className="otherTableTitles ">
          <h3 className="font-15">   
           <Link className="text-color-gray" to={ROUTES.NETWORK_FIREWALL_FIREWALLS + "/" + projectId}>
             Firewalls
            </Link></h3>
          <h3 className="font-15 activetable">      
             Policies
          </h3>
          <h3 className="font-15">
            <Link className="text-color-gray" to={ROUTES.NETWORK_FIREWALL_RULEGROUPS + "/" + projectId}>
            Rule Groups
            </Link>
          </h3>
        </div>
      </>
    );
  };

  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Stateless Rule Groups" && "my_active"
                }`}
                onClick={() => setType("Stateless Rule Groups")}
              >
                Stateless Rule Groups
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Stateful Rule Groups" && "my_active"
                }`}
                onClick={() => setType("Stateful Rule Groups")}
              >
                Stateful Rule Groups
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Tags" && "my_active"
                }`}
                onClick={() => setType("Tags")}
              >
                Tags
              </li> 
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Name}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Description</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Description}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Policy Status
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.FirewallPolicyStatus}
                  </p>
                </div>
              </div>           
            </div>


            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Stateless Rule Groups" && "inaktif"
              }`}
            >
           
           <div className="detailTableType-1">
            <div className="d-flex flex-column">
                <table className="details-table font-15">
                  <thead className="font-15">
                    <tr>
                      <th><b>Priority</b></th>
                      <th><b>Name</b></th>
                      <th><b>Capacity</b></th>
                    </tr>
                  </thead>
                  <tbody>
                   {statelessRuleGroups}                  
                  </tbody>
                </table>
              </div>
           </div>
          
  
             <div class="detailTableType-1">
                <div  class="d-flex flex-column">
                <p class="account-detail-subtitle-1 details-table mt-3 font-15">
                    <b> Stateless default actions </b>
                </p>
                  <p class="account-detail-subtitle-1">
                    Actions for full packets
                  </p>
                  <p class="account-detail-subtitle-1">
                    Actions for fragmented packets
                  </p> 
                </div>
                
                  <div  class="d-flex flex-column">
                    <p class="account-detail-subtitle-1 details-table mt-5 font-15">
                        
                    </p>
                    <p class="account-title-subcontent">
                    {selectedRow.StatelessDefaultActionsFullPackets}
                    </p>
                    <p class="account-title-subcontent">
                    {selectedRow.StatelessDefaultActionsFragmentedPackets}
                    </p>                         
                  </div>
                </div>       
            
            </div>
  
            <DetailsGrid
              type={type}
              typeName="Stateful Rule Groups"
              array={selectedRow.StatefulRuleGroupReferences}
              metadata={[
                { header: "Name", key: "ResourceArn" },
                { header: "Capacity", key: "Capacity" },       
              ]}
            ></DetailsGrid>

            <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>

          </div>
        </div>
      </div>
    );
  };

  const statelessRuleGroups = (selectedRow) => {
    let  content = [];
   for( var idx in selectedRow.StatelessRuleGroupReferences)
   {
     var obj = selectedRow.StatelessRuleGroupReferences[idx];
     content.push(
     <tr key={idx}>
     <td>{idx}</td>
     <td>{obj && obj.Priority}</td>
     <td>{obj && obj.ResourceArn}</td>
     <td>{obj && obj.Capacity}</td>
   </tr>
     );
   }
    return content;
 }

  const Navigations = () => {
    const { projectId } = useParams();
    return (
    <>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.VPN + "/" + projectId}
      >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Hybrid Network</p>
      </Link>
      <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.TRAFFIC_MIRROR_TARGETS + "/" + projectId}
    >
      <p className="m-0 p-0 mr-2 font-weight-bold">Traffic Mirror Targets</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
    </>
    );
};
  
  
const FirewallPolicies = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        id: "Account ID",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "Name",
        id: "Name",
        accessor: "Name",
      },
      {
        Header: "Description",
        id: "Description",
        accessor: "Description",
      },
      {
        Header: "Policy Status",
        id: "Policy Status",
        accessor: "FirewallPolicyStatus",
      },
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "network",
    title: "Network",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_CENTERS,
    designRoute: ROUTES.DESIGN_NETWORK,
    SubMenu: SubMenu,
  });
};

export default FirewallPolicies;