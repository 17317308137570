import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";
import useServiceAccounts from "../../../../hooks/useServiceAccounts";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import { validateCidr, validateIPAddress } from "../../../../helpers/validator";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/infrastructure/workspaces";
const AWS_PRIMARY_REGION_PREFIX = "/aws/network/aws/awsprimaryregion";

const getInitialFormData = (data, workspaceBundles) => {
  const initialFormData = {
    deployawsworkspaces: {
      value: data[`${PREFIX}/deployawsworkspaces`] || "no",
    },
    workspacesaccount: {
      value: data[`${PREFIX}/workspacesaccount`] || "",
    },
    limitaccesstoknownnetworks: {
      value: data[`${PREFIX}/limitaccesstoknownnetworks`] || "no",
    },
    knownnetworks: [{ value: "", isValid: false }],
    createworkspace: {
      value: data[`${PREFIX}/createworkspace`] || "yes",
    },
    adminworkspace_bundleid: {
      value: data[`${PREFIX}/adminworkspace/bundleid`] || "",
    },
    adminworkspace_runningmode: {
      value: data[`${PREFIX}/adminworkspace/runningmode`] || "",
    },
    workspaceBundles: workspaceBundles || []
  };

  if (data[`${PREFIX}/knownnetworks`]) {
    initialFormData.knownnetworks = data[`${PREFIX}/knownnetworks`]
      .split(",")
      .map((value) => {
        return {
          value: value,
          isValid: true,
        };
      });
  }

  return initialFormData;
};

const VDISolution = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);
  const awsAmplify = useAwsAmplify();
  const accounts = useServiceAccounts(projectId);

  const [primaryRegion, setPrimaryRegion] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const primaryRegionResult = await awsAmplify.loadProjectData(projectId, AWS_PRIMARY_REGION_PREFIX);

        console.log(`primaryRegionResult`);
        console.log(primaryRegionResult);
        const primaryRegion = primaryRegionResult.data[AWS_PRIMARY_REGION_PREFIX];

        console.log(primaryRegion);

        if(primaryRegion){
          setPrimaryRegion(primaryRegion);
        }
        else{
          setIsLoading(false);
          return;
        }

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);

        const workspaceBundlesResult = await awsAmplify.getWorkspaceBundles(projectId);

        setIsLoading(false);

        setEmpty(result.data.empty);

        setFormData(convertBEToFE(result.data, workspaceBundlesResult.data.getWorkspaceBundles));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data, workspaceBundles) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data, workspaceBundles);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deployawsworkspaces`]: formData.deployawsworkspaces.value,
    };

    if (formData.deployawsworkspaces.value === "yes") {
      if (formData.workspacesaccount.value !== "") {
        be[`${PREFIX}/workspacesaccount`] = formData.workspacesaccount.value;
      }

      be[`${PREFIX}/limitaccesstoknownnetworks`] =
        formData.limitaccesstoknownnetworks.value;

      if (
        formData.limitaccesstoknownnetworks.value === "yes" &&
        formData.knownnetworks.filter((item) => item.isValid).length > 0
      ) {
        be[`${PREFIX}/knownnetworks`] = formData.knownnetworks
          .filter((item) => item.isValid)
          .map((item) => item.value)
          .join(",");
      }

      be[`${PREFIX}/createworkspace`] =
        formData.createworkspace.value;
      if (formData.createworkspace.value === "yes") {
        if (formData.adminworkspace_bundleid.value !== "") {
          be[`${PREFIX}/adminworkspace/bundleid`] =
            formData.adminworkspace_bundleid.value;
        }

        if (formData.adminworkspace_runningmode.value !== "") {
          be[`${PREFIX}/adminworkspace/runningmode`] =
            formData.adminworkspace_runningmode.value;
        }
      }
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Infrastructure</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Infrastructure"
                isDirty={isDirty}
                dashboardRoute={ROUTES.INFRASTRUCTURE_DIRECTORIES}
                designRoute={ROUTES.DESIGN_INFRASTRUCTURE}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div className="row d-flex align-items-center">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3>VDI Solution</h3>
                  </div>
                  <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    {primaryRegion && (
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label font-12 mr-xl-5 mr-lg-3 mr-1 ">
                        Deploy VDI Solution
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                        <RadioGroup
                          field="deployawsworkspaces"
                          value={formData.deployawsworkspaces.value}
                          onChange={updateRadio}
                          isLarge={true}
                        ></RadioGroup>
                      </div>
                    </div>
                    )}
                  </div>
                </div>

                {!primaryRegion && (
                      <span className="warning">
                        This depends on the AWS Primary Region information in 'AWS Environment" section of 'Network' domain that is yet to be provided.
                      </span>
                  )}

                {empty && (
                  <NoInitialData />
                )}

                {formData.deployawsworkspaces.value === "yes" && (
                  <div className="row">
                    <div className="mainContentArea row px-2  my-1 py-1 show-hide-content">
                      <div className="d-inline-flex flex-wrap w-100 my-1 row">
                        <div className="d-inline-flex col-md-3 pl-lg-0 px-2 my-1">
                          <label className="font-12 d-flex align-items-center mb-0 ">
                            AWS Workspaces Account
                          </label>
                        </div>
                        <div className="col-md-9">
                          <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                            <select
                              className="form-control gray"
                              value={formData.workspacesaccount.value}
                              onChange={(e) => {
                                const value = e.target.value;
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    workspacesaccount: {
                                      value: { $set: value },
                                    },
                                  })
                                );
                              }}
                            >
                              <option value="">Select</option>
                              {accounts.shared
                                .concat(accounts.security)
                                .map((acc, idxAcc) => (
                                  <option key={idxAcc} value={acc}>
                                    {acc}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div className="pl-lg-0 px-2 d-flex align-items-center col-md-3 mr-0 my-1">
                          <div className="font-12">
                            Limit Access to Known Networks
                          </div>
                        </div>
                        <div className="col-md-9 my-1">
                          <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-xl-5 mr-md-3 mr-0">
                            <RadioGroup
                              field="limitaccesstoknownnetworks"
                              value={formData.limitaccesstoknownnetworks.value}
                              onChange={updateRadio}
                              isLarge={true}
                            ></RadioGroup>
                          </div>
                        </div>
                      </div>
                      {formData.limitaccesstoknownnetworks.value === "yes" && (
                        <div className="show-hide-sub-content">
                          <div className=" d-inline-flex align-items-center w-100 row">
                            <div className="col-md-3  my-1 p-0">
                              <span className="mb-0 font-12">
                                IP address or CIDR block
                              </span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                              <div className="mr-3 my-1 ">
                                {formData.knownnetworks.map((item, idxItem) => (
                                  <div key={idxItem} className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                    <button disabled={disabled}
                                      className="closableInput"
                                      type="button"
                                      onClick={(e) => {
                                        if (formData.knownnetworks.length > 1) {
                                          setIsDirty(true);

                                          setFormData((state) =>
                                            update(state, {
                                              knownnetworks: {
                                                $splice: [[idxItem, 1]],
                                              },
                                            })
                                          );
                                        }
                                      }}
                                    ></button>
                                    <input
                                      type="text"
                                      className={`form-control bg-transparent ${
                                        item.value !== "" &&
                                        item.isValid === false
                                          ? "invalid"
                                          : ""
                                      }`}
                                      placeholder=""
                                      value={item.value}
                                      onChange={(e) => {
                                        const value = e.target.value;

                                        for (const [
                                          idxOtherItem,
                                          otherItem,
                                        ] of formData.knownnetworks.entries()) {
                                          if (idxItem !== idxOtherItem) {
                                            if (
                                              otherItem.value !== ""
                                            ) {
                                              if (otherItem.value.toLowerCase() === value.toLowerCase()) {
                                                setErrorMsg(
                                                  "IP Address or CIDR block must be unique"
                                                );
                                                return false;
                                              }
                                            }
                                          }
                                        }

                                        setErrorMsg(null);
                                        setIsDirty(true);

                                        setFormData((state) =>
                                          update(state, {
                                            knownnetworks: {
                                              [idxItem]: {
                                                value: {
                                                  $set: value,
                                                },
                                              },
                                            },
                                          })
                                        );
                                      }}
                                      onBlur={(e) => {
                                        const value = e.target.value;

                                        if (value !== "") {
                                          let errorMsg = validateIPAddress(
                                            value
                                          );

                                          if (errorMsg) {
                                            errorMsg = validateCidr(value);
                                          }

                                          setFormData((state) =>
                                            update(state, {
                                              knownnetworks: {
                                                [idxItem]: {
                                                  isValid: {
                                                    $set:
                                                      errorMsg === undefined,
                                                  },
                                                },
                                              },
                                            })
                                          );

                                          if (errorMsg) {
                                            setErrorMsg(errorMsg);
                                          }
                                        } else {
                                          setFormData((state) =>
                                            update(state, {
                                              knownnetworks: {
                                                [idxItem]: {
                                                  isValid: { $set: false },
                                                },
                                              },
                                            })
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                ))}
                                ;
                                <button disabled={disabled}
                                  type="button"
                                  onClick={(e) => {
                                    if (formData.knownnetworks.length < 10) {
                                      setIsDirty(true);

                                      setFormData((state) =>
                                        update(state, {
                                          knownnetworks: {
                                            $push: [
                                              { value: "", isValid: false },
                                            ],
                                          },
                                        })
                                      );
                                    }
                                  }}
                                >
                                  <img src="../images/coloredPlus.svg" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mainContentArea px-2  my-1 py-1 show-hide-content">
                      <div className=" align-items-center formRow my-1 py-1 row">
                        <div className="showRightContenBtn col-md-3 my-1 p-0">
                          <div className="w-100 d-inline-flex  px-md-2 px-3  align-items-center justify-content-between">
                            <div className="one form-line  d-inline-flex align-items-center">
                              <label className=" switch mb-0">
                                <input
                                  type="checkbox"
                                  checked={
                                    formData.createworkspace.value ===
                                    "yes"
                                  }
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    setIsDirty(true);

                                    setFormData((state) =>
                                      update(state, {
                                        createworkspace: {
                                          value: {
                                            $set: checked ? "yes" : "no",
                                          },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span className="slider round"></span>
                              </label>
                              <span className="switchLabel">
                                Create Workspace
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="rightContentArea col-md-9">
                          {formData.createworkspace.value === "yes" && (
                            <div className="rightContent w-100 flex-wrap">
                              <div className="mr-3 my-1">
                                <span className="font-12 mr-2">Bundle Id</span>
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  <select
                                    className="form-control"
                                    value={formData.adminworkspace_bundleid.value}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setIsDirty(true);

                                      setFormData((state) =>
                                        update(state, {
                                          adminworkspace_bundleid: {
                                            value: { $set: value },
                                          },
                                        })
                                      );
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {formData.workspaceBundles.map((bundle) => (
                                      <option value={bundle.BundleId}>{bundle.Name} ({bundle.LastUpdatedTime})</option>  
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="mr-3 my-1">
                                <span className="font-12 mr-2">Running Mode</span>
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  <select
                                    className="form-control"
                                    value={
                                      formData.adminworkspace_runningmode.value
                                    }
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setIsDirty(true);

                                      setFormData((state) =>
                                        update(state, {
                                          adminworkspace_runningmode: {
                                            value: { $set: value },
                                          },
                                        })
                                      );
                                    }}
                                  >
                                    <option value="">Select</option>
                                    <option value="ALWAYS_ON">AlwaysOn</option>
                                    <option value="AUTO_STOP">AutoStop</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(ROUTES.INFRASTRUCTURE_PATCHMANAGEMENT + "/" + projectId)
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Patch Management
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.INFRASTRUCTURE_CONTAINER_PLATFORM + "/" + projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                       Container Platform
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
               
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VDISolution;
