import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";

import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
} from "react-table";

import LeftHandMenu from "../LeftHandMenu";
import TopMenu from "../../TopMenu";

import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../hooks/useHideLeftMenu";
import DomainMenu from "../DomainMenu";

import { getError } from "../../../helpers/errorHelper";
import $ from "jquery";

const issueTypes = [
  "Assumption",
  "Dependency",
  "Design Decision",
  "Issue",
  "Risk",
  "Story",
];

const Plan = () => {
  const history = useHistory();
  const { projectId } = useParams();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [planData, setPlanData] = useState({
    Assumptions: [],
    Dependencies: [],
    DesignDecisions: [],
    Issues: [],
    Risks: [],
    Stories: [],
  });

  const mapIssueToFeatures = (issueType) => {
    var feature;
     switch(issueType)
     {
       case "Assumption" :
            feature= "Assumptions";
            break;
       case "Dependency" :
             feature = "Dependencies";
             break;
       case "Design Decision":
             feature = "DesignDecisions";
             break;
       case "Issue":
              feature = "Issues";
              break;
        case "Risk":
               feature = "Risks";
               break;
        case "Story":
               feature = "Stories";
               break;
     }
     return feature;
  }
 

  const awsAmplify = useAwsAmplify();

  const columns = useMemo(
    () => [
      {
        Header: "Summary",
        accessor: "summary",
        Cell: ({ value, row }) => {
          if (value) {
            return (
              <>
                <div className="plan__table-td"><span>{value}</span>
                <span><i style={{color: '4097d9'}}>{row.original.description}</i></span></div>
              </>
            );
          } else {
            return null;
          }
        },
      },
      {
        id: "description",
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          if (value) {
            return (
              <>
              <u>
                <img
                  src={`../images/icons/icon-${value
                    .replace(/\s/gi, "-")
                    .toLowerCase()}.png`}
                  alt=""
                ></img>
                <span>{value}</span>
                </u>
              </>
            );
          } else {
            return null;
          }
        },
      },
      {
        Header: "Assignee",
        accessor: "assignee",
      },
      {
        Header: "Due Date",
        accessor: "duedate",
      },
      {
        Header: "Sprint",
        accessor: "sprint",
      },
    ],
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);

        const errorMsgs = [];

        const promises = issueTypes.map(async (issueType) => {     
          let result = await awsAmplify.plan(projectId, issueType);
          //const issueTypeKey = issueType.replace(/\s/gi, "");

          const featureKey = mapIssueToFeatures(issueType);
          if (result.Status === "Success") {
            setPlanData((state) => {
              return { ...state, [featureKey]: result.IssuesList };
            });
          } else if (result.Status === "Failed") {
            errorMsgs.push(result.Message);
          }
        });

        await Promise.all(promises);

        if (errorMsgs.length > 0) {
          setErrorMsg(errorMsgs);
        }
      } catch (err) {
        //setIsLoading(false);
        const errorMsg = getError(err, setErrorMsg);

        if (errorMsg === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  useHideLeftMenu();

  const Feature = ({ feature }) => {

    const [tableHeight, setTableHeight] = useState('auto');
    const [activeIndex, setActiveIndex] = useState(null);
    const divElement = useRef(null);
    const divBodyElement = useRef(null);

/*     useEffect(() => {
      // setTableHeight(tableElement.current.offsetHeight);
    }, []);

   
    const mouseDown = (index) => {
      setActiveIndex(index);
    }

    const mouseMove = useCallback((e) => {
      const resizeVal = e.clientX - (divElement.current.offsetLeft);
      divElement.current.style.maxWidth = `${resizeVal}px`;
      // divBodyElement.current.style.maxWidth = `${resizeVal}px`;
      for(var row in rows){
        let element = $(e.target).closest(".plan__table-tr").find(".plan__table-col")[0];
        if(element)
            element.style.maxWidth = `${resizeVal}px`;
       
      }
    }, [activeIndex]);

    const removeListeners = useCallback(() => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('mouseup', removeListeners);
    }, [mouseMove]);

    const mouseUp = useCallback(() => {
      setActiveIndex(null);
      removeListeners();
    }, [setActiveIndex, removeListeners]);

 */

   /*  useEffect(() => {
      if (activeIndex !== null) {
        window.addEventListener('mousemove', mouseMove);
        window.addEventListener('mouseup', mouseUp);
      }
    
      return () => {
        removeListeners();
      }
    }, [activeIndex, mouseMove, mouseUp, removeListeners]);
 */

    const featureKey = feature.replace(/\s/gi, "");

    const {
      rows,
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      state: { globalFilter },
      setGlobalFilter,
      setSortBy,
    } = useTable(
      {
        columns,
        data: planData[featureKey],
        initialState: {
          globalFilter: "",
          hiddenColumns: ["description"],
          //sortBy: [{ id: "duedate", desc: false }],
        },
      },
      useGlobalFilter,
      useSortBy
    );

    return (
      <>
        <div className="plan__section">
          <div className="plan__section-head">
            <p className="plan__section-title">{feature}</p>
            <div className="plan__section-search">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="6.86393"
                  cy="6.86393"
                  r="5.24333"
                  stroke="#33325C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  
                />
                <path
                  d="M10.5108 10.7831L12.5665 12.8335"
                  stroke="#33325C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <label>
                <input
                  type="text"
                  name=""
                  placeholder="Search by keyword"
                  value={globalFilter || ""}
                  onChange={(e) => {
                    setGlobalFilter(e.target.value);
                  }}
                ></input>
              </label>
            </div>
          </div>
                      {planData[featureKey] &&
                      planData[featureKey].length === 0 ? (
                        <div className="row"> 
                      <div className="col-sm-12 h-100">
                        <span>No issues to display</span>
                        </div>
                      </div> 
                      ) : (
                        <div className="plan__section-scroll" >
                         <div className="plan__section-body"  > 
                        <div className="plan__table-row">  
                        <table
                          id="datatable"
                          className="plan__table"
                          {...getTableProps()}
                        >
                          <thead className="plan__table-head">
                            {headerGroups.map((headerGroup) => 
                            <>
                           
                             <tr className="plan__table-tr" {...headerGroup.getHeaderGroupProps()}>
                             {headerGroup.headers.map((column, i) => (
                              <>
                                 {column.Header === "Summary" && (
                                <>
                                   <div className="plan__table-col px-5" ref={divElement} >
                                  <th className="plan__table-th" 
                                    {
                                      ...
                                      column.isSorted
                                        ? column.isSortedDesc
                                          ? "descending"
                                          : "ascending"
                                        : ""
                                    } 
                                    {...column.getHeaderProps(
                                      column.getSortByToggleProps()
                                    )}
                                    
                                  >
                                    <p>
                                    <span {...column.getSortByToggleProps()}>
                                      {column.render("Header")}
                                    </span>
                                    </p>
                                  </th>
                                  </div>
                                  {/* <div class="plan__table-col px-2" resize-handle-js>
                                    <a href="#" class="" ></a>
                                 </div> */}

                                 {/*  <div
                                     style={{ height: '60px' }}
                                    onMouseDown={() => mouseDown(i)}
                                    className={`plan__table-col px-2 ${activeIndex === i ? 'active' : ''}`}
                                  > 
                                     <a href="#" class="" ></a> 
                                 </div> */}
                                  
                           
                                
                            </>
                            )} 
                           { column.Header !== "Summary" && ( 
                               <th
                                    className="plan__table-th" {
                                      ...
                                      column.isSorted
                                        ? column.isSortedDesc
                                          ? "descending"
                                          : "ascending"
                                        : ""
                                    }
                                    {...column.getHeaderProps(
                                      column.getSortByToggleProps()
                                    )} 
                                    
                                   
                                  >
                                    <p>
                                    <span {...column.getSortByToggleProps()}>
                                      {column.render("Header")}
                                    </span></p>
                                  </th>
                               )} 
                           </> ))}
                            </tr>
                            </>)}
                          </thead>
                       
                          <tbody className="plan__table-body webkit-scrollbar" 
                            {...getTableBodyProps()}
                            style={{ overflow: "auto"}}
                          >
                            {rows.map((row, i) => {
                              prepareRow(row);
                              return (
                              
                                <tr {...row.getRowProps()} className="plan__table-tr" >
                                  {row.cells.map((cell) => {
                                     return (   
                                      <>
                                      { cell.column.Header === "Summary" && (
                                       <><div class="plan__table-col">                              
                                      <td {...cell.getCellProps()} >
                                        {cell.render("Cell")}
                                      </td>
                                      </div>
                                      <div class="plan__table-col"></div> 
                                      </>
                                      )}
                                        { cell.column.Header !== "Summary" && (
                                       <>                            
                                      <td {...cell.getCellProps()} className="plan__table-td">
                                        {cell.render("Cell")}
                                      </td>
                                      </>
                                      )}

                                      </>

                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                       </div> 
                    </div>
                  </div> 
                  )}
           
        </div>
      </>
    );
  };


  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong>{" "}
              {Array.isArray(errorMsg)
                ? errorMsg.map((error) => <div>{error}</div>)
                : errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Plan</div>
      <LeftHandMenu></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu></TopMenu>
            <div className="tableArea accountsTableArea aplicationTableArea container-fluid p-0 d-flex flex-column">
              <DomainMenu
                title="Plan"
                isDashboard={false}
                isConfigure={false}
                isPrelaunch={false}
                isBuild={false}
                isPlan={true}
              ></DomainMenu>

              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <section id="plan" className="plan">
                  <div className="plan__wrapper">
                    <div className="plan__content py-2 mt-2 ">
                      <Feature feature="Design Decisions"></Feature>
                    </div>
                    <div className="plan__content py-2 mt-2">
                      <Feature feature="Assumptions"></Feature>
                    </div>
                    <div className="plan__content py-2 mt-2">
                      <Feature feature="Dependencies"></Feature>
                    </div>

                    <div className="plan__content py-2 mt-2">
                      <Feature feature="Risks"></Feature>
                    </div>

                    <div className="plan__content py-2 mt-2">
                      <Feature feature="Issues"></Feature>
                    </div>
                    <div className="plan__content py-2 mt-2">
                      <Feature feature="Stories"></Feature>
                    </div>
                  </div>
                </section>
                <section className="overlay" id="overlay"></section>
              </div>
            </div>
          </div>
        </div>
        {console.log(planData)}
      </div>
    </>
  );
};

export default Plan;
