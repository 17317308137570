


import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getBooleanValue } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "Patch Management Maintenance Windows";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
        <>
          <h3>Patch Management</h3>
          <div className="otherTableTitles ">
            <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_BASELINES + "/" + projectId}>  
               Baselines
            </Link>          
            </h3>
            <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_GROUPS + "/" + projectId}>  
              Groups  
            </Link>          
            </h3>
            <h3 className="font-15 activetable">  
              Maintenance Windows
            </h3>
            
          </div>
        </>
      );
  };

  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Tasks" && "my_active"
                }`}
                onClick={() => setType("Tasks")}
              >
                Tasks
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "History" && "my_active"
                }`}
                onClick={() => setType("History")}
              >
                History
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Targets" && "my_active"
                }`}
                onClick={() => setType("Targets")}
              >
                Targets
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Tags" && "my_active"
                }`}
                onClick={() => setType("Tags")}
              >
                Tags
              </li>
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Window ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.WindowId}
                  </p>
                </div>
           
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.Name}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Description
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.Description}
                  </p>
                </div>
             </div>

              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                    <p className="m-0 p-0 account-detail-subtitle">
                       State
                    </p>
                    <p className="m-0 p-0 account-title-subcontent">
                        {selectedRow &&  getBooleanValue(selectedRow, "Enabled")}
                    </p>
                    </div>
                    <div className="d-inline-flex w-100 my-1">
                    <p className="m-0 p-0 account-detail-subtitle">
                        Schedule
                    </p>
                    <p className="m-0 p-0 account-title-subcontent">
                        {selectedRow && selectedRow.Schedule}
                    </p>
                    </div>
                    <div className="d-inline-flex w-100 my-1">
                    <p className="m-0 p-0 account-detail-subtitle">Duration</p>
                    <p className="m-0 p-0 account-title-subcontent">
                        {selectedRow && selectedRow.Duration}
                    </p>
                 </div>
                 <div className="d-inline-flex w-100 my-1">
                    <p className="m-0 p-0 account-detail-subtitle">Cutoff Point</p>
                    <p className="m-0 p-0 account-title-subcontent">
                        {selectedRow &&  selectedRow.Cutoff.toString()}
                    </p>
                 </div>
                 <div className="d-inline-flex w-100 my-1">
                    <p className="m-0 p-0 account-detail-subtitle">Allow Unregistered Targets</p>
                    <p className="m-0 p-0 account-title-subcontent">
                        {selectedRow && selectedRow.AllowUnassociatedTargets}
                    </p>
                 </div>
                </div>
            </div>

            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Tasks" && "inaktif"
              }`}
            >
              <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Window Task ID</th>
                      <th>Priority</th>
                      <th>Name</th>
                      <th>Task ARN</th>
                      <th>Type</th>
                      <th>Targets</th>
                      <th>Description</th>
                      <th>Max Concurrency</th>
                      <th>Max Errors</th>
                      <th>Service Role ARN</th>
                    </tr>
                  </thead>
                  <tbody>
                    { selectedRow && selectedRow.Tasks && 
                      selectedRow.Tasks.map( (item, idx) => (
                    <tr key={idx}>
                      <td>{item.WindowTaskId}</td>
                       <td>{item.Priority}</td>
                       <td>{item.Name}</td>
                       <td>{item.TaskArn}</td>
                       <td>{item.Type}</td>
                       <td>{item.Targets && item.Targets.length}</td>
                       <td>{item.Description}</td>
                       <td>{item.MaxConcurrency}</td>
                       <td>{item.MaxErrors}</td>
                       <td>{item.ServiceRoleArn}</td>
                     </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

         <DetailsGrid
              type={type}
              typeName="History"
              array={selectedRow.History}
              metadata={[
                { header: "Window Execution ID", key: "WindowExecutionId" },
                { header: "Status", key: "Status" },
                { header: "Status Detail", key: "StatusDetails" },
                { header: "Start Time", key: "StartTime" },
                { header: "End Time", key: "EndTime" }       
              ]}
            ></DetailsGrid> 

            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Targets" && "inaktif"
              }`}
            >
              <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Window Target ID</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Resource Type</th>
                      <th>Target Tag Key</th>
                      <th>Target Tag Values </th>
                    </tr>
                  </thead>
                  <tbody>
                    { selectedRow && selectedRow.Targets && 
                      selectedRow.Targets.map( (item, idx) => (
                    <tr key={idx}>
                      <td>{item.WindowTargetId }</td>
                       <td>{item.Name}</td>
                       <td>{item.Description}</td>
                       <td>{item.ResourceType}</td>
                       <td>{getTargetsKeyValues("Key",item.Targets)}</td>
                        <td>{getTargetsKeyValues("Values",item.Targets)}</td>
                     </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>


          <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>
            
            </div>
          </div>
        </div>
    );
  };

   const getTargetsKeyValues = (param, array) =>
   {
     var objArray = [];
        array && array.map((obj) => {
          objArray.push(obj[param]);
        });

      return objArray.join(",");
   }

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
     <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.INFRASTRUCTURE_COMPONENTS + "/" + projectId} >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Image Builder</p>       
      </Link> 

      <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.INFRASTRUCTURE_WORKSPACES + "/" + projectId}
      >
      <p className="m-0 p-0 ml-2 font-weight-bold">Workspaces</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
      </>
    );
  };

  
const MaintenanceWindows = () => {
    const columns = useMemo(
        () => [
          {
            Header: "Account ID",
            accessor: "AccountId",
          },
          {
            Header: "Region",
            accessor: "Region",
          },
          {
            Header: "Window ID",
            accessor: "WindowId",
            id: "Window ID"
          },
          {
            Header: "Name",
            accessor: "Name",
            id: "Name"
          },
          {
            Header: "State",
            accessor: (d) => getBooleanValue(d, "Enabled"),
            id: "State"
          },
          {
            Header: "Next Execution Time",
            accessor: "NextExecutionTime",
            id: "Next Execution Time"
          },
        ],
        []
      );

    return Dashboard({
        domain: "infrastructure",
        title: "Infrastructure",
        dashboardName,
        columns,
        Details,
        Navigations,
        configureRoute: ROUTES.INFRASTRUCTURE_PATCHMANAGEMENT,
        designRoute: ROUTES.DESIGN_INFRASTRUCTURE,
        SubMenu: SubMenu,
      });
};

export default MaintenanceWindows;