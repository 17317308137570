import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "./DetailsTags";
import { getArrayValue } from "./DashboardHelper";
import DetailsGrid from "./DetailsGrid";

//const projectId = "google_1";
const dashboardName = "VPCs";

const SubMenu = () => {
  const { projectId } = useParams();

  return (
    <>
      <h3>VPCs</h3>
      <div className="otherTableTitles ">
        <h3 className="font-15 activeTable">VPCs</h3>
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPC_SUBNETS + "/" + projectId}>
            Subnets
          </Link>
        </h3>
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPC_ROUTE_TABLES + "/" + projectId}>
            Route Tables
          </Link>
        </h3>
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPC_DHCP + "/" + projectId}>
            DHCP
          </Link>
        </h3>
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.VPC_END_POINTS + "/" + projectId}>
            Endpoints
          </Link>
        </h3>
      </div>
    </>
  );
};

const Details = ({ close, selectedRow }) => {
  const [type, setType] = useState("Details");

  return (
    <div className="justify-content-center align-items-end account-detail">
      <div className="w-100 s-flex flex-column account-detail-area">
        <button className="exitAccount" onClick={() => close()}>
          <img src="../images/close.svg" />
        </button>
        <div className="">
          <ul className="account-detail-link m-0 p-0 d-inline-flex">
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Details" && "my_active"
              }`}
              onClick={() => setType("Details")}
            >
              Details
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "CIDR Block Association Set" && "my_active"
              }`}
              onClick={() => setType("CIDR Block Association Set")}
            >
              CIDR Block Association Set
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Route Tables" && "my_active"
              }`}
              onClick={() => setType("Route Tables")}
            >
              Route Tables
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Flow Logs" && "my_active"
              }`}
              onClick={() => setType("Flow Logs")}
            >
              Flow Logs
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Tags" && "my_active"
              }`}
              onClick={() => setType("Tags")}
            >
              Tags
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Network ACLs" && "my_active"
              }`}
              onClick={() => setType("Network ACLs")}
            >
              Network ACLs
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 account-detail-container-area">
          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Details" && "inaktif"
            }`}
          >
            <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.AccountId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Region</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Region}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">VPC Name</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {getArrayValue(selectedRow.Tags, -1, "Key", "Name", "Value")}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">VPC Id</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.VpcId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Owner Account Id
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.OwnerId}
                </p>
              </div>

              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">CIDR Block</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.CidrBlock}
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Is Default VPC
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.hasOwnProperty("IsDefault") &&
                    selectedRow.IsDefault.toString()}
                </p>
              </div>

              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  DHCP Options Id
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.DhcpOptionsId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">State</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.State}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Instance Tenancy
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.InstanceTenancy}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  DNS Resolution
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.hasOwnProperty("DNSresolution") &&
                    selectedRow.DNSresolution.toString()}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">DNS Hostname</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.hasOwnProperty("DNShostname") &&
                    selectedRow.DNShostname.toString()}
                </p>
              </div>
            </div>
          </div>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "CIDR Block Association Set" && "inaktif"
            }`}
          >
            <div className="d-flex flex-column">
              <table className="details-table">
                <thead>
                  <tr>
                    <th className="font-15">IPv4 CIDR Blocks</th>
                  </tr>
                  <tr>
                    <th>CIDR Block</th>
                    <th>Association Id</th>
                    <th>CIDR Block State</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedRow.CidrBlockAssociationSet &&
                    selectedRow.CidrBlockAssociationSet.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.CidrBlock}</td>
                        <td>{item.AssociationId}</td>
                        <td>
                          {item.CidrBlockState && item.CidrBlockState.State}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <table className="details-table mt-2">
                <thead>
                  <tr>
                    <th className="font-15">IPv6 CIDR Blocks</th>
                  </tr>
                  <tr>
                    <th>CIDR Block</th>
                    <th>IPv6 Pool</th>
                    <th>CIDR Block State</th>
                    <th>Status</th>
                    <th>Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedRow.Ipv6CidrBlockAssociationSet &&
                    selectedRow.Ipv6CidrBlockAssociationSet.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.Ipv6CidrBlock}</td>
                        <td>{item.Ipv6Pool}</td>
                        <td>
                          {item.Ipv6CidrBlockState &&
                            item.Ipv6CidrBlockState.State}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <DetailsGrid
            type={type}
            typeName="Route Tables"
            array={selectedRow.RouteTable}
            metadata={[
              { header: "Route Table Id", key: "RouteTableId" },
              { header: "Route Table Name", key: "RouteTableName" },
            ]}
          ></DetailsGrid>

          <DetailsGrid
            type={type}
            typeName="Flow Logs"
            array={selectedRow.FlowLogs}
            metadata={[
              { header: "Flow Log Id", key: "FlowLogId" },
              { header: "Filter", key: "TrafficType" },
              { header: "Destination Type", key: "LogDestinationType" },
              { header: "Destination Name", key: "LogGroupName" },
              { header: "IAM Role ARN", key: "DeliverLogsPermissionArn" },
              {
                header: "Maximum Aggregation Interval",
                key: "MaxAggregationInterval",
              },
              { header: "Creation Time", key: "CreationTime" },
              { header: "Status", key: "FlowLogStatus" },
              { header: "Log Line Format", key: "LogFormat" },
            ]}
          ></DetailsGrid>

          <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Network ACLs" && "inaktif"
            }`}
          >
            <div className="col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Network ACL Id
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.NetworkACL &&
                    selectedRow.NetworkACL.map((n) => n.NetworkAclId).join(",")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Navigations = () => {
  const { projectId } = useParams();
  return (
    <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.TGW + "/" + projectId}
    >
      <p className="m-0 p-0 mr-2 font-weight-bold">Transist Gateway</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
  );
};

const VPC = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "VPC Name",
        accessor: (d) => getArrayValue(d.Tags, -1, "Key", "Name", "Value"),
      },
      {
        Header: "VpcId",
        accessor: "VpcId",
      },
      {
        Header: "Owner Account Id",
        accessor: "OwnerId",
      },
      {
        Header: "CIDR Block",
        accessor: "CidrBlock",
      },
      {
        Header: "DHCP Options Id",
        accessor: "DhcpOptionsId",
      },
      {
        Header: "State",
        accessor: "State",
      },
      {
        Header: "Instance Tenancy",
        accessor: "InstanceTenancy",
      },
      {
        Header: "Is Default VPC",
        accessor: (value) =>
          value.hasOwnProperty("IsDefault") && value.IsDefault.toString(),
        id: "IsDefault",
      },
      {
        Header: "DNS Resolution",
        accessor: (value) =>
          value.hasOwnProperty("DNSresolution") &&
          value.DNSresolution.toString(),
        id: "DNSresolution",
      },
      {
        Header: "DNS Hostname",
        accessor: (value) =>
          value.hasOwnProperty("DNShostname") && value.DNShostname.toString(),
        id: "DNShostname",
      },
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "network",
    title: "Network",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_CENTERS,
    designRoute: ROUTES.DESIGN_NETWORK,
    SubMenu: SubMenu,
  });
};

export default VPC;
