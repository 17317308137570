import React, { useEffect, useState } from "react";
import update from "immutability-helper";

import useAwsAmplify from "../../hooks/useAwsAmplify";
import useSession from "../../hooks/useSession";
import { getError } from "../../helpers/errorHelper";

const lookupObj = {
  sourceProjectId: "Source Project",
  targetProjectId: "Target Project",
};

const ProjectCopyForm = ({ close, projects }) => {
  const { group, authUser, isSuperAdmin } = useSession();
  const awsAmplify = useAwsAmplify();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    sourceProjectId: {
      value: "",
      isValid: false,
    },
    targetProjectId: {
      value: "",
      isValid: false,
    },
    copyCode: false,
  });

  const [isDirty, setIsDirty] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    console.log(formData);

    const errorMsgs = [];

    for (const prop in formData) {
      if (formData[prop].hasOwnProperty("isValid") && !formData[prop].isValid) {
        if (formData[prop].errorMsg) {
          errorMsgs.push(formData[prop].errorMsg);
        } else {
          errorMsgs.push(`${lookupObj[prop]} must be provided`);

          setFormData((state) =>
            update(state, {
              [prop]: {
                errorMsg: {
                  $set: errorMsg
                    ? `${lookupObj[prop]} ${errorMsg}`
                    : `${lookupObj[prop]} must be provided`,
                },
              },
            })
          );
        }
      }
    }

    if (errorMsgs.length > 0) {
      setErrorMsg(errorMsgs);
      return;
    }

    try {
      setIsLoading(true);

      const result = await awsAmplify.copyProject(
        formData.sourceProjectId.value, 
        formData.targetProjectId.value,
        formData.copyCode
      );
      console.log(result);

      setIsLoading(false);
      setIsDirty(false);

      close(
        false,
        "copy",
        result.data.copyProject > 0
          ? `Successfully cleared the Target Project data and copied Source Project data to the Target Project`
          : `Successfuly copied Source Project data to the Target Project`
      );
    } catch (err) {
      setIsLoading(false);

      const errorMsg = getError(err);
      if (errorMsg === "Project doesn't exist") {
        close(true, "create", null, errorMsg);
      } else {
        setErrorMsg(errorMsg);
      }
    }
  };

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong>
              {Array.isArray(errorMsg)
                ? errorMsg.map((em, idx) => (
                    <>
                      <span key={idx}>{em}</span>
                    </>
                  ))
                : errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="justify-content-center align-items-center popupArea copy-project">
        <div className="auth-main">
          <form className="auth-form" onSubmit={onSubmit}>
            <button
              type="button"
              className="exit btn-animation"
              onClick={() => close(false, "copy")}
            >
              Exit
            </button>
            <div className="title">Copy Project</div>
            <div className="auth-form-content d-flex flex-column justify-content-center">
              <div className="halfBox d-flex flex-column mt-4">
                <div className="columnRow w-100 d-flex flex-column">
                  <div className="titleOfContent my-1">Source Project Name</div>
                  <select
                    className="selectInput my-1"
                    value={formData.sourceProjectId.value}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (
                        value !== "" &&
                        value !== formData.targetProjectId.value
                      ) {
                        setFormData((state) =>
                          update(state, {
                            sourceProjectId: { value: { $set: value } },
                          })
                        );
                      }
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;
                      if (value !== "") {
                        setFormData((state) =>
                          update(state, {
                            sourceProjectId: { isValid: { $set: true } },
                          })
                        );
                      }
                    }}
                  >
                    <option value="">Select</option>
                    {projects.map((p, idx) => (
                      <option key={idx} value={p.ProjectID}>
                        {isSuperAdmin
                          ? `${p.ClientName}: ${p.ProjectName}`
                          : p.ProjectName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="columnRow w-100 d-flex flex-column ">
                  <div className="titleOfContent my-1">Target Project Name</div>
                  <select
                    className="selectInput my-1"
                    value={formData.targetProjectId.value}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (
                        value !== "" &&
                        value !== formData.sourceProjectId.value
                      ) {
                        setFormData((state) =>
                          update(state, {
                            targetProjectId: { value: { $set: value } },
                          })
                        );
                      }
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;
                      if (value !== "") {
                        setFormData((state) =>
                          update(state, {
                            targetProjectId: { isValid: { $set: true } },
                          })
                        );
                      }
                    }}
                  >
                    <option value="">Select</option>
                    {projects.filter(p => {
                      if (formData.sourceProjectId.value) {
                        const sourceProject = projects.find(
                          (p) => p.ProjectID === formData.sourceProjectId.value
                        );

                        return (
                          p.ProjectID !== sourceProject.ProjectID &&
                          p.ProjectType === sourceProject.ProjectType
                        );
                      } else {
                        return true;
                      }
                    }).map((p, idx) => (
                      <option key={idx} value={p.ProjectID}>
                        {isSuperAdmin
                          ? `${p.ClientName}: ${p.ProjectName}`
                          : p.ProjectName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-line my-lg-0 my-1 d-inline-flex align-items-center">
                  <label className="switch mb-0">
                    <input
                      type="checkbox"
                      checked={formData.copyCode}
                      onChange={(e) => {
                        const value = e.target.checked;
                        setFormData((state) =>
                          update(state, { copyCode: { $set: value } })
                        );
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span className="switchLabel ">Copy Code</span>
                </div></div>
            </div>
            <div className="auth-form-footer">
              <div>
                <button className="confirmBtn btn btn-link btn-green btn-animation">
                  Copy
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {console.log(formData)}
    </>
  );
};

export default ProjectCopyForm;
