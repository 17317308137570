import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import * as ROUTES from "../constants/routes";
import useAwsAmplify from "../hooks/useAwsAmplify";

const schema = yup.object().shape({
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{6,99}$/,
      "password needs minimum 8 (upper, lower, special char and number)"
    )
    .required(),
});

const NewPasswordRequiredForm = ({
  tempAuthUser,
  setState,
  setAuthStateChanged,
  setError,
  setIsLoading,
  setTempAuthUser
}) => {
  const awsAmplify = useAwsAmplify();

  const [showPwd, setShowPwd] = useState(false);
  const { register, handleSubmit, formState, errors } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = async (formValues) => {
    setIsLoading(true);
    setError("");

    try {
      await awsAmplify.auth.completeNewPassword(
        tempAuthUser,
        formValues.password
      );

      setIsLoading(false);

      setAuthStateChanged("SIGNINSUCCESSFUL");
    } catch (err) {
      setIsLoading(false);
      if (err.message) {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    const errorMsgs = [];

    if(errors){
      for(const field in errors){
        if(errors[field].message){
          errorMsgs.push(errors[field].message);
        }
      }
    }

    if (errorMsgs.length > 0) {
      setError(errorMsgs);
    }
  }, [errors.password]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="password" className={errors.password && "highlight"}>
          <img src="images/icons/lock.svg" alt="" />
          <input
            type={showPwd ? "text" : "password"}
            placeholder="Enter your new password"
            name="password"
            ref={register}
          />
          <img
            className="toggle-password"
            src="images/icons/eye.svg"
            alt=""
            onClick={() => setShowPwd(!showPwd)}
          />
        </label>

        <div className="forgot-password">
          <button type="button" onClick={() => setTempAuthUser(null)}>
            Back to Sign In
          </button>
        </div>

        <button type="submit" className="button">
          Change
        </button>
      </form>
    </>
  );
};

export default NewPasswordRequiredForm;
