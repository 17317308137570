import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "Backup Jobs";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
      <>
        <h3>Backup</h3>
        <div className="otherTableTitles ">
          <h3 className="font-15">  
          <Link className="text-color-gray" to={ROUTES.DATA_PROTECTION_BACKUP_POLICY + "/" + projectId}>  
          Policies
         </Link>       
          </h3>
          <h3 className="font-15">   
          <Link className="text-color-gray" to={ROUTES.DATA_PROTECTION_BACKUP_VAULTS + "/" + projectId}>  
          Vaults
         </Link>       
          </h3>
          <h3 className="font-15">    
          <Link className="text-color-gray" to={ROUTES.DATA_PROTECTION_BACKUP_PLAN + "/" + projectId}>  
             Plans
         </Link>                  
          </h3>
          <h3 className="font-15 activetable">
             Backup Jobs
          </h3>
          <h3 className="font-15">
            <Link className="text-color-gray" to={ROUTES.DATA_PROTECTION_BACKUP_COPY_JOBS + "/" + projectId}>
             Copy Jobs
            </Link>
          </h3>
        </div>
      </>
    );
  };


  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
             
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Region}
                  </p>
                </div>

                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Backup Job ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    
                  </p>
                </div>

                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Resource Type</p>
                  <p className="m-0 p-0 account-title-subcontent">
                     
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle"> Resource ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle"> Creation Time</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Status</p>
                  <p className="m-0 p-0 account-title-subcontent">
                   
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Percentage Done</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Completion Date</p>
                  <p className="m-0 p-0 account-title-subcontent">
                   
                  </p>
                </div>
              
              </div>
              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Start By
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  
                  </p>
                </div>
              
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Vault Name
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  
                  </p>
                </div>
              
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Backup Plan Id
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  
                  </p>
                </div>
              
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Backup Plan ARN
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  
                  </p>
                </div>
              
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Backup Plan Version
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  
                  </p>
                </div>
              
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Backup Rule Id
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  
                  </p>
                </div>
              
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Backup Vault ARN
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  
                  </p>
                </div>
              
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Recovery Point ARN
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  
                  </p>
                </div>
              
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  IAM Role ARN
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  
                  </p>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
    <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.DATA_PROTECTION_ACM + "/" + projectId}
    >
      <p className="m-0 p-0 mr-2 font-weight-bold">ACM Certificates</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
    </>
    );
  };
  
  
  
const BackupJobs = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "Backup Job ID",
        accessor: "",
        id: "Backup Job ID"
      },
      {
        Header: "Resource Type",
        accessor: "",
        id: "Resource Type"
      },
      {
        Header: "Resource ID",
        accessor: "",
        id: "Resource ID"
      },
      {
        Header: "Creation Time",
        accessor: "",
        id: "Creation Time"
      },
      {
        Header: "Status",
        accessor: "",
        id: "Status"
      },
      {
        Header: "Percentage Done",
        accessor: "",
        id: "Percentage Done"
      },
    ],
    []
  );

  return Dashboard({
    domain: "dataprotection",
    title: "Backup",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_PROTECTION_BACKUP_ARCHIVAL,
    designRoute: ROUTES.DESIGN_DATA_PROTECTION,
    SubMenu: SubMenu,
  });
};

export default BackupJobs;