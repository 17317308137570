import React, { useState, useEffect, useMemo } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Helmet } from "react-helmet-async";

import TopMenu from "../TopMenu";
import useAwsAmplify from "../../hooks/useAwsAmplify";
import useHideLeftMenu from "../../hooks/useHideLeftMenu";
import { Link, useHistory } from "react-router-dom";

import * as ROUTES from "../../constants/routes";
import ProjectForm from "./ProjectForm";
import ProjectRemoveForm from "./ProjectRemoveForm";
import useSession from "../../hooks/useSession";
import { getError } from "../../helpers/errorHelper";
import ProjectCopyForm from "./ProjectCopyForm";

const AdminProjects = () => {
  const awsAmplify = useAwsAmplify();
  const { authUser, isUser, isClientAdmin, isSuperAdmin, group } = useSession();

  const history = useHistory();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isCreateProject, setIsCreateProject] = useState(false);
  const [isRemoveProject, setIsRemoveProject] = useState(false);
  const [isCopyProject, setIsCopyProject] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const [createdByUser, setCreatedByUser] = useState(null);
  const [info, setInfo] = useState("");

  const [reload, setReload] = useState(false);

  const [copyProjects, setCopyProjects] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "Client Name",
        accessor: "ClientName",
      },
      {
        Header: "Project Name",
        accessor: "ProjectName",
      },
      {
        Header: "Project Type",
        accessor: "ProjectType",
      },
      {
        Header: "Status",
        accessor: "ProjectStatus",
      },
      {
        Header: "Source Code Bucket",
        accessor: "SourceCodeBucket",
      },
      {
        Header: "Launch Access",
        accessor: "EnableLaunchAccess",
        Cell: ({ value, row }) => {
          const [access, setAccess] = useState(value);

          const projectId = row.original.ProjectID;
          return (
            <div className="btn-group btn-group-toggle btn-group-radio mw-100 ">
              <label
                className={`btn btn-link btn-linkYes ${
                  access === "yes" && "active"
                }`}
              >
                <input
                  type="radio"
                  name="options"
                  checked={access === "yes"}
                  onChange={() => {
                    setAccess("yes");
                    updateAccess(projectId, "EnableLaunchAccess", "yes");
                    //fetchData();
                  }}
                />{" "}
                YES
              </label>
              <label
                className={`btn btn-link btn-linkNo ${
                  access === "no" && "active"
                }`}
              >
                <input
                  type="radio"
                  name="options"
                  checked={access === "no"}
                  onChange={() => {
                    setAccess("no");
                    updateAccess(projectId, "EnableLaunchAccess", "no");
                    //fetchData();
                  }}
                />{" "}
                NO
              </label>
            </div>
          );
        },
      },
      {
        Header: "Locked",
        accessor: "Locked",
      },
      {
        Header: "Assigned To",
        accessor: "AssignedUserIDAndEmailIDs",
        Cell: ({ value, row }) => {
          const projectId = row.original.ProjectID;

          return (
            <div className="d-flex flex-column">
              {value &&
                value.map((user, idxUser) => (
                  <div
                    key={idxUser}
                    className="w-100 d-flex justify-content-between"
                  >
                    {user.EmailID}{" "}
                    <button
                      type="button"
                      onClick={() =>
                        unassignUserFromProject(projectId, user.UserID)
                      }
                    >
                      <img src="../images/close.png" />
                    </button>
                  </div>
                ))}
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          const projectId = row.original.ProjectID;

          return (
            <>
              <button
                className="btnDelete"
                onClick={() => deleteProject(row.original)}
              >
                Delete
              </button>
              <button
                className="btnUpdate"
                onClick={() => updateProject(projectId)}
              >
                Update
              </button>
            </>
          );
        },
      },
      {
        Header: "Created On",
        accessor: "CreatedOn",
      },
      {
        Header: "Created By",
        accessor: "CreatedBy",
      },
      {
        Header: "Updated On",
        accessor: "UpdatedOn",
      },
      {
        Header: "Updated By",
        accessor: "UpdatedBy",
      },
    ],
    []
  );

  const {
    rows,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        globalFilter: "",
        pageIndex: 0,
        pageSize: 10,
        sortBy: [{ id: "CreatedOn", desc: false }],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const fetchData = async () => {
    try {
      setData([]);

      setErrorMsg(null);
      setIsLoading(true);

      const results = await awsAmplify.getProjects();
      setIsLoading(false);

      console.log(results);

      if (results.data && results.data.getProjects) {
        setData(results.data.getProjects);
        setSortBy([{ id: "CreatedOn", desc: true }]);
      }
    } catch (err) {
      setIsLoading(false);

      getError(err, setErrorMsg);
    }
  };

  const closeHandler = async (load, action, info, error) => {
    if (action === "create") {
      setIsCreateProject(false);
    } else if (action === "remove") {
      setIsRemoveProject(false);
    } else if (action === "copy") {
    setIsCopyProject(false);
  }
    if (load) {
      setReload(true);
      await fetchData();
    }

    if (info) {
      setInfo(info);
      setTimeout(() => setInfo(""), 2000);
    } else if (error) {
      setErrorMsg(error);
    }
  };

  const deleteProject = async (project) => {
    try {
      setErrorMsg(null);
      setIsLoading(true);

      if (project.CreatedBy) {
        const results = await awsAmplify.getUserByEmailID(project.CreatedBy);

        setIsLoading(false);

        console.log(results);

        if (results.data && results.data.getUserByEmailID) {
          console.log(results.data.getUserByEmailID);

          setCreatedByUser(results.data.getUserByEmailID);
        }
      }

      setReload(false);
      setCurrentProject(project);
      setIsRemoveProject(true);
    } catch (err) {
      setIsLoading(false);

      getError(err, setErrorMsg);
    }
  };

  const updateAccess = async (projectId, field, value) => {
    try {
      setErrorMsg(null);
      setIsLoading(true);

      const results = await awsAmplify.updateProjectAccess(
        projectId,
        field,
        value
      );
      setIsLoading(false);

      console.log(results);
    } catch (err) {
      setIsLoading(false);

      getError(err, setErrorMsg);
    }
  };

  const unassignUserFromProject = async (projectId, userId) => {
    try {
      setErrorMsg(null);
      setIsLoading(true);

      const results = await awsAmplify.unassignUserFromProject(
        projectId,
        userId
      );

      setIsLoading(false);

      console.log(results);

      fetchData();
    } catch (err) {
      setIsLoading(false);

      getError(err, setErrorMsg);
    }
  };

  const updateProject = async (projectId) => {
    try {
      setErrorMsg(null);
      setIsLoading(true);

      const results = await awsAmplify.getProjectByID(projectId);
      setIsLoading(false);

      console.log(results);

      if (results.data && results.data.getProjectByID) {
        console.log(results.data.getProjectByID);

        const project = results.data.getProjectByID.project,
          domainUsers = results.data.getProjectByID.domainUsers;

        project.DomainUsers = domainUsers;

        console.log(project);

        setCurrentProject(project);

        setIsCreateProject(true);
      }
    } catch (err) {
      setIsLoading(false);

      const errorMsg = getError(err, setErrorMsg);
      if (errorMsg === "Project doesn't exist") {
        setTimeout(() => {
          fetchData();
        }, 2000);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openCopyProject = async() => {
    try {
      setCopyProjects([]);

      setErrorMsg(null);
      setIsLoading(true);

      const results = await awsAmplify.getCopyProjects();
      setIsLoading(false);

      console.log(results);

      if (results.data && results.data.getProjects) {
        setCopyProjects(results.data.getProjects.sort((p1, p2) => {
          if (p1.ClientName > p2.ClientName) return 1;
          if (p1.ClientName < p2.ClientName) return -1;
  
          if (p1.ProjectName > p2.ProjectName) return 1;
          if (p1.ProjectName < p2.ProjectName) return -1;
        }));
        setIsCopyProject(true);
      }
    } catch (err) {
      setIsLoading(false);

      getError(err, setErrorMsg);
    }
  };

  useHideLeftMenu();

  return (
    <>
      <Helmet>
        <body className="tableContaining superprojects" />
      </Helmet>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      {info && (
        <div className="alert-area">
          <div className="greenalert">
            <div className="messageText">{info}</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Admin</div>
      {isCreateProject && (
        <ProjectForm
          close={closeHandler}
          project={currentProject}
        ></ProjectForm>
      )}
      {isRemoveProject && (
        <ProjectRemoveForm
          close={closeHandler}
          project={currentProject}
          createdByUser={createdByUser}
        ></ProjectRemoveForm>
      )}
      {isCopyProject && (
        <ProjectCopyForm
          close={closeHandler}
          projects={copyProjects}
        ></ProjectCopyForm>
      )}
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreen h-100 d-flex flex-column">
            <TopMenu currentRouteIsAdmin={true} reload={reload}></TopMenu>
            <div className="tableArea accountsTableArea accountsTitledTable container-fluid p-0 d-flex flex-column">
              <div>
                <div className="topInfoNav justify-content-between align-items-start">
                  <ul className=" d-inline-flex leftNav">
                    <li className="px-xl-5 py-xl-4 px-lg-5 py-lg-1 px-4 py-1">
                      <a className="px-xl-4 px-lg-0">Admin</a>
                    </li>
                    <li className="px-xl-5 py-xl-4 px-lg-5 py-lg-1 px-4 py-1">
                      <button
                        className="d-inline-flex add-client-link"
                        onClick={() => {
                          setReload(false);
                          setCurrentProject(null);
                          setIsCreateProject(true);
                        }}
                      >
                        <div>
                          <img src="../images/Layer%2020.svg" />
                        </div>
                        <p className="ml-2 mr-0 p-0">Create Project</p>
                      </button>
                    </li>
                    <li className="copyProject px-xl-5 py-xl-3 py-lg-1 px-4 py-1">
                      <a className="d-inline-flex copy-project-link" href="#">
                        <div>
                          <img src="../images/portable-document-format.svg" />
                        </div>
                        <p className="ml-2 mr-0 p-0" onClick={openCopyProject}>Copy Project</p>
                      </a>
                    </li>
                    <div className="view-logs-icon-area">
                      <a href="#" className="btn-animation mr-1 view-logs-icon">
                        <img src="../images/viewlogs.png" />
                        <span className="d-flex align-items-center" onClick={() => history.push(ROUTES.ADMIN_LOGS)}>
                          View Logs
                        </span>
                      </a>
                    </div>
                  </ul>
                  <ul className="m-0 py-0 rightNav d-inline-flex px-0">
                    {isSuperAdmin && (
                      <li className=" mx-xl-4 mx-lg-1 mx-0 px-xl-4 py-xl-3 px-lg-3 py-lg-2 px-2 py-1">
                        <Link to={ROUTES.ADMIN_CLIENTS}>CLIENTS</Link>
                      </li>
                    )}
                    <li className=" mx-xl-4 mx-lg-1 mx-0 px-xl-4 py-xl-3 px-lg-3 py-lg-2 px-2 py-1">
                      <Link to={ROUTES.ADMIN_USERS}>USERS</Link>
                    </li>
                    <li className="mx-xl-4 mx-lg-1 mx-0 px-xl-4 py-xl-3 px-lg-3 py-lg-2 px-2 py-1">
                      <span className="active">PROJECTS</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mainArea fitDeviceHeight network-aws-environment flex-column px-md-1 ">
                <div>
                  <div
                    id="datatable_wrapper"
                    className="dataTables_wrapper no-footer h-100"
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-7">
                        <div
                          className="dataTables_length bs-select"
                          id="datatable_length"
                        >
                          <h3>Projects</h3>

                          <label>
                            Show
                            <select
                              name="datatable_length"
                              aria-controls="datatable"
                              className="custom-select custom-select-sm form-control form-control-sm"
                              value={pageSize}
                              onChange={(e) => {
                                setPageSize(Number(e.target.value));
                              }}
                            >
                              {[10, 50, 100, 500].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                  {pageSize}
                                </option>
                              ))}
                            </select>{" "}
                            entries
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-5">
                        <div
                          id="datatable_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            <div className="d-flex align-items-center justify-content-center">
                              <img
                                className="searchBar"
                                src="../images/search.svg"
                                alt=":)"
                              />
                            </div>
                            <input
                              type="search"
                              className="form-control form-control-sm"
                              placeholder="Filter by keyword"
                              aria-controls="datatable"
                              value={globalFilter || ""}
                              onChange={(e) => {
                                setGlobalFilter(e.target.value);
                              }}
                            />
                          </label>
                          <ul className="pagination">
                            <li
                              className="paginate_button page-item previous"
                              id="datatable_previous"
                            >
                              <button
                                className="page-link"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                              >
                                <img src="../images/ribbon-design.svg" />{" "}
                              </button>
                            </li>
                            <li className="pageInfo">
                              <span className="currentPage ">
                                {pageIndex + 1}
                              </span>
                              <span className="totalPage">
                                /{pageOptions.length}
                              </span>
                            </li>
                            <li
                              className="paginate_button page-item next"
                              id="datatable_next"
                            >
                              <button
                                className="page-link"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                              >
                                <img src="../images/ribbon-designRight.svg" />
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 h-100">
                        {data.length === 0 ? (
                          <span>No projects to display</span>
                        ) : (
                          <table
                            id="datatable"
                            className="w-100 h-100"
                            {...getTableProps()}
                          >
                            <thead>
                              {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                  {headerGroup.headers.map((column) => (
                                    <th
                                      className={
                                        column.isSorted
                                          ? column.isSortedDesc
                                            ? "descending"
                                            : "ascending"
                                          : ""
                                      }
                                      {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                      )}
                                    >
                                      <span {...column.getSortByToggleProps()}>
                                        {column.render("Header")}
                                      </span>
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </thead>
                            <tbody
                              {...getTableBodyProps()}
                              style={{ overflowY: "scroll" }}
                            >
                              {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                      return (
                                        <td {...cell.getCellProps()}>
                                          {cell.render("Cell")}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea px-md-3 px-0">
                  <div
                    className="dataTables_info"
                    id="datatable_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing {pageIndex * pageSize + 1} to{" "}
                    {pageIndex * pageSize + pageSize > rows.length
                      ? rows.length
                      : pageIndex * pageSize + pageSize}{" "}
                    of {rows.length} entries
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminProjects;
