import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import {
  validateAccountEmailId,
  validateAccountName,
  validateDomainName,
  validateIPAddress,
  validatePassword,
} from "../../../../helpers/validator";
import RadioGroup from "../../RadioGroup";
import useServiceAccounts from "../../../../hooks/useServiceAccounts";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import useRegions from "../../../../hooks/useRegions";
import usePrimaryRegion from "../../../../hooks/usePrimaryRegion";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/infrastructure/adintegration";
// const SSO_PREFIX = "/aws/iam/awsssointegration/awsssodirectory";
const SSO_PREFIX = "/aws/iam/sso/awssso/deploymentmodel";

const getInitialFormData = (data, sso) => {
  const initialFormData = {
    sso: sso,
    deployadintegration: {
      value: data[`${PREFIX}/deployadintegration`] || "yes",
    },
    awsaccounttohostadintegration: {
      value: data[`${PREFIX}/awsaccounttohostadintegration`] || "",
    },
    sharedirectorywithorganization: {
      value:
        data[
          `${PREFIX}/awsmanagedmicrosoftad/sharedirectorywithorganization`
        ] ||
        data[
          `${PREFIX}/awsmanagedadwithtrustrelationship/sharedirectorywithorganization`
        ] ||
        "no",
    },
    addeploymentoption: {
      value:
        data[`${PREFIX}/addeploymentoption`] ||
        (sso === "deployawsmanagedmsad"
          ? "awsmanagedmicrosoftad"
          : "adconnector"),
    },
    directorydnsdomainname: {
      value:
        data[`${PREFIX}/adconnector/directorydnsdomainname`] ||
        data[`${PREFIX}/awsmanagedmicrosoftad/directorydnsdomainname`] ||
        data[
          `${PREFIX}/awsmanagedadwithtrustrelationship/directorydnsdomainname`
        ] ||
        "",
      isValid:
        data[`${PREFIX}/adconnector/directorydnsdomainname`] ||
        data[`${PREFIX}/awsmanagedmicrosoftad/directorydnsdomainname`] ||
        data[
          `${PREFIX}/awsmanagedadwithtrustrelationship/directorydnsdomainname`
        ]
          ? true
          : false,
    },
    directorydnsserveripaddresses: data[
      `${PREFIX}/adconnector/directorydnsserveripaddresses`
    ]
      ? data[`${PREFIX}/adconnector/directorydnsserveripaddresses`].split(",").map((ip) => {
          return { value: ip, isValid: true };
        })
      : [
          { value: "", isValid: false },
          { value: "", isValid: false },
        ],
    adconnectorserviceaccountusername: {
      value: data[`${PREFIX}/adconnector/serviceaccountusername`] || "",
      isValid: data[`${PREFIX}/adconnector/serviceaccountusername`]
        ? true
        : false,
    },
    adconnectorserviceaccountpassword: {
      value: data[`${PREFIX}/adconnector/serviceaccountpassword`] || "",
      isValid: data[`${PREFIX}/adconnector/serviceaccountpassword`]
        ? true
        : false,
    },
    trusttype: {
      value:
        data[`${PREFIX}/awsmanagedadwithtrustrelationship/trusttype`] || "",
    },
    existingornewremotedomainname: {
      value:
        data[
          `${PREFIX}/awsmanagedadwithtrustrelationship/existingornewremotedomainname`
        ] || "",
      isValid: data[
        `${PREFIX}/awsmanagedadwithtrustrelationship/existingornewremotedomainname`
      ]
        ? true
        : false,
    },
    trustpassword: {
      value:
        data[`${PREFIX}/awsmanagedadwithtrustrelationship/trustpassword`] || "",
      isValid: data[`${PREFIX}/awsmanagedadwithtrustrelationship/trustpassword`]
        ? true
        : false,
    },
    trustdirection: {
      value:
        data[`${PREFIX}/awsmanagedadwithtrustrelationship/trustdirection`] ||
        "",
    },
    selectiveauthentication: {
      value: data[
        `${PREFIX}/awsmanagedadwithtrustrelationship/selectiveauthentication`
      ]
        ? data[
            `${PREFIX}/awsmanagedadwithtrustrelationship/selectiveauthentication`
          ] === "Enabled"
          ? "yes"
          : "no"
        : "no",
    },
    conditionalforwarderipaddresses: data[
      `${PREFIX}/awsmanagedadwithtrustrelationship/conditionalforwarderipaddresses`
    ]
      ? data[
          `${PREFIX}/awsmanagedadwithtrustrelationship/conditionalforwarderipaddresses`
        ]
          .split(",")
          .map((ip) => {
            return { value: ip, isValid: true };
          })
      : [
          { value: "", isValid: false },
          { value: "", isValid: false },
        ],
    directoryedition: {
      value: data[`${PREFIX}/awsmanagedmicrosoftad/directoryedition`] || data[`${PREFIX}/awsmanagedadwithtrustrelationship/directoryedition`] || "",
    },
    directorydnsnetbiosname: {
      value: data[`${PREFIX}/awsmanagedmicrosoftad/directorydnsnetbiosname`]
        ? data[`${PREFIX}/awsmanagedmicrosoftad/directorydnsnetbiosname`] ===
          "none"
          ? ""
          : data[`${PREFIX}/awsmanagedmicrosoftad/directorydnsnetbiosname`]
        : data[
            `${PREFIX}/awsmanagedadwithtrustrelationship/directorydnsnetbiosname`
          ]
        ? data[
            `${PREFIX}/awsmanagedadwithtrustrelationship/directorydnsnetbiosname`
          ] === "none"
          ? ""
          : data[
              `${PREFIX}/awsmanagedadwithtrustrelationship/directorydnsnetbiosname`
            ]
        : "",
      isValid: data[`${PREFIX}/awsmanagedmicrosoftad/directorydnsnetbiosname`]
        ? data[`${PREFIX}/awsmanagedmicrosoftad/directorydnsnetbiosname`] ===
          "none"
          ? false
          : true
        : data[
            `${PREFIX}/awsmanagedadwithtrustrelationship/directorydnsnetbiosname`
          ]
        ? data[
            `${PREFIX}/awsmanagedadwithtrustrelationship/directorydnsnetbiosname`
          ] === "none"
          ? false
          : true
        : false,
    },
    adadminpassword: {
      value:
        data[`${PREFIX}/awsmanagedmicrosoftad/adadminpassword`] ||
        data[`${PREFIX}/awsmanagedadwithtrustrelationship/adadminpassword`] ||
        "",
      isValid:
        data[`${PREFIX}/awsmanagedmicrosoftad/adadminpassword`] ||
        data[`${PREFIX}/awsmanagedadwithtrustrelationship/adadminpassword`]
          ? true
          : false,
    },
    adconfirmadminpassword: {
      value:
        data[`${PREFIX}/awsmanagedmicrosoftad/adadminpassword`] ||
        data[`${PREFIX}/awsmanagedadwithtrustrelationship/adadminpassword`] ||
        "",
      isValid:
        data[`${PREFIX}/awsmanagedmicrosoftad/adadminpassword`] ||
        data[`${PREFIX}/awsmanagedadwithtrustrelationship/adadminpassword`]
          ? true
          : false,
    },
    directoryregionslist:
      data[`${PREFIX}/adconnector/adconnectoregionslist`] &&
      data[`${PREFIX}/adconnector/adconnectoregionslist`].length > 0
        ? data[`${PREFIX}/adconnector/adconnectoregionslist`].split(",")
        : data[`${PREFIX}/awsmanagedmicrosoftad/directoryregionslist`] &&
          data[`${PREFIX}/awsmanagedmicrosoftad/directoryregionslist`].length >
            0
        ? data[`${PREFIX}/awsmanagedmicrosoftad/directoryregionslist`].split(
            ","
          )
        : data[
            `${PREFIX}/awsmanagedadwithtrustrelationship/directoryregionslist`
          ] &&
          data[
            `${PREFIX}/awsmanagedadwithtrustrelationship/directoryregionslist`
          ].length > 0
        ? data[
            `${PREFIX}/awsmanagedadwithtrustrelationship/directoryregionslist`
          ].split(",")
        : [],

        awsmanagedmicrosoftad_windowsautojoindomain: { value : data[`${PREFIX}/awsmanagedmicrosoftad/windowsautojoindomain`] || "yes" },
        awsmanagedmicrosoftad_linuxautojoindomain: {value:  data[`${PREFIX}/awsmanagedmicrosoftad/linuxautojoindomain`] || "yes" },

        awsmanagedadwithtrustrelationship_windowsautojoindomain: {value: data[`${PREFIX}/awsmanagedadwithtrustrelationship/windowsautojoindomain`] || "yes" },
        awsmanagedadwithtrustrelationship_linuxautojoindomain: {value: data[`${PREFIX}/awsmanagedadwithtrustrelationship/linuxautojoindomain`] || "yes"},
  };

  return initialFormData;
};

const ADIntegration = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(() => getInitialFormData({}));
  
  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  const accounts = useServiceAccounts(projectId);
  const regions = useRegions(projectId);
  const primaryRegion = usePrimaryRegion(projectId);

  if(primaryRegion && !formData.directoryregionslist.includes(primaryRegion)){
    formData.directoryregionslist.push(primaryRegion);
  }

  const [showPwd, setShowPwd] = useState(false);
  const [showPwd1, setShowPwd1] = useState(false);
  const [showPwd2, setShowPwd2] = useState(false);
  const [showPwd3, setShowPwd3] = useState(false);
  const [showPwd4, setShowPwd4] = useState(false);
  const [showPwd5, setShowPwd5] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);

        setEmpty(result.data.empty);

        const ssoResult = await awsAmplify.loadProjectData(
          projectId,
          SSO_PREFIX
        );

        //setSso(ssoResult && ssoResult.data[SSO_PREFIX]);

        console.log(ssoResult.data && ssoResult.data[SSO_PREFIX]);

        setIsLoading(false);

        setFormData(
          convertBEToFE(
            result.data,
            ssoResult.data && ssoResult.data[SSO_PREFIX]
          )
        );
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data, sso) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data, sso);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deployadintegration`]: formData.deployadintegration.value,
    };

    if (formData.deployadintegration.value === "yes") {

      if (formData.awsaccounttohostadintegration.value !== "") {
        be[`${PREFIX}/awsaccounttohostadintegration`] =
          formData.awsaccounttohostadintegration.value;
      }
      
      be[`${PREFIX}/addeploymentoption`] = formData.addeploymentoption.value;      
      if (formData.addeploymentoption.value === "adconnector") {
        if (formData.directoryregionslist.length > 0) {
          be[
            `${PREFIX}/adconnector/adconnectoregionslist`
          ] = formData.directoryregionslist.join(",");
        }
  
        //be[`${PREFIX}/sharedirectorywithorganization`] = "no";

        if (formData.directorydnsdomainname.isValid) {
          be[`${PREFIX}/adconnector/directorydnsdomainname`] =
            formData.directorydnsdomainname.value;
        }

        if (
          formData.directorydnsserveripaddresses.filter((ip) => ip.isValid)
            .length > 0
        ) {
          be[
            `${PREFIX}/adconnector/directorydnsserveripaddresses`
          ] = formData.directorydnsserveripaddresses
            .filter((ip) => ip.isValid)
            .map((ip) => ip.value)
            .join(",");
        }

        if (formData.adconnectorserviceaccountusername.isValid) {
          be[`${PREFIX}/adconnector/serviceaccountusername`] =
            formData.adconnectorserviceaccountusername.value;
        }

        if (formData.adconnectorserviceaccountpassword.isValid) {
          be[`${PREFIX}/adconnector/serviceaccountpassword`] =
            formData.adconnectorserviceaccountpassword.value;
        }
      } else if (
        formData.addeploymentoption.value === "awsmanagedmicrosoftad"
      ) {
        if (formData.directoryregionslist.length > 0) {
          be[
            `${PREFIX}/awsmanagedmicrosoftad/directoryregionslist`
          ] = formData.directoryregionslist.join(",");
        }
  
        be[`${PREFIX}/awsmanagedmicrosoftad/sharedirectorywithorganization`] = "no";

        if (formData.directoryedition.value !== "") {
          be[`${PREFIX}/awsmanagedmicrosoftad/directoryedition`] = formData.directoryedition.value;

          if(formData.directoryedition.value === "Enterprise"){
            be[`${PREFIX}/awsmanagedmicrosoftad/sharedirectorywithorganization`] = formData.sharedirectorywithorganization.value;
          }
          else
          {
            be[`${PREFIX}/awsmanagedmicrosoftad/sharedirectorywithorganization`] = "no";
            
            if(primaryRegion){
              be[`${PREFIX}/awsmanagedmicrosoftad/directoryregionslist`] = primaryRegion;  
            }
          }
        }

        if (formData.directorydnsdomainname.isValid) {
          be[`${PREFIX}/awsmanagedmicrosoftad/directorydnsdomainname`] =
            formData.directorydnsdomainname.value;
        }

        if (formData.directorydnsnetbiosname.isValid) {
          be[`${PREFIX}/awsmanagedmicrosoftad/directorydnsnetbiosname`] =
            formData.directorydnsnetbiosname.value;
        }
        else{
          be[`${PREFIX}/awsmanagedmicrosoftad/directorydnsnetbiosname`] = "none";
        }

        if (
          formData.adadminpassword.isValid &&
          formData.adconfirmadminpassword.value ===
            formData.adadminpassword.value
        ) {
          be[`${PREFIX}/awsmanagedmicrosoftad/adadminpassword`] = formData.adadminpassword.value;
        }

        be[`${PREFIX}/awsmanagedmicrosoftad/windowsautojoindomain`] = formData.awsmanagedmicrosoftad_windowsautojoindomain.value || "yes";
        be[`${PREFIX}/awsmanagedmicrosoftad/linuxautojoindomain`] = formData.awsmanagedmicrosoftad_linuxautojoindomain.value || "yes";
       
      } else if (
        formData.addeploymentoption.value ===
        "awsmanagedadwithtrustrelationship"
      ) {
        if (formData.directoryregionslist.length > 0) {
          be[
            `${PREFIX}/awsmanagedadwithtrustrelationship/directoryregionslist`
          ] = formData.directoryregionslist.join(",");
        }
  
        be[`${PREFIX}/awsmanagedadwithtrustrelationship/sharedirectorywithorganization`] = "no";

        if (formData.directoryedition.value !== "") {
          be[`${PREFIX}/awsmanagedadwithtrustrelationship/directoryedition`] = formData.directoryedition.value;

          if(formData.directoryedition.value === "Enterprise"){
            be[`${PREFIX}/awsmanagedadwithtrustrelationship/sharedirectorywithorganization`] = formData.sharedirectorywithorganization.value;
          }
          else
          {
            be[`${PREFIX}/awsmanagedadwithtrustrelationship/sharedirectorywithorganization`] = "no";

            if(primaryRegion){
              be[`${PREFIX}/awsmanagedadwithtrustrelationship/directoryregionslist`] = primaryRegion;  
            }
          }
        }

        if (formData.directorydnsdomainname.isValid) {
          be[`${PREFIX}/awsmanagedadwithtrustrelationship/directorydnsdomainname`] =
            formData.directorydnsdomainname.value;
        }

        if (formData.directorydnsnetbiosname.isValid) {
          be[`${PREFIX}/awsmanagedadwithtrustrelationship/directorydnsnetbiosname`] =
            formData.directorydnsnetbiosname.value;
        }
        else{
          be[`${PREFIX}/awsmanagedadwithtrustrelationship/directorydnsnetbiosname`] = "none";
        }

        if (
          formData.adadminpassword.isValid &&
          formData.adconfirmadminpassword.value ===
            formData.adadminpassword.value
        ) {
          be[`${PREFIX}/awsmanagedadwithtrustrelationship/adadminpassword`] = formData.adadminpassword.value;
        }

        if (formData.trusttype.value !== "") {
          be[`${PREFIX}/awsmanagedadwithtrustrelationship/trusttype`] = formData.trusttype.value;
        }

        if (formData.existingornewremotedomainname.isValid) {
          be[`${PREFIX}/awsmanagedadwithtrustrelationship/existingornewremotedomainname`] =
            formData.existingornewremotedomainname.value;
        }

        if (formData.trustpassword.isValid) {
          be[`${PREFIX}/awsmanagedadwithtrustrelationship/trustpassword`] = formData.trustpassword.value;
        }

        if (formData.trustdirection.value !== "") {
          be[`${PREFIX}/awsmanagedadwithtrustrelationship/trustdirection`] = formData.trustdirection.value;
        }

        be[`${PREFIX}/awsmanagedadwithtrustrelationship/selectiveauthentication`] =
          formData.selectiveauthentication.value === "yes"
            ? "Enabled"
            : "Disabled";

        if (
          formData.conditionalforwarderipaddresses.filter((ip) => ip.isValid)
            .length > 0
        ) {
          be[
            `${PREFIX}/awsmanagedadwithtrustrelationship/conditionalforwarderipaddresses`
          ] = formData.conditionalforwarderipaddresses
            .filter((ip) => ip.isValid)
            .map((ip) => ip.value)
            .join(",");
        }

        be[`${PREFIX}/awsmanagedadwithtrustrelationship/windowsautojoindomain`] = formData.awsmanagedadwithtrustrelationship_windowsautojoindomain.value || "yes";
        be[`${PREFIX}/awsmanagedadwithtrustrelationship/linuxautojoindomain`] = formData.awsmanagedadwithtrustrelationship_linuxautojoindomain.value || "yes";
       
      }
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const onFieldChange = (value, key) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key]: { value: { $set: value } } })
    );
  };

  const onFieldBlur = (validateFunc, value, key) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          [key]: { isValid: { $set: errorMsg === undefined } },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, { [key]: { isValid: { $set: false } } })
      );
    }
  };

  const exists = (array, value) => {
    return formData[array].indexOf(value) !== -1;
  };

  const add = (array, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [array]: { $push: [value] } })
    );
  };

  const remove = (array, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
          [array]: { $splice: [[state[array].indexOf(value), 1]] },
      })
    );
  };

  const modifyArray = (array, value) => {
    if (exists(array, value)) {
      remove(array, value);
    } else {
      add(array, value);
    }
  };

  useHideLeftMenu();
  useUnload(isDirty);

  const RegionsList = () => (
    <div className="d-inline-flex align-items-center row">
      <span className="col-md-3 my-1 p-0">
        <span className="mb-0 font-12">
          {formData.addeploymentoption.value === "adconnector"
            ? "Directory Connector Regions"
            : "Directory Regions"}
        </span>
      </span>
      <div className="col-md-9">
        {regions.length === 0 && (
          <span className="warning">
            This depends on the AWS Regions informations in 'AWS Environment"
            section of 'Network' domain that is yet to be provided.
          </span>
        )}
        {regions.map((region, idxRegion) => (
          <div
            key={idxRegion}
            className={`one mr-2 bg-special-lightblue form-line w-md-250 py-2 my-1 d-inline-flex align-items-center ${
              region === primaryRegion ? "blueSwitch" : ""
            }`}
          >
            <label className="switch mb-0">
              {region === primaryRegion ? (
                <input type="checkbox" disabled checked />
              ) : (
                <input
                  type="checkbox"
                  checked={exists("directoryregionslist", region)}
                  onChange={() => modifyArray("directoryregionslist", region)}
                />
              )}

              <span className="slider round"></span>
            </label>
            <span className="switchLabel">{region}</span>
          </div>
        ))}
      </div>
    </div>
  );

  const ShareDirectory = () => {
    return (
      formData.directoryedition.value === "Enterprise" && (
        <div className="d-inline-flex align-items-center row form-line ">
          <div className="col-md-3 my-1 p-0">
            <span className="mb-0 font-12">Share Directory</span>
          </div>
          <div className="col-md-9">
            <label className="switch mb-0">
              <input
                type="checkbox"
                checked={
                  formData.sharedirectorywithorganization.value === "yes"
                }
                onChange={(e) => {
                  const value = e.target.checked ? "yes" : "no";
                  setIsDirty(true);

                  setFormData((state) =>
                    update(state, {
                      sharedirectorywithorganization: {
                        value: { $set: value },
                      },
                    })
                  );
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      )
    );
  };

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Infrastructure</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Infrastructure"
                isDirty={isDirty}
                dashboardRoute={ROUTES.INFRASTRUCTURE_DIRECTORIES}
                designRoute={ROUTES.DESIGN_INFRASTRUCTURE}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 p-0">
                <div className="row d-flex align-items-center">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3>Active Directory Integration</h3>
                  </div>
                  <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                        Deploy Active Directory Solution
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                        <RadioGroup
                          field="deployadintegration"
                          value={formData.deployadintegration.value}
                          onChange={updateRadio}
                          isLarge={true}
                        ></RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>
                {empty && (
                  <NoInitialData />
                )}
                {formData.deployadintegration.value === "yes" && (
                  <div className="show-hide-content">
                    <div className="d-flex flex-column  pb-3 ">
                      <div className="d-inline-flex align-items-center px-3 row">
                        <div className="col-md-3 my-1 p-0">
                          <span className="mb-0 font-12">
                            AWS Account to Host AD Integration
                          </span>
                        </div>
                        <div className="col-md-9 flex-wrap d-inline-flex">
                          <div className="mr-3 my-1">
                            <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                            {(accounts && (accounts.security
                              .concat(accounts.shared)).length === 0)  ?
                            (
                              <div className="col-md-12 flex-wrap d-inline-flex">
                              <span className="warning">
                              This section depends on the platform accounts information in
                              Account Framework section that is yet to be provided
                            </span>
                            </div>
                            ) : (
                              <>  
                              <select
                                className="form-control gray"
                                value={
                                  formData.awsaccounttohostadintegration.value
                                }
                                onChange={(e) =>
                                  onFieldChange(
                                    e.target.value,
                                    "awsaccounttohostadintegration"
                                  )
                                }
                              >
                                <option value="">Select</option>
                                {accounts.security
                                  .concat(accounts.shared)
                                  .map((item, idxItem) => (
                                    <option value={item} key={idxItem}>
                                      {item}
                                    </option>
                                  ))}
                              </select>
                              </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className=" col-12 d-inline-flex align-items-center selectionArea my-1 px-3 justify-content-between flex-wrap py-1">
                        <div className="font-weight-bold my-2 font-12">
                          AD Deployment Option
                        </div>
                        <div className="my-2">
                          <label className="container">
                            AD Connector for Existing AD
                            <input
                              type="radio"
                              name="addeploymentoption"
                              checked={
                                formData.addeploymentoption.value ===
                                "adconnector"
                              }
                              onChange={(e) => {
                                if (formData.sso === "deployawsmanagedmsad") {
                                } else {
                                  onFieldChange(
                                    "adconnector",
                                    "addeploymentoption"
                                  );
                                }
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="my-2">
                          <label className="container">
                            AWS Managed Microsoft AD
                            <input
                              type="radio"
                              name="addeploymentoption"
                              checked={
                                formData.addeploymentoption.value ===
                                "awsmanagedmicrosoftad"
                              }
                              onChange={(e) =>
                                onFieldChange(
                                  "awsmanagedmicrosoftad",
                                  "addeploymentoption"
                                )
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="my-2">
                          <label className="container">
                            AWS Managed AD with Trust Relationship
                            <input
                              type="radio"
                              name="addeploymentoption"
                              checked={
                                formData.addeploymentoption.value ===
                                "awsmanagedadwithtrustrelationship"
                              }
                              onChange={(e) =>
                                onFieldChange(
                                  "awsmanagedadwithtrustrelationship",
                                  "addeploymentoption"
                                )
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>

                      {formData.addeploymentoption.value === "adconnector" && (
                        <div className="d-flex flex-column mainContentArea  pl-3 pr-3 py-1 my-1">
                          <div className="d-inline-flex align-items-center row ">
                            <div className="col-md-3 p-0 my-1">
                              <span className="mb-0 font-12">
                                Directory DNS Domain Name
                              </span>
                            </div>
                            <div className="col-md-9  flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  <input
                                    type="text"
                                    placeholder="e.g. client.com"
                                    value={
                                      formData.directorydnsdomainname.value
                                    }
                                    className={`form-control bg-transparent ${
                                      formData.directorydnsdomainname.value !==
                                        "" &&
                                      formData.directorydnsdomainname
                                        .isValid === false
                                        ? "invalid"
                                        : ""
                                    }`}
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.value,
                                        "directorydnsdomainname"
                                      )
                                    }
                                    onBlur={(e) =>
                                      onFieldBlur(
                                        validateDomainName,
                                        e.target.value,
                                        "directorydnsdomainname"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-inline-flex align-items-center row ">
                            <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">
                                Directory DNS Server IP Addresses
                              </span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                              {formData.directorydnsserveripaddresses.map(
                                (item, idxItem) => (
                                  <div key={idxItem} className="mr-3 my-1">
                                    <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                      <button disabled={disabled}
                                        type="button"
                                        onClick={() => {
                                          if (
                                            formData
                                              .directorydnsserveripaddresses
                                              .length > 2
                                          ) {
                                            setIsDirty(true);
                                            setFormData((state) =>
                                              update(state, {
                                                directorydnsserveripaddresses: {
                                                  $splice: [[idxItem, 1]],
                                                },
                                              })
                                            );
                                          }
                                        }}
                                        className="closableInput"
                                      ></button>
                                      <input
                                        type="text"
                                        placeholder="192.168.1.50"
                                        value={item.value}
                                        className={`form-control bg-transparent ${
                                          item.value !== "" &&
                                          item.isValid === false
                                            ? "invalid"
                                            : ""
                                        }`}
                                        onChange={(e) => {
                                          const value = e.target.value;

                                          for (const [
                                            idxOtherItem,
                                            otherItem,
                                          ] of formData.directorydnsserveripaddresses.entries()) {
                                            if (idxItem !== idxOtherItem) {
                                              if (
                                                otherItem.isValid &&
                                                otherItem.value !== ""
                                              ) {
                                                if (
                                                  otherItem.value.toLowerCase() ===
                                                  value.toLowerCase()
                                                ) {
                                                  setErrorMsg(
                                                    "Directory DNS Server IP Addresses must be unique"
                                                  );
                                                  return false;
                                                }
                                              }
                                            }
                                          }

                                          setErrorMsg(null);
                                          setIsDirty(true);

                                          setFormData((state) =>
                                            update(state, {
                                              directorydnsserveripaddresses: {
                                                [idxItem]: {
                                                  value: { $set: value },
                                                },
                                              },
                                            })
                                          );
                                        }}
                                        onBlur={(e) => {
                                          const value = e.target.value;

                                          if (value !== "") {
                                            const errorMsg = validateIPAddress(
                                              value
                                            );

                                            setFormData((state) =>
                                              update(state, {
                                                directorydnsserveripaddresses: {
                                                  [idxItem]: {
                                                    isValid: {
                                                      $set:
                                                        errorMsg === undefined,
                                                    },
                                                  },
                                                },
                                              })
                                            );

                                            if (errorMsg) {
                                              setErrorMsg(errorMsg);
                                            }
                                          } else {
                                            setFormData((state) =>
                                              update(state, {
                                                directorydnsserveripaddresses: {
                                                  [idxItem]: {
                                                    isValid: { $set: false },
                                                  },
                                                },
                                              })
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                              <button disabled={disabled}
                                type="button"
                                onClick={() => {
                                  if (
                                    formData.directorydnsserveripaddresses
                                      .length < 6
                                  ) {
                                    setIsDirty(true);
                                    setFormData((state) =>
                                      update(state, {
                                        directorydnsserveripaddresses: {
                                          $push: [
                                            { value: "", isValid: false },
                                          ],
                                        },
                                      })
                                    );
                                  }
                                }}
                              >
                                <img src="../images/coloredPlus.svg" />
                              </button>
                            </div>
                          </div>
                          <div className="d-inline-flex align-items-center row ">
                            <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">
                                AD Connector Service Account Username
                              </span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={
                                      formData.adconnectorserviceaccountusername
                                        .value
                                    }
                                    className={`form-control bg-transparent ${
                                      formData.adconnectorserviceaccountusername
                                        .value !== "" &&
                                      formData.adconnectorserviceaccountusername
                                        .isValid === false
                                        ? "invalid"
                                        : ""
                                    }`}
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.value,
                                        "adconnectorserviceaccountusername"
                                      )
                                    }
                                    onBlur={(e) =>
                                      onFieldBlur(
                                        validateAccountName,
                                        e.target.value,
                                        "adconnectorserviceaccountusername"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">Password</span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                  <button disabled={disabled}
                                    type="button"
                                    onClick={() => setShowPwd(!showPwd)}
                                    className="showableInput"
                                  ></button>
                                  <input
                                    type={showPwd ? "text" : "password"}
                                    placeholder=""
                                    value={
                                      formData.adconnectorserviceaccountpassword
                                        .value
                                    }
                                    className={`form-control bg-transparent ${
                                      formData.adconnectorserviceaccountpassword
                                        .value !== "" &&
                                      formData.adconnectorserviceaccountpassword
                                        .isValid === false
                                        ? "invalid"
                                        : ""
                                    }`}
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.value,
                                        "adconnectorserviceaccountpassword"
                                      )
                                    }
                                    onBlur={(e) =>
                                      onFieldBlur(
                                        validatePassword,
                                        e.target.value,
                                        "adconnectorserviceaccountpassword"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <RegionsList></RegionsList>          
                        </div>
                      )}

                      {formData.addeploymentoption.value ===
                        "awsmanagedmicrosoftad" && (
                        <div className="d-flex flex-column mainContentArea  pl-3 pr-3 my-1 py-1 ">
                          <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">
                                Directory Edition
                              </span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  <select
                                    className="form-control gray"
                                    value={formData.directoryedition.value}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setIsDirty(true);

                                      setFormData((state) =>
                                        update(state, {
                                          directoryedition: {
                                            value: {
                                              $set: value,
                                            },
                                          },
                                        })
                                      );
                                    }}
                                  >
                                    <option value="">Select</option>
                                    <option value="Standard">Standard</option>
                                    <option value="Enterprise">
                                      Enterprise
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">
                                Directory DNS Domain Name
                              </span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  <input
                                    type="text"
                                    placeholder="E.g client.com"
                                    value={
                                      formData.directorydnsdomainname.value
                                    }
                                    className={`form-control bg-transparent ${
                                      formData.directorydnsdomainname.value !==
                                        "" &&
                                      formData.directorydnsdomainname
                                        .isValid === false
                                        ? "invalid"
                                        : ""
                                    }`}
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.value,
                                        "directorydnsdomainname"
                                      )
                                    }
                                    onBlur={(e) =>
                                      onFieldBlur(
                                        validateDomainName,
                                        e.target.value,
                                        "directorydnsdomainname"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">
                                Directory NetBIOS Name
                              </span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  <input
                                    type="text"
                                    className="form-control bg-transparent"
                                    placeholder=""
                                    value={
                                      formData.directorydnsnetbiosname.value
                                    }
                                    className={`form-control bg-transparent ${
                                      formData.directorydnsnetbiosname.value !==
                                        "" &&
                                      formData.directorydnsnetbiosname
                                        .isValid === false
                                        ? "invalid"
                                        : ""
                                    }`}
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.value,
                                        "directorydnsnetbiosname"
                                      )
                                    }
                                    onBlur={(e) =>
                                      onFieldBlur(
                                        validateAccountName,
                                        e.target.value,
                                        "directorydnsnetbiosname"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-inline-flex align-items-center row">
                            <div className="col-xl-3 my-1 col-md-3  p-0">
                              <span className="mb-0 font-12">
                                Admin Password
                              </span>
                            </div>
                            <div className="col-xl-3 col-lg-9  flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                  <button disabled={disabled}
                                    type="button"
                                    onClick={() => setShowPwd1(!showPwd1)}
                                    className="showableInput"
                                  ></button>
                                  <input
                                    type={showPwd1 ? "text" : "password"}
                                    placeholder=""
                                    value={formData.adadminpassword.value}
                                    className={`form-control bg-transparent ${
                                      formData.adadminpassword.value !== "" &&
                                      formData.adadminpassword.isValid === false
                                        ? "invalid"
                                        : ""
                                    }`}
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.value,
                                        "adadminpassword"
                                      )
                                    }
                                    onBlur={
                                      (e) => {
                                        const value = e.target.value;

                                        if (value !== "") {
                                          const errorMsg = validatePassword(
                                            value
                                          );

                                          setFormData((state) =>
                                            update(state, {
                                              adadminpassword: {
                                                isValid: {
                                                  $set: errorMsg === undefined,
                                                },
                                              },
                                            })
                                          );

                                          if (errorMsg) {
                                            setErrorMsg(errorMsg);
                                          } else {
                                            if (
                                              value !==
                                              formData.adconfirmadminpassword
                                                .value
                                            ) {
                                              setFormData((state) =>
                                                update(state, {
                                                  adadminpassword: {
                                                    isValid: {
                                                      $set: false,
                                                    },
                                                  },
                                                })
                                              );

                                              setErrorMsg("Admin password doesn't match")
                                            }
                                          }
                                        } else {
                                          setFormData((state) =>
                                            update(state, {
                                              adadminpassword: {
                                                isValid: { $set: false },
                                              },
                                            })
                                          );
                                        }
                                      }
                                      /* onFieldBlur(
                                        validatePassword,
                                        e.target.value,
                                        "adadminpassword"
                                      ) */
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-3 my-1 col-md-3  p-0 d-flex rightOfLineType1">
                              <span className="mb-0 font-12">
                                Confirm Password
                              </span>
                            </div>
                            <div className="col-xl-3 col-lg-9  flex-wrap d-inline-flex">
                              <div className="mr-3 my-1 ">
                                <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                  <button disabled={disabled}
                                    type="button"
                                    onClick={() => setShowPwd2(!showPwd2)}
                                    className="showableInput"
                                  ></button>
                                  <input
                                    type={showPwd2 ? "text" : "password"}
                                    className="form-control bg-transparent"
                                    placeholder=""
                                    value={
                                      formData.adconfirmadminpassword.value
                                    }
                                    /* className={`form-control bg-transparent ${
                                      formData.adconfirmadminpassword.value !==
                                        "" &&
                                      formData.adconfirmadminpassword
                                        .isValid === false
                                        ? "invalid"
                                        : ""
                                    }`} */
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.value,
                                        "adconfirmadminpassword"
                                      )
                                    }
                                    onBlur={(e) => {
                                      const value = e.target.value;

                                      if (
                                        value !== formData.adadminpassword.value
                                      ) {
                                        setFormData((state) =>
                                          update(state, {
                                            adadminpassword: {
                                              isValid: { $set: false },
                                            },
                                          })
                                        );

                                        setErrorMsg(
                                          "Admin password doesn't match"
                                        );
                                      } else {
                                        setFormData((state) =>
                                          update(state, {
                                            adadminpassword: {
                                              isValid: { $set: true },
                                            },
                                          })
                                        );

                                        setErrorMsg(null);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {formData.directoryedition.value === "Enterprise" && (
                            <RegionsList></RegionsList>
                          )}
                          <ShareDirectory></ShareDirectory>
                          <div className="formRow col-md-6 mt-5 py-2 row">
                          <div className="d-inline-flex my-2 justify-content-between">
                            <div className="one form-line  d-inline-flex align-items-center">
                              <label className=" switch mb-0">
                                <input
                                  type="checkbox"
                                  checked={
                                    formData.awsmanagedmicrosoftad_windowsautojoindomain.value ===
                                    "yes"
                                  }
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    setIsDirty(true);

                                    setFormData((state) =>
                                      update(state, {
                                        awsmanagedmicrosoftad_windowsautojoindomain: {
                                          value: {
                                            $set: checked ? "yes" : "no",
                                          },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span className="slider round"></span>
                              </label>
                              <span className="switchLabel">
                               Enable seamless domain join for windows EC2 instances
                              </span>
                            </div>
                          </div>
                          <div className="d-inline-flex my-2  align-items-center justify-content-between">
                            <div className="one form-line  d-inline-flex align-items-center">
                              <label className=" switch mb-0">
                                <input
                                  type="checkbox"
                                  checked={
                                    formData.awsmanagedmicrosoftad_linuxautojoindomain.value ===
                                    "yes"
                                  }
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    setIsDirty(true);

                                    setFormData((state) =>
                                      update(state, {
                                        awsmanagedmicrosoftad_linuxautojoindomain: {
                                          value: {
                                            $set: checked ? "yes" : "no",
                                          },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span className="slider round"></span>
                              </label>
                              <span className="switchLabel">
                               Enable seamless domain join for Linux EC2 instances
                              </span>
                            </div>
                          </div>
                        </div>
                        </div>
                      )}

                      {formData.addeploymentoption.value ===
                        "awsmanagedadwithtrustrelationship" && (
                        <div className="d-flex flex-column mainContentArea  pl-3 pr-3  py-1 my-1">
                          <div className="d-flex flex-column   ">
                            <div className="d-inline-flex align-items-center row ">
                              <div className="col-md-3 p-0 my-1">
                                <span className="mb-0 font-12">
                                  Directory Edition
                                </span>
                              </div>
                              <div className="col-md-9 flex-wrap d-inline-flex">
                                <div className="mr-3 my-1">
                                  <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                    <select
                                      className="form-control gray"
                                      value={formData.directoryedition.value}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        setIsDirty(true);

                                        setFormData((state) =>
                                          update(state, {
                                            directoryedition: {
                                              value: {
                                                $set: value,
                                              },
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      <option value="">Select</option>
                                      <option value="Standard">Standard</option>
                                      <option value="Enterprise">
                                        Enterprise
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-inline-flex align-items-center row">
                              <div className="col-md-3 my-1 p-0">
                                <span className="mb-0 font-12">
                                  Directory DNS Domain Name
                                </span>
                              </div>
                              <div className="col-md-9 flex-wrap d-inline-flex">
                                <div className="mr-3 my-1">
                                  <div className=" w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                    <input
                                      type="text"
                                      className="form-control bg-transparent"
                                      placeholder=""
                                      value={
                                        formData.directorydnsdomainname.value
                                      }
                                      className={`form-control bg-transparent ${
                                        formData.directorydnsdomainname
                                          .value !== "" &&
                                        formData.directorydnsdomainname
                                          .isValid === false
                                          ? "invalid"
                                          : ""
                                      }`}
                                      onChange={(e) =>
                                        onFieldChange(
                                          e.target.value,
                                          "directorydnsdomainname"
                                        )
                                      }
                                      onBlur={(e) =>
                                        onFieldBlur(
                                          validateDomainName,
                                          e.target.value,
                                          "directorydnsdomainname"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-inline-flex align-items-center row">
                              <div className="col-md-3 my-1 p-0">
                                <span className="mb-0 font-12">
                                  Directory NetBIOS Name
                                </span>
                              </div>
                              <div className="col-md-9 flex-wrap d-inline-flex">
                                <div className="mr-3 my-1">
                                  <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                    <input
                                      type="text"
                                      className=" form-control bg-transparent"
                                      placeholder=""
                                      value={
                                        formData.directorydnsnetbiosname.value
                                      }
                                      className={`form-control bg-transparent ${
                                        formData.directorydnsnetbiosname
                                          .value !== "" &&
                                        formData.directorydnsnetbiosname
                                          .isValid === false
                                          ? "invalid"
                                          : ""
                                      }`}
                                      onChange={(e) =>
                                        onFieldChange(
                                          e.target.value,
                                          "directorydnsnetbiosname"
                                        )
                                      }
                                      onBlur={(e) =>
                                        onFieldBlur(
                                          validateAccountName,
                                          e.target.value,
                                          "directorydnsnetbiosname"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-inline-flex align-items-center row">
                              <div className="my-1 col-md-3  p-0">
                                <span className="mb-0 font-12">
                                  Admin Password
                                </span>
                              </div>
                              <div className="col-md-9  flex-wrap d-inline-flex">
                                <div className="mr-3 my-1">
                                  <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                    <button disabled={disabled}
                                      type="button"
                                      onClick={() => setShowPwd3(!showPwd3)}
                                      className="showableInput"
                                    ></button>
                                    <input
                                      type={showPwd3 ? "text" : "password"}
                                      placeholder=""
                                      value={formData.adadminpassword.value}
                                      className={`form-control bg-transparent ${
                                        formData.adadminpassword.value !== "" &&
                                        formData.adadminpassword.isValid ===
                                          false
                                          ? "invalid"
                                          : ""
                                      }`}
                                      onChange={(e) =>
                                        onFieldChange(
                                          e.target.value,
                                          "adadminpassword"
                                        )
                                      }
                                      onBlur={
                                        (e) => {
                                          const value = e.target.value;
  
                                          if (value !== "") {
                                            const errorMsg = validatePassword(
                                              value
                                            );
  
                                            setFormData((state) =>
                                              update(state, {
                                                adadminpassword: {
                                                  isValid: {
                                                    $set: errorMsg === undefined,
                                                  },
                                                },
                                              })
                                            );
  
                                            if (errorMsg) {
                                              setErrorMsg(errorMsg);
                                            } else {
                                              if (
                                                value !==
                                                formData.adconfirmadminpassword
                                                  .value
                                              ) {
                                                setFormData((state) =>
                                                  update(state, {
                                                    adadminpassword: {
                                                      isValid: {
                                                        $set: false,
                                                      },
                                                    },
                                                  })
                                                );
  
                                                setErrorMsg("Admin password doesn't match")
                                              }
                                            }
                                          } else {
                                            setFormData((state) =>
                                              update(state, {
                                                adadminpassword: {
                                                  isValid: { $set: false },
                                                },
                                              })
                                            );
                                          }
                                        }
                                        /* onFieldBlur(
                                          validatePassword,
                                          e.target.value,
                                          "adadminpassword"
                                        ) */
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="d-inline-flex align-items-center row">
                              <div className="my-1 col-md-3  p-0">
                                <span className="mb-0 font-12">
                                  Confirm Password
                                </span>
                              </div>
                              <div className="col-md-9  flex-wrap d-inline-flex">
                                <div className="mr-3 my-1">
                                  <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                    <button disabled={disabled}
                                      type="button"
                                      onClick={() => setShowPwd4(!showPwd4)}
                                      className="showableInput"
                                    ></button>
                                    <input
                                      type={showPwd4 ? "text" : "password"}
                                      className="form-control bg-transparent"
                                      placeholder=""
                                      value={
                                        formData.adconfirmadminpassword.value
                                      }
                                      /* className={`form-control bg-transparent ${
                                      formData.adconfirmadminpassword.value !==
                                        "" &&
                                      formData.adconfirmadminpassword
                                        .isValid === false
                                        ? "invalid"
                                        : ""
                                    }`} */
                                      onChange={(e) =>
                                        onFieldChange(
                                          e.target.value,
                                          "adconfirmadminpassword"
                                        )
                                      }
                                      onBlur={(e) => {
                                        const value = e.target.value;

                                        if (
                                          value !==
                                          formData.adadminpassword.value
                                        ) {
                                          setFormData((state) =>
                                            update(state, {
                                              adadminpassword: {
                                                isValid: { $set: false },
                                              },
                                            })
                                          );

                                          setErrorMsg(
                                            "Admin password doesn't match"
                                          );
                                        } else {
                                          setFormData((state) =>
                                            update(state, {
                                              adadminpassword: {
                                                isValid: { $set: true },
                                              },
                                            })
                                          );

                                          setErrorMsg(null);
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="d-inline-flex align-items-center selectionArea no-border my-1 row">
                            <div className="col-md-3 my-1  p-0">
                              <span className="mb-0 font-12">Trust Type</span>
                            </div>
                            <div className=" col-md-9">
                              <div className="d-inline-flex mr-3 my-1 flex-wrap">
                                <label className="container font-12 text-color-black">
                                  External Trust
                                  <input
                                    type="radio"
                                    name="trusttype"
                                    checked={
                                      formData.trusttype.value ===
                                      "External"
                                    }
                                    onChange={(e) =>
                                      onFieldChange(
                                        "External",
                                        "trusttype"
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="d-inline-flex mr-3 my-1 flex-wrap">
                                <label className="container text-color-black">
                                  Forest Trust
                                  <input
                                    type="radio"
                                    name="trusttype"
                                    checked={
                                      formData.trusttype.value === "Forest"
                                    }
                                    onChange={(e) =>
                                      onFieldChange("Forest", "trusttype")
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">
                                Existing or new remote domain
                              </span>
                            </div>
                            <div className="col-md-5 col-9 flex-wrap d-inline-flex">
                              <div className="mr-3 my-1 w-100">
                                <div className="w-xs-100 w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  <input
                                    type="text"
                                    placeholder="corp.example.com"
                                    value={
                                      formData.existingornewremotedomainname
                                        .value
                                    }
                                    className={`fw-100 form-control bg-transparent ${
                                      formData.existingornewremotedomainname
                                        .value !== "" &&
                                      formData.existingornewremotedomainname
                                        .isValid === false
                                        ? "invalid"
                                        : ""
                                    }`}
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.value,
                                        "existingornewremotedomainname"
                                      )
                                    }
                                    onBlur={(e) =>
                                      onFieldBlur(
                                        validateDomainName,
                                        e.target.value,
                                        "existingornewremotedomainname"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">
                                Trust Password
                              </span>
                            </div>
                            <div className="col-md-5 col-9 flex-wrap d-inline-flex">
                              <div className="mr-3 w-100 my-1">
                                <div className="w-xs-100 w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  <button disabled={disabled}
                                    type="button"
                                    onClick={() => setShowPwd5(!showPwd5)}
                                    className="showableInput"
                                  ></button>
                                  <input
                                    type={showPwd5 ? "text" : "password"}
                                    placeholder=""
                                    value={formData.trustpassword.value}
                                    className={`w-100 form-control bg-transparent ${
                                      formData.trustpassword.value !== "" &&
                                      formData.trustpassword.isValid === false
                                        ? "invalid"
                                        : ""
                                    }`}
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.value,
                                        "trustpassword"
                                      )
                                    }
                                    onBlur={(e) =>
                                      onFieldBlur(
                                        validatePassword,
                                        e.target.value,
                                        "trustpassword"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="d-inline-flex align-items-center selectionArea no-border my-1 row">
                            <div className="col-md-3 my-1  p-0">
                              <span className="mb-0 font-12">
                                Trust Direction
                              </span>
                            </div>
                            <div className=" col-md-9">
                              <div className="d-inline-flex mr-3 my-1 flex-wrap">
                                <label className="container font-12 text-color-black">
                                  One—Way:outgoing
                                  <input
                                    type="radio"
                                    name="trustdirection"
                                    checked={
                                      formData.trustdirection.value ===
                                      "One-Way: Outgoing"
                                    }
                                    onChange={(e) =>
                                      onFieldChange(
                                        "One-Way: Outgoing",
                                        "trustdirection"
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="d-inline-flex mr-3 my-1 flex-wrap">
                                <label className="container text-color-black">
                                  One—Way:incoming
                                  <input
                                    type="radio"
                                    name="trustdirection"
                                    checked={
                                      formData.trustdirection.value ===
                                      "One-Way: Incoming"
                                    }
                                    onChange={(e) =>
                                      onFieldChange(
                                        "One-Way: Incoming",
                                        "trustdirection"
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>

                              <div className="d-inline-flex mr-3 my-1 flex-wrap">
                                <label className="container text-color-black">
                                  Two-Way
                                  <input
                                    type="radio"
                                    name="trustdirection"
                                    checked={
                                      formData.trustdirection.value === "Two-Way"
                                    }
                                    onChange={(e) =>
                                      onFieldChange("Two-Way", "trustdirection")
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="d-inline-flex align-items-center selectionArea no-border my-1 row">
                            <div className="col-md-3 col-9 my-1  p-0">
                              <span className="mb-0 font-12">
                                Selective Authentication
                              </span>
                            </div>
                            <div className=" col-md-9 col-3">
                              <div className="one form-line  d-inline-flex align-items-center">
                                <label className=" switch mb-0 pl-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData.selectiveauthentication.value ===
                                      "yes"
                                    }
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.checked ? "yes" : "no",
                                        "selectiveauthentication"
                                      )
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="d-inline-flex align-items-center row my-1">
                            <div className="col-md-3 p-0">
                              <span className="mb-0 font-12">
                                Conditional forwarder
                              </span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                              {formData.conditionalforwarderipaddresses.map(
                                (item, idxItem) => (
                                  <div className="mr-3 " key={idxItem}>
                                    <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                      <button disabled={disabled}
                                        type="button"
                                        onClick={() => {
                                          if (
                                            formData
                                              .conditionalforwarderipaddresses
                                              .length > 2
                                          ) {
                                            setIsDirty(true);
                                            setFormData((state) =>
                                              update(state, {
                                                conditionalforwarderipaddresses: {
                                                  $splice: [[idxItem, 1]],
                                                },
                                              })
                                            );
                                          }
                                        }}
                                        className="closableInput"
                                      ></button>
                                      <input
                                        type="text"
                                        placeholder="192.168.1.50"
                                        value={item.value}
                                        className={`form-control bg-transparent ${
                                          item.value !== "" &&
                                          item.isValid === false
                                            ? "invalid"
                                            : ""
                                        }`}
                                        onChange={(e) => {
                                          const value = e.target.value;

                                          for (const [
                                            idxOtherItem,
                                            otherItem,
                                          ] of formData.conditionalforwarderipaddresses.entries()) {
                                            if (idxItem !== idxOtherItem) {
                                              if (
                                                otherItem.isValid &&
                                                otherItem.value !== ""
                                              ) {
                                                if (
                                                  otherItem.value.toLowerCase() ===
                                                  value.toLowerCase()
                                                ) {
                                                  setErrorMsg(
                                                    "Conditional Forwarder IP Address must be unique"
                                                  );
                                                  return false;
                                                }
                                              }
                                            }
                                          }

                                          setErrorMsg(null);
                                          setIsDirty(true);

                                          setFormData((state) =>
                                            update(state, {
                                              conditionalforwarderipaddresses: {
                                                [idxItem]: {
                                                  value: { $set: value },
                                                },
                                              },
                                            })
                                          );
                                        }}
                                        onBlur={(e) => {
                                          const value = e.target.value;

                                          if (value !== "") {
                                            const errorMsg = validateIPAddress(
                                              value
                                            );

                                            setFormData((state) =>
                                              update(state, {
                                                conditionalforwarderipaddresses: {
                                                  [idxItem]: {
                                                    isValid: {
                                                      $set:
                                                        errorMsg === undefined,
                                                    },
                                                  },
                                                },
                                              })
                                            );

                                            if (errorMsg) {
                                              setErrorMsg(errorMsg);
                                            }
                                          } else {
                                            setFormData((state) =>
                                              update(state, {
                                                conditionalforwarderipaddresses: {
                                                  [idxItem]: {
                                                    isValid: { $set: false },
                                                  },
                                                },
                                              })
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                              <button disabled={disabled}
                                type="button"
                                onClick={() => {
                                  if (
                                    formData.conditionalforwarderipaddresses
                                      .length < 6
                                  ) {
                                    setIsDirty(true);
                                    setFormData((state) =>
                                      update(state, {
                                        conditionalforwarderipaddresses: {
                                          $push: [
                                            { value: "", isValid: false },
                                          ],
                                        },
                                      })
                                    );
                                  }
                                }}
                              >
                                <img src="../images/coloredPlus.svg" />
                              </button>
                            </div>
                          </div>
                          {formData.directoryedition.value === "Enterprise" && (
                            <RegionsList></RegionsList>
                          )}
                          <ShareDirectory></ShareDirectory>

                          <div class="my-2"></div>
                          <div className="formRow col-md-6 mt-5 my-3 pt-2 row">
                          <div className="d-inline-flex my-2 justify-content-between">
                            <div className="one form-line  d-inline-flex align-items-center">
                              <label className=" switch mb-0">
                                <input
                                  type="checkbox"
                                  checked={
                                    formData.awsmanagedadwithtrustrelationship_windowsautojoindomain.value ===
                                    "yes"
                                  }
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    setIsDirty(true);

                                    setFormData((state) =>
                                      update(state, {
                                        awsmanagedadwithtrustrelationship_windowsautojoindomain: {
                                          value: {
                                            $set: checked ? "yes" : "no",
                                          },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span className="slider round"></span>
                              </label>
                              <span className="switchLabel">
                               Enable seamless domain join for Windows EC2 instances
                              </span>
                            </div>
                          </div>
                          <div className="d-inline-flex my-2 justify-content-between">
                            <div className="one form-line  d-inline-flex align-items-center">
                              <label className=" switch mb-0">
                                <input
                                  type="checkbox"
                                  checked={
                                    formData.awsmanagedadwithtrustrelationship_linuxautojoindomain.value ===
                                    "yes"
                                  }
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    setIsDirty(true);

                                    setFormData((state) =>
                                      update(state, {
                                        awsmanagedadwithtrustrelationship_linuxautojoindomain: {
                                          value: {
                                            $set: checked ? "yes" : "no",
                                          },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span className="slider round"></span>
                              </label>
                              <span className="switchLabel">
                               Enable seamless domain join for Linux EC2 instances
                              </span>
                            </div>
                          </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.INFRASTRUCTURE_IMAGEMANAGEMENT + "/" + projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Image Management
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ADIntegration;
