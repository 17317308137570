import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "./DetailsTags";
import DetailsGrid from "./DetailsGrid";
import { getArrayValue, getBooleanValue } from "./DashboardHelper";

//const projectId = "google_1";
const dashboardName = "TGW RouteTables";

const SubMenu = () => {
  const { projectId } = useParams();

  return (
    <>
      <h3>Transit Gateway</h3>
      <div className="otherTableTitles ">
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.TGW + "/" + projectId}>
            TGWs
          </Link>
        </h3>
        <h3 className="font-15 activeTable">Routing Domains</h3>
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.TGW_ATTACHMENT + "/" + projectId}>
            Attachments
          </Link>
        </h3>
      </div>
    </>
  );
};

const Details = ({ close, selectedRow }) => {
  const [type, setType] = useState("Details");

  return (
    <div className="justify-content-center align-items-end account-detail">
      <div className="w-100 s-flex flex-column account-detail-area">
        <button className="exitAccount" onClick={() => close()}>
          <img src="../images/close.svg" />
        </button>
        <div className="">
          <ul className="account-detail-link m-0 p-0 d-inline-flex">
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Details" && "my_active"
              }`}
              onClick={() => setType("Details")}
            >
              Details
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Associations" && "my_active"
              }`}
              onClick={() => setType("Associations")}
            >
              Associations
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Propagations" && "my_active"
              }`}
              onClick={() => setType("Propagations")}
            >
              Propagations
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Routes" && "my_active"
              }`}
              onClick={() => setType("Routes")}
            >
              Routes
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Tags" && "my_active"
              }`}
              onClick={() => setType("Tags")}
            >
              Tags
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 account-detail-container-area">
          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Details" && "inaktif"
            }`}
          >
            <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow["AccountId"]}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Region</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Region}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  TGW Route Table Name
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {getArrayValue(selectedRow.Tags, -1, "Key", "Name", "Value")}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  TGW Route Table Id
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.TransitGatewayRouteTableId}
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">TGW Id</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.TransitGatewayId}
                </p>
              </div>

              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">State</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.State}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Default Association Route Table
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {getBooleanValue(selectedRow, "DefaultAssociationRouteTable")}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Default Propagation Route Table
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {getBooleanValue(selectedRow, "DefaultPropagationRouteTable")}
                </p>
              </div>
            </div>
          </div>

          <DetailsGrid
            type={type}
            typeName="Associations"
            array={selectedRow.Associations}
            metadata={[
              { header: "Attachment Id", key: "TransitGatewayAttachmentId" },
              { header: "Resource Type", key: "ResourceType" },
              { header: "Resource Id", key: "ResourceId" },
              { header: "State", key: "State" },
            ]}
          ></DetailsGrid>

          <DetailsGrid
            type={type}
            typeName="Propagations"
            array={selectedRow.Propagations}
            metadata={[
              { header: "Attachment Id", key: "TransitGatewayAttachmentId" },
              { header: "Resource Type", key: "ResourceType" },
              { header: "Resource Id", key: "ResourceId" },
              { header: "State", key: "State" },
            ]}
          ></DetailsGrid>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Routes" && "inaktif"
            }`}
          >
            <div className="d-flex flex-column">
              <table className="details-table">
                <thead>
                  <tr>
                    <th>CIDR</th>
                    <th>Attachment</th>
                    <th>Resource Type</th>
                    <th>Route Type</th>
                    <th>Route State</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedRow.Routes &&
                    selectedRow.Routes.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.DestinationCidrBlock}</td>
                        <td>
                          {item.TransitGatewayAttachments &&
                            (item.TransitGatewayAttachments[0].ResourceId ||
                              item.TransitGatewayAttachments[0]
                                .TransitGatewayAttachmentId)}
                        </td>
                        <td>
                          {item.TransitGatewayAttachments &&
                            item.TransitGatewayAttachments[0].ResourceType}
                        </td>
                        <td>{item.Type}</td>
                        <td>{item.State}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>
        </div>
      </div>
    </div>
  );
};

const Navigations = () => {
  const { projectId } = useParams();
  return (
    <>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.VPC + "/" + projectId}
      >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">VPCs</p>
      </Link>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.VPN + "/" + projectId}
      >
        <p className="m-0 p-0 mr-2 font-weight-bold">Hybrid Network</p>
        <img src="../images/ribbon-designRight.svg" />
      </Link>
    </>
  );
};

const TGWRoutingDomains = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "TGW Route Table Name",
        accessor: (d) => getArrayValue(d.Tags, -1, "Key", "Name", "Value"),
      },
      {
        Header: "TGW Route Table Id",
        accessor: "TransitGatewayRouteTableId",
      },
      {
        Header: "TGW Id",
        accessor: "TransitGatewayId",
      },
      {
        Header: "State",
        accessor: "State",
      },
      {
        Header: "Default Association Route Table",
        accessor: (d) => getBooleanValue(d, "DefaultAssociationRouteTable"),
        id: "DefaultAssociationRouteTable",
      },
      {
        Header: "Default Propagation Route Table",
        accessor: (d) => getBooleanValue(d, "DefaultPropagationRouteTable"),
        id: "DefaultPropagationRouteTable",
      },
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "network",
    title: "Network",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_CENTERS,
    designRoute: ROUTES.DESIGN_NETWORK,
    SubMenu: SubMenu,
  });
};

export default TGWRoutingDomains;
