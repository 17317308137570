import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useHistory, useParams } from "react-router-dom";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";
import ShortAccount3 from "../../ShortAccount3";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";
import LongAccount3 from "../../LongAccount3";

import * as ROUTES from "../../../../constants/routes";
import { validateAccountEmailId } from "../../../../helpers/validator";
import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

//const projectId = "google_1";

const PREFIX = "/aws/accountframework/platformaccounts";
const ORG_STRUCTURE_PREFIX =  "/aws/accountframework/organizationalstructure";
const SHAREDSERVICES_ACCOUNTS = [
  "createoruseexisting",
  "sharedservicesaccountoption",
  "network",
  "loganalytics",
  "workspaces",
  "releasemanagement",
  "sharedservices",
];
const SECURITY_ACCOUNTS = ["logarchive", "audit", "security"];
const DEFAULT_ACCOUNTS = SHAREDSERVICES_ACCOUNTS.concat(SECURITY_ACCOUNTS);
const ACCOUNT_LISTS = ["sharedservicesaccountslist", "securityaccountslist"];
const RESERVED_KEYS = ACCOUNT_LISTS.concat([
  "createoruseexisting",
  "sharedservicesaccountoption",
]);
const ALL_RESERVED_KEYS = RESERVED_KEYS.concat(DEFAULT_ACCOUNTS);

const allowedFormValues = {
  useexisting_dedicatedaccounts: {
    types: ["accountid"],
    sharedservicesaccountslist: [
      "sharedservices",
      "loganalytics",
      "workspaces",
      "releasemanagement",
      "network",
    ],
    securityaccountslist: ["logarchive", "audit", "security"],
  },
  useexisting_singleaccount: {
    types: ["accountid"],
    sharedservicesaccountslist: ["sharedservices"],
    securityaccountslist: ["logarchive", "audit", "security"],
  },
  create_dedicatedaccounts: {
    types: ["accountname", "accountemailid"],
    sharedservicesaccountslist: [
      "sharedservices",
      "loganalytics",
      "workspaces",
      "releasemanagement",
      "network",
    ],
    securityaccountslist: ["logarchive", "audit", "security"],
  },
  create_singleaccount: {
    types: ["accountname", "accountemailid"],
    sharedservicesaccountslist: ["sharedservices"],
    securityaccountslist: ["logarchive", "audit", "security"],
  },
};

const PlatformAccounts = () => {
  const { projectId } = useParams();

  const awsAmplify = useAwsAmplify();
  const history = useHistory();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [empty, setEmpty] = useState(false);

  const [formValues, setFormValues] = useState({
    [`${PREFIX}/createoruseexisting`]: {
      value: "useexisting",
      isValid: true,
      isChanged: false,
    },
    [`${PREFIX}/sharedservicesaccountoption`]: {
      value: "singleaccount",
      isValid: true,
      isChanged: false,
    },
    [`${PREFIX}/sharedservicesaccountslist`]: {
      value: [
        "sharedservices",
        "loganalytics",
        "workspaces",
        "releasemanagement",
        "network",
      ],
      isValid: true,
      isChanged: false,
    },
    [`${PREFIX}/securityaccountslist`]: {
      value: ["logarchive", "audit", "security"],
      isValid: true,
      isChanged: false,
    },
    platformOU: [],
    [`${PREFIX}/absoluteouname`] : {
      value : ""
    },
    [`${PREFIX}/ouname`]: {
      value:""
    } 
  });

  const [isDirty, setIsDirty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const getAccountsListName = (name) => {
    return SHAREDSERVICES_ACCOUNTS.indexOf(name) !== -1
      ? `${PREFIX}/sharedservicesaccountslist`
      : `${PREFIX}/securityaccountslist`;
  };

  const isSwitchOn = (name) => {
    return (
      formValues[getAccountsListName(name)] &&
      formValues[getAccountsListName(name)].value.indexOf(name) !== -1
    );
  };

  const updateSwitch = (name, status) => {
    const accountListName = getAccountsListName(name);

    if (status === true) {
      if (formValues[accountListName].value.indexOf(name) === -1) {
        formValues[accountListName].value.push(name);
      }
    } else {
      formValues[accountListName].value = formValues[
        accountListName
      ].value.filter((v) => v !== name);
    }

    makeChange(accountListName);
  };

  const updateValue = (name, value) => {
    const convertedFormValues = convertFormValues(formValues);
    const nameArray = name.split("/");
    const field = nameArray[nameArray.length - 1];

    const uniqueSet = new Set();

    for (const prop in convertedFormValues) {
      const splitArray = prop.split("/");
      if (
        splitArray.length === 5 &&
        splitArray[splitArray.length - 1] === field
      ) {
        uniqueSet.add(convertedFormValues[prop].toLowerCase());
      }
    }

    if (uniqueSet.has(value.toLowerCase())) {
      setErrorMsg(`${field} has to be unique`);
      return false;
    }

    setFormValues((state) => {
      let newFormValue = {
        ...state[name],
        value: value,
      };

      if (!state[name]) {
        newFormValue.isChanged = false;
      }

      return {
        ...state,
        [name]: newFormValue,
      };
    });
  };

  const validate = (name, value) =>
    setFormValues((state) => {
      return {
        ...state,
        [name]: {
          ...state[name],
          isValid: value,
        },
      };
    });

  const makeChange = (name) =>
    setFormValues((state) => {
      return {
        ...state,
        [name]: {
          ...state[name],
          isChanged: true,
        },
      };
    });

  /* useEffect(() => {
    let isDirty = false;

    for (const key in formValues) {
      if (
        formValues[key].value !== "" &&
        formValues[key].isValid &&
        formValues[key].isChanged
      ) {
        isDirty = true;
      }
    }

    setIsDirty(isDirty);
  }, [formValues]); */

  useEffect(() => {
    console.log("fetching");
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);
        const result = await awsAmplify.loadProjectData(projectId, PREFIX);

        const orgStructure = await awsAmplify.loadProjectData(projectId, ORG_STRUCTURE_PREFIX);
        setIsLoading(false);

        console.log(result);

        if (result.data) {
          setEmpty(result.data.empty);

          const loadedFormValues = {
            [`${PREFIX}/createoruseexisting`]: {
              value: "useexisting",
              isValid: true,
              isChanged: false,
            },
            [`${PREFIX}/sharedservicesaccountoption`]: {
              value: "singleaccount",
              isValid: true,
              isChanged: false,
            },
            [`${PREFIX}/sharedservicesaccountslist`]: {
              value: [
                "sharedservices",
                "loganalytics",
                "workspaces",
                "releasemanagement",
                "network",
              ],
              isValid: true,
              isChanged: false,
            },
            [`${PREFIX}/securityaccountslist`]: {
              value: ["logarchive", "audit", "security"],
              isValid: true,
              isChanged: false,
            },
            [`${PREFIX}/absoluteouname`] :{
              value: ""
            },
            [`${PREFIX}/ouname`] :{
              value: ""
            },
            platformOU:[]
          };

          if (!result.data.empty) {
            result.data[`${PREFIX}/sharedservicesaccountslist`] = result.data[
              `${PREFIX}/sharedservicesaccountslist`
            ]
              ? result.data[`${PREFIX}/sharedservicesaccountslist`].split(",")
              : [];
            result.data[`${PREFIX}/securityaccountslist`] = result.data[
              `${PREFIX}/securityaccountslist`
            ]
              ? result.data[`${PREFIX}/securityaccountslist`].split(",")
              : [];

            for (const ssA of result.data[
              `${PREFIX}/sharedservicesaccountslist`
            ]) {
              if (ALL_RESERVED_KEYS.indexOf(ssA) === -1) {
                ssAccounts.push({
                  id: uuidv4(),
                  name: ssA,
                });
              }
            }

            for (const sA of result.data[`${PREFIX}/securityaccountslist`]) {
              if (ALL_RESERVED_KEYS.indexOf(sA) === -1) {
                sAccounts.push({
                  id: uuidv4(),
                  name: sA,
                });
              }
            }
          }

          for (const key in result.data) {
            loadedFormValues[key] = {
              isValid: true,
              value: result.data[key],
              isChanged: false,
            };
          }

          if (result.data[`${PREFIX}/absoluteouname`]) {
            loadedFormValues[`${PREFIX}/absoluteouname`].value =  result.data[`${PREFIX}/absoluteouname`] || "";
          }

          if(orgStructure !== undefined && orgStructure.data)
          {
            const OU_data = orgStructure.data;
            var OUs = OU_data[`${ORG_STRUCTURE_PREFIX}/oulist`]; 
            loadedFormValues.platformOU = OUs && OUs.split(',').filter(Boolean) || [];
          }

          setFormValues(loadedFormValues);
        } else {
          console.log("No value");

          setFormValues({
            [`${PREFIX}/createoruseexisting`]: {
              value: "useexisting",
              isValid: true,
              isChanged: false,
            },
            [`${PREFIX}/sharedservicesaccountoption`]: {
              value: "singleaccount",
              isValid: true,
              isChanged: false,
            },
            [`${PREFIX}/sharedservicesaccountslist`]: {
              value: [
                "sharedservices",
                "loganalytics",
                "workspaces",
                "releasemanagement",
                "network",
              ],
              isValid: true,
              isChanged: false,
            },
            [`${PREFIX}/securityaccountslist`]: {
              value: ["logarchive", "audit", "security"],
              isValid: true,
              isChanged: false,
            },
            [`${PREFIX}/absoluteouname`]: {
              value : ""
            },
            platformOU:[]
          });
        }
      } catch (err) {
        setIsLoading(false);
        if (err.response) setErrorMsg(err.response.data.message);
        else setErrorMsg(err.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const [ssAccounts, setSsAccounts] = useState([]);
  const [sAccounts, setSAccounts] = useState([]);

  const addAccount = (item, isSs = true) => {
    if (isSs) {
      setSsAccounts(ssAccounts.concat(item));
    } else {
      setSAccounts(sAccounts.concat(item));
    }
    setIsDirty(true);
  };

  const removeAccount = (id, isSs = true) => {
    if (isSs) {
      setSsAccounts(ssAccounts.filter((item) => item.id !== id));
    } else {
      setSAccounts(sAccounts.filter((item) => item.id !== id));
    }
    setIsDirty(true);
  };

  const [newSsAccountFunction, setNewSsAccountFunction] = useState("");
  const [newSAccountFunction, setNewSAccountFunction] = useState("");

  const [
    isNewSsAccountFunctionValid,
    setIsNewSsAccountFunctionValid,
  ] = useState(true);
  const [isNewSAccountFunctionValid, setIsNewSAccountFunctionValid] = useState(
    true
  );

  const isNewAccountFunctionValid = (isSs = true, value) => {
    const newAccountFunction =
      value || (isSs ? newSsAccountFunction : newSAccountFunction);

    if (newAccountFunction === "") {
      return "New Account Function must not empty";
    }

    if (!/^\S*$/.test(newAccountFunction)) {
      return "New Account Function must not contains space";
    }

    if (ALL_RESERVED_KEYS.indexOf(newAccountFunction.toLowerCase()) !== -1) {
      return "New Account Function must be unique";
    } else {
      //let accounts = isSs ? ssAccounts : sAccounts;

      for (const account of ssAccounts.concat(sAccounts)) {
        if (account.name.toLowerCase() === newAccountFunction.toLowerCase()) {
          return "New Account Function must be unique";
        }
      }

      return;
    }
  };

  const convertFormValues = () => {
    const newFormValues = {
      [`${PREFIX}/createoruseexisting`]: formValues[
        `${PREFIX}/createoruseexisting`
      ].value,
      [`${PREFIX}/sharedservicesaccountoption`]: formValues[
        `${PREFIX}/sharedservicesaccountoption`
      ].value,
    [`${PREFIX}/absoluteouname`] : formValues[`${PREFIX}/absoluteouname`].value
    };

    if(formValues[`${PREFIX}/absoluteouname`] && formValues[`${PREFIX}/absoluteouname`].value != "")
    {
      let ouname = formValues[`${PREFIX}/absoluteouname`].value.split('/');
      let lastStrOU = ouname[ouname.length-1];
      newFormValues[`${PREFIX}/ouname`] = lastStrOU;
    }

    const allowedValues =
      allowedFormValues[
        `${formValues[`${PREFIX}/createoruseexisting`].value}_${
          formValues[`${PREFIX}/sharedservicesaccountoption`].value
        }`
      ];

    console.log("allowed values");
    console.log(allowedValues);

    console.log("current values");
    console.log(formValues[`${PREFIX}/sharedservicesaccountslist`]);
    console.log(formValues[`${PREFIX}/securityaccountslist`]);

    const sharedservicesaccountslist = [];

    for (const entry of formValues[`${PREFIX}/sharedservicesaccountslist`]
      .value) {
      if (allowedValues.sharedservicesaccountslist.indexOf(entry) !== -1) {
        sharedservicesaccountslist.push(entry);

        for (const type of allowedValues.types) {
          if (
            formValues[`${PREFIX}/${entry}/${type}`] &&
            formValues[`${PREFIX}/${entry}/${type}`].value !== "" &&
            formValues[`${PREFIX}/${entry}/${type}`].isValid === true
          ) {
            newFormValues[`${PREFIX}/${entry}/${type}`] =
              formValues[`${PREFIX}/${entry}/${type}`].value;
          }
        }
      }
    }

    const securityaccountslist = [];

    for (const entry of formValues[`${PREFIX}/securityaccountslist`].value) {
      if (allowedValues.securityaccountslist.indexOf(entry) !== -1) {
        securityaccountslist.push(entry);

        for (const type of allowedValues.types) {
          if (
            formValues[`${PREFIX}/${entry}/${type}`] &&
            formValues[`${PREFIX}/${entry}/${type}`].value !== "" &&
            formValues[`${PREFIX}/${entry}/${type}`].isValid === true
          ) {
            newFormValues[`${PREFIX}/${entry}/${type}`] =
              formValues[`${PREFIX}/${entry}/${type}`].value;
          }
        }
      }
    }

    if (
      newFormValues[`${PREFIX}/sharedservicesaccountoption`] ===
      "dedicatedaccounts"
    ) {
      for (const ssAccount of ssAccounts) {
        sharedservicesaccountslist.push(ssAccount.name);

        for (const type of allowedValues.types) {
          if (
            formValues[`${PREFIX}/${ssAccount.name}/${type}`] &&
            formValues[`${PREFIX}/${ssAccount.name}/${type}`].value !== "" &&
            formValues[`${PREFIX}/${ssAccount.name}/${type}`].isValid === true
          ) {
            newFormValues[`${PREFIX}/${ssAccount.name}/${type}`] =
              formValues[`${PREFIX}/${ssAccount.name}/${type}`].value;
          }
        }
      }

      for (const sAccount of sAccounts) {
        securityaccountslist.push(sAccount.name);

        for (const type of allowedValues.types) {
          if (
            formValues[`${PREFIX}/${sAccount.name}/${type}`] &&
            formValues[`${PREFIX}/${sAccount.name}/${type}`].value !== "" &&
            formValues[`${PREFIX}/${sAccount.name}/${type}`].isValid === true
          ) {
            newFormValues[`${PREFIX}/${sAccount.name}/${type}`] =
              formValues[`${PREFIX}/${sAccount.name}/${type}`].value;
          }
        }
      }
    }

    newFormValues[
      `${PREFIX}/sharedservicesaccountslist`
    ] = sharedservicesaccountslist.join(",");
    newFormValues[`${PREFIX}/securityaccountslist`] = securityaccountslist.join(
      ","
    );

    console.log("Posting the following:");
    console.log(newFormValues);

    return newFormValues;
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFormValues(formValues),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }

      if (
        formValues[`${PREFIX}/sharedservicesaccountoption`].value !==
        "dedicatedaccounts"
      ) {
        setSsAccounts([]);
        setSAccounts([]);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}

      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="userTitleTop">Account Framework</div>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              className="tableArea accountsTableArea haveFilter coreTableArea container-fluid p-0 d-flex flex-column"
              onSubmit={async (e) => {
                e.preventDefault();
                await submit();
              }}
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                isDirty={isDirty}
                dashboardRoute={ROUTES.ORGANIZATIONAL_UNITS}
                designRoute={ROUTES.DESIGN_ACCOUNT_FRAMEWORK}
              ></DomainMenu>
              <fieldset
                disabled={disabled}
                className="mainArea fitDeviceHeight"
              >
                <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 ">
                  <div className="row d-flex align-items-center my-4">
                    <div className="col-xl-4 col-lg-4 col-md-3 col-12 pl-0">
                      <h3> Platform Accounts</h3>
                    </div>
                    <div className="col-xl-3 col-lg-2 col-1 col-md-1 col-12"></div>
                    <div className="col-xl-5 col-lg-6 col-5 col-md-8 col-12 pr-0 pl-0 d-flex align-items-center justify-content-end rightNavArea">
                      <div className="d-inline-flex yes-no-area align-items-center w-100">
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100 w-100">
                          <label
                            className={`btn btn-link ${
                              formValues[`${PREFIX}/createoruseexisting`]
                                .value === "useexisting"
                                ? "active"
                                : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="createoruseexisting"
                              value="useexisting"
                              checked={
                                formValues[`${PREFIX}/createoruseexisting`]
                                  .value === "useexisting"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                updateValue(
                                  `${PREFIX}/createoruseexisting`,
                                  "useexisting"
                                );
                                makeChange(`${PREFIX}/createoruseexisting`);
                              }}
                            />{" "}
                            Use Existing Core Accounts
                          </label>
                          <label
                            className={`btn btn-link ${
                              formValues[`${PREFIX}/createoruseexisting`]
                                .value === "create"
                                ? "active"
                                : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="createoruseexisting"
                              value="create"
                              checked={
                                formValues[`${PREFIX}/createoruseexisting`]
                                  .value === "create"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                updateValue(
                                  `${PREFIX}/createoruseexisting`,
                                  "create"
                                );
                                makeChange(`${PREFIX}/createoruseexisting`);
                              }}
                            />{" "}
                            Create Platform Accounts
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {empty && (
                    <NoInitialData />
                  )}
                <div className="d-inline-flex align-items-center row">
                  <div className=" selectionArea d-inline-flex flex-wrap align-items-center my-1  p-1">
                  <div className="col-md-1 flex-wrap my-1 p-0 mx-3">
                      <span className="mb-0 font-12">
                      Platform OU
                      </span>
                   </div>
                    { (formValues.platformOU && formValues.platformOU.length === 0) ? (
                      <div className="col-md-6 flex-wrap d-inline-flex">
                          <span className="warning">
                          This section depends on the organizational structure information in
                          Account Framework section that is yet to be provided
                        </span>
                        </div>
                      ) : (
                      <>
                        <div className="col-md-6 flex-wrap d-inline-flex">
                            <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                            <select className="form-control bg-gray pr-lg-0 pr-5" 
                                disabled={!(formValues.platformOU && formValues.platformOU.length > 0)}
                                  value={( formValues[`${PREFIX}/absoluteouname`].value)}
                                  onChange={(e) => {
                                    updateValue(
                                      `${PREFIX}/absoluteouname`,
                                      e.target.value
                                    );
                                    setIsDirty(true);
                                }}>
                                <option value="">Select</option>
                                {formValues.platformOU && formValues.platformOU.map((ouName, idxV) => (
                                (ouName !== "") &&
                                  <option key={idxV} value={ouName}>
                                    {ouName}
                                  </option>
                                ))}</select>  
                            </div>
                      </div> 
                      </>
                    )}
                  </div>
                  </div>
                           
                 
                  <div className="row">
                    <div className="col-12 px-2 my-3">
                      <div className=" specialSelectionArea d-inline-flex selectionArea px-2 justify-content-between py-1 my-1">
                        <div className="my-2">
                          <label className="container">
                            Single Shared Services Account
                            <input
                              type="radio"
                              name="sharedservicesaccountoption"
                              value="singleaccount"
                              checked={
                                formValues[
                                  `${PREFIX}/sharedservicesaccountoption`
                                ].value === "singleaccount"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                updateValue(
                                  `${PREFIX}/sharedservicesaccountoption`,
                                  "singleaccount"
                                );
                                makeChange(
                                  `${PREFIX}/sharedservicesaccountoption`
                                );
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="my-2">
                          <label className="container">
                            Dedicated Accounts for Shared Services{" "}
                            <input
                              type="radio"
                              name="sharedservicesaccountoption"
                              value="dedicatedaccounts"
                              checked={
                                formValues[
                                  `${PREFIX}/sharedservicesaccountoption`
                                ].value === "dedicatedaccounts"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                updateValue(
                                  `${PREFIX}/sharedservicesaccountoption`,
                                  "dedicatedaccounts"
                                );
                                makeChange(
                                  `${PREFIX}/sharedservicesaccountoption`
                                );
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {formValues[`${PREFIX}/createoruseexisting`].value ===
                      "useexisting" && (
                      <>
                        <div className="d-flex col-lg-6 col-12  pl-md-0 px-1 specialHalfColumn">
                          <div className="d-flex flex-column w-100 px-3 my-1 py-1 mainContentArea">
                            <div className=" d-inline-flex row topOfFormRow  px-md-2 px-3 my-1 align-items-center justify-content-between">
                              <div className="col-12 pl-0">
                                <h2 className="font-weight-bold mb-0">
                                  Shared Services Accounts
                                </h2>
                              </div>
                            </div>

                            <ShortAccount3
                              paramPrefix={PREFIX}
                              checkboxLabel="Shared Services"
                              mandatory={true}
                              name="sharedservices"
                              formValues={formValues}
                              updateValue={updateValue}
                              validate={validate}
                              makeChange={makeChange}
                              setErrorMsg={setErrorMsg}
                              updateSwitch={updateSwitch}
                              setIsDirty={setIsDirty}
                            ></ShortAccount3>

                            {formValues[`${PREFIX}/sharedservicesaccountoption`]
                              .value === "dedicatedaccounts" && (
                              <>
                                <ShortAccount3
                                  paramPrefix={PREFIX}
                                  name="loganalytics"
                                  checkboxLabel="Log Analytics"
                                  mandatory={false}
                                  defaultChecked={isSwitchOn("loganalytics")}
                                  formValues={formValues}
                                  updateValue={updateValue}
                                  validate={validate}
                                  makeChange={makeChange}
                                  setErrorMsg={setErrorMsg}
                                  updateSwitch={updateSwitch}
                                  setIsDirty={setIsDirty}
                                ></ShortAccount3>

                                <ShortAccount3
                                  paramPrefix={PREFIX}
                                  name="workspaces"
                                  checkboxLabel="Workspaces (VDI)"
                                  mandatory={false}
                                  defaultChecked={isSwitchOn("workspaces")}
                                  formValues={formValues}
                                  updateValue={updateValue}
                                  validate={validate}
                                  makeChange={makeChange}
                                  setErrorMsg={setErrorMsg}
                                  updateSwitch={updateSwitch}
                                  setIsDirty={setIsDirty}
                                ></ShortAccount3>

                                <ShortAccount3
                                  paramPrefix={PREFIX}
                                  name="releasemanagement"
                                  checkboxLabel="Release Management"
                                  mandatory={false}
                                  defaultChecked={isSwitchOn(
                                    "releasemanagement"
                                  )}
                                  formValues={formValues}
                                  updateValue={updateValue}
                                  validate={validate}
                                  makeChange={makeChange}
                                  setErrorMsg={setErrorMsg}
                                  updateSwitch={updateSwitch}
                                  setIsDirty={setIsDirty}
                                ></ShortAccount3>

                                <ShortAccount3
                                  paramPrefix={PREFIX}
                                  name="network"
                                  checkboxLabel="Network"
                                  mandatory={false}
                                  defaultChecked={isSwitchOn("network")}
                                  formValues={formValues}
                                  updateValue={updateValue}
                                  validate={validate}
                                  makeChange={makeChange}
                                  setErrorMsg={setErrorMsg}
                                  updateSwitch={updateSwitch}
                                  setIsDirty={setIsDirty}
                                ></ShortAccount3>

                                {ssAccounts.map((item, idx) => (
                                  <ShortAccount3
                                    key={item.id}
                                    paramPrefix={PREFIX}
                                    name={item.name}
                                    checkboxLabel={item.name}
                                    remove={() => removeAccount(item.id)}
                                    newAccount={true}
                                    mandatory={false}
                                    defaultChecked={true}
                                    formValues={formValues}
                                    updateValue={updateValue}
                                    validate={validate}
                                    makeChange={makeChange}
                                    setErrorMsg={setErrorMsg}
                                    updateSwitch={updateSwitch}
                                    setIsDirty={setIsDirty}

                                    /* key={item.id}
                                paramPrefix={PREFIX}
                                accountName={item.name}
                                checkboxLabel={item.name}
                                remove={() => removeAccount(item.id)}
                                newAccount={true}
                                mandatory={false}
                                defaultChecked={true}
                                formValues={formValues}
                                updateValue={updateValue}
                                validate={validate}
                                makeChange={makeChange}
                                setErrorMsg={setErrorMsg}
                                setIsDirty={setIsDirty} */
                                  ></ShortAccount3>
                                ))}

                                <div className=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-start">
                                  <div className="one form-line my-lg-0 my-1 d-inline-flex align-items-center">
                                    <span className="switchLabel">
                                      Account Function
                                    </span>
                                  </div>
                                  <div className="d-inline-flex my-lg-0 my-1 ml-3 form-line two">
                                    <input
                                      type="text"
                                      className={`form-control ${
                                        !isNewSsAccountFunctionValid &&
                                        "invalid"
                                      }`}
                                      placeholder=""
                                      value={newSsAccountFunction}
                                      onChange={(e) => {
                                        const errorMsg = isNewAccountFunctionValid(
                                          true,
                                          e.target.value
                                        );

                                        if (errorMsg) {
                                          setIsNewSsAccountFunctionValid(false);
                                          setErrorMsg(errorMsg);
                                          return false;
                                        } else {
                                          setIsNewSsAccountFunctionValid(true);
                                          setErrorMsg(null);
                                          setNewSsAccountFunction(
                                            e.target.value
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="add-button ml-3">
                                    <button
                                      disabled={disabled}
                                      type="button"
                                      className="add-new-account-link d-inline-flex align-items-center"
                                      onClick={() => {
                                        const errorMsg = isNewAccountFunctionValid(
                                          true
                                        );

                                        if (errorMsg) {
                                          setIsNewSsAccountFunctionValid(false);
                                          setErrorMsg(errorMsg);
                                        } else {
                                          setIsNewSsAccountFunctionValid(true);
                                          addAccount({
                                            id: uuidv4(),
                                            name: newSsAccountFunction,
                                          });
                                          setNewSsAccountFunction("");
                                        }
                                      }}
                                    >
                                      <img src="../images/coloredPlus.svg" />
                                      <p className="my-0 ml-2 p-0">
                                        Add Account
                                      </p>
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="d-flex col-lg-6 col-12  pr-md-0 px-2 specialHalfColumn">
                          <div className="d-flex flex-column w-100 px-3 py-1 my-1 mainContentArea">
                            <div className=" d-inline-flex row topOfFormRow  px-md-2 px-3 my-1 align-items-center justify-content-between">
                              <div className="col-12 pl-0">
                                <h2 className="font-weight-bold mb-0">
                                  Security Accounts
                                </h2>
                              </div>
                            </div>
                            <ShortAccount3
                              paramPrefix={PREFIX}
                              name="logarchive"
                              checkboxLabel="Log Archive"
                              mandatory={true}
                              defaultChecked={isSwitchOn("logarchive")}
                              formValues={formValues}
                              updateValue={updateValue}
                              validate={validate}
                              makeChange={makeChange}
                              setErrorMsg={setErrorMsg}
                              updateSwitch={updateSwitch}
                              setIsDirty={setIsDirty}
                            ></ShortAccount3>

                            <ShortAccount3
                              paramPrefix={PREFIX}
                              name="audit"
                              checkboxLabel="Audit"
                              mandatory={true}
                              defaultChecked={isSwitchOn("audit")}
                              formValues={formValues}
                              updateValue={updateValue}
                              validate={validate}
                              makeChange={makeChange}
                              setErrorMsg={setErrorMsg}
                              updateSwitch={updateSwitch}
                              setIsDirty={setIsDirty}
                            ></ShortAccount3>

                            <ShortAccount3
                              paramPrefix={PREFIX}
                              name="security"
                              checkboxLabel="Security"
                              mandatory={false}
                              defaultChecked={isSwitchOn("security")}
                              formValues={formValues}
                              updateValue={updateValue}
                              validate={validate}
                              makeChange={makeChange}
                              setErrorMsg={setErrorMsg}
                              updateSwitch={updateSwitch}
                              setIsDirty={setIsDirty}
                            ></ShortAccount3>

                            {formValues[`${PREFIX}/sharedservicesaccountoption`]
                              .value === "dedicatedaccounts" && (
                              <>
                                {sAccounts.map((item, idx) => (
                                  <ShortAccount3
                                    key={item.id}
                                    paramPrefix={PREFIX}
                                    name={item.name}
                                    checkboxLabel={item.name}
                                    remove={() => removeAccount(item.id, false)}
                                    newAccount={true}
                                    mandatory={false}
                                    defaultChecked={true}
                                    formValues={formValues}
                                    updateValue={updateValue}
                                    validate={validate}
                                    makeChange={makeChange}
                                    setErrorMsg={setErrorMsg}
                                    updateSwitch={updateSwitch}
                                    setIsDirty={setIsDirty}

                                    /* key={item.id}
                                  paramPrefix={PREFIX}
                                  accountName={item.name}
                                  checkboxLabel={item.name}
                                  remove={() => removeAccount(item.id, false)}
                                  newAccount={true}
                                  mandatory={false}
                                  defaultChecked={true}
                                  formValues={formValues}
                                  updateValue={updateValue}
                                  validate={validate}
                                  makeChange={makeChange}
                                  setErrorMsg={setErrorMsg}
                                  setIsDirty={setIsDirty} */
                                  ></ShortAccount3>
                                ))}
                                <div className=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-start">
                                  <div className="one form-line my-lg-0 my-1 d-inline-flex align-items-center">
                                    <span className="switchLabel">
                                      Account Function
                                    </span>
                                  </div>
                                  <div className="d-inline-flex my-lg-0 my-1 ml-3 form-line two">
                                    <input
                                      type="text"
                                      className={`form-control ${
                                        !isNewSAccountFunctionValid && "invalid"
                                      }`}
                                      placeholder=""
                                      value={newSAccountFunction}
                                      onChange={(e) => {
                                        const errorMsg = isNewAccountFunctionValid(
                                          false,
                                          e.target.value
                                        );

                                        if (errorMsg) {
                                          setIsNewSAccountFunctionValid(false);
                                          setErrorMsg(errorMsg);
                                        } else {
                                          setIsNewSAccountFunctionValid(true);
                                          setErrorMsg(null);
                                          setNewSAccountFunction(
                                            e.target.value
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="add-button ml-3">
                                    <button
                                      disabled={disabled}
                                      type="button"
                                      className="add-new-account-link d-inline-flex align-items-center"
                                      onClick={() => {
                                        const errorMsg = isNewAccountFunctionValid(
                                          false
                                        );

                                        if (errorMsg) {
                                          setIsNewSAccountFunctionValid(false);
                                          setErrorMsg(errorMsg);
                                        } else {
                                          setIsNewSAccountFunctionValid(true);
                                          addAccount(
                                            {
                                              id: uuidv4(),
                                              name: newSAccountFunction,
                                            },
                                            false
                                          );
                                          setNewSAccountFunction("");
                                        }
                                      }}
                                    >
                                      <img src="../images/coloredPlus.svg" />
                                      <p className="my-0 ml-2 p-0">
                                        Add Account
                                      </p>
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {formValues[`${PREFIX}/createoruseexisting`].value ===
                      "create" && (
                      <>
                        <div className="col-12 px-3 d-flex flex-column topOfMainContentArea py-1 my-1 specialAlignment">
                          <div className="d-inline-flex justify-content-between my-1 pl-3">
                            <h3 className="font-15 font-weight-bold mb-0 d-flex align-items-center mb-0">
                              {" "}
                              Shared Services Accounts
                            </h3>
                          </div>
                          <LongAccount3
                            paramPrefix={PREFIX}
                            accountName="sharedservices"
                            checkboxLabel="Shared Services"
                            mandatory={true}
                            defaultChecked={isSwitchOn("sharedservices")}
                            formValues={formValues}
                            updateValue={updateValue}
                            validate={validate}
                            makeChange={makeChange}
                            setErrorMsg={setErrorMsg}
                            updateSwitch={updateSwitch}
                            setIsDirty={setIsDirty}
                          ></LongAccount3>

                          {formValues[`${PREFIX}/sharedservicesaccountoption`]
                            .value === "dedicatedaccounts" && (
                            <>
                              <LongAccount3
                                paramPrefix={PREFIX}
                                accountName="loganalytics"
                                checkboxLabel="Log Analytics"
                                inputLabel="Account Id"
                                mandatory={false}
                                defaultChecked={isSwitchOn("loganalytics")}
                                formValues={formValues}
                                updateValue={updateValue}
                                validate={validate}
                                makeChange={makeChange}
                                setErrorMsg={setErrorMsg}
                                updateSwitch={updateSwitch}
                                setIsDirty={setIsDirty}
                              ></LongAccount3>
                              <LongAccount3
                                paramPrefix={PREFIX}
                                accountName="workspaces"
                                checkboxLabel="Workspaces (VDI)"
                                inputLabel="Account Id"
                                mandatory={false}
                                defaultChecked={isSwitchOn("workspaces")}
                                formValues={formValues}
                                updateValue={updateValue}
                                validate={validate}
                                makeChange={makeChange}
                                setErrorMsg={setErrorMsg}
                                updateSwitch={updateSwitch}
                                setIsDirty={setIsDirty}
                              ></LongAccount3>
                              <LongAccount3
                                paramPrefix={PREFIX}
                                accountName="releasemanagement"
                                checkboxLabel="Release Management"
                                inputLabel="Account Id"
                                mandatory={false}
                                defaultChecked={isSwitchOn("releasemanagement")}
                                formValues={formValues}
                                updateValue={updateValue}
                                validate={validate}
                                makeChange={makeChange}
                                setErrorMsg={setErrorMsg}
                                updateSwitch={updateSwitch}
                                setIsDirty={setIsDirty}
                              ></LongAccount3>
                              <LongAccount3
                                paramPrefix={PREFIX}
                                accountName="network"
                                checkboxLabel="Network"
                                inputLabel="Account Id"
                                mandatory={false}
                                defaultChecked={isSwitchOn("network")}
                                formValues={formValues}
                                updateValue={updateValue}
                                validate={validate}
                                makeChange={makeChange}
                                setErrorMsg={setErrorMsg}
                                updateSwitch={updateSwitch}
                                setIsDirty={setIsDirty}
                              ></LongAccount3>

                              {ssAccounts.map((item, idx) => (
                                <LongAccount3
                                  key={item.id}
                                  paramPrefix={PREFIX}
                                  accountName={item.name}
                                  checkboxLabel={item.name}
                                  remove={() => removeAccount(item.id)}
                                  newAccount={true}
                                  mandatory={false}
                                  defaultChecked={true}
                                  formValues={formValues}
                                  updateValue={updateValue}
                                  validate={validate}
                                  makeChange={makeChange}
                                  setErrorMsg={setErrorMsg}
                                  setIsDirty={setIsDirty}
                                ></LongAccount3>
                              ))}

                              <div className=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-start">
                                <div className="one form-line my-lg-0 my-1 d-inline-flex align-items-center">
                                  <span className="switchLabel">
                                    Account Function
                                  </span>
                                </div>
                                <div className="d-inline-flex my-lg-0 my-1 ml-3 form-line two">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      !isNewSsAccountFunctionValid && "invalid"
                                    }`}
                                    placeholder=""
                                    value={newSsAccountFunction}
                                    onChange={(e) => {
                                      const errorMsg = isNewAccountFunctionValid(
                                        true,
                                        e.target.value
                                      );

                                      if (errorMsg) {
                                        setIsNewSsAccountFunctionValid(false);
                                        setErrorMsg(errorMsg);
                                        return false;
                                      } else {
                                        setIsNewSsAccountFunctionValid(true);
                                        setErrorMsg(null);
                                        setNewSsAccountFunction(e.target.value);
                                      }
                                    }}
                                  />
                                </div>
                                <div className="add-button ml-3">
                                  <button
                                    disabled={disabled}
                                    type="button"
                                    className="add-new-account-link d-inline-flex align-items-center"
                                    onClick={() => {
                                      const errorMsg = isNewAccountFunctionValid(
                                        true
                                      );

                                      if (errorMsg) {
                                        setIsNewSsAccountFunctionValid(false);
                                        setErrorMsg(errorMsg);
                                      } else {
                                        setIsNewSsAccountFunctionValid(true);
                                        addAccount({
                                          id: uuidv4(),
                                          name: newSsAccountFunction,
                                        });
                                        setNewSsAccountFunction("");
                                      }
                                    }}
                                  >
                                    <img src="../images/coloredPlus.svg" />
                                    <p className="my-0 ml-2 p-0">Add Account</p>
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="d-flex col-md-12 col-12 px-0 mainContentArea my-1 py-1">
                          <div className="d-flex flex-column w-100 p-3 ">
                            <div className="d-inline-flex justify-content-between pl-0 my-1">
                              <h3 className="font-15 font-weight-bold mb-0">
                                {" "}
                                Security Accounts
                              </h3>
                            </div>

                            <LongAccount3
                              paramPrefix={PREFIX}
                              accountName="logarchive"
                              checkboxLabel="Log Archive"
                              inputLabel="Account Id"
                              mandatory={true}
                              defaultChecked={isSwitchOn("logarchive")}
                              formValues={formValues}
                              updateValue={updateValue}
                              validate={validate}
                              makeChange={makeChange}
                              setErrorMsg={setErrorMsg}
                              updateSwitch={updateSwitch}
                              setIsDirty={setIsDirty}
                            ></LongAccount3>
                            <LongAccount3
                              paramPrefix={PREFIX}
                              accountName="audit"
                              checkboxLabel="Audit"
                              inputLabel="Account Id"
                              mandatory={true}
                              defaultChecked={isSwitchOn("audit")}
                              formValues={formValues}
                              updateValue={updateValue}
                              validate={validate}
                              makeChange={makeChange}
                              setErrorMsg={setErrorMsg}
                              updateSwitch={updateSwitch}
                              setIsDirty={setIsDirty}
                            ></LongAccount3>
                            <LongAccount3
                              paramPrefix={PREFIX}
                              accountName="security"
                              checkboxLabel="Security"
                              inputLabel="Account Id"
                              mandatory={false}
                              defaultChecked={isSwitchOn("security")}
                              formValues={formValues}
                              updateValue={updateValue}
                              validate={validate}
                              makeChange={makeChange}
                              setErrorMsg={setErrorMsg}
                              updateSwitch={updateSwitch}
                              setIsDirty={setIsDirty}
                            ></LongAccount3>

                            {formValues[`${PREFIX}/sharedservicesaccountoption`]
                              .value === "dedicatedaccounts" && (
                              <>
                                {sAccounts.map((item, idx) => (
                                  <LongAccount3
                                    key={item.id}
                                    paramPrefix={PREFIX}
                                    accountName={item.name}
                                    checkboxLabel={item.name}
                                    remove={() => removeAccount(item.id, false)}
                                    newAccount={true}
                                    mandatory={false}
                                    defaultChecked={true}
                                    formValues={formValues}
                                    updateValue={updateValue}
                                    validate={validate}
                                    makeChange={makeChange}
                                    setErrorMsg={setErrorMsg}
                                    setIsDirty={setIsDirty}
                                  ></LongAccount3>
                                ))}
                                <div className=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-start">
                                  <div className="one form-line my-lg-0 my-1 d-inline-flex align-items-center">
                                    <span className="switchLabel">
                                      Account Function
                                    </span>
                                  </div>
                                  <div className="d-inline-flex my-lg-0 my-1 ml-3 form-line two">
                                    <input
                                      type="text"
                                      className={`form-control ${
                                        !isNewSAccountFunctionValid && "invalid"
                                      }`}
                                      placeholder=""
                                      value={newSAccountFunction}
                                      onChange={(e) => {
                                        const errorMsg = isNewAccountFunctionValid(
                                          false,
                                          e.target.value
                                        );

                                        if (errorMsg) {
                                          setIsNewSAccountFunctionValid(false);
                                          setErrorMsg(errorMsg);
                                        } else {
                                          setIsNewSAccountFunctionValid(true);
                                          setErrorMsg(null);
                                          setNewSAccountFunction(
                                            e.target.value
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="add-button ml-3">
                                    <button
                                      disabled={disabled}
                                      type="button"
                                      className="add-new-account-link d-inline-flex align-items-center"
                                      onClick={() => {
                                        const errorMsg = isNewAccountFunctionValid(
                                          false
                                        );

                                        if (errorMsg) {
                                          setIsNewSAccountFunctionValid(false);
                                          setErrorMsg(errorMsg);
                                        } else {
                                          setIsNewSAccountFunctionValid(true);
                                          addAccount(
                                            {
                                              id: uuidv4(),
                                              name: newSAccountFunction,
                                            },
                                            false
                                          );
                                          setNewSAccountFunction("");
                                        }
                                      }}
                                    >
                                      <img src="../images/coloredPlus.svg" />
                                      <p className="my-0 ml-2 p-0">
                                        Add Account
                                      </p>
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </fieldset>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={async () => {
                      if (isDirty) {
                        await submit();
                      }
                      history.push(ROUTES.ORGANIZATIONAL_STRUCTURE+"/"+projectId);
                    }}
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Organizational Structure
                    </p>
                  </button>
                
                  <button
                    className="btn-post btn-animation d-inline-flex"
                    type="button"
                    onClick={async () => {
                      if (isDirty) {
                        await submit();
                      }
                      history.push(
                        ROUTES.ENVIRONMENT_STRATEGY + "/" + projectId
                      );
                    }}
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Environment Strategy
                    </p>
                    <img alt="" src="/images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* {console.log(formValues)} */}
    </>
  );
};

export default PlatformAccounts;
