import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import axios from 'axios';

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "./DetailsTags";
import { getArrayValue } from "./DashboardHelper";
import DetailsGrid from "./DetailsGrid";

const dashboardName = "Firewalls";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
      <>
        <h3>Network Firewall</h3>
        <div className="otherTableTitles ">
          <h3 className="font-15 activeTable">Firewalls</h3>
          <h3 className="font-15">
            <Link className="text-color-gray" to={ROUTES.NETWORK_FIREWALL_POLICIES + "/" + projectId}>
             Policies
            </Link>
          </h3>
          <h3 className="font-15">
            <Link className="text-color-gray" to={ROUTES.NETWORK_FIREWALL_RULEGROUPS + "/" + projectId}>
            Rule Groups
            </Link>
          </h3>
        </div>
      </>
    );
  };

  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Firewall Endpoints" && "my_active"
                }`}
                onClick={() => setType("Firewall Endpoints")}
              >
                Firewall Endpoints
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Tags" && "my_active"
                }`}
                onClick={() => setType("Tags")}
              >
                Tags
              </li> 
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.FirewallName}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Description</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Description}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Firewall Status
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.FirewallStatus}
                  </p>
                </div>
  
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Associated Firewall Policy</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.FirewallPolicyArn}
                  </p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Associated VPC
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.VpcId}
                  </p>
                </div>
  
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Associated Subnets
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                     {
                       (selectedRow &&  selectedRow.SubnetMappings &&
                        selectedRow.SubnetMappings.map((subnets) =>
                          subnets.SubnetId)).join(', ')
                     }
                   
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Log type</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.LoggingConfigurations &&
                      selectedRow.LoggingConfigurations.LogType}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                   Log destination for alerts
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && selectedRow.LoggingConfigurations &&
                      selectedRow.LoggingConfigurations.LogDestination &&
                      selectedRow.LoggingConfigurations.LogGroup}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Log destination for flows
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && selectedRow.LoggingConfigurations &&
                      selectedRow.LoggingConfigurations.LogDestination &&
                      selectedRow.LoggingConfigurations.LogGroup}
                  </p>
                </div>
              </div>
            </div>

            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Firewall Endpoints" && "inaktif"
              }`}
            >
              <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Availability Zone</th>
                      <th>Firewall Subnet</th>
                      <th>Endpoint ID</th>
                      <th> Firewall Endpoint Status</th>
                    </tr>
                  </thead>
                  <tbody>
                     { 
                        firewallEndpoints(selectedRow)
                     }
                    
                  </tbody>
                </table>
              </div>
          
            </div>

            <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>

          </div>
        </div>
      </div>
    );
  };


  const firewallEndpoints = (selectedRow) => {
     let  content = [];
    for( var idx in selectedRow.SyncStates)
    {
      var obj = selectedRow.SyncStates[idx];
      content.push(
      <tr key={idx}>
      <td>{idx}</td>
      <td>{obj["Attachment"] && obj["Attachment"].SubnetId}</td>
      <td>{obj["Attachment"] && obj["Attachment"].EndpointId}</td>
      <td>{obj["Attachment"] && obj["Attachment"].Status}</td>
    </tr>
      );
    }
     return content;
  }

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.VPN + "/" + projectId}
      >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Hybrid Network</p>
      </Link>
      <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.TRAFFIC_MIRROR_TARGETS + "/" + projectId}
    >
      <p className="m-0 p-0 mr-2 font-weight-bold">Traffic Mirror Targets</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
    </>
    );
  };
  
  
const Firewalls = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "Name",
        accessor: "FirewallName"
      },
      {
        Header: "Description",
        accessor: "Description",
      },
      {
        Header: "Firewall Status",
        accessor: "FirewallStatus",
      },
      {
        Header: "Associated Firewall Policy",
        accessor: "FirewallPolicyArn",
      },
      {
        Header: "Associated VPC",
        id: "Associated VPC",
        accessor: "VpcId",
      },
      {
        Header: "Associated Subnets",
        id: "Associated Subnets",
        accessor: (d) => getArrayValue(d.SubnetMappings, 0, "SubnetId"),
      }
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "network",
    title: "Network",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_CENTERS,
    designRoute: ROUTES.DESIGN_NETWORK,
    SubMenu: SubMenu,
  });
};

export default Firewalls;