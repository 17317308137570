import React from "react";
// import "./editable-item.css";

const EditableItem = (props) => {
    const { title, changeTitle, removeNode, addChild, placeholder } = props;

    return (
        <div className="EditableItem">
        
            {addChild != undefined && (
            <button
               className="EditableItem-Button EditableItem-Button_add"
               onClick={(e) => {addChild(e)}}>
                +
            </button> )}

            {removeNode != undefined && (
            <button
              className="EditableItem-Button EditableItem-Button_remove" 
              onClick={(e) => removeNode(e)}>
              -
            </button> )}

            { (changeTitle === "readonly") ? (
            <input
              className="EditableItem-Text bg-special-lightblue border-10 "
              value={title}
              readOnly={true}
              placeholder=""
            /> ) :
            (<input
            className="EditableItem-Text bg-special-lightblue border-10 "
            onChange={(e) => { changeTitle(e.target.value) }}
            value={title}
            placeholder={placeholder}
          /> ) 
            }
        

        </div>
    );
}

export default EditableItem;