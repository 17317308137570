import React, { useState, useEffect } from "react";

import LeftHandMenu from "../LeftHandMenu";
import TopMenu from "../../TopMenu";

import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../hooks/useHideLeftMenu";
import DomainMenu from "../DomainMenu";

import * as ROUTES from "../../../constants/routes";
import { getError } from "../../../helpers/errorHelper";

const domains = [
  "ProjectLauncher",
  "AccountFramework",
  "IAM",
  "Network",
  "Security",
  "Infrastructure",
  "Governance",
  "Monitoring",
  "DataProtection",
  "DigitalAccelerators",
];

const Build = () => {
  const history = useHistory();
  const { projectId } = useParams();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [projectStatus, setProjectStatus] = useState(null);
  const [alertClass, setAlertClass] = useState("");

  const [buildData, setBuildData] = useState(null);

  const intervalObj = {};

  const awsAmplify = useAwsAmplify();

  const getClassName = (domain, key) => {
    if (buildData && buildData[domain] && buildData[domain][key]) {
      return `class-${buildData[domain][key]}`;
    } else {
      return "class-loading";
    }
  };

  const getProjectStatus = async () => {
    try {
      setErrorMsg(null);
      const result = await awsAmplify.getProjectStatus(projectId);

      if (result && result.data && result.data.getProjectStatus) {
        setProjectStatus(result.data.getProjectStatus);
        return result.data.getProjectStatus;
      }
    } catch (err) {
      setIsLoading(false);
      getError(err, setErrorMsg);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);

        //setIsLoading(true);

        const projectStatus = await getProjectStatus();

        //setIsLoading(false);

        const promises = domains.map(async (domain) => {
          const result = await awsAmplify.build(projectId, domain);

          if (
            projectStatus &&
            (projectStatus.status === "Launching" ||
              projectStatus.status === "Deploying")
          ) {
            for (const subdomain in result) {
              if (
                result[subdomain] === "running" ||
                result[subdomain] === "yettostart"
              ) {
                let handler = setInterval(async () => {
                  const result = await awsAmplify.build(projectId, domain);

                  console.log(intervalObj);
                  let allDone = true;

                  for (const subdomain in result) {
                    if (
                      result[subdomain] === "running" ||
                      result[subdomain] === "yettostart"
                    ) {
                      allDone = false;
                      break;
                    }
                  }

                  if (allDone && intervalObj[domain]) {
                    clearInterval(intervalObj[domain]);
                    delete intervalObj[domain];
                  }

                  setBuildData((state) => {
                    return { ...state, [domain]: result };
                  });
                }, 30000);

                if (!intervalObj[domain]) {
                  intervalObj[domain] = handler;
                }
                break;
              }
            }
          }

          setBuildData((state) => {
            return { ...state, [domain]: result };
          });
        });

        await Promise.all(promises);
      } catch (err) {
        //setIsLoading(false);
        const errorMsg = getError(err, setErrorMsg);

        if (errorMsg === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();

    return () => {
      for (const domain in intervalObj) {
        clearInterval(intervalObj[domain]);
      }
    };
  }, [projectId]);

  useEffect(() => {
    if (projectStatus && projectStatus.status) {
      switch (projectStatus.status) {
        case "Created":
        case "Saved":
          setAlertClass("bluealert");
          break;
        case "Launching":
        case "Deploying":
          setAlertClass("orangealert");
          break;
        case "Launched Successfully":
        case "Deployed Successfully":
          setAlertClass("greenalert");
          break;
        case "Launch Failed":
        case "Deployment Failed":
          setAlertClass("redalert");
          break;
        default:
          setAlertClass("");
          break;
      }
    }

    if (
      projectStatus &&
      (projectStatus.status === "Launching" ||
        projectStatus.status === "Deploying")
    ) {
      let handler = setInterval(getProjectStatus, 5000);

      return () => clearInterval(handler);
    }
  }, [projectStatus]);

  useHideLeftMenu();

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Build Status</div>
      <LeftHandMenu></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu></TopMenu>
            <div className="tableArea accountsTableArea aplicationTableArea container-fluid p-0 d-flex flex-column">
              <DomainMenu
                title="Build"
                isDashboard={false}
                isConfigure={false}
                isPrelaunch={false}
                isBuild={true}
              ></DomainMenu>

              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div className="row">
                  {projectStatus && (
                    <div className="status-bar">
                      <div className={alertClass}>
                        <div className="warnImg">
                          {alertClass === "orangealert" ? (
                            <img
                              className="rotating"
                              src="../images/rotating-arrow.svg"
                              alt="rotating arrow"
                            />
                          ) : (
                            <img src="../images/warning.svg" alt="warning" />
                          )}
                        </div>
                        <div className="messageText">
                          {`${projectStatus.status === "Deploying" ? `${projectStatus.status} Project` : `Project ${projectStatus.status}`}  on ${projectStatus.updatedOn}. `}
                          {projectStatus.message}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="mainContentArea preLaunchArea row px-2 py-2 mt-2">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-12 p-0">
                        <div className="col-md-6 col-12 d-flex flex-column">
                          <div className="row">
                            <div className="col-12 d-flex flex-column ">
                              <div className="build__box-head p-relative">
                                <div>
                                  <img
                                    src="../images/icons/icon-project-launcher.svg"
                                    alt=""
                                  ></img>
                                  <h3 className="font-15 px-3">
                                    Project Launcher
                                  </h3>
                                </div>
                                <div className="p-relative p-5 my-1 d-inline-flex align-items-center">
                                  <span
                                    className={getClassName(
                                      "ProjectLauncher",
                                      "ProjectLauncher"
                                    )}
                                  ></span>
                                </div>
                              </div>

                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "ProjectLauncher",
                                    "PreRequisites"
                                  )}
                                ></span>
                                <span className="font-12">Pre-requisites</span>
                                {/* <a
                                  href={`${ROUTES.PLATFORM_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Pre-requisites
                                </a> */}
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "ProjectLauncher",
                                    "CodeGeneration"
                                  )}
                                ></span>
                                <span className="font-12">Code Generation</span>
                                {/* <a
                                  href={`${ROUTES.APPLICATION_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Code Generation
                                </a> */}
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "ProjectLauncher",
                                    "CodeTransfer"
                                  )}
                                ></span>
                                <span className="font-12">Code Transfer</span>
                                {/* <a
                                  href={`${ROUTES.APPLICATION_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Code Transfer
                                </a> */}
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "ProjectLauncher",
                                    "Builder"
                                  )}
                                ></span>
                                <span className="font-12">
                                  Code Validation & Builder
                                </span>
                                {/* <a
                                  href={`${ROUTES.APPLICATION_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Builder
                                </a> */}
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "ProjectLauncher",
                                    "DeploymentOrchestration"
                                  )}
                                ></span>
                                <span className="font-12">
                                  Deployment Orchestration
                                </span>
                                {/* <a
                                  href={`${ROUTES.APPLICATION_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Deployment Orchestration
                                </a> */}
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "ProjectLauncher",
                                    "FoundationalResources"
                                  )}
                                ></span>
                                <span className="font-12">
                                  Foundational Resources
                                </span>
                                {/* <a
                                  href={`${ROUTES.APPLICATION_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Foundational Resources
                                </a> */}
                              </div>
                            </div>
                            <div className="col-12 d-flex flex-column">
                              <div className="build__box-head p-relative">
                                <div>
                                  <img
                                    src="../images/icons/icon-security.svg"
                                    alt=""
                                  ></img>
                                  <h3 className="font-15 px-3">Security</h3>
                                </div>
                                <div className="p-relative p-5 my-1 d-inline-flex align-items-center">
                                  <span
                                    className={getClassName(
                                      "Security",
                                      "Security"
                                    )}
                                  ></span>
                                </div>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Security",
                                    "ComplianceManagement"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.SECURITY_COMPLIANCE_MANAGEMENT}/${projectId}`}
                                  className="font-12"
                                >
                                  Compliance Management
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Security",
                                    "IntelligentThreatDetection"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.SECURITY_INTELLIGENT_THREAT_DETECTION}/${projectId}`}
                                  className="font-12"
                                >
                                  Intelligent Threat Detection
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Security",
                                    "VulnerabilityManagement"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.SECURITY_VULNERABILITY_MANAGEMENT}/${projectId}`}
                                  className="font-12"
                                >
                                  Vulnerability Management
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Security",
                                    "EndpointProtection"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.SECURITY_ENDPOINT_PROTECTION}/${projectId}`}
                                  className="font-12"
                                >
                                  Endpoint Protection
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Security",
                                    "SecretsManagement"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.SECURITY_SECRETS_MANAGEMENT}/${projectId}`}
                                  className="font-12"
                                >
                                  Secrets Management
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Security",
                                    "PerimeterProtection"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.SECURITY_PERIMETER_PROTECTION}/${projectId}`}
                                  className="font-12"
                                >
                                  Perimeter Protection
                                </a>
                              </div>

                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName("Security", "SIEM")}
                                ></span>
                                <a
                                  href={`${ROUTES.SECURITY_SIEM}/${projectId}`}
                                  className="font-12"
                                >
                                  SIEM
                                </a>
                              </div>
                            </div>
                            <div className="col-12 d-flex flex-column">
                              <div className="build__box-head p-relative">
                                <div>
                                  <img
                                    src="../images/icons/icon-digital.svg"
                                    alt=""
                                  ></img>
                                  <h3 className="font-15 px-3">Digital</h3>
                                </div>
                                <div className="p-relative p-5 my-1 d-inline-flex align-items-center">
                                  <span
                                    className={getClassName(
                                      "DigitalAccelerators",
                                      "DigitalAccelerators"
                                    )}
                                  ></span>
                                </div>
                              </div>

                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "DigitalAccelerators",
                                    "DeveloperPortal"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.DIGITAL_DEV_PORTAL}/${projectId}`}
                                  className="font-12"
                                >
                                  Developer Portal
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "DigitalAccelerators",
                                    "DeveloperTools"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.DIGITAL_DEV_TOOLS}/${projectId}`}
                                  className="font-12"
                                >
                                  Developer Tools
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "DigitalAccelerators",
                                    "StarterKits"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.DIGITAL_STARTER_KITS}/${projectId}`}
                                  className="font-12"
                                >
                                  Starter Kits
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-12 flex-column d-flex">
                          <div className="row">
                            <div className="col-12 d-flex flex-column ">
                              <div className="build__box-head p-relative">
                                <div>
                                  <img
                                    src="../images/icons/icon-account-framework.svg"
                                    alt=""
                                  ></img>
                                  <h3 className="font-15 px-3">
                                    Account Framework
                                  </h3>
                                </div>
                                <div className="p-relative p-5 my-1 d-inline-flex align-items-center">
                                  <span
                                    className={getClassName(
                                      "AccountFramework",
                                      "AccountFramework"
                                    )}
                                  ></span>
                                </div>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "AccountFramework",
                                    "PlatformAccounts"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.PLATFORM_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Platform Accounts
                                </a>
                              </div>
                              {/* <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "AccountFramework",
                                    "platformaccounts"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.PLATFORM_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Envionment Strategy
                                </a>
                              </div> */}
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "AccountFramework",
                                    "WorkloadAccounts"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.WORKLOAD_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                   Workload Accounts
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "AccountFramework",
                                    "SandboxAccounts"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.SANDBOX_ACCOUNTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Sandbox Accounts
                                </a>
                              </div>
                            </div>
                            <div className="col-12 d-flex flex-column">
                              <div className="build__box-head p-relative">
                                <div>
                                  <img
                                    src="../images/icons/icon-infrastructure.svg"
                                    alt=""
                                  ></img>
                                  <h3 className="font-15 px-3">
                                    Infrastructure
                                  </h3>
                                </div>
                                <div className="p-relative p-5 my-1 d-inline-flex align-items-center">
                                  <span
                                    className={getClassName(
                                      "Infrastructure",
                                      "Infrastructure"
                                    )}
                                  ></span>
                                </div>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Infrastructure",
                                    "ADIntegration"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.INFRASTRUCTURE_ADIntegration}/${projectId}`}
                                  className="font-12"
                                >
                                  Active Directory Integration
                                </a>
                              </div>

                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Infrastructure",
                                    "ImageManagement"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.INFRASTRUCTURE_IMAGEMANAGEMENT}/${projectId}`}
                                  className="font-12"
                                >
                                  Image Management
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Infrastructure",
                                    "PatchManagement"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.INFRASTRUCTURE_PATCHMANAGEMENT}/${projectId}`}
                                  className="font-12"
                                >
                                  Patch Management
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Infrastructure",
                                    "VDISolution"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.INFRASTRUCTURE_VDISOLUTION}/${projectId}`}
                                  className="font-12"
                                >
                                  VDI Solution
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Infrastructure",
                                    "ContainerPlatform"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.INFRASTRUCTURE_CONTAINER_PLATFORM}/${projectId}`}
                                  className="font-12"
                                >
                                  Container Platform
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Infrastructure",
                                    "APIGateway"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.INFRASTRUCTURE_API_GATEWAY}/${projectId}`}
                                  className="font-12"
                                >
                                  API Gateway
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Infrastructure",
                                    "SharedFileSystem"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.INFRASTRUCTURE_SHARED_FILE_SYSTEM}/${projectId}`}
                                  className="font-12"
                                >
                                  Shared File System
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Infrastructure",
                                    "SecureFileExchange"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.INFRASTRUCTURE_SECURE_FILE_EXCHANGE}/${projectId}`}
                                  className="font-12"
                                >
                                  Secure File Exchange
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Infrastructure",
                                    "EnterpriseServiceBus"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.INFRASTRUCTURE_ENTERPRISE_SERVICE_BUS}/${projectId}`}
                                  className="font-12"
                                >
                                  Enterprise Service Bus
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Infrastructure",
                                    "MessageQueue"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.INFRASTRUCTURE_MESSAGE_QUEUE}/${projectId}`}
                                  className="font-12"
                                >
                                  Message Queue
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Infrastructure",
                                    "DataLake"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.INFRASTRUCTURE_DATA_LAKE}/${projectId}`}
                                  className="font-12"
                                >
                                  Data Lake
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-12 p-0">
                        <div className="col-md-6 col-12 flex-column d-flex">
                          <div className="row">
                            <div className="col-12 d-flex flex-column">
                              <div className="build__box-head p-relative">
                                <div>
                                  <img
                                    src="../images/icons/icon-identity-access.svg"
                                    alt=""
                                  ></img>
                                  <h3 className="font-15 px-3">
                                    Identity & Access
                                  </h3>
                                </div>
                                <div className="p-relative p-5 my-1 d-inline-flex align-items-center">
                                  <span
                                    className={getClassName("IAM", "IAM")}
                                  ></span>
                                </div>
                              </div>

                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName("IAM", "SSO")}
                                ></span>
                                <a
                                  href={`${ROUTES.SINGLE_SIGN_ON}/${projectId}`}
                                  className="font-12"
                                >
                                  Single Sign-On
                                </a>
                              </div>

                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "IAM",
                                    "PAM"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.PRIVILEGED_ACCESS_MANAGEMENT}/${projectId}`}
                                  className="font-12"
                                >
                                  Privileged Access
                                </a>
                              </div>
                            </div>
                            <div className="col-12 d-flex flex-column">
                              <div className="build__box-head p-relative">
                                <div>
                                  <img
                                    src="../images/icons/icon-governance.svg"
                                    alt=""
                                  ></img>
                                  <h3 className="font-15 px-3">Governance</h3>
                                </div>
                                <div className="p-relative p-5 my-1 d-inline-flex align-items-center">
                                  <span
                                    className={getClassName(
                                      "Governance",
                                      "Governance"
                                    )}
                                  ></span>
                                </div>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Governance",
                                    "TaggingStrategy"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.GOVERNANCE_TAGGING_STRATEGY}/${projectId}`}
                                  className="font-12"
                                >
                                  Tagging Strategy
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Governance",
                                    "ServiceControlPolicies"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.GOVERNANCE_SERVICE_CONTROL_POLICIES}/${projectId}`}
                                  className="font-12"
                                >
                                  Service Contol Polices
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Governance",
                                    "ConfigRules"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.GOVERNANCE_CONFIG_RULES}/${projectId}`}
                                  className="font-12"
                                >
                                  Config Rules
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Governance",
                                    "BudgetEnforcement"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.GOVERNANCE_BUDGET_ENFORCEMENT}/${projectId}`}
                                  className="font-12"
                                >
                                  Budget Enforcement
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Governance",
                                    "AWSLimitsMonitor"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.GOVERNANCE_AWS_LIMITS_MONITOR}/${projectId}`}
                                  className="font-12"
                                >
                                  AWS Limits Monitor
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Governance",
                                    "ServiceNowIntegration"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.GOVERNANCE_SERVICENOW_INTEGRATION}/${projectId}`}
                                  className="font-12"
                                >
                                  ServiceNow Integration
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Governance",
                                    "FinOps"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.GOVERNANCE_FIN_OPS}/${projectId}`}
                                  className="font-12"
                                >
                                  FinOps
                                </a>
                              </div>
                            </div>
                            <div className="col-12 d-flex flex-column">
                              <div className="build__box-head p-relative">
                                <div>
                                  <img
                                    src="../images/icons/icon-monitoring.svg"
                                    alt=""
                                  ></img>
                                  <h3 className="font-15 px-3">Monitoring</h3>
                                </div>
                                <div className="p-relative p-5 my-1 d-inline-flex align-items-center">
                                  <span
                                    className={getClassName(
                                      "Monitoring",
                                      "Monitoring"
                                    )}
                                  ></span>
                                </div>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Monitoring",
                                    "CloudWatchEventRules"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.MONITORING_CLOUDWATCH_EVENTS}/${projectId}`}
                                  className="font-12"
                                >
                                  CloudWatch Event Rules
                                </a>
                              </div>

                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Monitoring",
                                    "CentralizedLogging"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.MONITORING_CENTRALIZED_LOGGING}/${projectId}`}
                                  className="font-12"
                                >
                                  Centralized Logging
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Monitoring",
                                    "Loganalytics"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.MONITORING_LOG_ANALYTICS}/${projectId}`}
                                  className="font-12"
                                >
                                  Log Analytics
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Monitoring",
                                    "MonitoringToolsIntegration"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.MONITORING_MONITORING_TOOLS_INTEGRATION}/${projectId}`}
                                  className="font-12"
                                >
                                  Monitoring Tools Integration
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-12 d-flex flex-column">
                          <div className="row">
                            <div className="col-12 d-flex flex-column">
                              <div className="build__box-head p-relative">
                                <div>
                                  <img
                                    src="../images/icons/icon-network.svg"
                                    alt=""
                                  ></img>
                                  <h3 className="font-15 px-3">Network</h3>
                                </div>
                                <div className="p-relative p-5 my-1 d-inline-flex align-items-center">
                                  <span
                                    className={getClassName(
                                      "Network",
                                      "Network"
                                    )}
                                  ></span>
                                </div>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Network",
                                    "InterVPCConnectivity"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.INTER_VPC_CONNECTIVITY}/${projectId}`}
                                  className="font-12"
                                >
                                  Inter VPC Connectivity
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Network",
                                    "VPCFactory"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.VPC_FACTORY}/${projectId}`}
                                  className="font-12"
                                >
                                  VPC Factory
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName("Network", "IPAM")}
                                ></span>
                                <a
                                  href={`${ROUTES.VPC_FACTORY}/${projectId}`}
                                  className="font-12"
                                >
                                  IPAM
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Network",
                                    "CrossRegionConnectivity"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.CROSS_REGION_CONNECTIVITY}/${projectId}`}
                                  className="font-12"
                                >
                                  Cross-region Connectivity
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Network",
                                    "VPNConnectivity"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.VPC_CONNECTIVITY}/${projectId}`}
                                  className="font-12"
                                >
                                  VPN Connectivity
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Network",
                                    "DirectConnect"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.DIRECT_CONNECT_CONNECTIVITY}/${projectId}`}
                                  className="font-12"
                                >
                                  DirectConnect
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Network",
                                    "TrafficInspection"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.TRAFFIC_INSPECTION}/${projectId}`}
                                  className="font-12"
                                >
                                  Traffic Inspection
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Network",
                                    "SharedVPCEndpoints"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.VPC_SHAREDENDPOINTS}/${projectId}`}
                                  className="font-12"
                                >
                                  Shared VPC Endpoints
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "Network",
                                    "PrivateDNS"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.INFRASTRUCTURE_PRIVATEDNS}/${projectId}`}
                                  className="font-12"
                                >
                                  Private DNS
                                </a>
                              </div>
                            </div>
                            <div className="col-12 d-flex flex-column">
                              <div className="build__box-head p-relative">
                                <div>
                                  <img
                                    src="../images/icons/icon-data-protection.svg"
                                    alt=""
                                  ></img>
                                  <h3 className="font-15 px-3">
                                    Data Protection
                                  </h3>
                                </div>
                                <div className="p-relative p-5 my-1 d-inline-flex align-items-center">
                                  <span
                                    className={getClassName(
                                      "DataProtection",
                                      "DataProtection"
                                    )}
                                  ></span>
                                </div>
                              </div>

                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "DataProtection",
                                    "Backup"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.DATA_PROTECTION_BACKUP_ARCHIVAL}/${projectId}`}
                                  className="font-12"
                                >
                                  Backup & Archival
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "DataProtection",
                                    "CertificateManagement"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.DATA_PROTECTION_CERTIFICATE_MANAGEMENT}/${projectId}`}
                                  className="font-12"
                                >
                                  Certificate Management
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "DataProtection",
                                    "KeyManagement"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.DATA_PROTECTION_KEY_MANAGEMENT}/${projectId}`}
                                  className="font-12"
                                >
                                  Key Management
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "DataProtection",
                                    "DataPrivacy"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.DATA_PROTECTION_DATA_PRIVACY}/${projectId}`}
                                  className="font-12"
                                >
                                  Data Privacy
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                  className={getClassName(
                                    "DataProtection",
                                    "DataLossPrevention"
                                  )}
                                ></span>
                                <a
                                  href={`${ROUTES.DATA_PROTECTION_DLP}/${projectId}`}
                                  className="font-12"
                                >
                                  Data Loss Prevention
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Build;
