import React, { useState, useEffect } from "react";

import LeftHandMenu from "../LeftHandMenu";
import TopMenu from "../../TopMenu";

import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../hooks/useHideLeftMenu";
import DomainMenu from "../DomainMenu";

import * as ROUTES from "../../../constants/routes";
import { getError } from "../../../helpers/errorHelper";

const features = [
  "Accounts",
  "OrganizationalUnits"
];

const GcpValidate = () => {
  const history = useHistory();
  const { projectId } = useParams();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [infoMsg, setInfoMsg] = useState(null);

  const [validateData, setValidateData] = useState(null);

  const awsAmplify = useAwsAmplify();

  const FeatureComponent = ({ feature, name, route }) => (
    <li style={{ width: "30px" }}>
      {validateData && validateData[feature] ? (
        validateData[feature] === "invalid" ? (
          <span className="decline"></span>
        ) : (
          <a
            style={{ cursor: "pointer" }}
            className={getClassName(feature)}
            onClick={() => {
              setInfoMsg(
                `Actual Count: ${validateData[feature].ActualCount},   Expected Count: ${validateData[feature].ExpectedCount}`
              );
            }}
          >
            {validateData[feature].ActualCount}
          </a>
        )
      ) : (
        <span className="refresh"></span>
      )}
      <a href={`${route}/${projectId}`}>{name}</a>
    </li>
  );

  const getClassName = (feature) => {
    if (validateData && validateData[feature]) {
      if (
        validateData[feature].ActualCount ===
        validateData[feature].ExpectedCount
      ) {
        return "green";
      } else if (
        validateData[feature].ActualCount < validateData[feature].ExpectedCount
      ) {
        return "red";
      } else {
        return "orange";
      }
    }

    return "refresh";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);

        const projectAccessResp = await awsAmplify.getProjectAccess(projectId);

        if(!projectAccessResp.data.getProjectAccess.hasDashboardAccess){
          history.goBack();
          return;
        }

        const promises = features.map(async (feature) => {
          let result = await awsAmplify.validate(projectId, feature);

          /* if (feature === "Accounts") {
            result = { [feature]: { ActualCount: 2 } };
          } */

          if (
            result &&
            result[feature] &&
            result[feature].ActualCount &&
            result[feature].ExpectedCount
          ) {
            setValidateData((state) => {
              return { ...state, [feature]: result[feature] };
            });
          } else {
            setValidateData((state) => {
              return { ...state, [feature]: "invalid" };
            });
          }
        });

        await Promise.all(promises);
      } catch (err) {
        const errorMsg = getError(err, setErrorMsg);

        if (errorMsg === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  useHideLeftMenu();

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}

      {infoMsg && (
        <div className="alert-area">
          <div className="greenalert">
            <span className="closebtn" onClick={() => setInfoMsg(null)}>
              &times;
            </span>
            <div className="messageText">{infoMsg}</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Validation</div>
      <LeftHandMenu isDashboard={true}></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu></TopMenu>
            <div className="tableArea accountsTableArea aplicationTableArea container-fluid p-0 d-flex flex-column">
              <DomainMenu
                title="Validation"
                isDashboard={true}
                isConfigure={false}
                isPrelaunch={false}
                designRoute={ROUTES.DESIGN_ACCOUNT_FRAMEWORK}
                configureRoute={ROUTES.CONFIGURE}
              ></DomainMenu>

              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div className="dashboard__wrapper">
                  <div className="validate-dashboard__block-wrapper">
                    <div>
                      <div className="validate-dashboard__block-row">
                        <div>
                          <div className="validate-dashboard__block">
                            <div className="validate-dashboard__block-header">
                              <div>
                                <p>Account Framework</p>
                              </div>
                              {/* <div>
                                <span className="success"></span>
                              </div> */}
                            </div>
                            <div className="validate-dashboard__block-body">
                              <div className="validate-dashboard__box">
                                <div className="validate-dashboard__box-body is-2">
                                  <ul>
                                    <FeatureComponent
                                      feature="Accounts"
                                      name="Accounts"
                                      route={ROUTES.GCP_ACCOUNTS}
                                    ></FeatureComponent>
                                    <FeatureComponent
                                      feature="OrganizationalUnits"
                                      name="OUs"
                                      route={ROUTES.GCP_ORGANIZATIONAL_UNITS}
                                    ></FeatureComponent>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {console.log(validateData)}
      </div>
    </>
  );
};

export default GcpValidate;
