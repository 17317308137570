import * as ROUTES from "../constants/routes";

export const getDefaultRoute = (projectType) => {
  let route = ROUTES.HOME;

  switch (projectType.toLowerCase()) {
    case "aws":
      route = ROUTES.CONFIGURE;
      break;
    case "gcp":
      route = ROUTES.GCP_CONFIGURE;
      break;
    case "alicloud":
      route = ROUTES.ALICLOUD_CONFIGURE;
      break;
    case "azure":
      route = ROUTES.AZURE_CONFIGURE;
      break;
    default:
      break;
  }

  return route;
};

export const getPrelaunchRoute = (projectType) => {
  let route = ROUTES.HOME;

  switch (projectType.toLowerCase()) {
    case "aws":
      route = ROUTES.PRELAUNCH;
      break;
    case "gcp":
      route = ROUTES.GCP_PRELAUNCH;
      break;
    case "alicloud":
      route = ROUTES.ALICLOUD_PRELAUNCH;
      break;
    case "azure":
      route = ROUTES.AZURE_PRELAUNCH;
      break;
    default:
      break;
  }

  return route;
};

export const getConfigureRoute = (projectType) => {
  let route = ROUTES.HOME;

  switch (projectType.toLowerCase()) {
    case "aws":
      route = ROUTES.CONFIGURE;
      break;
    case "gcp":
      route = ROUTES.GCP_CONFIGURE;
      break;
    case "alicloud":
      route = ROUTES.ALICLOUD_CONFIGURE;
      break;
    case "azure":
      route = ROUTES.AZURE_CONFIGURE;
      break;
    default:
      break;
  }

  return route;
};

export const getBuildRoute = (projectType) => {
  let route = ROUTES.HOME;

  switch (projectType.toLowerCase()) {
    case "aws":
      route = ROUTES.BUILD;
      break;
    case "gcp":
      route = ROUTES.GCP_BUILD;
      break;
    case "alicloud":
      route = ROUTES.ALICLOUD_BUILD;
      break;
    case "azure":
      route = ROUTES.AZURE_BUILD;
      break;
    default:
      break;
  }

  return route;
};

export const getPlanRoute = (projectType) => {
  let route = ROUTES.HOME;

  switch (projectType.toLowerCase()) {
    case "aws":
      route = ROUTES.PLAN;
      break;
    case "gcp":
      route = ROUTES.GCP_PLAN;
      break;
    case "alicloud":
      route = ROUTES.ALICLOUD_PLAN;
      break;
    case "azure":
      route = ROUTES.AZURE_PLAN;
      break;
    default:
      break;
  }

  return route;
};

export const getValidateRoute = (projectType) => {
  let route = ROUTES.HOME;

  switch (projectType.toLowerCase()) {
    case "aws":
      route = ROUTES.VALIDATE;
      break;
    case "gcp":
      route = ROUTES.GCP_VALIDATE;
      break;
    case "alicloud":
      route = ROUTES.ALICLOUD_VALIDATE;
      break;
    case "azure":
      route = ROUTES.AZURE_VALIDATE;
      break;
    default:
      break;
  }

  return route;
};

export const getDesignRoute = (projectType) => {
  let route = ROUTES.HOME;

  switch (projectType.toLowerCase()) {
    case "aws":
      route = ROUTES.DESIGN;
      break;
    case "gcp":
      route = ROUTES.GCP_DESIGN;
      break;
    case "alicloud":
      route = ROUTES.ALICLOUD_DESIGN;
      break;
    case "azure":
      route = ROUTES.AZURE_DESIGN;
      break;
    default:
      break;
  }

  return route;
};


