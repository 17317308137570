



import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getBooleanValue } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "Workspaces";  //To do:  mapping pending

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
        <>
          <h3>Workspaces</h3>
          <div className="otherTableTitles ">
          <h3 className="font-15 activetable">   
              Workspaces       
            </h3>
            <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_IP_ACCESS_CONTROLS + "/" + projectId}>  
              IP Access Controls
            </Link>   
            </h3>           
          </div>
        </>
      );
  };

  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
             </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
               <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">

                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">User Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Workspace Id</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Directory Id</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">IP Address</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Computer Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Registration Code</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Running Mode</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">State</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {}
                  </p>
                </div>
              </div>

              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                    <p className="m-0 p-0 account-detail-subtitle">
                        Bundle Id
                    </p>
                    <p className="m-0 p-0 account-title-subcontent">
                        {}
                    </p>
                 </div>
                <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                    Subnet Id
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                    {}
                </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                    Running Mode AutoStop Timeout in Minutes
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                {}
                </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                     Root Volume Size in GB
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                    {}
                </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                    User Volume Size in GB
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                    {}
                </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                Compute Type
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                    {}
                </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                Connection State
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                    {}
                </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                Connection StateCheck Timestamp
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                    {}
                </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                Last Known User Connection Timestamp
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                    {}
                </p>
                </div>
                </div>
            </div>
            </div>
          </div>
        </div>
    );
  };

  function Navigations() {
  const { projectId } = useParams();
  return (
    <>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.INFRASTRUCTURE_COMPONENTS + "/" + projectId}>
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Image Builder</p>
      </Link>

    
    </>
  );
}

const Workspaces = () => {
    const columns = useMemo(
        () => [
          {
            Header: "Account ID",
            accessor: "AccountId",
          },
          {
            Header: "Region",
            accessor: "Region",
          },
          {
            Header: "User Name",
            accessor: "",
            id: "User Name"
          },
          {
            Header: "Workspace Id",
            accessor: "",
            id: "Workspace Id"
          },
          {
            Header: "Directory Id",
            accessor: "",
            id: "Directory Id"
          },
          {
            Header: "IP Address",
            accessor: "",
            id: "IP Address"
          },
          {
            Header: "Computer Name",
            accessor: "",
            id: "Computer Name"
          },
          {
            Header: "Registration Code",
            accessor: "",
            id: "Registration Code"
          },
          {
            Header: "Running Mode",
            accessor: "",
            id: "Running Mode"
          },
          {
            Header: "State",
            accessor: "",
            id: "State"
          },
          {
            Header: "Connection State",
            accessor: "",
            id: "Connection State"
          }
        ],
        []
      );

    return Dashboard({
        domain: "infrastructure",
        title: "Infrastructure",
        dashboardName,
        columns,
        Details,
        Navigations,
        configureRoute: ROUTES.INFRASTRUCTURE_VDISOLUTION,
        designRoute: ROUTES.DESIGN_INFRASTRUCTURE,
        SubMenu: SubMenu,
      });
};

export default Workspaces;