import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HomeHeader from "./HomeHeader";
import HomeFooter from "./HomeFooter";

const Contact = () => {
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
    {error && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setError(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong>
              {Array.isArray(error)
                ? error.map((em, idx) => <span key={idx}>{em}</span>)
                : error}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}

{info && (
        <div className="alert-area">
          <div className="bluealert">
<div className="messageText">{info}</div>
          </div>
        </div>
      )}
      <div className="body-hero">
        <HomeHeader setError={setError} setIsLoading={setIsLoading}></HomeHeader>

        <section className="banner half">
          <div id="tsparticles"></div>
          <img className="bg" src="images/materials/bg03.jpg" alt="" />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h1>Welcome to Epic9</h1>
                <h4>
                  Everything we do at Epic9 is about Customer Delight and
                  delivering the promise of NextGen technologies
                </h4>

                <div className="banner-box">
                  <h2>About Epic9</h2>
                  <p>
                    We are a solution-led Cloud consulting company driven by a
                    passion for delivering the “Faster, Better, Cheaper” promise
                    of Cloud, Automation, and NextGen technologies. We build
                    innovative technology solutions to address today’s
                    enterprise challenges and to put our clients on a
                    transformation journey to become the enterprise of the
                    future. We take next gen technologies and turn them in to
                    solutions for your enterprise. Our consultants have deep
                    subject matter expertise in Cloud, Automation, DevOps and
                    Open Source technologies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <main className="inner-page">
          <section className="contact">
            <div className="maps">
              <div className="info row">
                <div className="col-lg-12">
                  <h5>We appreciate your interest in Epic9</h5>
                  <p>
                    Delighted customers are the reason for our existence.
                    Responsiveness and fanatic customer service are guaranteed.
                  </p>
                </div>
                <div className="col-6">
                  <address>
                    100 Overlook Center, <br />
                    2 nd Floor, <br />
                    Princeton, NJ 08540, <br />
                    USA
                  </address>
                  <span>Directions</span>
                  <a className="link" href="mailto:info@fabecit.com">
                    info@fabecit.com
                  </a>
                  <a className="link" href="tel:+1 (732) 328-9119">
                    +1 (732) 328-9119
                  </a>
                </div>
                <div className="col-6">
                  <address>
                    Mytri Square, 2-41/11, 6/2, Gachibowli - Miyapur Road,
                    Prashanth Nagar Colony, Hyderabad, Telangana 500084, India
                  </address>
                </div>
              </div>
              <img src="images/materials/maps.png" alt="" className="bg" />
            </div>

            <div className="boxs container">
              <div className="row">
                <div className="col-lg-4">
                  <a href="#demoForm" className="box demoButton">
                    <img src="images/icons/i01.svg" alt="" />
                    <h4>Request A Demo</h4>
                    <p>
                      Request a demonstration of our BuiltRight Cloud Foundation
                      and HeadStart Digital Accelerator.
                    </p>
                  </a>
                </div>
                <div className="col-lg-4">
                  <a href="#demoForm" className="box demoButton">
                    <img src="images/icons/i02.svg" alt="" />
                    <h4>Sales Inquiry</h4>
                    <p>
                      Reach out to our sales team directly for immediate
                      assistance with all sales related inquires.
                    </p>
                  </a>
                </div>
                <div className="col-lg-4">
                  <a className="box signClick">
                    <img src="images/icons/i03.svg" alt="" />
                    <h4>Sign up</h4>
                    <p>
                      Register for access to BuiltRight Cloud Foundation and
                      HeadStart Digital Accelerator products
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </main>
        <HomeFooter setError={setError} setIsLoading={setIsLoading} setInfo={setInfo}></HomeFooter>
      </div>
    </>
  );
};

export default Contact;
