import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import * as ROUTES from "../constants/routes";
import useAwsAmplify from "../hooks/useAwsAmplify";
import NewPasswordRequiredForm from "./NewPasswordRequiredForm";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const SignInForm = ({
  setState,
  setAuthStateChanged,
  setError,
  setIsLoading,
  setInfo
}) => {
  const awsAmplify = useAwsAmplify();

  const [showPwd, setShowPwd] = useState(false);
  const { register, handleSubmit, formState, errors } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const [tempAuthUser, setTempAuthUser] = useState(null);

  const onSubmit = async (formValues) => {
    setIsLoading(true);
    setError("");

    try {
      const signInResults = await awsAmplify.signIn(
        formValues.email,
        formValues.password
      );

      console.log(signInResults);
      setIsLoading(false);

      if (signInResults.challengeName === "NEW_PASSWORD_REQUIRED") {
        setInfo("Change Password");
        setTempAuthUser(signInResults);

        setTimeout(() => setInfo(""), 2000);
      } else {
        setAuthStateChanged("SIGNINSUCCESSFUL");
      }
    } catch (err) {
      setIsLoading(false);

      if (err.code === "UserNotConfirmedException") {
        setError("Confirm Registration");
        setState("CONFIRMSIGNUP");
      } else if (err.code === "UserNotFoundException") {
        setError("User not registered. Please register");

        //setTimeout(() => setError(null), 3000);

        setState("SIGNUP");
      } else if (
        err.code === "NotAuthorizedException" &&
        err.message === "Incorrect username or password."
      ) {
        setError("Incorrect Password");
      } else {
        if (err.message) {
          setError(err.message);
        }
      }
    }
  };

  useEffect(() => {
    const errorMsgs = [];

    if(errors){
      for(const field in errors){
        if(errors[field].message){
          errorMsgs.push(errors[field].message);
        }
      }
    }

    if (errorMsgs.length > 0) {
      setError(errorMsgs);
    }
  }, [errors.email, errors.password]);

  return (
    <>
      {tempAuthUser ? (
        <NewPasswordRequiredForm
          tempAuthUser={tempAuthUser}
          setState={setState}
          setAuthStateChanged={setAuthStateChanged}
          setError={setError}
          setIsLoading={setIsLoading}
          setTempAuthUser={setTempAuthUser}
        ></NewPasswordRequiredForm>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="email" 
              className={errors.email && "highlight"}>
            <img src="images/icons/mail.svg" alt="" />
            <input
              type="text"
              placeholder="Email Address"
              name="email"
              ref={register}
            />
          </label>
          <label htmlFor="#" 
              className={errors.password && "highlight"}>
            <img src="images/icons/lock.svg" alt="" />
            <input
              type={showPwd ? "text" : "password"}
              className={errors.password && "invalid"}
              placeholder="Password"
              name="password"
              ref={register}
            />
            <img
              className="toggle-password"
              src="images/icons/eye.svg"
              alt=""
              onClick={() => setShowPwd(!showPwd)}
            />
          </label>

          <div className="forgot-password">
            Forgot your password?{" "}
            <button 
            style={{color: "#3D9CD1"}} type="button" onClick={() => setState("RESETPWD")}>
              Reset password
            </button>
          </div>

          <button type="submit" className="button">
            Sign in
          </button>
        </form>
      )}
      {console.log(errors)}
    </>
  );
};

export default SignInForm;
