import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "ACM Certificates";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
      <>
        <h3>ACM Certificates</h3>
      </>
    );
  };

  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Domain Validation" && "my_active"
                }`}
                onClick={() => setType("Domain Validation")}
              >
                Domain Validation
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "In Use By" && "my_active"
                }`}
                onClick={() => setType("In Use By")}
              >
                In Use By
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Tags" && "my_active"
                }`}
                onClick={() => setType("Tags")}
              >
                Tags
              </li> 
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Domain Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Certificate && selectedRow.Certificate.DomainName}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Status</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Certificate && selectedRow.Certificate.Status}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Issuer
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Certificate && selectedRow.Certificate.Issuer}
                  </p>
                </div>
  
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Type</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Certificate && selectedRow.Certificate.Type}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Certificate ARN</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Certificate && selectedRow.Certificate.CertificateArn}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Renewal Eligibility</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Certificate && selectedRow.Certificate.RenewalEligibility}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Subject Alternative Names</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    <ul>
                    {selectedRow.Certificate && selectedRow.Certificate.SubjectAlternativeNames.map((item) =>
                    <li key = {item.toString()}>{item}</li>)}
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Serial
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Certificate && selectedRow.Certificate.Serial}
                  </p>
                </div>
  
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Subject
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Certificate && selectedRow.Certificate.Subject}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Created At</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Certificate && selectedRow.Certificate.CreatedAt}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                   Issued At
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Certificate && selectedRow.Certificate.IssuedAt}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                   Not Before
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    { selectedRow.Certificate && selectedRow.Certificate.NotBefore}
                  </p>
                </div>
             
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Not After
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    { selectedRow.Certificate && selectedRow.Certificate.NotAfter}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Key Algorithm
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    { selectedRow.Certificate && selectedRow.Certificate.KeyAlgorithm}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Certificate Transparency Logging Preference
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Certificate && selectedRow.Certificate.Options && selectedRow.Certificate.Options.CertificateTransparencyLoggingPreference}
                  </p>
                </div>
             
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Signature Algorithm
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    { selectedRow.Certificate && selectedRow.Certificate.SignatureAlgorithm}
                  </p>
                </div>
             
             
             
              </div>
            </div>

            <DetailsGrid
              type={type}
              typeName="Domain Validation"
              array={selectedRow.Certificate.DomainValidationOptions}
              metadata={[
                { header: "Domain Name", key: "DomainName" },
                { header: "Validation Domain", key: "ValidationDomain" },
                { header: "Validation Method", key: "ValidationMethod" },
                { header: "Validation Status", key: "ValidationStatus" },
                { header: "Resource Record Name", key: "ResourceRecord.Name" }, 
                { header: "Resource Record Type", key: "ResourceRecord.Type" }, 
                { header: "Resource Record Value", key: "ResourceRecord.Value" }            
              ]}
            ></DetailsGrid>

          <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "In Use By" && "inaktif"
              }`}
            >
              <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Associated Resources</th>
                    </tr>
                  </thead>
                  <tbody>
                  {selectedRow.Certificate &&
                  selectedRow.Certificate.InUseBy.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item}</td>
                    </tr>
                  ))}  
                  </tbody>
                </table>

                <table className="details-table">
                  <thead>
                      <tr><th>Key Usages</th></tr>
                  </thead>
                  <tbody>
                  {selectedRow.Certificate && selectedRow.Certificate.KeyUsages &&
                  selectedRow.Certificate.KeyUsages.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item.Name}</td>
                    </tr>
                  ))}  
                  </tbody>
                </table>

                <table className="details-table">
                  <thead>
                      <tr> 
                        <th>Extended Key Usages - Name</th>
                        <th>Extended Key Usages - OID</th> 
                      </tr>
                  </thead>
                  <tbody>
                  {selectedRow.Certificate && selectedRow.Certificate.ExtendedKeyUsages.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item.Name}</td>
                      <td>{item.OID}</td>
                    </tr>
                  ))}  
                  </tbody>
                </table>
              </div>
            </div>

            <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>

          </div>
        </div>
      </div>
    );
  };

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.DATA_PROTECTION_BACKUP_PLAN + "/" + projectId}
      >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Backup & Archival</p>       
      </Link>

      <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.DATA_PROTECTION_KEYS + "/" + projectId}
    >
      <p className="m-0 p-0 ml-2 font-weight-bold">Key Management</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
      </>
    );
  };
  
  
const ACMCertificates = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "Domain Name",
        accessor: "Certificate.DomainName",
        id: "Domain Name"
      },
      {
        Header: "Status",
        accessor: "Certificate.Status",
        id: "Status"
      },
      {
        Header: "Type",
        accessor: "Certificate.Type",
        id: "Type"
      },
      {
        Header: "Certificate ARN",
        accessor: "Certificate.CertificateArn",
        id: "Certificate ARN"
      }
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "dataprotection",
    title: "Data Protection",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_PROTECTION_CERTIFICATE_MANAGEMENT,
    designRoute: ROUTES.DESIGN_DATA_PROTECTION,
    SubMenu: SubMenu,
  });
};

export default ACMCertificates;