
import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getBooleanValue, getDateValue } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "Directories";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
        <>
          <h3>Directories</h3>
          <div className="otherTableTitles ">
            <h3 className="font-15 activetable">  
              Directories      
            </h3>
            <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_SHARED_WITH_ME + "/" + projectId}>  
               Shared with me
            </Link>          
            </h3>
          </div>
        </>
      );
  };

  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "DNS IP Addresses" && "my_active"
                }`}
                onClick={() => setType("DNS IP Addresses")}
              >
               DNS IP Addresses
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "VPC Settings" && "my_active"
                }`}
                onClick={() => setType("VPC Settings")}
              >
                VPC Settings
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Connector/Trust Relationship" && "my_active"
                }`}
                onClick={() => setType("Connector/Trust Relationship")}
              >
                Connector/Trust Relationship
              </li> 
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Directory Sharing" && "my_active"
                }`}
                onClick={() => setType("Directory Sharing")}
              >
                Directory Sharing
              </li> 
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Directory ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.DirectoryId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Directory Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.Name}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Type
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.Type}
                  </p>
                </div>
  
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Size</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.Size}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Launch Date</p>
                  <p className="m-0 p-0 account-title-subcontent">
                   {selectedRow && selectedRow.LaunchTime} 
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  SSO Enabled
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                   {getBooleanValue(selectedRow, "SsoEnabled")}
                  </p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Directory NetBIOS Name
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.ShortName}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Edition</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Edition}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                   Alias
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Alias}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                   Access URL
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    { selectedRow.AccessUrl}
                  </p>
                </div>
             
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                   Stage
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    { selectedRow.Stage}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Stage Last Updated
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    { selectedRow.StageLastUpdatedDateTime}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Desired # of Domain Controllers
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    { selectedRow.DesiredNumberOfDomainControllers}
                  </p>
                </div>
              </div>
            </div>

            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "DNS IP Addresses" && "inaktif"
              }`}
            >
              <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>DNS IP Addresses</th>
                    </tr>
                  </thead>
                  <tbody>
                    { selectedRow && selectedRow.OwnerDirectoryDescription && 
                    selectedRow.OwnerDirectoryDescription.DnsIpAddrs
                     && selectedRow.OwnerDirectoryDescription.DnsIpAddrs.map( (item, idx) => (
                    <tr key={idx}>
                       <td>{item}</td>
                     </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "VPC Settings" && "inaktif"
              }`}
            >
            <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">VPC ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.OwnerDirectoryDescription && selectedRow.OwnerDirectoryDescription.VpcSettings 
                    && selectedRow.OwnerDirectoryDescription.VpcSettings.VpcId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Secruity Group Id</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.OwnerDirectoryDescription && selectedRow.OwnerDirectoryDescription.VpcSettings 
                     && selectedRow.OwnerDirectoryDescription.VpcSettings.SecruityGroupId}
                  </p>
                </div>
              </div>

              <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Subnets</th>
                      <th>Availability Zones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{selectedRow && selectedRow.OwnerDirectoryDescription
                         && selectedRow.OwnerDirectoryDescription.VpcSettings &&
                      selectedRow.OwnerDirectoryDescription.VpcSettings.SubnetIds}</td>
                      <td>{selectedRow && selectedRow.OwnerDirectoryDescription && 
                      selectedRow.OwnerDirectoryDescription.VpcSettings && 
                      selectedRow.OwnerDirectoryDescription.VpcSettings.AvailabilityZones}</td>
                    </tr> 
                  </tbody>
                </table>
              </div>
             
            
            </div>

            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Connector/Trust Relationship" && "inaktif"
              }`}
            >
                <h3 className="font-15 font-bold px-1">Connector Details</h3>
                <div  className="d-inline-flex px-2">
                   <div className="detailTableType-1">
                    <div  className="d-flex flex-column">
                      <p className="account-detail-subtitle-1">
                          Customer User Name
                      </p>
                    </div>
                        
                    <div  className="d-flex flex-column">
                        <p className="account-title-subcontent">
                           {selectedRow.Name}
                        </p>
                      </div>
                    </div>
                </div>
              <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Connector Ips</th>
                    </tr>
                  </thead>
                  <tbody>
                  {selectedRow &&  selectedRow.ConnectSettings &&
                  selectedRow.ConnectSettings.ConnectIps.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item}</td>
                    </tr>
                  ))}  
                  </tbody>
                </table>
              </div>
              <div className="d-flex flex-column">
              <h3 class="font-15 font-bold px-1 mt-2">Trust Relationships</h3>
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Domain Name</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Direction</th>
                      <th>Selective Auth</th>
                      <th>Conditional Forwarder IPs</th>
                      <th>Last Verified</th>
                    </tr>
                  </thead>
                  <tbody>
                  {selectedRow && selectedRow["TrustRelationship"] &&
                  selectedRow["TrustRelationship"].map((item, idx) => (
                    <tr key={idx}>
                      <td>{item.RemoteDomainName}</td>
                      <td>{item.TrustType}</td>
                      <td>{item.TrustState}</td>
                      <td>{item.TrustDirection}</td>
                      <td>{item.SelectiveAuth}</td>
                      <td>{item.IPs/* TODO */}</td>
                      <td>{item.LastUpdatedDateTime}</td>
                    </tr>
                  ))}  
                  </tbody>
                </table>
              </div>
          </div>

          <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Directory Sharing" && "inaktif"
              }`}
            >
              <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Owner Account ID</th>
                      <th>Owner Directory ID</th>
                      <th>Shared Directory ID</th>
                      <th>Shared Account ID</th>
                      <th>Shared Method</th>
                      <th>Date Shared</th>
                    </tr>
                  </thead>
                  <tbody>
                    { selectedRow && selectedRow.Sharing && 
                      selectedRow.Sharing.map( (item, idx) => (
                    <tr key={idx}>
                       <td>{item.OwnerAccountId}</td>
                       <td>{item.OwnerDirectoryId}</td>
                       <td>{item.SharedDirectoryId}</td>
                       <td>{item.SharedAccountId}</td>
                       <td>{item.ShareMethod}</td>
                       <td>{item.CreatedDateTime}</td>
                     </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            </div>
   

          </div>
        </div>
    );
  };

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
      <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.INFRASTRUCTURE_INBOUND_ENDPOINTS + "/" + projectId}
    >
      <p className="m-0 p-0 ml-2 font-weight-bold">Private DNS</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
      </>
    );
  };
  
const Directories = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "Directory ID",
        accessor: "DirectoryId",
        id: "Directory ID"
      },
      {
        Header: "Directory Name",
        accessor: "Name",
        id: "Directory Name"
      },
      {
        Header: "Type",
        accessor: "Type",
        id: "Type"
      },
      {
        Header: "Size",
        accessor: "Size",
        id: "Size"
      },
      {
        Header: "Launch Date",
        accessor: (d) => getDateValue(d.LaunchTime,""),
        id: "Launch Date"
      },
      {
        Header: "SSO Enabled",
        accessor:  (d) => getBooleanValue(d,"SsoEnabled"),
        id: "SSO Enabled"   
      }
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "infrastructure",
    title: "Infrastructure",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.INFRASTRUCTURE_ADIntegration,
    designRoute: ROUTES.DESIGN_INFRASTRUCTURE,
    SubMenu: SubMenu,
  });
};

export default Directories;