import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import * as ROUTES from "../constants/routes";
import useAwsAmplify from "../hooks/useAwsAmplify";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  password: yup
    .string()
    .matches(
      ///^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/,
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&,><’:;|_~`])\S{8,99}$/,
      "password needs minimum 8 (upper, lower, special char and number)"
    ),
});

const SignUpForm = ({ setState, setError, setIsLoading }) => {
  const awsAmplify = useAwsAmplify();
  const history = useHistory();
  const location = useLocation();

  let msg;

  if (location.search) {
    const params = new URLSearchParams(location.search);

    if (params.get("msg")) {
      switch (params.get("msg")) {
        case "notRegistered":
          msg = "User not registered. Please register";
          break;
        default:
          msg = null;
          break;
      }
    }
  }

  const [showPwd, setShowPwd] = useState(false);

  const { register, handleSubmit, formState, errors } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = async (formValues) => {
    setIsLoading(true);
    setError("");

    console.log(formState);

    console.log(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/.test(formValues.password));

    try {
      await awsAmplify.signUp(
        formValues.email,
        formValues.password,
        formValues.firstName,
        formValues.lastName
      );
      setIsLoading(false);

      setState("CONFIRMSIGNUP");
    } catch (err) {
      console.log(err);

      setIsLoading(false);

      if (err.code === "UsernameExistsException") {
        setError("Already registered. Please sign in");

        setState("SIGNIN");
      } /* else if (err.code === "UserLambdaValidationException") {
        setError(err.message);
        //setTimeout(() => setError(null), 3000);

        setState("CONFIRMSIGNUP");
        //setError(err.message.split(":")[1]);
      } */ else {
        if (err.message) {
          if (err.message.indexOf("PreSignUp failed with error") !== -1) {
            const errorMessageArray = err.message.split(":");
            if (errorMessageArray.length > 1) {
              setError(errorMessageArray[1]);
            }
          } else {
            setError(err.message);
          }
        }
      }
    }
  };

  useEffect(() => {
    const errorMsgs = [];

    if (errors) {
      for (const field in errors) {
        if (errors[field].message) {
          errorMsgs.push(errors[field].message);
        }
      }
    }

    if (errorMsgs.length > 0) {
      setError(errorMsgs);
    }
  }, [errors.firstName, errors.lastName, errors.email, errors.password]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="#" className={errors.firstName && "highlight"}>
          <img src="images/icons/user.svg" alt="" />
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            ref={register}
          />
        </label>
        <label htmlFor="#" className={errors.lastName && "highlight"}>
          <img src="images/icons/user.svg" alt="" />
          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            ref={register}
          />
        </label>
        <label htmlFor="#" className={errors.email && "highlight"}>
          <img src="images/icons/mail.svg" alt="" />
          <input
            type="email"
            placeholder="Email Address"
            name="email"
            ref={register}
          />
        </label>
        <label htmlFor="#" className={errors.password && "highlight"}>
          <img src="images/icons/lock.svg" alt="" />
          <input
            type={showPwd ? "text" : "password"}
            placeholder="Password"
            name="password"
            ref={register}
          />
          <img
            className="toggle-password"
            src="images/icons/eye.svg"
            alt=""
            onClick={() => setShowPwd(!showPwd)}
          />
        </label>
        <div className="forgot-password">
          Already Signed Up?{" "}
          <button 
            style={{color: "#3D9CD1"}} type="button" onClick={() => setState("CONFIRMSIGNUP")}>
              Confirm Registration
            </button>
        </div>

        <button type="submit" className="button">
          Sign up
        </button>
      </form>
    </>
  );
};

export default SignUpForm;
