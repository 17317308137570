import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "./DetailsTags";
import { getArrayValue } from "./DashboardHelper";

//const projectId = "google_1";
const dashboardName = "Traffic Mirror Targets";

const Details = ({ close, selectedRow }) => {
  const [type, setType] = useState("Details");

  return (
    <div className="justify-content-center align-items-end account-detail">
      <div className="w-100 s-flex flex-column account-detail-area">
        <button className="exitAccount" onClick={() => close()}>
          <img src="../images/close.svg" />
        </button>
        <div className="">
          <ul className="account-detail-link m-0 p-0 d-inline-flex">
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Details" && "my_active"
              }`}
              onClick={() => setType("Details")}
            >
              Details
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Sessions" && "my_active"
              }`}
              onClick={() => setType("Sessions")}
            >
              Sessions
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Tags" && "my_active"
              }`}
              onClick={() => setType("Tags")}
            >
              Tags
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Sharing" && "my_active"
              }`}
              onClick={() => setType("Sharing")}
            >
              Sharing
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 account-detail-container-area">
          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Details" && "inaktif"
            }`}
          >
            <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.AccountId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Region</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Region}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Traffic Mirror Target Name
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                  {getArrayValue(selectedRow.Tags, -1, "Key", "Name", "Value")}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Target ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.TrafficMirrorTargetId}
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Description</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Description}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Type</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Type}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Destination</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.NetworkLoadBalancerArn}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Owner</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.OwnerId}
                </p>
              </div>
            </div>
          </div>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Sessions" && "inaktif"
            }`}
          >
            <table className="details-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Session ID</th>
                  <th>Description</th>
                  <th>Source</th>
                  <th>Target</th>
                  <th>Session Number</th>
                  <th>Filter</th>
                  <th>Owner</th>
                </tr>
              </thead>
              <tbody>
                {selectedRow.Session &&
                  selectedRow.Session.map((s, idx) => (
                    <tr key={idx}>
                      <td>{s.Name}</td>
                      <td>{s.SessionId}</td>
                      <td>{s.Description}</td>
                      <td>{s.Source}</td>
                      <td>{s.Target}</td>
                      <td>{s.SessionNumber}</td>
                      <td>{s.Filter}</td>
                      <td>{s.Owner}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>
          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Sharing" && "inaktif"
            }`}
          >
            <table className="details-table">
              <thead>
                <tr>
                  <th>ARN</th>
                  <th>Type</th>
                  <th>Resource Share ARN</th>
                  <th>Created at</th>
                  <th>Last Update</th>
                </tr>
              </thead>
              <tbody>
                {selectedRow.Sharing &&
                  selectedRow.Sharing.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item.arn}</td>
                      <td>{item.type}</td>
                      <td>{item.resourceShareArn}</td>
                      <td>{item.creationTime}</td>
                      <td>{item.lastUpdatedTime}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const Navigations = () => {
  const { projectId } = useParams();
  return (
    <>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.NETWORK_FIREWALL_FIREWALLS + "/" + projectId}
      >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Network Firewalls</p>
      </Link>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.RESOURCE_SHARES_BY_ME + "/" + projectId}
      >
        <p className="m-0 p-0 mr-2 font-weight-bold">Resource Shares</p>
        <img src="../images/ribbon-designRight.svg" />
      </Link>
    </>
  );
};

const TrafficMirrorTargets = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "Traffic Mirror Target Name",
        accessor: (d) => getArrayValue(d.Tags, -1, "Key", "Name", "Value"),
      },
      {
        Header: "Target ID",
        accessor: "TrafficMirrorTargetId",
      },
      {
        Header: "Description",
        accessor: "Description",
      },
      {
        Header: "Type",
        accessor: "Type",
      },
      {
        Header: "Destination",
        accessor: "NetworkLoadBalancerArn",
      },
      {
        Header: "Owner",
        accessor: "OwnerId",
      },
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "network",
    title: "Network",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.SINGLE_SIGN_ON,
    designRoute: ROUTES.DESIGN_NETWORK,
  });
};

export default TrafficMirrorTargets;
