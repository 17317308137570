import React, { useEffect, useState } from "react";

import LeftHandMenu from "../LeftHandMenu";
import TopMenu from "../../TopMenu";

import useHideLeftMenu from "../../../hooks/useHideLeftMenu";
import DomainMenu from "../DomainMenu";

import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../hooks/useAwsAmplify";
import { getError } from "../../../helpers/errorHelper";

const AzureDesign = () => {
  const { projectId } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const getProjectAccess = async (projectId) => {
      try {
        setIsLoading(true);

        const projectAccessResp = await awsAmplify.getProjectAccess(projectId);

        setIsLoading(false);

        if(!projectAccessResp.data.getProjectAccess.hasDocAccess){
          history.goBack();
          return;
        }
      } catch (err) {
        setIsLoading(false);

        const errorMsg = getError(err, setError);

        if (errorMsg === "You have no access") {
          history.goBack();
        }
      }
    };

    if (projectId) {
      getProjectAccess(projectId);
    }
  }, [projectId]);

  useHideLeftMenu();

  return (
    <>
      {error && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setError(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {error}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}    
      <div className="userTitleTop">Design</div>
      <LeftHandMenu></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu></TopMenu>
            <div className="tableArea accountsTableArea aplicationTableArea container-fluid p-0 d-flex flex-column">
              <DomainMenu
                title="Design"
                isDashboard={false}
                isConfigure={false}
                isPrelaunch={false}
                isDesign={true}
              ></DomainMenu>

              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div className="row mt-2">
                  <div className="mainContentArea preLaunchArea row px-2 py-4 mt-2">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-12 p-0">
                        <div className="col-md-6 col-12 d-flex flex-column">
                          <div className="row">
                            <div className="col-12 d-flex flex-column ">
                              <div className="build__box-head p-relative">
                                <div>
                                  <img
                                    src="../images/icons/icon-account-framework.svg"
                                    alt=""
                                  ></img>
                                  <h3 className="font-15 px-3">
                                    Account Framework
                                  </h3>
                                </div>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <a href="#" className="font-12">
                                  Platform Accounts
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <a href="#" className="font-12">
                                  Envionment Strategy
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <a href="#" className="font-12">
                                Workload Accounts
                                </a>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <a href="#" className="font-12">
                                  Sandbox Accounts
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AzureDesign;
