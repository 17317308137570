import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import * as ROUTES from "../constants/routes";
import useAwsAmplify from "../hooks/useAwsAmplify";

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const ForgetPassword = ({
  close,
  setIsSwitchUser,
  setIsVerifyCode,
  setIsForgotPassword,
  setEmail
}) => {
  const awsAmplify = useAwsAmplify();
  const history = useHistory();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState, errors } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = async (formValues) => {
    setIsLoading(true);
    setError("");

    try {
      await awsAmplify.forgotPassword(formValues.email);
      setIsLoading(false);

      setEmail(formValues.email);

      setIsForgotPassword(false);
      setIsVerifyCode(true);
    } catch (err) {
      console.log(err);

      setIsLoading(false);
      
      if(err.message){
        setError(err.message);
      }
      //window.location.href = "/";
    }
  };

  useEffect(() => {
    const errorMsgs = [];

    if(errors){
      for(const field in errors){
        if(errors[field].message){
          errorMsgs.push(errors[field].message);
        }
      }
    }

    if (errorMsgs.length > 0) {
      setError(errorMsgs);
    }
  }, [errors.email]);

  return (
    <>
      {error && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setError(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong>
              {Array.isArray(error)
                ? error.map((em, idx) => (
                      <span key={idx}>{em}</span>
                  ))
                : error}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="auth-main">
        <div className="auth-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <a onClick={close} className="exit btn-animation">
              Exit
            </a>
            <div className="title">Reset Your Password</div>
            <div className="auth-form-content">
              <div className="warning">
                You will receive a verification code to reset your password
              </div>
              <input
                type="text"
                className="form-control mail"
                placeholder="Email Address"
                name="email"
                ref={register}
              />
            </div>
            <div className="auth-form-footer">
              <div>
                <button
                  className="btn btn-link btn-green btn-animation"
                  disabled={!formState.isValid}
                >
                  Submit
                </button>
              </div>
              <div>
                <a
                  onClick={() => {
                    setIsSwitchUser(true);
                    setIsForgotPassword(false);
                  }}
                  className="gray myclickable"
                >
                  Back to Switch User
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
