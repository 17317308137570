import React, { useState, useEffect } from "react";
import { validateAccountId } from "../../helpers/validator";

const ShortAccount3 = ({
  paramPrefix,
  name,
  checkboxLabel,
  newAccount = false,
  mandatory,
  defaultChecked = true, // true is for disabled blueSwitch style
  formValues,
  updateValue,
  validate,
  makeChange,
  setErrorMsg,
  remove,
  updateSwitch,
  setIsDirty
}) => {
  const accountIdName = `${paramPrefix}/${name}/accountid`;

  const [checked, setChecked] = useState(
    mandatory ? mandatory : defaultChecked
  );

  useEffect(() => {
    if (!mandatory) {
      setChecked(defaultChecked);
    }
  }, [defaultChecked]);

  return (
    <>
      <div className=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
        <div
          className={`one form-line my-lg-0 my-1 d-inline-flex align-items-center ${
            mandatory ? "blueSwitch" : ""
          } `}
        >
          <label className="switch mb-0">
            <input
              type="checkbox"
              disabled={mandatory}
              checked={checked}
              onChange={(e) => {
                setIsDirty(true);
                setChecked(e.target.checked); // OR setChecked(!checked);

                if (newAccount) {
                  if (e.target.checked === false) {
                    delete formValues[name];

                    remove();
                  }
                } else {
                  updateSwitch(name, e.target.checked);
                }

                if(e.target.checked === false){
                  updateValue(accountIdName, "");
                }
              }}
            />
            <span className="slider round"></span>
          </label>
          <span className="switchLabel">{checkboxLabel}</span>
        </div>
        {checked && (
          <div className="d-inline-flex my-lg-0 my-1 form-line two">
            <div className="d-flex align-items-center mr-3 green-style text-nowrap">
              Account Id
            </div>
            <input
              type="text"
              className={`form-control ${(formValues[accountIdName] && formValues[accountIdName].value !== "" && !formValues[accountIdName].isValid) && "invalid"} `}
              placeholder=""
              name={`${paramPrefix}/${name}/accountid`}
              value={
                (formValues[accountIdName] &&
                  formValues[accountIdName].value) ||
                ""
              }
              onChange={(e) => {
                setErrorMsg(null);
                setIsDirty(true);

                updateValue(accountIdName, e.target.value);

                if (e.target.value !== "" && e.target.value.trim() !== "") {
                  //makeChange(accountIdName);
                }
              }}
              onBlur={(e) => {
                if (e.target.value !== "") {
                  const errorMsg = validateAccountId(e.target.value);

                  validate(accountIdName, errorMsg === undefined);

                  if (errorMsg) {
                    setErrorMsg(`${checkboxLabel} Account Id ${errorMsg}`);
                  }
                } else {
                  validate(accountIdName, false);
                  // need to reset isValid? user entered valid value first then remove, isValid = true
                }
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ShortAccount3;
