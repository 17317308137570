import React, { useState, useEffect, useRef } from "react";
import update from "immutability-helper";

import axios from "axios";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams,  } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import { validateAccountEmailId, validate1to20AlpheNumHyphen, validateDomainName,
    validateIPAddress, validatePasswordMax127, validate1to10AlphaNumericHyphen,validateAccountName} from "../../../../helpers/validator";
import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const SECURITY_ACCOUNT_LIST_KEY = "/aws/accountframework/platformaccounts/securityaccountslist";
const SHARED_SERVICES_ACCOUNT_LIST_KEY = "/aws/accountframework/platformaccounts/sharedservicesaccountslist";

const PREFIX = "/aws/iam/pam";

const PrivilegedAccessManagement = () => {
    const { projectId } = useParams();
  
    const history = useHistory();
    const [isDirty, setIsDirty] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  
    const [formData, setFormData] = useState({
        deploypamsolution: {
            value: "yes",
          },
          
          pamsolution: {
              value: ""
          },

          cyberarkdeploymentmodel: {
            value:""
          },
          awsaccountlist: [],
          integratewithexisting: {
            awsaccountforpam : {
              value: "",
              isValid: false,
            },
            vaultadminusername   : {
              value: "",
              isValid: false,
            },
            vaultadminuserpassword : {
              value: "",
              isValid: false,
            },
            pvwaipaddress: {
              value: "",
              isValid: false,
            },
            deployec2autoonboarding :{
              value: "yes"
            },
          },

          deploycyberark: {
            awsaccountforpam : {
              value: "",
              isValid: false,
            },
            vaultadminuserpassword   : {
              value: "",
              isValid: false,
            },
            vaultmasteruserpassword : {
              value: "",
              isValid: false,
            },
            deployldapintegration  : {
              value: "yes",
            },
            deploysolutionindrregion : {
              value: "yes"
            },
            deployec2autoonboarding :{
              value: "yes"
            },
           
          },

    });

    
  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        console.log(result);

        const securityAccountsResults = await awsAmplify.loadProjectData(projectId, SECURITY_ACCOUNT_LIST_KEY);
        console.log(securityAccountsResults);
        

        const sharedServicesAccountsResults = await awsAmplify.loadProjectData(projectId, SHARED_SERVICES_ACCOUNT_LIST_KEY);
        console.log(sharedServicesAccountsResults);

        const securityaccountsdata = securityAccountsResults.data &&  securityAccountsResults.data[SECURITY_ACCOUNT_LIST_KEY] 
                                ? securityAccountsResults.data[SECURITY_ACCOUNT_LIST_KEY].split(",") : [];

        const sharedservicesdata = sharedServicesAccountsResults.data &&  sharedServicesAccountsResults.data[SHARED_SERVICES_ACCOUNT_LIST_KEY] 
                                  ? sharedServicesAccountsResults.data[SHARED_SERVICES_ACCOUNT_LIST_KEY].split(",") : [];
                             
       const accountslist = [];
      securityaccountsdata.forEach(element => {
          accountslist.push(element);
        }); 
        sharedservicesdata.forEach(element => {
          accountslist.push(element);
       });

        setIsLoading(false);

        setEmpty(result.data.empty);

        if (result.data) {
          setFormData(convertBEToFE(result.data,accountslist));
        } else {
          setFormData({
            deploypamsolution: {
              value: "yes",
            },

            pamsolution: {
                value: "",
            }
           
          });
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response && err.response.data.message);

        if(err.response.data.message === "You have no access"){
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);

      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertFEToBE = () => {
    const beData = {
      [`${PREFIX}/deploypamsolution`]: formData.deploypamsolution.value,
    };

      if (formData.deploypamsolution.value === "yes") {

        if(formData.pamsolution && formData.pamsolution.value !== "") {
        beData[`${PREFIX}/pamsolution`] = formData.pamsolution.value;

      if (formData.pamsolution.value === "cyberark"  && formData.cyberarkdeploymentmodel && 
                formData.cyberarkdeploymentmodel.value !== "") {
        beData[`${PREFIX}/cyberark/deploymentmodel`] = formData.cyberarkdeploymentmodel.value;

        if(formData.cyberarkdeploymentmodel.value === "integratewithexisting") {
          if(formData.integratewithexisting.awsaccountforpam.value != "")
              beData[`${PREFIX}/cyberark/integratewithexisting/awsaccountforpam`] = formData.integratewithexisting.awsaccountforpam.value;
           
          if(formData.integratewithexisting.vaultadminusername.isValid)
              beData[`${PREFIX}/cyberark/integratewithexisting/vault/adminusername`] = formData.integratewithexisting.vaultadminusername.value;

          if(formData.integratewithexisting.vaultadminuserpassword.isValid)
              beData[`${PREFIX}/cyberark/integratewithexisting/vault/adminuserpassword`] = formData.integratewithexisting.vaultadminuserpassword.value;

          if(formData.integratewithexisting.pvwaipaddress.isValid)
              beData[`${PREFIX}/cyberark/integratewithexisting/pvwaipaddress`] = formData.integratewithexisting.pvwaipaddress.value;

          if(formData.integratewithexisting.deployec2autoonboarding.value !== "")
              beData[`${PREFIX}/cyberark/integratewithexisting/deployec2autoonboarding`] = formData.integratewithexisting.deployec2autoonboarding.value;
        }

        if(formData.cyberarkdeploymentmodel.value === "deploy") {
          if(formData.deploycyberark.awsaccountforpam.value !== "")
              beData[`${PREFIX}/cyberark/deploy/awsaccountforpam`] = formData.deploycyberark.awsaccountforpam.value;

          if(formData.deploycyberark.vaultadminuserpassword.isValid)
              beData[`${PREFIX}/cyberark/deploy/vault/adminuserpassword`] = formData.deploycyberark.vaultadminuserpassword.value;

          if(formData.deploycyberark.vaultmasteruserpassword.isValid)
          beData[`${PREFIX}/cyberark/deploy/vault/masteruserpassword`] = formData.deploycyberark.vaultmasteruserpassword.value;

          if(formData.deploycyberark.deployec2autoonboarding.value !== "")
              beData[`${PREFIX}/cyberark/deploy/deployec2autoonboarding`] = formData.deploycyberark.deployec2autoonboarding.value;

          if(formData.deploycyberark.deployldapintegration.value !== "")
          beData[`${PREFIX}/cyberark/deploy/deployldapintegration`] = formData.deploycyberark.deployldapintegration.value;

          if(formData.deploycyberark.deploysolutionindrregion.value !== "")
          beData[`${PREFIX}/cyberark/deploy/deploysolutionindrregion`] = formData.deploycyberark.deploysolutionindrregion.value;

        }
      }
   
     }
  }
  
    return beData;
  };

  const convertBEToFE = (beData, accountslist) => {
    const feData = {
      deploypamsolution: {
        value: "yes"
      },
      awsaccountlist: accountslist ? accountslist : [],
      integratewithexisting: {
        awsaccountforpam : {
          value: beData[`${PREFIX}/cyberark/integratewithexisting/awsaccountforpam`] || "" ,
          isValid: beData.hasOwnProperty(`${PREFIX}/cyberark/integratewithexisting/awsaccountforpam`),
        },
        deployec2autoonboarding :{
          value: beData[`${PREFIX}/cyberark/integratewithexisting/deployec2autoonboarding`] || "yes"
        },
        vaultadminusername   : {
          value: beData[`${PREFIX}/cyberark/integratewithexisting/vault/adminusername`] || "" ,
          isValid: beData.hasOwnProperty(`${PREFIX}/cyberark/integratewithexisting/vault/adminusername`),
        },
        vaultadminuserpassword : {
          value: beData[`${PREFIX}/cyberark/integratewithexisting/vault/adminuserpassword`] || "" ,
          isValid: beData.hasOwnProperty(`${PREFIX}/cyberark/integratewithexisting/vault/adminuserpassword`),
        },
        pvwaipaddress: {
          value: beData[`${PREFIX}/cyberark/integratewithexisting/pvwaipaddress`] || "" ,
          isValid: beData.hasOwnProperty(`${PREFIX}/cyberark/integratewithexisting/pvwaipaddress`),
        },
      },

      deploycyberark: {
        awsaccountforpam : {
          value: beData[`${PREFIX}/cyberark/deploy/awsaccountforpam`] || "" ,
          isValid: beData.hasOwnProperty(`${PREFIX}/cyberark/deploy/awsaccountforpam`),
        },
        deployec2autoonboarding :{
          value: beData[`${PREFIX}/cyberark/deploy/deployec2autoonboarding`] || "yes"
        },
        deploysolutionindrregion :{
          value: beData[`${PREFIX}/cyberark/deploy/deploysolutionindrregion`] || "yes"
        },
        vaultadminuserpassword : {
          value: beData[`${PREFIX}/cyberark/deploy/vault/adminuserpassword`] || "" ,
          isValid: beData.hasOwnProperty(`${PREFIX}/cyberark/deploy/vault/adminuserpassword`),
        },
        vaultmasteruserpassword : {
          value: beData[`${PREFIX}/cyberark/deploy/vault/masteruserpassword`] || "" ,
          isValid: beData.hasOwnProperty(`${PREFIX}/cyberark/deploy/vault/masteruserpassword`),
        },
        deployldapintegration: {
          value: beData[`${PREFIX}/cyberark/deploy/deployldapintegration`] || "yes" ,
        },
      },
     
    };
     
      if (beData[`${PREFIX}/deploypamsolution`])
      {
        feData.deploypamsolution.value = beData[`${PREFIX}/deploypamsolution`] ? beData[`${PREFIX}/deploypamsolution`] : "yes" ;
        if(beData[`${PREFIX}/deploypamsolution`] === "yes") {
          feData.pamsolution =  {
            value: beData[`${PREFIX}/pamsolution`] ? beData[`${PREFIX}/pamsolution`]: "" }
        }

        if(beData[`${PREFIX}/pamsolution`] === "cyberark") {
          feData.cyberarkdeploymentmodel = {
            value: beData[`${PREFIX}/cyberark/deploymentmodel`] ? beData[`${PREFIX}/cyberark/deploymentmodel`]: "" 
          }
        }
      }
    return feData;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const onFieldChange = (value, key1, key2) => {
    setErrorMsg(null);
    setIsDirty(true);

    if (key2) {
      setFormData((state) =>
        update(state, { [key1]: { [key2]: { value: { $set: value } } } })
      );
    } else {
      setFormData((state) =>
        update(state, { [key1]: { value: { $set: value } } })
      );
    }
  };

  const onFieldBlur = (validateFunc, value, key1, key2) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      if (key2) {
        setFormData((state) =>
          update(state, {
            [key1]: { [key2]: { isValid: { $set: errorMsg === undefined } } },
          })
        );
      } else {
        setFormData((state) =>
          update(state, {
            [key1]: { isValid: { $set: errorMsg === undefined } },
          })
        );
      }

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      if (key2) {
        setFormData((state) =>
          update(state, {
            [key1]: { [key2]: { isValid: { $set: false } } },
          })
        );
      } else {
        setFormData((state) =>
          update(state, {
            [key1]: { isValid: { $set: false } },
          })
        );
      }
    }
  };

  const getFieldValidationClass = (key1, key2) => {
    if (key2) {
      return !formData[key1][key2].isValid && formData[key1][key2].value !== ""
        ? "invalid"
        : "";
    } else {
      return !formData[key1].isValid && formData[key1].value !== ""
        ? "invalid"
        : "";
    }
  };

  useHideLeftMenu();
  useUnload();

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Identity&Access</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
          <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Identity & Access"
                isDirty={isDirty}
                dashboardRoute={ROUTES.IDENTITY_PROVIDERS}
                designRoute={ROUTES.DESIGN_IAM}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 ">
                <div className="row mb-2 d-flex align-items-center">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3>Privileged Access Management</h3>
                  </div>
                  <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                            Deploy PAM Solution
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                        <label
                          className={`createElementBtn btn btn-link btn-linkYes ${
                            formData.deploypamsolution.value === "yes"
                              ? "active"
                              : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={
                              formData.deploypamsolution.value === "yes"
                            }
                            value="yes"
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) => {
                                return {
                                  ...state,
                                  deploypamsolution: {
                                    value: "yes",
                                  },
                                };
                              });
                            }}
                          />{" "}
                          YES
                        </label>
                        <label
                          className={`dontCreateElementBtn btn btn-link btn-linkNo ${
                            formData.deploypamsolution.value === "no"
                              ? "active"
                              : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={
                              formData.deploypamsolution.value === "no"
                            }
                            value="no"
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) => {
                                return {
                                  ...state,
                                  deploypamsolution: {
                                    value: "no",
                                  },
                                };
                              });
                            }}
                          />{" "}
                          NO
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {empty && (
                    <NoInitialData />
                )}
                {formData.deploypamsolution.value === "yes" && (
                  <div className="show-hide-content">          
                    <div className="col-12 d-inline-flex selectionArea my-3 px-3 justify-content-between align-items-center flex-wrap py-1">
                        <label className="font-weight-bold my-2 font-12">
                         Privileged Access Solution
                        </label>
                        <div className="my-2">
                          <label className="container">
                            AWS Session Manager
                            <input
                              type="radio"
                              name="single"
                              value="awssessionmanager"
                              checked={
                               formData.pamsolution &&  formData.pamsolution.value ===
                                "awssessionmanager"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) => {
                                  return {
                                    ...state,
                                    pamsolution: {
                                      value: "awssessionmanager",
                                    },
                                  };
                                });
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="my-2">
                          <label className="container">
                            CyberArk
                            <input
                              type="radio"
                              name="single"
                              value="cyberark"
                              checked={
                                formData.pamsolution && formData.pamsolution.value ===
                                "cyberark"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) => {
                                  return {
                                    ...state,
                                    pamsolution: {
                                      value: "cyberark",
                                    },
                                  };
                                });
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="my-2">
                          <label className="container">
                            BeyondTrust
                            <input
                              type="radio"
                              name="single"
                              value="beyondtrust"
                              checked={
                                formData.pamsolution && formData.pamsolution.value ===
                                "beyondtrust"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) => {
                                  return {
                                    ...state,
                                    pamsolution: {
                                      value: "beyondtrust",
                                    },
                                  };
                                });
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>         
                      {formData.pamsolution && formData.pamsolution.value === "cyberark" && (
                       <div className="show-hide-sub-content">
                      <div className="col-8 d-inline-flex selectionArea my-3 px-3 justify-content-between align-items-center flex-wrap py-1">
                        <label className="font-weight-bold font-12 my-2">
                          CyberArk Deployment Model
                        </label>
                          <div className="my-2 mx-3">
                                <label className="container">
                                Deploy CyberArk
                                  <input
                                    type="radio"
                                    name="cyberarkradio"
                                    value="deploy"
                                    checked={ formData.cyberarkdeploymentmodel && formData.cyberarkdeploymentmodel.value ===
                                      "deploy"}
                                      onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) => {
                                          return {
                                            ...state,
                                            cyberarkdeploymentmodel: {
                                              value: "deploy",
                                            },
                                          };
                                        });
                                      }}
                                    />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                    
                              <div className="my-2">
                                <label className="container">
                                Integrate with existing
                                  <input
                                    type="radio"
                                    name="cyberarkradio"
                                    value="integratewithexisting"
                                    checked={ formData.cyberarkdeploymentmodel && formData.cyberarkdeploymentmodel.value ===
                                      "integratewithexisting"}
                                      onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) => {
                                          return {
                                            ...state,
                                            cyberarkdeploymentmodel: {
                                              value: "integratewithexisting",
                                            },
                                          };
                                        });
                                      }}
                                    />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                    
                      </div>
                      </div>
                      )}

                { formData.pamsolution && formData.pamsolution.value === "cyberark" && 
                formData.cyberarkdeploymentmodel  && formData.cyberarkdeploymentmodel.value === "integratewithexisting" && (
                 <div className="show-hide-sub-content">
                  <div className="selectionArea my-3 px-3 justify-content-between align-items-center flex-wrap py-1">
                   <label className="font-weight-bold my-2 font-12">
                   Existing CyberArk Server Access 
                   </label>

               <div className="d-flex flex-column mainContentArea  pl-3 pr-3 py-1 my-1 ">
                 <div className="d-inline-flex align-items-center row">
                 <div className="col-md-3 my-1 p-0">
                     <span className="mb-0 font-12">
                     AWS Account for PAM Solution
                     </span>
                 </div>
                 <div className="col-md-9 flex-wrap d-inline-flex">
                    <div className="mr-3 my-1">
                        <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                        <select
                          className="form-control gray"
                          value={formData.integratewithexisting
                            .awsaccountforpam.value }
                            onChange={(e) => {
                              onFieldChange(
                              e.target.value,
                              "integratewithexisting",
                              "awsaccountforpam"
                              );                                       
                        }} >   
                          <option value="">Select</option>
                            { formData.awsaccountlist && formData.awsaccountlist.map(
                              (account, idxAccount) => (
                                <option key={idxAccount} value={account}>
                                  {account}
                                </option>
                              )
                            )}
                      </select>
                             </div>
                         </div>
                       </div>
                
                 
                 </div>
                 <div className="d-inline-flex align-items-center row">
                 <div className="col-md-3 my-1 p-0">
                     <span className="mb-0 font-12">
                     Password Vault Web Access IP Address
                     </span>
                 </div>
                   <div className="col-md-9 flex-wrap d-inline-flex">
                   <div className="mr-3 my-1">
                         <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                             <input
                             type="text"
                             className={`form-control bg-transparent ${getFieldValidationClass(
                                 "integratewithexisting",
                                 "pvwaipaddress"
                             )}`}
                             placeholder="192.168.1.56"
                             value={formData.integratewithexisting
                               .pvwaipaddress.value}
                               onChange={(e) => {
                                 onFieldChange(
                                 e.target.value,
                                 "integratewithexisting",
                                 "pvwaipaddress"
                                 );                                       
                             }}
                             onBlur={(e) => {
                                 onFieldBlur(
                                 validateIPAddress,
                                 e.target.value,
                                 "integratewithexisting",
                                 "pvwaipaddress"
                                 );
                             }}
                             />
                         </div>
                         </div>
                   </div> 
                 </div>
                 <div className="d-inline-flex align-items-center row">
                 <div className="col-md-3 my-1 p-0">
                     <span className="mb-0 font-12">
                     Vault Admin Username 
                     </span>
                 </div>
                 <div className="col-md-9 flex-wrap d-inline-flex">
                     <div className="mr-3 my-1">
                     <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                         <input
                         type="text"
                         className={`form-control bg-transparent ${getFieldValidationClass(
                             "integratewithexisting",
                             "vaultadminusername"
                         )}`}
                         placeholder=""
                         value={
                             formData.integratewithexisting
                             .vaultadminusername.value
                         }
                         onChange={(e) => {
                             onFieldChange(
                             e.target.value,
                             "integratewithexisting",
                             "vaultadminusername"
                             );
                         }}
                         onBlur={(e) => {
                             onFieldBlur(
                             validate1to20AlpheNumHyphen,
                             e.target.value,
                             "integratewithexisting",
                             "vaultadminusername"
                             );
                         }}
                         />
                     </div>
                     </div>
                 </div>
                 </div>
                 <div className="d-inline-flex align-items-center row">
                 <div className="col-md-3 my-1 p-0">
                     <span className="mb-0 font-12">Vault Admin User Password</span>
                 </div>
                 <div className="col-md-9 flex-wrap d-inline-flex">
                     <div className="mr-3 my-1">
                     <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                         <input
                         type="password"
                         className={`form-control show-psw bg-transparent ${getFieldValidationClass(
                             "integratewithexisting",
                             "vaultadminuserpassword"
                         )}`}
                         placeholder="*******"
                         value={
                             formData.integratewithexisting
                             .vaultadminuserpassword.value
                         }
                         onChange={(e) => {
                             onFieldChange(
                             e.target.value,
                             "integratewithexisting",
                             "vaultadminuserpassword"
                             );
                         }}
                         onBlur={(e) => {
                             onFieldBlur(
                             validatePasswordMax127,
                             e.target.value,
                             "integratewithexisting",
                             "vaultadminuserpassword"
                             );
                         }}
                         />
                     </div>
                     </div>
                 </div>
                 </div>
                 <div className="d-inline-flex align-items-center row">
                   <div className="col-md-3 my-1 p-0">
                   <span className="mb-0 font-12">Deploy EC2 Auto-Onboarding</span>
                   </div>
                   <div className="col-md-9 flex-wrap d-inline-flex">
                   <div className="mr-3 my-1">
                   <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                     <label
                       className={`createSubElementBtn btn btn-link btn-linkYes ${
                       formData.integratewithexisting.deployec2autoonboarding && formData.integratewithexisting.deployec2autoonboarding.value ===
                         "yes"
                           ? "active"
                           : ""
                       }`}
                     >
                       <input
                         type="radio"
                         name="options"
                         value="yes"
                         checked={
                           formData.integratewithexisting.deployec2autoonboarding && formData.integratewithexisting.deployec2autoonboarding
                             .value === "yes" 
                         }
                         onChange={(e) => {
                           onFieldChange(
                             "yes",
                             "integratewithexisting",
                             "deployec2autoonboarding"
                             );     
                         }}
                       />{" "}
                       YES
                     </label>
                     <label
                       className={`dontCreateSubElementBtn btn btn-link btn-linkNo ${
                         formData.integratewithexisting.deployec2autoonboarding && formData.integratewithexisting.deployec2autoonboarding.value ===
                         "no"
                           ? "active"
                           : ""
                       }`}
                     >
                       <input
                         type="radio"
                         name="options"
                         checked={
                             formData.integratewithexisting.deployec2autoonboarding && formData.integratewithexisting.deployec2autoonboarding
                             .value === "no"
                         }
                         onChange={(e) => {
                           onFieldChange(
                             "no",
                             "integratewithexisting",
                             "deployec2autoonboarding"
                             );      
                         }}
                       />{" "}
                       NO
                     </label>
                   </div>
               </div></div>
                 </div>    
              
               </div>
            </div>
    

              </div>
               )}

              { formData.pamsolution && formData.pamsolution.value === "cyberark" && 
              formData.cyberarkdeploymentmodel  && formData.cyberarkdeploymentmodel.value === "deploy" && (
              <div className="show-hide-sub-content">
                  <div className="selectionArea my-3 px-3 justify-content-between align-items-center flex-wrap py-1">
                   <label className="font-weight-bold my-2 font-12">
                   Deploy CyberArk 
                   </label>

               <div className="d-flex flex-column mainContentArea  pl-3 pr-3 py-1 my-1 ">
                 <div className="d-inline-flex align-items-center row">
                 <div className="col-md-3 my-1 p-0">
                     <span className="mb-0 font-12">
                     AWS Account for PAM Solution
                     </span>
                 </div>
                 <div className="col-md-9 flex-wrap d-inline-flex">
                     <div className="mr-3 my-1">
                     <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                        <select
                          className="form-control gray"
                          value={formData.deploycyberark
                            .awsaccountforpam.value }
                            onChange={(e) => {
                              onFieldChange(
                              e.target.value,
                              "deploycyberark",
                              "awsaccountforpam"
                              );                                       
                        }} >   
                          <option value="">Select</option>
                            { formData.awsaccountlist && formData.awsaccountlist.map(
                              (account, idxAccount) => (
                                <option key={idxAccount} value={account}>
                                  {account}
                                </option>
                              )
                            )}
                      </select>
                             </div>
                        </div>
                 </div>
                 </div>
              
                 <div className="d-inline-flex align-items-center row">
                 <div className="col-md-3 my-1 p-0">
                     <span className="mb-0 font-12">Vault Admin User Password</span>
                 </div>
                 <div className="col-md-9 flex-wrap d-inline-flex">
                     <div className="mr-3 my-1">
                     <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                         <input
                         type="password"
                         className={`form-control show-psw bg-transparent ${getFieldValidationClass(
                             "deploycyberark",
                             "vaultadminuserpassword"
                         )}`}
                         placeholder="*******"
                         value={
                             formData.deploycyberark
                             .vaultadminuserpassword.value
                         }
                         onChange={(e) => {
                             onFieldChange(
                             e.target.value,
                             "deploycyberark",
                             "vaultadminuserpassword"
                             );
                         }}
                         onBlur={(e) => {
                             onFieldBlur(
                             validatePasswordMax127,
                             e.target.value,
                             "deploycyberark",
                             "vaultadminuserpassword"
                             );
                         }}
                         />
                     </div>
                     </div>
                 </div>
                 </div>
               
                 <div className="d-inline-flex align-items-center row">
                 <div className="col-md-3 my-1 p-0">
                     <span className="mb-0 font-12">Vault Master User Password</span>
                 </div>
                 <div className="col-md-9 flex-wrap d-inline-flex">
                     <div className="mr-3 my-1">
                     <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                         <input
                         type="password"
                         className={`form-control show-psw bg-transparent ${getFieldValidationClass(
                             "deploycyberark",
                             "vaultmasteruserpassword"
                         )}`}
                         placeholder="*******"
                         value={
                             formData.deploycyberark
                             .vaultmasteruserpassword.value
                         }
                         onChange={(e) => {
                             onFieldChange(
                             e.target.value,
                             "deploycyberark",
                             "vaultmasteruserpassword"
                             );
                         }}
                         onBlur={(e) => {
                             onFieldBlur(
                             validatePasswordMax127,
                             e.target.value,
                             "deploycyberark",
                             "vaultmasteruserpassword"
                             );
                         }}
                         />
                     </div>
                     </div>
                 </div>
                 </div>
             
                 <div className="d-inline-flex align-items-center row">
                   <div className="col-md-3 my-1 p-0">
                   <span className="mb-0 font-12">Deploy AD Integration</span>
                   </div>
                   <div className="col-md-9 flex-wrap d-inline-flex">
                   <div className="mr-3 my-1">
                   <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                     <label
                       className={`createSubElementBtn btn btn-link btn-linkYes ${
                       formData.deploycyberark.deployldapintegration  && formData.deploycyberark.deployldapintegration.value ===
                         "yes"
                           ? "active"
                           : ""
                       }`}
                     >
                       <input
                         type="radio"
                         name="deployldap"
                         value="yes"
                         checked={
                           formData.deploycyberark.deployldapintegration  && formData.deploycyberark.deployldapintegration    
                             .value === "yes" 
                         }
                         onChange={(e) => {
                           onFieldChange(
                             "yes",
                             "deploycyberark",
                             "deployldapintegration"
                             );     
                         }}
                       />{" "}
                       YES
                     </label>
                     <label
                       className={`dontCreateSubElementBtn btn btn-link btn-linkNo ${
                         formData.deploycyberark.deployldapintegration  && formData.deploycyberark.deployldapintegration.value ===
                         "no"
                           ? "active"
                           : ""
                       }`}
                     >
                       <input
                         type="radio"
                         name="deployldap"
                         checked={
                             formData.deploycyberark.deployldapintegration  && formData.deploycyberark.deployldapintegration  
                             .value === "no"
                         }
                         onChange={(e) => {
                           onFieldChange(
                             "no",
                             "deploycyberark",
                             "deployldapintegration"
                             );      
                         }}
                       />{" "}
                       NO
                     </label>
                   </div>
               </div></div>
                 </div>    
                   
                 <div className="d-inline-flex align-items-center row">
                   <div className="col-md-3 my-1 p-0">
                   <span className="mb-0 font-12">Deploy EC2 Auto-Onboarding</span>
                   </div>
                   <div className="col-md-9 flex-wrap d-inline-flex">
                   <div className="mr-3 my-1">
                   <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                     <label
                       className={`createSubElementBtn btn btn-link btn-linkYes ${
                       formData.deploycyberark.deployec2autoonboarding && formData.deploycyberark.deployec2autoonboarding.value ===
                         "yes"
                           ? "active"
                           : ""
                       }`}
                     >
                       <input
                         type="radio"
                         name="deployec2"
                         value="yes"
                         checked={
                           formData.deploycyberark.deployec2autoonboarding && formData.deploycyberark.deployec2autoonboarding
                             .value === "yes" 
                         }
                         onChange={(e) => {
                           onFieldChange(
                             "yes",
                             "deploycyberark",
                             "deployec2autoonboarding"
                             );     
                         }}
                       />{" "}
                       YES
                     </label>
                     <label
                       className={`dontCreateSubElementBtn btn btn-link btn-linkNo ${
                         formData.deploycyberark.deployec2autoonboarding && formData.deploycyberark.deployec2autoonboarding.value ===
                         "no"
                           ? "active"
                           : ""
                       }`}
                     >
                       <input
                         type="radio"
                         name="deployec2"
                         checked={
                             formData.deploycyberark.deployec2autoonboarding && formData.deploycyberark.deployec2autoonboarding
                             .value === "no"
                         }
                         onChange={(e) => {
                           onFieldChange(
                             "no",
                             "deploycyberark",
                             "deployec2autoonboarding"
                             );      
                         }}
                       />{" "}
                       NO
                     </label>
                   </div>
               </div></div>
                 </div>    
         
                 <div className="d-inline-flex align-items-center row">
                   <div className="col-md-3 my-1 p-0">
                   <span className="mb-0 font-12">Deploy CyberArk Solution in DR Region</span>
                   </div>
                   <div className="col-md-9 flex-wrap d-inline-flex">
                   <div className="mr-3 my-1">
                   <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                     <label
                       className={`createSubElementBtn btn btn-link btn-linkYes ${
                       formData.deploycyberark.deploysolutionindrregion  && formData.deploycyberark.deploysolutionindrregion.value ===
                         "yes"
                           ? "active"
                           : ""
                       }`}
                     >
                       <input
                         type="radio"
                         name="deploydr"
                         value="yes"
                         checked={
                           formData.deploycyberark.deploysolutionindrregion  && formData.deploycyberark.deploysolutionindrregion  
                             .value === "yes" 
                         }
                         onChange={(e) => {
                           onFieldChange(
                             "yes",
                             "deploycyberark",
                             "deploysolutionindrregion"
                             );     
                         }}
                       />{" "}
                       YES
                     </label>
                     <label
                       className={`dontCreateSubElementBtn btn btn-link btn-linkNo ${
                         formData.deploycyberark.deploysolutionindrregion   && formData.deploycyberark.deploysolutionindrregion.value ===
                         "no"
                           ? "active"
                           : ""
                       }`}
                     >
                       <input
                         type="radio"
                         name="deploydr"
                         checked={
                             formData.deploycyberark.deploysolutionindrregion   && formData.deploycyberark.deploysolutionindrregion  
                             .value === "no"
                         }
                         onChange={(e) => {
                           onFieldChange(
                             "no",
                             "deploycyberark",
                             "deploysolutionindrregion"
                             );      
                         }}
                       />{" "}
                       NO
                     </label>
                   </div>
               </div></div>
                 </div>    
                 
               </div>
            </div>
    

              </div>
           )} 

           </div>
               )}
              </div>
              </fieldset>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() => saveDataIfDirty(ROUTES.SINGLE_SIGN_ON + "/" + projectId)}
                  >
                      <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Single Sign-On  
                    </p>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );



};  

    export default PrivilegedAccessManagement;