import React, { useEffect, useState } from "react";
import HomeFooter from "./HomeFooter";
import HomeHeader from "./HomeHeader";

import { HashLink } from "react-router-hash-link";

import $ from "jquery";

import "aos/dist/aos.css";
import AOS from "aos";

import { Hub } from "@aws-amplify/core";


import "slick-carousel";

const NewHome = () => {
  const [authStateChanged, setAuthStateChanged] = useState(null);
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  Hub.listen("auth", (data) => {
    switch (data.payload.event) {
      case "signIn":
        setAuthStateChanged("SIGNIN");
        break;
      case "signOut":
        setAuthStateChanged("SIGNOUT");
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    //$(function () {
      //slider.js

      /* $('.loop').slick({
        dots: true,
        infinite: false,
        speed: 1500,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 8000,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1
    }); */
    
    $(".left").on("click",function(){
        $(this).next().slick('slickPrev');
    });
    $(".right").on("click",function(){
        $(this).prev().slick('slickNext');
    });

    $('.inner-loop').slick({
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 5000,
      fade: false,
      slidesToShow: 1,
      slidesToScroll: 1
  });

  
  $(".prev").on("click",function(){
      $(this).next().slick('slickPrev');
  });
  $(".next").on("click",function(){
      $(this).prev().slick('slickNext');
  });
    // main.js
    $(window).scroll(function () {
      var sticky = $("header"),
        scroll = $(window).scrollTop(),
        windowHeight = $(window).height();

      if (scroll >= 100) sticky.addClass("sticky");
      else sticky.removeClass("sticky");
    });

    $("*").click(function (event) {
      if (!$(event.target).is(".signClick,.signClick *, .log *, .sign *")) {
        $(".log li").removeClass("active");
        // return false;
      }
    });

    $(".column01-tab-content  .item").addClass("animate");
    $(".column01-tab-content .item").first().removeClass("animate");

    // Banner Tab
    $(".banner .buttons li").on("click", function () {
      AOS.init({
        disable: "mobile",
      });
      var index = $(this).index();
      // Column01
      $(".column01-tab-head li").removeClass("active");
      $(".column01-tab-head li").eq(index).addClass("active");
      $(".column01-tab-content .item").addClass("animate");
      $(".column01-tab-content .item").eq(index).removeClass("animate");

      // Column02
      $(".column02-tab-head li").removeClass("active");
      $(".column02-tab-head li").eq(index).addClass("active");

      $(".inner-tab-head li").removeClass("active");
      $(".inner-tab-head li:first-child").addClass("active");
      $(".inner-tab-content .inner-item").hide();
      $(".inner-tab-content .inner-item:first-child").show();

      $(".inner-loop").slick("refresh");
      $(".column02-tab-content .item").hide();
      $(".column02-tab-content .item").eq(index).show();

      // Column03
      $(".column03-tab-head li").removeClass("active");
      $(".column03-tab-head li").eq(index).addClass("active");
      $(".column03-tab-content .item").addClass("animate");
      $(".column03-tab-content .item").eq(index).removeClass("animate");
    });
    // Banner Tab End

    // Column01 Tab
    $(".column01-tab-head li").on("click", function () {
      AOS.init({
        disable: "mobile",
      });
      var index = $(this).index();
      $(".column01-tab-head li").removeClass("active");
      $(this).addClass("active");

      $(".column01-tab-content .item").addClass("animate");
      $(".column01-tab-content .item").eq(index).removeClass("animate");

      // Column02
      $(".column02-tab-head li").removeClass("active");
      $(".column02-tab-head li").eq(index).addClass("active");

      $(".inner-tab-head li").removeClass("active");
      $(".inner-tab-head li:first-child").addClass("active");
      $(".inner-tab-content .inner-item").hide();
      $(".inner-tab-content .inner-item:first-child").show();

      $(".inner-loop").slick("refresh");
      $(".column02-tab-content .item").hide();
      $(".column02-tab-content .item").eq(index).show();

      // Column03
      $(".column03-tab-head li").removeClass("active");
      $(".column03-tab-head li").eq(index).addClass("active");
      $(".column03-tab-content .item").addClass("animate");
      $(".column03-tab-content .item").eq(index).removeClass("animate");
    });
    // Column01 Tab Emd

    // Column02 Tab
    $(".column02-tab-content .item").each(function (index) {
      AOS.init({
        disable: "mobile",
      });
      $(".column02-tab-content .item").hide();
      $(".column02-tab-content .item").first().show();
      $(this).find(".inner-tab .inner-tab-content .inner-item").hide();
      $(this).find(".inner-tab .inner-tab-content .inner-item").first().show();

      $(this)
        .parent()
        .prev()
        .find("li")
        .on("click", function () {
          var index = $(this).index();
          $(".column02-tab-head li").removeClass("active");
          $(this).addClass("active");
          $(".inner-tab-head li").removeClass("active");
          $(this)
            .parent()
            .next()
            .find(".item .inner-tab .inner-tab-head li:first-child")
            .addClass("active");
          $(this)
            .parent()
            .next()
            .find(".item .inner-tab .inner-tab-content .inner-item:first-child")
            .show();
          $(".inner-loop").slick("refresh");
          $(".column02-tab-content .item").hide();
          $(this).parent().next().find(".item").eq(index).show();

          // Column01
          $(".column01-tab-head li").removeClass("active");
          $(".column01-tab-head li").eq(index).addClass("active");
          $(".column01-tab-content .item").addClass("animate");
          $(".column01-tab-content .item").eq(index).removeClass("animate");

          // Column03
          $(".column03-tab-head li").removeClass("active");
          $(".column03-tab-head li").eq(index).addClass("active");
          $(".column03-tab-content .item").addClass("animate");
          $(".column03-tab-content .item").eq(index).removeClass("animate");
        });

      $(this)
        .find(".inner-tab .inner-tab-head li")
        .on("click", function () {
          var index2 = $(this).index();
          $(".inner-tab-head li").removeClass("active");
          $(this).addClass("active");
          $(".inner-loop").slick("refresh");
          $(".inner-tab-content .inner-item").hide();

          $(this).parent().next().find(".inner-item").eq(index2).show();
        });
    });
    // Column02 Tab

    // Column03 Tab
    $(".column03-tab-content  .item").addClass("animate");
    $(".column03-tab-content .item").first().removeClass("animate");
    $(".column03-tab-head li").on("click", function () {
      AOS.init({
        disable: "mobile",
      });
      var index = $(this).index();
      $(".column03-tab-head li").removeClass("active");
      $(this).addClass("active");

      $(".column03-tab-content .item").addClass("animate");
      $(".column03-tab-content .item").eq(index).removeClass("animate");

      // Column01
      $(".column01-tab-head li").removeClass("active");
      $(".column01-tab-head li").eq(index).addClass("active");
      $(".column01-tab-content .item").addClass("animate");
      $(".column01-tab-content .item").eq(index).removeClass("animate");

      // Column02
      $(".column02-tab-head li").removeClass("active");
      $(".column02-tab-head li").eq(index).addClass("active");

      $(".inner-tab-head li").removeClass("active");
      $(".inner-tab-head li:first-child").addClass("active");
      $(".inner-tab-content .inner-item").hide();
      $(".inner-tab-content .inner-item:first-child").show();

      $(".inner-loop").slick("refresh");
      $(".column02-tab-content .item").hide();
      $(".column02-tab-content .item").eq(index).show();
    });
    // Column03 Tab

    // end main.js

    AOS.init({
      disable: "mobile",
    });

    //$('.demoForm').hide();
    
    $(".demoButton").on("click", function () {
      $(".demoForm").slideDown();
    });

  //});
  }, []);

  return (
    <>
    {error && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setError(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong>
              {Array.isArray(error)
                ? error.map((em, idx) => <span key={idx}>{em}</span>)
                : error}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}

{info && (
        <div className="alert-area">
          <div className="bluealert">
<div className="messageText">{info}</div>
          </div>
        </div>
      )}
      <div className="body-hero">
         <HomeHeader setError={setError} setIsLoading={setIsLoading} setInfo={setInfo}></HomeHeader> 
        <section className="banner">
          <img className="bg" src="images/materials/bg01.jpg" alt="" />
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 text">
                <span
                  className="gradient-button"
                  data-aos="fade-right"
                  data-aos-duration="800"
                  data-aos-delay="300"
                >
                  Accelerate your Digital Journey
                </span>
                <h1
                  data-aos="fade-right"
                  data-aos-duration="800"
                  data-aos-delay="500"
                >
                  Foundation for Digital Innovation
                </h1>
              </div>

              <div
                className="col-lg-6"
                data-aos="fade-left"
                data-aos-duration="800"
                data-aos-delay="1300"
              >
                <img
                  className="vektor"
                  src="images/materials/banner01.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      
      </div>
    </>
  );
};

export default NewHome;
