
import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getBooleanValue} from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";


const dashboardName = "Config Rules";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
      <>
        <h3>Config</h3>
        <div className="otherTableTitles ">
          <h3 className="font-15">   
          <Link className="text-color-gray" to={ROUTES.GOVERNANCE_RECORDERS + "/" + projectId}>  
             Recorders
         </Link>
          </h3>
          <h3 className="font-15 activetable">  
             Rules
          </h3>
        </div>
      </>
    );
  };


  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Trigger Type" && "my_active"
                }`}
                onClick={() => setType("Trigger Type")}
              >
              Trigger Type
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Resource Types" && "my_active"
                }`}
                onClick={() => setType("Resource Types")}
              >
              Resource Types
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Parameters" && "my_active"
                }`}
                onClick={() => setType("Parameters")}
              >
              Parameters
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Resources in Scope" && "my_active"
                }`}
                onClick={() => setType("Resources in Scope")}
              >
              Resources in Scope
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Source" && "my_active"
                }`}
                onClick={() => setType("Source")}
              >
              Source
              </li>
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Config Rule Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.ConfigRuleName} 
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    ARN
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.ConfigRuleArn}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    ID
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.ConfigRuleId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Description
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.Description}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                      State
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.ConfigRuleState}
                  </p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                   Maximum Execution Frequency
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.MaximumExecutionFrequency}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Created by</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.CreatedBy}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Last Successful Invocation
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.OverallRuleStatus && selectedRow.OverallRuleStatus[0].LastSuccessfulInvocationTime}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Last Successful Evaluation
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.OverallRuleStatus && selectedRow.OverallRuleStatus[0].LastSuccessfulEvaluationTime}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Remediation Action
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Scope of Changes
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {}
                  </p>
                </div>
              </div>
            </div>

            <div
             className={`bolgeler-container w-100 row p-5 ${
               type !== "Trigger Types" && "inaktif"
             }`}
           >
            <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Trigger Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                     <td></td>  
                    </tr>          
                  </tbody>
                </table>
            </div>
            </div>
            <div
             className={`bolgeler-container w-100 row p-5 ${
               type !== "Resource Types" && "inaktif"
             }`}
           >
            <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Compliance Resource Types</th>
                    </tr>
                  </thead>
                  <tbody>
                  {selectedRow.Scope && (
                    <tr>
                        {selectedRow.Scope.ComplianceResourceTypes && 
                        selectedRow.Scope.ComplianceResourceTypes.map(
                            (item) =>  
                            <td>{item}</td>
                         ) }
                    </tr>
                    )}  
                  </tbody>
                </table>
            </div>
            </div>

            <div
             className={`bolgeler-container w-100 row p-5 ${
               type !== "Parameters" && "inaktif"
             }`}
           >
            <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th>Type</th>
                      <th>Value</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                  {selectedRow.InputParameters && (
                    <tr>
                        
                    </tr>
                    )}  
                  </tbody>
                </table>
            </div>
            </div>

            <div
             className={`bolgeler-container w-100 row p-5 ${
               type !== "Resources in Scope" && "inaktif"
             }`}
           >
            <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Resource ID</th>
                      <th>Type</th>
                      <th>Compliance Status</th>
                      <th>Action Status</th>
                      <th>Annotation</th>
                    </tr>
                  </thead>
                  <tbody>                 
                    <tr>                      
                    </tr>
                  </tbody>
                </table>
            </div>
            </div>

            <div
             className={`bolgeler-container w-100 row p-5 ${
               type !== "Source" && "inaktif"
             }`}
           >
            <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Owner</th>
                      <th>Source Identifier</th>
                    </tr>
                  </thead>
                  <tbody>                 
                    <tr> 
                        <td>{selectedRow.Source.Owner}</td>
                        <td>{selectedRow.Source.SourceIdentifier}</td>
                    </tr>
                  </tbody>
                </table>
            </div>
            </div>

          </div>
        </div>
      </div>
    );
  };

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.GOVERNANCE_SERVICE_CONTROL + "/" + projectId} >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Organization Policies</p>       
      </Link> 

    <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.GOVERNANCE_AWS_BUDGETS + "/" + projectId}
    >
      <p className="m-0 p-0 mr-2 font-weight-bold">AWS Budgets</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
    </>
    );
  };
  
  
const Rules = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account Id",
        accessor: "AccountId",
        id:"Account Id"
      },
      {
        Header: "Region",
        accessor: "Region",
        id:"Region"
      },
      {
        Header: "Config Rule Name",
        accessor: "ConfigRuleName",
        id: "Config Rule Name"
      },
      {
        Header: "ARN",
        accessor: "ConfigRuleArn",
        id: "ARN"
      },
      {
        Header: "ID",
        accessor: "ConfigRuleId",
        id: "ID"
      },
      {
        Header: "Description",
        accessor: "Description",
        id: "Description"
      },
      {
        Header: "State",
        accessor: "ConfigRuleState",
        id: "State"
      },
      {
        Header: "Maximum Execution Frequency",
        accessor: "MaximumExecutionFrequency",
        id: "Maximum Execution Frequency"
      },
      {
        Header: "Created By",
        accessor: "CreatedBy",
        id: "Created By"
      },
      {
        Header: "Last Successful Invocation",
        accessor: (d) => getArrayValue(d.OverallRuleStatus, 0, "LastSuccessfulInvocationTime", "", ""),
        id: "Last Successful Invocation"
      },
      {
        Header: "Last Successful Evaluation",
        accessor: (d) => getArrayValue(d.OverallRuleStatus, 0, "LastSuccessfulEvaluationTime", "", ""),
        id: "Last Successful Evaluation"
      },
      {
        Header: "Remediation Action",
        accessor: "",
        id: "Remediation Action"
      },
      {
        Header: "Scope Of Changes",
        accessor: "",
        id: "Scope Of Changes"
      },
    ],
    []
  );

  return Dashboard({
    domain: "governance",
    title: "Governance",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.GOVERNANCE_SERVICE_CONTROL_POLICIES,
    designRoute: ROUTES.DESIGN_GOVERNANCE,
    SubMenu: SubMenu,
  });
};

export default Rules;