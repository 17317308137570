import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import { validateIPAddress, validateTgwasn } from "../../../../helpers/validator";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const projectId = "google_1";
const PREFIX = "/aws/network/vpnconnectivity";

const REGION_KEY = "/aws/network/aws/awsregionslist";
const DATACENTER_KEY = "/aws/network/datacenters/datacenterslist";
const ONPREMINTEGRATION_KEY = "/aws/network/datacenters/establishonpremintegration";

const VPNConnectivity = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    deployvpnconnectivity: {
      value: "yes",
    },
    array: [],
    regions: [],
    dataCenters: [],
  });

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        console.log(result);

        /* const regionResult = await awsAmplify.loadProjectData(
          projectId,
          REGION_KEY
        );
        console.log(regionResult);

        const dataCenterResult = await awsAmplify.loadProjectData(
          projectId,
          DATACENTER_KEY
        );
        console.log(dataCenterResult);

        const onPremIntegrationResult = await awsAmplify.loadProjectData(
          projectId,
          ONPREMINTEGRATION_KEY
        );
        console.log(onPremIntegrationResult); */

        setIsLoading(false);

        setEmpty(result.data.empty);

        const fe = convertBEToFE(result.data);

        setFormData(fe);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);

      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }

    const fe = {
      deployvpnconnectivity: {
        value: data[`${PREFIX}/deployvpnconnectivity`] || "yes",
      },
      array: [createEntity()],
      regions: data[REGION_KEY] ? data[REGION_KEY].split(",") : [],
      dataCenters: data[DATACENTER_KEY] ? data[DATACENTER_KEY].split(",") : [],
      onPremIntegration: data[ONPREMINTEGRATION_KEY],
    };

    if (data[`${PREFIX}/vpnconnectionslist`]) {
      fe.array = [];

      const vpnconnectionslist = data[`${PREFIX}/vpnconnectionslist`].split(
        ","
      );

      vpnconnectionslist.forEach((cr) => {
        fe.array.push({
          awsregion: data[`${PREFIX}/${cr}/awsregion`] || "",
          datacenter: data[`${PREFIX}/${cr}/datacenter`] || "",
          cgwasn: {
            value: data[`${PREFIX}/${cr}/cgwasn`] || "",
            isValid: data.hasOwnProperty(`${PREFIX}/${cr}/cgwasn`),
          },
          cgwipaddress: {
            value: data[`${PREFIX}/${cr}/cgwipaddress`] || "",
            isValid: data.hasOwnProperty(`${PREFIX}/${cr}/cgwipaddress`),
          },
          useawsglobalaccelerator:
            data[`${PREFIX}/${cr}/useawsglobalaccelerator`] || "yes",
        });
      });
    }

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deployvpnconnectivity`]: formData.deployvpnconnectivity.value,
    };

    if (formData.deployvpnconnectivity.value === "yes") {
      const validItems = [];

      formData.array.forEach((item, idxItem) => {
        let hasValidItem = false;

        if (item.awsregion !== "") {
          be[`${PREFIX}/vpnconnection${idxItem + 1}/awsregion`] =
            item.awsregion;
          hasValidItem = true;
        }

        if (item.datacenter !== "") {
          be[`${PREFIX}/vpnconnection${idxItem + 1}/datacenter`] =
            item.datacenter;
          hasValidItem = true;
        }

        if (item.cgwasn.value !== "" && item.cgwasn.isValid) {
          be[`${PREFIX}/vpnconnection${idxItem + 1}/cgwasn`] =
            item.cgwasn.value;
          hasValidItem = true;
        }

        if (item.cgwipaddress.value !== "" && item.cgwipaddress.isValid) {
          be[`${PREFIX}/vpnconnection${idxItem + 1}/cgwipaddress`] =
            item.cgwipaddress.value;
          hasValidItem = true;
        }

        if (item.useawsglobalaccelerator) {
          be[`${PREFIX}/vpnconnection${idxItem + 1}/useawsglobalaccelerator`] =
            item.useawsglobalaccelerator;
          hasValidItem = true;
        }

        if (hasValidItem) {
          validItems.push(`vpnconnection${idxItem + 1}`);
        }
      });

      if (validItems.length > 0) {
        be[`${PREFIX}/vpnconnectionslist`] = validItems.join(",");
      }
    }

    return be;
  };

  const addArrayItem = () => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, { array: { $push: [createEntity()] } })
    );
  };

  const removeArrayItem = (idx) => {
    setIsDirty(true);

    setFormData((state) => update(state, { array: { $splice: [[idx, 1]] } }));
  };

  const onArrayItemChange = (idx, key, value) => {
    setErrorMsg(null);
    setIsDirty(true);
    setFormData((state) =>
      update(state, {
        array: { [idx]: { [key]: { value: { $set: value } } } },
      })
    );
  };

  const onArrayItemBlur = (idx, validateFunc, key, value) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          array: {
            [idx]: { [key]: { isValid: { $set: errorMsg === undefined } } },
          },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, {
          array: {
            [idx]: { [key]: { isValid: { $set: false } } },
          },
        })
      );
    }
  };

  const getFieldArrayValidationClass = (idx, key1) => {
    return !formData.array[idx][key1].isValid &&
      formData.array[idx][key1].value !== ""
      ? "invalid"
      : "";
  };

  const isAllowed = (item, idxItem) => {
    if (
      item.awsregion !== "" &&
      item.datacenter !== "" &&
      item.cgwipaddress.value !== "" // &&
      //item.cgwasn.isValid
    ) {
      for (const [idxOtherItem, otherItem] of formData.array.entries()) {
        if (idxItem !== idxOtherItem) {
          if (
            otherItem.awsregion !== "" &&
            otherItem.datacenter !== "" &&
            otherItem.cgwipaddress.value !== "" &&
            otherItem.cgwipaddress.isValid
          ) {
            if (
              item.awsregion === otherItem.awsregion &&
              item.datacenter === otherItem.datacenter &&
              item.cgwipaddress.value === otherItem.cgwipaddress.value
            ) {
              setErrorMsg(
                "Same 'Connect AWS Region', 'to data center' and 'Customer Gateway IP Address' combination is not allowed in any 2 rows"
              );
              return false;
            }
          }
        }
      }
    }
    return true;
  };

  const createEntity = () => {
    return {
      awsregion: "",
      datacenter: "",
      cgwasn: { value: "", isValid: false },
      cgwipaddress: { value: "", isValid: false },
      useawsglobalaccelerator: "yes",
    };
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Network</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Network"
                isDirty={isDirty}
                dashboardRoute={ROUTES.VPC}
                designRoute={ROUTES.DESIGN_NETWORK}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 ">
                <div className="row d-flex align-items-center ">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3 className="font-weight-bold">VPN Connectivity</h3>
                  </div>
                  {(formData.onPremIntegration === "no" ||
                    (formData.regions.length !== 0 &&
                      formData.dataCenters.length !== 0)) && (
                    <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                      <div className="d-inline-flex yes-no-area align-items-center">
                        <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                          Deploy VPN Connectivity
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                          <label
                            className={`createElementBtn btn btn-link btn-linkYes mw-70 ${
                              formData.deployvpnconnectivity.value === "yes" &&
                              "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.deployvpnconnectivity.value === "yes"
                              }
                              onChange={() => {
                                if (formData.onPremIntegration === "no") {
                                  return false;
                                }

                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    deployvpnconnectivity: {
                                      value: { $set: "yes" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            YES
                          </label>
                          <label
                            className={`dontCreateElementBtn btn btn-link btn-linkNo mw-70 ${
                              formData.deployvpnconnectivity.value === "no" &&
                              "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.deployvpnconnectivity.value === "no"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    deployvpnconnectivity: {
                                      value: { $set: "no" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            NO
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {empty && (
                  <NoInitialData />
                )}

                {formData.onPremIntegration === "yes" &&
                (formData.regions.length === 0 ||
                  formData.dataCenters.length === 0) ? (
                  <span className="warning">
                    This section depends on data in AWS Environment and Data
                    Centers sections in Network domain, which are yet to be
                    provided
                  </span>
                ) : formData.onPremIntegration === "no" ? (
                  <span className="warning">
                    This section is not applicable as On-prem Integration is not
                    required. Check 'Establish On-prem Integration' in
                    DataCenters section
                  </span>
                ) : (
                  formData.deployvpnconnectivity.value === "yes" && (
                    <div className="show-hide-content">
                      <div className=" d-flex flex-column   pb-3 pt-0">
                        {formData.array.map((item, idxItem) => (
                          <div
                            key={idxItem}
                            className="row mainContentArea d-flex flex-column px-md-2 px-2 my-1 pb-2 py-1"
                          >
                            <div className="row p-relative">
                              <div className="col-12 px-0 my-1">
                                <p className="color-spc3 font-12 my-0 p-0">
                                  VPN Connection {idxItem + 1}
                                </p>
                              </div>
                              <div className="col-md-11 col-12 formRow flex-wrap d-inline-flex justify-content-start">
                                <div className="d-inline-flex form-line3 wrapped-margin mr-2 my-1">
                                  <span className="font-12  text-nowrap d-flex align-items-center mr-md-2 mr-0">
                                    Connect AWS region
                                  </span>
                                  <select
                                    className="form-control mw-150"
                                    value={item.awsregion}
                                    onChange={(e) => {
                                      setErrorMsg(null);
                                      const value = e.target.value;

                                      if (value) {
                                        if (
                                          isAllowed(
                                            { ...item, awsregion: value },
                                            idxItem
                                          )
                                        ) {
                                          setIsDirty(true);
                                          setFormData((state) =>
                                            update(state, {
                                              array: {
                                                [idxItem]: {
                                                  awsregion: {
                                                    $set: value,
                                                  },
                                                },
                                              },
                                            })
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {formData.regions.map(
                                      (region, idxRegion) => (
                                        <option key={idxRegion} value={region}>
                                          {region}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <div className="d-inline-flex form-line3 wrapped-margin my-1 mr-2">
                                  <span className="font-12 text-nowrap d-flex align-items-center mr-md-2 mr-0">
                                    to data center
                                  </span>
                                  <select
                                    className="form-control mw-150"
                                    value={item.datacenter}
                                    onChange={(e) => {
                                      setErrorMsg(null);
                                      const value = e.target.value;

                                      if (value) {
                                        if (
                                          isAllowed(
                                            { ...item, datacenter: value },
                                            idxItem
                                          )
                                        ) {
                                          setIsDirty(true);
                                          setFormData((state) =>
                                            update(state, {
                                              array: {
                                                [idxItem]: {
                                                  datacenter: {
                                                    $set: value,
                                                  },
                                                },
                                              },
                                            })
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {formData.dataCenters.map((dc, idxDc) => (
                                      <option key={idxDc} value={dc}>
                                        {dc}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="d-inline-flex form-line3 wrapped-margin  my-1 mr-md-2 mr-0">
                                  <span className="font-12 text-nowrap d-flex align-items-center mr-2">
                                    Customer Gateway ASN
                                  </span>
                                  <input
                                    type="text"
                                    className={`form-control mw-150 ${getFieldArrayValidationClass(
                                      idxItem,
                                      "cgwasn"
                                    )}`}
                                    placeholder="64800"
                                    value={item.cgwasn.value}
                                    onChange={(e) => {
                                      onArrayItemChange(
                                        idxItem,
                                        "cgwasn",
                                        e.target.value
                                      );
                                    }}
                                    onBlur={(e) => {
                                      onArrayItemBlur(
                                        idxItem,
                                        validateTgwasn,
                                        "cgwasn",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                                <div className="d-inline-flex form-line3 wrapped-margin my-1">
                                  <span className="font-12 text-nowrap d-flex align-items-center mr-md-2 mr-0">
                                    Customer Gateway IP Addres
                                  </span>
                                  <input
                                    type="text"
                                    className={`form-control mw-150 ${getFieldArrayValidationClass(
                                      idxItem,
                                      "cgwipaddress"
                                    )}`}
                                    placeholder="292.196.55.50"
                                    value={item.cgwipaddress.value}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (
                                        isAllowed(
                                          {
                                            ...item,
                                            cgwipaddress: {
                                              ...item.cgwipaddress,
                                              value: value,
                                            },
                                          },
                                          idxItem
                                        )
                                      ) {
                                        onArrayItemChange(
                                          idxItem,
                                          "cgwipaddress",
                                          value
                                        );
                                      }
                                    }}
                                    onBlur={(e) =>
                                      onArrayItemBlur(
                                        idxItem,
                                        validateIPAddress,
                                        "cgwipaddress",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>

                                <div className="d-inline-flex form-line wrapped-margin my-1">
                                  <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                    <label className="switch mb-0 ml-lg-3 ml-0">
                                      <input
                                        type="checkbox"
                                        checked={
                                          item.useawsglobalaccelerator === "yes"
                                        }
                                        onChange={(e) => {
                                          const value =
                                            e.target.checked === true
                                              ? "yes"
                                              : "no";
                                          setIsDirty(true);
                                          setFormData((state) =>
                                            update(state, {
                                              array: {
                                                [idxItem]: {
                                                  useawsglobalaccelerator: {
                                                    $set: value,
                                                  },
                                                },
                                              },
                                            })
                                          );
                                        }}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel font-weight-light">
                                      Use AWS Global Accelerator
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1 col-12 justify-content-end closeFormRow d-flex align-items-center px-0">
                                <button disabled={disabled}
                                  className="d-flex justify-content-end"
                                  type="button"
                                  onClick={() => removeArrayItem(idxItem)}
                                >
                                  <img src="../images/close.svg" />
                                </button>
                              </div>
                              <div
                                className="col-md-1 col-12 closeFormRowMobil tr-10"
                                type="button"
                                onClick={() => removeArrayItem(idxItem)}
                              >
                                <button disabled={disabled} className="d">
                                  <img src="../images/close.svg" />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="w-100 px-md-2 p-2 mb-2  px-2">
                          <div className="row d-flex align-items-center">
                            <div className="add-button d-flex  justify-content-end">
                              <button disabled={disabled}
                                className="add-new-account-link btn-animation d-inline-flex align-items-center"
                                type="button"
                                onClick={() => addArrayItem()}
                              >
                                <p className="my-0 mr-2 p-0 green-style2">
                                  Add VPN Connection
                                </p>
                                <img src="../images/coloredPlus.svg" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.CROSS_REGION_CONNECTIVITY + "/" + projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Cross-region Connectivity
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.DIRECT_CONNECT_CONNECTIVITY + "/" + projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Direct Connect
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VPNConnectivity;
