
import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getBooleanValue } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "Image Builder Recipes";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
        <>
          <h3>Image Builder</h3>
          <div className="otherTableTitles ">
          <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_COMPONENTS + "/" + projectId}>  
              Components
            </Link>          
            </h3>
            <h3 className="font-15 activetable">  
               Recipes  
            </h3>
            <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_PIPELINES + "/" + projectId}>  
              Pipelines
            </Link>          
            </h3>
            <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_IMAGES + "/" + projectId}>  
               Images
            </Link>
            </h3>                
          </div>
        </>
      );
  };

  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
		    <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Associated Components" && "my_active"
                }`}
                onClick={() => setType("Associated Components")}
              >
                Associated Components
              </li>
             </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
               <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">

                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">AWS Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Recipe Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.imageRecipe && selectedRow.imageRecipe.name}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Version</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.imageRecipe  && selectedRow.imageRecipe.version}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Image OS</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.imageRecipe  && selectedRow.imageRecipe.platform}
                  </p>
                </div>
              </div>

              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                    <p className="m-0 p-0 account-detail-subtitle">
                        Source Image
                    </p>
                    <p className="m-0 p-0 account-title-subcontent">
                        {selectedRow.imageRecipe && selectedRow.imageRecipe.parentImage}
                    </p>
                 </div>
                <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                    Date Created
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.imageRecipe && selectedRow.imageRecipe.dateCreated}
                </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                    Owner
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                {selectedRow.imageRecipe && selectedRow.imageRecipe.owner}
                </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                ARN
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.imageRecipe && selectedRow.imageRecipe.arn}
                </p>
                </div>
                </div>
            
            </div>
           
           {/* TODO:  null values to be replaced with exact keys from json */}
         <DetailsGrid
            type={type}
            typeName="Associated Components"
            array={selectedRow["AssociatedComponents"]}
            metadata={[
              { header: "Component Name", key: "name" },
              { header: "Version", key: "version" },
              { header: "Description", key: "description" },
              { header: "Date Created", key: "dateCreated" },
              { header: "Image OS", key: "platform" },
              { header: "Owner", key: "owner" },
              { header: "ARN", key: "arn" },
            ]}
          ></DetailsGrid> 
            </div>
          </div>
        </div>
    );
  };

  function Navigations() {
  const { projectId } = useParams();
  return (
    <>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.INFRASTRUCTURE_INBOUND_ENDPOINTS + "/" + projectId}>
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Private DNS</p>
      </Link>

      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.INFRASTRUCTURE_BASELINES + "/" + projectId}
      >
        <p className="m-0 p-0 ml-2 font-weight-bold">Patch Management</p>
        <img src="../images/ribbon-designRight.svg" />
      </Link>
    </>
  );
}

const Recipes = () => {
    const columns = useMemo(
        () => [
          {
            Header: "Account ID",
            accessor: "AccountId",
          },
          {
            Header: "AWS Region",
            accessor: "Region",
          },
          {
            Header: "Recipe Name",
            accessor: "imageRecipe.name",
            id: "Recipe Name"
          },
          {
            Header: "Version",
            accessor: "imageRecipe.version",
            id: "Version"
          },
          {
            Header: "Image OS",
            accessor: "imageRecipe.platform",
            id: "Image OS"
          },
          {
            Header: "Source Image",
            accessor: "imageRecipe.parentImage",
            id: "Source Image"
          },
          {
            Header: "Date Created",
            accessor: "imageRecipe.dateCreated",
            id: "Date Created"
          },
          {
            Header: "Owner",
            accessor: "imageRecipe.owner",
            id: "Owner"
          },
          {
            Header: "ARN",
            accessor: "imageRecipe.arn",
            id: "ARN"
          },
          {
            Header: "Description",
            accessor: "imageRecipe.description",
            id: "Description"
          }
        ],
        []
      );

    return Dashboard({
        domain: "infrastructure",
        title: "Infrastructure",
        dashboardName,
        columns,
        Details,
        Navigations,
        configureRoute: ROUTES.INFRASTRUCTURE_IMAGEMANAGEMENT,
        designRoute: ROUTES.DESIGN_INFRASTRUCTURE,
        SubMenu: SubMenu,
      });
};

export default Recipes;