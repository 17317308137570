import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/dataprotection/certificatemanagement";

const SECURITY_ACCOUNT_LIST_KEY = "/aws/accountframework/platformaccounts/securityaccountslist";
const SHARED_SERVICES_ACCOUNT_LIST_KEY = "/aws/accountframework/platformaccounts/sharedservicesaccountslist";
const AWS_REGION_LIST_KEY = "/aws/network/aws/awsregionslist";

const CertificateManagement = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  

  const [formData, setFormData] = useState({
    deploycertificatemanagement:  {value: "yes" },
    deployacmscproduct: {value: "yes" },
    deployacmprivateca: {value: "yes" },
    privatecadeploymentmodel: "",
    awsaccountforrootca: {value: "" },
    awsaccountforrootcalist: [],
    awsaccountforsubordinateca: {value: "" }, 
    awsaccountforsubordinatecalist: [],
    subordinatecaregionlist: [],
    awsregionlist:[],
    monitorhighriskevents:  {value: "yes" }

  });

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        console.log(result);       

        setEmpty(result.data.empty);

         const securityAccountsResults = await awsAmplify.loadProjectData(projectId, SECURITY_ACCOUNT_LIST_KEY);
          console.log(securityAccountsResults);
          

          const sharedServicesAccountsResults = await awsAmplify.loadProjectData(projectId, SHARED_SERVICES_ACCOUNT_LIST_KEY);
          console.log(sharedServicesAccountsResults);

          const securityaccountsdata = securityAccountsResults.data &&  securityAccountsResults.data[SECURITY_ACCOUNT_LIST_KEY] 
                                  ? securityAccountsResults.data[SECURITY_ACCOUNT_LIST_KEY].split(",") : [];

          const sharedservicesdata = sharedServicesAccountsResults.data &&  sharedServicesAccountsResults.data[SHARED_SERVICES_ACCOUNT_LIST_KEY] 
                                    ? sharedServicesAccountsResults.data[SHARED_SERVICES_ACCOUNT_LIST_KEY].split(",") : [];
                               
         const accountslist = [];
        securityaccountsdata.forEach(element => {
            accountslist.push(element);
          }); 
          sharedservicesdata.forEach(element => {
            accountslist.push(element);
         });
          
         const awsRegionData = await awsAmplify.loadProjectData(projectId, AWS_REGION_LIST_KEY);
          console.log(awsRegionData);
          setIsLoading(false);
         

        const fe = convertBEToFE(
          result.data,
          accountslist,
          awsRegionData.data
        );

        setFormData(fe);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response && err.response.data.message);

        if(err.response && err.response.data.message === "You have no access"){
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);

      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };


  const convertBEToFE = (
    data,
    accountslist,
    awsRegionDatalist
      ) => {
    if (!data) {
      data = {};
    }

    const fe = {
    deploycertificatemanagement: data[`${PREFIX}/deploycertificatemanagement`] || "yes",
    deployacmscproduct: data[`${PREFIX}/deployacmscproduct`] || "yes" ,
    deployacmprivateca: data[`${PREFIX}/deployacmprivateca`] || "yes",
    privatecadeploymentmodel: data[`${PREFIX}/privatecadeploymentmodel`] || "",
    awsaccountforrootca: data[`${PREFIX}/awsaccountforrootca`] || "",
    awsaccountforrootcalist: accountslist ? accountslist : [],
    awsaccountforsubordinateca: data[`${PREFIX}/awsaccountforsubordinateca`] || "", 
    awsaccountforsubordinatecalist: accountslist ? accountslist : [],
    subordinatecaregionlist : data[`${PREFIX}/subordinatecaregionlist`] ?
                                 data[`${PREFIX}/subordinatecaregionlist`].split(",") : [],
    awsregionlist: awsRegionDatalist &&  awsRegionDatalist[AWS_REGION_LIST_KEY] 
                            ? awsRegionDatalist[AWS_REGION_LIST_KEY].split(",") : [], 
    monitorhighriskevents: data[`${PREFIX}/monitorhighriskevents`] || "yes"    
    };

    if(fe.subordinatecaregionlist.indexOf("NA") !== -1)
    {
      fe.subordinatecaregionlist= [];
    }
    fe.awsaccountforrootca = fe.awsaccountforrootca === 'NA' ? "" : fe.awsaccountforrootca;
    fe.awsaccountforsubordinateca = fe.awsaccountforsubordinateca === 'NA' ? "" : fe.awsaccountforsubordinateca;
    return fe;
  };

  const convertFEToBE = ( ) => {
    const be = {
      [`${PREFIX}/deploycertificatemanagement`]: formData.deploycertificatemanagement,
    };

    if (formData.deploycertificatemanagement === "yes") {
        if (formData.deployacmscproduct !== "") {
          be[`${PREFIX}/deployacmscproduct`] = formData.deployacmscproduct;
        }
         
        if (formData.deployacmprivateca !== "") {
            be[`${PREFIX}/deployacmprivateca`] = formData.deployacmprivateca;
          
        if(formData.deployacmprivateca !== "no")
        {
          if (formData.privatecadeploymentmodel !== "") {
          be[`${PREFIX}/privatecadeploymentmodel`] = formData.privatecadeploymentmodel;

          if(formData.privatecadeploymentmodel === "subordinatetoexternalprivateca")
          {
            be[`${PREFIX}/awsaccountforrootca`] = "NA";
            if (formData.awsaccountforsubordinateca !== "") {
              be[`${PREFIX}/awsaccountforsubordinateca`] = formData.awsaccountforsubordinateca; 
            }
            if (formData.subordinatecaregionlist.length > 0) {
              be[`${PREFIX}/subordinatecaregionlist`] = formData.subordinatecaregionlist.join(","); 
            }          
          }
          else if(formData.privatecadeploymentmodel === "rootcaonly")
          {
              be[`${PREFIX}/awsaccountforsubordinateca`] =  "NA";
              be[`${PREFIX}/subordinatecaregionlist`] = "NA"
              if (formData.awsaccountforrootca !== "") {
                be[`${PREFIX}/awsaccountforrootca`] = formData.awsaccountforrootca; 
            }
          }
          else
          {         
              if (formData.awsaccountforrootca !== "") {
                  be[`${PREFIX}/awsaccountforrootca`] = formData.awsaccountforrootca; 
              }
              if (formData.awsaccountforsubordinateca !== "") {
                  be[`${PREFIX}/awsaccountforsubordinateca`] = formData.awsaccountforsubordinateca; 
              }
              if (formData.subordinatecaregionlist != "none" && formData.subordinatecaregionlist.length > 0) {
                be[`${PREFIX}/subordinatecaregionlist`] = formData.subordinatecaregionlist.join(","); 
            }
          }
          } 
        }
        else
        {
          be[`${PREFIX}/privatecadeploymentmodel`] = "none";
          be[`${PREFIX}/subordinatecaregionlist`] = "none";
        }

        if (formData.monitorhighriskevents !== "") {
            be[`${PREFIX}/monitorhighriskevents`] = formData.monitorhighriskevents;
            }     
        }
    }
  return be;
};

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };


  const onFieldChange = (value, key1) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key1]: { $set: value } })
    );
  };

    const exists = (value) => {
        return formData.subordinatecaregionlist.indexOf(value.region) !== -1;
      };
    
      const modifyArray = (value) => {
        setIsDirty(true);
        const newRegion = value.region;    
        if (exists(value)) {
            setFormData((state) => {
                return {
                  ...state,
                  subordinatecaregionlist: update(state.subordinatecaregionlist, {
                    $splice: [[state.subordinatecaregionlist.indexOf(newRegion), 1]],
                  })
                };
              });            
        } else {       
            
          setFormData((state) => {
              return {
                  ...state,
              subordinatecaregionlist : update(state.subordinatecaregionlist,  {$push: [newRegion]})  
              };
          });
        }
      };
    

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Data Protection</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
          <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Data Protection"
                isDirty={isDirty}
                dashboardRoute={ROUTES.DATA_PROTECTION_ACM}
                designRoute={ROUTES.DESIGN_DATA_PROTECTION}
                isConfigure={true}
                isDesign={false}
                isDashboard={false}
              ></DomainMenu>

<fieldset disabled={disabled} className="mainArea fitDeviceHeight">
            <div className="mainArea fitDeviceHeight certificate-management-page flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div className="row d-flex align-items-center">
                    <div className="col-xl-4 col-lg-4 col-md-4 pl-lg-0 px-2">
                        <h3>Certificate Management</h3>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 d-flex align-items-center justify-content-end rightNavArea px-0">
                    <div className="d-inline-flex yes-no-area align-items-center">
                        <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 text-nowrap">
                            Deploy Centralized Certificate Management
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                            <label
                              className={`createElementBtn btn btn-link btn-linkYes h-20 ${
                               formData &&  formData.deploycertificatemanagement ===  "yes" && "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name={`${PREFIX}/deploycertificatemanagement`}
                                checked={
                                  formData.deploycertificatemanagement === "yes"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                        deploycertificatemanagement: { $set: "yes" },
                                    })
                                  );
                                }}
                              />{" "}
                              YES
                            </label>
                            <label
                              className={`dontCreateElementBtn btn btn-link btn-linkNo h-20 ${
                                formData.deploycertificatemanagement === "no" && "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name={`${PREFIX}/deploycertificatemanagement`}
                                checked={
                                  formData.deploycertificatemanagement === "no"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                        deploycertificatemanagement: { $set: "no" }
                                    })
                                  );
                                }}
                              />{" "}
                              NO
                            </label>
                          </div>                
                    </div>
                    </div>
                </div>

                {empty && (
                  <NoInitialData />
                )}
           
                {formData.deploycertificatemanagement === "yes" && (
                <div className="row">
                    <div className="row  py-1 my-1">
                    <div className="col-12 px-1 my-1">
                    <div className="w-100">
                        <div className="d-inline-flex yes-no-area align-items-center">
                                <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                                    Deploy Centralized ACM SC Product
                                </div>
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                                    <label
                                    className={`createElementBtn btn btn-link btn-linkYes h-20 ${
                                        formData.deployacmscproduct ===  "yes" && "active"
                                    }`}
                                    >
                                    <input
                                        type="radio"
                                        name={`${PREFIX}/deployacmscproduct`}
                                        checked={
                                        formData.deployacmscproduct === "yes"
                                        }
                                        onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                            update(state, {
                                                deployacmscproduct: { $set: "yes" },
                                            })
                                        );
                                        }}
                                    />{" "}
                                    YES
                                    </label>
                                    <label
                                    className={`dontCreateElementBtn btn btn-link btn-linkNo h-20 ${
                                        formData.deployacmscproduct === "no" && "active"
                                    }`}
                                    >
                                    <input
                                        type="radio"
                                        name={`${PREFIX}/deployacmscproduct`}
                                        checked={
                                        formData.deployacmscproduct === "no"
                                        }
                                        readOnly = {true}
                                    />{" "}
                                    NO
                                    </label>
                                 </div>
                  
                        </div> 
                    </div>   
                    </div>
                    </div>  

                 <div className="row  py-1 my-1">
                 <div className="col-12 px-1 my-1">
                        <div className="w-100">
                            <div className="d-inline-flex yes-no-area align-items-center">
                                    <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                                        Deploy Private CA
                                    </div>
                                    <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                                    <label
                                    className={`createElementBtn btn btn-link btn-linkYes h-20 ${
                                        formData.deployacmprivateca ===  "yes" && "active"
                                    }`}
                                    >
                                    <input
                                        type="radio"
                                        name={`${PREFIX}/deployacmprivateca`}
                                        checked={
                                        formData.deployacmprivateca === "yes"
                                        }
                                        onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                            update(state, {
                                                deployacmprivateca: { $set: "yes" },
                                            })
                                        );
                                        }}
                                    />{" "}
                                    YES
                                    </label>
                                    <label
                                    className={`dontCreateElementBtn btn btn-link btn-linkNo h-20 ${
                                        formData.deployacmprivateca === "no" && "active"
                                    }`}
                                    >
                                    <input
                                        type="radio"
                                        name={`${PREFIX}/deployacmprivateca`}
                                        checked={
                                        formData.deployacmprivateca === "no"
                                        }
                                        onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                            update(state, {
                                                deployacmprivateca: { $set: "no" }
                                            })
                                        );
                                        }}
                                    />{" "}
                                    NO
                                    </label>
                                 </div>
                                </div> 
                        </div>   
                  </div>
                </div>

                  {formData.deployacmprivateca === "yes" && ( 
                    <div className="mainContentArea row  py-1 my-1">        
                     <div className="d-inline-flex align-items-start row my-1 px-1">
                     <div className="col-md-3 my-1 p-0 d-flex">
                       <span className="mb-0 font-12">Deployment Model</span>
                      </div>
                     <div className="col-md-9 flex-wrap d-flex flex-column">            
                         <label className="container font-12 my-2 pl-4-important">
                              Root CA only (also the Issuing CA)
                             <input type="radio" 
                                    name= {`${PREFIX}/privatecadeploymentmodel`}
                                    value="rootcaonly"
                                    defaultChecked = {
                                        formData.privatecadeploymentmodel === "rootcaonly"
                                    }
                                    onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            privatecadeploymentmodel: { $set: "rootcaonly" },
                                          })
                                        );
                                      }}
                                      />
                             <span className="checkmark"></span>
                         </label>
                         <label className="container font-12 my-2 pl-4-important">
                              Root CA and a Subordinate Issuing CA
                             <input type="radio" 
                                    name={`${PREFIX}/privatecadeploymentmodel`}
                                    value="rootandsubordinateca"
                                    defaultChecked = {
                                       formData.privatecadeploymentmodel === "rootandsubordinateca"
                                    }
                                    onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            privatecadeploymentmodel: { $set: "rootandsubordinateca" },
                                          })
                                        );
                                      }}
                                   />
                             <span className="checkmark"></span>
                         </label>
                         <label className="container font-12 my-2 pl-4-important">
                             Subordinate Issuing CA to an External Private CA
                             <input type="radio"
                                    name = {`${PREFIX}/privatecadeploymentmodel`}
                                    value="subordinatetoexternalprivateca"
                                    defaultChecked = {
                                        formData.privatecadeploymentmodel === "subordinatetoexternalprivateca"
                                    }
                                    onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            privatecadeploymentmodel: { $set: "subordinatetoexternalprivateca" },
                                          })
                                        );
                                      }}
                             />
                             <span className="checkmark"></span>
                         </label>                      
                    </div>
                   </div>
                    
                   {(formData.privatecadeploymentmodel !== "subordinatetoexternalprivateca") && (
                    <div className="d-inline-flex align-items-center row px-1">
                     <div className="col-md-3 p-0 my-1">
                       <span className="mb-0 font-12">AWS Account for Root CA</span>
                      </div>
                     <div className="col-md-9 flex-wrap d-inline-flex">
                         <div className="mr-3 my-1">
                            
                             <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                             <select
                                className="form-control gray"
                                value={formData.awsaccountforrootca }
                                onChange={(e) => {
                                    onFieldChange(
                                    e.target.value,
                                    "awsaccountforrootca"
                                );
                              }} >   
                                 <option value="">Select</option>
                                 <option value="master">master</option>
                                    { formData.awsaccountforrootcalist && formData.awsaccountforrootcalist.map(
                                      (account, idxAccount) => (
                                        <option key={idxAccount} value={account}>
                                          {account}
                                        </option>
                                      )
                                    )}
                             </select>
                             </div>
                         </div>
                       </div>
                   </div> )}

                    {(formData.privatecadeploymentmodel !== "rootcaonly" ) && (
                      <>
                    <div className="d-inline-flex align-items-center row px-1">
                     <div className="col-md-3 p-0 my-1">
                       <span className="mb-0 font-12">AWS Account for Subordinate Issuing CA</span>
                      </div>
                     <div className="col-md-9 flex-wrap d-inline-flex">
                         <div className="mr-3 my-1">
                             <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                             {(formData && formData.awsaccountforsubordinatecalist.length === 0)  ?
                            (
                              <div className="col-md-12 flex-wrap d-inline-flex">
                              <span className="warning">
                              This section depends on the platform accounts information in
                              Account Framework section that is yet to be provided
                            </span>
                            </div>
                            ) : (
                              <> 
                             <select
                                className="form-control gray"
                                value={formData.awsaccountforsubordinateca}
                                onChange={(e) => {
                                    onFieldChange(
                                    e.target.value,
                                    "awsaccountforsubordinateca"
                                );
                              }} >   
                                 <option value="">Select</option>
                                    { formData.awsaccountforsubordinatecalist && formData.awsaccountforsubordinatecalist.map(
                                      (account, idxAccount) => (
                                        <option key={idxAccount} value={account}>
                                          {account}
                                        </option>
                                      )
                                    )}
                             </select>
                             </>
                            )}
                             </div>
                         </div>
                       </div>
                   </div> 
                    
                     <div className="d-inline-flex align-items-center row px-1">
                     <div className="col-md-3 p-0 my-1">
                       <span className="mb-0 font-12">Issuing CA Regions</span>
                      </div>
                     <div className="col-md-9 flex-wrap d-inline-flex">
                     {formData.awsregionlist.length === 0 ? (
                            <span className="warning">
                              This depends on the AWS Regions information in
                              'AWS Environment" section of 'Network' domain that
                              is yet to be provided.
                            </span>
                          ) : (                                            
                        formData.awsregionlist  && formData.awsregionlist.map((region,idxregion) => 
                        (<div className="mr-3 my-1">
                              <div className="form-line my-1  d-inline-flex align-items-center">
                                  <label className="switch mb-0">
                                    <input type="checkbox" 
                                           name= {`${PREFIX}/subordinatecaregionlistoption/${idxregion}`} 
                                           value = {region}
                                           checked = {exists({region})}
                                           onChange={() => {
                                            modifyArray({region})
                                    }}
                                      />
                                    <span className="slider round"></span>
                                  </label>
                                  <span className="switchLabel ">{region}</span>
                              </div>
                           </div>
                        )))}
                       </div>
                   </div>
                     </> )}
                 </div> 
                  )}
                   
                 <div className="row  py-1 my-1">
                     <div className="col-12 px-1 my-1">
                      <div className="w-100">
                         <div className="d-inline-flex yes-no-area align-items-center">
                            <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                                Monitor High-risk Events 
                            </div>
                            <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                            <label className={`createElementBtn btn btn-link btn-linkYes h-20 ${
                                   formData.monitorhighriskevents === "yes" && "active" }`} >
                                <input type="radio" name={`${PREFIX}/monitorhighriskevents`}
                                  checked={ 
                                            formData.monitorhighriskevents === "yes"  
                                         }
                                  onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                        monitorhighriskevents:{ $set: "yes" }
                                      },
                                    )
                                  );
                                }}
                              />{" "}
                              YES
                            </label>

                            <label
                              className={`dontCreateElementBtn btn btn-link btn-linkNo h-20 ${
                                formData.monitorhighriskevents === "no" && "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name={`${PREFIX}/monitorhighriskevents`}
                                checked={
                                  formData.monitorhighriskevents === "no"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                        monitorhighriskevents: {  $set:  "no" },
                                    })
                                  );
                                }}
                              />{" "}
                              NO
                            </label>
                           </div>
                        </div> 
                      </div>   
                     </div>
                    </div>
    
                </div>
                
                )}
            </div>
            </fieldset>
            <div class="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
              
              <button class="btn-post btn-animation d-inline-flex"
               onClick={() =>
                saveDataIfDirty(ROUTES.DATA_PROTECTION_BACKUP_ARCHIVAL + "/" + projectId)
              }>            
               <img src="../images/ribbon-design.svg"></img>
               <p class="m-0 p-0 ml-2 font-weight-bold">Backup & Archival</p>
           </button>
             
             <button class="btn-post btn-animation d-inline-flex"
              onClick={() =>
                saveDataIfDirty(ROUTES.DATA_PROTECTION_KEY_MANAGEMENT + "/" + projectId)
              }>           
                 <p class="m-0 p-0 mr-2 font-weight-bold">Key Management</p>
               <img src="../images/ribbon-designRight.svg">
               </img>
           </button>
         </div>
     </form>
 
        </div>
       </div></div>
       
   </>
    );
};
export default CertificateManagement;
