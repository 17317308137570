import { useEffect, useState } from "react";
import useAwsAmplify from "./useAwsAmplify";

const usePrimaryRegion = (projectId) => {
  const awsAmplify = useAwsAmplify();
  const [data, setData] = useState();

  useEffect(() => {
    const fetch = async () => {
      const getProjectTypeResp = await awsAmplify.getProjectType(
        projectId);

      const projectType = getProjectTypeResp.data.getProjectType;

      const KEY = `/${projectType.toLowerCase()}/network/aws/awsprimaryregion`;

      const projectData = await awsAmplify.loadProjectData(
        projectId,
        KEY
      );

      if (
        projectData.data &&
        projectData.data[KEY]
      ) {
        setData(projectData.data[KEY]);
      }
    };

    fetch();
  }, [projectId]);

  return data;
};

export default usePrimaryRegion;
