import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { Link, useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import {
  validate1to15AlphaNumeric,
  validateAccountName,
  validateAccountEmailId,
} from "../../../../helpers/validator";
import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const projectId = "google_1";
const PREFIX = "/aws/accountframework/sandboxaccounts";
const ORG_STRUCTURE_PREFIX =  "/aws/accountframework/organizationalstructure";

const createEntity = () => {
  return {
    name: {
      value: "",
      isValid: false,
    },
    email: {
      value: "",
      isValid: false,
    },
  };
};

const SandboxAccounts = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    createsandboxaccounts: {
      value: "yes",
    },
    array: [createEntity()],
    OUnames:[],
    OUName : {
      value: ""
    }
  });
  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);
        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        const orgStructure = await awsAmplify.loadProjectData(projectId, ORG_STRUCTURE_PREFIX);
        setIsLoading(false);

        console.log(result);

        setEmpty(result && result.data && result.data.empty);

        const fe = convertBEToFE(result, orgStructure);
         setFormData(fe);
       
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response && err.response.data.message);

        if(err.response.data.message === "You have no access"){
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);
      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (result, orgStructure) => {
    const fe = {
      createsandboxaccounts: {
        value: "yes",
      },
      array: [],
      OUnames: [],
      OUName : {
        value: ""
      }
    };

    if (result && result.data && !result.data.empty) {
      let data = result.data;
      fe.createsandboxaccounts.value = data[`${PREFIX}/createsandboxaccounts`] || "yes";

      if (data[`${PREFIX}/createsandboxaccounts`] === "yes") {
        if (data[`${PREFIX}/sandboxaccountslist`]) {
          const accountNames = data[`${PREFIX}/sandboxaccountslist`].split(
            ","
          );

          for (const accountName of accountNames) {
            const entity = createEntity();

            entity.name.value = accountName;
            entity.name.isValid = true;

            if (data[`${PREFIX}/${accountName}/accountemailid`]) {
              entity.email.value =
                data[`${PREFIX}/${accountName}/accountemailid`] || "";
              entity.email.isValid = true;
            }

            fe.array.push(entity);
          }
        }
        else
        {
          fe.array.push(createEntity());
        }
        if (data[`${PREFIX}/absoluteouname`]) {
          fe.OUName.value =  data[`${PREFIX}/absoluteouname`];
        }
      }
    } else {
      fe.array.push(createEntity());
    }

    if(orgStructure !== undefined && orgStructure.data)
    {
      const OU_data = orgStructure.data;
      var OUs = OU_data[`${ORG_STRUCTURE_PREFIX}/oulist`]; 
      fe.OUnames = OUs && OUs.split(',').filter(Boolean) || [];
    }

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/createsandboxaccounts`]: formData.createsandboxaccounts
        .value,
    };

    if (formData.createsandboxaccounts.value === "yes") {
      const sandboxaccountslist = [];

      for (const item of formData.array) {
        console.log(item);
        if (item.name.isValid) {
          sandboxaccountslist.push(item.name.value);

          if (item.email.isValid) {
            be[`${PREFIX}/${item.name.value}/accountemailid`] =
              item.email.value;
          }
        }
      }

      if (sandboxaccountslist.length > 0) {
        be[`${PREFIX}/sandboxaccountslist`] = sandboxaccountslist.join(",");
      }
      if(formData.OUName && formData.OUName.value != "")
      {
        be[`${PREFIX}/absoluteouname`] = formData.OUName.value
        let ouname = be[`${PREFIX}/absoluteouname`].split('/');
        let lastStrOU = ouname[ouname.length-1];
        be[`${PREFIX}/ouname`] = lastStrOU;
      }

    }

    console.log(be);

    return be;
  };

  const addApp = () => {
    setIsDirty(true);

    setFormData((state) => {
      return {
        ...state,
        array: update(state.array, { $push: [createEntity()] }),
      };
    });
  };

  const removeApp = (idx) => {
    setIsDirty(true);

    setFormData((state) => {
      return {
        ...state,
        array: update(state.array, { $splice: [[idx, 1]] }),
      };
    });
  };

  const updateArrayValue = (idx, key, value) => {
    setFormData((state) => {
      return {
        ...state,
        array: update(state.array, {
          [idx]: {
            [key]: {
              value: { $set: value },
            },
          },
        }),
      };
    });
  };

  const updateArrayIsValid = (idx, key, isValid) => {
    setFormData((state) => {
      return {
        ...state,
        array: update(state.array, {
          [idx]: {
            [key]: {
              isValid: { $set: isValid },
            },
          },
        }),
      };
    });
  };

  const onFieldChange = (name,value) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [name]: { value: { $set: value } } })
    );
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Account Framework</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
          <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                isDirty={isDirty}
                dashboardRoute={ROUTES.ORGANIZATIONAL_UNITS}
                designRoute={ROUTES.DESIGN_ACCOUNT_FRAMEWORK}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 ">
                <div className="row d-flex align-items-center">
                  <div className="col-xl-5 col-lg-4 col-md-4 pl-lg-0 px-2">
                    <h3>Sandbox Accounts</h3>
                  </div>
                  <div className="px-0 col-xl-7 col-lg-8 col-md-8 d-flex align-items-center justify-content-end rightNavArea">
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 text-nowrap">
                        Create Sandbox Accounts
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100">
                        <label
                          className={`createElementBtn btn btn-link mw-70 btn-linkYes ${
                            (formData && formData.createsandboxaccounts &&
                                 formData.createsandboxaccounts.value) === "yes"
                              ? "active"
                              : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="createsandboxaccounts"
                            value="yes"
                            checked={
                             (formData && formData.createsandboxaccounts &&
                              formData.createsandboxaccounts.value) === "yes"
                            }
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) => {
                                return {
                                  ...state,
                                  createsandboxaccounts: {
                                    value: "yes",
                                  },
                                };
                              });
                            }}
                          />{" "}
                          YES
                        </label>
                        <label
                          className={`dontCreateElementBtn btn btn-link mw-70 btn-linkNo ${
                            (formData && formData.createsandboxaccounts &&
                            formData.createsandboxaccounts.value) === "no"
                              ? "active"
                              : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="createsandboxaccounts"
                            value="no"
                            checked={
                              (formData && formData.createsandboxaccounts &&
                              formData.createsandboxaccounts.value) === "no"
                            }
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) => {
                                return {
                                  ...state,
                                  createsandboxaccounts: {
                                    value: "no",
                                  },
                                };
                              });
                            }}
                          />{" "}
                          NO
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {empty && (
                    <NoInitialData />
                )}
                { (formData && formData.createsandboxaccounts && formData.createsandboxaccounts.value) === "yes" && (
                  <div className="show-hide-content">
                     <div className="row">
                       <div className=" selectionArea d-inline-flex flex-wrap align-items-center my-2  p-1">
                       <div className="col-md-1 flex-wrap my-1 p-0 mx-3">
                         <span className="mb-0 font-12">
                         Sandbox OU
                        </span>
                      </div>
                      { (formData.OUnames && formData.OUnames.length === 0) ? (
                      <div className="col-md-6 flex-wrap d-inline-flex">
                          <span className="warning">
                          This section depends on the organizational structure information in
                          Account Framework section that is yet to be provided
                        </span>
                        </div>
                      ) : (
                        <>
                       <div className="col-md-6 flex-wrap d-inline-flex">
                        <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                        <select className="form-control bg-gray pr-lg-0 pr-5" 
                            disabled={disabled}
                              value={(formData.OUName && formData.OUName.value)}
                              onChange={(e) => {
                              onFieldChange(
                                "OUName",
                                 e.target.value
                              );
                              setIsDirty(true);
                            }}>
                        <option value="">Select</option>
                        {formData.OUnames && formData.OUnames.map((OUName, idxV) => (
                        (OUName !== "") &&
                          <option key={idxV} value={OUName}>
                            {OUName}
                          </option>
                        ))}</select>             
                         </div>
                        </div>
                        </>
                      )}
                        </div>
                     </div>
                    <div className="d-flex flex-column mainContentArea  px-3 py-1 my-1">
                      {formData.array.map((item, idx) => {
                        return (
                          <div
                            className=" d-inline-flex my-1 formRow px-md-2 px-3 align-items-center justify-content-between"
                            key={idx}
                          >
                            <div className="d-flex align-items-center justify-content-center number mr-xl-5 mr-lg-4 mr-md-3 mr-1 my-lg-0   my-1 one">
                              {idx + 1}
                            </div>
                            <div className="d-inline-flex form-line two my-lg-0  my-1">
                              <div className="d-flex align-items-center mx-xl-5">
                                <b className="text-nowrap">Account Name</b>
                              </div>
                              <input
                                type="text"
                                className={
                                  !item.name.isValid && item.name.value !== ""
                                    ? "form-control invalid"
                                    : "form-control"
                                }
                                placeholder={`Account Name ${idx + 1}`}
                                value={(item.name && item.name.value) || ""}
                                onChange={(e) => {
                                  setIsDirty(true);
                                  setErrorMsg("");

                                  const value = e.target.value;

                                  for (const [
                                    idxOtherItem,
                                    otherItem,
                                  ] of formData.array.entries()) {
                                    if (idx !== idxOtherItem) {
                                      if (
                                        otherItem.name.isValid &&
                                        otherItem.name.value !== ""
                                      ) {
                                        if (
                                          otherItem.name.value.toLowerCase() ===
                                          value.toLowerCase()
                                        ) {
                                          setErrorMsg(
                                            "Account name must be unique"
                                          );
                                          return false;
                                        }
                                      }
                                    }
                                  }

                                  updateArrayValue(idx, "name", e.target.value);
                                }}
                                onBlur={(e) => {
                                  const value = e.target.value;

                                  if (value !== "") {
                                    const errorMsg = validateAccountName(value);

                                    updateArrayIsValid(
                                      idx,
                                      "name",
                                      errorMsg === undefined
                                    );

                                    if (errorMsg) {
                                      setErrorMsg(errorMsg);
                                    }
                                  } else {
                                    updateArrayIsValid(idx, "name", false);
                                  }
                                }}
                              />
                            </div>

                            <div className="d-inline-flex form-line my-lg-0 my-1 three pl-xl-4 ml-xl-4 pl-lg-2 ml-lg-2  ml-0">
                              <div className="d-flex align-items-center mx-xl-5 font-weight-light">
                                <span className="text-nowrap">
                                 Email Id
                                </span>
                              </div>
                              <input
                                disabled={!item.name.isValid}
                                type="text"
                                className={
                                  !item.email.isValid && item.email.value !== ""
                                    ? "form-control invalid"
                                    : "form-control"
                                }
                                placeholder={`E.g. email${
                                  idx + 1
                                }@client.com`}
                                value={(item.email && item.email.value) || ""}
                                onChange={(e) => {
                                  setIsDirty(true);
                                  setErrorMsg("");

                                  const value = e.target.value;

                                  for (const [
                                    idxOtherItem,
                                    otherItem,
                                  ] of formData.array.entries()) {
                                    if (idx !== idxOtherItem) {
                                      if (
                                        otherItem.email.isValid &&
                                        otherItem.email.value !== ""
                                      ) {
                                        if (
                                          otherItem.email.value.toLowerCase() ===
                                          value.toLowerCase()
                                        ) {
                                          setErrorMsg(
                                            "Developer Email Id must be unique"
                                          );
                                          return false;
                                        }
                                      }
                                    }
                                  }

                                  updateArrayValue(
                                    idx,
                                    "email",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  const value = e.target.value;

                                  if (value !== "") {
                                    const errorMsg = validateAccountEmailId(
                                      value
                                    );

                                    updateArrayIsValid(
                                      idx,
                                      "email",
                                      errorMsg === undefined
                                    );

                                    if (errorMsg) {
                                      setErrorMsg(errorMsg);
                                    }
                                  } else {
                                    updateArrayIsValid(idx, "email", false);
                                  }
                                }}
                              />
                            </div>
                            <button disabled={disabled}
                              type="button"
                              className="closeFormRow closeFormRowBtn my-lg-0   my-1 four"
                              onClick={() => removeApp(idx)}
                            >
                              <img src="../images/bigfontclose.svg" />
                            </button>
                            <button disabled={disabled}
                              type="button"
                              className="closeFormRowMobil closeFormRowBtn four"
                              onClick={() => removeApp(idx)}
                            >
                              <img src="../images/bigfontclose.svg" />
                            </button>
                          </div>
                        );
                      })}

                      <div className="w-100  mb-2  ">
                        <div className="row d-flex align-items-center">
                          <div className="add-button d-flex px-1  justify-content-end">
                            <button disabled={disabled}
                              type="button"
                              className="add-new-account-link btn-animation d-inline-flex align-items-center"
                              onClick={() => addApp()}
                            >
                              <p className="my-0 mr-2 p-0 green-style2">
                                Add Sandbox Account
                              </p>
                              <img src="../images/coloredPlus.svg" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              </fieldset>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={async () => {
                      if (isDirty) {
                        await submit();
                      }
                      history.push(
                        ROUTES.WORKLOAD_ACCOUNTS + "/" + projectId
                      );
                    }}
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                    Workload Accounts
                    </p>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {console.log(formData)}
    </>
  );
};

export default SandboxAccounts;
