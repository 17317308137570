import React, { useState, useEffect, useMemo } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Helmet } from "react-helmet-async";

import TopMenu from "../TopMenu";
import useAwsAmplify from "../../hooks/useAwsAmplify";
import useHideLeftMenu from "../../hooks/useHideLeftMenu";

import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import useSession from "../../hooks/useSession";
import { getError } from "../../helpers/errorHelper";

const Logs = () => {
  const awsAmplify = useAwsAmplify();
  const { authUser, isUser, isClientAdmin, isSuperAdmin, group } = useSession();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const [next, setNext] = useState(null);
  const [current, setCurrent] = useState(null);
  const [previous, setPrevious] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "Date",
      },
      {
        Header: "Performed By",
        accessor: "PerformedBy",
      },
      {
        Header: "Action",
        accessor: "Action",
      },
      {
        Header: "Additional Information",
        accessor: "AddInfo",
      },
    ],
    []
  );

  const {
    rows,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        globalFilter: "",
        pageIndex: 0,
        pageSize: 10,
        //sortBy: [{ id: "Name", desc: false }],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const fetchData = async () => {
    try {
      setData([]);

      setErrorMsg(null);
      setIsLoading(true);

      const results = await awsAmplify.getLogs(current);
      setIsLoading(false);

      console.log(results);

      if (results.data && results.data.getLogs) {
        setData(results.data.getLogs.Items);
        setSortBy([{ id: "Date", desc: true }]);

        if (results.data.getLogs.LastEvaluatedKey) {
          setNext(results.data.getLogs.LastEvaluatedKey);
        } else {
          setNext(null);
        }
      }
    } catch (err) {
      setIsLoading(false);

      getError(err, setErrorMsg);
    }
  };

  useEffect(() => {
    fetchData();
  }, [current]);

  useHideLeftMenu();

  return (
    <>
      <Helmet>
        <body className="tableContaining superusers" />
      </Helmet>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Admin</div>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreen h-100 d-flex flex-column">
            <TopMenu currentRouteIsAdmin={true}></TopMenu>
            <div className="tableArea accountsTableArea  accountsTitledTable container-fluid p-0 d-flex flex-column">
              <div>
                <div className="topInfoNav justify-content-between align-items-start">
                  <ul className=" d-inline-flex leftNav">
                    <li className="px-xl-5 py-xl-4 px-lg-5 py-lg-1 px-4 py-1">
                      <a className="px-xl-4 px-lg-0">Admin</a>
                    </li>
                  </ul>
                  <ul className="m-0 py-0 rightNav d-inline-flex px-0">
                    {isSuperAdmin && (
                      <li className=" mx-xl-4 mx-lg-1 mx-0 px-xl-4 py-xl-3 px-lg-3 py-lg-2 px-2 py-1">
                        <Link to={ROUTES.ADMIN_CLIENTS}>CLIENTS</Link>
                      </li>
                    )}
                    <li className=" mx-xl-4 mx-lg-1 mx-0 px-xl-4 py-xl-3 px-lg-3 py-lg-2 px-2 py-1">
                      <Link to={ROUTES.ADMIN_USERS}>USERS</Link>
                    </li>
                    <li className="mx-xl-4 mx-lg-1 mx-0 px-xl-4 py-xl-3 px-lg-3 py-lg-2 px-2 py-1">
                      <Link to={ROUTES.ADMIN_PROJECTS}>PROJECTS</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mainArea fitDeviceHeight network-aws-environment flex-column px-md-1 px-0">
                <div>
                  <div
                    id="datatable_wrapper"
                    className="dataTables_wrapper no-footer h-100"
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-7">
                        <div
                          className="dataTables_length bs-select"
                          id="datatable_length"
                        >
                          <h3>Logs</h3>

                          <label>
                            Show
                            <select
                              name="datatable_length"
                              aria-controls="datatable"
                              className="custom-select custom-select-sm form-control form-control-sm"
                              value={pageSize}
                              onChange={(e) => {
                                setPageSize(Number(e.target.value));
                              }}
                            >
                              {[10, 50, 100, 500].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                  {pageSize}
                                </option>
                              ))}
                            </select>{" "}
                            entries
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-5">
                        <div
                          id="datatable_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            <div className="d-flex align-items-center justify-content-center">
                              <img
                                className="searchBar"
                                src="../images/search.svg"
                                alt=":)"
                              />
                            </div>
                            <input
                              type="search"
                              className="form-control form-control-sm"
                              placeholder="Filter by keyword"
                              aria-controls="datatable"
                              value={globalFilter || ""}
                              onChange={(e) => {
                                setGlobalFilter(e.target.value);
                              }}
                            />
                          </label>
                          <ul className="pagination">
                            <li
                              className="paginate_button page-item previous"
                              id="datatable_previous"
                            >
                              <button
                                className="page-link"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                              >
                                <img src="../images/ribbon-design.svg" />{" "}
                              </button>
                            </li>
                            <li className="pageInfo">
                              <span className="currentPage ">
                                {pageIndex + 1}
                              </span>
                              <span className="totalPage">
                                /{pageOptions.length}
                              </span>
                            </li>
                            <li
                              className="paginate_button page-item next"
                              id="datatable_next"
                            >
                              <button
                                className="page-link"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                              >
                                <img src="../images/ribbon-designRight.svg" />
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 h-100">
                        {data.length === 0 ? (
                          <span>No logs to display</span>
                        ) : (
                          <table
                            id="datatable"
                            className="w-100 h-100"
                            {...getTableProps()}
                          >
                            <thead>
                              {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                  {headerGroup.headers.map((column) => (
                                    <th
                                      className={
                                        column.isSorted
                                          ? column.isSortedDesc
                                            ? "descending"
                                            : "ascending"
                                          : ""
                                      }
                                      {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                      )}
                                    >
                                      <span {...column.getSortByToggleProps()}>
                                        {column.render("Header")}
                                      </span>
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </thead>
                            <tbody
                              {...getTableBodyProps()}
                              style={{ overflowY: "scroll" }}
                            >
                              {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                      return (
                                        <td {...cell.getCellProps()}>
                                          {cell.render("Cell")}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex flex-md-row flex-column justify-content-between footerOfMainArea px-md-3 px-0">
                  <div
                    className="dataTables_info text-nowrap"
                    id="datatable_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing {pageIndex * pageSize + 1} to{" "}
                    {pageIndex * pageSize + pageSize > rows.length
                      ? rows.length
                      : pageIndex * pageSize + pageSize}{" "}
                    of {rows.length} entries
                  </div>
                  <div className="d-inline-flex w-100 justify-content-end">
                  <div>
                  <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                    <button
                      className={
                        (previous.length > 0 && "active-state  ") +
                        " confirmBtn btn btn-link blueBorderButton btn-animation mr-md-2 mr-0 "
                      }
                      disabled={previous.length <= 0}
                      onClick={() => {
                        setCurrent(previous.pop());
                      }}
                    >
                      Previous
                    </button>
                    <button
                      className={
                        (next && "active-state  ") +
                        " confirmBtn btn btn-link blueBorderButton btn-animation mr-md-2 mr-0 "
                      }
                      disabled={!next}
                      onClick={() => {
                        previous.push(current);
                        if (current) {
                          
                        }
                        setCurrent(next);
                      }}
                    >
                      Next
                    </button>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logs;
