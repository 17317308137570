import { useEffect } from "react";

const useUnload = (disallowUnload) => {
  useEffect(() => {
    const onUnload = (e) => {
      if (disallowUnload) {
        e.preventDefault();
        e.returnValue = true;
      }
    };

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, [disallowUnload]);
};

export default useUnload;
