import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HomeHeader from "./HomeHeader";
import HomeFooter from "./HomeFooter";

const Careers = () => {
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <>
    {error && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setError(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong>
              {Array.isArray(error)
                ? error.map((em, idx) => <span key={idx}>{em}</span>)
                : error}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}

{info && (
        <div className="alert-area">
          <div className="bluealert">
<div className="messageText">{info}</div>
          </div>
        </div>
      )}
      <div className="body-hero">
        <HomeHeader setError={setError} setIsLoading={setIsLoading}></HomeHeader>

        <section className="banner half">
          <div id="tsparticles"></div>
          <img className="bg" src="images/materials/bg02.jpg" alt="" />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h1>Seize your unfair advantage</h1>
                <h4>
                  Enter the fold for a unique learning and work environment
                </h4>

                <div className="banner-box">
                  <h2>Work at Epic9</h2>
                  <p>
                    We are growing fast and looking for awesome people to join
                    our team. At Epic9, we love what we do, pour our passion
                    into building technology solutions and providing amazing
                    service to our clients. We strive to work smart, stay humble
                    & nimble and create solutions that truly transform our
                    clients IT. We’re looking for dedicated, hard-working cloud
                    practitioners who will help us achieve our goal of being the
                    best cloud engineering services provider in the markets we
                    serve. We hire for projects throughout the United States and
                    India
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <main className="inner-page">
          <section className="careers">
            <div className="row item align-items-center">
              <div className="col-lg-6">
                <img src="images/materials/c02.jpg" alt="" />
              </div>
              <div className="col-lg-6">
                <div className="text">
                  <h4>Current Openings</h4>
                  <p>
                    We are constantly in need of experts in next gen
                    technologies to build innovative solutions for our clients.
                    Epic9 is committed to providing an equal opportunity to all
                    job seekers.
                    <br />
                    Submit your resume if you have experience in one of the
                    following roles or possess deep subject matter expertise in
                    the associated technologies.
                  </p>
                </div>
              </div>
            </div>
            <div className="row flex-row-reverse  item align-items-center">
              <div className="col-lg-6">
                <img src="images/materials/c03.jpg" alt="" />
              </div>
              <div className="col-lg-6">
                <div className="text">
                  <h4>AWS Solutions Architect</h4>
                  <p>
                    AWS continues to have a steep growth curve since its launch
                    in 2006. As a trusted customer advocate, the AWS Solutions
                    Architect helps our clients understand best practices around
                    advanced cloud-based solutions, and how to migrate existing
                    workloads to AWS. The AWS Solutions Architect will have the
                    opportunity to help shape and execute a strategy to build
                    mindshare and broad use of AWS. You will be expected to be a
                    thought leader and help grow AWS adoption in the client
                    organization
                  </p>
                </div>
              </div>
            </div>
            <div className="row item align-items-center">
              <div className="col-lg-6">
                <img src="images/materials/c04.jpg" alt="" />
              </div>
              <div className="col-lg-6">
                <div className="text">
                  <h4>Fullstack Developers (React, Node.js, Mongo DB)</h4>
                  <p>
                    As a mobile app & web developer you will be building next
                    generation responsive, cross platform products for our
                    customers. Proficiency with React, Node.js, Mongo DB and a
                    strong understanding of the patterns and practices that
                    revolve around the mobile & web app development is expected.
                    You will work on technical design and implementation of new
                    products or enhancements and participate in all phases of
                    the development cycle: concept, technical design, prototype,
                    code development, testing, release to QA and implementation.
                  </p>
                </div>
              </div>
            </div>
            <div className="row flex-row-reverse item align-items-center">
              <div className="col-lg-6">
                <img src="images/materials/c05.jpg" alt="" />
              </div>
              <div className="col-lg-6">
                <div className="text">
                  <h4>DevOps Engineer</h4>
                  <p>
                    As a DevOps engineer you will work on our projects helping
                    clients with their transformation initiatives in automating
                    the software delivery pipeline and running the applications
                    in Cloud. This role involves incorporating practices like
                    agile development, continuous integration, infrastructure as
                    code, configuration automation and continuous delivery.
                    Experience in release management, AWS
                    <strong>
                      CLI, Cloud Formation, Python, Jenkins, Puppet or Chef is
                      expected.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="row item align-items-center">
              <div className="col-lg-6">
                <img src="images/materials/c06.jpg" alt="" />
              </div>
              <div className="col-lg-6">
                <div className="text">
                  <h4>Microservices Architect</h4>
                  <p>
                    Software is a practice in continuous evolution. Over the
                    last decade, microservices has become an industry
                    phenomenon. This role is for hands-on, industry thought
                    leaders, who are motivated to convert the buzz to reality.
                    You will be expected to drive a product from
                    conceptualization phase to getting it in the market
                    successfully
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>

        <HomeFooter setError={setError} setIsLoading={setIsLoading} setInfo={setInfo}></HomeFooter>
      </div>
    </>
  );
};

export default Careers;
