import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import {
  validateAccountName,
  validateDomainName,
  validateIPAddress,
  validatePassword,
} from "../../../../helpers/validator";
import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import { getError } from "../../../../helpers/errorHelper";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";
import RadioGroup from "../../RadioGroup";

const projectId = "google_1";
const PREFIX = "/aws/network/vpcfactory";
const IPAM_KEY = "/aws/network/datacenters/establishonpremintegration";

const VPCFactory = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    deployvpcfactory: {
      value: "yes",
    },
    enableprivatednsintegration: {
      value: "yes",
    },
    enableflowlogsingestiontologanalytics: {
      value: "no",
    },
    enableipamintegration: {
      value: "yes",
    },
    enableipv6support: {
      value: "yes",
    },
    createdefaultsecuritygroup: {
      value: "yes",
    },
    allowcreationofpublicsubnets: {
      value: "yes",
    },
    "ipamintegration/deploymentmodel": {
      value: "integratewithexisting",
    },
    "ipamintegration/solution": {
      value: "bluecat",
    },
    ipamsolution: {
      value: "",
    },
    "ipamintegration/existingipamserver": {
      value: "",
      isValid: false,
    },
    "ipamintegration/existingipamserveruserid": {
      value: "",
      isValid: false,
    },
    "ipamintegration/existingipamserverpassword": {
      value: "",
      isValid: false,
    },
    ipamDataOnPrem :{
      value: ""
    }
  });

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched", "Deployed"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        console.log(result);

        const ipamResult = await awsAmplify.loadProjectData(
          projectId,
          IPAM_KEY
        );
        console.log(ipamResult);

        setEmpty(result.data.empty);

        setFormData(convertBEToFE(result.data, ipamResult.data));
      } catch (err) {
        setIsLoading(false);
        const errorMsg = getError(err, setErrorMsg);

        if(errorMsg === "You have no access"){
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      const errorMsg = getError(err, setErrorMsg);

      if(errorMsg === "You have no access"){
        history.goBack();
      }
    }
  };

  const convertBEToFE = (data, ipamData) => {
    if (!data) {
      data = {};
    }

    const fe = {
      deployvpcfactory: {
        value: data[`${PREFIX}/deployvpcfactory`] || "yes",
      },
      ipam: ipamData && ipamData[IPAM_KEY],
      enableprivatednsintegration: {
        value: data[`${PREFIX}/enableprivatednsintegration`] || "yes",
      },
      enableflowlogsingestiontologanalytics: {
        value: data[`${PREFIX}/enableflowlogsingestiontologanalytics`] || "no",
      },
      enableipamintegration: {
        value: data[`${PREFIX}/enableipamintegration`] || "yes",
      },
      enableipv6support: {
        value: data[`${PREFIX}/enableipv6support`] || "yes",
      },
      createdefaultsecuritygroup: {
        value: 
        ipamData && ipamData[IPAM_KEY] === "no" 
        ? "no"  
        : data[`${PREFIX}/createdefaultsecuritygroup`] || "yes",
      },
      allowcreationofpublicsubnets: {
        value: data[`${PREFIX}/allowcreationofpublicsubnets`] || "yes",
      },
      "ipamintegration/deploymentmodel": {
        value:
          ipamData && ipamData[IPAM_KEY] === "no"
            ? "deploy"
            : data[`${PREFIX}/ipamintegration/deploymentmodel`] ||
              "integratewithexisting",
      },
      "ipamintegration/solution": {
        value: data[`${PREFIX}/ipamintegration/solution`] || "bluecat",
      },
      ipamsolution: {
        value:  data[`${PREFIX}/ipamintegration/solution`] || ""
      },
      "ipamintegration/existingipamserver": {
        value: "",
        isValid: false
      },
      "ipamintegration/existingipamserveruserid": {
        value: "",
        isValid: false
      },
      "ipamintegration/existingipamserverpassword": {
        value: "",
        isValid: false
      },
      ipamDataOnPrem : {
        value : ipamData && ipamData[IPAM_KEY]
      }
    };
     
    if(fe.ipamDataOnPrem && fe.ipamDataOnPrem.value == "no" && fe.createdefaultsecuritygroup.value == "no")
    {
      setIsDirty(true);
    }

     if(fe.ipamsolution && fe.ipamsolution.value !== "") {
      fe["ipamintegration/existingipamserver"] = {
        value: data[`${PREFIX}/ipamintegration/${fe.ipamsolution.value}/existingipamserver`] || "",
        isValid: data[`${PREFIX}/ipamintegration/${fe.ipamsolution.value}/existingipamserver`]
          ? true
          : false,
      };
      fe["ipamintegration/existingipamserveruserid"] = {
        value: data[`${PREFIX}/ipamintegration/${fe.ipamsolution.value}/existingipamserveruserid`] || "",
        isValid: data[`${PREFIX}/ipamintegration/${fe.ipamsolution.value}/existingipamserveruserid`]
          ? true
          : false,
      };
      fe["ipamintegration/existingipamserverpassword"] = {
        value:
          data[`${PREFIX}/ipamintegration/${fe.ipamsolution.value}/existingipamserverpassword`] || "",
        isValid: data[`${PREFIX}/ipamintegration/${fe.ipamsolution.value}/existingipamserverpassword`]
          ? true
          : false,
      };
    }

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deployvpcfactory`]: formData.deployvpcfactory.value,
    };

    if (formData.deployvpcfactory.value === "yes") {
      be[`${PREFIX}/enableprivatednsintegration`] =
        formData.enableprivatednsintegration.value;
      be[`${PREFIX}/enableflowlogsingestiontologanalytics`] =
        formData.enableflowlogsingestiontologanalytics.value;
      be[`${PREFIX}/enableipamintegration`] =
        formData.enableipamintegration.value;
      be[`${PREFIX}/enableipv6support`] = formData.enableipv6support.value;
      be[`${PREFIX}/createdefaultsecuritygroup`] =
        formData.createdefaultsecuritygroup.value;

      be[`${PREFIX}/allowcreationofpublicsubnets`] =
        formData.allowcreationofpublicsubnets.value;
     if(formData.enableipamintegration.value === "yes"){   
      be[`${PREFIX}/ipamintegration/solution`] =
        formData["ipamintegration/solution"].value;
      be[`${PREFIX}/ipamintegration/deploymentmodel`] =
        formData["ipamintegration/deploymentmodel"].value;

      if (
        formData["ipamintegration/deploymentmodel"].value ===
        "integratewithexisting"
      ) {
          formData.ipamsolution.value = formData["ipamintegration/solution"].value;
          if (formData.ipamsolution && formData.ipamsolution.value !== "") {
            if (formData["ipamintegration/existingipamserver"] && formData["ipamintegration/existingipamserver"].isValid) {
              be[`${PREFIX}/ipamintegration/${formData.ipamsolution.value}/existingipamserver`] =
                formData["ipamintegration/existingipamserver"].value;
            }

            if (formData["ipamintegration/existingipamserveruserid"] && formData["ipamintegration/existingipamserveruserid"].isValid) {
              be[`${PREFIX}/ipamintegration/${formData.ipamsolution.value}/existingipamserveruserid`] =
                formData["ipamintegration/existingipamserveruserid"].value;
            }

            if (formData["ipamintegration/existingipamserverpassword"] && formData["ipamintegration/existingipamserverpassword"].isValid) {
              be[`${PREFIX}/ipamintegration/${formData.ipamsolution.value}/existingipamserverpassword`] =
                formData["ipamintegration/existingipamserverpassword"].value;
            }
          }
       }
      }
    }

    return be;
  };

  const getFieldValidationClass = (key1) => {
    return !formData[key1].isValid && formData[key1].value !== ""
      ? "invalid"
      : "";
  };

  const onFieldChange = (value, key1) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key1]: { value: { $set: value } } })
    );
  };

  const onFieldBlur = (validateFunc, value, key1) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, { [key1]: { isValid: { $set: errorMsg === undefined } } })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, { [key1]: { isValid: { $set: false } } })
      );
    }
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Network</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
          <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Network"
                isDirty={isDirty}
                dashboardRoute={ROUTES.VPC}
                designRoute={ROUTES.DESIGN_NETWORK}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 ">
                <div className="row d-flex align-items-center mb-2">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3 className="font-weight-bold">VPC Factory</h3>
                  </div>
                  <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                        Deploy VPC Factory
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                        <label
                          className={`createElementBtn btn btn-link btn-linkYes mw-70 btn-linkYes ${
                            formData.deployvpcfactory.value === "yes" &&
                            "active"
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={formData.deployvpcfactory.value === "yes"}
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) =>
                                update(state, {
                                  deployvpcfactory: { value: { $set: "yes" } },
                                })
                              );
                            }}
                          />{" "}
                          YES
                        </label>
                        <label
                          className={`dontCreateElementBtn btn btn-link btn-linkNo mw-70 btn-linkNo" ${
                            formData.deployvpcfactory.value === "no" && "active"
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={formData.deployvpcfactory.value === "no"}
                            onChange={() => {}}
                            /* onChange={() => {
                              setIsDirty(true);
                              setFormData((state) =>
                                update(state, {
                                  deployvpcfactory: { value: { $set: "no" } },
                                })
                              );
                            }} */
                          />{" "}
                          NO
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {empty && (
                  <NoInitialData />
                )}
                {formData.deployvpcfactory.value === "yes" && (
                  <>
                    <div className="show-hide-content">
                      <div className="d-flex flex-column   pb-3 pt-0">
                        <div className=" row mainContentArea p-relative px-md-2 px-2 my-1 py-1 align-items-center justify-content-between">
                          <div className="col-md-6 col-12 my-1 px-0">
                            <div className="bg-special border-10 d-inline-flex justify-content-between w-100 px-2 py-1">
                              <span className="d-flex align-items-center font-12">
                                Enable Private DNS Support
                              </span>
                              <div>
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100 ">
                                  <label
                                    className={`btn btn-link btn-linkYes ${
                                      formData.enableprivatednsintegration
                                        .value === "yes" && "active"
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="options"
                                      checked={
                                        formData.enableprivatednsintegration
                                          .value === "yes"
                                      }
                                      onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            enableprivatednsintegration: {
                                              value: { $set: "yes" },
                                            },
                                          })
                                        );
                                      }}
                                    />{" "}
                                    YES
                                  </label>
                                  <label
                                    className={`btn btn-link btn-linkNo ${
                                      formData.enableprivatednsintegration
                                        .value === "no" && "active"
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="options"
                                      checked={
                                        formData.enableprivatednsintegration
                                          .value === "no"
                                      }
                                      onChange={() => {
                                        /* setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            enableprivatednsintegration: {
                                              value: { $set: "no" },
                                            },
                                          })
                                        ); */
                                      }}
                                    />{" "}
                                    NO
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 my-1 px-0">
                            <div className="bg-special border-10 d-inline-flex justify-content-between w-100 px-2 py-1">
                              <span className="d-flex align-items-center font-12">
                                Enable IPv6 Support
                              </span>
                              <div>
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100 ">
                                  <label
                                    className={`btn btn-link btn-linkYes ${
                                      formData.enableipv6support.value ===
                                        "yes" && "active"
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="options"
                                      checked={
                                        formData.enableipv6support.value ===
                                        "yes"
                                      }
                                      onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            enableipv6support: {
                                              value: { $set: "yes" },
                                            },
                                          })
                                        );
                                      }}
                                    />{" "}
                                    YES
                                  </label>
                                  <label
                                    className={`btn btn-link btn-linkNo ${
                                      formData.enableipv6support.value ===
                                        "no" && "active"
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="options"
                                      checked={
                                        formData.enableipv6support.value ===
                                        "no"
                                      }
                                      onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            enableipv6support: {
                                              value: { $set: "no" },
                                            },
                                          })
                                        );
                                      }}
                                    />{" "}
                                    NO
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 my-1 px-0">
                            <div className="bg-special border-10 d-inline-flex justify-content-between w-100 px-2 py-1">
                              <span className="d-flex align-items-center font-12">
                                Enable VPC Flow Logs Ingestion to Log Analytics
                              </span>
                              <div>
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100 ">
                                  <label
                                    className={`btn btn-link btn-linkYes ${
                                      formData
                                        .enableflowlogsingestiontologanalytics
                                        .value === "yes" && "active"
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="options"
                                      checked={
                                        formData
                                          .enableflowlogsingestiontologanalytics
                                          .value === "yes"
                                      }
                                      onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            enableflowlogsingestiontologanalytics: {
                                              value: { $set: "yes" },
                                            },
                                          })
                                        );
                                      }}
                                    />{" "}
                                    YES
                                  </label>
                                  <label
                                    className={`btn btn-link btn-linkNo ${
                                      formData
                                        .enableflowlogsingestiontologanalytics
                                        .value === "no" && "active"
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="options"
                                      checked={
                                        formData
                                          .enableflowlogsingestiontologanalytics
                                          .value === "no"
                                      }
                                      onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            enableflowlogsingestiontologanalytics: {
                                              value: { $set: "no" },
                                            },
                                          })
                                        );
                                      }}
                                    />{" "}
                                    NO
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 my-1 px-0">
                            <div className="bg-special border-10 d-inline-flex justify-content-between w-100 px-2 py-1">
                              <span className="d-flex align-items-center font-12">
                                Create Default Security Group for Management
                                from On-premise Network
                              </span>
                              <div>
                                {formData.ipamDataOnPrem && (formData.ipamDataOnPrem.value == "no") ?  (
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100 ">      
                                      <label
                                    className={`btn btn-link btn-linkYes ${
                                      formData.createdefaultsecuritygroup
                                        .value === "yes" && "active"
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="options"
                                      checked={
                                        formData.createdefaultsecuritygroup
                                          .value === "yes"
                                      }
                                    />{" "}
                                    YES
                                  </label>
                                  <label
                                    className={`btn btn-link btn-linkNo ${
                                      formData.createdefaultsecuritygroup
                                        .value === "no" && "active"
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="options"
                                      checked={
                                        formData.createdefaultsecuritygroup
                                          .value === "no"
                                      }
                                      onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            createdefaultsecuritygroup: {
                                              value: { $set: "no" },
                                            },
                                          })
                                        );
                                      }}
                                    />{" "}
                                    NO
                                  </label>
                                </div>
                                ) : 
                                (<div className="btn-group btn-group-toggle btn-group-radio mw-100 ">      
                                <RadioGroup
                                  field="createdefaultsecuritygroup"
                                  value={formData.createdefaultsecuritygroup.value}
                                  onChange={(key, value) => {
                                    setIsDirty(true);
                                    setFormData((state) =>
                                      update(state, {
                                        [key]: {
                                          value: { $set: value },
                                        },
                                      })
                                    );
                                  }}
                                  isLarge={false}
                                  lockToYes={false}
                                   ></RadioGroup>
                              </div>)
                              }
                               </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 my-1 px-0">
                            <div className="bg-special border-10 d-inline-flex justify-content-between w-100 px-2 py-1">
                              <span className="d-flex align-items-center font-12">
                                Enable IPAM Integration for Automatic VPC CIDR
                                Block Allocation
                              </span>
                              <div>
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100 ">
                                  <label
                                    className={`btn btn-link btn-linkYes ${
                                      formData.enableipamintegration.value ===
                                        "yes" && "active"
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="options"
                                      checked={
                                        formData.enableipamintegration.value ===
                                        "yes"
                                      }
                                      onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            enableipamintegration: {
                                              value: { $set: "yes" },
                                            },
                                          })
                                        );
                                      }}
                                    />{" "}
                                    YES
                                  </label>
                                  <label
                                    className={`btn btn-link btn-linkNo ${
                                      formData.enableipamintegration.value ===
                                        "no" && "active"
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="options"
                                      checked={
                                        formData.enableipamintegration.value ===
                                        "no"
                                      }
                                      onChange={() => {
                                        /* setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            enableipamintegration: {
                                              value: { $set: "no" },
                                            },
                                          })
                                        ); */
                                      }}
                                    />{" "}
                                    NO
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 my-1 px-0">
                            <div className="bg-special border-10 d-inline-flex justify-content-between w-100 px-2 py-1">
                              <span className="d-flex align-items-center font-12">
                                Allow Creation of Public Subnets
                              </span>
                              <div>
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100 ">
                                  <label
                                    className={`btn btn-link btn-linkYes ${
                                      formData.allowcreationofpublicsubnets
                                        .value === "yes" && "active"
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="options"
                                      checked={
                                        formData.allowcreationofpublicsubnets
                                          .value === "yes"
                                      }
                                      onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            allowcreationofpublicsubnets: {
                                              value: { $set: "yes" },
                                            },
                                          })
                                        );
                                      }}
                                    />{" "}
                                    YES
                                  </label>
                                  <label
                                    className={`btn btn-link btn-linkNo ${
                                      formData.allowcreationofpublicsubnets
                                        .value === "no" && "active"
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="options"
                                      checked={
                                        formData.allowcreationofpublicsubnets
                                          .value === "no"
                                      }
                                      onChange={() => {
                                        /* setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            allowcreationofpublicsubnets: {
                                              value: { $set: "no" },
                                            },
                                          })
                                        ); */
                                      }}
                                    />{" "}
                                    NO
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      {formData.enableipamintegration.value === "yes" && (
                        <>
                        <div className="row">
                          <div className="col-xxl-8 col-xl-9 col-12 d-inline-flex my-1 py-2 selectionArea flex-wrap px-2 ">
                            <span className="font-12 d-flex align-items-center mw-md-200">
                              IPAM Server
                            </span>
                            <div className="mw-md-150">
                              <label className="container">
                                Deploy
                                <input
                                  type="radio"
                                  name="server"
                                  checked={
                                    formData["ipamintegration/deploymentmodel"]
                                      .value === "deploy"
                                  }
                                  onChange={() => {
                                    setIsDirty(true);
                                    setFormData((state) =>
                                      update(state, {
                                        "ipamintegration/deploymentmodel": {
                                          value: { $set: "deploy" },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="mw-md-150">
                              <label className="container">
                                Integrate with existing IPAM server{" "}
                                <input
                                  type="radio"
                                  name="server"
                                  checked={
                                    formData["ipamintegration/deploymentmodel"]
                                      .value === "integratewithexisting"
                                  }
                                  onChange={() => {
                                    if(formData.ipam === "no"){

                                    }
                                    else{
                                    setIsDirty(true);
                                    setFormData((state) =>
                                      update(state, {
                                        "ipamintegration/deploymentmodel": {
                                          value: {
                                            $set: "integratewithexisting",
                                          },
                                        },
                                      })
                                    );
                                  }
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-xxl-8 col-xl-12 col-12 d-inline-flex selectionArea py-2 my-1 flex-wrap  px-2  justify-content-between">
                            <span className="font-12 d-flex align-items-center mw-md-200">
                              IPAM Solution
                            </span>
                            {formData["ipamintegration/deploymentmodel"].value ===
                          "deploy" && (
                              <>
                                <div className="mw-md-150">
                                  <label className="container">
                                    AWS IPAM
                                    <input
                                      type="radio"
                                      name="solution"
                                      checked={
                                        formData["ipamintegration/solution"]
                                          .value === "awsipam"
                                      }
                                      onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            "ipamintegration/solution": {
                                              value: { $set: "awsipam" },
                                            },
                                          })
                                        );
                                      }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </> )}
                            <div className="mw-md-150">
                              <label className="container">
                                InfoBlox
                                <input
                                  type="radio"
                                  name="solution"
                                  checked={
                                    formData["ipamintegration/solution"]
                                      .value === "infoblox"
                                  }
                                  onChange={() => {
                                    setIsDirty(true);
                                    setFormData((state) =>
                                      update(state, {
                                        "ipamintegration/solution": {
                                          value: { $set: "infoblox" },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="mw-md-150" href="">
                              <label className="container">
                                BlueCat{" "}
                                <input
                                  type="radio"
                                  name="solution"
                                  checked={
                                    formData["ipamintegration/solution"]
                                      .value === "bluecat"
                                  }
                                  onChange={() => {
                                    setIsDirty(true);
                                    setFormData((state) =>
                                      update(state, {
                                        "ipamintegration/solution": {
                                          value: { $set: "bluecat" },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="mw-md-150" href="">
                              <label className="container">
                                phpIPAM
                                <input
                                  type="radio"
                                  name="solution"
                                  checked={
                                    formData["ipamintegration/solution"]
                                      .value === "php"
                                  }
                                  onChange={() => {
                                    setIsDirty(true);
                                    setFormData((state) =>
                                      update(state, {
                                        "ipamintegration/solution": {
                                          value: { $set: "php" },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>

                        {formData["ipamintegration/deploymentmodel"].value ===
                          "integratewithexisting" && (
                          <>
                            <div className="d-flex flex-column mainContentArea  px-2 my-1 py-1 ">
                              <div className="d-inline-flex align-items-center flex-wrap  my-1">
                                <div className="p-0 mw-md-200">
                                  <span className="mb-0 font-12">
                                    Existing IPAM Server
                                  </span>
                                </div>
                                <div className=" flex-wrap d-inline-flex">
                                  <div className="mr-3 ">
                                    <div className="w-250 bg-gray border-10 p-relative d-inline-flex align-items-center">
                                      <input
                                        type="text"
                                        className={`form-control bg-transparent ${getFieldValidationClass(
                                          "ipamintegration/existingipamserver"
                                        )}`}
                                        placeholder="192.169.1.20"
                                        value={
                                          formData[
                                            "ipamintegration/existingipamserver"
                                          ].value
                                        }
                                        onChange={(e) => {
                                          onFieldChange(
                                            e.target.value,
                                            "ipamintegration/existingipamserver"
                                          );
                                        }}
                                        onBlur={(e) => {
                                          const validateDomainNameAndIPAddress = (
                                            v
                                          ) => {
                                            let errorMsg = validateDomainName(
                                              v
                                            );

                                            if (errorMsg) {
                                              errorMsg = validateIPAddress(v);

                                              if (errorMsg) {
                                                return "must be domain format or ip address format";
                                              } else {
                                                return errorMsg;
                                              }
                                            } else {
                                              return errorMsg;
                                            }
                                          };

                                          onFieldBlur(
                                            validateDomainNameAndIPAddress,
                                            e.target.value,
                                            "ipamintegration/existingipamserver"
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-inline-flex align-items-center flex-wrap my-1">
                                <div className="mw-md-200  p-0 ">
                                  <span className="mb-0 font-12">
                                    Existing IPAM Server Access
                                  </span>
                                </div>
                                <div className="flex-wrap d-inline-flex">
                                  <div className="mr-3">
                                    <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                      <input
                                        type="text"
                                        className={`form-control bg-transparent ${getFieldValidationClass(
                                          "ipamintegration/existingipamserveruserid"
                                        )}`}
                                        placeholder="User Id"
                                        value={
                                          formData[
                                            "ipamintegration/existingipamserveruserid"
                                          ].value
                                        }
                                        onChange={(e) => {
                                          onFieldChange(
                                            e.target.value,
                                            "ipamintegration/existingipamserveruserid"
                                          );
                                        }}
                                        onBlur={(e) => {
                                          onFieldBlur(
                                            validateAccountName,
                                            e.target.value,
                                            "ipamintegration/existingipamserveruserid"
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="mr-3">
                                    <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                      <button disabled={disabled}
                                        tabIndex="-1"
                                        type="button"
                                        className="showableInput"
                                        onClick={() =>
                                          setShowPassword(!showPassword)
                                        }
                                      ></button>
                                      <input
                                        type={
                                          showPassword ? "text" : "password"
                                        }
                                        className={`form-control bg-transparent ${getFieldValidationClass(
                                          "ipamintegration/existingipamserverpassword"
                                        )}`}
                                        placeholder="********"
                                        value={
                                          formData[
                                            "ipamintegration/existingipamserverpassword"
                                          ].value
                                        }
                                        onChange={(e) => {
                                          onFieldChange(
                                            e.target.value,
                                            "ipamintegration/existingipamserverpassword"
                                          );
                                        }}
                                        onBlur={(e) => {
                                          onFieldBlur(
                                            validatePassword,
                                            e.target.value,
                                            "ipamintegration/existingipamserverpassword"
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        </>
                        )}
                        </div>
                    </div>
                  </>
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() => saveDataIfDirty(ROUTES.VPC_DESIGN + "/" + projectId)}
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">VPC Design</p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(ROUTES.INTER_VPC_CONNECTIVITY + "/" + projectId)
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Inter-VPC Connectivity
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VPCFactory;
