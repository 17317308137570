import React from "react";

const DetailsTags = ({type, tags}) => (
  <div
    className={`bolgeler-container w-100 row p-5 ${
      type !== "Tags" && "inaktif"
    }`}
  >
    <table className="details-table">
      <thead>
        <tr>
          <th>Key</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {tags && tags.length > 0 &&
          tags.map((item, idx) => (
            <tr key={idx}>
              <td> {(item.Key) ? item.Key : item.TagKey}</td>
              <td>{ (item.Value) ? item.Value : item.TagValue}</td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
);

export default DetailsTags; 
