import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";
import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import useServiceAccounts from "../../../../hooks/useServiceAccounts";
import { validateAccountName, validateTime } from "../../../../helpers/validator";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

//import OPERATING_SYSTEMS from "./OperatingSystemLookup";
//import APPLICATIONS from "./ApplicationLookup";

const PREFIX = "/aws/infrastructure/patchmanagement";
const OPERATING_SYSTEMS = {};
const OPERATING_SYSTEM_LIST = [];
const APPLICATIONS = {};

const getInitialFormData = (data, systemData) => {
  const initialFormData = {
    deploypatchmanagement: {
      value: data[`${PREFIX}/deploypatchmanagement`] || "no",
    },
    centralizepatchmanagement: {
      value: data[`${PREFIX}/centralizepatchmanagement`] || "yes",
    },
    patchmanageraccount: {
      value: data[`${PREFIX}/patchmanageraccount`] || "",
    },
    patchingtasks: [createPatchingTask()],
    systemData: systemData,
  };

  if (data[`${PREFIX}/patchingtaskslist`]) {
    initialFormData.patchingtaskslist = data[
      `${PREFIX}/patchingtaskslist`
    ].split(",");
    initialFormData.patchingtasks = [];

    initialFormData.patchingtaskslist.forEach((pt) => {
      const patchingtask = {
        patchingtaskname: {
          value: data[`${PREFIX}/${pt}/patchingtaskname`] || "",
          isValid: data[`${PREFIX}/${pt}/patchingtaskname`] ? true : false,
        },
        patchoperation: {
          value: data[`${PREFIX}/${pt}/patchoperation`] || "",
        },
        patchgroups: data[`${PREFIX}/${pt}/patchgroups`]
          ? data[`${PREFIX}/${pt}/patchgroups`].split(",").map((pg) => {
              return { value: pg, isValid: true };
            })
          : [{ value: "", isValid: false }],
        maintenancewindowduration: {
          value: data[`${PREFIX}/${pt}/maintenancewindowduration`] || "",
            //? parseInt(data[`${PREFIX}/${pt}/maintenancewindowduration`])
           // : "",
        },
        usedefaultpatchbaseline: {
          value: data[`${PREFIX}/${pt}/usedefaultpatchbaseline`] || "yes",
        },
        patchbaselinename: {
          value: data[`${PREFIX}/${pt}/patchbaselinename`] || "",
          isValid: data[`${PREFIX}/${pt}/patchbaselinename`] ? true : false,
        },
        operatingsystem: {
          value: data[`${PREFIX}/${pt}/operatingsystem`] || "",
        },
        makeitdefaultpatchbaseline: {
          value: data[`${PREFIX}/${pt}/makeitdefaultpatchbaseline`] || "yes",
        },
        approvalruleforos_products: data[
          `${PREFIX}/${pt}/approvalruleforos/products`
        ]
          ? data[`${PREFIX}/${pt}/approvalruleforos/products`]
              .split(",")
              .map((item) => {
                if (item === "*") {
                  return "All";
                } else {
                  return item;
                }
              })
          : [],
        approvalruleforos_classification: data[
          `${PREFIX}/${pt}/approvalruleforos/classification`
        ]
          ? data[`${PREFIX}/${pt}/approvalruleforos/classification`]
              .split(",")
              .map((item) => {
                if (item === "*") {
                  return "All";
                } else {
                  return item;
                }
              })
          : [],
        approvalruleforos_severity: data[
          `${PREFIX}/${pt}/approvalruleforos/severity`
        ]
          ? data[`${PREFIX}/${pt}/approvalruleforos/severity`]
              .split(",")
              .map((item) => {
                if (item === "*") {
                  return "All";
                } else {
                  return item;
                }
              })
          : [],
        approvalruleforos_autoapprovepatchesafter: {
          value:
            data[`${PREFIX}/${pt}/approvalruleforos/autoapprovepatchesafter`] ||
            "",
        },
        approvalruleforos_compliancereporting: {
          value:
            data[`${PREFIX}/${pt}/approvalruleforos/compliancereporting`] || "",
        },
        includenonsecurityupdates: {
          value:
            data[`${PREFIX}/${pt}/includenonsecurityupdates`] &&
            data[`${PREFIX}/${pt}/includenonsecurityupdates`] !== "NA"
              ? data[`${PREFIX}/${pt}/includenonsecurityupdates`] === "true"
                ? "yes"
                : "no"
              : "yes",
        },
        approvalruleforapplicationpatches: {
          value: data[`${PREFIX}/${pt}/approvalruleforapplicationpatches`]
            ? data[`${PREFIX}/${pt}/approvalruleforapplicationpatches`] === "NA"
              ? "yes"
              : data[`${PREFIX}/${pt}/approvalruleforapplicationpatches`]
            : "yes",
        },
        approvalruleforapplications_family:
          data[`${PREFIX}/${pt}/approvalruleforapplications/family`] &&
          data[`${PREFIX}/${pt}/approvalruleforapplications/family`] !== "NA"
            ? data[`${PREFIX}/${pt}/approvalruleforapplications/family`]
                .split(",")
                .map((item) => {
                  if (item === "*") {
                    return "All";
                  } else {
                    return item;
                  }
                })
            : [],
        approvalruleforapplications_products:
          data[`${PREFIX}/${pt}/approvalruleforapplications/products`] &&
          data[`${PREFIX}/${pt}/approvalruleforapplications/products`] !== "NA"
            ? data[`${PREFIX}/${pt}/approvalruleforapplications/products`]
                .split(",")
                .map((item) => {
                  if (item === "*") {
                    return "All";
                  } else {
                    return item;
                  }
                })
            : [],
        approvalruleforapplications_classification:
          data[`${PREFIX}/${pt}/approvalruleforapplications/classification`] &&
          data[`${PREFIX}/${pt}/approvalruleforapplications/classification`] !==
            "NA"
            ? data[`${PREFIX}/${pt}/approvalruleforapplications/classification`]
                .split(",")
                .map((item) => {
                  if (item === "*") {
                    return "All";
                  } else {
                    return item;
                  }
                })
            : [],
        approvalruleforapplications_severity:
          data[`${PREFIX}/${pt}/approvalruleforapplications/severity`] &&
          data[`${PREFIX}/${pt}/approvalruleforapplications/severity`] !== "NA"
            ? data[`${PREFIX}/${pt}/approvalruleforapplications/severity`]
                .split(",")
                .map((item) => {
                  if (item === "*") {
                    return "All";
                  } else {
                    return item;
                  }
                })
            : [],
        approvalruleforapplications_autoapprovepatchesafter: {
          value:
            data[
              `${PREFIX}/${pt}/approvalruleforapplications/autoapprovepatchesafter`
            ] &&
            data[
              `${PREFIX}/${pt}/approvalruleforapplications/autoapprovepatchesafter`
            ] !== "NA"
              ? data[
                  `${PREFIX}/${pt}/approvalruleforapplications/autoapprovepatchesafter`
                ]
              : "",
        },
        approvalruleforapplications_compliancereporting: {
          value:
            data[
              `${PREFIX}/${pt}/approvalruleforapplications/compliancereporting`
            ] &&
            data[
              `${PREFIX}/${pt}/approvalruleforapplications/compliancereporting`
            ] !== "NA"
              ? data[
                  `${PREFIX}/${pt}/approvalruleforapplications/compliancereporting`
                ]
              : "",
        },
      };

      patchingtask.approvalruleforos_all_products = [];
      patchingtask.approvalruleforos_all_classification = [];
      patchingtask.approvalruleforos_all_severity = [];

      patchingtask.approvalruleforapplications_all_family = [];
      patchingtask.approvalruleforapplications_all_products = [];
      patchingtask.approvalruleforapplications_all_classification = [];
      patchingtask.approvalruleforapplications_all_severity = [];

      if (patchingtask.operatingsystem.value !== "") {
        patchingtask.approvalruleforos_all_products =
          OPERATING_SYSTEMS[patchingtask.operatingsystem.value].product;
        patchingtask.approvalruleforos_all_classification =
          OPERATING_SYSTEMS[patchingtask.operatingsystem.value].classification;
        patchingtask.approvalruleforos_all_severity =
          OPERATING_SYSTEMS[patchingtask.operatingsystem.value].severity;

        if (patchingtask.operatingsystem.value === "WINDOWS") {
          patchingtask.approvalruleforapplications_all_family =
            APPLICATIONS[patchingtask.operatingsystem.value].product_family;
          patchingtask.approvalruleforapplications_all_products =
            APPLICATIONS[patchingtask.operatingsystem.value].product;
          patchingtask.approvalruleforapplications_all_classification =
            APPLICATIONS[patchingtask.operatingsystem.value].classification;
          patchingtask.approvalruleforapplications_all_severity =
            APPLICATIONS[patchingtask.operatingsystem.value].severity;
        }
      }

      if (data[`${PREFIX}/${pt}/patchschedule`]) {
        const scheduleArray = data[`${PREFIX}/${pt}/patchschedule`].split(" ");
        console.log(scheduleArray);

        patchingtask.patchscheduleinterval = {
          value: scheduleArray[4],
        };
        patchingtask.patchscheduletime = {
          value: `${scheduleArray[1]}:${scheduleArray[0]}`,
          isValid: true,
        };
      } else {
        patchingtask.patchscheduleinterval = {
          value: "",
        };
        patchingtask.patchscheduletime = {
          value: "",
          isValid: false,
        };
      }

      initialFormData.patchingtasks.push(patchingtask);
    });
  }

  return initialFormData;
};

const createPatchingTask = () => {
  return {
    patchingtaskname: {
      value: "",
      isValid: false,
    },
    patchoperation: {
      value: "",
    },
    patchgroups: [createPatchgroup()],
    patchscheduleinterval: {
      value: "",
    },
    patchscheduletime: {
      value: "",
      isValid: false,
    },
    maintenancewindowduration: {
      value: "",
    },
    usedefaultpatchbaseline: {
      value: "yes",
    },
    patchbaselinename: {
      value: "",
    },
    operatingsystem: {
      value: "",
    },
    makeitdefaultpatchbaseline: {
      value: "yes",
    },
    approvalruleforos_products: [],
    approvalruleforos_all_products: [],
    approvalruleforos_classification: [],
    approvalruleforos_all_classification: [],
    approvalruleforos_severity: [],
    approvalruleforos_all_severity: [],
    approvalruleforos_autoapprovepatchesafter: {
      value: "",
    },
    approvalruleforos_compliancereporting: {
      value: "",
    },
    includenonsecurityupdates: {
      value: "yes",
    },
    approvalruleforapplicationpatches: {
      value: "yes",
    },
    approvalruleforapplications_family: [],
    approvalruleforapplications_all_family: [],
    approvalruleforapplications_products: [],
    approvalruleforapplications_all_products: [],
    approvalruleforapplications_classification: [],
    approvalruleforapplications_all_classification: [],
    approvalruleforapplications_severity: [],
    approvalruleforapplications_all_severity: [],
    approvalruleforapplications_autoapprovepatchesafter: {
      value: "",
    },
    approvalruleforapplications_compliancereporting: {
      value: "",
    },
  };
};

const createPatchgroup = () => {
  return {
    value: "",
    isValid: false,
  };
};

const convertSystemData = (rawData) => {
  const systemData = { OSs: [], APP: {}, OS: {} };

  APPLICATIONS[rawData.APP.OS] = {};

  rawData.APP.Data.forEach((data) => {
    APPLICATIONS[rawData.APP.OS][data.Property.toLowerCase()] =
      data.Data.length === 0 ? [] : ["All", ...data.Data];
  });

  rawData.OS.forEach((os) => {
    OPERATING_SYSTEM_LIST.push(os.OS);
    OPERATING_SYSTEMS[os.OS] = {};

    os.Data.forEach((data) => {
      OPERATING_SYSTEMS[os.OS][data.Property.toLowerCase()] =
        data.Data.length === 0 ? [] : ["All", ...data.Data];
    });
  });

  return systemData;
};

const PatchManagement = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);
  const awsAmplify = useAwsAmplify();
  const serviceAccounts = useServiceAccounts(projectId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);

        const systemDataResult = await awsAmplify.getPatchManagement();

        console.log(systemDataResult);

        const systemData = convertSystemData(
          systemDataResult.data.getPatchManagement
        );

        console.log(systemData);

        setIsLoading(false);

        setEmpty(result.data.empty);

        setFormData(convertBEToFE(result.data, systemData));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data, systemData) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data, systemData);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploypatchmanagement`]: formData.deploypatchmanagement.value,
    };

    if (formData.deploypatchmanagement.value === "yes") {
      be[`${PREFIX}/centralizepatchmanagement`] =
        formData.centralizepatchmanagement.value;

      if (formData.patchmanageraccount.value !== "") {
        be[`${PREFIX}/patchmanageraccount`] =
          formData.patchmanageraccount.value;
      }

      const patchingtasks = [];
      const patchingtaskswithnondefaultbaseline = [];

      formData.patchingtasks.forEach((pt, idx) => {
        const key = `patchingtask${idx + 1}`;

        patchingtasks.push(key);

        if (pt.patchingtaskname.isValid) {
          be[`${PREFIX}/${key}/patchingtaskname`] = pt.patchingtaskname.value;
        }

        if (pt.patchoperation.value !== "") {
          be[`${PREFIX}/${key}/patchoperation`] = pt.patchoperation.value;
        }

        const patchgroups = pt.patchgroups
          .filter((pt) => pt.isValid)
          .map((pt) => pt.value);

        if (patchgroups.length > 0) {
          be[`${PREFIX}/${key}/patchgroups`] = patchgroups.join(",");
        }

        if (
          pt.patchscheduleinterval.value !== "" &&
          pt.patchscheduletime.isValid
        ) {
          const timeArray = pt.patchscheduletime.value.split(":");
          be[`${PREFIX}/${key}/patchschedule`] = `${timeArray[1]} ${
            timeArray[0]
          } ${pt.patchscheduleinterval.value === "?" ? "*" : "?"} * ${
            pt.patchscheduleinterval.value
          } *`;
        }

        if (pt.maintenancewindowduration.value !== "") {
          be[`${PREFIX}/${key}/maintenancewindowduration`] =
            pt.maintenancewindowduration.value;
        }

        be[`${PREFIX}/${key}/usedefaultpatchbaseline`] =
          pt.usedefaultpatchbaseline.value;

        if (pt.usedefaultpatchbaseline.value === "no") {
          patchingtaskswithnondefaultbaseline.push(key);

          if (pt.patchbaselinename.isValid) {
            be[`${PREFIX}/${key}/patchbaselinename`] =
              pt.patchbaselinename.value;
          }

          if (pt.operatingsystem.value !== "") {
            be[`${PREFIX}/${key}/operatingsystem`] = pt.operatingsystem.value;
          }

          be[`${PREFIX}/${key}/makeitdefaultpatchbaseline`] =
            pt.makeitdefaultpatchbaseline.value;

          if (pt.approvalruleforos_products.length > 0) {
            be[
              `${PREFIX}/${key}/approvalruleforos/products`
            ] = pt.approvalruleforos_products
              .map((item) => {
                if (item === "All") {
                  return "*";
                } else {
                  return item;
                }
              })
              .join(",");
          }

          if (pt.approvalruleforos_classification.length > 0) {
            be[
              `${PREFIX}/${key}/approvalruleforos/classification`
            ] = pt.approvalruleforos_classification
              .map((item) => {
                if (item === "All") {
                  return "*";
                } else {
                  return item;
                }
              })
              .join(",");
          }

          if (pt.approvalruleforos_severity.length > 0) {
            be[
              `${PREFIX}/${key}/approvalruleforos/severity`
            ] = pt.approvalruleforos_severity
              .map((item) => {
                if (item === "All") {
                  return "*";
                } else {
                  return item;
                }
              })
              .join(",");
          }

          if (pt.operatingsystem.value !== "") {
            be[`${PREFIX}/${key}/operatingsystem`] = pt.operatingsystem.value;
          }

          if (pt.approvalruleforos_autoapprovepatchesafter.value !== "") {
            be[`${PREFIX}/${key}/approvalruleforos/autoapprovepatchesafter`] =
              pt.approvalruleforos_autoapprovepatchesafter.value;
          }

          if (pt.approvalruleforos_compliancereporting.value !== "") {
            be[`${PREFIX}/${key}/approvalruleforos/compliancereporting`] =
              pt.approvalruleforos_compliancereporting.value;
          }

          if (
            pt.operatingsystem.value !== "WINDOWS" &&
            pt.operatingsystem.value !== "SUSE"
          ) {
            be[`${PREFIX}/${key}/includenonsecurityupdates`] =
              pt.includenonsecurityupdates.value === "yes" ? "true" : "false";
          } else {
            be[`${PREFIX}/${key}/includenonsecurityupdates`] = "NA";
          }

          if (pt.operatingsystem.value === "WINDOWS") {
            be[`${PREFIX}/${key}/approvalruleforapplicationpatches`] =
              pt.approvalruleforapplicationpatches.value;

            if (pt.approvalruleforapplicationpatches.value === "yes") {
              if (pt.approvalruleforapplications_family.length > 0) {
                be[
                  `${PREFIX}/${key}/approvalruleforapplications/family`
                ] = pt.approvalruleforapplications_family
                  .map((item) => {
                    if (item === "All") {
                      return "*";
                    } else {
                      return item;
                    }
                  })
                  .join(",");
              }

              if (pt.approvalruleforapplications_products.length > 0) {
                be[
                  `${PREFIX}/${key}/approvalruleforapplications/products`
                ] = pt.approvalruleforapplications_products
                  .map((item) => {
                    if (item === "All") {
                      return "*";
                    } else {
                      return item;
                    }
                  })
                  .join(",");
              }

              if (pt.approvalruleforapplications_classification.length > 0) {
                be[
                  `${PREFIX}/${key}/approvalruleforapplications/classification`
                ] = pt.approvalruleforapplications_classification
                  .map((item) => {
                    if (item === "All") {
                      return "*";
                    } else {
                      return item;
                    }
                  })
                  .join(",");
              }

              if (pt.approvalruleforapplications_severity.length > 0) {
                be[
                  `${PREFIX}/${key}/approvalruleforapplications/severity`
                ] = pt.approvalruleforapplications_severity
                  .map((item) => {
                    if (item === "All") {
                      return "*";
                    } else {
                      return item;
                    }
                  })
                  .join(",");
              }

              if (
                pt.approvalruleforapplications_autoapprovepatchesafter.value !==
                ""
              ) {
                be[
                  `${PREFIX}/${key}/approvalruleforapplications/autoapprovepatchesafter`
                ] =
                  pt.approvalruleforapplications_autoapprovepatchesafter.value;
              }

              if (
                pt.approvalruleforapplications_compliancereporting.value !== ""
              ) {
                be[
                  `${PREFIX}/${key}/approvalruleforapplications/compliancereporting`
                ] = pt.approvalruleforapplications_compliancereporting.value;
              }
            } else {
              be[`${PREFIX}/${key}/approvalruleforapplications/family`] = "NA";
              be[`${PREFIX}/${key}/approvalruleforapplications/products`] =
                "NA";
              be[
                `${PREFIX}/${key}/approvalruleforapplications/classification`
              ] = "NA";
              be[`${PREFIX}/${key}/approvalruleforapplications/severity`] =
                "NA";
              be[
                `${PREFIX}/${key}/approvalruleforapplications/autoapprovepatchesafter`
              ] = "NA";
              be[
                `${PREFIX}/${key}/approvalruleforapplications/compliancereporting`
              ] = "NA";
            }
          } else {
            be[`${PREFIX}/${key}/approvalruleforapplicationpatches`] = "NA";
            be[`${PREFIX}/${key}/approvalruleforapplications/family`] = "NA";
            be[`${PREFIX}/${key}/approvalruleforapplications/products`] = "NA";
            be[`${PREFIX}/${key}/approvalruleforapplications/classification`] =
              "NA";
            be[`${PREFIX}/${key}/approvalruleforapplications/severity`] = "NA";
            be[
              `${PREFIX}/${key}/approvalruleforapplications/autoapprovepatchesafter`
            ] = "NA";
            be[
              `${PREFIX}/${key}/approvalruleforapplications/compliancereporting`
            ] = "NA";
          }
        }
      });

      if (patchingtasks.length > 0) {
        be[`${PREFIX}/patchingtaskslist`] = patchingtasks.join(",");
      }

      if (patchingtaskswithnondefaultbaseline.length > 0) {
        be[
          `${PREFIX}/patchingtaskswithnondefaultbaseline`
        ] = patchingtaskswithnondefaultbaseline.join(",");
      } else {
        be[`${PREFIX}/patchingtaskswithnondefaultbaseline`] = "none";
      }
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const onFieldChange = (value, key, idx) => {
    setErrorMsg(null);
    setIsDirty(true);

    if (idx !== undefined) {
      setFormData((state) =>
        update(state, {
          patchingtasks: {
            [idx]: {
              [key]: {
                value: {
                  $set: value,
                },
              },
            },
          },
        })
      );
    } else {
      setFormData((state) =>
        update(state, { [key]: { value: { $set: value } } })
      );
    }
  };

  const onFieldBlur = (validateFunc, value, key, idx) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      if (idx !== undefined) {
        setFormData((state) =>
          update(state, {
            patchingtasks: {
              [idx]: {
                [key]: { isValid: { $set: errorMsg === undefined } },
              },
            },
          })
        );
      } else {
        setFormData((state) =>
          update(state, {
            [key]: { isValid: { $set: errorMsg === undefined } },
          })
        );
      }

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      if (idx !== undefined) {
        setFormData((state) =>
          update(state, {
            patchingtasks: {
              [idx]: {
                [key]: { isValid: { $set: false } },
              },
            },
          })
        );
      } else {
        setFormData((state) =>
          update(state, { [key]: { isValid: { $set: false } } })
        );
      }
    }
  };

  const addPatchingTask = () => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, { patchingtasks: { $push: [createPatchingTask()] } })
    );
  };

  const removePatchingTask = (idx) => {
    if (formData.patchingtasks.length > 1) {
      setIsDirty(true);

      setFormData((state) =>
        update(state, { patchingtasks: { $splice: [[idx, 1]] } })
      );
    }
  };

  const exists = (item, array, value) => {
    return item[array].indexOf(value) !== -1;
  };

  const add = (idx, array, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        patchingtasks: {
          [idx]: {
            [array]: {
              $push: [value],
            },
          },
        },
      })
    );
  };

  const remove = (idx, array, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        patchingtasks: {
          [idx]: {
            [array]: {
              $splice: [[state.patchingtasks[idx][array].indexOf(value), 1]],
            },
          },
        },
      })
    );
  };

  const onSelectionChange = (idxItem, field, item, value) => {
    setIsDirty(true);

    if (value === "All") {
      setFormData((state) =>
        update(state, {
          patchingtasks: {
            [idxItem]: {
              [field]: {
                $set: [value],
              },
            },
          },
        })
      );
    } else {
      if (exists(item, field, "All")) {
        setFormData((state) =>
          update(state, {
            patchingtasks: {
              [idxItem]: {
                [field]: {
                  $splice: [
                    [state.patchingtasks[idxItem][field].indexOf(value), 1],
                  ],
                },
              },
            },
          })
        );
      }

      if (!exists(item, field, value)) {
        setFormData((state) =>
          update(state, {
            patchingtasks: {
              [idxItem]: {
                [field]: {
                  $push: [value],
                },
              },
            },
          })
        );
      }
    }
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Infrastructure</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Infrastructure"
                isDirty={isDirty}
                dashboardRoute={ROUTES.INFRASTRUCTURE_BASELINES}
                designRoute={ROUTES.DESIGN_INFRASTRUCTURE}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 ">
                <div className="row d-flex align-items-center">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3>Patch Management</h3>
                  </div>
                  <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                        Deploy Patch Management Solution
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                        <RadioGroup
                          field="deploypatchmanagement"
                          value={formData.deploypatchmanagement.value}
                          onChange={updateRadio}
                          isLarge={true}
                        ></RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>

                {empty && (
                  <NoInitialData />
                )}

                {formData.deploypatchmanagement.value === "yes" && (
                  <div className="row">
                    <div className="d-flex flex-column ">
                      <div className="d-inline-flex flex-wrap w-100 my-1 row">
                        <div className="pl-lg-0 px-2 d-flex align-items-center col-md-3 mr-0 my-1">
                          <div className="font-12">
                            Centralize Patch Management
                          </div>
                        </div>
                        <div className="col-md-9 my-1">
                          <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-xl-5 mr-md-3 mr-0">
                            <label
                              className={`createElementBtn btn btn-link btn-linkYes ${
                                formData.centralizepatchmanagement.value ===
                                  "yes" && "active"
                              } `}
                            >
                              <input
                                type="radio"
                                name="options"
                                checked={
                                  formData.centralizepatchmanagement.value ===
                                  "yes"
                                }
                                onChange={() =>
                                  updateRadio(
                                    "centralizepatchmanagement",
                                    "yes"
                                  )
                                }
                              />{" "}
                              YES
                            </label>
                            <label
                              className={`createElementBtn btn btn-link btn-linkNo ${
                                formData.centralizepatchmanagement.value ===
                                  "no" && "active"
                              } `}
                            >
                              <input type="radio" name="options" /> NO
                            </label>
                          </div>
                        </div>
                        <div className="d-inline-flex col-md-3 pl-lg-0 px-2 my-1">
                          <label className="font-12 d-flex align-items-center mb-0 ">
                            Patch Manager Account
                          </label>
                        </div>
                        <div className="col-md-9">
                          <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                          {(serviceAccounts && (serviceAccounts.security
                              .concat(serviceAccounts.shared)).length === 0)  ?
                            (
                              <div className="col-md-12 flex-wrap d-inline-flex">
                              <span className="warning">
                              This section depends on the platform accounts information in
                              Account Framework section that is yet to be provided
                            </span>
                            </div>
                            ) : (
                              <>  
                            <select
                              className="form-control gray"
                              value={formData.patchmanageraccount.value}
                              onChange={(e) => {
                                onFieldChange(
                                  e.target.value,
                                  "patchmanageraccount"
                                );
                              }}
                            >
                              <option value="">Select</option>
                              {serviceAccounts.security
                                .concat(serviceAccounts.shared)
                                .map((sa, idxSa) => (
                                  <option key={idxSa} value={sa}>
                                    {sa}
                                  </option>
                                ))}
                            </select>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      {formData.patchingtasks.map((item, idxItem) => (
                        <React.Fragment key={idxItem}>
                          <div className="row">
                            <div className="d-inline-flex  my-1 pl-lg-0 px-2 col-md-3">
                              <div className="pl-lg-0 px-2 d-flex align-items-center ">
                                <div className="font-12">
                                  Patching Task # {idxItem + 1} Name
                                </div>
                              </div>
                            </div>
                            <div className="d-inline-flex col-md-6 my-1">
                              <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                <input
                                  type="text"
                                  className="bg-gray form-control"
                                  placeholder="Install-OSPatch-ProductionEnv"
                                  value={item.patchingtaskname.value}
                                  onChange={(e) => {
                                    const value = e.target.value;

                                    for (const [
                                      idxOtherItem,
                                      otherItem,
                                    ] of formData.patchingtasks.entries()) {
                                      if (idxItem !== idxOtherItem) {
                                        if (
                                          otherItem.patchingtaskname.isValid &&
                                          otherItem.patchingtaskname.value !==
                                            ""
                                        ) {
                                          if (
                                            otherItem.patchingtaskname.value.toLowerCase() ===
                                            value.toLowerCase()
                                          ) {
                                            setErrorMsg(
                                              "Patching task name must be unique"
                                            );
                                            return false;
                                          }
                                        }
                                      }
                                    }

                                    onFieldChange(
                                      value,
                                      "patchingtaskname",
                                      idxItem
                                    );
                                  }}
                                  onBlur={(e) =>
                                    onFieldBlur(
                                      validateAccountName,
                                      e.target.value,
                                      "patchingtaskname",
                                      idxItem
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="d-inline-flex col-md-3 my-1 justify-content-end">
                              <button disabled={disabled}
                                className="closeFormRow closeFormRowBtn mx-2 four"
                                type="button"
                                onClick={() => removePatchingTask(idxItem)}
                              >
                                <img src="../images/bigfontclose.svg" />
                              </button>
                            </div>
                            <button disabled={disabled}
                              className="closeFormRowMobil closeFormRowBtn my-lg-0 my-1 mx-2 four"
                              type="button"
                              onClick={() => removePatchingTask(idxItem)}
                            >
                              <img src="../images/bigfontclose.svg" />
                            </button>
                          </div>

                          <div className="d-flex flex-column mainContentArea  pl-3 pr-3 my-1 py-1">
                            <div className="d-inline-flex align-items-center row">
                              <div className="col-md-3 my-1 p-0 ">
                                <span className="mb-0 font-12">
                                  Patch Operation
                                </span>
                              </div>
                              <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                <div className="mr-3 my-1">
                                  <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                                    <select
                                      className="form-control gray"
                                      value={item.patchoperation.value}
                                      onChange={(e) => {
                                        onFieldChange(
                                          e.target.value,
                                          "patchoperation",
                                          idxItem
                                        );
                                      }}
                                    >
                                      <option value="">Select</option>
                                      <option value="Install">
                                        Scan & Install
                                      </option>
                                      <option value="Scan">Scan only</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-inline-flex align-items-center row ">
                              <div className="col-md-3 my-1 p-0">
                                <span className="mb-0 font-12">
                                  Patch Groups(s)
                                </span>
                              </div>
                              <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                <div className="mr-3 my-1 ">
                                  {item.patchgroups.map((pg, idxPg) => (
                                    <div
                                      className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center"
                                      key={idxPg}
                                    >
                                      <button disabled={disabled}
                                        type="button"
                                        className="closableInput"
                                        onClick={() => {
                                          if (item.patchgroups.length > 1) {
                                            setIsDirty(true);

                                            setFormData((state) =>
                                              update(state, {
                                                patchingtasks: {
                                                  [idxItem]: {
                                                    patchgroups: {
                                                      $splice: [[idxPg, 1]],
                                                    },
                                                  },
                                                },
                                              })
                                            );
                                          }
                                        }}
                                      ></button>
                                      <input
                                        type="text"
                                        className="form-control bg-transparent"
                                        placeholder="Production"
                                        value={pg.value}
                                        onChange={(e) => {
                                          const value = e.target.value;

                                          for (const [
                                            idxOtherItem,
                                            otherItem,
                                          ] of item.patchgroups.entries()) {
                                            if (idxPg !== idxOtherItem) {
                                              if (
                                                otherItem.isValid &&
                                                otherItem.value !== ""
                                              ) {
                                                if (
                                                  otherItem.value.toLowerCase() ===
                                                  value.toLowerCase()
                                                ) {
                                                  setErrorMsg(
                                                    "Patching group must be unique"
                                                  );
                                                  return false;
                                                }
                                              }
                                            }
                                          }

                                          setErrorMsg(null);
                                          setIsDirty(true);

                                          setFormData((state) =>
                                            update(state, {
                                              patchingtasks: {
                                                [idxItem]: {
                                                  patchgroups: {
                                                    [idxPg]: {
                                                      value: {
                                                        $set: value,
                                                      },
                                                    },
                                                  },
                                                },
                                              },
                                            })
                                          );
                                        }}
                                        onBlur={(e) => {
                                          const value = e.target.value;

                                          if (value !== "") {
                                            const errorMsg = validateAccountName(
                                              value
                                            );

                                            setFormData((state) =>
                                              update(state, {
                                                patchingtasks: {
                                                  [idxItem]: {
                                                    patchgroups: {
                                                      [idxPg]: {
                                                        isValid: {
                                                          $set:
                                                            errorMsg ===
                                                            undefined,
                                                        },
                                                      },
                                                    },
                                                  },
                                                },
                                              })
                                            );

                                            if (errorMsg) {
                                              setErrorMsg(errorMsg);
                                            }
                                          } else {
                                            setFormData((state) =>
                                              update(state, {
                                                patchingtasks: {
                                                  [idxItem]: {
                                                    patchgroups: {
                                                      [idxPg]: {
                                                        isValid: {
                                                          $set: false,
                                                        },
                                                      },
                                                    },
                                                  },
                                                },
                                              })
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  ))}

                                  <button disabled={disabled}
                                    type="button"
                                    onClick={() => {
                                      setIsDirty(true);
                                      setFormData((state) =>
                                        update(state, {
                                          patchingtasks: {
                                            [idxItem]: {
                                              patchgroups: {
                                                $push: [createPatchgroup()],
                                              },
                                            },
                                          },
                                        })
                                      );
                                    }}
                                  >
                                    <img src="../images/coloredPlus.svg" />
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="d-inline-flex align-items-center row ">
                              <div className="col-md-3 my-1 p-0">
                                <span className="mb-0 font-12">Schedule</span>
                              </div>
                              <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                <div className="mr-3 my-1 ">
                                  <div className="w-250  border-10 my-1  p-relative   d-inline-flex align-items-center">
                                    <label className="mb-0 d-flex align-items-center font-12 mr-2">
                                      Every
                                    </label>
                                    <select
                                      className="form-control gray"
                                      value={item.patchscheduleinterval.value}
                                      onChange={(e) => {
                                        onFieldChange(
                                          e.target.value,
                                          "patchscheduleinterval",
                                          idxItem
                                        );
                                      }}
                                    >
                                      <option value="">Select</option>
                                      <option value="?">Every Day</option>
                                      <option value="MON">Monday</option>
                                      <option value="TUE">Tuesday</option>
                                      <option value="WED">Wednesday</option>
                                      <option value="THU">Thursday</option>
                                      <option value="FRI">Friday</option>
                                      <option value="SAT">Saturday</option>
                                      <option value="SUN">Sunday</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="mr-3 my-1 ">
                                  <div className="maxw-md-50  border-10 my-1  p-relative   d-inline-flex align-items-center">
                                    <label className="mb-0 d-flex align-items-center font-12 mr-2">
                                      at
                                    </label>
                                    <input
                                      type="text"
                                      className={`form-control bg-gray ${
                                        !item.patchscheduletime.isValid &&
                                        item.patchscheduletime.value !== ""
                                          ? "invalid"
                                          : ""
                                      }`}
                                      placeholder="12:00"
                                      name="patchscheduletime"
                                      value={item.patchscheduletime.value}
                                      onChange={(e) =>
                                        onFieldChange(
                                          e.target.value,
                                          "patchscheduletime",
                                          idxItem
                                        )
                                      }
                                      onBlur={(e) =>
                                        onFieldBlur(
                                          validateTime,
                                          e.target.value,
                                          "patchscheduletime",
                                          idxItem
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-inline-flex align-items-center row">
                              <div className="col-md-3 my-1 p-0 ">
                                <span className="mb-0 font-12">
                                  Maintenance Window Duration
                                </span>
                              </div>
                              <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                <div className="mr-3 my-1">
                                  <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                                    <select
                                      className="form-control gray"
                                      value={
                                        item.maintenancewindowduration.value
                                      }
                                      onChange={(e) => {
                                        onFieldChange(
                                          e.target.value,
                                          "maintenancewindowduration",
                                          idxItem
                                        );
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {[...Array(24).keys()].map((idx) => (
                                        <option value={idx + 1} key={idx}>{`${
                                          idx + 1
                                        } hour${
                                          idx + 1 > 1 ? "s" : ""
                                        }`}</option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="d-inline-flex align-items-center row">
                              <div className="col-md-3 my-1 p-0">
                                <span className="mb-0 font-12">
                                  Use Default Patch Baseline
                                </span>
                              </div>
                              <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                <div className="mr-3 my-1">
                                  <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-xl-5 mr-md-3 mr-0">
                                    <label
                                      className={`createElementBtn btn btn-link btn-linkYes ${
                                        item.usedefaultpatchbaseline.value ===
                                          "yes" && "active"
                                      } `}
                                    >
                                      <input
                                        type="radio"
                                        name={`usedefaultpatchbaseline-${idxItem}`}
                                        checked={
                                          item.usedefaultpatchbaseline.value ===
                                          "yes"
                                        }
                                        onChange={() =>
                                          onFieldChange(
                                            "yes",
                                            "usedefaultpatchbaseline",
                                            idxItem
                                          )
                                        }
                                      />{" "}
                                      YES
                                    </label>
                                    <label
                                      className={`createElementBtn btn btn-link btn-linkNo ${
                                        item.usedefaultpatchbaseline.value ===
                                          "no" && "active"
                                      } `}
                                    >
                                      <input
                                        type="radio"
                                        name={`usedefaultpatchbaseline-${idxItem}`}
                                        checked={
                                          item.usedefaultpatchbaseline.value ===
                                          "no"
                                        }
                                        onChange={() =>
                                          onFieldChange(
                                            "no",
                                            "usedefaultpatchbaseline",
                                            idxItem
                                          )
                                        }
                                      />{" "}
                                      NO
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {item.usedefaultpatchbaseline.value === "no" && (
                              <div className="flex-column row show-hide-inside-content px-0">
                                <div className="d-inline-flex align-items-center row px-0">
                                  <div className="my-1 p-0 col-md-3">
                                    <div className="pl-lg-0 px-2 d-flex align-items-center ">
                                      <div className="font-12">
                                        Patch Baseline Name
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                    <div className="w-250 bg-gray border-10  p-relative my-1  d-inline-flex align-items-center">
                                      <input
                                        type="text"
                                        className="bg-gray form-control"
                                        placeholder="Windows-Custom-Patch-Baseline"
                                        value={item.patchbaselinename.value}
                                        onChange={(e) => {
                                          const value = e.target.value;

                                          /* for (const [
                                    idxOtherItem,
                                    otherItem,
                                  ] of formData.patchingtasks.entries()) {
                                    if (idxItem !== idxOtherItem) {
                                      if (
                                        otherItem.patchingtaskname.isValid &&
                                        otherItem.patchingtaskname.value !== ""
                                      ) {
                                        if (
                                          otherItem.patchingtaskname.value.toLowerCase() ===
                                          value.toLowerCase()
                                        ) {
                                          setErrorMsg(
                                            "Patching task name must be unique"
                                          );
                                          return false;
                                        }
                                      }
                                    }
                                  } */

                                          onFieldChange(
                                            value,
                                            "patchbaselinename",
                                            idxItem
                                          );
                                        }}
                                        onBlur={(e) =>
                                          onFieldBlur(
                                            validateAccountName,
                                            e.target.value,
                                            "patchbaselinename",
                                            idxItem
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="d-inline-flex align-items-center row px-0">
                                  <div className="col-md-3 my-1 p-0 ">
                                    <span className="mb-0 font-12">
                                      Operating System
                                    </span>
                                  </div>
                                  <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                    <div className="mr-3 my-1">
                                      <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                                        <select
                                          className="form-control gray"
                                          value={item.operatingsystem.value}
                                          onChange={(e) => {
                                            setIsDirty(true);
                                            const value = e.target.value;

                                            setFormData((state) =>
                                              update(state, {
                                                patchingtasks: {
                                                  [idxItem]: {
                                                    operatingsystem: {
                                                      value: {
                                                        $set: value,
                                                      },
                                                    },
                                                    approvalruleforos_all_products: {
                                                      $set: OPERATING_SYSTEMS[
                                                        value
                                                      ]
                                                        ? OPERATING_SYSTEMS[
                                                            value
                                                          ].product
                                                        : [],
                                                    },
                                                    approvalruleforos_products: {
                                                      $set: [],
                                                    },
                                                    approvalruleforos_all_classification: {
                                                      $set: OPERATING_SYSTEMS[
                                                        value
                                                      ]
                                                        ? OPERATING_SYSTEMS[
                                                            value
                                                          ].classification
                                                        : [],
                                                    },
                                                    approvalruleforos_classification: {
                                                      $set: [],
                                                    },
                                                    approvalruleforos_all_severity: {
                                                      $set: OPERATING_SYSTEMS[
                                                        value
                                                      ]
                                                        ? OPERATING_SYSTEMS[
                                                            value
                                                          ].severity
                                                        : [],
                                                    },
                                                    approvalruleforos_severity: {
                                                      $set: [],
                                                    },
                                                    approvalruleforapplications_all_family: {
                                                      $set: APPLICATIONS[value]
                                                        ? APPLICATIONS[value]
                                                            .product_family
                                                        : [],
                                                    },
                                                    approvalruleforapplications_family: {
                                                      $set: [],
                                                    },
                                                    approvalruleforapplications_all_products: {
                                                      $set: APPLICATIONS[value]
                                                        ? APPLICATIONS[value]
                                                            .product
                                                        : [],
                                                    },
                                                    approvalruleforapplications_products: {
                                                      $set: [],
                                                    },
                                                    approvalruleforapplications_all_classification: {
                                                      $set: APPLICATIONS[value]
                                                        ? APPLICATIONS[value]
                                                            .classification
                                                        : [],
                                                    },
                                                    approvalruleforapplications_classification: {
                                                      $set: [],
                                                    },
                                                    approvalruleforapplications_all_severity: {
                                                      $set: APPLICATIONS[value]
                                                        ? APPLICATIONS[value]
                                                            .severity
                                                        : [],
                                                    },
                                                    approvalruleforapplications_severity: {
                                                      $set: [],
                                                    },
                                                  },
                                                },
                                              })
                                            );
                                          }}
                                        >
                                          <option value="">Select</option>
                                          {OPERATING_SYSTEM_LIST.map(
                                            (item, idxItem) => (
                                              <option
                                                value={item}
                                                key={idxItem}
                                              >
                                                {item}
                                              </option>
                                            )
                                          )}
                                          {/* <option value="Windows">
                                            Windows
                                          </option>
                                          <option value="Amazon Linux2">
                                            Amazon Linux2
                                          </option>
                                          <option value="CentOS">CentOS</option>
                                          <option value="Oracle Linux">
                                            Oracle Linux
                                          </option>
                                          <option value="Redhat Enterprise Linux">
                                            Redhat Enterprise Linux
                                          </option>
                                          <option value="SUSE">SUSE</option>
                                          <option value="Ubuntu">Ubuntu</option>
                                          <option value="Debian">Debian</option> */}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-inline-flex align-items-center row px-0">
                                  <div className="col-md-3 my-1 p-0 ">
                                    <span className="mb-0 font-12">
                                      Make this the default Patch Baseline for{" "}
                                      {item.operatingsystem.value}
                                    </span>
                                  </div>
                                  <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                    <div className="one form-line  d-inline-flex align-items-center">
                                      <label className=" switch mb-0 pl-0">
                                        <input
                                          type="checkbox"
                                          checked={
                                            item.makeitdefaultpatchbaseline
                                              .value === "yes"
                                          }
                                          onChange={(e) => {
                                            onFieldChange(
                                              e.target.checked ? "yes" : "no",
                                              "makeitdefaultpatchbaseline",
                                              idxItem
                                            );
                                          }}
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-inline-flex align-items-center row px-0">
                                  <div className="col-12 px-0 my-1">
                                    <p className="font-weight-bold font-12 mb-0">
                                      Approval Rule for Operating Systems
                                      Patches
                                    </p>
                                  </div>
                                </div>

                                <div className="d-inline-flex align-items-center row px-0">
                                  <div className="col-md-3 my-1 p-0 ">
                                    <span className="mb-0 font-12">
                                      Products
                                    </span>
                                  </div>
                                  <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                    <div className="mr-3 my-1">
                                      <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                                        <select
                                          className="form-control gray"
                                          value=""
                                          onChange={(e) => {
                                            onSelectionChange(
                                              idxItem,
                                              "approvalruleforos_products",
                                              item,
                                              e.target.value
                                            );
                                          }}
                                        >
                                          <option value="">
                                            Select Products
                                          </option>
                                          {item.approvalruleforos_all_products.map(
                                            (p, idxP) => (
                                              <option key={idxP} value={p}>
                                                {p}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                    {item.approvalruleforos_products.map(
                                      (p, idxP) => (
                                        <div key={idxP} className="mr-3 my-1 ">
                                          <div className="w-250 bg-gray border-10 p-relative d-inline-flex align-items-center">
                                            <button disabled={disabled}
                                              type="button"
                                              className="closableInput"
                                              onClick={() => {
                                                remove(
                                                  idxItem,
                                                  "approvalruleforos_products",
                                                  p
                                                );
                                              }}
                                            ></button>
                                            <input
                                              type="text"
                                              className="form-control bg-transparent"
                                              placeholder={p}
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>

                                <div className="d-inline-flex align-items-center row px-0">
                                  <div className="col-md-3 my-1 p-0 ">
                                    <span className="mb-0 font-12">
                                      Classification(s)
                                    </span>
                                  </div>
                                  <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                    <div className="mr-3 my-1">
                                      <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                                        <select
                                          className="form-control gray"
                                          value=""
                                          onChange={(e) => {
                                            onSelectionChange(
                                              idxItem,
                                              "approvalruleforos_classification",
                                              item,
                                              e.target.value
                                            );
                                          }}
                                        >
                                          <option value="">
                                            Select Classifications
                                          </option>
                                          {item.approvalruleforos_all_classification.map(
                                            (p, idxP) => (
                                              <option key={idxP} value={p}>
                                                {p}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                    {item.approvalruleforos_classification.map(
                                      (p, idxP) => (
                                        <div key={idxP} className="mr-3 my-1 ">
                                          <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                            <button disabled={disabled}
                                              type="button"
                                              className="closableInput"
                                              onClick={() => {
                                                remove(
                                                  idxItem,
                                                  "approvalruleforos_classification",
                                                  p
                                                );
                                              }}
                                            ></button>
                                            <input
                                              type="text"
                                              className="form-control bg-transparent"
                                              placeholder={p}
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                                <div className="d-inline-flex align-items-center row px-0">
                                  <div className="col-md-3 my-1 p-0 ">
                                    <span className="mb-0 font-12">
                                      Severity
                                    </span>
                                  </div>
                                  <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                    <div className="mr-3 my-1">
                                      <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                                        <select
                                          className="form-control gray"
                                          value=""
                                          onChange={(e) => {
                                            onSelectionChange(
                                              idxItem,
                                              "approvalruleforos_severity",
                                              item,
                                              e.target.value
                                            );
                                          }}
                                        >
                                          <option value="">
                                            Select Severity
                                          </option>
                                          {item.approvalruleforos_all_severity.map(
                                            (p, idxP) => (
                                              <option key={idxP} value={p}>
                                                {p}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                    {item.approvalruleforos_severity.map(
                                      (p, idxP) => (
                                        <div key={idxP} className="mr-3 my-1 ">
                                          <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                            <button disabled={disabled}
                                              type="button"
                                              className="closableInput"
                                              onClick={() => {
                                                remove(
                                                  idxItem,
                                                  "approvalruleforos_severity",
                                                  p
                                                );
                                              }}
                                            ></button>
                                            <input
                                              type="text"
                                              className="form-control bg-transparent"
                                              placeholder={p}
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                                <div className="d-inline-flex align-items-center row px-0">
                                  <div className="col-md-3 my-1 p-0 ">
                                    <span className="mb-0 font-12">
                                      Auto-approve Patches After
                                    </span>
                                  </div>
                                  <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                    <div className="mr-3 my-1">
                                      <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                                        <select
                                          className="form-control gray"
                                          value={
                                            item
                                              .approvalruleforos_autoapprovepatchesafter
                                              .value
                                          }
                                          onChange={(e) => {
                                            onFieldChange(
                                              e.target.value,
                                              "approvalruleforos_autoapprovepatchesafter",
                                              idxItem
                                            );
                                          }}
                                        >
                                          <option value="">Select</option>
                                          {[...Array(30).keys()].map((idx) => (
                                            <option value={idx + 1}>{`${
                                              idx + 1
                                            } day${
                                              idx + 1 > 1 ? "s" : ""
                                            }`}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-inline-flex align-items-center row px-0">
                                  <div className="col-md-3 my-1 p-0 ">
                                    <span className="mb-0 font-12">
                                      Compliance Reporting
                                    </span>
                                  </div>
                                  <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                    <div className="mr-3 my-1">
                                      <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                                        <select
                                          className="form-control gray"
                                          value={
                                            item
                                              .approvalruleforos_compliancereporting
                                              .value
                                          }
                                          onChange={(e) => {
                                            onFieldChange(
                                              e.target.value,
                                              "approvalruleforos_compliancereporting",
                                              idxItem
                                            );
                                          }}
                                        >
                                          <option value="">Select</option>
                                          <option value="ALL">All</option>
                                          <option value="CRITICAL">
                                            Critical
                                          </option>
                                          <option value="IMPORTANT">
                                            Important
                                          </option>
                                          <option value="MODERATE">
                                            Moderate
                                          </option>
                                          <option value="LOW">Low</option>
                                          <option value="UNSPECIFIED">
                                            Unspecified
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {item.operatingsystem.value !== "WINDOWS" &&
                                  item.operatingsystem.value !== "SUSE" && (
                                    <div className="d-inline-flex align-items-center row px-0">
                                      <div className="col-md-3 my-1 p-0 ">
                                        <span className="mb-0 font-12">
                                          Include Non-security Updates
                                        </span>
                                      </div>
                                      <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                        <div className="one form-line  d-inline-flex align-items-center">
                                          <label className=" switch mb-0 pl-0">
                                            <input
                                              type="checkbox"
                                              checked={
                                                item.includenonsecurityupdates
                                                  .value === "yes"
                                              }
                                              onChange={(e) => {
                                                onFieldChange(
                                                  e.target.checked
                                                    ? "yes"
                                                    : "no",
                                                  "includenonsecurityupdates",
                                                  idxItem
                                                );
                                              }}
                                            />
                                            <span className="slider round"></span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                {item.operatingsystem.value === "WINDOWS" && (
                                  <>
                                    <div className="d-inline-flex align-items-center row px-0">
                                      <div className="col-md-3 my-1 p-0">
                                        <p className="font-weight-bold mb-0 font-12">
                                          Approval Rule for Application Patches
                                        </p>
                                      </div>
                                      <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                        <div className="one form-line  d-inline-flex align-items-center">
                                          <label className=" switch mb-0 pl-0">
                                            <input
                                              type="checkbox"
                                              checked={
                                                item
                                                  .approvalruleforapplicationpatches
                                                  .value === "yes"
                                              }
                                              onChange={(e) => {
                                                onFieldChange(
                                                  e.target.checked
                                                    ? "yes"
                                                    : "no",
                                                  "approvalruleforapplicationpatches",
                                                  idxItem
                                                );
                                              }}
                                            />
                                            <span className="slider round"></span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    {item.approvalruleforapplicationpatches
                                      .value === "yes" && (
                                      <>
                                        <div className="d-inline-flex align-items-center row px-0">
                                          <div className="col-md-3 my-1 p-0 ">
                                            <span className="mb-0 font-12">
                                              Product Family
                                            </span>
                                          </div>
                                          <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                            <div className="mr-3 my-1">
                                              <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                                                <select
                                                  className="form-control gray"
                                                  value=""
                                                  onChange={(e) => {
                                                    onSelectionChange(
                                                      idxItem,
                                                      "approvalruleforapplications_family",
                                                      item,
                                                      e.target.value
                                                    );
                                                  }}
                                                >
                                                  <option value="">
                                                    Select Family
                                                  </option>
                                                  {item.approvalruleforapplications_all_family.map(
                                                    (p, idxP) => (
                                                      <option
                                                        key={idxP}
                                                        value={p}
                                                      >
                                                        {p}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                            {item.approvalruleforapplications_family.map(
                                              (p, idxP) => (
                                                <div
                                                  key={idxP}
                                                  className="mr-3 my-1 "
                                                >
                                                  <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                                    <button disabled={disabled}
                                                      type="button"
                                                      className="closableInput"
                                                      onClick={() => {
                                                        remove(
                                                          idxItem,
                                                          "approvalruleforapplications_family",
                                                          p
                                                        );
                                                      }}
                                                    ></button>
                                                    <input
                                                      type="text"
                                                      className="form-control bg-transparent"
                                                      placeholder={p}
                                                      readOnly
                                                    />
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                        <div className="d-inline-flex align-items-center row px-0">
                                          <div className="col-md-3 my-1 p-0 ">
                                            <span className="mb-0 font-12">
                                              Products
                                            </span>
                                          </div>
                                          <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                            <div className="mr-3 my-1">
                                              <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                                                <select
                                                  className="form-control gray"
                                                  value=""
                                                  onChange={(e) => {
                                                    onSelectionChange(
                                                      idxItem,
                                                      "approvalruleforapplications_products",
                                                      item,
                                                      e.target.value
                                                    );
                                                  }}
                                                >
                                                  <option value="">
                                                    Select Product
                                                  </option>
                                                  {item.approvalruleforapplications_all_products.map(
                                                    (p, idxP) => (
                                                      <option
                                                        key={idxP}
                                                        value={p}
                                                      >
                                                        {p}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                            {item.approvalruleforapplications_products.map(
                                              (p, idxP) => (
                                                <div
                                                  key={idxP}
                                                  className="mr-3 my-1 "
                                                >
                                                  <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                                    <button disabled={disabled}
                                                      type="button"
                                                      className="closableInput"
                                                      onClick={() => {
                                                        remove(
                                                          idxItem,
                                                          "approvalruleforapplications_products",
                                                          p
                                                        );
                                                      }}
                                                    ></button>
                                                    <input
                                                      type="text"
                                                      className="form-control bg-transparent"
                                                      placeholder={p}
                                                      readOnly
                                                    />
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>

                                        <div className="d-inline-flex align-items-center row px-0">
                                          <div className="col-md-3 my-1 p-0 ">
                                            <span className="mb-0 font-12">
                                              Classification(s)
                                            </span>
                                          </div>
                                          <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                            <div className="mr-3 my-1">
                                              <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                                                <select
                                                  className="form-control gray"
                                                  value=""
                                                  onChange={(e) => {
                                                    onSelectionChange(
                                                      idxItem,
                                                      "approvalruleforapplications_classification",
                                                      item,
                                                      e.target.value
                                                    );
                                                  }}
                                                >
                                                  <option value="">
                                                    Select Classifications
                                                  </option>
                                                  {item.approvalruleforapplications_all_classification.map(
                                                    (p, idxP) => (
                                                      <option
                                                        key={idxP}
                                                        value={p}
                                                      >
                                                        {p}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                            {item.approvalruleforapplications_classification.map(
                                              (p, idxP) => (
                                                <div
                                                  key={idxP}
                                                  className="mr-3 my-1 "
                                                >
                                                  <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                                    <button disabled={disabled}
                                                      type="button"
                                                      className="closableInput"
                                                      onClick={() => {
                                                        remove(
                                                          idxItem,
                                                          "approvalruleforapplications_classification",
                                                          p
                                                        );
                                                      }}
                                                    ></button>
                                                    <input
                                                      type="text"
                                                      className="form-control bg-transparent"
                                                      placeholder={p}
                                                      readOnly
                                                    />
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>

                                        <div className="d-inline-flex align-items-center row px-0">
                                          <div className="col-md-3 my-1 p-0 ">
                                            <span className="mb-0 font-12">
                                              Severity
                                            </span>
                                          </div>
                                          <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                            <div className="mr-3 my-1">
                                              <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                                                <select
                                                  className="form-control gray"
                                                  value=""
                                                  onChange={(e) => {
                                                    onSelectionChange(
                                                      idxItem,
                                                      "approvalruleforapplications_severity",
                                                      item,
                                                      e.target.value
                                                    );
                                                  }}
                                                >
                                                  <option value="">
                                                    Select Severity
                                                  </option>
                                                  {item.approvalruleforapplications_all_severity.map(
                                                    (p, idxP) => (
                                                      <option
                                                        key={idxP}
                                                        value={p}
                                                      >
                                                        {p}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                            {item.approvalruleforapplications_severity.map(
                                              (p, idxP) => (
                                                <div
                                                  key={idxP}
                                                  className="mr-3 my-1 "
                                                >
                                                  <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                                    <button disabled={disabled}
                                                      type="button"
                                                      className="closableInput"
                                                      onClick={() => {
                                                        remove(
                                                          idxItem,
                                                          "approvalruleforapplications_severity",
                                                          p
                                                        );
                                                      }}
                                                    ></button>
                                                    <input
                                                      type="text"
                                                      className="form-control bg-transparent"
                                                      placeholder={p}
                                                      readOnly
                                                    />
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                        <div className="d-inline-flex align-items-center row px-0">
                                          <div className="col-md-3 my-1 p-0 ">
                                            <span className="mb-0 font-12">
                                              Auto-approve Patches After
                                            </span>
                                          </div>
                                          <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                            <div className="mr-3 my-1">
                                              <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                                                <select
                                                  className="form-control gray"
                                                  value={
                                                    item
                                                      .approvalruleforapplications_autoapprovepatchesafter
                                                      .value
                                                  }
                                                  onChange={(e) => {
                                                    onFieldChange(
                                                      e.target.value,
                                                      "approvalruleforapplications_autoapprovepatchesafter",
                                                      idxItem
                                                    );
                                                  }}
                                                >
                                                  <option value="">
                                                    Select
                                                  </option>
                                                  {[...Array(30).keys()].map(
                                                    (idx) => (
                                                      <option
                                                        value={idx + 1}
                                                      >{`${idx + 1} day${
                                                        idx + 1 > 1 ? "s" : ""
                                                      }`}</option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="d-inline-flex align-items-center row px-0">
                                          <div className="col-md-3 my-1 p-0 ">
                                            <span className="mb-0 font-12">
                                              Compliance Reporting
                                            </span>
                                          </div>
                                          <div className="col-md-9 flex-wrap d-inline-flex px-1">
                                            <div className="mr-3 my-1">
                                              <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                                                <select
                                                  className="form-control gray"
                                                  value={
                                                    item
                                                      .approvalruleforapplications_compliancereporting
                                                      .value
                                                  }
                                                  onChange={(e) => {
                                                    onFieldChange(
                                                      e.target.value,
                                                      "approvalruleforapplications_compliancereporting",
                                                      idxItem
                                                    );
                                                  }}
                                                >
                                                  <option value="">
                                                    Select
                                                  </option>
                                                  <option value="ALL">
                                                    All
                                                  </option>
                                                  <option value="CRITICAL">
                                                    Critical
                                                  </option>
                                                  <option value="IMPORTANT">
                                                    Important
                                                  </option>
                                                  <option value="MODERATE">
                                                    Moderate
                                                  </option>
                                                  <option value="LOW">
                                                    Low
                                                  </option>
                                                  <option value="UNSPECIFIED">
                                                    Unspecified
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </React.Fragment>
                      ))}
                      <div className="w-100  mb-2  ">
                        <div className="row d-flex align-items-center">
                          <div className="add-button d-flex px-1  justify-content-end">
                            <button disabled={disabled}
                              className="add-new-account-link btn-animation d-inline-flex align-items-center"
                              type="button"
                              onClick={() => addPatchingTask()}
                            >
                              <p className="my-0 mr-2 p-0 green-style2">
                                Add Patching Task
                              </p>
                              <img src="../images/coloredPlus.svg" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.INFRASTRUCTURE_IMAGEMANAGEMENT + "/" + projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Image Management
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.INFRASTRUCTURE_VDISOLUTION + "/" + projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      VDI Solution
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatchManagement;
